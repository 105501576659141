import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";

const ErrorFallback = ({ error, resetErrorBoundary }: any) => {
  return (
    <Box role='alert'>
      <Text>Something went wrong:</Text>
      {/* <pre>{error.message}</pre>*/}
      <button onClick={resetErrorBoundary}>Try again</button>
    </Box>
  );
};

interface ErrorBoundaryProps {
  children: React.ReactNode;
}
export const ErrorBoundary = (props: ErrorBoundaryProps) => {
  const { children } = props;

  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
