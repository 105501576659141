enum AprEmailPlaceHolder {
  ConsenyDueDate = "[Consent Due Date]",
  EmployeeName = "[Employee Name]",
  EvaluationDueDate = "[Evaluation Due Date]",
  EvaluatorName = "[Evaluator Name]",
  ManagerDueDate = "[Manager's Due Date]",
  ManagerName = "[Manager Name]",
  NominationDueDate = "[Nomination Due Date]",
  PeerName = "[Peer Name]",
  PeerReviewDueDate = "[Peer Review Due Date]",
  ReviewCycle = "[Review Cycle]",
  SelfReviewDueDate = "[Self Review Due Date]",
}

export default AprEmailPlaceHolder
