// @ts-nocheck 
import {Text} from "components/text";
import {PRIMARY_TEXT} from "constants/colors";
import React from "react";

const CoursePageQuestionSingleAnswerListItem = ({questionOption}) => {
  return (
    <Text
      color={PRIMARY_TEXT}
      display="block"
      letterSpacing="0.4px"
      margin="6px 26px"
      title={questionOption.Answer}
    />
  );
};

export default CoursePageQuestionSingleAnswerListItem;
