import { RenderModulationProps } from '../interfaces';
import React from 'react';
import { List, ListItem } from '@chakra-ui/react';

const RenderModulation = (props: RenderModulationProps) => {
  const { show, handleClick, selectedValue } = props;

  return show ? (
    <List width="50px">
      <ListItem
        textAlign="center"
        sx={{
          ...('am' === selectedValue && {
            color: 'gray.700',
            background: 'gray.100',
          }),
        }}
        onClick={() => handleClick('am')}
        padding="6px 0"
        cursor="pointer"
        _hover={{
          background: 'gray.100',
          color: 'gray.800',
        }}
      >
        AM
      </ListItem>

      <ListItem
        textAlign="center"
        sx={{
          ...('pm' === selectedValue && {
            color: 'gray.700',
            background: 'gray.100',
          }),
        }}
        onClick={() => handleClick('pm')}
        padding="6px 0"
        cursor="pointer"
        _hover={{
          background: 'gray.100',
          color: 'gray.800',
        }}
      >
        PM
      </ListItem>
    </List>
  ) : null;
};

export default RenderModulation;
