import React, { FunctionComponent } from 'react';
import { ParsedDate, SplittedDate } from '../../../Types';
import DayPickerBody from './DayPickerBody';
import DayPickerHeader from './DayPickerHeader';

interface DayPickerProps {
  selectedDate: ParsedDate;
  calenderDate: ParsedDate;
  onDaySelect: (date: SplittedDate) => void;
  disableDateBefore?: any;
  disableDateAfter?: any;
}

const DayPicker: FunctionComponent<DayPickerProps> = ({
  selectedDate,
  calenderDate,
  onDaySelect,
  disableDateBefore,
  disableDateAfter,
}) => {
  return (
    <table>
      <DayPickerHeader />

      <DayPickerBody
        selectedDate={selectedDate}
        calenderDate={calenderDate}
        onSelect={onDaySelect}
        disableDateBefore={disableDateBefore}
        disableDateAfter={disableDateAfter}
      />
    </table>
  );
};

export default DayPicker;
