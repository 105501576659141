// NETWORK
import { ApiClient } from "../../../network/apiClient"
import {
  CREATE_PERFORMANCE_FORM,
  FETCH_PERFORMANCE_FORM_LIST,
  FETCH_PERFORMANCE_FORM_WEIGHTS,
  FETCH_PERFORMANCE_PREVIEW,
  UPDATE_PERFORMANCE_FORM_WEIGHTS,
} from "./api"

// STORE
import * as fromCurrentFormDetailStore from "../current-form-detail"
import * as fromReviewCycleStore from "../review-cycle"
import * as fromStatusStore from "../status"
import * as fromPerformanceFormState from "./../performance-form-state"

// SELECTOR
import * as fromSelectors from "./selectors"

// SLICE
import * as fromSlice from "./slice"

// interface
import * as fromInterface from "../../../ts"

type fetchPostType = {
  pageSize: number
  page: number
  assessmentOptionType: number
  formName: string
  isRolledOut: boolean
  reviewCycleId: any
}

// PAGE LEVEL ACTIONS
export const fetchPerformanceFormInitialPageData =
  (params: fetchPostType) => async (dispatch: any) => {
    dispatch(fetchPerformanceFormList(params))
    // dispatch(fromReviewCycleStore.fetchReviewCycle())
    dispatch(fromStatusStore.fetchStatus())
  }

export const clearPerformancePageData =
  (params: fetchPostType) => async (dispatch: any) => {
    dispatch(clearPerformanceForm())
    dispatch(fromReviewCycleStore.clearReviewCycle())
    dispatch(fromStatusStore.clearStatus())
  }
// END PAGE LEVEL ACTIONS

export const fetchPerformanceFormList =
  (params: fetchPostType) => async (dispatch: any, getState: any) => {
    dispatch(fromSlice.fetchRequest())

    const filters = fromSelectors.selectPerformanceFormSelectFilters(getState())
    const pageInfo = fromSelectors.selectPerformanceFormSelectPageInfo(
      getState(),
    )

    const mappedParams = {
      reviewCycleId: filters?.reviewCycleId?.value || filters?.reviewCycleId,
      formName: filters?.formName,
      isRolledOut: filters?.isRolledOut?.value || filters?.isRolledOut,
      ...pageInfo,
    }
    const response: fromInterface.ResponseWrapper<fromInterface.ReviewCycle> =
      await ApiClient.post<fetchPostType>({
        endpoint: FETCH_PERFORMANCE_FORM_LIST,
        body: params,
        dispatch: dispatch,
      })

    if (response?.Status) {
      dispatch(fromSlice.fetchSuccess(response?.Data))
    }
    return response
  }
export const fetchPerformancePreview =
  (params: {
    formId: number,
    cb?: any
  }) => async (dispatch: any) => {

    const response: any =
      await ApiClient.get<fetchPostType>({
        endpoint: FETCH_PERFORMANCE_PREVIEW.replace(":formId", params.formId + ""),
        dispatch: dispatch,
      })

    const { Data, Status } = response

    if (Status) {
      params.cb?.({ Data })
    }
  }

/**
 * FETCH PERFROMANCE FORM DETAIL
 * @param param0
 * @returns
 */
export const fetchPerformanceFormDetail =
  ({ formId, cb }: { formId?: number, cb?: any }) =>
    async (dispatch: any, getState: any) => {
      dispatch(
        fromCurrentFormDetailStore.setCurrentFormDetail({
          formId: formId,
          cb
        }),
      )
    }

/**
 * FETCH PERFORMANCE FORM WEIGHT
 * @param param0
 * @returns
 */
export const fetchPerformanceFormWeights =
  ({ formId, cb }: { formId?: number; cb?: any }) =>
    async (dispatch: any, getState: any) => {
      const response: fromInterface.ResponseWrapper<fromInterface.PerformanceFormWeights> =
        await ApiClient.get<any>({
          endpoint: FETCH_PERFORMANCE_FORM_WEIGHTS.replace(
            ":formId",
            formId + "",
          ),
          dispatch: dispatch,
        })

      if (response?.Status) {
        cb?.(response?.Data)
      }
    }

export const createNewFormConfiguration =
  ({ payload, cb }: { payload: any; cb?: any }) =>
    async (dispatch: any) => {
      const response: fromInterface.ResponseWrapper<fromInterface.PerformanceForm> =
        await ApiClient.post<fetchPostType>({
          endpoint: CREATE_PERFORMANCE_FORM,
          body: { ...payload, AssessmentOptionType: 1 }, //AssessmentOptionType.PerformanceAppraisals
          dispatch: dispatch,
          showMessage: true,
        })

      const { Data, Status } = response

      if (Status) {
        cb?.(Data)
        dispatch(
          fromCurrentFormDetailStore.setCurrentFormDetail({
            formId: Data?.Id,
          }),
        )
      }

      return response
    }

export const updatePerformanceForm = (params: any) => async (dispatch: any) => {
  const response: fromInterface.ResponseWrapper<fromInterface.ReviewCycle> =
    await ApiClient.post<fetchPostType>({
      endpoint: CREATE_PERFORMANCE_FORM,
      body: { ...params, AssessmentOptionType: 1 }, //AssessmentOptionType.PerformanceAppraisals
      dispatch: dispatch,
      showMessage: true,
    })

  const { Data, Status } = response

  if (Status) {
    dispatch(
      fromCurrentFormDetailStore.setCurrentFormDetail({
        formId: Data?.Id,
      }),
    )
  }

  return response
}

export const updatePerformanceFormFilter =
  (filters: any) => async (dispatch: any, getState: any) => {
    const oldFilters = fromSelectors.selectPerformanceFormSelectFilters(
      getState(),
    )

    const newFilters = { ...oldFilters, ...filters }

    dispatch(fromSlice.updateFilter(newFilters))
  }

export const updatePerformanceFormPageInfo =
  (pageInfo: any) => async (dispatch: any, getState: any) => {
    const oldPageInfo = fromSelectors.selectPerformanceFormSelectPageInfo(
      getState(),
    )

    const newPageInfo = { ...oldPageInfo, ...pageInfo }

    dispatch(fromSlice.updatePageInfo(newPageInfo))
  }

/**
 * UPDATE PERFORMANCE FORM WEIGHT
 * @param param0
 * @returns
 */
export const updatePerformanceFormWeight =
  ({ payload, cb }: { payload?: any; cb?: any }) =>
    async (dispatch: any, getState: any) => {
      const response: fromInterface.ResponseWrapper<fromInterface.PerformanceFormWeights> =
        await ApiClient.put<any>({
          endpoint: UPDATE_PERFORMANCE_FORM_WEIGHTS,
          dispatch: dispatch,
          body: payload,
        })

      if (response?.Status) {
        const data: fromInterface.PerformanceFormWeights =
          response?.Data as fromInterface.PerformanceFormWeights
        const state = getState()
        const performanceFormState =
          fromPerformanceFormState.selectFormState(state)
        const { Id } = performanceFormState
        const sectionFound = data?.Sections?.find(
          (section: fromInterface.PerformanceFormWeightsSection) =>
            section.Id === Id,
        )

        dispatch(
          fromPerformanceFormState.syncFormState({
            ...performanceFormState,
            Weight: sectionFound?.Weight,
          }),
        )

        cb?.(response?.Data)
      }
    }

export const clearPerformanceForm = () => (dispatch: any) => {
  dispatch(fromSlice.clear())
}
