import isEmpty from "isEmpty";
import configureStore from "../store/configureStore";

const store = configureStore;
// const store = configureStore();

export const ID = () => {
  let userDetail =
    localStorage.UserDetail && JSON.parse(localStorage.UserDetail);
  let empId = 0;
  if (!isEmpty(userDetail)) {
    empId = userDetail.ID;
  }
  return empId;
};

export const UserFullDetail = () => {
  let userDetail =
    localStorage.UserDetail && JSON.parse(localStorage.UserDetail);
  let emp = {};
  if (!isEmpty(userDetail)) {
    emp = userDetail;
  }
  return emp;
};

export const UserName = () => {
  let userDetail =
    localStorage.UserDetail && JSON.parse(localStorage.UserDetail);
  let name = null;
  if (!isEmpty(userDetail)) {
    name = userDetail.UserName;
  }
  return name;
};

export const UserGuid = () => {
  let userDetail =
    localStorage.UserDetail && JSON.parse(localStorage.UserDetail);
  let guid = null;
  if (!isEmpty(userDetail)) {
    guid = userDetail.UserID;
  }
  return guid;
};

export const RoleId = () => {
  let userDetail =
    localStorage.UserDetail && JSON.parse(localStorage.UserDetail);
  let roleId = null;
  if (!isEmpty(userDetail)) {
    roleId = userDetail.RoleId;
  }
  return roleId;
};

export const RoleName = () => {
  let userDetail =
    localStorage.UserDetail && JSON.parse(localStorage.UserDetail);
  let roleName = null;
  if (!isEmpty(userDetail)) {
    roleName = userDetail.RoleName;
  }
  return roleName;
};

export const EmployeeID = () => {
  let userDetail =
    localStorage.UserDetail && JSON.parse(localStorage.UserDetail);
  let empId = 0;
  if (!isEmpty(userDetail)) {
    //empId = userDetail.EmployeeId ? userDetail.EmployeeId : 0;
    empId = userDetail.IsEmployeeType
      ? userDetail.EmployeeId
      : userDetail.MappedEmployeeId;
  }
  return empId;
};

export const RoleIdByStore = () => {
  const { auth } = store.getState();
  let roleId = null;
  if (!isEmpty(auth.user) && !isEmpty(auth.user.RoleId)) {
    roleId = auth.user.RoleId;
  }
  return roleId;
};

export const UserByStore = () => {
  const { auth } = store.getState();
  let user = null;
  if (!isEmpty(auth.user)) {
    user = auth.user;
  }
  return user;
};

export const getUploadPath = file => {
  const { auth } = store.getState();
  if (file) {
    let baseUrl = auth?.baseUrl?.NetFrameworkAppBaseUrl
      ? auth.baseUrl.NetFrameworkAppBaseUrl
      : "";
    return baseUrl + file;
  }
  return null;
};

export const getBaseUrl = (url = "") => {
  const { auth } = store.getState();
  let baseUrl = auth?.baseUrl;
  const base = baseUrl?.NetFrameworkAppBaseUrl || `${window.location.origin}//`;

  return url ? base + url : base;
};
