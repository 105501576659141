import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import * as fromInterface from "../../../ts";
export interface PMSConfigTypeState {
  ConfigValue: string;
  ConfigType: number;
}
export interface PMSConfigState {
  items: PMSConfigTypeState[];
  status: fromInterface.loadingType;
  loaded: boolean;
}

const initialState: PMSConfigState = {
  items: [],
  status: fromInterface.STATUS.LOADING,
  loaded: false,
};

const pmsConfigSlice = createSlice({
  name: "pms config",
  initialState,
  reducers: {
    fetchRequest: state => {
      state.status = fromInterface.STATUS.LOADING;
    },
    fetchSuccess: (state, action: PayloadAction<PMSConfigTypeState[]>) => {
      const payload = action.payload?.map(item => ({
        ...item,
        ConfigValue: item?.ConfigValue?.replace(/\n/g, "<br/>"),
      }));
      state.items = payload;
      state.status = fromInterface.STATUS.IDLE;
      state.loaded = true;
    },
    clear: state => {
      state = { ...initialState };
    },
  },
});

export const { fetchRequest, fetchSuccess, clear } = pmsConfigSlice.actions;

export default pmsConfigSlice.reducer;
