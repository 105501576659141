import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import FormGroup from "components/form";
import {Tabs, Tab} from "components/tabs/index.d";
import CourseList from "./courseList";
import {
  getCourseList,
  getFilteredCourseList,
  getSearchedFilteredCourseList,
} from "../helpers/action";

const initialFilters = {
  pageIndex: 0,
  pageSize: 99999,
  courseName: "",
  categoryI: -1,
  createdOn: null,
  createdToate: null,
  status: -1,
};

const CourseTabs = (props) => {
  const [filters, setFilters] = useState(initialFilters);
  const [activeTabId, setActiveTabId] = useState(1);
  const [searchCourseText, setSearchCourseText] = useState("");
  const [data, setData] = useState([]);

  const tabs = [
    {
      id: 1,
      label: "Active Courses",
    },
    {
      id: 0,
      label: "Old Courses",
    },
  ];

  const dispatch = useDispatch();
  const {courseList} = useSelector((state) => state.lmsReducer);
  const {filteredCourseList} = useSelector((state) => state.lmsReducer);

  useEffect(() => {
    dispatch(getCourseList(filters));
  }, []);

  useEffect(() => {
    setFilters({...initialFilters, status: activeTabId});
  }, [activeTabId]);

  useEffect(() => {
    dispatch(getFilteredCourseList(filters.status));
  }, [filters, courseList]);

  useEffect(() => {
    setData(filteredCourseList);
  }, [filteredCourseList]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const result =
        filteredCourseList.length > 0 &&
        filteredCourseList.filter((course) => {
          return course.Title.toLowerCase().includes(
            searchCourseText.toLocaleLowerCase()
          );
        });

      setData(result);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [filteredCourseList, searchCourseText]);

  const handleSearchCourseText = (name, value) => {
    setSearchCourseText(value);
  };

  return (
    <>
      <FormGroup
        className="search"
        label="Search Course"
        onChange={handleSearchCourseText}
        name="searchCourseText"
        value={searchCourseText}
        placeholder=""
      />
      {tabs && (
        <Tabs noBackground activeTab={activeTabId}>
          {tabs.map((tab, index) => (
            <Tab
              key={tab.id}
              label={tab.label}
              name={tab.id}
              id={tab.id}
              isActive={activeTabId === tab.id}
              setActiveTabId={setActiveTabId}
            >
              <CourseList data={data} />
            </Tab>
          ))}
        </Tabs>
      )}
    </>
  );
};

export default CourseTabs;
