// @ts-nocheck 
export enum LMSQuestionTypeEnum {
  ShortText = 1,
  LongText = 2,
  RadioButton = 3,
  CheckBox = 4,
}

export enum AnswerType {
  normal = "normal",
  yes = "yes",
  true = "true",
}

export enum CourseStatus {
  Unpublish = 0,
  Publish = 1,
  Discontinue = 3,
}

export enum CourseDashboardStatus {
  All = -1,
  Assign = -2,
  NotStarted = 0,
  InProgress = 1,
  Completed = 2,
  Deadline = 3,
}

export enum LMSCourseShowCourseTo {
  AssignedUser = 1,
  Everyone = 2,
}

export enum EmployeeCourseAssignStatus {
  NotStarted = 0,
  InProgress = 1,
  Completed = 2,
  Left = 3,
}
export enum LMSCourseNavigationPolicyType {
  Free = 1,
  Sequential = 2,
}
