import { ApiClient } from "../../../network/apiClient";
import { pmsConfig } from "./api";
import * as fromSlice from "./slice";

export const getPMSConfigSignatures =
  () => async (dispatch: any, getState: any) => {
    dispatch(fromSlice.fetchRequest());
    const response: any = await ApiClient.get({
      endpoint: pmsConfig,
      dispatch: dispatch,
      showLoader: true,
    });
    dispatch(fromSlice.fetchSuccess(response?.Data || []));
  };

export const savePMSConfigSignatures =
  (data: any) => async (dispatch: any, getState: any) => {
    dispatch(fromSlice.fetchRequest());
    const response: any = await ApiClient.post({
      endpoint: pmsConfig,
      dispatch: dispatch,
      showLoader: true,
      body: data,
    });
    return response;
  };
