import _ from "lodash";

export const camelCaseKeys = (object: any): any => {
  if (Array.isArray(object)) {
    return object.map(camelCaseKeys);
  }
  if (typeof object === "object") {
    const entries = Object.entries(object);
    return Object.fromEntries(entries.map(transfromEntry));
  }
  return object;

  function transfromEntry([k, v]: any[]) {
    return [_.camelCase(k), typeof v === "object" && v ? camelCaseKeys(v) : v];
  }
};
