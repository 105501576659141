// @ts-nocheck
import { GET_OVERTIME_REQUEST } from "actions/types";
import types from "constants/types";

const initialState = {
  overtimeRequest: [],
  overtimeShownColumns: [],
  showHideOvertimeColumnLoading: false,
  payrollPeriods: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_OVERTIME_REQUEST:
      return {
        ...state,
        overtimeRequest: action.payload,
      };
    case types.EXPORT_OVERTIME_REQUEST:
      return {
        ...state,
        overtimeExportList: action.payload,
      };
    case types.GET_OVERTIME_SHOWN_COLUMNS:
      return {
        ...state,
        overtimeShownColumns: action.payload,
      };
    case types.SHOW_HIDE_OVERTIME_COLUMN_REQUEST:
      return {
        ...state,
        showHideOvertimeColumnLoading: action.payload,
      };
    case types.GET_PAYROLL_PERIODS:
      return {
        ...state,
        payrollPeriods: action.payload,
      };
    default:
      return state;
  }
}
