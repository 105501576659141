// @ts-nocheck 

import * as fromTypes from "./types"
import { Action, Reducer } from "redux";

export interface GlobalUiState {
    isSidebarOpen: boolean;
}

const initialState: GlobalUiState = {
    isSidebarOpen: true,
};

const globalUiReducer: Reducer<GlobalUiState> = (
    state: GlobalUiState,
    incomingAction: Action
): GlobalUiState => {
    if (state === undefined) {
        return initialState;
    }
    const action = incomingAction as LoginAction;
    switch (action.type) {
        case fromTypes.TOGGLE_SIDEBAR:
            return {
                ...state,
                isSidebarOpen: !state.isSidebarOpen,
            };

        default:
            return state;
    }
};

export default globalUiReducer
