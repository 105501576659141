// @ts-nocheck 
import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Icons from "components/icons";
import Button from "components/button";
import {MdAdd} from "react-icons/md";

import {
  addCourseCategory,
  updateCourseCategory,
  deleteCourseCategory,
  getCourseCategories,
} from "../helpers/action";
import CourseCategoryTable from "./table";

import "./style.scss";
import Modal from "components/modal";
import CourseCategoryForm from "./form";

const initialData = {
  Id: 0,
  Name: "",
};

const CourseCategories = () => {
  const [editViewData, setEditViewData] = useState(initialData);
  const [editView, setEditView] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [showDelModal, setShowDelModal] = useState({show: false, type: "normal"});

  const {courseCategories} = useSelector((state: any) => state.lmsReducer);

  const dispatch = useDispatch();

  const handleAddCategory = () => {
    setEditView(false);
    setEditViewData(initialData);
    setShowCategoryModal(true);
  };

  const handleCategoryEdit = (e, data) => {
    e.stopPropagation();
    setEditViewData(data);
    setShowCategoryModal(true);
    setEditView(true);
  };

  const handleCategoryDel = (e, data) => {
    e.stopPropagation();
    setEditViewData(data);
    setShowDelModal({...showDelModal, show: true, type: "confirm"});
  };

  const handleChange = (name, value) => {
    setEditViewData({...editViewData, [name]: value});
  };

  const delCategory = () => {
    dispatch(deleteCourseCategory(editViewData.Id));
  };

  const handleSubmit = () => {
    if (editView) {
      dispatch(updateCourseCategory(editViewData, setShowCategoryModal));
    } else {
      dispatch(addCourseCategory(editViewData, setShowCategoryModal));
    }
  };

  useEffect(()=>{
    dispatch(getCourseCategories())
  },[])

  return (
    <div className="course-categories">
      <div className="flex mb-20">
        <div>
          <span>Course Categories</span>
        </div>
        <div className="course-categories__header ml-20">
          <Button
            bg="primary"
            buttonType="icon-button"
            icon={<Icons name="Add" />}
            leftIcon={<MdAdd />}
            onClick={handleAddCategory}
            title="Add Category"
          />
        </div>
      </div>
      <CourseCategoryTable
        courseCategories={courseCategories}
        handleCategoryEdit={handleCategoryEdit}
        handleCategoryDel={handleCategoryDel}
      />
      {showCategoryModal && (
        <Modal open={showCategoryModal} onModalClose={() => setShowCategoryModal(false)}>
          <CourseCategoryForm
            editViewData={editViewData}
            handleSubmit={handleSubmit}
            closeForm={() => setShowCategoryModal(false)}
            editView={editView}
            handleChange={handleChange}
          />
        </Modal>
      )}
      {showDelModal.show && (
        <Modal
          title={"Course Category"}
          open={showDelModal.show}
          onModalClose={() =>
            setShowDelModal({...showDelModal, show: false, type: "normal"})
          }
          type={showDelModal.type}
          func={delCategory}
        >
          <></>
        </Modal>
      )}
    </div>
  );
};

export default CourseCategories;
