import * as fromPerformanceRolloutPreviewStore from "../performance-rollout-preview";
import * as fromCommentStore from "../performance-review-comments";

export const fetchRolloutPreviewDetailInitialPageData = (params: { rolloutId: number }) => async (dispatch: any) => {
  const rolloutPreviewResponse= await dispatch(fromPerformanceRolloutPreviewStore.PerformanceRolloutPreviewStore.fetchRolloutPreview(params));

  const  {Reviews} = rolloutPreviewResponse;
  const mappedReviewIds: string = Reviews.map((review: any) => review.ReviewId ).join(",") || "";

  await dispatch(fromCommentStore.PerformanceReviewCommentStore.fetchCommentsByReviewId({
    reviewId: mappedReviewIds
  }));
};

export const clearRolloutPreviewDetailInitialPageData = () => (dispatch: any) => {

};