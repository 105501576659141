import { createSlice } from "@reduxjs/toolkit";
import * as fromInterface from "../../../ts";

export interface ReviewFlowDropdownState {
    reviewFlowItems: any,
    reviewFlowStatus: fromInterface.loadingType;
    reviewFlowLoaded: boolean;

    reviewFlow360Items: any,
    reviewFlow360Status: fromInterface.loadingType;
    reviewFlow360Loaded: boolean;
}

const initialState: ReviewFlowDropdownState = {
    reviewFlowItems: [],
    reviewFlowStatus: fromInterface.STATUS.IDLE,
    reviewFlowLoaded: false,

    reviewFlow360Items: [],
    reviewFlow360Status: fromInterface.STATUS.IDLE,
    reviewFlow360Loaded: false,
};

const reviewFlowDropdownSlice = createSlice({
    name: "review flow dropdown",
    initialState,
    reducers: {
        fetchRequest: (state, isThreSixty) => {
            if (isThreSixty)
                state.reviewFlow360Status = fromInterface.STATUS.LOADING
            else
                state.reviewFlowStatus = fromInterface.STATUS.LOADING;
        },
        fetchReviewFlowSuccess: (state: any, action: any) => {
            const payload = action.payload
            state.reviewFlowItems = payload;
            state.reviewFlowStatus = fromInterface.STATUS.IDLE;
            state.reviewFlowLoaded = true
        },

        fetchReviewFlow360Success: (state: any, action: any) => {
            const payload = action.payload
            state.reviewFlow360Items = payload;
            state.reviewFlow360Status = fromInterface.STATUS.IDLE;
            state.reviewFlow360Loaded = true
        },
        clear: (state, isThreSixty) => {
            if (isThreSixty) {
                state.reviewFlow360Items = []
                state.reviewFlow360Status = fromInterface.STATUS.IDLE
                state.reviewFlow360Loaded = false
            }
            else {
                state.reviewFlowItems = []
                state.reviewFlowStatus = fromInterface.STATUS.IDLE
                state.reviewFlowLoaded = false
            }

        },
    },
});

export const {
    fetchRequest,
    fetchReviewFlowSuccess,
    fetchReviewFlow360Success,
    clear,
} = reviewFlowDropdownSlice.actions

export default reviewFlowDropdownSlice.reducer;
