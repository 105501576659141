
import React, { lazy } from "react";
import RouteEnum from "../../../constants/routeEnum";
import lmsLinks from "./lmsLinks";
// LMS
const LMS = lazy(() => import("../../../scenes/lms"));


const routes = (features) => [

    {
        path: RouteEnum.lmsRoutes,
        // exact:true,
        component: LMS,
        // title: "Request",
        feature: features.LMS,
        children: lmsLinks,
    },
]

export default routes;