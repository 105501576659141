import {
  GET_EMPLOYEE_DASHBOARD_DETAIL,
  IS_EMPLOYEE_DETAIL_LOADING,
  GET_EMPLOYEE_PROFILE_PENDING_REQUEST,
  POST_EMPLOYEE_OFFICE_CONTACT,
  POST_EMPLOYEE_HOME_CONTACT,
} from "actions/types";

const initialState = {
  pendingRequest: null,
  employeeDetail: null,
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case IS_EMPLOYEE_DETAIL_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_EMPLOYEE_DASHBOARD_DETAIL: {
      return {
        ...state,
        employeeDetail: action.payload,
      };
    }
    case GET_EMPLOYEE_PROFILE_PENDING_REQUEST: {
      return {
        ...state,
        pendingRequest: action.payload,
      };
    }
    // case POST_EMPLOYEE_OFFICE_CONTACT: {
    //   return {
    //     ...state,
    //     pendingRequest:
    //       state.pendingRequest && state.pendingRequest.OfficeContact
    //         ? {
    //             ...state.pendingRequest,
    //             OfficeContact: {
    //               ...state.pendingRequest.OfficeContact,
    //               NewData: action.payload?.data,
    //             },
    //           }
    //         : {
    //             ...state.pendingRequest,
    //             OfficeContact: {
    //               RequestId: action.payload?.RequestId,
    //               NewData: action.payload?.data,
    //             },
    //           },
    //   };
    // }
    default:
      return state;
  }
}
