import {
  Box,
  FormControl,
  FormHelperText,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";

import { Radio } from "@chakra-ui/react";
import merge from "lodash/merge";
import { Controller } from "react-hook-form";
import { resolveObjectValueByPath } from "../../../../helpers";
import FormLabel from "../form-label/form-label";
import FormErrorLable from "../form-error-text";

interface RadioGroupV2Props {
  name: string;
  errors?: any;
  control?: any;
  value?: any;
  options: any;
  ignoreControl?: boolean;
  required?: boolean;
  direction?: "column" | "row";
  onChange?: any;
  spacing?: any;
  rule?: any;
  errorMessage?: any;
  additionalActiveComponent?: any;
  isDisabled?: boolean;
}

export const RadioGroupV2 = (props: RadioGroupV2Props) => {
  let {
    name,
    errors,
    control,
    value,
    options,
    direction,
    onChange,
    required = false,
    ignoreControl = false,
    spacing,
    rule,
    errorMessage,
    additionalActiveComponent,
    ...rest
  } = props;

  const error =
    errorMessage || (errors && resolveObjectValueByPath(errors, name)?.message);

  let _rule = {
    required: {
      value: required,
      message: "Required",
    },
  };

  if (rule) {
    _rule = merge(_rule, rule);
  }

  return (
    <FormControl id={name} isRequired={false}>
      {!control || ignoreControl ? (
        <RadioGroup
          {...rest}
          value={value && (value.value || value)}
          onChange={(selectedValue: any) => {
            let completeValue = options.find(
              (f: any) => f.value.toString() === selectedValue.toString(),
            );

            onChange?.(completeValue);
          }}
        >
          <Stack direction={direction || "row"}>
            {options &&
              options.map((item: any, index: number) => (
                <React.Fragment key={index}>
                  {/* <Box> */}
                  <Radio
                    id={item.label}
                    colorScheme='blue'
                    key={index}
                    value={item.value}
                  >
                    <FormLabel htmlFor={item.label} label={item.label} />
                  </Radio>
                  {/* <span style={{ marginRight: "2rem" }} /> */}
                  {/* </Box> */}
                  <Box paddingLeft='25px' mt='0' width='100%'>
                    {additionalActiveComponent &&
                      value === item.value &&
                      additionalActiveComponent}
                  </Box>
                </React.Fragment>
              ))}
          </Stack>
        </RadioGroup>
      ) : (
        <Controller
          control={control}
          name={name}
          rules={_rule}
          render={(controllerProps: any) => {
            const {
              field: { onChange: _onChange, value: _value },
            } = controllerProps;

            return (
              <RadioGroup
                value={_value?.value + "" || _value + ""}
                onChange={(selectedValue: any) => {
                  let completeValue = options.find(
                    (f: any) => f.value.toString() === selectedValue.toString(),
                  );
                  _onChange?.(completeValue);
                }}
                {...rest}
              >
                <Stack direction={direction || "row"}>
                  {options &&
                    options.map((item: any, index: number) => (
                      <React.Fragment key={index}>
                        <Radio
                          id={item.label}
                          display='flex'
                          colorScheme='blue'
                          key={`${name}-${index}`}
                          value={item.value + ""}
                        >
                          <FormLabel htmlFor={item.label} label={item.label} />
                        </Radio>
                        {/* <span style={{ marginRight: "2rem" }} /> */}
                      </React.Fragment>
                    ))}
                </Stack>
              </RadioGroup>
            );
          }}
        />
      )}
      <FormErrorLable py='2px' px={1} fontSize='14px' error={error} />
    </FormControl>
  );
};

export default RadioGroupV2;
