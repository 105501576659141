import * as fromInterface from "../../../ts";
import { ApiClient } from "../../../network/apiClient";
import * as fromSlice from "./slice";
import * as select from "./selectors"

import { reviewCycle, reviewCycleList } from "./api";

export const fetchActiveReviewCycle = () => async (dispatch: any, getState: any) => {


    dispatch(fromSlice.fetchRequest_Active())
    const state = getState()
    const { pageIndex, pageSize } = select.selectActiveReviewCycleItemFilter(state)
  
    const body = {
        pageIndex, pageSize, isArchived: false
    }
    const response: any = await ApiClient.post({
        endpoint: reviewCycleList,
        body: body,
        dispatch: dispatch
    })

    if (response?.Status) {
        dispatch(fromSlice.fetchSucces_Active(response.Data))
    }


};

export const fetchArchivedReviewCycle = () => async (dispatch: any, getState: any) => {
    dispatch(fromSlice.fetchRequest_Archived())
    const state = getState()
    const { pageIndex, pageSize } = select.selectArchivedReviewCycleItemFilter(state)
    const body = {
        pageIndex, pageSize, isArchived: true
    }
    const response: any = await ApiClient.post({
        endpoint: reviewCycleList,
        body: body,
        dispatch: dispatch
    })

    if (response?.Status) {
        dispatch(fromSlice.fetchSucces_Archived(response.Data))
    }
};

export const saveReviewCycle = (data: any) => async (dispatch: any, getState: any) => {
    const param = {
        endpoint: reviewCycle,
        body: data,
        dispatch: dispatch
    }
    const response = await (data.Id ? ApiClient.put(param) : ApiClient.post(param))
    return response.Status
}

export const deleteReviewCycle = (id: number) => async (dispatch: any) => {
    const param = {
        endpoint: `${reviewCycle}/${id}`,
        dispatch: dispatch
    }
    const response: any = await ApiClient.remove(param)
    return response.Status
}
export const clearReviewCycle = () => (dispatch: any) => {
    dispatch(fromSlice.clear())
}




export const updateActiveReviewCycleFilterAndFetch = ({ pageIndex, pageSize }: any) => (dispatch: any, getState: any) => {
    dispatch(fromSlice.updateFilter_Active({ pageIndex, pageSize }))
    dispatch(fetchActiveReviewCycle())
}

export const updateArchivedReviewCycleFilterAndFetch = ({ pageIndex, pageSize }: any) => (dispatch: any, getState: any) => {
    dispatch(fromSlice.updateFilter_Archived({ pageIndex, pageSize }))
    dispatch(fetchArchivedReviewCycle())
}