import { z } from "zod";

export const PerformanceFormReviewSchema = z.object({
  id: z.number(),
  performanceFormId: z.number().optional(),
  performanceRolloutId: z.number().optional(),
  peerAffinityLevelId: z.number().optional(),
  step: z.number().optional(),
  reviewDetails: z.array(
    z.object({
      sectionId: z.number(),
      sectionContentId: z.number(),
      sectionContentOptionId: z.number().optional(),
      text: z.string().optional(),
      ratingScaleId: z.number().optional(),
      ratingScaleOptionId: z.number().optional(),
      ratingScore: z.number().optional(),
      goalAction: z.object({
        goalDatabaseId: z.number(),
        target: z.number(),
        targetText: z.string(),
        achievement: z.number(),
        achievementText: z.string(),
        weight: z.number(),
      }).optional(),
      ksa: z.object({
        ksaid: z.number(),
        rolloutKSADetailId: z.number(),
        ratingName: z.string(),
        ratingScore: z.number(),
        expected: z.number(),
      }).optional(),
    }),
  ),
  comments: z.array(
    z.object({
      sectionId: z.number(),
      sectionContentId: z.number(),
      sectionContentOptionId: z.number().optional(),
      goalDatabaseId: z.number().optional(),
      comment: z.string(),
    }),
  ).optional(),
  evaluation: z.object({
    ratingScaleId: z.number(),
    ratingScaleOptionId: z.number(),
    comments: z.string(),
  }).optional(),
  signature: z.boolean().optional(),
  status: z.number().optional(),
  developmentPlan: z.object({
    id: z.number(),
    description: z.string(),
    justification: z.string(),
    targetDate: z.string(),
    responsibleForExecutingPlans: z.array(z.string()),
    other: z.string(),
    developmentAreas: z.array(z.object({ id: z.number(), name: z.string() })),
  }).optional(),
});

export type IPerformanceFormReview = z.infer<typeof PerformanceFormReviewSchema>