import { ApiClient } from "../../../network/apiClient";
import { activeFeatureFlag } from "./api";
import * as fromSlice from "./slice";

export const fetchActiveFeatureFlag =
  (page?: any) => async (dispatch: any, getState: any) => {
    dispatch(fromSlice.fetchRequest());

    const response: any = await ApiClient.get({
      endpoint: activeFeatureFlag,
      dispatch: dispatch,
    });

    dispatch(fromSlice.fetchSuccess(response?.Data));

    return response;
  };

export const clearActiveFeatureFlag = () => (dispatch: any) => {
  dispatch(fromSlice.clear());
};
