/**
 * PSEUDO
 *
 * STEPS = []
 *
 * IF SUMMARY
 *  STEPS.PUSH(SUMMARY)
 *
 * IF AFFINITY LEVEL
 *  STEPS.PUSH(AFFINITY)
 *
 * LOOP
 *  STEPS.PUSH(SECTION BLOCK)
 *
 * IF EVALUATION
 *  STEPS.PUSH(SECTION BLOCK)
 *
 * @deprecated
 * @param reviewDetail
 * @returns
 */

import * as fromInterface from '../../../../ts';

const data = {
  Scores: [
    {
      SectionId: 568,
      Section: 'Free Text',
      SectionWeight: 100,
      SelfScore: 100,
      ManagerScore: null,
      PeerScore: 50,
      CombinedScore: null,
    },
  ],
  ScoreSummary: {
    MarkedScores: {
      Title: 'Marked Score',
      TotalWeight: 100,
      SelfScore: 100,
      ManagerScore: 0,
      PeerScore: 50,
      CombinedScore: 0,
    },
    ConvertedScores: {
      Title: 'Converted Score',
      SelfScore: 100,
      ManagerScore: null,
      PeerScore: 100,
      Total: 100,
    },
    ReviewerWeights: {
      Title: 'Reviewer Weight',
      SelfReviewerWeight: 30,
      ManagerReviewerWeight: 60,
      PeerReviewerWeight: 10,
    },
    WeightedScoreResults: {
      Title: 'Weighted Score',
      SelfScore: 30,
      ManagerScore: null,
      PeerScore: 10,
      Total: 40,
      Remarks:
        'Work performance consistently meets the expectation for the position.',
    },
  },
  Evaluation: null,
  DevelopmentArea: null,
  ReviewInfo: {
    IsSignatureRequired: false,
    IncludeEmployeeDevelopmentPlanSection: false,
    ShowOverallPerformanceRatingSection: false,
    DisplayEvaluationAndDevelopmentSection: true,
    DisplayScoreSummary: true,
    CurrentStep: 3,
    TotalSteps: 3,
    SubmittedOn: null,
    ReviewId: 824,
    SelfReviewId: 823,
    PerformanceRolloutId: 212,
    PerformanceFormId: 194,
    EmployeeId: 2,
    EmployeeName: 'Gajab Man Singh',
    UrlPhoto: '\\uploads\\acf110ca-98c3-415f-bb68-efae7161937b.jpeg',
    ReviewType: 'Manager',
    FormName: 'Flag on/off form',
    ManagerId: 12071,
    ManagerName: 'Adarsh Mishra',
    ReviewDeadline: '2023-09-06T00:00:00',
    CycleName: 'April to September 2023',
    GoalType: 1,
    Signature: false,
    SignatureMessage:
      'My signature indicates that I have rated my Direct Report fairly to the best of my knowledge without being partial to any of my subordinates. <br/><br/>I further confirm that the subordinate has worked with me for a reasonable period for me to access his skills, abilities, behavior, performance and potential. And where I have not worked with the subordinate for reasonable time; I have made reasonable efforts to know the subordinate so that I can provide justified rating and review.',
    ConsentByEmployee: false,
    ConsentMessage:
      'My signature indicates that I met with my supervisor to review and discuss this assessment. It does not necessarily mean that I am in agreement with its contents.<br/><br/>I am writing this to check the line indentation.',
    ConsentOn: null,
    DueDays: 'Due in 8 days',
    Status: 1,
    StatusName: 'PENDING',
    PeerAffinityLevelId: null,
    PeerAffinityLevelName: null,
    RatingScale: {
      value: 37,
      label: '4 Point (Excellent, Better, Good, poor, NA)',
    },
    RatingScaleOptions: [
      {
        score: 1,
        value: 274,
        label: 'Excellent',
      },
      {
        score: 2,
        value: 275,
        label: 'Better',
      },
      {
        score: 3,
        value: 276,
        label: 'Good',
      },
      {
        score: 4,
        value: 277,
        label: 'poor',
      },
      {
        score: 0,
        value: 278,
        label: 'NA',
      },
    ],
    OverallPerfomanceRatingScale: {
      value: 37,
      label: '4 Point (Excellent, Better, Good, poor, NA)',
    },
    OverallPerfomanceRatingScaleOptions: [
      {
        score: 1,
        value: 274,
        label: 'Excellent',
      },
      {
        score: 2,
        value: 275,
        label: 'Better',
      },
      {
        score: 3,
        value: 276,
        label: 'Good',
      },
      {
        score: 4,
        value: 277,
        label: 'poor',
      },
      {
        score: 0,
        value: 278,
        label: 'NA',
      },
    ],
  },
  Sections: [
    {
      Id: 567,
      PerformanceFormId: 194,
      SectionContentType: 3,
      SectionContentTypeName: 'Text Block',
      SectionOrder: 0,
      SectionName: 'This is radio nepal',
      IntroText: 'Special instruction',
      RatingScaleId: 37,
      RatingScaleName: '4 Point (Excellent, Better, Good, poor, NA)',
      SectionWeight: 0,
      ToBeAnsweredBy: '',
      AssessmentType: 0,
      AllowSelfMarking: false,
      ImportGoals: null,
      ImportKSA: null,
      Contents: [
        {
          Id: 677,
          SectionId: 567,
          ContentWeight: 0,
          TextBlock: '<p>This is some text block content</p>',
          Title: '',
          DoNotMark: true,
          AllowComments: false,
          AllowCommentForEachOptions: false,
          SectionContentOrder: 0,
          RatingScaleOptionId: null,
          RatingScaleOptionName: null,
          RatingScore: null,
          Text: null,
          TotalMarks: null,
          Options: [],
          KSAOptions: [],
          GoalActionOptions: [],
        },
      ],
      RatingScaleOptions: [
        {
          score: 1,
          value: 274,
          label: 'Excellent',
        },
        {
          score: 2,
          value: 275,
          label: 'Better',
        },
        {
          score: 3,
          value: 276,
          label: 'Good',
        },
        {
          score: 4,
          value: 277,
          label: 'poor',
        },
        {
          score: 0,
          value: 278,
          label: 'NA',
        },
      ],
    },
    {
      Id: 568,
      PerformanceFormId: 194,
      SectionContentType: 2,
      SectionContentTypeName: 'Free Text Question',
      SectionOrder: 0,
      SectionName: 'Free Text',
      IntroText: 'This is special instructions',
      RatingScaleId: 37,
      RatingScaleName: '4 Point (Excellent, Better, Good, poor, NA)',
      SectionWeight: 0,
      ToBeAnsweredBy: 'Manager,Self,Peers',
      AssessmentType: 1,
      AllowSelfMarking: true,
      ImportGoals: null,
      ImportKSA: null,
      Contents: [
        {
          Id: 678,
          SectionId: 568,
          ContentWeight: null,
          TextBlock: '',
          Title: 'Who am i?',
          DoNotMark: false,
          AllowComments: false,
          AllowCommentForEachOptions: false,
          SectionContentOrder: 0,
          RatingScaleOptionId: null,
          RatingScaleOptionName: null,
          RatingScore: 10,
          Text: 'asd',
          TotalMarks: 10,
          Options: [],
          KSAOptions: [],
          GoalActionOptions: [],
        },
      ],
      RatingScaleOptions: [
        {
          score: 1,
          value: 274,
          label: 'Excellent',
        },
        {
          score: 2,
          value: 275,
          label: 'Better',
        },
        {
          score: 3,
          value: 276,
          label: 'Good',
        },
        {
          score: 4,
          value: 277,
          label: 'poor',
        },
        {
          score: 0,
          value: 278,
          label: 'NA',
        },
      ],
    },
  ],
};

const getSummarySteps = (reviewDetail: any) => {
  const { ReviewInfo, Sections } = reviewDetail;
  const { CurrentStep, DisplayScoreSummary } = ReviewInfo;

  let stepLength = Sections?.length;

  // adds summary detail
  return DisplayScoreSummary
    ? [
        {
          isBlock: false,
          isSummary: true,
          sectionContentType: 100,
          sectionId: 100,
          isEvaluation: false,
          isAffinity: false,
          isOverallRatingAndDevelopmentPlanSection: false,

          displayNumber: 0,
          index: 0,
          actualCurrentStep: 0,
          isActive: false,
          isSelected: false,
          isDisabled: false,
          isLastStep: false,
          isFirstStep: false,
        },
      ]
    : [];
};

const getAffinitySteps = (reviewDetail: any) => {
  const { ReviewInfo, Sections } = reviewDetail;
  const { CurrentStep, DisplayScoreSummary, ReviewType } = ReviewInfo;

  let stepLength = Sections?.length;

  let _CurrentStep =
    CurrentStep > stepLength - 1 ? stepLength - 1 : CurrentStep;

  const isAffinityLevelStepRequired =
    !DisplayScoreSummary && ReviewType === 'Peers';

  // adds summary detail
  return isAffinityLevelStepRequired
    ? [
        {
          isBlock: false,
          isSummary: false,
          sectionContentType: 102,
          sectionId: 102,
          isAffinity: true,
          isEvaluation: false,
          isOverallRatingAndDevelopmentPlanSection: false,

          displayNumber: 0,
          index: 0,
          actualCurrentStep: 0,
          isActive: false,
          isSelected: false,
          isDisabled: false,
          isLastStep: false,
          isFirstStep: false,
        },
      ]
    : [];
};

const getSectionSteps = (reviewDetail: any) => {
  const { ReviewInfo, Sections } = reviewDetail;
  const { CurrentStep } = ReviewInfo;

  let stepLength = Sections?.length;

  let _CurrentStep =
    CurrentStep > stepLength - 1 ? stepLength - 1 : CurrentStep;

  let indexToIncrement = 0;

  return (
    Array.from({
      length: stepLength,
    })?.map((item: any, idx: number) => {
      const section = Sections[idx];

      return {
        isBlock: true,
        isSummary: false,
        sectionContentType: section.SectionContentType,
        sectionId: section?.Id,
        isAffinity: false,
        isEvaluation: false,
        isOverallRatingAndDevelopmentPlanSection: false,

        displayNumber: 0,
        index: 0,
        actualCurrentStep: 0,
        isActive: false,
        isSelected: false,
        isDisabled: false,
        isLastStep: false,
        isFirstStep: false,
      };
    }) || []
  );
};
const getOverallEvaluationRatingSteps = (reviewDetail: any) => {
  const { ReviewInfo } = reviewDetail;
  const {
    DisplayEvaluationAndDevelopmentSection,
    IsSignatureRequired,
    ShowOverallPerformanceRatingSection,
    IncludeEmployeeDevelopmentPlanSection,
  } = ReviewInfo;

  // add overall evaluation section
  const showDevelopmentPlanAndOverallEvaluationSection =
    DisplayEvaluationAndDevelopmentSection &&
    (ShowOverallPerformanceRatingSection ||
      IncludeEmployeeDevelopmentPlanSection);

  const showLastSection =
    showDevelopmentPlanAndOverallEvaluationSection || IsSignatureRequired;

  return showLastSection
    ? [
        {
          isBlock: false,
          isSummary: false,
          sectionContentType: 101,
          sectionId: 101,
          isAffinity: false,
          isEvaluation: false,
          isOverallRatingAndDevelopmentPlanSection: true,

          displayNumber: 0,
          index: 0,
          actualCurrentStep: 0,
          isActive: false,
          isSelected: false,
          isDisabled: false,
          isLastStep: false,
          isFirstStep: false,
        },
      ]
    : [];
};

export const createSteps = (reviewDetail: any): any => {
  let stepsToCreate: fromInterface.Step[] = [];
  stepsToCreate = [...stepsToCreate, ...getSummarySteps(reviewDetail)];
  stepsToCreate = [...stepsToCreate, ...getAffinitySteps(reviewDetail)];
  stepsToCreate = [...stepsToCreate, ...getSectionSteps(reviewDetail)];
  stepsToCreate = [
    ...stepsToCreate,
    ...getOverallEvaluationRatingSteps(reviewDetail),
  ];

  const { ReviewInfo } = reviewDetail;
  const { CurrentStep } = ReviewInfo;

  let stepLength = stepsToCreate?.length;

  let _CurrentStep =
    CurrentStep > stepLength - 1 ? stepLength - 1 : CurrentStep;

  const mapped = stepsToCreate.map((step: fromInterface.Step, idx: number) => {
    const isActive = idx <= _CurrentStep;
    const isDisabled = !isActive;
    const isSelected = idx === _CurrentStep;
    const isLastStep = idx === stepLength - 1;
    const isFirstStep = idx === 0;
    const actualCurrentStep = _CurrentStep;

    return {
      ...step,
      index: idx,
      displayNumber: idx + 1,
      actualCurrentStep,
      isActive,
      isSelected,
      isDisabled,
      isLastStep,
      isFirstStep,
    };
  });

  console.table(mapped);
  return {
    newSteps: mapped,
    currentStep: { ...mapped[_CurrentStep] },
  };
};

// const print = (data: any) => {
//   return JSON.stringify(data, null, 2);
// };
// createSteps(data);
// print();
