import * as fromSlice from "./slice";

export const selectCurrentPerformanceScoreFilterSlice = (state: any): fromSlice.PerformanceScoreFilterState => {
    return state.dataStore.performanceScoreFilter;
};

export const selectCurrentPerformanceScoreFilter = (state: any): fromSlice.PerformanceScoreFilterState => {
    return selectCurrentPerformanceScoreFilterSlice(state);
};

