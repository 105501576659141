// @ts-nocheck 



export const contentTypes = {
    text: { title: 'TEXT', id: 1 },
    image: { title: 'IMAGE', id: 2 },
    video: { title: 'VIDEO', id: 3 },
    video_link: { title: 'VIDEO_LINK', id: 4 },
    pdf: { title: 'PDF', id: 5 },
    attachment: { title: 'ATTACHMENT', id: 6 },
    questions: 'QUESTIONS',
}


export enum radioType {
    normal = "normal",
    yes = "yes",
    true = "true",
}

export enum MyTeamTab {
    TeamMembers = "Team Members",
    OnLeave = "On Leave",
    Absent = "Absent",
    BirthDays = "Birth Days",
}
