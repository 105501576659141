import * as fromSlice from "./slice"
import * as fromInterface from "../../../ts"
import { ApiClient } from "../../../network/apiClient"
import { PEER_AFFINITY_LEVEL, PEER_AFFINITY_LEVEL_LIST } from "./api"

export const clearPeerAffinityLevelScore = (): any => async (dispatch: any) => {
  dispatch(fromSlice.clear())
}

export const fetchPeerAffinityLevel = () => async (dispatch: any) => {
  dispatch(fromSlice.fetchRequest())

  const response: fromInterface.ResponseWrapper<any> = await ApiClient.get<any>(
    {
      endpoint: PEER_AFFINITY_LEVEL_LIST,
      dispatch: dispatch,
    },
  )
  const { Data, Status } = response

  if (Status) {
    dispatch(fromSlice.fetchSuccess(Data))
  }
}

export const savePeerAffinityLevel =
  (data?: any): any =>
  async (dispatch: any) => {
    dispatch(fromSlice.fetchRequest())
    const param = {
      endpoint: PEER_AFFINITY_LEVEL,
      body: data,
      dispatch: dispatch,
    }
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.post<any>(param)
    const { Status } = response

    if (Status) {
      dispatch(fetchPeerAffinityLevel())
    }
    dispatch(fromSlice.fetchComplete())
    return Status
  }
