import { ApiClient } from "../../network/apiClient";
import * as fromInterface from "../../ts";
import {
  PERSONAL_INFO_EMPLOYEE_DETAILS,
  PERSONAL_INFO_HOME_CONTACT,
  PERSONAL_INFO_OFFICE_CONTACT,
  PERSONAL_INFO_PENDING_REQUESTS,
  PERSONAL_INFO_PERMANENT_ADDRESS,
  PERSONAL_INFO_POST_EMPLOYEE_PERSONAL_INFO,
} from "./api";
import * as fromSlice from "./slice";
import * as fromSelector from "./selectors";

export const fetchEmployeeDetails =
  (): any => async (dispatch: any, getState: any) => {
    const state = getState();
    dispatch(fromSlice.fetchEmployeeDetailsRequest());

    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.get<any>({
        endpoint: PERSONAL_INFO_EMPLOYEE_DETAILS,
        dispatch,
      });

    const { Data, Status } = response;
    if (Status) {
      dispatch(fromSlice.fetchEmployeeDetailsSuccess(Data));
    }
    return fromSelector.selectEmployeeDetails(state);
  };

export const fetchPendingRequests =
  (): any => async (dispatch: any, getState: any) => {
    const state = getState();
    dispatch(fromSlice.fetchPendingRequestsRequest());

    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.get<any>({
        endpoint: PERSONAL_INFO_PENDING_REQUESTS,
        dispatch,
      });

    const { Data, Status } = response;
    if (Status) {
      dispatch(fromSlice.fetchPendingRequestsSuccess(Data));
    }
    return fromSelector.selectPendingRequests(state);
  };

export const saveEmployeePersonalInfo =
  (data: any, onModalClose: any) => async (dispatch: any) => {
    dispatch(fromSlice.saveEmployeePersonalInfoRequest());
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.post<any>({
        endpoint: PERSONAL_INFO_POST_EMPLOYEE_PERSONAL_INFO,
        body: data,
        dispatch,
      });
    const { Status } = response;
    if (Status) {
      dispatch(fetchPendingRequests());
      onModalClose();
    }
    dispatch(fromSlice.saveEmployeePersonalInfoSuccess());
  };

export const saveEmployeeOfficeContact =
  (data: any, onModalClose: any) => async (dispatch: any) => {
    dispatch(fromSlice.saveEmployeePersonalInfoRequest());
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.post<any>({
        endpoint: PERSONAL_INFO_OFFICE_CONTACT,
        body: data,
        dispatch,
      });
    const { Status } = response;
    if (Status) {
      dispatch(fetchPendingRequests());
      onModalClose();
    }
    dispatch(fromSlice.saveEmployeePersonalInfoSuccess());
  };

export const saveEmployeeHomeContact =
  (data: any, onModalClose: any) => async (dispatch: any) => {
    dispatch(fromSlice.saveEmployeePersonalInfoRequest());
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.post<any>({
        endpoint: PERSONAL_INFO_HOME_CONTACT,
        body: data,
        dispatch,
      });
    const { Status } = response;
    if (Status) {
      dispatch(fetchPendingRequests());
      onModalClose();
    }
    dispatch(fromSlice.saveEmployeePersonalInfoSuccess());
  };

export const saveEmployeePermanentAddress =
  (data: any, onModalClose: any) => async (dispatch: any) => {
    dispatch(fromSlice.saveEmployeePersonalInfoRequest());
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.post<any>({
        endpoint: PERSONAL_INFO_PERMANENT_ADDRESS,
        body: data,
        dispatch,
      });
    const { Status } = response;
    if (Status) {
      dispatch(fetchPendingRequests());
      onModalClose();
    }
    dispatch(fromSlice.saveEmployeePersonalInfoSuccess());
  };

export const saveEmployeeContactChangeRequest =
  (data: any, onModalClose: any, endpoint: string) => async (dispatch: any) => {
    dispatch(fromSlice.saveEmployeePersonalInfoRequest());
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.post<any>({
        endpoint,
        body: data,
        dispatch,
      });
    const { Status } = response;
    if (Status) {
      dispatch(fetchPendingRequests());
      onModalClose();
    }
    dispatch(fromSlice.saveEmployeePersonalInfoSuccess());
  };
