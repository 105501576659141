import axios from "helpers/axiosinstance";
import {
  GET_BRANCHES,
  BRANCH_SELECT_LOADING,
  GET_EMPLOYEES,
  EMPLOYEE_SELECT_LOADING,
  GET_DEPARTMENTS,
  SUB_DEPARTMENT_SELECT_LOADING,
  GET_SUB_DEPARTMENTS,
  GET_LEAVE_TYPES,
  GET_SALARY_TYPES,
  SALARY_TYPES_SELECT_LOADING,
  LEAVE_TYPES_SELECT_LOADING,
  DEPARTMENT_SELECT_LOADING,
  GET_LOCATION,
  LOCATION_SELECT_LOADING,
  GET_GENDERS,
  GENDER_SELECT_LOADING,
  GET_MARITAL_STATUS,
  MARITAL_STATUS_SELECT_LOADING,
  GET_EMPLOYEE_STATES,
  LEVELS_SELECT_LOADING,
  GET_LEVELS,
  EMPLOYEE_STATES_SELECT_LOADING,
  DESIGNATION_SELECT_LOADING,
  GET_DESIGNATION,
  GET_PROVINCE_LIST,
  GET_JOB_STATUS_SELECT,
  GET_EMPLOYEE_LIST_SEARCH,
  JOB_STATUS_SELECT_LOADING,
} from "actions/types";
import isEmpty from "isEmpty";
import { toastMessage } from "actions/validateAction";
import dispatchError from "helpers/dispatchError";
import { loadingHeaders } from "helpers/getHeaders";

export const getBranches = () => dispatch => {
  dispatch({ type: BRANCH_SELECT_LOADING });

  axios
    // .get(`/v1/branches`,)
    // .get(`/v1/payroll/Shared/branches`)
    .get(`/v1/common/branches`)
    .then(res => {
      //toastMessage(dispatch,res);
      dispatch({
        type: GET_BRANCHES,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatchError(err, dispatch, GET_BRANCHES, "Cannot Get Branches");
      // dispatch({
      //   type: GET_BRANCHES,
      //   payload: null
      // })
    });
};

export const getEmployees = text => async dispatch => {
  dispatch({ type: EMPLOYEE_SELECT_LOADING });
  let data = !isEmpty(text) ? `?searchTerm=${text}` : "";
  try {
    const res = await axios.get(`/v1/common/employees${data}`);

    if (res?.status === 200) {
      dispatch({
        type: GET_EMPLOYEE_LIST_SEARCH,
        payload: res.data,
      });
      return res;
    } else {
      toastMessage(dispatch, res);
    }
  } catch (err) {
    dispatchError(
      err,
      dispatch,
      GET_EMPLOYEE_LIST_SEARCH,
      "Cannot Get Employees",
    );
    // dispatch({
    //   type: GET_EMPLOYEES,
    //   payload: null
    // })
  }
};

export const getDepartments = () => dispatch => {
  dispatch({ type: DEPARTMENT_SELECT_LOADING });

  axios
    // .get(`/v1/branches`,)
    .get(`/v1/common/departments`)
    .then(res => {
      dispatch({
        type: GET_DEPARTMENTS,
        payload: res.data,
      });
      toastMessage(dispatch, res);
    })
    .catch(err => {
      dispatchError(err, dispatch, GET_DEPARTMENTS, "Cannot Get Departments");
      // dispatch({
      //   type: GET_DEPARTMENTS,
      //   payload: null
      // })
    });
};

export const getSubDepartments = () => dispatch => {
  dispatch({ type: SUB_DEPARTMENT_SELECT_LOADING });

  axios
    // .get(`/v1/branches`,)
    .get(`/v1/common/sub-departments`)
    .then(res => {
      dispatch({
        type: GET_SUB_DEPARTMENTS,
        payload: res.data,
      });
      toastMessage(dispatch, res);
    })
    .catch(err => {
      dispatchError(
        err,
        dispatch,
        GET_SUB_DEPARTMENTS,
        "Cannot Get Sub Departments",
      );
      // dispatch({
      //   type: GET_SUB_DEPARTMENTS,
      //   payload: null
      // })
    });
};

export const getLeaveTypes = () => dispatch => {
  dispatch({ type: LEAVE_TYPES_SELECT_LOADING });

  axios
    // .get(`/v1/branches`,)
    .get(`/v1/payroll/Shared/leave-types`)
    .then(res => {
      dispatch({
        type: GET_LEAVE_TYPES,
        payload: res.data,
      });
      toastMessage(dispatch, res);
    })
    .catch(err => {
      dispatchError(err, dispatch, GET_LEAVE_TYPES, "Cannot Get Leave Types");
      // dispatch({
      //   type: GET_LEAVE_TYPES,
      //   payload: null
      // })
    });
};

export const getSalaryTypes = () => dispatch => {
  dispatch({ type: SALARY_TYPES_SELECT_LOADING });
  axios
    .get(`/v1/payroll/shared/salary-types`)
    .then(res => {
      toastMessage(dispatch, res);
      dispatch({
        type: GET_SALARY_TYPES,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatchError(err, dispatch, GET_SALARY_TYPES, "Cannot Get Salary Types");
      // dispatch({
      //     type: GET_SALARY_TYPES,
      //     payload: null
      // });
    });
};

export const getEmployeeStates = () => dispatch => {
  dispatch({ type: EMPLOYEE_STATES_SELECT_LOADING });
  axios
    .get(`/v1/common/employee-states`)
    .then(res => {
      toastMessage(dispatch, res);
      dispatch({
        type: GET_EMPLOYEE_STATES,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatchError(
        err,
        dispatch,
        GET_EMPLOYEE_STATES,
        "Cannot Get Employee States",
      );
      // dispatch({
      //     type: GET_EMPLOYEE_STATES,
      //     payload: null
      // });
    });
};
export const getMaritalStatus = () => dispatch => {
  dispatch({ type: MARITAL_STATUS_SELECT_LOADING });
  axios
    .get(`/v1/common/marital-status`)
    .then(res => {
      toastMessage(dispatch, res);
      dispatch({
        type: GET_MARITAL_STATUS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatchError(
        err,
        dispatch,
        GET_MARITAL_STATUS,
        "Cannot Get Maritial Status",
      );
      // dispatch({
      //     type: GET_MARITAL_STATUS,
      //     payload: null
      // });
    });
};

export const getGenders = () => dispatch => {
  dispatch({ type: GENDER_SELECT_LOADING });
  axios
    .get(`/v1/common/genders`)
    .then(res => {
      toastMessage(dispatch, res);
      dispatch({
        type: GET_GENDERS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatchError(err, dispatch, GET_GENDERS, "Cannot Get Genders");
      // dispatch({
      //     type: GET_GENDERS,
      //     payload: null
      // });
    });
};

export const getDesignations = () => dispatch => {
  dispatch({ type: DESIGNATION_SELECT_LOADING });
  axios
    .get(`/v1/common/designations`)
    .then(res => {
      toastMessage(dispatch, res);
      dispatch({
        type: GET_DESIGNATION,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatchError(
        err,
        dispatch,
        GET_DESIGNATION,
        "Cannot Get Designation/Position",
      );
      // dispatch({
      //     type: GET_DESIGNATION,
      //     payload: null
      // });
    });
};

export const getLocation = () => dispatch => {
  dispatch({ type: LOCATION_SELECT_LOADING });
  axios
    .get(`/v1/common/locations`)
    .then(res => {
      toastMessage(dispatch, res);
      dispatch({
        type: GET_LOCATION,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatchError(err, dispatch, GET_LOCATION, "Cannot Get Locations");
      // dispatch({
      //     type: GET_LOCATION,
      //     payload: null
      // });
    });
};

export const getProvinceList = () => dispatch => {
  axios
    .get(`/v1/common/provinces`)
    .then(res => {
      toastMessage(dispatch, res);
      const data =
        res.data &&
        res.data.map(item => ({
          ...item,
          Value: item.ProvinceId,
          Text: item.Name,
        }));
      dispatch({
        type: GET_PROVINCE_LIST,
        payload: data,
      });
    })
    .catch(err => {
      dispatchError(
        err,
        dispatch,
        GET_PROVINCE_LIST,
        "Cannot Get Province list",
      );
    });
};

export const getLevels = () => dispatch => {
  dispatch({ type: LEVELS_SELECT_LOADING });
  axios
    .get(`/v1/common/levels`)
    .then(res => {
      toastMessage(dispatch, res);
      dispatch({
        type: GET_LEVELS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatchError(err, dispatch, GET_LEVELS, "Cannot Get Levels");
      // dispatch({
      //     type: GET_LEVELS,
      //     payload: null
      // });
    });
};

export const getJobStatus = () => dispatch => {
  dispatch({ type: JOB_STATUS_SELECT_LOADING });
  axios
    .get(`/v1/common/shared/status`)
    .then(res => {
      toastMessage(dispatch, res);
      dispatch({
        type: GET_JOB_STATUS_SELECT,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatchError(err, dispatch, GET_JOB_STATUS_SELECT, "Cannot Get Levels");
      // dispatch({
      //     type: GET_LEVELS,
      //     payload: null
      // });
    });
};
