// @ts-nocheck 
import React, {useEffect, useState} from "react";
import {useController, useForm} from "react-hook-form";
import {wholeFormInterface} from "ts/interfaces/formInterface";
import Form from "../form";

function FormItem(props: wholeFormInterface) {
  const {
    name,
    formName,
    label,
    multiple,
    error,
    rules,
    control,
    defaultValue = formName === "switch" ? false : "",
    setValue,
    ...inputProps
  } = props;
  const {field} = useController({name, control, rules, defaultValue});
  const isError = Boolean(error);
  const [extraProps, setExtraProps] = useState({});

  useEffect(() => {
    props.value !== undefined && setValue && setValue(name, props.value);
  }, [props.value]);

  useEffect(() => {
    if (formName === "switch") {
      setExtraProps({...extraProps, checked: field.value});
    }
    if (formName === "checkBoxList") {
      setExtraProps({...extraProps, selectedItems: field.value});
    }
    if (formName === "radioList") {
      setExtraProps({...extraProps, checked: field.value});
    }
    if (formName === "checkBoxGroup") {
      setExtraProps({...extraProps, checkedGroupValue: field.value});
    }
  }, [field.value]);

  const onChange = (name, value) => {
    if (formName === "checkgroup") {
      field.onChange(name.target.checked);
      return;
    }
    if (formName === "chipsinput") {
      field.onChange(multiple ? value : value?.value);
      return;
    }
    if (formName === "switch") {
      field.onChange(value === "" ? false : value);
      return;
    }
    if (formName === "checkBoxGroup") {
      setExtraProps({...extraProps, checkedGroupValue: value});
    }
    field.onChange(value);
  };

  return (
    <>
      <Form
        onChange={onChange}
        onBlur={field.onBlur}
        error={error}
        value={field.value}
        {...props}
        {...extraProps}
      />
    </>
  );
}

FormItem.defaultProps = {
  formName: "textinput",
  name: "",
  label: "",
  autoFocus: false,
  alignment: "vertical",
  validators: [],
};

export default FormItem;
