// @ts-nocheck 
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import Table from "components/customTable";

import Avatar from "react-avatar";
import {useSelector} from "react-redux";
import dayjs from "dayjs";
import Pagination from "components/common/pagination";
import {TaskBoards} from "common-links";
import {Link} from "react-router-dom";
import Icons from "components/icons";
import value from "environment";
import isEmpty from "helpers/isEmpty";
import {getFullURL} from "helpers/fullURL";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const EmpCell = (props) => {
  const {baseURL} = props;
  const {original} = props.row;
  return (
    <div className="assign-employee__emp">
      <div className="assign-employee__emp-image">
        <Avatar
          size="32px"
          round
          src={`${baseURL}${original.UrlPhoto}`}
          name={original.EmployeeName}
        />
      </div>
      <div className="assign-employee__emp-link">{original.EmployeeName}</div>
    </div>
  );
};

function TableView(props: any) {
  const ref = useRef(null);
  const {
    handleRowChange,
    handleIndexChange,
    pageIndex,
    pageSize,
    tableData,
    onRowSelect,
    selectedRows,
  } = props;
  const baseURL = getFullURL(useSelector, "");
  let columns = [
    {
      Header: "Employee Name",
      accessor: "Id",
      Cell: (props) => <EmpCell {...props} baseURL={baseURL} />,
      width: 200,
    },

    {
      Header: "Position",
      accessor: "Position",
      width: 160,
    },
    {
      Header: "Branch",
      accessor: "BranchName",
      width: 160,
    },
    {
      Header: "Department",
      accessor: "Department",
      width: 160,
    },
  ];
  const cols = useMemo(() => [...columns], []);
  const data = useMemo(() => tableData, [tableData]);
  return (
    <div>
      <div>
        <Table
          isRowSelect
          onRowSelect={onRowSelect}
          selectedRows={selectedRows}
          isFixedWidth
          data={data}
          columns={cols}
        />
      </div>
      <div className="">
        {tableData && tableData.length > 0 && (
          <Pagination
            handleRowChange={handleRowChange}
            handleIndexChange={handleIndexChange}
            pageIndex={pageIndex}
            rowsPerPage={pageSize}
            total={tableData[0].TotalRows}
          />
        )}
      </div>
    </div>
  );
}

export default TableView;
