import { z } from "zod"

export const employeeJournalSchema = z.object({
  Id: z.number(),
  Remark: z.string(),
  RemarkType: z.number(),
  RemarkTypeName: z.string(),
  CreatedByEmployeeName: z.string(),
  UrlPhoto: z.string(),
  UrlPhotoThumbnail: z.string(),
  CreatedDate: z.string()
})

export type IEmployeeJournal = z.infer<typeof employeeJournalSchema> | null;