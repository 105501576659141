//@ts-nocheck
import React from "react";
import RouteEnum from "../../../constants/routeEnum";
import requestLinks from "./requestLinks";

const DisciplinaryActionScreen = React.lazy(
  () => import("scenes/admin/disciplinaryAction"),
);
const DisciplinaryActions = React.lazy(
  () => import("scenes/admin/disciplinaryAction/form/disciplinaryActions"),
);
const DisciplinaryStages = React.lazy(
  () => import("scenes/admin/disciplinaryStages"),
);
const EmployeeOnBoarding = React.lazy(
  () => import("scenes/admin/employeeOnboarding"),
);
const CreateOnboardingEmployeePath = React.lazy(
  () => import("scenes/admin/employeeOnboarding/form"),
);
const EmployeeOnboardingAddEmployee = React.lazy(
  () => import("scenes/admin/employeeOnboarding/form/createNew"),
);
const EmployeeOnboardingPackages = React.lazy(
  () => import("scenes/admin/employeeOnboarding/subscPackages"),
);
const JobProfile = React.lazy(() => import("scenes/admin/jobProfile"));
const JobProfileFormScreen = React.lazy(
  () => import("scenes/admin/jobProfile/form/jobProfile/jobProfileForm"),
);
const KSALibrariesScreen = React.lazy(
  () => import("scenes/admin/jobProfile/ksaLibraries"),
);
const Dashboard = React.lazy(() => import("scenes/employee/dashboard"));
const OnBoardingDashboard = React.lazy(
  () => import("scenes/employee/onboarding"),
);
const Personal = React.lazy(() => import("scenes/employee/personal"));
const JobInformation = React.lazy(
  () => import("scenes/employee/jobInformation"),
);
const Payroll = React.lazy(() => import("scenes/employee/payroll"));
const Profile = React.lazy(() => import("scenes/employee/employeeProfile"));
const EmployeeListScreen = React.lazy(
  () => import("scenes/employee/employeeList"),
);
const EmployeeLeavesScreen = React.lazy(() => import("scenes/employee/leaves"));
const EmployeeTimeScreen = React.lazy(
  () => import("scenes/employee/time/attendance"),
);
const EmployeeOtherMenus = React.lazy(
  () => import("scenes/employee/otherMenus"),
);
const RequestScreen = React.lazy(() => import("scenes/employee/requests"));

const LeaveRequestDetailsScreen = React.lazy(
  () => import("scenes/employee/leaves/leaveDetails"),
);
const OverTimeRequestDetailsScreen = React.lazy(
  () => import("scenes/employee/overtimeRequest/overtimeDetails"),
);
const EmployeeTimeRequestScreen = React.lazy(
  () => import("scenes/employee/time/timeRequest"),
);

const Reports = React.lazy(() => import("scenes/employee/reports"));
const Notitifications = React.lazy(() => import("scenes/notifications"));
const TravelAdvanceReport = React.lazy(
  () => import("scenes/hr/reports/advanceReports"),
);

const HRDashboard = React.lazy(() => import("scenes/hr"));
const HRCommonTasks = React.lazy(() => import("scenes/hr/commonTasks"));
const PayrollDashboard = React.lazy(() => import("scenes/payroll/dashboard"));
const PayrollCommonLinks = React.lazy(
  () => import("scenes/payroll/commonTasks"),
);

// PAYROLL
const PayrollReports = React.lazy(() => import("scenes/payroll/reports"));
const PayrollSettings = React.lazy(() => import("scenes/payroll/settings"));
const PayrollIncomeCalculation = React.lazy(
  () => import("scenes/payroll/incomeCalculation"),
);
const PayrollLeaveAbsence = React.lazy(
  () => import("scenes/payroll/leaveAbsence"),
);
const PayrollReviewFinalize = React.lazy(
  () => import("scenes/payroll/reviewFinalize"),
);
const PayrollRFAdjustmentFinalize = React.lazy(
  () => import("scenes/payroll/rfAdjustment/rfAdjustment"),
);
const PayrollSalaryAdjustmentFinalize = React.lazy(
  () => import("scenes/payroll/salaryAdjustment"),
);
const PayrollStopPayment = React.lazy(
  () => import("scenes/payroll/stopPayment"),
);
const PayrollTaxAdjustment = React.lazy(
  () => import("scenes/payroll/taxAdjustment"),
);
const PayrollTaxCalculation = React.lazy(
  () => import("scenes/payroll/taxCalaulation"),
);
// const PayrollContinue = React.lazy(() => import("scenes/payroll/payroll"));


// Time Management
const MobileDeviceScreen = React.lazy(
  () => import("scenes/leaveTime/mobileDevice"),
);

// Admin Dashboards Comp
const JobDescriptionFormScreen = React.lazy(
  () =>
    import("scenes/admin/jobProfile/form/jobDescription/jobDescriptionForm"),
);
const HRSettings = React.lazy(() => import("scenes/hr/settings"));
const HRReports = React.lazy(() => import("scenes/hr/reports"));
const Settings = React.lazy(() => import("scenes/settings"));
const TravelSettings = React.lazy(() => import("scenes/settings/travel"));

// Overtime & Allowance

// Roles and Authorization
const Authorized = React.lazy(() => import("scenes/roles/authorized"));
const Roled = React.lazy(() => import("scenes/roles/roles"));
const DirectManagers = React.lazy(
  () => import("@rigotech/hrms-roles/src/directManagers"),
);
const Clusters = React.lazy(() => import("scenes/roles/clusters"));
const ReportsPage = React.lazy(
  () => import("scenes/employee/reports/pages/reports"),
);

// loan routes
const LoanManagement = React.lazy(() => import("scenes/loan"));
const AdminLoanManagement = React.lazy(
  () => import("scenes/loan/adminListing"),
);
const EmployeeLoanList = React.lazy(
  () => import("scenes/loan/employeeListing"),
);
const HomeLoanCreateScreen = React.lazy(
  () => import("scenes/loan/loanTypes/homeLoan"),
);
const HomeLoanViewScreen = React.lazy(
  () => import("scenes/loan/loanTypes/homeLoan/homeLoanView"),
);
const VehicleLoanViewScreen = React.lazy(
  () => import("scenes/loan/loanTypes/vehicleLoan/vehicleLoanView"),
);
const VehicleCreateScreen = React.lazy(
  () => import("scenes/loan/loanTypes/vehicleLoan"),
);
const StaffODCreateScreen = React.lazy(
  () => import("scenes/loan/loanTypes/staffODLoan"),
);
const StaffPersonalCreateScreen = React.lazy(
  () => import("scenes/loan/loanTypes/staffPersonalLoan"),
);

// Roster
// const Rosters = React.lazy(() => import("scenes/roster"));

// Access & ID Category
const AccessAndIdCategoryTemplateScreen = React.lazy(
  () => import("scenes/onboarding/accessAndIdCategoryTemplate"),
);
// Checklist
const ChecklistTemplateScreen = React.lazy(
  () => import("scenes/onboarding/checklistTemplate"),
);
// Message
const MessageTemplateScreen = React.lazy(
  () => import("scenes/onboarding/messageTemplate"),
);
// const Appraisals = React.lazy(() => import('erformanceAppraisals = React.lazy(() => import('scenes/appraisals/performanceAppraisals'))
const ViewAllMessages = React.lazy(
  () => import("scenes/employee/dashboard/messages/viewAll"),
);

const AllowanceRequest = React.lazy(() => import("scenes/hr/allowance"));
const OvertimeRequest = React.lazy(() => import("scenes/hr/overtime"));

const EmployeeDataChangeRequests = React.lazy(
  () => import("scenes/admin/employeeDataChangeRequests"),
);

const routes = features => [
  {
    path: RouteEnum.AuthorizedUser,
    exact: true,
    isAdmin: true,
    component: Authorized,
    title: "Authorized Users",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.Roles,
    exact: true,
    isAdmin: true,
    component: Roled,
    title: "Roles",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.ManagersLinks,
    exact: true,
    isAdmin: true,
    component: DirectManagers,
    title: "Direct Managers",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.ClustersLink,
    exact: true,
    isAdmin: true,
    component: Clusters,
    title: "Regions & Cluster",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.EmployeeDataChangeRequest,
    exact: true,
    isAdmin: true,
    component: EmployeeDataChangeRequests,
    title: "Employee Data Change Requests",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.OvertimeRequestPath,
    exact: true,
    isAdmin: true,
    component: OvertimeRequest,
    title: "Overtimes Request",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.AllowanceRequestPath,
    exact: true,
    isAdmin: true,
    component: AllowanceRequest,
    title: "Allowance Request",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.EmployeeDashBoard,
    exact: true,
    isEmployee: true,
    component: Dashboard,
    title: "Employee Dashboard",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.EmployeeViewAllMessage,
    exact: true,
    isEmployee: true,
    component: ViewAllMessages,
    title: "Employee Messages",
    feature: features.CoreHR,
  },

  {
    path: RouteEnum.EmployeeOnboarded,
    exact: true,
    isEmployee: true,
    component: OnBoardingDashboard,
    title: "Employee Dashboard",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.PersonalInfo,
    exact: true,
    isEmployee: true,
    component: Personal,
    title: "Personal Info",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.JobInfo,
    exact: true,
    isEmployee: true,
    component: JobInformation,
    title: "Job Info",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.EmployeeListPath,
    exact: true,
    isAdmin: true,
    component: EmployeeListScreen,
    title: "Employee List",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.EmployeeProfile + "/:id",
    exact: true,
    component: Profile,
    isAdmin: true,
    title: "Employee Profile",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.EmployeeReportsPath,
    exact: true,
    component: Reports,
    title: "Employee Reports",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.YearlyReportsPath + "/:reports",
    exact: true,
    component: ReportsPage,
    title: "Reports",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.EmployeePayroll,
    exact: true,
    component: Payroll,
    title: "Employee Payroll",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.EmployeeLeavesPath,
    exact: true,
    component: EmployeeLeavesScreen,
    title: "Leaves",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.EmployeeTimePath,
    exact: true,
    component: EmployeeTimeScreen,
    title: "Time",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.EmployeeTimeRequestPath,
    exact: true,
    component: EmployeeTimeRequestScreen,
    title: "Time Request",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.EmployeeOtherMenu,
    exact: true,
    component: EmployeeOtherMenus,
    title: "Menus",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.EmployeeRequest,
    // exact:true,
    component: RequestScreen,
    // title: "Request",
    feature: features.CoreHR,
    children: requestLinks,
  },
  // {
  //     path: RouteEnum.roosterRoutes,
  //     // exact:true,
  //     component: Rosters,
  //     // title: "Rosters",
  //     feature: features.CoreHR,
  //     children: roosterLinks,
  // },
  {
    path: RouteEnum.LeaveRequestDetailsPath,
    exact: true,
    component: LeaveRequestDetailsScreen,
    title: "Leave Details",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.OverTimeRequestDetailsPath,
    exact: true,
    component: OverTimeRequestDetailsScreen,
    title: "Overtime Details",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.NotificationsPath,
    exact: true,
    component: Notitifications,
    title: "Notitifactions",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.PayrollDashboardPath,
    exact: true,
    component: PayrollDashboard,
    title: "Payroll Dashboard",
    isAdmin: true,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.PayrollDashboardCommonTaskPath,
    exact: true,
    component: PayrollCommonLinks,
    title: "Payroll Common Task",
    isAdmin: true,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.PayrollDashboardReportsPath,
    exact: true,
    component: PayrollReports,
    title: "Payroll Reports",
    isAdmin: true,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.PayrollDashboardSettingsPath,
    exact: true,
    component: PayrollSettings,
    title: "Payroll Settings",
    isAdmin: true,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.PayrollContinuePath,
    exact: true,
    isAdmin: true,
    children: [
      {
        path: RouteEnum.PayrollIncomeCalculationPath,
        exact: true,
        component: PayrollIncomeCalculation,
        title: "Payroll Income Calculation",
        isAdmin: true,
        feature: features.CoreHR,
      },
      {
        path: RouteEnum.PayrollLeaveAbsencePath,
        exact: true,
        component: PayrollLeaveAbsence,
        title: "Payroll Leave Absence",
        isAdmin: true,
        feature: features.CoreHR,
      },
      {
        path: RouteEnum.PayrollReviewFinalizePath,
        exact: true,
        component: PayrollReviewFinalize,
        title: "Payroll Review Finalize Absence",
        isAdmin: true,
        feature: features.CoreHR,
      },
      {
        path: RouteEnum.PayrollRFAdjustmentPath,
        exact: true,
        component: PayrollRFAdjustmentFinalize,
        title: "Payroll RF Adjustment",
        isAdmin: true,
        feature: features.CoreHR,
      },
      {
        path: RouteEnum.PayrollSalaryAdjustmentPath,
        exact: true,
        component: PayrollSalaryAdjustmentFinalize,
        title: "Payroll Salary Adjustment",
        isAdmin: true,
        feature: features.CoreHR,
      },
      {
        path: RouteEnum.PayrollStopPaymentPath,
        exact: true,
        component: PayrollStopPayment,
        title: "Payroll Stop Payment",
        isAdmin: true,
        feature: features.CoreHR,
      },
      {
        path: RouteEnum.PayrollTaxAdjustmentPath,
        exact: true,
        component: PayrollTaxAdjustment,
        title: "Payroll Tax Adjustment",
        isAdmin: true,
        feature: features.CoreHR,
      },
      {
        path: RouteEnum.PayrollTaxCalculationPath,
        exact: true,
        component: PayrollTaxCalculation,
        title: "Payroll Tax Calculation",
        isAdmin: true,
        feature: features.CoreHR,
      },
    ],
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.Settings,
    exact: true,
    component: Settings,
    title: "HR Settings",
    isAdmin: true,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.TravelSettings,
    exact: true,
    component: TravelSettings,
    title: "HR Settings",
    isAdmin: true,
    feature: features.CoreHR,
  },


  {
    path: RouteEnum.MobileDevicePath,
    exact: true,
    component: MobileDeviceScreen,
    isAdmin: true,
    title: "Mobile Devices",
    feature: features.CoreHR,
  },

  {
    path: RouteEnum.DisciplinaryActionPath,
    exact: true,
    component: DisciplinaryActionScreen,
    isAdmin: true,
    title: "Disciplinary Action",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.DisciplinaryActionStagesPath,
    exact: true,
    component: DisciplinaryStages,
    isAdmin: true,
    title: "Disciplinary Action Stages",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.ViewDisciplinaryActionPath + ":disciplinaryCaseId",
    exact: true,
    component: DisciplinaryActions,
    isAdmin: true,
    title: "Disciplinary Action",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.HRDashboardPath,
    exact: true,
    component: HRDashboard,
    isAdmin: true,
    title: "HR Dashboard",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.TravelAdvanceSettlementReport,
    exact: true,
    component: TravelAdvanceReport,
    isAdmin: true,
    title: "Travel Advance Report Dashboard",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.HRDashboardCommonTaskPath,
    exact: true,
    component: HRCommonTasks,
    isAdmin: true,
    title: "HR Common Task",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.HRDashboardReportsPath,
    exact: true,
    component: HRReports,
    isAdmin: true,
    title: "HR Reports",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.HRDashboardSettingsPath,
    exact: true,
    component: HRSettings,
    isAdmin: true,
    title: "HR Settings",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.KSALibrariesPath,
    exact: true,
    component: KSALibrariesScreen,
    isAdmin: true,
    title: "KSALibraries",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.JobProfilePath,
    exact: true,
    component: JobProfile,
    isAdmin: true,
    title: "Job Profile",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.CreateJobProfilePath,
    exact: true,
    component: JobProfileFormScreen,
    isAdmin: true,
    title: "Create Job Profile",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.UpdateJobProfilePath + ":profileId",
    exact: true,
    component: JobProfileFormScreen,
    isAdmin: true,
    title: "Update Job Profile",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.CreateJobDescriptionPath,
    exact: true,
    component: JobDescriptionFormScreen,
    isAdmin: true,
    title: "Create Job Description",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.UpdateJobDescriptionPath + ":descriptionId",
    exact: true,
    component: JobDescriptionFormScreen,
    isAdmin: true,
    title: "Update Job Profile",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.EmployeeOnboarding,
    exact: true,
    component: EmployeeOnBoarding,
    title: "Employee Onboarding",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.EmployeeOnboardingAddEmployee,
    exact: true,
    isAdmin: true,
    component: EmployeeOnboardingAddEmployee,
    title: "Employee Onboarding Add Employee",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.CreateOnboardingEmployee,
    exact: true,
    component: CreateOnboardingEmployeePath,
    title: "Employee Onboarding",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.UpdateOnboardingEmployee + "/:id",
    exact: true,
    component: CreateOnboardingEmployeePath,
    title: "Employee Onboarding",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.EmployeeOnboardingPackages + "/:id",
    exact: true,
    isAdmin: true,
    component: EmployeeOnboardingPackages,
    title: "Employee Onboarding Package",
    feature: features.CoreHR,
  },
  // loan module
  {
    path: RouteEnum.LoanManagement,
    exact: true,
    component: LoanManagement,
    title: "Loan Management",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.CreateHomeLoanPath,
    exact: true,
    title: "Request Home Loan",
    component: HomeLoanCreateScreen,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.UpdateHomeLoanPath + "/:id",
    exact: true,
    title: "Request Home Loan",
    component: HomeLoanCreateScreen,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.ViewHomeLoanPath + "/:id",
    exact: true,
    title: "View Home Loan",
    component: HomeLoanViewScreen,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.CreateVehicleLoanPath,
    exact: true,
    title: "Request Vehicle Loan",
    component: VehicleCreateScreen,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.UpdateVehicleLoanPath + "/:id",
    exact: true,
    title: "Update Vehicle Loan",
    component: VehicleCreateScreen,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.ViewVehicleLoanPath + "/:id",
    exact: true,
    title: "View Home Loan",
    component: VehicleLoanViewScreen,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.CreateStaffODLoanPath,
    exact: true,
    title: "Request Staff OD Loan",
    component: StaffODCreateScreen,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.UpdateStaffODLoanPath + "/:id",
    exact: true,
    title: "Update Staff OD Loan",
    component: StaffODCreateScreen,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.CreateStaffPersonalLoanPath,
    exact: true,
    title: "Request Staff Pesonal Loan",
    component: StaffPersonalCreateScreen,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.CreateStaffAdvanceLoanPath,
    exact: true,
    title: "Request Staff Advance Loan",
    component: StaffPersonalCreateScreen,
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.UpdateStaffPersonalLoanPath + "/:id",
    exact: true,
    title: "Update Staff Personal Loan",
    component: StaffPersonalCreateScreen,
    feature: features.CoreHR,
  },
  // {
  //   path: RouteEnum.AddRecordPath,
  //   exact: true,
  //   component: LoanManagement,
  //   title: "Loan Management",
  //   feature: features.CoreHR,
  // },
  {
    path: RouteEnum.EmployeeLoanList,
    exact: true,
    component: EmployeeLoanList,
    title: "Loan List",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.AccessAndIDCategory,
    exact: true,
    component: AccessAndIdCategoryTemplateScreen,
    title: "Access & ID Templates",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.Checklist,
    exact: true,
    component: ChecklistTemplateScreen,
    title: "Checklist Templates",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.Message,
    exact: true,
    component: MessageTemplateScreen,
    title: "Message Templates",
    feature: features.CoreHR,
  },
];

// const justLoanModule = features => [
//   {
//     path: RouteEnum.Settings,
//     exact: true,
//     component: Settings,
//     title: "HR Settings",
//     isAdmin: true,
//     feature: features.CoreHR,
//   },
//   {
//     path: RouteEnum.LeaveTimeDashboardPath,
//     exact: true,
//     component: LeaveTimeDashboard,
//     isAdmin: true,
//     title: "Leave & Time Dashboard",
//     feature: features.CoreHR,
//   },
//   {
//     path: RouteEnum.LeaveTimeDashboardCommonTaskPath,
//     exact: true,
//     component: LeaveCommonLinks,
//     isAdmin: true,
//     title: "Leave & Time Common Task",
//     feature: features.CoreHR,
//   },
//   {
//     path: RouteEnum.LeaveTimeDashboardReportsPath,
//     exact: true,
//     component: LeaveReports,
//     isAdmin: true,
//     title: "Leave & Time Reports",
//     feature: features.CoreHR,
//   },
//   {
//     path: RouteEnum.LeaveTimeDashboardSettingsPath,
//     exact: true,
//     component: LeaveSettings,
//     isAdmin: true,
//     title: "Leave & Time Settings",
//     feature: features.CoreHR,
//   },

//   {
//     path: RouteEnum.HRDashboardPath,
//     exact: true,
//     component: HRDashboard,
//     isAdmin: true,
//     title: "HR Dashboard",
//     feature: features.CoreHR,
//   },
//   {
//     path: RouteEnum.HRDashboardCommonTaskPath,
//     exact: true,
//     component: HRCommonTasks,
//     isAdmin: true,
//     title: "HR Common Task",
//     feature: features.CoreHR,
//   },
//   {
//     path: RouteEnum.HRDashboardReportsPath,
//     exact: true,
//     component: HRReports,
//     isAdmin: true,
//     title: "HR Reports",
//     feature: features.CoreHR,
//   },
//   {
//     path: RouteEnum.HRDashboardSettingsPath,
//     exact: true,
//     component: HRSettings,
//     isAdmin: true,
//     title: "HR Settings",
//     feature: features.CoreHR,
//   },
//   {
//     path: RouteEnum.PayrollDashboardPath,
//     exact: true,
//     component: PayrollDashboard,
//     title: "Payroll Dashboard",
//     isAdmin: true,
//     feature: features.CoreHR,
//   },
//   {
//     path: RouteEnum.PayrollDashboardCommonTaskPath,
//     exact: true,
//     component: PayrollCommonLinks,
//     title: "Payroll Common Task",
//     isAdmin: true,
//     feature: features.CoreHR,
//   },
//   {
//     path: RouteEnum.PayrollDashboardReportsPath,
//     exact: true,
//     component: PayrollReports,
//     title: "Payroll Reports",
//     isAdmin: true,
//     feature: features.CoreHR,
//   },
//   {
//     path: RouteEnum.PayrollDashboardSettingsPath,
//     exact: true,
//     component: PayrollSettings,
//     title: "Payroll Settings",
//     isAdmin: true,
//     feature: features.CoreHR,
//   },

//   //Loan Module
//   {
//     path: RouteEnum.LoanManagement,
//     exact: true,
//     component: LoanManagement,
//     title: "Loan Management",
//     feature: features.CoreHR,
//   },
//   {
//     path: RouteEnum.AdminLoanManagement,
//     exact: true,
//     component: AdminLoanManagement,
//     title: "Loan List",
//     isAdmin: true,
//     feature: features.CoreHR,
//   },
//   {
//     path: RouteEnum.CreateHomeLoanPath,
//     exact: true,
//     title: "Request Home Loan",
//     component: HomeLoanCreateScreen,
//     feature: features.CoreHR,
//   },
//   {
//     path: RouteEnum.UpdateHomeLoanPath + "/:id",
//     exact: true,
//     title: "Request Home Loan",
//     component: HomeLoanCreateScreen,
//     feature: features.CoreHR,
//   },
//   {
//     path: RouteEnum.ViewHomeLoanPath + "/:id",
//     exact: true,
//     title: "View Home Loan",
//     component: HomeLoanViewScreen,
//     feature: features.CoreHR,
//   },
//   {
//     path: RouteEnum.CreateVehicleLoanPath,
//     exact: true,
//     title: "Request Vehicle Loan",
//     component: VehicleCreateScreen,
//     feature: features.CoreHR,
//   },
//   {
//     path: RouteEnum.UpdateVehicleLoanPath + "/:id",
//     exact: true,
//     title: "Update Vehicle Loan",
//     component: VehicleCreateScreen,
//     feature: features.CoreHR,
//   },
//   {
//     path: RouteEnum.CreateStaffODLoanPath,
//     exact: true,
//     title: "Request Staff OD Loan",
//     component: StaffODCreateScreen,
//     feature: features.CoreHR,
//   },
//   {
//     path: RouteEnum.UpdateStaffODLoanPath + "/:id",
//     exact: true,
//     title: "Update Staff OD Loan",
//     component: StaffODCreateScreen,
//     feature: features.CoreHR,
//   },
//   {
//     path: RouteEnum.CreateStaffPersonalLoanPath,
//     exact: true,
//     title: "Request Staff Personal Loan",
//     component: StaffPersonalCreateScreen,
//     feature: features.CoreHR,
//   },
//   {
//     path: RouteEnum.CreateStaffAdvanceLoanPath,
//     exact: true,
//     title: "Request Staff Advance Loan",
//     component: StaffPersonalCreateScreen,
//     feature: features.CoreHR,
//   },
//   {
//     path: RouteEnum.UpdateStaffPersonalLoanPath + "/:id",
//     exact: true,
//     title: "Update Staff Personal Loan",
//     component: StaffPersonalCreateScreen,
//     feature: features.CoreHR,
//   },
//   {
//     path: RouteEnum.EmployeeLoanList,
//     exact: true,
//     component: EmployeeLoanList,
//     title: "Loan List",
//     feature: features.CoreHR,
//   },

// ];

export default routes;
