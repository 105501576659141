import React from 'react';
import RigoFroalaEditor from 'react-froala-wysiwyg';
import { Flex } from '@chakra-ui/layout';
import { Controller } from 'react-hook-form';
// CHAKRA-UI
import { FormControl, FormHelperText, Input } from '@chakra-ui/react';
import { resolveObjectValueByPath } from '../../../../helpers';
import FormErrorLable from '../form-error-text';
import FormLabel from '../form-label/form-label';
import merge from 'lodash/merge';

import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

import 'froala-editor/js/plugins.pkgd.min.js';

interface BaseFroalaEditorProps {
  name: any;
  label?: any;
  value?: any;
  width?: any;
  control?: any;
  ignoreControl?: boolean;
  required?: boolean;
  errors?: any;
  rule?: any;
  onChange?: any;
  onChangeRHF?: any;
  customLabel?: any;
}

export const FroalaEditorConfig = {
  key: 'UBB7jF4D5H5D3G3C7aIVLEABVAYFKc1Ce1MYGD1c1NYVMiB3B9B6D5C2C4G4H3G3H3==',
  attribution: false,
  toolbarButtons: [
    'bold',
    'italic',
    'underline',
    'strikeThrough',
    'blockStyle',
    'inlineStyle',
    'align',
    'fontSize',
    'color',
    'outdent',
    'indent',
    'undo',
    'redo',
    'insertLink',
    // "subscript",
    // "superscript",
    // "fontFamily",
    // "formatBlock",
    // "insertOrderedList",
    // "insertUnorderedList",
    // "selectAll",
    // "insertImage",
    // "insertVideo",
    // "table",
    // "html",
    // "save",
    // "insertHorizontalRule",
    // "uploadFile",
    // "removeFormat",
    // "fullscreen",
  ],
};

const FroalaEditorComponent = (props: any) => {
  let {
    label,
    name,
    onChange: _onChange,
    onChangeRHF,
    customLabel,
    options,
    ...rest
  } = props;

  const handleChange = (model: any) => {
    _onChange?.(name, model);
    onChangeRHF?.(model);
  };

  return (
    <>
      <RigoFroalaEditor
        model={props.value}
        config={FroalaEditorConfig}
        onModelChange={handleChange}
        {...rest}
      />
    </>
  );
};

interface FroalaEditorProps extends BaseFroalaEditorProps, Record<any, any> {
  type?: any;
}
export const FroalaEditor = (props: FroalaEditorProps) => {
  const {
    label,
    customLabel,
    width,
    control,
    value,
    ignoreControl = false,
    required = false,
    errors,
    rule,
    ...rest
  } = props;

  const error = errors && resolveObjectValueByPath(errors, props.name)?.message;
  let _rule = {
    required: {
      value: required,
      message: 'Required',
    },

    minLength: {
      value: 2,
      message: 'This input is below minLength.',
    },
  };
  if (rule) {
    _rule = merge(_rule, rule);
  }
  if (!control || ignoreControl) {
    return (
      <FormControl
        display='flex'
        flexDirection='column'
        gap={2}
        id={props.name}
        isRequired={required}
        style={{ width }}
      >
        <FormLabel label={label} customLabel={customLabel} />
        <FroalaEditorComponent value={value} {...rest} />
        <FormErrorLable error={error} />
      </FormControl>
    );
  }
  return (
    <FormControl
      display='flex'
      flexDirection='column'
      gap={1}
      id={props.name}
      isRequired={false}
      style={{ width }}
    >
      <Flex gap={2} alignItems='center' mb='10px'>
        <FormLabel
          color='#000000b3'
          fontSize='14px'
          lineHeight='21px'
          label={label}
          customLabel={customLabel}
        />
        {control && !required && (
          <FormHelperText m={0} color='gray.400'>
            optional
          </FormHelperText>
        )}
      </Flex>
      <Controller
        control={control}
        name={props.name}
        rules={_rule}
        render={controllerProps => {
          const {
            field: { onChange: _onChange, value: _value },
          } = controllerProps;

          return (
            <>
              <FroalaEditorComponent
                {...rest}
                value={_value}
                onChangeRHF={_onChange}
              />
            </>
          );
        }}
      />
      <FormErrorLable error={error} />
    </FormControl>
  );

  // return (
  //     <FroalaEditorComponent />
  // )
};

export default FroalaEditor;
