// @ts-nocheck 
import {RegularList} from "components/common/regularList";
import {Text} from "components/text";
import React from "react";
import CoursePageQuestionListItem from "./coursePageQuestionListItem";

const CoursePageListItem = ({coursePage, index}) => {
  return (
    <div className="course-page-block">
      <Text
        fontSize="16px"
        fontWeight="500"
        letterSpacing="0.4px"
        textDecoration="underline"
        textUnderlineOffset="2px"
        title={`S${index + 1}. ${coursePage.PageName}`}
      />
      {coursePage?.Question.length > 0 && (
        <RegularList
          items={coursePage.Question}
          resourceName="coursePageQuestion"
          itemComponent={CoursePageQuestionListItem}
        />
      )}
    </div>
  );
};

export default CoursePageListItem;
