import { createSlice } from "@reduxjs/toolkit";
import * as fromInterface from "../../../ts";

export interface NewPerformanceSelectedFromOptionState {
    selected: any,
}

const initialState: NewPerformanceSelectedFromOptionState = {
    selected: null,
};

const newPerformanceSelectedFromOptionSlice = createSlice({
    name: "New Performance Selected From Option",
    initialState,
    reducers: {
        setSelected: (state, action) => {
            const payload = action.payload
            state.selected = payload;
        },
        clear: (state) => {
            state.selected = null
        },
    },
});

export const {
    setSelected,
    clear,
} = newPerformanceSelectedFromOptionSlice.actions

export default newPerformanceSelectedFromOptionSlice.reducer;
