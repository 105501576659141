// NETWORK
import { ApiClient } from "../../../network/apiClient";
import { FETCH_PERFORMANCE_DETAIL } from "./api";

// SLICE
import * as fromSlice from "./slice";

// INTERFACE
import * as fromInterface from "../../../ts";

export const setCurrentFormDetail = ({ formId, cb }: { formId: any, cb?: any }) => async (dispatch: any) => {

    dispatch(fromSlice.updateFormDetailRequest())

    const response: fromInterface.ResponseWrapper<any> = await ApiClient.get<any>({
        endpoint: FETCH_PERFORMANCE_DETAIL.replace(":formId", formId + ""),
        dispatch: dispatch
    })

    if (response?.Status) {
        cb?.(response?.Data)
        dispatch(fromSlice.updateFormDetail(response?.Data))
    }

}

export const fetchPerf = ({ formId, cb }: any) => async (dispatch: any) => {

    const response: fromInterface.ResponseWrapper<any> = await ApiClient.get<any>({
        endpoint: FETCH_PERFORMANCE_DETAIL.replace(":formId", formId + ""),
        dispatch: dispatch
    })

    if (response?.Status) {
        cb?.(response?.Data)
    }

}