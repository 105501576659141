// @ts-nocheck 
import React, { useRef, useState } from "react";
import { ButtonProps } from "ts/interfaces/componentInterface";
import { IoIosArrowDown } from "react-icons/io";
import classnames from "classnames";
import isEmpty from "isEmpty";
import useOutsideClick from "helpers/outsideClickHook";

function ButtonDrop(props: ButtonProps) {
  const {
    children,
    title,
    withDrop,
    onClick,
    customDropIcon,
    dropClass,
    icon,
    onDropClick,
    justDrop,
    dropComponent,
    dropAbove,
    ...rest
  } = props;

  const ref = useRef(null);
  const [open, setOpen] = useState(false);

  const onDrop = (e: any) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const dropClasses = classnames({
    "button-drop": true,
    "button-drop__active": open,
    [dropClass]: !isEmpty(dropClass),
  });
  useOutsideClick(ref, () => {
    setOpen(false);
  });
  if(justDrop){
    return(
      <>
          <ButtonContent  onClick={(e) => onDrop(e)}  {...props} />
          <div ref={ref} onClick={(e) => {onDrop(e);onDropClick && onDropClick(e)}} className={dropClasses}>
            <div className="button-drop__icon">
              {!isEmpty(customDropIcon) ? customDropIcon : <IoIosArrowDown />}
            </div>
            <div style={dropAbove?{top:-25}:null} className="button-drop__content">{dropComponent}</div>
          </div>
      </>
    )
  }
  return (
    <>
      <ButtonContent {...props} />
      <div ref={ref} onClick={(e) => onDrop(e)} className={dropClasses}>
        <div className="button-drop__icon">
          {!isEmpty(customDropIcon) ? customDropIcon : <IoIosArrowDown />}
        </div>
        <div className="button-drop__content">{dropComponent}</div>
      </div>
    </>
  );
}

const ButtonContent = ({ title, children, onClick }: ButtonProps) => {
  if (!isEmpty(children) || !isEmpty(title)) {
    return (
      <span onClick={(e) => onClick(e)}>
        {!isEmpty(title) ? title : children}
      </span>
    );
  }
  return null;
};

export default ButtonDrop;
