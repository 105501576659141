import { HiThumbUp } from "react-icons/hi";
import { IoCloseSharp } from "react-icons/io5";
import { TbCheck, TbRotateClockwise2 } from "react-icons/tb";

export const statusLabel = {
  Pending: "Pending",
  Approved: "Approved",
  Rejected: "Rejected",
  Recommended: "Recommended",
};

export const statusBgColor = {
  Pending: "#FEF7EE",
  Approved: "#d2f0db",

  Denied: "#ffd3d3",
  Rejected: "#ffd3d3",
  Cancelled: "#ffd3d3",
  Recommended: "#2A57D8",
};

export const statusColor = {
  Pending: "#c0a880",
  Approved: "#68a57c",
  Rejected: "#B66F80",
  Denied: "#B66F80",
  Cancelled: "#B66F80",
  Recommended: "#FEF7EE",
};

export const statusIcon = {
  Pending: TbRotateClockwise2,
  Approved: TbCheck,
  Rejected: IoCloseSharp,
  Cancelled: IoCloseSharp,
  Recommended: HiThumbUp,
};
