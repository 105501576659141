//GeoFencing
enum GeoFencing {
  geofencingRoutes = "/geofencing",
  geoBranchesAndWorkSitesRoutes = "/geofencing/branches-and-work-sites",
  geoLoactionAttendanceRoutes = "/geofencing/location-attendance",
  geoOfficeRoutes = "/geofencing/geo-office-sites",
  geoAttendancedevicesRulesRoutes = "/geofencing/location-attendance",
  attendanceRulesRoutes = "/geofencing/attendance-device-rules",
}

export default GeoFencing;
