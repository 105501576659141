import { STATUS_REVIEW_CYCLE, STATUS_STATUS } from './../constants';
import * as fromConstants from "../constants";
import * as fromSlice from "./../slice";

const selectGlobalFilterSlice = (state: any): fromSlice.GlobalFilterState => {
    return state.dataStore.globalFilter;
};

export const selectFilterStatuses = (state: any): any => {
    return selectGlobalFilterSlice(state).statuses;
};

export const selectFilterStatusesByName = (name: typeof fromConstants.STATUS_REVIEW_CYCLE | typeof fromConstants.STATUS_STATUS) => (state: any): any => {
    return selectFilterStatuses(state)?.[name];
};

export const selectFilterData = (state: any): any => {
    return selectGlobalFilterSlice(state).data;
};

export const selectFilterDataByName = (name: typeof fromConstants.DATA_REVIEW_CYCLE | typeof fromConstants.DATA_STATUS |
    typeof fromConstants.PAGE_INDEX | typeof fromConstants.PAGE_SIZE) => (state: any): any => {
        return selectFilterData(state)?.[name];
    };

export const selectFilters = (state: any): any => {
    return selectGlobalFilterSlice(state).filters;
};

export const selectFIlterByName = (name: typeof fromConstants.SELECTED_REVIEW_CYCLE | typeof fromConstants.SELECTED_STATUS | typeof fromConstants.SELECTED_FORM_NAME) => (state: any): any => {
    return selectGlobalFilterSlice(state)?.filters?.[name];
};


