// LIBS
import { createNextState } from '@reduxjs/toolkit';

// CONSTANTS
import AprPerformanceReviewStatusEnum from '../../../../enums/appraisal/AprPerformanceReviewStatus';

// NETWORK
import { ApiClient } from '../../../../network/apiClient';
import { SAVE_PERFORMANCE_REVIEW } from '../api';

// STORES
import * as fromPerformanceReviewCommentStore from '../../performance-review-comments';
import * as fromSelector from '../selectors';
import * as fromSlice from '../slice';
import { setStep } from './set-step';
import * as _ from 'lodash';

const get_next_state_for_goal_action = (
  nextReviewDetail: any,
  payloadReviewDetail: any,
  sectionIdx: any,
) => {
  return createNextState(nextReviewDetail, (draftState: any) => {
    draftState.Sections[sectionIdx].Contents.forEach(
      (content: any, contentIdx: number) => {
        content.GoalActionOptions.forEach(
          (goalActionOption: any, gaaIdx: number) => {
            const goalActionOptionFromDraft = goalActionOption;
            const goalActionfromPayload =
              payloadReviewDetail.reviewDetails[gaaIdx].GoalAction;

            draftState.Sections[sectionIdx].Contents[
              contentIdx
            ].GoalActionOptions[gaaIdx] = {
              ...goalActionOptionFromDraft,
              ...goalActionfromPayload,
            };

            draftState.Sections[sectionIdx].Contents[
              contentIdx
            ].GoalActionOptions[gaaIdx].RatingScaleOptionId =
            payloadReviewDetail.reviewDetails[gaaIdx].RatingScaleOptionId;
          },
        );
      },
    );
  });
};

const dataMaker: any = {
  payload: {},
  nextReviewDetail: {},
  type: '',

  setType: function (type: string) {
    this.type = type;
    return this;
  },

  setDefaultPayload: function (payload: string) {
    this.payload = payload;
    return this;
  },

  setDefaultReviewDetail: function (reviewDetail: string) {
    this.nextReviewDetail = reviewDetail;
    return this;
  },

  /**
   * PREPARE DATA TO SEND TO API
   * @returns
   */
  preparePayload: function () {
    // this.type === "GOALS_AND_ACTIONS" ||
    if (this.type === 'KSA' || this.type === 'MATRIX_RATING') {
      this.payload = createNextState(this.payload, (draftState: any) => {
        draftState.Comments = draftState?.Comments.filter(
          (workingComment: any) => {
            return (
              workingComment.ReviewId ===
              this.nextReviewDetail.ReviewInfo.ReviewId
            );
          },
        );
      });
    } else if (this.type === 'FREE_TEXT_QUESTION') {
      if (this.payload?.reviewDetails) {
        /**
         * PREPARE COMMENTS
         * ONLY FREE TEXT HAS COMMENT ON DIFFERENT POSTION SO NEEDS TO BE MAPPED
         */
        let Comments: any[] = [];
        this.payload = createNextState(this.payload, (draftState: any) => {
          draftState?.reviewDetails?.forEach((review: any) => {
            review?.Comments.forEach((workingComment: any) => {
              if (
                workingComment.Comment &&
                workingComment.ReviewId ===
                  this.nextReviewDetail.ReviewInfo.ReviewId
              ) {
                const { SectionId, SectionContentId, Comment } = workingComment;

                Comments.push({
                  SectionId,
                  SectionContentId,
                  Comment,
                  ReviewId: this.nextReviewDetail.ReviewInfo.ReviewId,
                });
              }
            });

            delete review['Comments'];
          });

          draftState.Comments = Comments;
        });
      }
    } else if (this.type === 'OVERALL_PERFORMANCE_RATING') {
      this.payload = {
        Id: this.payload.Id,
        PerformanceFormId: this.nextReviewDetail?.ReviewInfo?.PerformanceFormId,
        PerformanceRolloutId:
          this.nextReviewDetail?.ReviewInfo?.PerformanceRolloutId,
        Status: this.payload.status,
        Signature: this.payload.signature,
        Step: this.payload.step,
        isOverallRatingAndDevelopmentPlanSection:
          this.payload.isOverallRatingAndDevelopmentPlanSection,
        Comments: [],
        ...(this.nextReviewDetail?.ReviewInfo
          ?.DisplayEvaluationAndDevelopmentSection &&
          this.nextReviewDetail?.ReviewInfo
            ?.ShowOverallPerformanceRatingSection && {
            evaluation: {
              ...(this.nextReviewDetail?.ReviewInfo
                ?.OverallPerfomanceRatingScale?.value && {
                ratingScaleId:
                  this.nextReviewDetail?.ReviewInfo
                    ?.OverallPerfomanceRatingScale.value,
              }),
              ...(this.payload?.evaluation?.RatingScaleOptionId && {
                ratingScaleOptionId:
                  this.payload.evaluation.RatingScaleOptionId,
              }),
              ...(this.payload?.evaluation?.Comments && {
                Comments: this.payload.evaluation.Comments,
              }),
            },
          }),
        ...(this.nextReviewDetail.ReviewInfo
          ?.IncludeEmployeeDevelopmentPlanSection && {
          DevelopmentPlan: this.payload.DevelopmentPlan,
        }),
        ...(this.nextReviewDetail.ReviewInfo?.PeerAffinityLevelId && {
          PeerAffinityLevelId:
            this.nextReviewDetail.ReviewInfo?.PeerAffinityLevelId,
        }),
      };
    } else if (this.type === 'SUMMARY_NEXT') {
      this.payload = {
        ...this.payload,
        reviewDetails: [],
        Comments: [],
        signature: false,
      };
    } else if (this.type === 'AFFINITY_LEVEL') {
      this.payload = {
        ...this.payload,
        PeerAffinityLevelId: this.payload?.PeerAffinityLevelId.value,
        PeerAffinityLevelName: this.payload?.PeerAffinityLevelId.label,
      };
    }

    return this;
  },

  /**
   * PREPARE DATA TO SYNC WITH LOCAL STATE
   * @param sectionIdx
   * @returns
   */
  prepareReviewDetail: function (sectionIdx: number) {
    /**
     * REQUIRED TO SYNC FORM DATA LOCALLY
     */
    if (this.type === 'GOALS_AND_ACTIONS') {
      this.nextReviewDetail = get_next_state_for_goal_action(
        this.nextReviewDetail,
        this.payload,
        sectionIdx,
      );
    } else if (this.type === 'FREE_TEXT_QUESTION') {
      this.nextReviewDetail = createNextState(
        this.nextReviewDetail,
        (draftState: any) => {
          const foundSection = draftState?.Sections?.[sectionIdx as number];

          foundSection?.Contents.forEach((content: any, contendIdx: any) => {
            const { RatingScaleOptionId, RatingScore, Text } =
              this.payload.reviewDetails[contendIdx];

            content.RatingScaleOptionId = RatingScaleOptionId;
            content.RatingScore = RatingScore;
            content.Text = Text;
          });
        },
      );
    } else if (this.type === 'MATRIX_RATING') {
      this.nextReviewDetail = createNextState(
        this.nextReviewDetail,
        (draftState: any) => {
          this.payload.reviewDetails.forEach((payloadReviewDetail: any) => {
            const stateSectionIndex = this.nextReviewDetail.Sections.findIndex(
              (section: any) => section?.Id === payloadReviewDetail.sectionId,
            );

            console.log({ stateSectionIndex });
            if (stateSectionIndex !== -1) {
              // prettier-ignore
              const stateContentIndex = this.nextReviewDetail.Sections[stateSectionIndex].Contents.findIndex((content: any) =>
                content.Id === payloadReviewDetail.sectionContentId,
              );
              console.log({ stateContentIndex });
              if (stateContentIndex !== -1) {
                // prettier-ignore
                const stateOptionIndex = this.nextReviewDetail.Sections[stateSectionIndex].Contents[stateContentIndex].Options.findIndex((Option: any) => {
                  return (
                    Option.Id === payloadReviewDetail.sectionContentOptionId
                  );
                },
                );

                if (stateOptionIndex !== -1) {
                  /* prettier-ignore */
                  draftState.Sections[stateSectionIndex].Contents[stateContentIndex].Options[stateOptionIndex].RatingScaleOptionId = payloadReviewDetail.RatingScaleOptionId;
                }
              }
            }
          });
        },
      );
    } else if (this.type === 'KSA') {
      this.nextReviewDetail = createNextState(
        this.nextReviewDetail,
        (draftState: any) => {
          const foundsection = draftState?.Sections?.[sectionIdx as number];

          foundsection?.Contents.forEach((content: any) => {
            content.KSAOptions.forEach((KSAOption: any, ksaIdx: any) => {
              KSAOption.RatingScore =
                this.payload.reviewDetails[ksaIdx].Ksa.RatingScore;
            });
          });
        },
      );
    } else if (this.type === 'OVERALL_PERFORMANCE_RATING') {
      this.nextReviewDetail = createNextState(
        this.nextReviewDetail,
        (draftState: any) => {
          // SET EVALUATION KEY VALUES
          if (this.payload?.evaluation?.Comments) {
            draftState.Comments = this.payload.evaluation.Comments;
          }

          if (this.payload?.evaluation?.ratingScaleId) {
            // prettier-ignore
            draftState.RatingScaleId = this.payload.evaluation.ratingScaleId;
          }

          if (this.payload?.evaluation?.ratingScaleOptionId) {
            // prettier-ignore
            draftState.ratingScaleOptionId = this.payload.evaluation.ratingScaleOptionId;
          }
          // SET DEVELOPMENT PLAN AREA
          if (this.payload.DevelopmentPlan) {
            draftState.DevelopmentArea = this.payload.DevelopmentPlan;
          }
        },
      );
    } else if (this.type === 'AFFINITY_LEVEL') {
      this.nextReviewDetail = createNextState(
        this.nextReviewDetail,
        (draftState: any) => {
          draftState.ReviewInfo.PeerAffinityLevelId =
            this.payload.PeerAffinityLevelId;
          draftState.ReviewInfo.PeerAffinityLevelName =
            this.payload.PeerAffinityLevelName;
        },
      );
    }

    return this;
  },

  result: function () {
    return {
      payload: this.payload,
      nextReviewDetail: this.nextReviewDetail,
    };
  },

  print: function () {
    const result: any = this.result();

    console.log('=============================');
    console.log(JSON.stringify(result, null, 2));
    console.log('=============================');
    return this;
  },

  printPayload: function () {
    const payload = this.payload;

    console.log('=============================');
    console.log(JSON.stringify(payload, null, 2));
    console.log('=============================');
    return this;
  },
};

// CALCULATE NEW CURRENT STEP
// CALCULATE NEW STEPS
export const syncSteps = (state: any, dispatch: any) => {
  const steps = fromSelector.selectSteps(state);
  const selectedStep = steps.find(step => step.isSelected);

  const _step = (selectedStep?.index as number) ?? 0;
  dispatch(setStep(_step + 1));
};

export const saveAndFinishLater =
  ({
    contentSectionType,
    submitType,
    payload,
    history,
    dashboardRoute,
  }: {
    contentSectionType: string;
    submitType: string;
    payload?: any;
    history?: any;
    dashboardRoute?: any;
  }) =>
  async (dispatch: any, getState: any) => {
    const state = getState();

    const reviewDetail = fromSelector.selectReview(state);
    const currentStep = fromSelector.selectCurrentStep(state);

    const sectionIdx = reviewDetail.Sections?.findIndex(section => {
      return section.Id === currentStep.sectionId;
    });

    const basePayload = {
      step:
        submitType === 'NEXT'
          ? (currentStep?.index as number) + 1
          : currentStep?.index,
      status:
        currentStep.isLastStep && submitType === 'SUBMIT'
          ? AprPerformanceReviewStatusEnum.Submitted
          : AprPerformanceReviewStatusEnum.Pending,
      ...(currentStep.isLastStep && { signature: true }),
    };

    // MERGE THESE TWO
    let finalPayload: any = {
      ...payload,
      ...basePayload,
      Id: reviewDetail?.ReviewInfo?.ReviewId,
      PerformanceFormId: reviewDetail?.ReviewInfo?.PerformanceFormId,
      PerformanceRolloutId: reviewDetail?.ReviewInfo?.PerformanceRolloutId,
      ...(reviewDetail?.ReviewInfo?.PeerAffinityLevelId && {
        PeerAffinityLevelId: reviewDetail?.ReviewInfo?.PeerAffinityLevelId,
      }),
      isOverallRatingAndDevelopmentPlanSection:
        currentStep.isOverallRatingAndDevelopmentPlanSection,
    };

    const result = dataMaker
      .setType(contentSectionType)
      .setDefaultPayload(finalPayload)
      .setDefaultReviewDetail(reviewDetail)
      .preparePayload()
      .prepareReviewDetail(sectionIdx)
      .print()
      .result();

    const response: any = await ApiClient.post<any>({
      endpoint: SAVE_PERFORMANCE_REVIEW,
      dispatch: dispatch,
      body: result?.payload,
    });

    const { Status } = response;

    if (Status) {
      if (submitType === 'SUBMIT') {
        history.push(dashboardRoute);
      } else {
        if (result?.payload?.Comments?.length !== 0) {
          dispatch(
            fromPerformanceReviewCommentStore.PerformanceReviewCommentStore.syncComments(
              result?.payload?.Comments,
            ),
          );
        }

        if (submitType === 'NEXT') {
          syncSteps(state, dispatch);
        }

        if (result?.nextReviewDetail) {
          dispatch(fromSlice.syncReviewDetail(result?.nextReviewDetail));
        }
      }
    }
  };
