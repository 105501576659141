// @ts-nocheck 
import React, {useEffect, useState} from "react";
import FormGroup from "components/formGroup";
import FormItem from "components/form";
import {useForm} from "react-hook-form";
import {useDispatch} from "react-redux";
import Button from "components/button";
import DataSelect from "components/dataselect";
import isEmpty from "helpers/isEmpty";
import {getCourseByTitle} from "../helpers/action";
const initialState = {
  courseName: "",
  employeeName: "",
  activeBranch: null,
  activeDepartment: null,
  status: null,
  options: [],
};

const CourseAssignmentFilter = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    setToAutoSelect();
  }, [props.course]);
  const method = useForm();
  const {onSearch} = props;
  const [filter, setFilter] = useState(initialState);
  const onChange = (name, value) => {
    setFilter((prev) => {
      var nextFilter = {
        ...prev,
        [name]: value,
      };
      onSearch(nextFilter);
      return nextFilter;
    });
  };
  const setToAutoSelect = async () => {
    if (props.course) {
      var options = await loadAutoSelectOptions(props.course?.label);
      var obj = options.find((x) => x.value == props.course.value);
      setFilter({
        ...filter,
        courseName: obj,
        options,
      });
    }
  };
  const loadAndSetOptions = async (value) => {
    let options = await loadAutoSelectOptions(value);
    setFilter({
      ...filter,
      options,
    });
    return options;
  };
  const loadAutoSelectOptions = async (value) => {
    if (value && value.length >= 2) {
      var res: any = await dispatch(getCourseByTitle(value));
      return res.map((x) => ({...x, label: x.Title, value: x.Id}));
    }

    return [];
  };
  return (
    <div className="course-management__filters">
      <FormItem
        formName="asyncautoselect"
        width="200px"
        value={filter.courseName}
        name="courseName"
        placeholder="Search course"
        onChange={onChange}
        label="Course"
        options={filter.options}
        loadOptions={loadAndSetOptions}
      />

      <FormItem
        // formName="searchInput"
        width="200px"
        value={filter.employeeName}
        name="employeeName"
        placeholder="Search employee"
        onChange={onChange}
        label="Employee"
      />
      <DataSelect
        onChange={onChange}
        value={filter.activeBranch}
        label="Branch"
        name="activeBranch"
        type=""
        actionType="branch"
        width="200px"
      />
      <DataSelect
        onChange={onChange}
        value={filter.activeDepartment}
        label="Department"
        name="activeDepartment"
        type=""
        actionType="department"
        width="200px"
      />
      <FormItem
        formName="reactselect"
        value={filter.status}
        onChange={onChange}
        options={[
          {label: "Completed", value: 2},
          {label: "In progress", value: 1},
          {label: "Not started", value: 0},
        ]}
        name="status"
        width="200px"
        label="Status"
      />
    </div>
  );
};

export default CourseAssignmentFilter;
