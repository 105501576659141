// @ts-nocheck 
import React,{useState,useEffect} from 'react'
import * as nepCalendarHelpers from './helpers'
import * as engCalendarHelpers from './engHelpers'


interface DateRangeProps{
    startDate:string,
    endDate:string
}

export const useDateRange = (props,isNepali=false) =>{
    const {date,onDateChange,isDateRange} = props;
    const [dateRange, setDate] = useState<DateRangeProps>({startDate:'',endDate:''});
    const setDateRange = (date) =>{
        setDate(da=>{
            if(!da.startDate){
                da.startDate = date;
            }
            else if(da.startDate && da.endDate){
                da.startDate = date;
                da.endDate = '';
            }
            else if(!da.endDate){
                if(isNepali){
                    if(nepCalendarHelpers.nepDateDiff(da.startDate,date) <= 0){
                        da.endDate = date;
                    }
                    else{
                        da.endDate = da.startDate;
                        da.startDate = date;
                    }
                }else{
                    if(engCalendarHelpers.dateDiff(da.startDate,date) <= 0){
                        da.endDate = date;
                    }
                    else{
                        da.endDate = da.startDate;
                        da.startDate = date;
                    }
                }
            }
            isDateRange && onDateChange && onDateChange(da)
            return da
        })
    }
    useEffect(()=>{
        if(date){
            if(checkValidDateRangeDate(date)){
                setDate(date)
            }
        }
    },[])
    return {dateRange,setDateRange};
}


const checkValidDateRangeDate = (date)=>{
    if(typeof date !== 'object'){
        console.log('Invalid Date Range Date');
        return false;
    }
    if(!('startDate' in date)){
        console.log('No Start Date Presents');
        return false;
    }
    if(!('endDate' in date)){
        console.log('No End Date Presents');
        return false;
    }
    return true
}



export default useDateRange
