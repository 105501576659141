
export const CALENDAR_MONTHS_30 = ['04', '06', '09', '11'];

export function checkValue(str, max) {
    if (str.charAt(0) !== '0' || str == '00') {
        var num = parseInt(str);
        if (isNaN(num) || num <= 0 || num > max) num = 1;
        str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '0' + num : num.toString();
    };
    return str;
};
export const onDate = (e,onChange,isNepali=false,isDateRange) =>{
    var input = e.target.value;
    if(!isDateRange){
        if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
        var values = input.split('/').map(function(v) {
          return v.replace(/\D/g, '')
        });
        if (values[0]) values[0] = checkValue(values[0], 12);
    
        const monthNum = isNepali ? (32) : ( (CALENDAR_MONTHS_30.includes(values[0])) ? 30 : 
                        ('02'.includes(values[0])) ? 29 : 31);
    
    
        if (values[1]) values[1] = checkValue(values[1], monthNum);
        var output = values.map(function(v, i) {
          return v.length == 2 && i < 2 ? v + ' / ' : v;
        });
        // let val = output.join('').substr(0, 14);
        e.target.value =output.join('').substr(0, 14);
    }else{

    }

    onChange(e)
}

export const onDateBlur = (e,onChange,isNepali=false,isDateRange=false) =>{
    var input = e.target.value;
    if(!isDateRange){
      var output =  inputSplit(input);;
      e.target.value = output;
      onChange(e)
    }else{
      // var [val1,val2] = input.split('-');
      // const o1 = inputSplit(val1);
      // const o2 = inputSplit(val2);
      // console.log(o1 + ' - ' + o2)
      // e.target.value = o1 + ' - ' + o2;
      // onChange(e)
    }

}

const inputSplit = (input) =>{
  if(input){
    var values = input.split('/').map(function(v, i) {
      return v.replace(/\D/g, '')
    });
    var output = '';
    
    if (values.length == 3) {
      var year = values[2].length !== 4 ? parseInt(values[2]) + 2000 : parseInt(values[2]);
      var month = parseInt(values[0]) - 1;
      var day = parseInt(values[1]);
      var d = new Date(year, month, day);
      if (!isNaN(d)) {
        // var dates = [d.getFullYear(), d.getMonth() + 1, d.getDate()];
        var dates = [d.getMonth() + 1, d.getDate(), d.getFullYear()];
        output = dates.map(function(v) {
          v = v.toString();
          if (/\s/.test(v)) {
            v.replace(/\s/g, '') 
          }
          return v.length == 1 ? '0' + v : v;
        }).join(' / ');
      };
    };
    return output
  }
  return ''

}