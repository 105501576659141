// @ts-nocheck 
import * as React from "react";
import "./App.scss";
import "./Syncfusion.css";
import Routes from "./routes";
import Loading from "./components/common/loading";
import ToastMessages from "./components/common/toastMessages";
import {
  getUser,
  logoutUser,
  loginUser,
  refreshToken,
} from "./actions/authAction";
import { ConnectedRouter } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import { useActiveFeatureFlag } from "services/features/useFeature";
import Loaders from "components/loaders";
import {
  GetCompanyHolidaysWhole,
  GetCompanyInformation,
} from "actions/commonAction";
import { ConfigProvider } from "react-avatar";
import CheckIdleTime from "components/common/checkIdleTime";
import GlobalErrorBoundry from "./errors/globalErrorBoundry";
import { useDarkMode } from "helpers/customHooks/useDarkMode";
import useCommonAPIs from "helpers/customHooks/useCommonApis";
import isEmpty from "helpers/isEmpty";

// RIGOTECH
import { ModalSpinner } from "@rigotech/ui";

// STORE
import * as fromDataStore from "@rigotech/hrms-data"

const App = (props: any) => {
  // const fetchFlags = async () =>{
  //   await store.dispatch(getBaseURL());
  //   await store.dispatch(getActiveFeatureFlag());
  //   await store.dispatch(getFeatureFlag());

  // }
  // const [darkMode, setDarkMode] = useDarkMode();



  // HOOKS
  const dispatch = useDispatch();

  // SLEECTORS
  const { isAuthenticated, user, baseUrl } = useSelector((state: any) => state.auth);
  const { refresh_loading } = useSelector((state: any) => state.loadingReducer);
  const showLoader = useSelector(fromDataStore.selectUiLoaderSHow);



  // FUNCITONS
  const loading = useCommonAPIs();

  React.useEffect(() => {
    const handleInvalidToken = (e) => {
      if (e.key === "RefreshToken" && e.oldValue && !e.newValue) {
        // Your logout logic here
        // logoutUser()
        //asd;
        props.history.push("/sign-out");
      }
    };
    window.addEventListener("storage", handleInvalidToken);
    return function cleanup() {
      window.removeEventListener("storage", handleInvalidToken);
    };
  }, [logoutUser]);

  React.useEffect(() => {
    const handleInvalidToken = (e) => {
      if (e.key === "UserDetail" && e.oldValue && e.newValue) {
        var isSwitch = localStorage.getItem("isSwitch");
        if (e.newValue !== e.oldValue && isEmpty(isSwitch)) {
          dispatch(refreshToken());
        }
      }
    };
    window.addEventListener("storage", handleInvalidToken);
    return function cleanup() {
      window.removeEventListener("storage", handleInvalidToken);
    };
  }, [loginUser]);

  // const { data, status, error, isFetching } = useActiveFeatureFlag();
  // if (status === "loading") {
  //   return <Loaders type={"spinner"} loading={true} />;
  // }
  // if (error) {
  // }
  // if (!isEmpty(data)) {
  //   dispatch(activeFlags(data));
  // }
  if (refresh_loading) {
    return null;
  }
  if(loading){
    return <Loaders type={"rigoLoading"} loading={true} />
  }
  return (
    <ConnectedRouter history={props.history}>
      <GlobalErrorBoundry>
        <React.Suspense
          fallback={<Loaders type={"rigoLoading"} loading={true} />}
        >
          <ConfigProvider colors={["#0074e9", "#43c463", "#de2e21", "#ffa126"]}>
            <div id="app" className="App">
              <Routes />
              <Loading />
              <ToastMessages />
              <CheckIdleTime />
              {showLoader && <ModalSpinner />}
            </div>
          </ConfigProvider>
        </React.Suspense>
      </GlobalErrorBoundry>
    </ConnectedRouter>
  );
};

export default App;