
import * as fromInterface from "../../../ts";
import { ReviewFlowDropdownState } from "./slice";

const selectReviewFlowDropDownSlice = (state: any): ReviewFlowDropdownState => {
    return state.dataStore.reviewFlowDropdown;
};

export const selectReviewFlowDropDownStatus = (state: any): fromInterface.loadingType => {
    return selectReviewFlowDropDownSlice(state).reviewFlowStatus;
};

export const selectReviewFlowDropDownLoaded = (state: any): boolean => {
    return selectReviewFlowDropDownSlice(state).reviewFlowLoaded;
};

export const selectReviewFlowDropDownItems = (state: any) => {
    return selectReviewFlowDropDownSlice(state).reviewFlowItems;
};


export const selectReviewFlow360DropDownStatus = (state: any): fromInterface.loadingType => {
    return selectReviewFlowDropDownSlice(state).reviewFlow360Status;
};

export const selectReviewFlow360DropDownLoaded = (state: any): boolean => {
    return selectReviewFlowDropDownSlice(state).reviewFlow360Loaded;
};

export const selectReviewFlow360DropDownItems = (state: any) => {
    return selectReviewFlowDropDownSlice(state).reviewFlow360Items;
};
