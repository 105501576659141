import { createSlice } from "@reduxjs/toolkit";

export interface ShiftDashboardFilterState {
    filter: { TeamId?: null } | null
}

const initialState: ShiftDashboardFilterState = {
    filter: { TeamId: null }
};

const shiftDashboardFilterSlice = createSlice({
    name: "shift dashboard",
    initialState,
    reducers: {
        setFilter: (state, action: any) => {
            const payload = action.payload
            state.filter = payload
        },

        clear: (state) => {
            state.filter = { ...initialState.filter }
        },
    },
});

export const {
    clear,
    setFilter
} = shiftDashboardFilterSlice.actions;

export default shiftDashboardFilterSlice.reducer;
