import { z } from "zod"

export const employeeFamsAssetsSchema = z.object({
    AssetID: z.string(),
    AssetNumber: z.string(),
    AssetName: z.string(),
    Description: z.string(),
    IssueDate: z.string(),
    ReturnDate: z.any(),
    ReturnCondition: z.string(),
    ReturnNotes: z.string(),
    CustodyType: z.string()
})

export type IEmployeeFamsAssets = z.infer<typeof employeeFamsAssetsSchema> | null;