import * as fromSlice from './slice';
import * as fromGoalDataStore from './../employee-data-change-requests';
export const udpateEmployeeDataChangeRequestsFilter =
  (newFilters: any): any =>
  (dispatch: any, getState: any) => {
    dispatch(fromSlice.udpateFilter(newFilters));
  };

export const clearEmployeeDataChangeRequestsFilter =
  () => (dispatch: any, getState: any) => {
    dispatch(fromSlice.clear());
  };

export const udpateEmployeeDataChangeRequestsTablePagination =
  (pageInfo: any) => (dispatch: any, getState: any) => {
    // OPTIMISTIC UI UPDATE
    dispatch(fromSlice.udpateFilter(pageInfo));
    dispatch(fromGoalDataStore.fetchEmployeeDataChangeRequests());
  };
