import * as fromInterface from "../../../ts";
import { SelectedSectionOptionState } from "./slice";

const selectSelectedSectionOptionCurrentSlice = (state: any): SelectedSectionOptionState => {
    return state.dataStore.selectedSectionOption;
};

export const selectSelectedSectionOptionStatus = (state: any): fromInterface.loadingType => {
    return selectSelectedSectionOptionCurrentSlice(state).status;
};

export const selectSelectedSectionOptionLoaded = (state: any): boolean => {
    return selectSelectedSectionOptionCurrentSlice(state).loaded;
};

export const selectSelectedSectionOption = (state: any) => {
    return selectSelectedSectionOptionCurrentSlice(state).selectedSectionId;
};