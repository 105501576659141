// @ts-nocheck 
import React, { Component } from 'react'
import queryString from 'query-string';
import isEmpty from 'isEmpty'
import InputGroup from "components/form";
import { connect } from "react-redux";
// import complogo from "../../img/compLogo.png";
import Modal from "components/modal";
import classnames from "classnames";
import { resetPassword } from 'actions/authAction';
import './reset.styles.scss';
import PasswordSvg from './passwordSvg'

export class ResetPasswordComp extends Component<any,any>{
    constructor(props) {
        super(props);
        this.state = {
            UserId: "",
            Code: "",
            Password: "",
            ConfirmPassword: '',
            errors: {},
            modalOpen: false,
            title:'',
            content:'',
            loadingState:false,
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onModalClose = this.onModalClose.bind(this);
    }
    async componentDidMount() {
        let params:any = queryString.parse(this.props.location.search);
        console.log(params)
        if(!isEmpty(params)){
            if(params.token && !isEmpty(params.token)){
                let newCode = params.token.split(" ").join("+",);
                this.setState({Code:newCode});
            }
            if(params.verify && !isEmpty(params.verify)){
                let newCode = params.verify.split(" ").join("+",);
                this.setState({UserId:newCode});
                return;
            }
            this.setState({modalOpen:true,title:'Invalid Link',content:'There is no code or userid inside the link'})
            return;
        }
        this.setState({modalOpen:true,title:'Invalid Link',content:'There is no code and userid inside the link'})
    }
    onModalClose () {
        this.setState({ modalOpen: false,content:'',title:'' });
        this.props.history.push("/login")
    };
    onChange(name,value) {
        this.setState({ [name]: value });
    }
    onSubmit(e) {
        e.preventDefault();
        const { Code, Password,ConfirmPassword,UserId } = this.state;
        const data = {
            Code,
            UserId,
            Password,
            ConfirmPassword
        };
        
        const { isValid, errors } = this.onValidate(data);
        this.setState({ errors });
        if (!isValid) {
            return false;
        }
        this.props.resetPassword(data,this.props.history)
    }
    onValidate(data) {
        let errors:any = {};
        if (isEmpty(data.Code)) {
            errors.Code = "Wrong link code is required";
        }
        if (isEmpty(data.Password)) {
            errors.Password = "Password is required";
        }
        if (isEmpty(data.ConfirmPassword)) {
            errors.ConfirmPassword = "ConfirmPassword is required";
        }
        if (!isEmpty(data.Password) && !isEmpty(data.ConfirmPassword) && data.Password !== data.ConfirmPassword) {
            errors.Password = "Passwords do not match";
        }
        return { isValid: isEmpty(errors), errors };
    }
    render() {
        const loginLoading =false;
        return (
            <div className="reset">
                <div className="reset-left">
                    <div className="reset-container">
                        <div className="reset-header">
                            <h4 className="reset-heading">
                                Reset your password
                            </h4>
                        </div>
                        <form className="reset-form" onSubmit={this.onSubmit}>
                            {/* <InputGroup
                                label="Code"
                                name="Code"
                                type="text"
                                placeholder="Code"
                                onChange={this.onChange}
                                value={this.state.Code}
                                error={this.state.errors.Code}
                                disabled={true}
                            />
                            <InputGroup
                                label="UserId"
                                name="UserId"
                                type="text"
                                placeholder="UserId"
                                onChange={this.onChange}
                                value={this.state.UserId}
                                error={this.state.errors.UserId}
                                disabled={true}
                            /> */}
                            <InputGroup
                                label="Password"
                                name="Password"
                                type="password"
                                placeholder="Password"
                                onChange={this.onChange}
                                value={this.state.Password}
                                error={this.state.errors.Password}
                                autoFocus
                            />
                            <InputGroup
                                label="Confirm Password"
                                name="ConfirmPassword"
                                type="password"
                                placeholder="ConfirmPassword"
                                onChange={this.onChange}
                                value={this.state.ConfirmPassword}
                                error={this.state.errors.ConfirmPassword}
                            />
                            {
                                !isEmpty(this.state.errors) ?
                                <div className="error__message">
                                    {typeof this.state.errors === 'string' && this.state.errors}
                                </div>
                                :null
                            }
                            <button 
                                disabled={loginLoading}
                                className="reset-submit flex"
                                type="submit">
                                    {
                                        loginLoading?
                                        <>
                                        <span className="button-spin"></span>
                                        <span>Please wait...</span>
                                        </>
                                        
                                        :'Reset password'
                                    }
                            </button>
                            <div className="reset-form__forgot">
                                <span
                                    className="forgot-link"
                                    onClick={() =>
                                        this.props.history.push("/login")
                                    }
                                >
                                    Go back to login page
                                </span>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="reset-right">
                    <div className="reset-image">
                        {/* <PasswordSvg /> */}
                    </div>
                </div>
                <Modal 
                    open={this.state.modalOpen}
                    type="dangerContent"
                    title={this.state.title}
                    content={this.state.content}
                    onModalClose={this.onModalClose}
                />
            </div>
        )
    }
}

const mapDispatchToProps = {
    resetPassword
}

export default connect(null,mapDispatchToProps)(ResetPasswordComp)
