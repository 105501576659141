// DUPLICATE OF VALIDATION ACTION FROM HRMS CORE
import {
    ADD_MESSAGE,
    CLEAR_MESSAGE,
    REMOVE_MESSAGE,
    ADD_MESSAGES,
    ADD_OTHER_MESSAGES,
} from "./types";
import shortid from "shortid";

export interface validateAction {
    message: any,
    type: string,
    position: string,
    id: string
}

interface dispatchAction {
    type: string;
    payload: object | string | [];
}

export const addMessage = (data: validateAction) => (dispatch: (action: dispatchAction) => dispatchAction) => {
    dispatch({
        type: ADD_MESSAGE,
        payload: {
            type: data.type,
            message: data.message,
            position: data.position,
        },
    });
};

export const addMessages = (data: validateAction) => (dispatch: (action: dispatchAction) => dispatchAction) => {
    dispatch({
        type: ADD_MESSAGES,
        payload: {
            type: data.type,
            message: data.message,
            position: data.position,
            id: shortid.generate(),
        },
    });
};

export const clearOtherMessage = () => (dispatch: (action: dispatchAction) => dispatchAction) => {
    dispatch({
        type: ADD_OTHER_MESSAGES,
        payload: {},
    });
};

export const toastMessage = (dispatch: any, res: any) => {
    let MessageType = null;
    let Message = [];

    if (res) {
        if (res.MessageType) {
            MessageType = res.MessageType;
        } else if (res.data && res.data.MessageType) {
            MessageType = res.data.MessageType;
        }

        if (res.Message) {
            if (res.Message[0] === "") return;
            Message = res.Message;
        } else if (res.data && res.data.Message) {
            Message = res.data.Message;
        }
    }
    if (MessageType == null) MessageType = "Success";

    if (MessageType && Message?.length > 0) {
        dispatch({
            type: ADD_MESSAGE,
            payload: { type: MessageType, message: Message },
        });
    }
};

export const removeMessage = (id: string) => (dispatch: (action: dispatchAction) => dispatchAction) => {
    dispatch({
        type: REMOVE_MESSAGE,
        payload: id,
    });
};

export const clearMessage = () => (dispatch: (action: dispatchAction) => dispatchAction) => {
    dispatch({
        type: CLEAR_MESSAGE,
        payload: {},
    });
};
