import * as fromInterface from "../../../ts";
import { RatingScaleState } from "./slice";

const selectRatingScaleCurrentSlice = (state: any): RatingScaleState => {
    return state.dataStore.ratingScale;
};

export const selectRatingScaleStatus = (state: any): fromInterface.loadingType => {
    return selectRatingScaleCurrentSlice(state).status;
};

export const selectRatingScaleLoaded = (state: any): boolean => {
    return selectRatingScaleCurrentSlice(state).loaded;
};

export const selectRatingScaleItems = (state: any) => {
    return selectRatingScaleCurrentSlice(state).items;
};

export const selectRatingScaleDropdownOptions = (state: any) => {
    return selectRatingScaleCurrentSlice(state).items.map(item => {
        return {
            label: item.ScaleName,
            value: item.Id
        }
    });
}