// LIBS
import { ApiClient } from '../../../network/apiClient';

// SLICE
import * as fromSlice from './slice';

// INTERFACE
import * as fromInterface from '../../../ts';
import { z } from 'zod';

// STORE
import * as fromCurrentFormDetailStore from '../current-form-detail';
import * as fromPerformanceFormWeightStore from '../performance-form-weight';
import * as fromPerformanceFormStateStore from '../performance-form-state';
import { REMOVE_SECTION_CONTENT, SAVE_SECTION_CONTENT } from './api';

export const syncFormState =
  (formState: any): any =>
  (dispatch: any) => {
    dispatch(fromSlice.syncFormState(formState));
  };

export const addToContent = (): any => (dispatch: any) => {
  dispatch(fromSlice.addContent());
};

type sectionContentSave = {
  id?: number;
  performanceFormId?: number;
  performanceSectionId?: number;
  doNotMark?: boolean;
  maximumMarks?: number;
  title?: string;
  Title?: string;
  textBlock?: string;
  allowComments?: boolean;
  sectionContentOrder?: number;
  isImport: boolean;
  options?: {
    name: string;
  }[];
};

export const saveContent =
  (params: { index: number; contentData: any }): any =>
  async (dispatch: any, getState: any) => {
    const state = getState();

    const currentPerformanceForm =
      fromCurrentFormDetailStore.selectCurrentFormDetail(state);

    // // todo: verify later
    // if (!currentPerformanceForm?.AllowDifferentWeight) {
    //   return;
    // }
    const formState = fromPerformanceFormStateStore.selectFormState(state);

    const { Id: performanceSectionId, PerformanceFormId: performanceFormId } =
      formState;

    const { id, ...rest } = params.contentData;

    const finalPayload: sectionContentSave = {
      ...rest,
      performanceSectionId,
      performanceFormId,
      sectionContentOrder: 0,
    };

    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.post<any>({
        endpoint: SAVE_SECTION_CONTENT,
        body: finalPayload,
        dispatch: dispatch,
        showMessage: true,
      });

    if (response?.Status) {
      const newContents = [
        {
          ...params.contentData,
          Id: response?.Data?.Id,
          Options: response?.Data?.Options,
          AllowComments: response.Data?.AllowComments,
        },
      ];
      const finalized = {
        ...formState,
        ImportGoals: response?.Data.IsImport,
        Contents: [...formState.Contents, ...newContents],
      };

      console.log('given input', params);
      dispatch(syncFormState(finalized));

      dispatch(
        fromPerformanceFormWeightStore.PerformanceFormWeightStore.fetchPerformanceFormWeights(
          {
            formId: performanceFormId,
          },
        ),
      );
    }
  };

export const saveTextBlockContent =
  (params: { index: number; contentData: any }): any =>
  async (dispatch: any, getState: any) => {
    const state = getState();

    const formState = fromPerformanceFormStateStore.selectFormState(state);

    const { Id: performanceSectionId, PerformanceFormId: performanceFormId } =
      formState;

    const { id, ...rest } = params.contentData;

    const finalPayload: sectionContentSave = {
      ...rest,
      performanceSectionId,
      performanceFormId,
      sectionContentOrder: 0,
    };

    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.post<any>({
        endpoint: SAVE_SECTION_CONTENT,
        body: finalPayload,
        dispatch: dispatch,
        showMessage: true,
      });

    if (response?.Status) {
      const mappedContents = formState.Contents.map(
        (Content: any, ContentIdx: number) => {
          if (ContentIdx === params.index) {
            return {
              Id: response.Data.Id,
              SectionId: '',
              ContentWeight: null,
              TextBlock: params.contentData.TextBlock,
              Title: params.contentData.Title || '',
              DoNotMark: params.contentData.DoNotMark || true,
              TotalMarks: params.contentData.TotalMarks || null,
              AllowComments: params.contentData.AllowComments || false,
              AllowCommentForEachOptions: false,
              SectionContentOrder: 0,
              Options: [],
            };
          }

          return Content;
        },
      );

      const finalized = {
        ...formState,
        ImportGoals: response?.Data.IsImport,
        Contents: mappedContents,
      };

      console.log('given input', params);
      dispatch(syncFormState(finalized));

      dispatch(
        fromPerformanceFormWeightStore.PerformanceFormWeightStore.fetchPerformanceFormWeights(
          {
            formId: performanceFormId,
          },
        ),
      );
    }
  };
export const saveGoalActionContent =
  (params: { index: number; contentData: any }): any =>
  async (dispatch: any, getState: any) => {
    const state = getState();

    const formState = fromPerformanceFormStateStore.selectFormState(state);

    const { Id: performanceSectionId, PerformanceFormId: performanceFormId } =
      formState;

    const { id, ...rest } = params.contentData;

    const finalPayload: sectionContentSave = {
      ...rest,
      performanceSectionId,
      performanceFormId,
      sectionContentOrder: 0,
    };

    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.post<any>({
        endpoint: SAVE_SECTION_CONTENT,
        body: finalPayload,
        dispatch: dispatch,
        showMessage: true,
      });

    if (response?.Status) {
      const newContents = [
        {
          ...params.contentData,
          Id: response?.Data?.Id,
          Options: response?.Data?.Options,
          AllowComments: response.Data?.AllowComments,
        },
      ];
      const finalized = {
        ...formState,
        ImportGoals: response?.Data.IsImport,
        Contents: newContents,
      };

      dispatch(syncFormState(finalized));

      dispatch(
        fromPerformanceFormWeightStore.PerformanceFormWeightStore.fetchPerformanceFormWeights(
          {
            formId: performanceFormId,
          },
        ),
      );
    }
  };

export const saveMatrixRatingContent =
  (params: { index: number; contentData: any }): any =>
  async (dispatch: any, getState: any) => {
    const state = getState();

    const formState = fromPerformanceFormStateStore.selectFormState(state);

    const { Id: performanceSectionId, PerformanceFormId: performanceFormId } =
      formState;

    const { id, ...rest } = params.contentData;

    const finalPayload: sectionContentSave = {
      ...rest,
      performanceSectionId,
      performanceFormId,
      sectionContentOrder: 0,
    };

    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.post<any>({
        endpoint: SAVE_SECTION_CONTENT,
        body: finalPayload,
        dispatch: dispatch,
        showMessage: true,
      });

    if (response?.Status) {
      const nextFormState = {
        ...formState,
        ImportGoals: response?.Data.IsImport,
        Contents: formState.Contents.map((Content: any, ContentIdx: number) => {
          if (ContentIdx === params.index) {
            return {
              ...params.contentData,
              Id: response.Data.Id,
              Options: response?.Data?.Options,
              AllowComments: response.Data?.AllowComments,
              // AllowComments: params.contentData.AllowComments || false,
            };
          }

          return Content;
        }),
      };

      dispatch(syncFormState(nextFormState));

      dispatch(
        fromPerformanceFormWeightStore.PerformanceFormWeightStore.fetchPerformanceFormWeights(
          {
            formId: performanceFormId,
          },
        ),
      );
    }
  };

export const enableEditMode =
  (payload: any): any =>
  (dispatch: any) => {
    dispatch(fromSlice.enableEditMode(payload));
  };

export const cancelEdit = (): any => (dispatch: any) => {
  dispatch(fromSlice.cancelEdit());
};

export const removeContent =
  (id: number): any =>
  async (dispatch: any, getState: any) => {
    const state = getState();
    const currentPerformanceForm =
      fromCurrentFormDetailStore.selectCurrentFormDetail(state);

    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.remove<any>({
        endpoint: REMOVE_SECTION_CONTENT.replace(':sectionContentId', id + ''),
        dispatch: dispatch,
        showMessage: true,
      });

    if (response?.Status) {
      dispatch(
        fromPerformanceFormWeightStore.PerformanceFormWeightStore.fetchPerformanceFormWeights(
          {
            formId: currentPerformanceForm?.Id,
          },
        ),
      );
      dispatch(fromSlice.removeContent(id));
    }
  };

export const formStateSchema = z.object({
  Id: z.number(),
  PerformanceFormId: z.number(),
  ContentType: z.number(),
  ContentTypeName: z.string(),
  Order: z.number(),
  Name: z.string(),
  IntroText: z.string(),
  RatingScaleId: z.number(),
  RatingScaleName: z.string(),
  Weight: z.null(),
  ToBeAnsweredBy: z.string(),
  AssessmentType: z.number(),
  AllowSelfMarking: z.boolean(),
  ImportGoals: z.boolean(),
  ImportKSA: z.null(),
  RatingScaleOptions: z.array(
    z.object({
      SectionId: z.number(),
      RatingScaleId: z.number(),
      ScaleName: z.string(),
      IsIncludeNA: z.boolean(),
      RatingScaleOptionId: z.number(),
      OptionName: z.string(),
      Score: z.number(),
    }),
  ),
  Contents: z.array(
    z.object({
      Id: z.any(),
      // SectionId: z.number(),
      // ContentWeight: z.null(),
      // TextBlock: z.string(),
      // Title: z.null(),
      // DoNotMark: z.boolean(),
      // TotalMarks: z.null(),
      // AllowComments: z.boolean(),
      // SectionContentOrder: z.number(),
      Options: z.array(
        z.any(),
        // z.object({
        //   // Id: z.number(),
        //   // SectionId: z.number(),
        //   // SectionContentId: z.number(),
        //   Name: z.string(),
        //   TotalMarks: z.null(),
        // }),
      ),
    }),
    // z.unknown(),
  ),
});

type formStateType = z.infer<typeof formStateSchema>;
