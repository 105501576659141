import { Input } from '@chakra-ui/react';
import { UncontrollerComponentProps } from './interface';
import { useFroalaEditor } from './use-froala-editor';
import RigoFroalaEditor from 'react-froala-wysiwyg';

export const FroalaEditorConfig = {
  key: 'UBB7jF4D5H5D3G3C7aIVLEABVAYFKc1Ce1MYGD1c1NYVMiB3B9B6D5C2C4G4H3G3H3==',
  attribution: false,

  toolbarButtons: [
    'bold',
    'italic',
    'underline',
    'strikeThrough',
    'blockStyle',
    'inlineStyle',
    'align',
    'fontSize',
  ],
};

export const RigoUncontrolledComponent = (
  props: UncontrollerComponentProps,
) => {
  const { onChangeRHF, value: rhfValue } = props;
  const {
    name,
    value, // this is user defined value for uncontrolled component

    label,
    control,
    errors,
    required,
    rule,

    // this is user defined value for uncontrolled component
    onChange: _onChange,

    ...contextRest
  } = useFroalaEditor();

  const handleChange = (value: any) => {
    _onChange?.(name, value);
    onChangeRHF?.(value);
  };

  const valueNormalized = rhfValue ?? value;

  const inputProps = {
    name,
    model: valueNormalized,
    ...contextRest,
  };

  return (
    <RigoFroalaEditor
      config={FroalaEditorConfig}
      onModelChange={handleChange}
      {...inputProps}
    />
  );
};
