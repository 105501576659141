

import React, { lazy } from "react";
import RouteEnum from "../../../constants/routeEnum";


// Task Bords Components
const Boards = React.lazy(() => import("../../../scenes/tasks/boards"));
const Board = React.lazy(() => import("../../../scenes/tasks/board"));
const BoardList = React.lazy(() => import("../../../scenes/tasks/boardsList"));
const TaskTodo = React.lazy(() => import("../../../scenes/tasks/taskTodo"));
const TaskSummary = React.lazy(() => import("../../../scenes/tasks/taskSummary"));
const BoardActivityReport = React.lazy(() => import("../../../scenes/tasks/boardActivityReport"));



const routes = (features) => [
    {
        path: RouteEnum.TaskBoards,
        component: Boards,
        // title: "Tasks Boards",
        feature: features.TaskManagement,
        children: taskboardLinks,
    },
    {
        path: RouteEnum.TaskSummary,
        exact: true,
        component: TaskSummary,
        feature: features.TaskManagement,
        title: "Tasks Summary",
    },
    {
        path: RouteEnum.TaskBoardActitityReport,
        exact: true,
        component: BoardActivityReport,
        feature: features.TaskManagement,
        title: "Tasks Summary",
    }]


const taskboardLinks = [
    {
        path: RouteEnum.TaskBoardSingleLink + ":board",
        exact: true,
        component: Board,
        title: "Tasks Boards",
    },

    {
        path: RouteEnum.TaskBoardListLinks,
        exact: true,
        component: BoardList,
        title: "Tasks Boards",
    },
    {
        path: RouteEnum.TaskBoardTodo,
        exact: true,
        component: TaskTodo,
        title: "Tasks To Do",
    },
];

export default routes;