import {
  SAVE_ONBOARDING_EMPLOYEE,
  GET_ONBOARDING_EMPLOYEE_DETAIL,
  SAVE_EMPLOYEE_ONBOARDING_CHECKLIST,
  SET_CHECKLIST,
  CLEAR_EMPLOYEE_ONBOARDING_CHECKLIST,
  GET_ONBOARDING_EMPLOYEE_LIST,
  DELETE_ONBOARDING_EMPLOYEE,
  GET_ONBOAREDING_EMPLOYEE_EMAIL_PREVIEW,
  GET_EMPLOYEE_SALARY_TEMPLATE_DETAILS,
  GET_ON_BOARDING_ACCESS_ID_CATEGORIES,
  GET_ON_BOARDING_CHECKLIST_CATEGORIES,
  SAVE_ONBOARDING_ACCESSID,
  SAVE_ONBOARDING_CHECKLISTS,
  SAVE_EMPLOYEE_INTRO,
  REQUEST_ONBOARDING_EMPLOYEE,
  MESSAGE_TEMPLATE_LIST,
} from "actions/types";

const initialState = {
  checkList: {},
  saveOnboardingEmployee: false,
  onboardingEmployeeDetail: {},
  onboardingEmployeeList: [],
  requesting: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REQUEST_ONBOARDING_EMPLOYEE: {
      return {
        ...state,
        requesting: true,
      };
    }
    case MESSAGE_TEMPLATE_LIST: {
      return {
        ...state,
        loading: false,
        messageTemplateList: action.payload,
      };
    }
    case SAVE_ONBOARDING_EMPLOYEE: {
      return {
        ...state,
        saveOnboardingEmployee: action.payload,
        requesting: action.requesting,
      };
    }
    case GET_ONBOARDING_EMPLOYEE_DETAIL: {
      let checkListMenu = state.onboardingEmployeeDetail?.EmployeeOnboardingCheckList;
      checkListMenu =
        checkListMenu &&
        checkListMenu.map((item) => {
          return {
            ...item,
            Checked: item?.IsSelected || item?.Checked,
          };
        });
      let EmployeeOnboardingCheckList = checkListMenu;

      return {
        ...state,
        onboardingEmployeeDetail: {
          ...state.onboardingEmployeeDetail,
          ...action.payload,
          // EmployeeOnboardingCheckList,
        },
        requesting: action.requesting,
      };
    }
    case SAVE_EMPLOYEE_ONBOARDING_CHECKLIST: {
      return {
        ...state,
        onboardingEmployeeDetail: {
          ...state.onboardingEmployeeDetail,
          EmployeeOnboardingCheckList: action.payload,
        },
      };
    }
    case SET_CHECKLIST:
      return {
        ...state,
        checkList: action.payload,
      };
    case CLEAR_EMPLOYEE_ONBOARDING_CHECKLIST: {
      return {
        ...state,
        onboardingEmployeeDetail: {},
        checkList: {},
      };
    }
    case GET_ONBOARDING_EMPLOYEE_LIST: {
      return {
        ...state,
        onboardingEmployeeList: action.payload,
      };
    }
    case DELETE_ONBOARDING_EMPLOYEE: {
      return {
        ...state,
        deleteOnboardingEmployee: true,
        onboardingEmployeeList: state.onboardingEmployeeList.filter(
          (onboardingEmployee) => onboardingEmployee.EmployeeId !== action.payload
        ),
      };
    }
    case GET_ONBOAREDING_EMPLOYEE_EMAIL_PREVIEW: {
      return {
        ...state,
        employeePreviewEmail: action.payload,
      };
    }
    case GET_EMPLOYEE_SALARY_TEMPLATE_DETAILS: {
      return {
        ...state,
        employeeSalaryTemplateDetails: action.payload,
      };
    }
    case GET_ON_BOARDING_ACCESS_ID_CATEGORIES: {
      return {
        ...state,
        accessIdTemplates: action.payload,
      };
    }
    case GET_ON_BOARDING_CHECKLIST_CATEGORIES: {
      return {
        ...state,
        checkListCategories: action.payload,
      };
    }
    case SAVE_ONBOARDING_ACCESSID: {
      return {
        ...state,
        saveOnboardingAccessId: action.payload,
      };
    }
    case SAVE_ONBOARDING_CHECKLISTS: {
      return {
        ...state,
        saveOnboardingCheckLists: action.payload,
        requesting: action.requesting,
      };
    }
    case SAVE_EMPLOYEE_INTRO: {
      return {
        ...state,
        employeeIntroSaved: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
