
import * as fromSlice from "./slice";
import * as fromSelector from "./selectors"
import * as fromPerformanceRolloutEmployeeDataStore from "../performance-rollout-employee"
import * as fromPerformanceRolloutAppraisalDataStore from "../performance-rollout-appraisal"

export const udpatePerformanceRolloutEmployeeFilter = (newFilters: any): any => (dispatch: any, getState: any) => {
    dispatch(fromSlice.udpateFilter(newFilters))
}
export const fetchPerformanceRolloutEmployees = (): any => async (dispatch: any, getState: any) => {
    const state = getState()
    dispatch(fromPerformanceRolloutEmployeeDataStore.fetchPerformanceRolloutEmployeeList())
}
export const udpatePerformanceRolloutEmployeeFilterAndFetch = (newFilters: fromSlice.PerformanceRolloutEmployeeFilterState) => async (dispatch: any, getState: any) => {

    dispatch(fromSlice.udpateFilter(newFilters))
    dispatch(fromPerformanceRolloutEmployeeDataStore.fetchPerformanceRolloutEmployeeList())
}

export const clearPerformanceRolloutEmployeeFilter = () => (dispatch: any) => {
    dispatch(fromSlice.clear())
}  