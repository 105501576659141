// @ts-nocheck 
import React, {useRef, useEffect, useState} from "react";
import {
  getUser,
  getBaseURL,
  getPublicRecruitmentBaseURL,
  loginUser,
  refreshToken,
  getIsHRorFinance,
  getHrCommonSettings,
} from "actions/authAction";
import {
  GetCompanyHolidaysWhole,
  GetCompanyInformation,
  GetEmpMenus,
} from "actions/commonAction";
import {useDispatch, useSelector} from "react-redux";
import {
  getFeatureFlag,
  getActiveFeatures,
  getActiveFeatureFlag,
  activeFlags,
} from "actions/permissionsAction";
import {roles} from "constants/constants";
import isEmpty from "helpers/isEmpty";
import { useHistory } from "react-router";

function useCommonAPIs() {
  const dispatch = useDispatch();
  const [loading,setLoading] = useState(false);
  const {isAuthenticated, user, baseUrl} = useSelector((state: any) => state.auth);
  const {activeFeatureFlags} = useSelector((state: any) => state.permissionReducer);
  const {isRequestedForCompanyInformation} = useSelector(
    (state: any) => state.commonReducer
  );
  const {refresh_loading} = useSelector((state: any) => state.loadingReducer);
  useEffect(() => {
    (async () => {
      try{
        setLoading(true)
        await dispatch(getBaseURL());
        await dispatch(getPublicRecruitmentBaseURL());
        (!activeFeatureFlags || activeFeatureFlags?.length === 0) &&
          (await dispatch(getActiveFeatureFlag()));
        await dispatch(getFeatureFlag());
        // await dispatch(getActiveFeatures());
        // await dispatch(GetEmpMenus());
  
        // await dispatch(GetCompanyHolidaysWhole());
      }catch(err){
        console.error({err})
      }finally{
        setLoading(false)
      }

    })();
  }, []);
  let a: any = roles();
  useEffect(() => {
    if (
      isAuthenticated &&
      baseUrl &&
      !isEmpty(user) 
      && !isRequestedForCompanyInformation
    ) {
      dispatch(
        GetCompanyInformation(a.includes(user.RoleId), baseUrl?.NetFrameworkAppBaseUrl)
      );
      
    }
    if(user?.RoleName === 'Appraisal' && baseUrl){
      baseUrl?.NetFrameworkAppBaseUrl &&
        window.location.replace(baseUrl?.NetFrameworkAppBaseUrl);
    }

    // if (
    //   isAuthenticated &&
    //   baseUrl &&
    //   !isEmpty(user) &&
    //   user.RoleName !== "Employee" &&
    //   user.RoleName !== "Administrator"
    // ) {
      // baseUrl?.NetFrameworkAppBaseUrl &&
      //   window.location.replace(baseUrl?.NetFrameworkAppBaseUrl);
    // }
  }, [isAuthenticated, baseUrl, user]);

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        try{
          setLoading(true)
          const promiseArray = [
            dispatch(getUser()),
            // dispatch(GetCompanyHolidaysWhole()),
            dispatch(getIsHRorFinance()),
            dispatch(getHrCommonSettings()),
          ];
          await Promise.all(promiseArray);
        }catch(err){
          console.error({err})
        }finally{
          setLoading(false)
        }
      })();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (user.RoleName === "Employee") dispatch(GetEmpMenus());
    // else if (user.RoleName === "Administrator") dispatch(getActiveFeatures());
    else dispatch(getActiveFeatures());
  }, [isAuthenticated, user]);

  return loading
}

export default useCommonAPIs;
