export const months = {
    en: [
        "Baisakh",
        "Jestha",
        "Asar",
        "Shrawan",
        "Bhadra",
        "Asoj",
        "Kartik",
        "Mangsir",
        "Pouse",
        "Magh",
        "Falgun",
        "Chaitra",
    ],
    ne: ["बैशाख", "जेठ", "असार", "सावन", "भदौ", "असोज", "कार्तिक", "मंसिर", "पौष", "माघ", "फागुन", "चैत"],
}

export const weeks = {
    en: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    ne: ["आईत", "सोम", "मंगल", "बुध", "बिही", "शुक्र", "शनि"],
}

export const maxBSYear = 2100
export const minBSYear = 1970

export const bsMonthMaxDays = [
    [30, 31],
    [31, 32],
    [31, 32],
    [31, 32],
    [31, 32],
    [30, 31],
    [29, 30],
    [29, 30],
    [29, 30],
    [29, 30],
    [29, 30],
    [30, 31],
]

export const bsMonthCalculatedData = [
    [0, 1, 1, 22, 1, 3, 1, 1, 1, 3, 1, 22, 1, 3, 1, 3, 1, 22, 1, 3, 1, 19, 1, 3, 1, 1, 3, 1, 2, 2, 1, 3, 1],
    [
        1,
        2,
        2,
        2,
        2,
        2,
        2,
        1,
        3,
        1,
        3,
        1,
        2,
        2,
        2,
        3,
        2,
        2,
        2,
        1,
        3,
        1,
        3,
        1,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        1,
        3,
        1,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        1,
        3,
        1,
        2,
        2,
        2,
        2,
        2,
        1,
        1,
        1,
        2,
        2,
        2,
        2,
        2,
        1,
        3,
        1,
        1,
        2,
    ],
    [
        0,
        1,
        2,
        1,
        3,
        1,
        3,
        1,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        3,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        1,
        3,
        1,
        3,
        1,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        1,
        3,
        1,
        3,
        1,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        1,
        3,
        1,
        3,
        1,
        1,
        1,
        1,
        2,
        2,
        2,
        2,
        2,
        1,
        3,
        1,
        1,
        2,
    ],
    [
        1,
        2,
        1,
        3,
        1,
        3,
        1,
        3,
        1,
        3,
        1,
        3,
        1,
        3,
        1,
        3,
        1,
        3,
        1,
        3,
        1,
        3,
        1,
        3,
        1,
        3,
        1,
        3,
        1,
        2,
        2,
        2,
        1,
        3,
        1,
        3,
        1,
        3,
        1,
        3,
        1,
        3,
        1,
        2,
        2,
        2,
        1,
        3,
        1,
        3,
        1,
        3,
        1,
        3,
        1,
        3,
        1,
        3,
        2,
        2,
        1,
        3,
        1,
        2,
        2,
        2,
        1,
        2,
    ],
    [59, 1, 26, 1, 28, 1, 2, 1, 12],
    [
        0,
        1,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        1,
        3,
        1,
        3,
        1,
        3,
        1,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        1,
        3,
        1,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        1,
        3,
        1,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        5,
        1,
        1,
        2,
        2,
        1,
        3,
        1,
        2,
        1,
        2,
    ],
    [0, 12, 1, 3, 1, 3, 1, 5, 1, 11, 1, 3, 1, 3, 1, 18, 1, 3, 1, 3, 1, 18, 1, 3, 1, 3, 1, 27, 1, 2],
    [
        1,
        2,
        2,
        2,
        2,
        1,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        3,
        1,
        3,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        1,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        1,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        1,
        2,
        2,
        2,
        15,
        2,
        4,
    ],
    [
        0,
        1,
        2,
        2,
        2,
        2,
        1,
        3,
        1,
        3,
        1,
        3,
        1,
        2,
        2,
        2,
        3,
        2,
        2,
        2,
        1,
        3,
        1,
        3,
        1,
        3,
        1,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        1,
        3,
        1,
        3,
        1,
        3,
        1,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        1,
        3,
        1,
        3,
        1,
        2,
        2,
        2,
        15,
        2,
        4,
    ],
    [
        1,
        1,
        3,
        1,
        3,
        1,
        14,
        1,
        3,
        1,
        1,
        1,
        3,
        1,
        14,
        1,
        3,
        1,
        3,
        1,
        3,
        1,
        18,
        1,
        3,
        1,
        3,
        1,
        3,
        1,
        14,
        1,
        3,
        15,
        1,
        2,
        1,
        1,
    ],
    [
        0,
        1,
        1,
        3,
        1,
        3,
        1,
        10,
        1,
        3,
        1,
        3,
        1,
        1,
        1,
        3,
        1,
        3,
        1,
        10,
        1,
        3,
        1,
        3,
        1,
        3,
        1,
        3,
        1,
        14,
        1,
        3,
        1,
        3,
        1,
        3,
        1,
        3,
        1,
        10,
        1,
        20,
        1,
        1,
        1,
    ],
    [
        1,
        2,
        2,
        1,
        3,
        1,
        3,
        1,
        3,
        1,
        2,
        2,
        2,
        2,
        2,
        3,
        2,
        2,
        2,
        2,
        2,
        1,
        3,
        1,
        3,
        1,
        3,
        1,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        1,
        3,
        1,
        3,
        1,
        3,
        1,
        3,
        1,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        1,
        3,
        1,
        3,
        1,
        20,
        3,
    ],
]
