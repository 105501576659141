import React, {useEffect} from "react";
import {Route, Redirect} from "react-router-dom";
import {connect, useSelector} from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "helpers/isEmpty";
import RouteEnum from "constants/routeEnum";
import OnlyDev from "components/common/onlyDev";
import FlagRoutes from "./flagRoutes";
import tokenService from "src/utils/tokenService";

const PrivateRoute = ({
  component: Component,
  children,
  title,
  auth,
  render,
  isNotPrivate,
  isAdmin,
  isEmployee,
  isDev,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <ShowRoutes
            {...props}
            {...rest}
            auth={auth}
            isNotPrivate={isNotPrivate}
            children={children}
            Component={Component}
            isDev={isDev}
            isAdmin={isAdmin}
            isEmployee={isEmployee}
            title={title}
          />
        );
      }}
    />
  );
};

export const ShowRoutes = ({
  Component,
  children,
  title,
  auth,
  isNotPrivate,
  isAdmin = false,
  isTeamManager,
  isDev,
  isEmployee,
  ...rest
}) => {
  const {isAuthenticated, user: uerRed} = auth;
  const userDetails = tokenService.getUserDetail(); /// localStorage.getItem("UserDetail");
  // const user = tokenService.getUserDetail();
  const user = !isEmpty(uerRed) ? uerRed : userDetails;
  const baseUrl = useSelector((state) => state.auth.baseUrl);
  const base = baseUrl ? baseUrl.NetFrameworkAppBaseUrl : "";
  useEffect(() => {
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const {
      location: {pathname},
    } = rest;
    if (!isEmpty(title)) {
      document.title = title;
    }
  }, []);

  if (isAuthenticated === true || isNotPrivate) {
    // console.log({isNotPrivate, isDev, isEmployee, isAdmin, user});
    let notTeamManager = !(isTeamManager && user.IsShiftTeamManager);
    const isEmployeeType = user?.IsEmployeeType ?? false;
    const isEmp = user?.IsEmployeeType;
    if (isAdmin && isEmployeeType && notTeamManager) {
      return <Redirect to={{pathname: RouteEnum.EmployeeDashBoard}} />;
    }
    if (
      isEmployee &&
      user &&
      !isEmployeeType &&
      // user.RoleId !== 2 &&
      user?.RoleName !== "OnboardingEmployee"
    ) {
      return <Redirect to={{pathname: RouteEnum.HRDashboardPath}} />;
    }
    if (isDev) {
      return (
        <OnlyDev>
          <Component baseUrl={base} {...rest} children={children} />
        </OnlyDev>
      );
    }
    // console.log({rest})
    return (
      <FlagRoutes feature={rest.feature}>
        <Component baseUrl={base} {...rest} children={children} />
      </FlagRoutes>
    );
  }
  return <Redirect to={{pathname: "/login", state: {auth: false}}} />;
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(PrivateRoute);
