import React from 'react';

export enum StepperEnums{
    authentication=1,
    chooseApp=5,
    setUp=2,
    code=3,
    completed=4,
}   

type TStep = {
    title:string,
    step:StepperEnums
}

export const steps = [
    {title:'Prepare',step:StepperEnums.authentication},
    // {title:'Choose an app',step:StepperEnums.chooseApp},
    {title:'Setup app',step:StepperEnums.setUp},
    {title:'Enter Code',step:StepperEnums.code},
    {title:'Completed',step:StepperEnums.completed},
]

interface IStepperContext{
    activeStep:StepperEnums;
    setActiveStepper?:Function;
    steps:Array<TStep>
}

const StepperContext = React.createContext<IStepperContext>({activeStep:StepperEnums.chooseApp,steps});


export default StepperContext;
