import React, { Component } from 'react';
import man from 'assets/images/man.png';
import FormGroup from 'components/form';
import isEmpty from 'isEmpty';
import { AiFillCloseCircle } from 'react-icons/ai';
import { connect } from 'react-redux';
import { getUploadPath } from 'constants/userDetails';
import Avatar from 'react-avatar';
import { ADMIN_ROLE_ID, TRAVEL_ROLE_ID } from 'constants/constants';

export class Create_ApproversTab extends Component {
  componentDidMount() {
    const { user } = this.props;
    if (user?.RoleId === ADMIN_ROLE_ID || user?.RoleId === TRAVEL_ROLE_ID) {
      let selectedApprovalList = [
        {
          Selected: false,
          Text: user?.EmployeeName,
          Value: user?.MappedEmployeeId,
          label: user?.EmployeeName,
          value: user?.MappedEmployeeId,
        },
      ];
      this.props.handleApproversTabState &&
        this.props.handleApproversTabState(
          'selectedApprovalList',
          selectedApprovalList,
        );
    }
  }

  handleApproverDropdown = (name, selected) => {
    let selectedApprovalList = [];
    if (!isEmpty(selected)) {
      selectedApprovalList.push(selected);
    }
    this.props.handleApproversTabState &&
      this.props.handleApproversTabState(name, selectedApprovalList);
  };

  handleRemove = value => {
    let { selectedApprovalList } = this.props.approverState;
    selectedApprovalList = selectedApprovalList.filter(f => f.value !== value);
    this.props.handleApproversTabState &&
      this.props.handleApproversTabState(
        'selectedApprovalList',
        selectedApprovalList,
      );
  };

  render() {
    const { approvalAuthorities, isViewMode, user } = this.props;
    const { selectedApprovalList } = this.props.approverState;

    let approvers = null;
    if (!isEmpty(approvalAuthorities?.value)) {
      approvers = approvalAuthorities?.value.AuthoritiesList;
    }

    return (
      <div className='travel-document-create'>
        <div className='travel-create__title'>
          <h3>Approvers</h3>
        </div>
        <div className='travel-create__body'>
          <div className='travel-detail-row'>
            <div className='travel-field'>
              {approvalAuthorities?.value?.IsFinalApprover &&
              user?.RoleId !== ADMIN_ROLE_ID &&
              user?.RoleId !== TRAVEL_ROLE_ID ? (
                <div className='selected-user'>
                  <Avatar
                    alt={user?.EmployeeName}
                    className='emp-img'
                    name={user?.EmployeeName}
                    round
                    size={40}
                    src={
                      !isEmpty(user?.UserImage)
                        ? getUploadPath(user?.UserImage)
                        : man
                    }
                  />
                  <h5 className='selected-user__name'>{user?.EmployeeName}</h5>
                </div>
              ) : (
                <FormGroup
                  formName='reactselect'
                  name='selectedApprovalList'
                  placeholder='Select approval'
                  value={
                    !isViewMode
                      ? selectedApprovalList && selectedApprovalList[0]
                        ? selectedApprovalList[0]
                        : null
                      : approvers[0]
                  }
                  options={approvers}
                  onChange={this.handleApproverDropdown}
                  disabled={
                    isViewMode ||
                    user?.RoleId === ADMIN_ROLE_ID ||
                    user?.RoleId === TRAVEL_ROLE_ID
                  }
                />
              )}

              {/* <br /> */}
              {selectedApprovalList &&
                selectedApprovalList.map((approver, index) => {
                  return (
                    <>
                      {isEmpty(approver?.label) ? null : (
                        <div className='selected-user' key={index}>
                          <img
                            src={
                              !isEmpty(approver.Imagepath)
                                ? getUploadPath(approver.Imagepath)
                                : man
                            }
                            className='avatar avatar-sm'
                            alt='Avatar'
                          />
                          <h5 className='selected-user__name'>
                            {approver.label}
                          </h5>
                          {!isViewMode &&
                            user?.RoleId !== ADMIN_ROLE_ID &&
                            user?.RoleId !== TRAVEL_ROLE_ID && (
                              <span
                                onClick={() =>
                                  this.handleRemove(approver.value)
                                }
                              >
                                <AiFillCloseCircle />
                              </span>
                            )}
                        </div>
                      )}
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  approvalAuthorities: state.travelInformationReducer.approvalAuthorities,
});

export default connect(mapStateToProps, null)(Create_ApproversTab);
