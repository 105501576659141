import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

export const selectSchema = z.object({
    label: z.string().nullable(),
    value: z.any().nullable(),
    TypeId:z.any().nullable(),
    Type:z.any().nullable(),
    ApproverTypeId:z.any().nullable(),
    ApproverId:z.any().nullable(),
    initiatorId:z.any().nullable(),
    Id:z.any().nullable(),
});
  

export const  leaveApprovalFlowFormSchema = z.object({
    FlowName: z.string({
        required_error: "Flow Name is required",
        invalid_type_error: "Flow Name must be a string",
    }),
    AppliesTo: selectSchema.required(),
    FlowActivities:z.array(selectSchema,{required_error: "Leave Flow activities field is required. Please choose atlease one flow activity item",}).nonempty({
        message:"Leave Flow activities field is required. Please choose atlease one flow activity item"
    }),
    FlowInitiators:z.array(selectSchema).nonempty({
        message:"Flow Initiators field is required. Please choose atlease one item"
    }),
    AdditionalApprovers:z.array(selectSchema).max(2,{message:'You are only allowed to select atmost 2 approvers'}).optional(),
    AdditionalApprovalRequired:z.boolean(),
    AdditionalApprovalActivationTime:z.any().optional(),
    LeeavesCount:selectSchema.optional(),
    Approvals:z.array(
        z.object({
            Approvers:
                z.array(selectSchema)
                .nonempty({message:"Approvers field is required"})
                .max(2,{message:"Please pick atmost of 2 approvers"})
        })
    ).max(5,{message:"You are allowed to add atmost of 7 levels"})
})
// .refine(input=>(!input.AdditionalApprovalRequired) || (input.AdditionalApprovalRequired===true && input.AdditionalApprovers),{
//     path: ["AdditionalApprovers","AdditionalApprovalActivationTime"],
//     message:"Additional Approvals Field is required",
// });


export type LeaveApprovalFlowForm = z.infer<typeof leaveApprovalFlowFormSchema>;
export const LeaveApprovalResolver = zodResolver(leaveApprovalFlowFormSchema)