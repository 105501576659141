// @ts-nocheck 
import {FAV_LINK, Toggle_FAV_LINK} from "store/types";
import {api, post, get} from "networkService";
import environment from "environment";

export const ToggleFavLink = (param: any) => async (dispatch: Function) => {
  const res: any = await post(
    environment.api.v1.common.toggleFavLink,
    dispatch,
    param,
    false
  );
  if (res.Status) {
    dispatch({type: Toggle_FAV_LINK, payload: res.Data});
  }
};

export const GetFavLink = (param: any) => async (dispatch: Function) => {
  dispatch({type: FAV_LINK, payload: []});
  const res: any = await get(environment.api.v1.common.getFavLinks, dispatch, param);
  dispatch({type: FAV_LINK, payload: res.Data});
};
