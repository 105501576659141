export enum featuresEnum {
  Default = 1,
  CoreHR = 2,
  Payroll = 3,
  JobProfile = 4,
  Leave = 5,
  Time = 6,
  Recruitment = 7,
  TaskManagement = 8,
  Chat = 9,
  Loan = 10,
  PerfomanceAppraisal = 11,
  Learning = 12,
  ShiftRoster = 13,
  "Project and Time Sheet" = 14,
  Training = 15,
  TravelManagement = 16,
  TaskTracker = 17,
  Settings = 18,
  GeoFencing = 34,
}
