import * as fromSlice from "./slice";

const selectCurrentGoalCategorySlice = (state: any): fromSlice.GoalCategoryState => {
    return state.dataStore.goalCategory;
};

export const selectGoalCategoryItems = (state: any): any[] => {
    return selectCurrentGoalCategorySlice(state).items;
};

export const selectGoalCategoryStatus = (state: any): boolean => {
    return selectCurrentGoalCategorySlice(state).loaded;
};

export const selectGoalCategoryLoaded = (state: any): boolean => {
    return selectCurrentGoalCategorySlice(state).loaded;
};