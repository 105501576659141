import React from "react";
import { ReactComponent as dragger } from "../../../assets/images/icons/drag.svg";
import { ReactComponent as Award } from "../../../assets/images/icons/award.svg";
import { ReactComponent as Brackets } from "../../../assets/images/icons/brackets.svg";
import { ReactComponent as DueDate } from "../../../assets/images/icons/due-date.svg";
import { ReactComponent as Priority } from "../../../assets/images/icons/priority.svg";
import { ReactComponent as Status } from "../../../assets/images/icons/status.svg";
import { ReactComponent as Team } from "../../../assets/images/icons/team.svg";
import { ReactComponent as BookReader } from "../../../assets/images/icons/book-reader.svg";
import { ReactComponent as EyeDropper } from "../../../assets/images/icons/eye-dropper.svg";
import { ReactComponent as SimlePlus } from "../../../assets/images/icons/smile-plus.svg";
import { ReactComponent as Simle } from "../../../assets/images/icons/smile.svg";
import { ReactComponent as AngleRight } from "../../../assets/images/icons/angle-right.svg";
import { ReactComponent as AngleLeft } from "../../../assets/images/icons/angle-left.svg";
import { ReactComponent as ArrowDown } from "../../../assets/images/icons/arrow-down.svg";
import { ReactComponent as ArrowUp } from "../../../assets/images/icons/arrow-up.svg";
import { ReactComponent as EllipsisV } from "../../../assets/images/icons/ellipsis-v.svg";
import { ReactComponent as ArrowCircleRight } from "../../../assets/images/icons/arrow-circle-right.svg";
import { ReactComponent as Tasks } from "../../../assets/images/icons/tasklist.svg";
import { ReactComponent as Allowance } from "../../../assets/images/icons/allowance.svg";
import { ReactComponent as Calculator } from "../../../assets/images/icons/payslip.svg";
import { ReactComponent as ApprovedCircle } from "../../../assets/images/icons/approved-check-circle.svg";
import { ReactComponent as Leave } from "../../../assets/images/icons/leave.svg";
import { ReactComponent as Home } from "../../../assets/images/icons/leave-time.svg";
import { ReactComponent as Shift } from "../../../assets/images/icons/shiftschedule.svg";
import { ReactComponent as Performance } from "../../../assets/images/icons/performance.svg";
import { ReactComponent as Scroll } from "../../../assets/images/icons/scroll.svg";
import { ReactComponent as Sliders } from "../../../assets/images/icons/sliders-h.svg";
import { ReactComponent as Techo } from "../../../assets/images/icons/tachometer-alt-fast.svg";
import { ReactComponent as User } from "../../../assets/images/icons/hr.svg";
import { ReactComponent as Users } from "../../../assets/images/icons/users-class.svg";
import { ReactComponent as Cubes } from "../../../assets/images/icons/cubes.svg";
import { ReactComponent as Cog } from "../../../assets/images/icons/users-cog.svg";
import { ReactComponent as Clipboard } from "../../../assets/images/icons/clipboard-user.svg";
import { ReactComponent as TaskAlt } from "../../../assets/images/icons/taskSvg.svg";
import { ReactComponent as Disciplinary } from "../../../assets/images/icons/disciplinary.svg";
import { ReactComponent as Job } from "../../../assets/images/icons/jobBreif.svg";
import { ReactComponent as FileInvoice } from "../../../assets/images/icons/file-invoice.svg";
import { ReactComponent as Repeat } from "../../../assets/images/icons/repeat.svg";
import { ReactComponent as Key } from "../../../assets/images/icons/key.svg";
import { ReactComponent as Sun } from "../../../assets/images/icons/sun.svg";
import { ReactComponent as Moon } from "../../../assets/images/icons/moon.svg";
import { ReactComponent as Times } from "../../../assets/images/icons/times.svg";
import { ReactComponent as SignOut } from "../../../assets/images/icons/sign-out.svg";
import { ReactComponent as Time } from "../../../assets/images/icons/time.svg";
import { ReactComponent as Travel } from "../../../assets/images/icons/travel.svg";
import { ReactComponent as Overtime } from "../../../assets/images/icons/overtime.svg";
import { ReactComponent as List } from "../../../assets/images/icons/details-list-ul.svg";
import { ReactComponent as Clock } from "../../../assets/images/icons/clock.svg";
import { ReactComponent as Forward } from "../../../assets/images/icons/forwarded.svg";
import { ReactComponent as ThumbsUp } from "../../../assets/images/icons/thumbs-up.svg";
import { ReactComponent as Document } from "../../../assets/icons/document.svg";
import { ReactComponent as Pdf } from "../../../assets/icons/pdf.svg";
import { ReactComponent as Applied } from "../../../assets/images/stageIcons/applied.svg";
import { ReactComponent as Delete } from "../../../assets/images/stageIcons/delete.svg";
import { ReactComponent as Hired } from "../../../assets/images/stageIcons/hired.svg";
import { ReactComponent as Interview } from "../../../assets/images/stageIcons/interview.svg";
import { ReactComponent as JobOffer } from "../../../assets/images/stageIcons/job-offer.svg";
import { ReactComponent as Reject } from "../../../assets/images/stageIcons/reject.svg";
import { ReactComponent as Shortlisted } from "../../../assets/images/stageIcons/shortlisted.svg";
import { ReactComponent as Written } from "../../../assets/images/stageIcons/written.svg";
import { ReactComponent as AddCircle } from "../../../assets/images/plus.svg";
import { ReactComponent as EyeView } from "../../../assets/images/icons/eye.svg";
import { ReactComponent as ShareView } from "../../../assets/images/icons/forwardShare.svg";
import { ReactComponent as Archive } from "../../../assets/images/icons/archive.svg";
import { ReactComponent as Check } from "../../../assets/images/icons/check__1_.svg";
import { ReactComponent as UpArrow } from "../../../assets/images/up-arrow.svg";
import { ReactComponent as DownArrow } from "../../../assets/images/down-arrow.svg";
import { ReactComponent as Enter } from "../../../assets/images/enter.svg";
import { ReactComponent as Escape } from "../../../assets/images/escape.svg";
import { ReactComponent as downarrow } from "../../../assets/images/icons/arrow-down.svg";
import { ReactComponent as uparrow } from "../../../assets/images/icons/arrow-up.svg";
import { ReactComponent as trash } from "../../../assets/images/icons/trash-can.svg";
import { ReactComponent as edit } from "../../../assets/images/icons/pencil.svg";
import { ReactComponent as MenuSettings } from "../../../assets/images/icons/menu-settings.svg";
import { ReactComponent as BreakOut } from "../../../assets/images/icons/break-out.svg";
import { ReactComponent as ThumbPrint } from "../../../assets/images/icons/thumb-print.svg";
import { ReactComponent as ReturnBack } from "../../../assets/images/icons/return-back.svg";
import { ReactComponent as Nodatafound } from "../../../assets/images/icons/nodatafound.svg";
import { ReactComponent as ExcelFile } from "../../../assets/images/icons/excelFile.svg";

const IconMap = {
  "up-arrow": UpArrow,
  "down-arrow": DownArrow,
  enter: Enter,
  excelFile: ExcelFile,
  nodatafound: Nodatafound,
  escape: Escape,
  award: Award,
  archive: Archive,
  brackets: Brackets,
  check: Check,
  dueDate: DueDate,
  priority: Priority,
  status: Status,
  "ellipsis-v": EllipsisV,
  "arrow-down": ArrowDown,
  "arrow-up": ArrowUp,
  team: Team,
  eyeDropper: EyeDropper,
  simle: Simle,
  simlePlus: SimlePlus,
  angleRight: AngleRight,
  angleLeft: AngleLeft,
  arrowCircleRight: ArrowCircleRight,
  tasks: Tasks,
  bookReader: BookReader,
  allowance: Allowance,
  approved: ApprovedCircle,
  calculator: Calculator,
  home: Home,
  shift: Shift,
  performance: Performance,
  "calendar-check": Shift,
  list: List,
  users: Users,
  cubes: Cubes,
  user: User,
  scroll: Scroll,
  sliders: Sliders,
  techo: Techo,
  cog: Cog,
  clipboard: Clipboard,
  taskAlt: TaskAlt,
  disciplinary: Disciplinary,
  job: Job,
  fileInvoice: FileInvoice,
  repeat: Repeat,
  key: Key,
  sun: Sun,
  moon: Moon,
  "sign-out": SignOut,
  overtime: Overtime,
  times: Times,
  time: Time,
  travel: Travel,
  leave: Leave,
  forwarded: Forward,
  clock: Clock,
  "thumbs-up": ThumbsUp,
  document: Document,
  pdf: Pdf,
  applied: Applied,
  delete: Delete,
  hired: Hired,
  interview: Interview,
  jobOffer: JobOffer,
  reject: Reject,
  shortlisted: Shortlisted,
  written: Written,
  addCircle: AddCircle,
  eyeView: EyeView,
  shareView: ShareView,
  dragger: dragger,
  uparrow: uparrow,
  downarrow: downarrow,
  trash: trash,
  edit: edit,
  "menu-settings": MenuSettings,
  "thumb-print": ThumbPrint,
  "return-back": ReturnBack,
  "break-out": BreakOut,
};

export const RenderIconComponent = ({ name, ...rest }: any) => {
  // @ts-ignore
  let IconComponent = IconMap[name];

  if (!IconComponent) {
    return null;
  }
  return <IconComponent {...rest} />;
};

export type IconName = keyof typeof IconMap;
export interface Icons {
  name: IconName;
  size?: number | string;
  height?: number | string;
  color?: string;
  className?: string;
  styles?: object;
}

export const SvgIcons = ({ size, color, name, height = 0 }: Icons) => {
  return (
    <RenderIconComponent
      name={name}
      width={size}
      height={size || height}
      fill={color}
    />
  );
};

SvgIcons.defaultProps = {
  size: "25px",
  color: "#3a3a3a",
};

export default SvgIcons;
