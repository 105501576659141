// @ts-nocheck 
import React, { useState } from "react";
import classnames from "classnames";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import isEmpty from "isEmpty";
import ModuleLinks from "./moduleLinks"

const ModuleMain = ({
    groupTitle,
    links,
    isFavorite,
    setFav,
    localStorageTitle,
    searchValue,
    baseUrl,
    isBaseUrl,
}: any) => {
    const [listItems, setListItems] = React.useState([]);

    const moduleClasses = classnames({
        "admin-module": true,
    });

   React.useEffect(() => {
     setListItems(links);
   }, [links]);

   React.useEffect(() => {
     if (!isEmpty(searchValue)) {
       setListItems(
         links.filter((item) =>
           item.LinkTitle.toLowerCase().includes(searchValue.toLowerCase())
         )
       );
     } else {
       setListItems(links);
     }
   }, [searchValue]);

   const mainClass = classnames({
     "admin-module__main": true,
     "admin-module__main-fav": isFavorite,
   });

   return (
     <li className="admin-modules__item">
       <div className={moduleClasses}>
         <div className={mainClass}>
           <div className="admin-module__title">
             <span>{groupTitle}</span>
           </div>
         </div>
         <div className="admin-module__links">
           {!isEmpty(listItems) ? (
             <ul className="admin-module__links-list">
               {
                 <TransitionGroup className="todo-list">
                   {listItems.map((item, id) => {
                     return (
                       <CSSTransition key={id} timeout={500} classNames="item">
                         <ModuleLinks
                           moduleLink={item}
                           baseUrl={baseUrl}
                           isBaseUrl={isBaseUrl}
                         />
                       </CSSTransition>
                     );
                   })}
                 </TransitionGroup>
               }
             </ul>
           ) : // :
           // isFavorite ?
           //     <span className="no-found">No Favorite found</span>
           //     :
           //     <span className="no-found">No Links found</span>
           null}
         </div>
       </div>
     </li>
   );
};

export default ModuleMain