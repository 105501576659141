// @ts-nocheck
import { v1 } from "constants/urls";

let prefix = `${v1}/common`;
export default {
  getBranchDetails: `${prefix}/branch-detail`,
  getDepartmentDetails: `${prefix}/department-detail`,
  commonUpload: `${prefix}/shared/UploadFiles`,
  company: `${prefix}/company`,
  educations: `${prefix}/educations`,
  featureflags: `${prefix}/featureflags`,
  activeFeatureflags: `${prefix}/active-featureflags`,
  activeFeatureCategory: `${prefix}/active-feature-categories`,
  countries: `${prefix}/countries`,
  currencies: `${prefix}/currencies`,
  districts: `${prefix}/districts`,
  employees: `${prefix}/employees`,
  prefixedEmpNames: `${prefix}/employees/PrefixedEmpNames`,
  provinces: `${prefix}/provinces`,
  nationalities: `${prefix}/nationalities`,
  branches: `${prefix}/branches`,
  departments: `${prefix}/departments`,
  designations: `${prefix}/designations`,
  localBodyByDistrict: `${prefix}/local-bodies`,
  hiremethod: `${prefix}/hiremethod`,
  payrollIncomeHead: `${v1}/payroll/income/heads`,
  levels: `${prefix}/levels`,
  locations: `${prefix}/locations`,
  bloodGroup: `${prefix}/blood-group`,
  overtimeType: `${v1}/payroll/employee/overtimeType`,
  companyHolidays: `${prefix}/company-holidays`,
  levelWithGroups: `${prefix}/levels-with-groups`,
  subDepartments: `${prefix}/sub-departments`,
  serverDateTime: `${prefix}/server-date-time`,
  companyInformation: `${prefix}/company-information`,
  companyLogo: `${prefix}/company-logo`,
  salesPayDuration: `${v1}/recruitment/jobs/salary-pay-durations`,
  expDuration: `${prefix}/experience-duration`,
  jobStatus: `${prefix}/shared/status`,
  sharedLevels: `${prefix}/shared/levels`,
  jobProfiles: `${v1}/jp/job-profiles/dropdown-list`,
  jobDescription: `${v1}/jp/job-descriptions/dropdown-list`,
  logoUpload: `${prefix}/shared/LogoFileUpload`,
  uploadFiles: `${prefix}/shared/UploadFiles`,
  deletedFiles: `${prefix}/shared/DeleteFiles`,
  districtByProvience: "common/districts/province",
  provinceDistrict: (provinceId) => `${prefix}/${provinceId}/districts`,
  localBodyDistrict: (districtId) => `${prefix}/${districtId}/local-bodies`,
  educationLevel: `${v1}/jp/educations`,
  empMenus: `${prefix}/emp-menus`,
  appVersion: `${prefix}/app-version`,

  toggleFavLink: `${v1}/featureFavorite/toggle-fav-link`,
  getFavLinks: `${v1}/featureFavorite/fav-links`,
};
