import { ApiClient } from "../../../network/apiClient";
import * as fromInterface from "../../../ts";
import { EmployeeType } from "./api";
import * as fromSlice from "./slice";
import * as fromSelector from "./selectors";

export const fetchEmployeeType =
  (): any => async (dispatch: any, getState: any) => {
    const state = getState();
    const isLoaded = fromSelector.selectEmployeeTypeLoaded(state);
    if (!isLoaded) {
      dispatch(fromSlice.fetchRequest());

      const response: fromInterface.ResponseWrapper<any> =
        await ApiClient.get<any>({
          endpoint: EmployeeType,
          dispatch: dispatch,
        });

      const { Data, Status } = response;

      if (Status) {
        dispatch(fromSlice.fetchSuccess(Data));
      }
    }
    return fromSelector.selectEmployeeTypeItems(state);
  };

export const clearEmployeeType = (): any => async (dispatch: any) =>
  dispatch(fromSlice.clear());
