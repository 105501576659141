// @ts-nocheck 
import React, { useState, useRef, useEffect, useLayoutEffect, useMemo } from "react";
import {
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
  MdKeyboardArrowRight,
} from "react-icons/md";
import { NavLink } from "react-router-dom";
import useOutsideClick from "helpers/outsideClickHook";
import classnames from "classnames";
import { CSSTransition } from "react-transition-group";
import { isFeatureEnabledValue } from "helpers/checkPermissions";
import userNavTab from "./userNavTab";
import { useSelector } from "react-redux";

function Navigation({ props }) {
  const navRef = useRef(null);
  const dropRef = useRef(null);
  const [drop, setDrop] = useState(false);
  const [hiddenChildren, setHiddenChildren] = useState([]);
  const {
    user,
    hrCommonSettings: { EmpDashboardShowPayslip },
  } = useSelector((state: any) => state.auth);

  useEffect(() => {
    if (navRef && navRef.current) {
      const ele = navRef.current;
      const isOffset =
        ele.offsetHeight < ele.scrollHeight || ele.offsetWidth < ele.scrollWidth;
    }
  }, [navRef]);
  const onHiddenChild = (child) => {
    setHiddenChildren((prev) => {
      if (prev) {
        const isThere = prev && prev.find((item) => item.navId === child.navId);
        if (isThere) {
          return prev;
        }
        return [...prev, child];
      }
      return [child];
    });
  };

  const removeFromHidden = (child) => {
    setHiddenChildren((prev) => {
      return prev ? prev.filter((item) => item.navId !== child.navId) : [];
    });
  };

  useOutsideClick(dropRef, () => setDrop(false));

  const { navigationContent } = useMemo(
    () => userNavTab(user, EmpDashboardShowPayslip),
    [user, EmpDashboardShowPayslip]
  );

  return (
    <div className="header-navigation">
      <nav ref={navRef} className="navbar flex">
        <div className="wrap-container">
          <ul className="navbar-list navbar-nav">
            {navigationContent.map((content, index) => {
              if (
                !isFeatureEnabledValue(content.feature) ||
                !content.tempUser ||
                !content?.showPayslip
              ) {
                return null;
              }
              if (content.env) {
                return (
                  <Tab
                    key={index}
                    removeFromHidden={removeFromHidden}
                    onHiddenChild={onHiddenChild}
                    parent={navRef?.current}
                    content={content}
                  />
                );
              }
              return null;
            })}
          </ul>
          {hiddenChildren && hiddenChildren.length > 0 && (
            <div ref={dropRef} className="navbar-down">
              <a
                onClick={() => setDrop(!drop)}
                className={classnames("navbar-list__links", {
                  "navbar-list__links-active": drop,
                })}
              >
                {drop ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
              </a>
              {drop && (
                <div className="navbar-dropdown">
                  <ul className="navbar-dropdown__list">
                    {hiddenChildren.map((item) => {
                      if (item.isDrop) {
                        return <DropLink content={item} isSideways />;
                      }
                      return (
                        <li key={item.title} className="navbar-dropdown__item">
                          <NavLink to={item.link} className="navbar-dropdown__link">
                            <div className="navbar-dropdown__title">{item.title}</div>
                          </NavLink>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>
      </nav>
    </div>
  );
}

const DropLink = ({ content, ...rest }) => {
  const { parent, onHiddenChild, removeFromHidden, isSideways } = rest;
  const [drop, setDrop] = useState(false);
  const onOpen = () => {
    setDrop(!drop);
  };
  const dropRef = useRef(null);
  const onClose = () => {
    setDrop(false);
  };
  useOutsideClick(dropRef, onClose);
  const dropClasses = classnames({
    "navbar-list__item": true,
    "drop-active": drop,
    "drop-side": isSideways,
  });
  const linkItem = dropRef?.current?.getBoundingClientRect();
  const {
    bottom,
    height: ht,
    left,
    right,
    top,
  } = linkItem || { top: 0, left: 0, bottom: 0, right: 0, height: 40 };
  const [isHidden, setHidden] = useState(false);
  const [width, height] = useWindowSize();
  useEffect(() => {
    HideChildren();
  }, [dropRef, parent]);

  useEffect(() => {
    HideChildren();
  }, [width, height]);
  const HideChildren = () => {
    if (dropRef && dropRef.current && parent && !isSideways) {
      const child = dropRef.current;
      if (
        child.offsetLeft + child.offsetWidth - parent.offsetLeft > parent.offsetWidth ||
        child.offsetTop - parent.offsetTop > parent.offsetHeight
      ) {
        setHidden(true);
        onHiddenChild(content);
      } else {
        setHidden(false);
        removeFromHidden(content);
      }
    }
  };
  return (
    <li
      ref={dropRef}
      style={{ display: isHidden ? "none" : "block" }}
      onClick={() => onOpen()}
      className={dropClasses}
    >
      <a className="navbar-list__links">
        <span>{content.title} </span>
        <span className="navbar-list__toogle-icon">
          {drop ? (
            isSideways ? (
              <MdKeyboardArrowDown />
            ) : (
              <MdKeyboardArrowUp />
            )
          ) : isSideways ? (
            <MdKeyboardArrowRight />
          ) : (
            <MdKeyboardArrowDown />
          )}
        </span>
      </a>
      <CSSTransition
        in={drop}
        timeout={300}
        classNames="dropdown"
        style={isSideways ? {} : { top: top + ht, left, right }}
        appear
        unmountOnExit
      >
        <div className="navbar-dropdown">
          <ul className="navbar-dropdown__list">
            {content.links.map((item) => {
              return (
                <li key={item.title} className="navbar-dropdown__item">
                  <NavLink to={item.link} className="navbar-dropdown__link">
                    <div className="navbar-dropdown__title">{item.title}</div>
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
      </CSSTransition>
    </li>
  );
};

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

const Tab = ({ key, content, ...rest }) => {
  const { parent, onHiddenChild, removeFromHidden } = rest;
  const [isHidden, setHidden] = useState(false);
  const [width, height] = useWindowSize();
  const navLinkRef = useRef(null);
  useEffect(() => {
    HideChildren();
  }, [navLinkRef, parent]);

  useEffect(() => {
    HideChildren();
  }, [width, height]);

  const HideChildren = () => {
    if (navLinkRef && navLinkRef.current && parent) {
      const child = navLinkRef.current;
      if (
        child.offsetLeft + child.offsetWidth - parent.offsetLeft > parent.offsetWidth ||
        child.offsetTop - parent.offsetTop > parent.offsetHeight
      ) {
        setHidden(true);
        onHiddenChild(content);
      } else {
        setHidden(false);
        removeFromHidden(content);
      }
    }
  };
  if (content.isDrop) {
    return <DropLink content={content} {...rest} />;
  }

  return (
    <li
      ref={navLinkRef}
      style={{ display: isHidden ? "none" : "block" }}
      className="navbar-list__item"
    >
      <NavLink
        exact
        className={"navbar-list__links"}
        to={{ pathname: content.link, state: { navId: content.navId } }}
        key={key}
        activeClassName="active"
      >
        <span>{content.title}</span>
      </NavLink>
    </li>
  );
};

export default Navigation;
