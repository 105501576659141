import React from "react";

import { Box, BoxProps } from "@chakra-ui/react";

const SIZE = {
  sm: { maxWidth: "1024px", width: "100%" },
  md: { maWidth: "1400px", width: "100%" },
  lg: { maxWidth: "1400px", width: "100%" },
  default: { maxWidth: "100%", width: "100%" },
};

interface IWrapper extends BoxProps {
  size?: keyof typeof SIZE;
}

export const Wrapper = (props: IWrapper) => {
  const { children, size, ...rest } = props;
  // const width = SIZE[size] as typeof SIZE[keyof typeof SIZE];

  return (
    <Box m='0 auto' padding='20px' {...SIZE[size || "default"]} {...rest}>
      {children}
    </Box>
  );
};

export default Wrapper;
