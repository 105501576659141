import React, { ReactChildren, ReactElement, useContext } from 'react'
import {Box} from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import StepperContext, { StepperEnums } from '../context';

interface IContainerProps{
    children:any,
    title:string,
    isBack?:boolean,
}

function Container(props:IContainerProps) {
    const values = useContext(StepperContext);
    const { children, title, isBack = false } = props;
    const onBack = () =>{
        values.setActiveStepper(values.activeStep - 1);
    }
    return (
        <Box maxW="750px" border="1px solid #D2DAE3" borderRadius="5px" padding="24px 52px" position="relative" margin={"40px auto"} >
            {isBack && <Box 
                            onClick={()=>onBack()} 
                            position="absolute" 
                            top="26px" 
                            left="36px" 
                            w="30px"
                            h="30px"
                            background={"#333d47"}
                            cursor="pointer" 
                            display="grid"
                            placeItems="center"
                            borderRadius={"24px"}
                            color="#fff"
                        ><ChevronLeftIcon fontSize={"25px"} /></Box>}
            <Box mb="28px" fontSize={"20px"} color={"#465060"} fontWeight={600} textAlign="center" >{title}</Box>
            {
                children
            }
        </Box>
    )
}

export default Container