import { createSlice } from "@reduxjs/toolkit";
import * as fromInterface from "../../../ts";

export interface PeformanceRolloutInfoState {
    Assigned: number
    Notassigned: number
    NotStarted: number
    InProgress: number
    ManagerReview: number
    SelfReview: number
    Completed: number
    status: fromInterface.loadingType;
    loaded: boolean;
}

const initialState: PeformanceRolloutInfoState = {
    Assigned: 0,
    Notassigned: 0,
    NotStarted: 0,
    InProgress: 0,
    ManagerReview: 0,
    SelfReview: 0,
    Completed: 0,
    status: fromInterface.STATUS.IDLE,
    loaded: false,
};

const performancRolloutInfoSlice = createSlice({
    name: "performance rollout info",
    initialState,
    reducers: {
        fetchRequest: (state) => {
            state.status = fromInterface.STATUS.LOADING;
        },
        fetchSuccess: (state, action) => {
            const payload = action.payload
            state = { ...state, ...payload, status: fromInterface.STATUS.IDLE, loaded: true };
            return state
        },
        clear: (state) => {
            return initialState
        },
    },
});

export const {
    fetchRequest,
    fetchSuccess,
    clear,
} = performancRolloutInfoSlice.actions

export default performancRolloutInfoSlice.reducer;
