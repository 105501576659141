//CHAKRA
import { Box, Center, Heading, Text } from "@chakra-ui/react";

//ICONS

//images
import datafilter from "../../../assets/images/datafilter.png";

interface NoDataFoundProps {
  heading?: string;
  message?: string;
}
export const InitialTableView = (props: NoDataFoundProps) => {
  const {
    heading = "Apply filter",
    message = "Please select any fitlers and click apply.",
  } = props;

  return (
    <Center
      padding='40px 25px'
      background='#fff'
      borderRadius='5px'
      minHeight='200px'
      width='100%'
      textAlign='center'
      flexDirection='column'
    >
      <img src={datafilter} alt='' height='140px' width='140px' />

      <Box pt='20px'>
        <Heading
          fontSize='26px'
          color='#9b9b9b'
          fontWeight='500'
          mb={2}
          textTransform='capitalize'
        >
          {heading}
        </Heading>
        <Text color='#bfbfbf' fontSize='16px' fontWeight='400'>
          {message}
        </Text>
      </Box>
    </Center>
  );
};

export default InitialTableView;
