import { createSlice } from "@reduxjs/toolkit";

export interface UILoaderState {
    show: boolean,
}

const initialState: UILoaderState = {
    show: false,
};

const UiLoaderSlice = createSlice({
    name: "performance form section",
    initialState,
    reducers: {
        toggle: (state) => {
            state.show = !state.show;
        },
        show: (state) => {
            state.show = true;
        },
        hide: (state) => {
            state.show = false;
        },
        clear: (state) => {
            state.show = false
        },
    },
});

export const {
    toggle,
    show,
    hide,
    clear,
} = UiLoaderSlice.actions

export default UiLoaderSlice.reducer;