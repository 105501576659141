// @ts-nocheck 
import React from "react";
import {Skeleton} from "@material-ui/lab";

const ReactSelectLoading = (props) => {
  const {width} = props;
  return (
    <div className="form-group">
      <Skeleton variant="text" width={75} height={15} />
      <Skeleton variant="rect" width={width ? width : 210} height={40} />
    </div>
  );
};

export default ReactSelectLoading;
