// @ts-nocheck 
import React from "react";
import {Card} from "components/card";
import {Text} from "components/text";
import {BORDER_COLOR, PRIMARY, WHITE} from "constants/colors";

const DateTypeList = ({activeDateType, handleDateTypeClick}) => {
  return (
    <Card gap={"0.8rem"} padding="1rem 1.5rem" width={"100%"}>
      <Card
        background={activeDateType === 0 && PRIMARY}
        border={activeDateType !== 0 && `1px solid ${BORDER_COLOR}`}
        borderRadius="3px"
        cursor={"pointer"}
        handleClick={() => handleDateTypeClick(0)}
        hoverBackground={PRIMARY}
        hoverColor={WHITE}
        padding={"0.7rem 1.4rem"}
      >
        <Text color={activeDateType === 0 && WHITE} title={"All Period"} />
      </Card>
      <Card
        background={activeDateType === 1 && PRIMARY}
        border={activeDateType !== 1 && `1px solid ${BORDER_COLOR}`}
        borderRadius="3px"
        cursor={"pointer"}
        handleClick={() => handleDateTypeClick(1)}
        hoverBackground={PRIMARY}
        hoverColor={WHITE}
        padding={"0.7rem 1.4rem"}
      >
        <Text color={activeDateType === 1 && WHITE} title={"This Year"} />
      </Card>
      <Card
        background={activeDateType === 2 && PRIMARY}
        border={activeDateType !== 2 && `1px solid ${BORDER_COLOR}`}
        borderRadius="3px"
        cursor={"pointer"}
        handleClick={() => handleDateTypeClick(2)}
        hoverBackground={PRIMARY}
        hoverColor={WHITE}
        padding={"0.7rem 1.4rem"}
      >
        <Text color={activeDateType === 2 && WHITE} title={"Last Year"} />
      </Card>
      <Card
        background={activeDateType === 3 && PRIMARY}
        border={activeDateType !== 3 && `1px solid ${BORDER_COLOR}`}
        borderRadius="3px"
        cursor={"pointer"}
        handleClick={() => handleDateTypeClick(3)}
        hoverBackground={PRIMARY}
        hoverColor={WHITE}
        padding={"0.7rem 1.4rem"}
      >
        <Text color={activeDateType === 3 && WHITE} title={"This Month"} />
      </Card>
      <Card
        background={activeDateType === 4 && PRIMARY}
        border={activeDateType !== 4 && `1px solid ${BORDER_COLOR}`}
        borderRadius="3px"
        cursor={"pointer"}
        handleClick={() => handleDateTypeClick(4)}
        hoverBackground={PRIMARY}
        hoverColor={WHITE}
        padding={"0.7rem 1.4rem"}
      >
        <Text color={activeDateType === 4 && WHITE} title={"Last Month"} />
      </Card>
    </Card>
  );
};

DateTypeList.defaultProps = {
  activeDateType: 0,
  handleDateTypeClick: () => {},
};

export default DateTypeList;
