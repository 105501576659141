
import { ApiClient } from "../../../network/apiClient";
import * as fromInterface from "../../../ts";
import { BRANCHES } from "./api";
import * as fromSlice from "./slice";
import * as fromSelector from "./selectors"


export const fetchBranches = (): any => async (dispatch: any, getState: any) => {
    const state = getState();
    const isLoaded = fromSelector.selectBranchLoaded(state)
    if (!isLoaded) {
        dispatch(fromSlice.fetchRequest())


        const response: fromInterface.ResponseWrapper<any> = await ApiClient.get<any>({
            endpoint: BRANCHES,
            dispatch: dispatch
        })

        const { Data, Status } = response

        if (Status) {
            dispatch(fromSlice.fetchSuccess(Data))
        }
    }
    return fromSelector.selectBranchItems(state)

}

export const clearBranches = (): any => async (dispatch: any) => dispatch(fromSlice.clear())
