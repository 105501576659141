import * as fromInterface from "../../../ts";
import { GeoOfficeSitesState } from "./slice";

const selectGeoOfficeSitesSlice = (state: any): GeoOfficeSitesState => {
  return state.dataStore.geoOfficeSites;
};

export const selectGeoOfficeStatus = (
  state: any
): fromInterface.loadingType => {
  return selectGeoOfficeSitesSlice(state).status;
};

export const selectGeoOfficeSitesLoaded = (state: any): boolean => {
  return selectGeoOfficeSitesSlice(state).loaded;
};

export const selectGeoOfficeSitesItems = (state: any) => {
  return selectGeoOfficeSitesSlice(state).items;
};
