import {
  GET_DISTRIBUTED_INCOME_BY_AMOUNT,
  SAVE_ACCESS_AND_ID_TEMPLATE,
  ACCESSANDIDTEMPLATELIST,
  SALARY_TEMPLATE_DETAILS,
  DELETE_SAVE_ACCESS_AND_ID_TEMPLATE,
  CLEAR_SAVE_ACCESS_AND_ID_TEMPLATE,
} from "actions/types";

const initialState = {
  salaryTemplateDetailRequesting: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DISTRIBUTED_INCOME_BY_AMOUNT: {
      return {
        ...state,
        distributedIncomeByAmount: action.payload,
      };
    }
    case SAVE_ACCESS_AND_ID_TEMPLATE: {
      return {
        ...state,
        saveAccessAndIdTemplate: true,
      };
    }
    case CLEAR_SAVE_ACCESS_AND_ID_TEMPLATE: {
      return {
        ...state,
        saveAccessAndIdTemplate: false,
      };
    }
    case ACCESSANDIDTEMPLATELIST: {
      return {
        ...state,
        accessAndIdTemplateList: action.payload,
      };
    }
    case SALARY_TEMPLATE_DETAILS: {
      return {
        ...state,
        salaryTemplateDetail: action.payload,
        salaryTemplateDetailRequesting: action.requesting,
      };
    }
    case DELETE_SAVE_ACCESS_AND_ID_TEMPLATE: {
      return {
        ...state,
        deleteSalaryTemplate: true,
      };
    }
    default:
      return state;
  }
}
