import types from "./types";
const initialState = {
  jobScoreCard: { loading: false, value: [] },
  jobApplicationForm: { loading: false, value: [] },
  jobHiringStages: { loading: false, value: [] },
  jobCategory: { loading: false, value: [] },
  jobNumber: { loading: false, value: "" },
  jobCreate: { loading: false, value: "" },
  loading: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case types.SCORECARD_LOADING:
      return {
        ...state,
        jobScoreCard: { loading: true, value: [] },
      };
    case types.JOB_CATEGORY_LOADING:
      return {
        ...state,
        jobCategory: { loading: true, value: [] },
      };
    case types.GET_JOB_SCORECARD:
      return {
        ...state,
        jobScoreCard: { loading: false, value: action.payload },
      };
    case types.HIRINGSTAGES_LOADING:
      return {
        ...state,
        jobHiringStages: { loading: true, value: [] },
      };
    case types.GET_JOB_CATEGORY:
      return {
        ...state,
        jobCategory: { loading: false, value: action.payload },
      };
    case types.GET_JOB_HIRING_STAGES:
      return {
        ...state,
        jobHiringStages: { loading: false, value: action.payload },
      };
    case types.APPLICATIONFORM_LOADING:
      return {
        ...state,
        jobApplicationForm: { loading: true, value: [] },
      };
    case types.GET_JOB_APPLICATION_FORM:
      return {
        ...state,
        jobApplicationForm: { loading: false, value: action.payload },
      };
    case types.GET_JOB_NUMBER:
      return {
        ...state,
        jobNumber: { loading: false, value: action.payload },
      };
    case types.CREATE_AND_UPDATE_JOB_REQ:
      return {
        ...state,
        jobCreate: {
          loading: false,
          value: action.payload,
        },
      };
    case types.JOB_NUMBER_LOADING:
      return {
        ...state,
        jobNumber: { loading: true, value: "" },
      };
    case types.CREATE_AND_UPDATE_JOB:
      return {
        ...state,
        jobCreate: { loading: false, value: action.payload },
      };
    case types.CREATE_AND_UPDATE_JOB_LOADING:
      return {
        ...state,
        jobCreate: { ...state.jobCreate, loading: true },
      };
    case types.GET_JOB_STEPS_SETTING: {
      return {
        ...state,
        jobStepsSetting: action.payload,
      };
    }
    default:
      return state;
  }
}
