// @ts-nocheck 
import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import Table from "components/customTable";

import {ButtonsFrame} from "./frameworkComponents";

const CourseCategoryTable = ({
  handleCategoryEdit,
  courseCategories,
  handleCategoryDel,
}) => {
  // const onRowSelect = (rows) => setSelectedRow(rows);

  const cols = useMemo(
    () => [
      {Header: "Id", accessor: "Id", width: 15},
      {Header: "Name", accessor: "Name", width: 100},
      {
        Header: "",
        accessor: "none",
        width: 80,
        Cell: (cellProps) => (
          <ButtonsFrame
            {...cellProps}
            onEditCategory={handleCategoryEdit}
            onDelCategory={handleCategoryDel}
          />
        ),
      },
    ],
    []
  );
  const data = useMemo(() => courseCategories, [courseCategories]);

  return (
    <div className="course-categories__table">
      <div
        id="myGrid"
        style={{
          height: "100%",
        }}
        className="ag-theme-alpine"
      >
        <Table isFixedWidth data={data} columns={cols} />
      </div>
    </div>
  );
};
export default CourseCategoryTable;
