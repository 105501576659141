
import { ApiClient } from "../../../../network/apiClient";
import * as fromInterface from "../../../../ts";
import { PERFORMANCE_REVIEW } from "../api";
import * as fromSlice from "../slice";

export const fetchPerformanceReviewForSelf = ({ reviewId }: { reviewId: number }): any => async (dispatch: any) => {
    dispatch(fromSlice.fetchSlefRequest())
    const response: fromInterface.ResponseWrapper<fromInterface.PerformanceReview> = await ApiClient.get<any>({
        endpoint: PERFORMANCE_REVIEW.replace(":reviewId", reviewId + ""),
        dispatch: dispatch
    })

    const { Data, Status } = response

    if (Status) {
        dispatch(fromSlice.fetchSelfSuccess(Data))
    }

}