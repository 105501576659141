import { Checkbox, Wrap, WrapItem } from '@chakra-ui/react';
import React from 'react';

export const CheckboxgroupComponent = (props: any) => {
  const [checkedValues, setCheckedValues] = React.useState<any[]>([]);

  const { value, options, onChange, name, ...rest } = props;

  React.useEffect(() => {
    if (value) {
      setCheckedValues(value);
    }
  }, [value]);

  function handleSelect(checkedValue: any) {
    let containsInChecked: boolean = checkedValues?.some(
      (value: any) => value.value === checkedValue.value,
    );

    let removedFromCheckedValues = checkedValues?.filter(
      value => value.value !== checkedValue.value,
    );

    let addToCheckedValues = [...(checkedValues ?? []), checkedValue];

    const newValues = containsInChecked
      ? removedFromCheckedValues
      : addToCheckedValues;

    setCheckedValues(newValues);

    return newValues;
  }

  return (
    <Wrap direction={props.direction || 'row'} spacing={props.spacing}>
      {options ? (
        options.map((item: any, idx: number) => {
          let foundInCheckedValues = checkedValues?.find((cv: any) => {
            return cv?.value === item.value || cv === item.value;
          });

          return (
            <WrapItem key={`${idx}`} w={props.width || '100%'}>
              <Checkbox
                size='sm'
                {...rest}
                isChecked={foundInCheckedValues ? true : false}
                onChange={() => {
                  const d = handleSelect(item);
                  onChange?.(name, d);
                }}
              >
                {item.label}
              </Checkbox>
            </WrapItem>
          );
        })
      ) : (
        <>No data</>
      )}
    </Wrap>
  );
};
