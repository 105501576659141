import React, {Component} from "react";
import classnames from "classnames";
import Loader from "components/loaders";
import {ADMIN_ROLE_ID} from "constants/constants";
import {RoleId} from "constants/userDetails";
import {Arrays} from "helpers/getTextLabel";
import isEmpty from "isEmpty";
import PropTypes from "prop-types";
import FormGroup from "components/form";
import {connect} from "react-redux";
import {getUploadPath} from "constants/userDetails";
import Avatar from "react-avatar";
import man from "assets/images/man.png";
import {GetEmployeeList} from "actions/commonAction";
import Loaders from "components/loaders";

import CommentSection from "./commentSection";
import {ClearApprovalAuthorities} from "../travelInformation/actions";
import Icons from "components/icons";
export class CommentsHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employee: [],
      remarks: "",
      roleId: null,
      isApprove: false,
      errors: {},
      statusId: 0,
      isActive: true,
      comments: [],
      isApproveBtnDisabled: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onApprove = this.onApprove.bind(this);
    this.onApproveReturn = this.onApproveReturn.bind(this);
  }

  async componentDidMount() {
    const {StatusId, childRef} = this.props;
    if (!isEmpty(childRef)) {
      childRef(this);
    }

    await this.props.GetEmployeeList();
    const roleId = RoleId();
    if (StatusId === 15 || roleId === ADMIN_ROLE_ID) {
      this.setState({...this.state, isApprove: true, statusId: StatusId, roleId});
    } else {
      this.setState({...this.state, isApprove: false, statusId: StatusId, roleId});
    }

    if (!isEmpty(this.props.approvalHistory.value)) {
      console.log("approvalHistory:", this.props.approvalHistory);
      this.props.approvalHistory.value.map((item) => {
        let comments = [];
        if (!isEmpty(item.ActionRemarks)) {
          comments.push(item.ActionRemarks);
        }
        console.log({...this.state, comments});
        this.setState({...this.state, comments});
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {StatusId, isUpdate, myDetail} = this.props;
    const {value} = this.props.approvalAuthorities;
    if (this.state.statusId !== StatusId) {
      if (StatusId === 15 || this.state.roleId === ADMIN_ROLE_ID) {
        this.setState({...this.state, isApprove: true, statusId: StatusId});
      } else {
        this.setState({...this.state, isApprove: false, statusId: StatusId});
      }
    }

    if (!isEmpty(value) && value !== prevProps.approvalAuthorities.value) {
      if (value.IsFinalApprover) {
        this.setState({...this.state, statusId: StatusId});
      }

      if (value.AuthoritiesList && value.AuthoritiesList.length === 1) {
        this.setState({...this.state, employee: Arrays(value.AuthoritiesList)[0]});
      }

      if (isUpdate) {
        if (!value.IsFinalApprover) {
          const approver = !isEmpty(value) ? Arrays(value.AuthoritiesList) : [];
          this.setState({...this.state, employee: approver[0]});
        } else {
          const approver = !isEmpty(myDetail)
            ? {
                label: myDetail.label,
                value: myDetail.value,
              }
            : {};
          this.setState({...this.state, employee: approver});
        }
      }
    }

    if (prevProps.approvalHistory !== this.props.approvalHistory) {
      if (!isEmpty(this.props.approvalHistory.value)) {
        let comments = [];
        this.props.approvalHistory.value.map((item) => {
          if (!isEmpty(item.ActionRemarks)) {
            comments.push(item.ActionRemarks);
          }
          console.log({comments});
          this.setState({...this.state, comments});
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.ClearApprovalAuthorities();
  }

  onChange(name, value) {
    this.setState({
      [name]: value,
      errors: {...this.state.errors, [name]: ""},
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const data = {
      employee: this.state.employee,
      remarks: this.state.remarks,
    };

    const {isAuth, errors} = this.onValidate(data);
    if (!isAuth) {
      this.setState({errors}, () => console.log(this.state.errors));
      return false;
    }
    this.setState({errors: {}});
    this.onApprove(this.props.StatusId);
    // this.props.onModalClose();
    this.setState({isApproveBtnDisabled: true});
  }

  onApprove(status) {
    const data = {
      employee: this.state.employee,
      remarks: this.state.remarks,
      requestId: this.props.RequestId,
      statusId: status,
    };
    this.props.func(data);
  }

  onApproveReturn(status) {
    const {value} = this.props.approvalAuthorities;
    const data = {
      employee: this.state.employee,
      remarks: this.state.remarks,
    };

    let postData = {};

    if (!isEmpty(value) && value.IsFinalApprover) {
      postData = {
        Status: 15,
        ApprovalEmployeeId:
          !isEmpty(data.employee) && !isEmpty(data.employee.value)
            ? parseInt(data.employee.value)
            : 0,
      };
    } else {
      postData = {
        HistoryID: 0,
        RequestId: this.props.RequestId,
        Status: status,
        ActionBy: "",
        ActionOn: "",
        ActionRemarks: data.remarks,
        ActionDisplayTitle: "",
        ApprovalEmployeeId:
          !isEmpty(data.employee) && !isEmpty(data.employee.value)
            ? parseInt(data.employee.value)
            : 0,
      };
    }
    const {isAuth, errors} = this.onValidate(data);
    if (!isAuth) {
      this.setState({errors}, () => console.log(this.state.errors));
      return false;
    }
    return postData;
  }

  onValidate(data) {
    let errors = {};
    if (isEmpty(data.employee) && !this.state.isApprove) {
      errors.employee = "Employee is required";
    }
    return {isAuth: isEmpty(errors), errors};
  }

  handleHeaderClick() {
    this.setState((prevState) => ({isActive: !prevState.isActive}));
  }

  render() {
    const {loading, value} = this.props.approvalAuthorities;
    const {
      approvalHistory,
      disableApprover,
      hideCommentSection,
      myDetail,
      pendingSettlement,
      StatusId,
      spinnerLoading,
      approvalLoading,
    } = this.props;
    const {isModal, isViewMode, isEditPage, isApprover, isRequester} = this.props;
    const {isApprove, errors} = this.state;
    const approvers = !isEmpty(value)
      ? Arrays(!isEmpty(value.AuthoritiesList) ? value.AuthoritiesList : value)
      : [];
    if (isEmpty(approvalHistory.value) && disableApprover) return null;

    if (
      (StatusId === 0 || StatusId === 1) &&
      !isApprove &&
      !isApprover &&
      !disableApprover
    ) {
      return (
        <div
          title={this.props.errors && this.props.errors.employee}
          className={classnames({
            flex: true,
            "comments-error":
              !isEmpty(this.props.errors) && !isEmpty(this.props.errors.employee),
          })}
        >
          <div>
            {!isEmpty(value) && !value.IsFinalApprover ? (
              <FormGroup
                formName="reactselect"
                onChange={this.onChange}
                value={this.state.employee}
                options={approvers}
                name="employee"
                width="250px"
                placeholder="Choose an employee"
                loading={loading}
                loadingType="skeleton"
                label="Select a approver"
                error={errors?.employee}
              />
            ) : (
              <div className="selected-user">
                <Avatar
                  alt={myDetail.label}
                  className="emp-img"
                  name={myDetail.label}
                  round
                  size={40}
                  src={
                    !isEmpty(myDetail.Imagepath) ? getUploadPath(myDetail.Imagepath) : man
                  }
                />
                <h5 className="selected-user__name">{myDetail.label}</h5>
              </div>
            )}
          </div>
        </div>
      );
    }

    return (
      <div className="approve-container p-xsm" style={isModal ? {width: "100%"} : {}}>
        {(isViewMode || isEditPage || isApprove) && !hideCommentSection && (
          <div className="comments">
            {(!isModal || StatusId === 0 || StatusId === 1) && !hideCommentSection ? (
              <div className="comments-header" onClick={() => this.handleHeaderClick()}>
                <span>Comments</span>
                {this.state.isActive ? (
                  <Icons name="ExpandLess" />
                ) : (
                  <Icons name="ExpandMore" />
                )}
              </div>
            ) : null}
            {this.state.isActive &&
              approvalHistory &&
              approvalHistory.value &&
              approvalHistory.value.length > 0 && (
                <div className="comments-body">
                  {approvalHistory.value === null || approvalHistory.loading ? (
                    <Loader
                      open={approvalHistory.loading}
                      loaderSize={"50"}
                      type="normal"
                    />
                  ) : null}
                  <ul className="comments-list">
                    {approvalHistory.value.map((item, index) => {
                      return <CommentSection key={index} data={item} />;
                    })}
                  </ul>
                </div>
              )}
           
            {!isViewMode && !isRequester && (
              <div
                title={this.state.errors.remarks}
                className={classnames({
                  "comments-remarks": true,
                  "comments-error": !isEmpty(this.state.errors.remarks),
                })}
              >
                <textarea
                  onChange={(e) => this.onChange(e.target.name, e.target.value)}
                  value={this.state.remarks}
                  placeholder="Remarks.."
                  name="remarks"
                  id="remarks"
                  cols="20"
                  rows=""
                ></textarea>
              </div>
            )}
          </div>
        )}
        {(isViewMode || isEditPage) && !isModal && !pendingSettlement ? null : (
          <div className="comments-footer">
            <form onSubmit={this.onSubmit}>
              <div
                title={this.state.errors.employee}
                className={classnames({
                  flex: true,
                  "comments-error": !isEmpty(this.state.errors.employee),
                  "justify-end": true,
                })}
              >
                {isApprove ? null : (
                  <div className={"comments-footer__input"}>
                    <FormGroup
                      formName="reactselect"
                      onChange={this.onChange}
                      isAbove
                      value={this.state.employee}
                      options={approvers}
                      name="employee"
                      width="250px"
                      placeholder="Choose an employee"
                      loading={loading}
                      loadingType="skeleton"
                      error={errors?.employee}
                      disabled={approvers && approvers.length === 1}
                    />
                  </div>
                )}
                {isModal ? (
                  <div className="comments-footer__submit">
                    <button
                      className="btn btn-blue"
                      type="submit"
                      disabled={
                        spinnerLoading ||
                        approvalLoading ||
                        this.state.isApproveBtnDisabled
                      }
                    >
                      {isApprove ? "Approve" : "Forward"}
                    </button>
                  </div>
                ) : null}
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

CommentsHistory.propTypes = {
  approvalAuthorities: PropTypes.object.isRequired,
  isModal: PropTypes.bool.isRequired,
};
CommentsHistory.defaultProps = {
  isModal: false,
};

const mapStateToProps = (state) => ({
  myDetail: state.commonReducer.myDetail,
  isSettlementSaveSuccess: state.travelSettlementReducer.isSettlementSaveSuccess,
  spinnerLoading: state.travelSettlementReducer.spinnerLoading,
  approvalLoading: state.travelInformationReducer.approvalLoading,
});

export default connect(mapStateToProps, {
  GetEmployeeList,
  ClearApprovalAuthorities,
})(CommentsHistory);
