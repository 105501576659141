import { RigoComponent } from './rigo-component';
import { RigoDefault } from './rigo-default';
import { RigoFormControl } from './rigo-form-control';
import { RigoFormErrorLabel } from './rigo-form-error-label';
import { RigoFormHelperText } from './rigo-form-helper-text';
import { RigoFormLabel } from './rigo-form-label';
import { RigoSwitch } from './rigo-switch';

export const SwitchV2 = (props: any) => {
  return <RigoSwitch {...props} />;
};

SwitchV2.Default = RigoDefault;
SwitchV2.FormLabel = RigoFormLabel;
SwitchV2.HelperText = RigoFormHelperText;
SwitchV2.ErrorLabel = RigoFormErrorLabel;
SwitchV2.FormControl = RigoFormControl;
SwitchV2.Component = RigoComponent;
