import { selectReview } from ".";

export const selectEvaluationPageData = (state: any): any => {
    const review = selectReview(state);
    // const selfReview = selectReviewForSelf(state);

    const evaluation = selectReview(state)?.Evaluation;

    if (evaluation) {

        return {
            ...evaluation,
            Comments: evaluation.Comments,
            SignatureMessage: review?.ReviewInfo?.SignatureMessage,
            // selfMarking: {
            //     IsSignatureRequired: selfReview?.ReviewInfo?.IsSignatureRequired,
            //     SignatureMessage: selfReview?.ReviewInfo?.SignatureMessage,
            //     Signature: selfReview?.ReviewInfo?.Signature
            // }
        }
    }

    return {
        Comments: [],
        SignatureMessage: review?.ReviewInfo?.SignatureMessage,
        selfMarking: {
        }
    };
};