import * as fromInterface from "../../../ts";
import { ReviewCycleState } from "./slice";

const currentReviewCycleDropdown = (state: any): ReviewCycleState => {
    return state.dataStore.reviewCycleDropdown;
};

export const selectReviewCycleDropdownStatus = (state: any): fromInterface.loadingType => {
    return currentReviewCycleDropdown(state).status;
};

export const selectReviewCycleDropdownLoaded = (state: any): boolean => {
    return currentReviewCycleDropdown(state).loaded;
};

export const selectReviewCycleDropdownItems = (state: any) => {
    return currentReviewCycleDropdown(state).items
};
