import React from 'react';
import { RigoFroalaEditorContext } from './rigo-froala-editor-context';

export const useFroalaEditor = () => {
  const context = React.useContext(RigoFroalaEditorContext);
  if (context === undefined) {
    throw new Error(
      'useFroalaEditor must be used within a <RigoFroalaEditorContext />',
    );
  }
  return context;
};
