import { z } from "zod";

export const PmsDashboardItemSchema = z.object({
  Id: z.number().optional(),
  ReviewCycleId: z.number().optional(),
  ReviewCycleName: z.string().optional(),
  FormId: z.number().optional(),
  FormName: z.string().optional(),
  IsClosed: z.boolean().optional(),
  ReviewType: z.string().optional(),
  TotalParticipants: z.number().optional(),
  TotalCompleted: z.number().optional(),
  HidePublishScore: z.boolean().optional(),
  IsScorePublished: z.boolean().optional(),
});
export type IPMSDashboardItem = z.infer<typeof PmsDashboardItemSchema>;

export const PmsDashboardItemCollectionSchema = z.array(PmsDashboardItemSchema);
export type IPMSDashboardItemCollection = z.infer<
  typeof PmsDashboardItemCollectionSchema
>;
