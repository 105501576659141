import * as fromInterface from "../../../ts";
import { ShowHideColumnsState } from "./slice";

const selectShowHideColumnSlice = (state: any): ShowHideColumnsState => {
  return state.dataStore.showHideColumns;
};

export const selectShowHideColumnStatus = (
  state: any
): fromInterface.loadingType => {
  return selectShowHideColumnSlice(state).status;
};

export const selectshowHideColumnLoaded = (state: any): boolean => {
  return selectShowHideColumnSlice(state).loaded;
};

export const selectShowHideColumnItems = (state: any) => {
  return selectShowHideColumnSlice(state).items;
};
