import { createSlice } from "@reduxjs/toolkit";
import * as fromInterface from "../../../ts";

export interface PerformanceRolloutPreviewState {
    item: any,
    status: fromInterface.loadingType;
    loaded: boolean;
}

const initialState: PerformanceRolloutPreviewState = {
    item: null,
    status: fromInterface.STATUS.LOADING,
    loaded: false,
};

const performanceRolloutPreviewSlice = createSlice({
    name: "performance rollout preview",
    initialState,
    reducers: {
        fetchRequest: (state) => {
            state.status = fromInterface.STATUS.LOADING;
        },
        fetchSuccess: (state, action) => {
            const payload = action.payload

            state.item = payload;
            state.status = fromInterface.STATUS.IDLE;
            state.loaded = true

        },

        clear: (state) => {
            state = initialState
        },
    },
});

export const {
    fetchRequest,
    fetchSuccess,
    clear,
} = performanceRolloutPreviewSlice.actions

export default performanceRolloutPreviewSlice.reducer;
