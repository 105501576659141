// @ts-nocheck
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import isEmpty from 'helpers/isEmpty'
import { addMessage } from 'actions/validateAction'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import RouteEnum from 'constants/routeEnum'
import { useSelector } from 'react-redux'
import { roles } from 'constants/constants'
import environment from 'environment'
import Loader from 'components/loaders'
import {
  goToOldEmployeeDashabord,
  goToOldAdminDashabord,
} from 'constants/constants'

interface Navigate {
  addMessage?: any
  baseUrl?: any
  location?: any
  history?: any
}

const linkData = [
  {
    name: 'Payroll',
    links: [
      { to: RouteEnum.PayrollDashboardPath, name: 'Payroll Dashvoard' },
      { to: RouteEnum.PayrollComponent, name: 'Payroll Component' },
    ],
  },
  {
    name: 'Travel',
    links: [
      { to: RouteEnum.TravelAllowanceExpensePath, name: 'Allowance Expense' },
      { to: RouteEnum.TravelCategoryPath, name: 'Travel Category' },
      { to: RouteEnum.TeamTravelRequestPath, name: 'Travel Request' },
    ],
  },
  {
    name: 'Recruit',
    links: [
      {
        to: RouteEnum.RequisitionRequestPath,
        name: 'Requisition Request',
      },
      { to: RouteEnum.RecruitDashboardPath, name: 'DashBoard' },
      { to: RouteEnum.JobCreatePath, name: 'Job Create' },
      { to: RouteEnum.JobListing, name: 'Job Listing' },
    ],
  },
  {
    name: 'Employee',
    links: [{ to: RouteEnum.EmployeeDashBoard, name: 'Employee Dashboard' }],
  },
  {
    name: 'Login',
    links: [
      { to: RouteEnum.LoginPath, name: 'Login' },
      { to: RouteEnum.Logout, name: 'Logout' },
      { to: RouteEnum.Settings, name: 'Settings' },
    ],
  },
]

function Navigate(props: Navigate) {
  const userRed = useSelector((state: any) => state.auth.user)
  const userDetails = localStorage.getItem('UserDetail')
  const user = !isEmpty(userRed)
    ? userRed
    : !isEmpty(userDetails)
    ? JSON.parse(userDetails)
    : []
  useEffect(() => {
    if (!isEmpty(user)) {
      const a: any[number] = roles()
      // if (a.includes(user.RoleId)) {
      if (!user.IsEmployeeType) {
        if (environment.isProduction) {
          // goToOldAdminDashabord(props.baseUrl)
          props.history.push(RouteEnum.HRDashboardPath)
        } else {
          props.history.push(RouteEnum.HRDashboardPath)
        }
        // props.history.push(RouteEnum.HRDashboardPath);
      } else {
        if (environment.isProduction) {
          if (user && user.RoleName === 'OnboardingEmployee') {
            props.history.push(RouteEnum.EmployeeOnboarded)
          } else {
            props.history.push(RouteEnum.EmployeeDashBoard)
          }
        } else {
          if (user && user.RoleName === 'OnboardingEmployee') {
            props.history.push(RouteEnum.EmployeeOnboarded)
          } else {
            props.history.push(RouteEnum.EmployeeDashBoard)
          }
        }
        // props.history.push(RouteEnum.EmployeeDashBoard);
      }
    }
  }, [user])
  return <div className="links "></div> 
}

const Block = ({ name, links }, index) => {
  return (
    <div key={name} className="link">
      <div className="link-header">{name}</div>
      {/* {
        environment.isProduction && <Loader backgroundColor="rgba(255,255,255,1)" loading={true} type="spinner" />
      } */}
      <ul className="link-list">
        {links.map((item) => {
          return (
            <li key={item.name} className="link-list__item">
              <Link to={item.to}>{item.name}</Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

const mapStateToProps = (state) => ({
  validateReducer: state.validateReducer,
})

export default connect(mapStateToProps, { addMessage })(Navigate)
