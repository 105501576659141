// @ts-nocheck 
import React, {useEffect, useRef} from "react";
import {useState} from "react";
import classnames from "classnames";
import Form from "components/form";
import shortid from "shortid";
import {LMSQuestionTypeEnum} from "scenes/lms/helpers/enums";
import CourseRadio from "./courseRadio";
import CourseCheck from "./courseCheck";

function Question(props) {
  const {question, answers, setAnswers, coursePageId} = props;
  const ans = answers?.find((item) => item.QuestionRefId === question.Id);

  const onChange = (value, isId = false, isMulti = false) => {
    setAnswers((prev) => {
      return (
        prev &&
        prev.map((item) => {
          let newItem = {...item, errors: null};
          if (item.QuestionRefId == question.Id) {
            return {
              ...newItem,
              Answer: !isId ? value : "",
              AnswerRefId: isId && !isMulti ? value : 0,
              MultipleAnswerRefId: isMulti ? value : [],
            };
          }
          return newItem;
        })
      );
    });
  };

  const getComponents = (type) => {
    switch (type) {
      case LMSQuestionTypeEnum.ShortText:
        return (
          <div className="course-page__quiz-form">
            <Form
              name="Answer"
              value={ans && ans.Answer}
              onChange={(name, value) => onChange(value)}
              error={ans?.errors}
            />
          </div>
        );
      case LMSQuestionTypeEnum.LongText:
        return (
          <div className="course-page__quiz-form">
            <Form
              name="Answer"
              formName="textarea"
              value={ans && ans.Answer}
              onChange={(name, value) => onChange(value)}
              error={ans?.errors}
            />
            {/* {ans?.errors && <span className="error__message">{ans.errors}</span>} */}
          </div>
        );

      case LMSQuestionTypeEnum.RadioButton:
        return (
          <CourseRadio
            answerOptions={question.AnswerOptions}
            answer={ans}
            onChange={onChange}
            question={question}
          />
        );
      case LMSQuestionTypeEnum.CheckBox:
        return (
          <CourseCheck
            answerOptions={question.AnswerOptions}
            answer={ans}
            onChange={onChange}
            question={question}
          />
        );
      default:
        break;
    }
  };

  return (
    <div className="course-page__quiz-body">
      <div className="course-page__quiz-label">Q. {question?.Question}</div>
      {getComponents(question?.QuestionType)}
    </div>
  );
}

function shuffle(arrays) {
  const array = [...arrays];
  var currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}

export default Question;
