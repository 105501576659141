// @ts-nocheck 
import {v1} from "constants/urls";

export default {
  signIn: `${v1}/auth/signin`,
  signOut: `${v1}/auth/signOut`,
  refreshToken: `${v1}/auth/refresh-token`,
  baseUrl: `${v1}/common/net-framework-app-base-url`,
  publicBaseUrl: `${v1}/common/public-recruitment-base-url`,
  signInWithOauth: `${v1}/auth/signin-with-oauth`,
  signInViaVianet: `${v1}/ext/callback-oauth`,
  tfaQrCode: `${v1}/auth/tfa-qr-code`,
  tfaCodeVerify: `${v1}/auth/tfa-code/verify`,
  tfaRecoveryEmail: `${v1}/auth/tfa-code-recovery-email`,
  tfaCodeReset: `${v1}/auth/tfa-code-reset`,
  samlRequest :`${v1}/auth/saml-request`,
  signInViaSamlSSO: `${v1}/ext/callback-saml`,
};
