// import { RightType, permissions } from "./permissionEnum";

// export { RightType, permissions };

export * from "./permissionEnum";

export const MANAGER_DEF_TAG = {
  directMangerDefined: 1,
  directManagerNotDefined: 2,
  matrixManagerDefined: 3,
  matrixManagerNotDefined: 4,
  customManagerDefined: 5,
  customManagerNotDefined: 6,
};

export const ASSIGNED_SUMMARY_COLOR = {
  "1": "#2266E3", //"Direct Managers defined":"1",
  "2": "#db8585", //"Direct Managers not defined":"2"
};

export const MANAGERIAL_TYPE = {
  Position: 1,
  Person: 2,
};

export const REPORTING_TO_MANAGER = {
  directManager: 1,
  matrixManager: 2,
  customManager: 3,
};

export const USER_GROUP_TAB = {
  active: true,
  disabled: false,
};

export enum MEMBER_POOL_OPTIONS {
  // Region = 1,
  // Cluster = 2,
  Branch = 3,
  Department = 4,
  Status = 5,
  Level = 6,
  JobCode = 7, //description:position
  Person = 8,
}

export const userGroupMemberApiFormat = {
  id: null,
  poolOptionRefId: null,
  poolOptionValues: "",
  poolRank: null,
};

export const ROLE_TAB = {
  active: 1,
  disabled: 0,
  deleted: 2,
};

export enum ROLE_TYPE {
  superAdmin = 1,
  hrManager = 2,
  branchManager = 3,
  departmentHead = 4,
  directManager = 5,
  employee = 6,
  standard = 7,
  viewOnly = 8,
  isEmployeeOnboard = 9,
}

export const MODAL_OPEN_TYPE = {
  new: 1,
  update: 2,
  delete: 3,
  assign: 4,
  setPermission: 5,
  status: 6,
  createPassword: 7,
};

export const CUSTOM_PERMISSION_TAB = {
  category: 0,
  moduleRights: 1,
  adminRights: 2,
};

export const USER_MANAGEMENT_TAB = {
  active: 1,
  disabled: 0,
  deleted: 2,
};

export const USER_TYPE = {
  OnBoarding: 0,
  Employee: 1,
  Standard: 2,
  Guest: 3,
};
