//to be shifted to enums/routes/ after merging in main branch
export enum PrimaryMenuRoutesEnum {
  CoreHR = "/hr/dashboard",
  LeaveAndTime = "/leavetime/dashboard",
  Payroll = "/payroll/dashboard",
  TaskManagement = "/tasks/boards/list",
  Recruitment = "/recruit/dashboard",
  TravelManagement = "/links/TravelManagement/2",
  Training = "/links/Training/2",
  ShiftRoster = "/links/ShiftRoster/2",
  LMS = "/lms/courses/management",
  PerfomanceAppraisal = "/appraisals",
  Settings = "/links/Settings/4",
}
