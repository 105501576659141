import React, { Component } from "react";
import { connect } from "react-redux";
import "../scss";
import Icons from "components/icons";
import Clock from "components/clock";
import Timer from "components/timer";
import FormGroup from "components/form";
import {
  GetProjectsForTimeFunction,
  GetRunningTask,
  SaveTaskState,
} from "../action";
import { Enum_EventType, Enum_TaskState } from "../enums";
import Button from "components/button";

export class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeProject: null,
      activeProjectWithFullData: null,
      isPlayed: false,
      isTimerActivated: null,
      tickerStartTime: new Date(),
      tickerTimeInMS: null,
      drop: false,
      taskId: 0,
      stateId: 0,
      searchTerm: "",
      timeText: null,
      isButtonReady: false,
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  async componentDidMount() {
    const { searchTerm } = this.state;
    await this.props.GetProjectsForTimeFunction(searchTerm);
    await this.initialize();

    document.addEventListener("mousedown", this.handleClickOutside);

    setTimeout(() => {
      this.setState({ isButtonReady: true });
    }, 1000);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(e) {
    if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
      this.setState({ drop: false });
    }
  }

  initialize = async () => {
    if (this.props.isAuthenticated) {
      await this.props.GetRunningTask();
      this.onRunningTimeReceived();
    }
  };

  onRunningTimeReceived = () => {
    const {
      projectsForTimeFunction,
      formatedProjectsForTimeFunction,
      runningTask,
    } = this.props;

    if (runningTask) {
      const {
        TaskSpentTime,
        ProjectId,
        ProjectName,
        TaskId,
        StateId,
        ClientId,
        ClientName,
      } = this.props.runningTask;

      let isPlayed = true;
      let isTimerActivated = true;

      if (StateId == Enum_TaskState.Completed) {
        isPlayed = false;
        isTimerActivated = false;
      } else if (StateId == Enum_TaskState.Paused) {
        isPlayed = false;
      }

      const availableTimeInMs = TaskSpentTime ? TaskSpentTime * 60 * 1000 : 0;

      const activeProjectWithFullData = {
        ProjectId,
        ProjectName,
        ClientId,
        ClientName,
      };
      const activeProject = { label: ProjectName, value: ProjectId };

      this.setState({
        taskId: TaskId,
        stateId: StateId,
        activeProjectWithFullData,
        activeProject,
        tickerTimeInMS: availableTimeInMs,
        // tickerTimeInString: availableTimeInString,
        isPlayed: isPlayed,
        isTimerActivated: isTimerActivated,
      });
    }
  };

  saveTaskState = async () => {
    const { activeProject, taskId, stateId } = this.state;
    const data = {
      TaskId: taskId,
      ProjectId: activeProject ? activeProject.value : 0,
      StateId: stateId,
    };
    await this.props.SaveTaskState(data, this.saveResponseTaskId);
    if (stateId == Enum_TaskState.Completed) {
      this.setState({
        activeProject: null,
        activeProjectWithFullData: null,
      });
    }
    if (stateId === Enum_TaskState.Started) {
      setTimeout(() => {
        this.setState({ isButtonReady: true });
      }, 1000);
    }
  };

  saveResponseTaskId = (taskId) => {
    this.setState({ taskId: taskId });
  };

  loadAutoSelectOptions = async (value) => {
    this.setState({ searchTerm: value });
    await this.props.GetProjectsForTimeFunction(value);
    return this.props.formatedProjectsForTimeFunction;
  };

  handleAutoSelect = (name, selected) => {
    const { projectsForTimeFunction } = this.props;
    const activeProjectWithFullData = projectsForTimeFunction
      ? projectsForTimeFunction.find((f) => f.ProjectId == selected.value)
      : null;

    this.setState(
      {
        isButtonReady: false,
        isTimerActivated: true,
        [name]: selected,
        activeProjectWithFullData: activeProjectWithFullData,
        stateId: Enum_TaskState.Started,
        taskId: 0,
        isPlayed: true,
        tickerStartTime: new Date(),
      },
      () => {
        this.saveTaskState();
      }
    );
  };

  handleComplete = () => {
    this.setState(
      {
        isTimerActivated: false,
        isPlayed: false,
        tickerTimeInMS: null,
        stateId: Enum_TaskState.Completed,
      },
      async () => {
        this.saveTaskState();
      }
    );
  };

  handlePlay = () => {
    this.setState(
      {
        // isButtonReady: false,
        isTimerActivated: true,
        isPlayed: true,
        stateId: Enum_TaskState.Resumed,
        tickerStartTime: new Date(),
      },
      () => {
        this.saveTaskState();
      }
    );
  };
  handlePause = () => {
    this.setState(
      {
        isPlayed: false,
        isSaveTickerTime: true,
        stateId: Enum_TaskState.Paused,
      },
      () => {
        this.saveTaskState();
      }
    );
  };

  handleTickerTime = (timeInMs) => {
    this.setState({
      tickerTimeInMS: timeInMs,
      isSaveTickerTime: false,
    });
  };

  async onDrop() {
    const toggleDrop = !this.state.drop;
    // if (toggleDrop) {
    //   await this.initialize();
    // }
    this.setState({ drop: toggleDrop });
  }

  render() {
    const {
      activeProject,
      activeProjectWithFullData,
      isPlayed,
      isSaveTickerTime,
      tickerTimeInMS,
      tickerTimeInString,
      isTimerActivated,
      tickerStartTime,
      drop,
      timeText,
      isButtonReady,
    } = this.state;
    const {
      formatedProjectsForTimeFunction,
      projectsForTimeFunction,
    } = this.props;

    return (
      <div className={`drop drop-fixed ${drop ? "active" : ""}`}>
        {/* <button
          ref={(dropdown) => (this.dropdown = dropdown)}
          onClick={this.onDrop.bind(this)}
          className="button-outline btn-start"
        >
          <Icons name="StopWatch" color={"#b7b7b7"} />
          <span> {activeProject && timeText ? timeText : "Start"}</span>
          <Icons name="ArrowUpRight" />
        </button> */}

        <Button
          type="button-outline"
          bg="primary"
          buttonType="icon-button"
          leftIcon={<Icons name="StopWatch" justSVG />}
          rightIcon={<Icons name="ArrowUpRight" justSVG />}
          title={
            isButtonReady && (activeProject && timeText ? timeText : "Start")
          }
          ref={(dropdown) => (this.dropdown = dropdown)}
          onClick={this.onDrop.bind(this)}
        />

        <div className="drop__content" ref={(ref) => (this.wrapperRef = ref)}>
          <div className="time-function">
            <div className="time-function-activity">
              <div className="time-function-activity-header">
                <div className="time-function-activity-header-left">
                  <div className="time-function-activity-header-left-action">
                    {isTimerActivated && (
                      // <span
                      //   className="flex group complete"
                      //   onClick={() => this.handleComplete()}
                      // >
                      //   <Icons name="BoldCheck" size={30} />
                      //   Complete
                      // </span>
                      <Button
                        buttonType="icon-button"
                        icon={<Icons name="BoldCheck" justSVG />}
                        title="Complete"
                        onClick={() => this.handleComplete()}
                        bg="secondary"
                      />
                    )}

                    {!isPlayed ? (
                      // <span
                      //   className="flex group"
                      //   style={{
                      //     background: !activeProject ? "#b7b7b7" : "#6dd400",
                      //   }}
                      //   onClick={() => activeProject && this.handlePlay()}
                      // >
                      //   <Icons name="Play" size={30} />
                      //   {!isTimerActivated ? "Start" : "Resume"}
                      // </span>
                      <Button
                        buttonType="icon-button"
                        icon={<Icons name="Play" justSVG />}
                        title={!isTimerActivated ? "Start" : "Resume"}
                        onClick={() => activeProject && this.handlePlay()}
                        bg={!activeProject ? "secondary-dark" : "secondary"}
                      />
                    ) : (
                      // <span
                      //   className="flex group pause"
                      //   onClick={this.handlePause}
                      // >
                      //   <Icons name="Pause" size={30} />
                      //   Pause
                      // </span>
                      <Button
                        buttonType="icon-button"
                        icon={<Icons name="Pause" justSVG />}
                        title={"Pause"}
                        onClick={this.handlePause}
                        bg="primary-light"
                      />
                    )}
                  </div>

                  <div className="time-function-activity-header-left-detail">
                    <div className="time-function-activity-header-left-detail-client">
                      {!activeProjectWithFullData
                        ? "Please select a project"
                        : activeProjectWithFullData.ProjectName}
                    </div>
                    <div className="time-function-activity-header-left-detail-project">
                      {activeProjectWithFullData &&
                        activeProjectWithFullData.ClientName}
                    </div>
                  </div>
                </div>
                <div className="time-function-activity-header-right">
                  <span>{isTimerActivated ? "TIME WORKED" : "TIME NOW"}</span>
                  <span className="time-function-activity-header-right-clock">
                    {isTimerActivated ? (
                      <Timer
                        start={isPlayed}
                        tickerStartTime={tickerStartTime}
                        availableTimeInMS={tickerTimeInMS}
                        isSaveTickerTime={isSaveTickerTime}
                        getTickerTime={(timeInMs) =>
                          this.handleTickerTime(timeInMs)
                        }
                        getTimeText={(text) =>
                          this.setState({ timeText: text })
                        }
                      />
                    ) : (
                      <Clock
                        getTimeText={(text) =>
                          this.setState({ timeText: text })
                        }
                      />
                    )}
                  </span>
                </div>
              </div>

              {!activeProject ? (
                <div>
                  <div className="time-function-activity-selection">
                    {/* <AsyncAutoSelect
                      onChange={this.handleAutoSelect}
                      name="activeProject"
                      value={activeProject}
                      options={formatedProjectsForTimeFunction}
                      loadOptions={this.loadAutoSelectOptions}
                      label="Choose a Project"
                    /> */}

                    <FormGroup
                      formName="asyncautoselect"
                      name={"activeProject"}
                      value={activeProject}
                      options={formatedProjectsForTimeFunction}
                      onChange={this.handleAutoSelect}
                      loadOptions={this.loadAutoSelectOptions}
                      label="Choose a Project"
                    />
                  </div>
                  {formatedProjectsForTimeFunction &&
                    formatedProjectsForTimeFunction.map((list, count) => {
                      const activeProjectWithFullData = projectsForTimeFunction
                        ? projectsForTimeFunction.find(
                            (f) => f.ProjectId == list.value
                          )
                        : null;

                      if (activeProjectWithFullData && count <= 2) {
                        const activeClass =
                          activeProject && activeProject.value == list.value
                            ? "active"
                            : "";
                        return (
                          <div
                            className={`${
                              "time-function-activity-top_three " + activeClass
                            } `}
                            key={count}
                            onClick={() =>
                              this.handleAutoSelect("activeProject", list)
                            }
                          >
                            <span className="time-function-activity-top_three-project">
                              {activeProjectWithFullData.ProjectName}
                            </span>
                            <span className="time-function-activity-top_three-client">
                              {activeProjectWithFullData.ClientName}
                            </span>
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                </div>
              ) : tickerTimeInMS && isPlayed ? (
                <p>
                  Timer is running. You can pause to take a break or Complete
                  the task
                </p>
              ) : (
                <p>
                  Timer is paused. You can resume working or Complete the task
                  when you paused.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  runningTask: state.taskTrackerReducer.runningTask,
  projectsForTimeFunction: state.taskTrackerReducer.projectsForTimeFunction,
  isAuthenticated: state.auth.isAuthenticated,
  formatedProjectsForTimeFunction:
    state.taskTrackerReducer.formatedProjectsForTimeFunction,
});

const mapDispatchToProps = {
  GetRunningTask,
  GetProjectsForTimeFunction,
  SaveTaskState,
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
