// @ts-nocheck 
import React, { useEffect, useMemo, useState } from "react";
import Filter from "./courseAssignmentFilter";
import AssignedEmployeeList from "./assignedEmployeeList";
import "./styles.scss";
import EditAssignment from "./editAssignment";
import Button from "components/button";
import { useHistory } from "react-router";
import RouteEnum from "constants/routeEnum";
import AssignCourse from "./assign";
import { IoMdArrowBack } from "react-icons/io";
import isEmpty from "helpers/isEmpty";
import {
  getAssignedEmployeeList,
  getMulitpleCourses,
  unAssignCourse,
} from "../helpers/action";
import { useDispatch, useSelector } from "react-redux";

const CourseAssignment = (props) => {
  const [state, setState] = useState({
    open: false,
    employee: null,
    openAssign: false,
    pageIndex: 0,
    pageSize: 10,
    courseName: null,
    employeeName: "",
    branchId: -1,
    departmentId: -1,
    status: -1,
  });
  const [assignedEmployees, setAssignedEmployees] = useState([]);
  const dispatch = useDispatch();

  const course = props?.location?.state?.course;
  useEffect(() => {
    setState((prev) => {
      let newState = { ...prev, courseName: course };
      loadAssignedEmployees(newState);
      return newState;
    });
  }, [course]);

  const handleChange = (name, val) => {
    setState((prev) => {
      const nextState = { ...prev, [name]: val };
      loadAssignedEmployees(nextState);
      return nextState;
    });
  };

  const handleSearchClick = (val) => {
    const { courseName, employeeName, activeBranch, activeDepartment, status } = val;
    const branchId = activeBranch?.value;
    const departmentId = activeDepartment?.value;
    const statusId = status?.value;

    setState((prev) => {
      const nextState = {
        ...prev,
        courseName,
        employeeName,
        branchId,
        departmentId,
        status: statusId,
      };

      loadAssignedEmployees(nextState);
      return nextState;
    });
  };
  const loadAssignedEmployees = async (obj) => {
    const {
      pageIndex,
      employeeName,
      pageSize,
      branchId,
      departmentId,
      status,
      courseName,
    } = obj;
    let param = {
      pageIndex,
      pageSize,
      branchId,
      departmentId,
      status,
      employeeName,
    };
    param["courseId"] = courseName?.value;

    const res: any = await dispatch(getAssignedEmployeeList(param));
    setAssignedEmployees(res?.Data);
  };
  const handleUnassignEmployee = async (id) => {
    const res = await dispatch(unAssignCourse(id));
    res &&
      setAssignedEmployees(
        assignedEmployees
          .filter((x) => x.AssignedId !== id)
          .map((x) => ({ ...x, TotalRows: x.TotalRows - 1 }))
      );
  };
  return (
    <div className="course-assign">
      <div className="course-assign__header">
        <div
          onClick={() => props.history.push(RouteEnum.lmsCourseManagement)}
          className="courseForm__header-back"
        >
          <IoMdArrowBack />
        </div>
        <div className="course-assign__title">Course Assignment</div>
      </div>
      <div className="course-assign__body">
        <div className="course-assign__filter">
          <Filter onSearch={handleSearchClick} course={state.courseName} />
        </div>
        <AssignedEmployeeList
          data={assignedEmployees}
          handleRowChange={(num) => handleChange("pageSize", num)}
          handleIndexChange={(num) => handleChange("pageIndex", num)}
          pageIndex={state.pageIndex}
          rowsPerPage={state.pageSize}
          onEditClicked={(emp) => setState({ ...state, employee: emp, open: true })}
          onUnassignEmployee={handleUnassignEmployee}
        />
        <EditAssignment
          open={state.open}
          employee={state.employee}
          onModalClose={() => setState({ ...state, employee: null, open: false })}
          onSuccessSubmitting={() => loadAssignedEmployees(state)}
        />
      </div>
    </div>
  );
};

export default CourseAssignment;
