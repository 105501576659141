import * as fromSlice from "./slice";
import * as fromPerformanceScoreStore from "./../performance-score"
export const udpatePerformanceScoreFilter = (newFilters: any): any => (dispatch: any, getState: any) => {
    dispatch(fromSlice.udpateFilter(newFilters))
}

export const clearPerformanceScoreFilter = () => (dispatch: any, getState: any) => {
    dispatch(fromSlice.clear())
}

export const udpatePerformanceScoreTablePagination = (pageInfo: any) => (dispatch: any, getState: any) => {
    // OPTIMISTIC UI UPDATE
    dispatch(fromSlice.udpateFilter(pageInfo))
    dispatch(fromPerformanceScoreStore.fetchPerformanceScoreList())
}
