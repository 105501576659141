import { Box, FormLabel } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import 'react-datepicker/dist/react-datepicker.css';

import './react-date-picker.scss';

// const ExampleCustomInput: FC<any> = forwardRef(
//   ({ name, value, onClick, onChange }: any, ref: any) => (
//     <Input
//       name={name}
//       className="example-custom-input"
//       onClick={onClick}
//       onChange={onChange}
//       ref={ref}
//       value={value}
//     />
//   )
// );

export const ReactDatePicker: React.FunctionComponent<any> = props => {
  const { name, value, onChange2, onChangeRHF, label, ...rest } = props;

  const [startDate, setStartDate] = useState<null | Date>(null);

  const handleChange = (date: any) => {
    onChange2?.(name, date);
    onChangeRHF?.(date);
    setStartDate(date);
  };

  useEffect(() => {
    setStartDate(() => {
      return value?.length > 0 ? new Date(value) : null;
    });
  }, [value]);

  return (
    <Box>
      <FormLabel>{label}</FormLabel>
      <Box>
        <DatePicker
          onChange={(date: any) => handleChange(date)}
          name={name}
          value={startDate}
          className='rigo-single__calender'
          calendarClassName='rigo-single__calender-open'
          {...rest}
        />
      </Box>
    </Box>
  );
};

ReactDatePicker.defaultProps = {
  format: 'yyyy/MM/dd',
  maxDate: new Date(),
};

export default ReactDatePicker;
