import { createSlice } from "@reduxjs/toolkit";
import * as fromInterface from "../../../ts";

export interface RatingScaleState {
    items: fromInterface.RatingScale[],
    status: fromInterface.loadingType;
    loaded: boolean;
}

const initialState: RatingScaleState = {
    items: [],
    status: fromInterface.STATUS.LOADING,
    loaded: false,
};

const ratingScaleSlice = createSlice({
    name: "rating scale",
    initialState,
    reducers: {
        fetchRequest: (state) => {
            state.status = fromInterface.STATUS.LOADING;
        },
        fetchSuccess: (state, action) => {

            const payload = action.payload

            state.items = payload;
            state.status = fromInterface.STATUS.IDLE;
            state.loaded = true

        },
        clear: (state) => {
            state.items = []
            state.status = fromInterface.STATUS.IDLE
            state.loaded = false
        },
    },
});

export const {
    fetchRequest,
    fetchSuccess,
    clear,
} = ratingScaleSlice.actions

export default ratingScaleSlice.reducer;
