import * as fromSlice from './slice'
import * as fromInterface from '../../../ts'
import { ApiClient } from '../../../network/apiClient'
import {
  EMPLOYEE_DATA_CHANGE_REQUEST_APPROVE,
  EMPLOYEE_DATA_CHANGE_REQUEST_LIST,
  EMPLOYEE_DATA_CHANGE_REQUEST_REJECT,
} from './api'

// STORES
import * as fromEmployeeDataChangeRequestsFilterStore from './../employee-data-change-requests-filter'

export const fetchEmployeeDataChangeRequests =
  () => async (dispatch: any, getState: any) => {
    dispatch(fromSlice.fetchRequest())

    const state = getState()
    const currentFilterState =
      fromEmployeeDataChangeRequestsFilterStore.selectCurrentEmployeeDataChangeRequestsFilter(
        state
      )

    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.post<any>({
        endpoint: EMPLOYEE_DATA_CHANGE_REQUEST_LIST,
        body: {
          ...currentFilterState,
          action: currentFilterState?.action?.value ?? '',
          employeeName: currentFilterState?.employeeName || '',
          section: currentFilterState?.section?.value ?? '',
          fromDate: currentFilterState?.fromDate,
          toDate: currentFilterState?.toDate,
        },
        dispatch,
      })
    const { Data, Status } = response

    if (Status) {
      dispatch(fromSlice.fetchSuccess(Data))
    }
  }

export const approveEmployeeDataChangeRequest =
  (id: any) => async (dispatch: any) => {
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.put<any>({
        endpoint: `${EMPLOYEE_DATA_CHANGE_REQUEST_APPROVE}?id=${id}`,
        dispatch,
      })

    const { Status } = response

    if (Status) {
      dispatch(fetchEmployeeDataChangeRequests())
    }
  }

export const rejectEmployeeDataChangeRequest =
  (id: any) => async (dispatch: any) => {
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.remove<any>({
        endpoint: `${EMPLOYEE_DATA_CHANGE_REQUEST_REJECT}/${id}`,
        dispatch,
      })

    const { Status } = response

    if (Status) {
      dispatch(fetchEmployeeDataChangeRequests())
    }
  }
