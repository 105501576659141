// @ts-nocheck 
import React from "react";
import Button from "components/button";
import Form from "components/form";

const CourseFilter = (props) => {
  const {onSearch, filter, setFilter, courseCategories} = props;
  const handleChange = (name, val) => {
    setFilter((prev) => {
      var newFilter = {...prev, [name]: val};
      onSearch(newFilter);
      return newFilter;
    });
  };
  return (
    <div className="course-management__filters">
      <div className="course-management__filters-left">
        <Form
          width="200px"
          name="courseName"
          placeholder="Search course"
          value={filter.courseName}
          onChange={handleChange}
        />
        <Form
          formName="customdateinput"
          isDateRange
          width="200px"
          name="createdOn"
          value={filter.createdOn}
          enableOkButton
          placeholder="created date range"
          onChange={handleChange}
        />
        <Form
          formName="reactselect"
          options={courseCategories}
          name="categoryId"
          width="200px"
          placeholder="Category"
          value={filter.categoryId}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};
export default CourseFilter;
