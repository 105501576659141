
import RouteEnum from "../../../constants/routeEnum";
import Courses from "../../../scenes/lms/courses";
import Course from "../../../scenes/lms/course";
import CoursePage from "../../../scenes/lms/course/page";
import AddCourse from "../../../scenes/lms/addCourse";
import AssignCourse from "../../../scenes/lms/assignCourse";
import CourseAssignment from "../../../scenes/lms/courseAssignment";
import CourseManagement from "../../../scenes/lms/courseManagement";
import CourseSettings from "../../../scenes/lms/courseSettings";
import CourseResults from "../../../scenes/lms/courseResults";
import CourseCategories from "../../../scenes/lms/courseCategories";
import CourseCertificateSettings from '../../../scenes/lms/courseCertificateSettings'

const lmsLinks = [
  {
    path: RouteEnum.lmsRoutes,
    exact: true,
    component: Courses,
    title: "LMS Courses",
    // feature:features.CoreHR
  },
  {
    path: RouteEnum.lmsRoutes + "/:type",
    exact: true,
    component: Courses,
    title: "LMS Courses",
    // feature:features.CoreHR
  },
  {
    path: RouteEnum.lmsCourse + "/:courseId",
    exact: true,
    component: Course,
    title: "LMS Course",
    // feature:features.CoreHR
  },
  {
    path: `${RouteEnum.lmsCourse}/:courseId/:assignedId`,
    exact: true,
    component: Course,
    title: "LMS Course",
    // feature:features.CoreHR
  },
  {
    path: RouteEnum.lmsCourse + "/:courseId/pages/:pageId",
    exact: true,
    component: CoursePage,
    title: "Course Page",
    // feature:features.CoreHR
  },
  {
    path: RouteEnum.lmsCourse + "/:courseId/:assignedId/pages/:pageId",
    exact: true,
    component: CoursePage,
    title: "Course Page",
    // feature:features.CoreHR
  },
  {
    path: RouteEnum.lmsCourseAdd,
    exact: true,
    component: AddCourse,
    title: "Add LMS Course",
    isAdmin: true,
    // feature:features.CoreHR
  },
  {
    path: RouteEnum.lmsCourseAdd + "/:courseId",
    exact: true,
    component: AddCourse,
    title: "Edit LMS Course",
    isAdmin: true,
    // feature:features.CoreHR
  },
  {
    path: RouteEnum.lmsCourseAdd + "/:courseId/:pageType",
    exact: true,
    component: AddCourse,
    title: "Add LMS Course Page",
    isAdmin: true,
    // feature:features.CoreHR
  },
  {
    path: RouteEnum.lmsCourseAdd + "/:courseId/:pageType/:pageId",
    exact: true,
    component: AddCourse,
    title: "Edit LMS Course Page",
    isAdmin: true,
    // feature:features.CoreHR
  },
  {
    path: RouteEnum.lmsCourseAssign + "/:courseId",
    exact: true,
    component: CourseAssignment,
    title: "Assigned Employees",
    isAdmin: true,
    // feature:features.CoreHR
  },
  {
    path: RouteEnum.lmsCourseAssign,
    exact: true,
    component: CourseAssignment,
    title: "Assigned Employees",
    isAdmin: true,
    // feature:features.CoreHR
  },
  {
    path: RouteEnum.lmsCourseManagement,
    exact: true,
    component: CourseManagement,
    title: "LMS Course Management",
    isAdmin: true,
    // feature:features.CoreHR
  },
  {
    path: RouteEnum.lmsCourseSettings + "/:courseId",
    exact: true,
    component: CourseSettings,
    title: "LMS Course Settings",
    isAdmin: true,
    // feature:features.CoreHR
  },
  {
    path: RouteEnum.lmsCourseResults,
    exact: true,
    component: CourseResults,
    title: "LMS Course Results",
    isAdmin: true,
  },
  {
    path: RouteEnum.lmsCourseCategories,
    exact: true,
    component: CourseCategories,
    title: "LMS Course Categories",
    isAdmin: true,
  },
  {
    path: RouteEnum.lmsCourseCertificateSettings,
    exact: true,
    component: CourseCertificateSettings,
    title: "LMS Courses Certificate Settings",
    // feature:features.CoreHR
    isAdmin: true,
  },
];

export default lmsLinks;
