import * as fromSlice from './slice';
import * as fromInterface from '../../../ts';

const selectCurrentEmployeeDataChangeRequestsSlice = (
  state: any
): fromSlice.EmployeeDataChangeRequestsState => {
  return state.dataStore.employeeDataChangeRequests;
};

export const selectEmployeeDataChangeRequestsItems = (state: any): any[] => {
  return selectCurrentEmployeeDataChangeRequestsSlice(state).items;
};

export const selectEmployeeDataChangeRequestsStatus = (
  state: any
): fromInterface.loadingType => {
  return selectCurrentEmployeeDataChangeRequestsSlice(state).status;
};

export const selectEmployeeDataChangeRequestsLoaded = (state: any): boolean => {
  return selectCurrentEmployeeDataChangeRequestsSlice(state).loaded;
};
