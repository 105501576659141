import React from "react";
import { toastMessage } from "actions/validateAction";

export function catchError(dispatch, error) {
  let response = {};
  response.MessageType = "Danger";
  response.Message = error.message;

  toastMessage(dispatch, response);
}
