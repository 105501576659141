import { List, ListItem } from '@chakra-ui/react';
import { createRef, useCallback, useEffect } from 'react';
import { ScrollNumberProps } from '../interfaces';
import React from 'react';

export const ScrollNumber = (props: ScrollNumberProps) => {
  const { selectedValue, cellIdentifier, handleNumClick, nums } = props;

  const containerRef: any = createRef();

  const numRefs: any = nums?.reduce((acc: any, value) => {
    acc[`${cellIdentifier}-${value}`] = createRef();
    return acc;
  }, {});

  const focusNumber = useCallback(
    (num: number) => {
      const rect = numRefs[`${cellIdentifier}-${num}`]?.current?.getBoundingClientRect();
      containerRef?.current?.scrollTo(0, rect?.height * num);
    },
    [cellIdentifier, containerRef, numRefs]
  );

  useEffect(() => {
    if (selectedValue) {
      focusNumber(selectedValue);
    }
  }, [selectedValue, focusNumber]);

  const onNumClick = (number: number) => {
    handleNumClick?.(number);
    focusNumber(number);
  };

  const disabledHrs = [1,2,3]

  return (
    <List
      className="noscrollbar"
      borderRight="1px solid #e5e6eb"
      ref={containerRef}
      scrollBehavior="smooth"
      overflowY="scroll"
      color="black"
      fontSize="12px"
      width="50px"
    >
      {nums?.map((n) => {
        const isSelected = n === selectedValue;
        const sxProps = {
          ...(isSelected && {
            background: 'gray.100',
            color: 'gray.800',
          }),
          // ...(disabledHrs.includes(n)  && {
          //   cursor: 'disabled',
          //   bg: 'red.200',
          // }),
        };
        return (
          <ListItem
            justifyContent="center"
            alignItems="center"
            display="flex"
            ref={numRefs[`${cellIdentifier}-${n}`]}
            key={`${cellIdentifier}-${n}`}
            onClick={() => onNumClick(n)}
            padding="6px 0"
            fontSize="14px"
            fontWeight="400"
            cursor="pointer"
            textAlign="center"
            _hover={{
              background: 'gray.100',
              color: 'gray.800',
            }}
            sx={sxProps}
          >
            {n}
          </ListItem>
        );
      })}
    </List>
  );
};
