import * as fromInterface from "../../../ts";
import { CurrentFormState } from "./slice";

import * as fromReviewCycleStore from "../review-cycle";

const selectCurrentFormSlice = (state: any): CurrentFormState => {
    return state.dataStore.currentFormDetail;
};

export const selectCurrentFormStatus = (state: any): fromInterface.loadingType => {
    return selectCurrentFormSlice(state).status;
};

export const selectCurrentFormLoaded = (state: any): boolean => {
    return selectCurrentFormSlice(state).loaded;
};

export const selectCurrentFormDetail = (state: any) => {

    return selectCurrentFormSlice(state).detail;
    // const currentFormDetail = 
    // const reviewCycleItems = fromReviewCycleStore.selectReviewCycleItems(state)

    // const reviewCycleFound = reviewCycleItems.find(currentFormDetail.ReviewCycleId); 

    // ;
    // return {
    //     ...currentFormDetail,
    //     ReviewCycleId: reviewCycleFound ?? {}

    // }
};

export const selectCurrentFormDetailSet = (state: any): boolean => {
    return selectCurrentFormSlice(state).detail !== null;
};