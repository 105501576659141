import React, { lazy } from "react";
import RouteEnum from "../../../constants/routeEnum";

// SBI LOAN COMPONENTS
const SbiLoan = React.lazy(() => import("../../../scenes/sbiLoan/loanSetting"));
const SbiPersonalLoan = React.lazy(
  () => import("../../../scenes/sbiLoan/personalLoan"),
);
const SbiLoanStatusHistroy = React.lazy(
  () => import("../../../scenes/sbiLoan/loanStatusHistories"),
);
const SbiLoanPurpose = React.lazy(
  () => import("../../../scenes/sbiLoan/loanPurpose"),
);

// NEW LOAN COMPONENTS
const MyAppliedLoanRequests = React.lazy(
  () => import("../../../scenes/employee/sbiloan/myAppliedLoanRequests"),
);
const ConsumerLoanRequest = React.lazy(
  () => import("../../../scenes/employee/sbiloan/loanRequest/consumerLoan"),
);
const SocialLoanRequest = React.lazy(
  () => import("../../../scenes/employee/sbiloan/loanRequest/socialLoan"),
);
const PersonalLoanRequest = React.lazy(
  () => import("../../../scenes/employee/sbiloan/loanRequest/personalLoan"),
);
const SBILoanDetailView = React.lazy(
  () =>
    import("../../../scenes/employee/sbiloan/loanDetails/consumerLoan/index"),
);

const SBILoan = lazy(() => import("../../../scenes/employee/sbiloan"));

// SBI LOAN ROUTES
const SBILoanModule = [
  {
    path: RouteEnum.CreateLoanSbiPath,
    exact: true,
    component: SbiLoan,
    title: "Loan",
  },
  {
    path: RouteEnum.SbiLoanPurpose,
    exact: true,
    component: SbiLoanPurpose,
    title: "Loan",
  },
  {
    path: RouteEnum.StaffPersonalLoan,
    exact: true,
    component: SbiPersonalLoan,
    title: "Loan",
  },
  {
    path: RouteEnum.ConsumerLoanRequest,
    exact: true,
    component: ConsumerLoanRequest,
    title: "Consumer Loan Request",
  },
  {
    path: RouteEnum.UpdateConsumerLoanRequest + "/:id",
    exact: true,
    component: ConsumerLoanRequest,
    title: "Update Consumer Loan Request",
  },
  {
    path: RouteEnum.UpdateSocialLoanRequest + "/:id",
    exact: true,
    component: SocialLoanRequest,
    title: "Update Consumer Loan Request",
  },
  {
    path: RouteEnum.SocialLoanRequest,
    exact: true,
    component: SocialLoanRequest,
    title: "Social Loan Request",
  },
  {
    path: RouteEnum.PersonalLoanRequest,
    exact: true,
    component: PersonalLoanRequest,
    title: "Personal Loan Request",
  },
  {
    path: RouteEnum.UpdatePersonalLoanRequest + "/:id",
    exact: true,
    component: PersonalLoanRequest,
    title: "Update Personal Loan Request",
  },
  {
    path: RouteEnum.MyAppliedLoanRequests,
    exact: true,
    component: MyAppliedLoanRequests,
    title: "My Loan Requests",
  },
  {
    path: RouteEnum.LoanStatusHistory,
    exact: true,
    component: SbiLoanStatusHistroy,
    title: "Loan status histories",
  },
  {
    path: RouteEnum.ViewSBILoanDetail + "/:id",
    exact: true,
    component: SBILoanDetailView,
    title: "Loan Detail",
  },
];

const routes = features => [
  {
    path: RouteEnum.loanRoutes,
    component: SBILoan,
    feature: features.Loan,
    children: SBILoanModule,
  },
];

export default routes;
