import { createSlice } from "@reduxjs/toolkit";
import * as fromInterface from "../../../ts";
export interface PerformanceRolloutThreeSixtyListState {
    items: any,
    status: fromInterface.loadingType;
    loaded: boolean;
}

const initialState: PerformanceRolloutThreeSixtyListState = {
    items: null,
    status: fromInterface.STATUS.IDLE,
    loaded: false,
};

const performanceRolloutThreeSixtyListSlice = createSlice({
    name: "performance rollout",
    initialState,
    reducers: {
        fetchRequest: (state) => {
            state.status = fromInterface.STATUS.LOADING;
        },
        fetchSuccess: (state, action) => {
            const payload = action.payload

            state.items = payload;
            state.status = fromInterface.STATUS.IDLE;
            state.loaded = true

        },
        clear: (state) => {
            state = { ...initialState }
            return state
        },
    },
});

export const {
    fetchRequest,
    fetchSuccess,
    clear,
} = performanceRolloutThreeSixtyListSlice.actions

export default performanceRolloutThreeSixtyListSlice.reducer;
