// @ts-nocheck 
import {
    ADD_LOADING,
    ADD_REFRESH_LOADING,
    REMOVE_LOADING,
} from "../actions/types";
import { Action, Reducer } from 'redux';

export interface InitialState {
    loading:boolean,
    loadingType:string,
    refresh_loading:boolean
}

const initialState:InitialState = {
    loading: false,
    loadingType: '',
    refresh_loading:false,
};

export interface ActionType {
    payload:boolean,
    loadingType:string
    type:string,
    
}

export const reducer:Reducer<InitialState> = (state:InitialState | undefined,incomingAction:Action):InitialState =>{
    if (state === undefined) {
        return initialState;
    }
    const action = incomingAction as ActionType;
    switch (action.type) {
        case ADD_LOADING:
            return {
                ...state,
                loading: action.payload,
                loadingType:action.loadingType
            };
        case ADD_REFRESH_LOADING:
            return{
                ...state,
                refresh_loading:action.payload
            }
        default:
            return state;
    }
}