import { AprPerformanceReviewFlow } from "../../../enums/appraisal";
import { createSlice } from "@reduxjs/toolkit";
import isEmpty from "lodash/isEmpty";
import * as fromInterface from "../../../ts";

export interface PerformanceRolloutAppraisalState {
    item: fromInterface.PerformanceRolloutAppraisal,
    isValidTab1: boolean,
    isValidTab2: boolean,
    isValidTab3: boolean,
    status: fromInterface.loadingType;
    loaded: boolean;
}


const initialState: PerformanceRolloutAppraisalState = {
    item: {
        EmployeeIds: []
    },
    isValidTab1: false,
    isValidTab2: false,
    isValidTab3: false,
    status: fromInterface.STATUS.IDLE,
    loaded: false,
};

const performanceRolloutAppraisalSlice = createSlice({
    name: "performance rollout appraisal",
    initialState,
    reducers: {
        fetchRequest: (state) => {
            state.status = fromInterface.STATUS.LOADING;
        },
        fetchSucces: (state) => {
            state.status = fromInterface.STATUS.IDLE;
        },
        resetEmployeeIds: (state) => {

            state.item = { ...state.item, EmployeeIds: [] };
            state.isValidTab1 = false
            state.isValidTab2 = false
            state.isValidTab3 = false
            return state

        },
        updateItem: (state, action) => {
            const payload = action.payload
            state.item = payload;

            state.isValidTab1 = !isEmpty(state?.item?.ReviewCycleId) && !isEmpty(state?.item?.EmployeeIds)
            state.isValidTab2 = !isEmpty(state?.item?.ReviewCycleId) && !isEmpty(state?.item?.ReviewWorkflowId)
                && (!isEmpty(state?.item?.SelfReviewDeadline) || state?.item?.ReviewWorkflowId?.reviewFlow === AprPerformanceReviewFlow.ManagerReviewOnly) && !isEmpty(state?.item?.ManagerReviewDeadline)
                && !isEmpty(state?.item?.PerformanceFormId)

            state.isValidTab3 = state.isValidTab1 && state.isValidTab2

            state.status = fromInterface.STATUS.IDLE;
            state.loaded = true

        },
        clear: (state) => {
            state = initialState
            return state
        },
    },
});

export const {
    fetchRequest,
    fetchSucces,
    updateItem,
    resetEmployeeIds,
    clear,
} = performanceRolloutAppraisalSlice.actions

export default performanceRolloutAppraisalSlice.reducer;
