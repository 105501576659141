// @ts-nocheck 
import React, {Component} from "react";

const LoadingHOC = (Components: any) => {
  class LoadingHOCClass extends Component<any, any> {
    render() {
      const {loading, load} = this.props;
      if (loading) {
        // return <Loader open={loading} />
        return null;
      }
      return <Components {...this.props} />;
    }
  }
  return LoadingHOCClass;
};

export default LoadingHOC;
