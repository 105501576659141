export const Enum_EventType = {
  NewContract: 1,
  Renewal: 2,
};

export const Enum_WhatToArchive = {
  Project: "p",
  Client: "c",
};

export const Enum_TaskState = {
  Started: 1,
  Paused: 2,
  Resumed: 3,
  Completed: 4,
};

export const Enum_ProjectStatus = {
  Saved: 0,
  Forwarded: 1,
  Reviewed: 2,
  Approved: 3,
  Denied: 4,
};
