import React from 'react';
import { Checkbox, Wrap, WrapItem } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { CheckValue } from './interface';
import { regex, resolveObjectValueByPath } from '../../../../helpers';
import merge from 'lodash/merge';
import { Text, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
/**
 *
 * DOCUMENT USUAGE
  <Input
    inputName="checkboxgroup"
    label="Name Checkboxes"
    name="testCheckboxesGroup"
    control={control}
    options={INITIAL_VALUE}
    required
  /> 
 */
export const CheckboxgroupComponent = (props: any) => {
  const [checkedValues, setCheckedValues] = useState<CheckValue[]>([]);

  const {
    value,
    options,
    onChangeRHF,
    onChange2,
    customLabel,
    inputName,
    name,
    ...rest
  } = props;
  useEffect(() => {
    if (value) {
      setCheckedValues(value);
    }
  }, [value]);

  function handleSelect(checkedValue: CheckValue) {
    let containsInChecked: boolean = checkedValues?.some(
      (value: any) => value.value === checkedValue.value,
    );

    let removedFromCheckedValues = checkedValues?.filter(
      value => value.value !== checkedValue.value,
    );

    let addToCheckedValues = [...(checkedValues ?? []), checkedValue];

    const newValues = containsInChecked
      ? removedFromCheckedValues
      : addToCheckedValues;

    setCheckedValues(newValues);

    return newValues;
  }

  return (
    <Wrap direction={props.direction || 'row'} spacing={props.spacing}>
      {options ? (
        options.map((item: any, idx: number) => {
          let foundInCheckedValues = checkedValues?.find((cv: CheckValue) => {
            return cv?.value === item.value || cv === item.value;
          });

          return (
            <WrapItem key={`${idx}`} w={props.width || '100%'}>
              <Checkbox
                size='sm'
                {...rest}
                isChecked={foundInCheckedValues ? true : false}
                onChange={() => {
                  const d = handleSelect(item);
                  onChange2?.(name, d);
                  onChangeRHF?.(d);
                }}
              >
                {item.label}
              </Checkbox>
            </WrapItem>
          );
        })
      ) : (
        <>No data</>
      )}
    </Wrap>
  );
};

export const Checkboxgroup = (props: any) => {
  const {
    customLabel,
    name,
    label,
    width,
    control,
    ignoreControl = false,
    required = false,
    errors,
    rule,
  } = props;

  const error = errors && resolveObjectValueByPath(errors, name)?.message;

  let _rule = {
    required: {
      value: required,
      message: 'required',
    },
    pattern: {
      value: props?.type === 'email' && regex.email ? '' : required,
      message: 'invalid e-mail',
    },
    minLength: {
      value: 2,
      message: 'This input is below minLength.',
    },
  };

  if (rule) {
    _rule = merge(_rule, rule);
  }

  return (
    <FormControl id={name} isRequired={required} style={{ width }}>
      {customLabel ? (
        customLabel
      ) : (
        <FormLabel
          fontSize='18px'
          fontWeight='500'
          color='#252525'
          lineHeight='1'
        >
          {label}
        </FormLabel>
      )}
      <>
        {!control || ignoreControl ? (
          <CheckboxgroupComponent {...props} />
        ) : (
          <Controller
            control={control}
            name={name}
            rules={_rule}
            render={(controllerProps: any) => {
              const {
                field: { onChange, value },
              } = controllerProps;

              return (
                <CheckboxgroupComponent
                  {...props}
                  value={value}
                  onChangeRHF={onChange}
                />
              );
            }}
          />
        )}
      </>
      {/* RENDER ERROR */}
      {error && <Text color='red.500'>{error}</Text>}
    </FormControl>
  );
};

export default Checkboxgroup;
