import { createSlice } from "@reduxjs/toolkit";
import * as fromInterface from "../../../ts";

export interface PerformanceRolloutEmployeeFilterState {
    pageIndex: number,
    pageSize: number,
    reviewCycleId?: any,
    employeeName?: string | null,
    branchId?: any,
    departmentId?: any,
    hideEmployeeReceivedAssessment: boolean,
    isThreeSixty?: boolean
}

const initialState: PerformanceRolloutEmployeeFilterState = {
    pageIndex: 0,
    pageSize: 20,
    reviewCycleId: null,
    employeeName: "",
    branchId: null,
    departmentId: null,
    hideEmployeeReceivedAssessment: true,
    isThreeSixty: false
};

const performanceRolloutEmployeeFilterSlice = createSlice({
    name: "performance rollout employee filter",
    initialState,
    reducers: {
        udpateFilter: (state, action) => {
            const payload = action.payload
            state = { ...state, ...payload }
            return state

        },
        clear: (state) => {
            state = { ...initialState }
            return state
        },
    },
});

export const {
    udpateFilter,
    clear,
} = performanceRolloutEmployeeFilterSlice.actions

export default performanceRolloutEmployeeFilterSlice.reducer;
