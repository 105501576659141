// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as LeaveIcon } from "assets/images/icons/leave.svg";
import { ReactComponent as TimeIcon } from "assets/images/icons/time.svg";
import { ReactComponent as OvertimeIcon } from "assets/images/icons/overtime.svg";
import { ReactComponent as AllowanceIcon } from "assets/images/icons/allowance.svg";
import { ReactComponent as TravelIcon } from "assets/images/icons/travel.svg";

import { ReactComponent as CheckIcon } from "assets/images/icons/check.svg";
import { ReactComponent as DoubleCheckIcon } from "assets/images/icons/check-double.svg";
import { ReactComponent as CrossIcon } from "assets/images/icons/cross.svg";
import { ReactComponent as ThumbsUpIcon } from "assets/images/icons/thumbs-up.svg";
import { ReactComponent as CalendarPlusIcon } from "assets/images/icons/calendar-plus.svg";
import { ReactComponent as ForwardedIcon } from "assets/images/icons/forwarded.svg";
import { ReactComponent as PayIcon } from "assets/images/icons/pay.svg";
import { ReactComponent as PlusCircle } from "assets/images/icons/plus-circle.svg";
import { ReactComponent as ApprovedCircle } from "assets/images/icons/approved-check-circle.svg";
import { ReactComponent as RejectedCircle } from "assets/images/icons/rejected-times-circle.svg";
import { ReactComponent as CommentLine } from "assets/images/icons/comment-alt-lines.svg";
import { ReactComponent as Delete } from "assets/images/icons/delete-trash.svg";
import { ReactComponent as DetailsList } from "assets/images/icons/details-list-ul.svg";
import { ReactComponent as DownloadFileInvoice } from "assets/images/icons/download-file-invoice.svg";
import { ReactComponent as EditPen } from "assets/images/icons/edit-pen.svg";
import { ReactComponent as RejectTimes } from "assets/images/icons/reject-times.svg";
import { ReactComponent as PeopleArrows } from "assets/images/icons/people-arrows.svg";
import { ReactComponent as Settlement } from "assets/images/icons/money-check-edit.svg";
import { ReactComponent as SettlementReverted } from "assets/images/icons/money-check-edit.svg";
import { ReactComponent as LMSAssigned } from "assets/images/icons/LMSAssigned.svg";

interface SvgIcons {
  name: string;
  size?: number;
  color?: string;
  className?: string;
  styles?: object;
  justSVG?: boolean;
  onClick?: any;
}

function getIconComponent(name) {
  switch (name) {
    case "Leave":
      return LeaveIcon;
    case "Time":
      return TimeIcon;
    case "Overtime":
      return OvertimeIcon;
    case "Allowance":
      return AllowanceIcon;
    case "Travel":
      return TravelIcon;
    case "Check":
      return CheckIcon;
    case "Doublecheck":
      return DoubleCheckIcon;
    case "Cross":
      return CrossIcon;
    case "Like":
      return ThumbsUpIcon;
    case "CalendarPlus":
      return CalendarPlusIcon;
    case "Forwarded":
      return ForwardedIcon;
    case "Pay":
      return PayIcon;
    case "PlusCircle":
      return PlusCircle;
    case "ApprovedCircle":
      return ApprovedCircle;
    case "RejectedCircle":
      return RejectedCircle;
    case "CommentLine":
      return CommentLine;
    case "Delete":
      return Delete;
    case "DetailsList":
      return DetailsList;
    case "DownloadFileInvoice":
      return DownloadFileInvoice;
    case "EditPen":
      return EditPen;
    case "RejectTimes":
      return RejectTimes;

    case "PeopleArrows":
      return PeopleArrows;
    case "Settlement":
      return Settlement;
    case "SettlementReverted":
      return SettlementReverted;
    case "LMSAssigned":
      return LMSAssigned;
    default:
      return null;
  }
}

function index(props: SvgIcons) {
  const { name, size, color, className, styles, onClick } = props;

  if (!name) return null;

  let ReactIcon = getIconComponent(name);
  const finalStyles = {
    display: "flex",
    fontSize: size,
    width: size,
    color: color,
    fill: color,
    ...styles,
  };

  return (
    <ReactIcon
      style={finalStyles}
      className={className}
      onClick={(event) => {
        onClick && onClick(event);
      }}
    />
  );
}

index.defaultProps = {
  name: "",
  size: 16,
  color: "",
  className: "",
  styles: "",
};

export default index;
