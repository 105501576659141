import moment from "moment";
export const Date_Format = (date) => {
  return moment(new Date(date)).format("MM/DD/YYYY "); //it was YYYY/MM/DD
};
export const Date_Format_Reverse = (date) => {
  return moment(new Date(date)).format("YYYY/MM/DD");
};
export const Date_Format_Dot = (date) => {
  return moment(new Date(date)).format("YYYY.MM.DD");
};

export const Time_Format = (date) => {
  return moment(new Date(date)).format("hh:mm:ss a");
};

export const Time_Format_HM = (date) => {
  return moment(new Date(date)).format("hh:mm a");
};

export const DateTime_Format = (date) => {
  return moment(new Date(date)).format("MM/DD/YYYY HH:mm:ss"); //it was YYYY/MM/DD
};

export const formatedMonthDayYear = (date) => {
  return (
    moment(new Date(date)).format("MMMM") +
    " " +
    moment(new Date(date)).format("DD") +
    ", " +
    moment(new Date(date)).format("YYYY")
  );
};

export const formatedShortMonthDayYear = (date) => {
  return (
    moment(new Date(date)).format("MMM") +
    " " +
    moment(new Date(date)).format("DD") +
    ", " +
    moment(new Date(date)).format("YYYY")
  );
};

export const formatedToMonthDay = (date) => {
  return (
    moment(new Date(date)).format("MMMM") +
    " " +
    moment(new Date(date)).format("DD")
  );
};

export const startDatetime = (date) => {
  return moment(date, "YYYYMMDDHHmmss")
    .startOf("day")
    .format("YYYY/MM/DD HH:mm:ss");
};

export const endDatetime = (date) => {
  return moment(date, "YYYYMMDDHHmmss")
    .endOf("day")
    .format("YYYY/MM/DD HH:mm:ss");
};

export const getHrsMinFormatFromMinutes = (mins) => {
  let hours = mins / 60;
  let rhours = Math.floor(hours);
  let minutes = (hours - rhours) * 60;
  let rminutes = Math.round(minutes);
  return rhours + "h " + rminutes + "m";
};

export const isDateTodayOrAfter = (toCheck, givenDate) => {
  return (
    moment(new Date(toCheck)).isAfter(new Date(givenDate)) ||
    moment(new Date(toCheck)).isSame(new Date(givenDate), "day")
  );
};
