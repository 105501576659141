import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../../../network/axiosBaseQuery";
import { FETCH_PERFORMANCE_PREVIEW, FETCH_ROLLOUT_PREVIEW } from "./../api";

export const rolloutPreviewApi: any = createApi({
    baseQuery: axiosBaseQuery(),
    endpoints: (builder): any => ({
        getRolloutPreview: builder.query<any, any>({
            query: (rolloutId: number) => ({
                url: FETCH_ROLLOUT_PREVIEW.replace(":rolloutId", rolloutId + ""),
                method: "get",
            }),
            transformResponse: (response: any) => {
                // if (!response.Status) {
                //     throw new Error(response.Message);
                // }
                return response?.Data;
                // {
                //     ...response,
                //     Data: {
                //         MyUpdate: response.Data.MyUpdate,
                //         RequestCount: response.Data.RequestCount,
                //     },
                // };
            },
        }),


    }),
});

export const {
    useGetRolloutPreviewQuery,
} = rolloutPreviewApi;