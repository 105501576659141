import { createGenericSlice, GenericState } from '../../@base-store';

const singleEmployeeSlice = createGenericSlice({
  name: 'Leave Time Single Employee',
  initialState: { status: 'loading' } as GenericState<any, any>,
  reducers: {},
});

export const { fetchRequest, fetchSuccess, fetchError, updateFilters, clear } =
  singleEmployeeSlice.actions;

export default singleEmployeeSlice.reducer;
