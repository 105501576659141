// MOCKS
import { MOCK_DATA } from "../../../mock";

// STORE
import * as fromInterface from "../../../ts";
import * as fromCurrentFormDetailStore from "../current-form-detail";
import * as fromCurrentSectionFormDetailStore from "../current-section-form-detail";
import * as fromSelectedSectionOptionStore from "../selected-section-option";
import * as fromFormStateStore from "../performance-form-state";
import * as fromPerformanceFormWeightStore from "../performance-form-weight";

// API
import {
  CREATE_SECTION,
  FETCH_SECTIONS_BY_FORM_ID,
  FETCH_SECTION_DETAIL_BY_SECTION_ID,
  REMOVE_SECTION,
  UPDATE_SECTION,
} from "./api";
import { ApiClient } from "../../../network/apiClient";

// SLICE
import * as fromSlice from "./slice";
import { selectNextSectionItemObjToDisplay, selectNextSectionItemToDisplay } from "./selectors";

// LODASH
import isEmpty from "lodash/isEmpty";

/**
 * FETCH SECTION SIDEBARD DATA
 * @param param0
 * @returns
 */
export const fetchSections =
  ({ formId }: { formId?: number }): any =>
  async (dispatch: any) => {
    dispatch(fromSlice.fetchRequest());
    const response: fromInterface.ResponseWrapper<any> = await ApiClient.get<any>({
      endpoint: `${FETCH_SECTIONS_BY_FORM_ID.replace(":formId", formId + "")}`,
      dispatch: dispatch,
    });

    if (response?.Status) {
      dispatch(fromSlice.fetchSuccess(response.Data));
    }

    return response;
  };

export const createSections =
  ({ payload, cb }: { payload: any; cb: any }) =>
  async (dispatch: any, getState: any) => {
    const state = getState();
    const currentConfigurationFormId = fromCurrentFormDetailStore.selectCurrentFormDetail(state);

    let formatted = {
      ...payload,
      PerformanceFormId: currentConfigurationFormId?.Id,
      Order: 0,
      AllowSelfMarking: true,
      ImportKSA: true,
      ImportGoals: payload.ImportGoals || false,
      ToBeAnsweredBy: payload.ToBeAnsweredBy.map((item: any) => item.label).join(","),
      IntroText: payload.IntroText,
    };

    const response: fromInterface.ResponseWrapper<any> = await ApiClient.post<any>({
      endpoint: CREATE_SECTION,
      body: formatted,
      dispatch: dispatch,
      showMessage: true,
    });

    if (response?.Status) {
      // ASSIGN UNIQUE LOCAL ID
      formatted = { ...response?.Data, content: [] };

      // PUSH INTO SECTION LIST
      const { Id, PerformanceFormId, Name, Order } = response?.Data;

      dispatch(
        fromSlice.createSuccess({
          PerformanceFormId: PerformanceFormId,
          Id: Id,
          Name: Name,
          Order: Order,
        }),
      );

      // SET CREATED SECTION DETAIL AS CURRENT SECTION CONTENT
      dispatch(fromSelectedSectionOptionStore.selectSectionOption({ PerformanceSectionId: Id }));

      // if (response?.Status) {
      //     cb?.(response.Data)
      // }
      dispatch(
        fromPerformanceFormWeightStore.PerformanceFormWeightStore.fetchPerformanceFormWeights({
          formId: PerformanceFormId,
        }),
      );

      cb?.(response?.Data);
    }

    return null;
  };

export const updateSections =
  ({ payload, cb }: { payload: any; cb: any }) =>
  async (dispatch: any, getState: any) => {
    const state = getState();
    const currentConfigurationFormId = fromCurrentFormDetailStore.selectCurrentFormDetail(state);

    let formatted = {
      ...payload,
      PerformanceFormId: currentConfigurationFormId?.Id,
      AssessmentType: payload.AssessmentType,
      Order: 0,
      AllowSelfMarking: payload.AllowSelfMarking,
      ImportKSA: true,
      ImportGoals: payload.ImportGoals || false,
      ToBeAnsweredBy: payload.ToBeAnsweredBy.map((item: any) => item.label).join(","),
      IntroText: payload.IntroText,
    };

    const response: fromInterface.ResponseWrapper<any> = await ApiClient.post<any>({
      endpoint: CREATE_SECTION,
      body: formatted,
      dispatch: dispatch,
      showMessage: true,
    });

    if (response?.Status) {
      // SYNC PERFORMANCE FORM STATE
      const state = getState();
      const currentFormState = fromFormStateStore.selectFormState(state);
      const newFormState = { ...currentFormState, ...response?.Data };
      dispatch(fromFormStateStore.syncFormState(newFormState));

      // ASSIGN UNIQUE LOCAL ID
      formatted = { ...response?.Data, content: [] };

      // PUSH INTO SECTION LIST
      const { Id, PerformanceFormId, Name, Order } = response?.Data;

      dispatch(
        fromSlice.updateSuccess({
          PerformanceFormId: PerformanceFormId,
          Id: Id,
          Name: Name,
          Order: Order,
        }),
      );

      // SET CREATED SECTION DETAIL AS CURRENT SECTION CONTENT
      dispatch(fromSelectedSectionOptionStore.selectSectionOption({ PerformanceSectionId: Id }));

      // if (response?.Status) {
      //     cb?.(response.Data)
      // }
      cb?.(payload);
    }

    return null;
  };

const lowercaseKeys = (obj: any) => {
  return Object.keys(obj).reduce((accumulator: any, key: any) => {
    accumulator[key.toLowerCase()] = obj[key];
    return accumulator;
  }, {});
};

export const fetchSectionDetail =
  ({ PerformanceSectionId }: { PerformanceSectionId?: number }) =>
  async (dispatch: any, getState: any) => {
    const response: fromInterface.ResponseWrapper<any> = await ApiClient.get<any>({
      endpoint: FETCH_SECTION_DETAIL_BY_SECTION_ID.replace(":PerformanceSectionId", PerformanceSectionId + ""),
      dispatch: dispatch,
      showLoader: true,
    });

    if (response?.Status) {
      // SET CREATE SECTION AS SELECTED
      const mappedOuter = lowercaseKeys(response?.Data);

      const contents = response?.Data?.Contents.map((content: any) => {
        return lowercaseKeys(content);
      });

      const responseMapped = {
        ...mappedOuter,
        contents,
      };

      dispatch(fromFormStateStore.syncFormState(response?.Data));
    }
  };

export const removeSection =
  ({ id, cb }: { id: any; cb: any }) =>
  async (dispatch: any, getState: any) => {
    const response: fromInterface.ResponseWrapper<any> = await ApiClient.remove<any>({
      endpoint: REMOVE_SECTION.replace(":PerformanceSectionId", id + ""),
      dispatch: dispatch,
      showLoader: true,
    });

    if (response?.Status) {
      // SET SELECT AS -1
      const state = getState();
      const nextSidebarOption = selectNextSectionItemObjToDisplay(id)(state);

      dispatch(fromSelectedSectionOptionStore.selectSectionOption({ PerformanceSectionId: nextSidebarOption?.Id }));

      // REMOVE FROM SLICE
      await dispatch(fromSlice.removeSuccess({ id }));

      const currentConfigurationFormId = fromCurrentFormDetailStore.selectCurrentFormDetail(state);

      dispatch(
        fromPerformanceFormWeightStore.PerformanceFormWeightStore.fetchPerformanceFormWeights({
          formId: currentConfigurationFormId?.Id,
        }),
      );

      cb?.({ nextSidebarOption: nextSidebarOption });
    }

    return response?.Status;
  };

export const clearSections = () => (dispatch: any) => {
  dispatch(fromSlice.clear());
};
