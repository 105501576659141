// @ts-nocheck 
import React from "react";
import {BsTextareaT} from "react-icons/bs";
import {FaFont} from "react-icons/fa";
import {IoMdRadioButtonOn} from "react-icons/io";
import {MdCheckBox} from "react-icons/md";
import QuestionLabel from "../addCourse/contents/questionLabel";

const questionTypeOptions = [
  {label: <QuestionLabel label="Radio Buttons" icon={<IoMdRadioButtonOn />} />, value: 3},
  {label: <QuestionLabel label="Short Text" icon={<FaFont />} />, value: 1},
  {label: <QuestionLabel label="Check Boxes" icon={<MdCheckBox />} />, value: 4},
  {label: <QuestionLabel label="Long Text" icon={<BsTextareaT />} />, value: 2},
];
export default questionTypeOptions;
