// @ts-nocheck
import React, { Component } from "react";
import { connect } from "react-redux";
import complogo from "assets/images/Login-Banner.png";
import rigo from "assets/images/rigo-logo-vector.svg";
// import Modal from "Components/Common/Modal";
import { getComapnyLogo } from "actions/commonAction";
import google from "assets/images/google.svg";
import isEmpty from "helpers/isEmpty";
import classnames from "classnames";
// import mainlogo from 'assets/images/main-logo.png'
import rigologong from "assets/images/rigo-logo-ng.svg";
import rigologofooter from "assets/images/rigo-logo-vector.svg";
import {
  loginUser,
  forgotPassword,
  otherMessage,
  sendUsernameToEmail,
  loginViaVianet,
  getSamlRequest,
  verifyTFACode,
  postTFACodeReset,
  postTFARecoveryEmail,
  getTFAQRCode,
} from "actions/authAction";
import { useDispatch, useSelector } from "react-redux";
import { MdClose } from "react-icons/md";
import { Redirect } from "react-router-dom";
import { clearOtherMessage } from "actions/validateAction";
import Form from "components/form";
import Modal from "components/modal";
import { isFeatureEnabled } from "helpers/checkPermissions";
import { roles } from "constants/constants";
import environment from "environment";
import Loader from "components/loaders";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { LoginRequestType } from "constants/loginRequestType";
import ExternalLoginButton from "./externalLoginButtons";
import OtpInput from "./twoFactorAuth/views/otpInput";
import RouteEnum from "constants/routeEnum";
import { PinInput, PinInputField } from "@chakra-ui/react";

interface LoginProps {
  loginUser: Function;
  errors: object;
  auth: any;
  // loadingState:boolean,
  baseUrl: string;
  history: any;
  sendUsernameToEmail?: any;
  forgotPassword?: Function;
  getComapnyLogo?: Function;
  companyLogo: any;
  otherMessage?: Function;
  activeFeatureFlags: any;
  loginViaVianet: Function;
  oauthEnabled: boolean;
  hideHRMLoginWhenOauthEnabled: boolean;
  loginRequestType: any;
  getSamlRequest: Function;
}

interface LoginState {
  email: string;
  rigoId: string;
  password: string;
  rememberClient: boolean;
  errors: errorsState;
  modalOpen: boolean;
  isFeatured: boolean;
  loadingState: boolean;
  forgot: string;
  companyLogo: any;
}

interface errorsState {
  rigoId?: string;
  password?: string;
  email?: string;
}

const LOGIN_FEATURE = "CoreHR";

export class Login extends Component<LoginProps, LoginState> {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      rigoId: "",
      password: "",
      rememberClient: true,
      errors: {},
      isFeatured: false,
      modalOpen: false,
      loadingState: false,
      isTwoFactorEnabled: false,
      isCodeReset: false,
      forgot: "",
      companyLogo: {},
      otp: "",
      code: "",
      showRigoLogin: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
    this._renderForgot = this._renderForgot.bind(this);
    this._renderOtp = this._renderOtp.bind(this);
    this.onOTPSend = this.onOTPSend.bind(this);
    this.onCodeReset = this.onCodeReset.bind(this);
    this.onCodeValidate = this.onCodeValidate.bind(this);
    this.onResetTFACode = this.onResetTFACode.bind(this);
    // this._renderLoading= this._renderLoading.bind(this);
  }
  async componentDidMount() {
    const { getComapnyLogo, companyLogo, tfaQrDetails } = this.props;
    const { auth } = this.props;

    await getComapnyLogo();
    if (!isEmpty(companyLogo)) {
      this.setState({ companyLogo });
    }
    if (!isEmpty(tfaQrDetails)) {
      this.setState({ isTwoFactorEnabled: true });
    }
    this.onRedirect();
  }
  componentDidUpdate(prevProps, prevState) {
    // const {auth,errors,companyLogo} = this.props;
    const {
      auth,
      errors,
      activeFeatureFlags,
      companyLogo,
      baseUrl,
      tfaQrDetails,
    } = this.props;
    this.onRedirect();
    if (errors !== prevProps.errors) {
      this.setState({ errors });
    }
    if (!isEmpty(companyLogo) && companyLogo !== prevProps.companyLogo) {
      this.setState({ companyLogo });
    }
    if (!isEmpty(tfaQrDetails) && !this.state.isTwoFactorEnabled) {
      this.setState({
        isTwoFactorEnabled: true,
        email: tfaQrDetails?.UserName,
      });
    }
  }
  onRedirect = () => {
    const { auth, baseUrl } = this.props;
    if (auth.isAuthenticated) {
      // this.props.history.push('/');
      // window.location.href =getBaseAxios();
      // this.props.history.push('/');
      if (!isEmpty(auth.user)) {
        // if(!this.state.loadingState){
        //     this.setState({loadingState:true})
        // }
        // this.props.history.push('/');
        let a: any = roles();
        if (a.includes(auth.user.RoleId)) {
          if (environment.isProduction) {
            if (!this.state.loadingState) {
              this.setState({ loadingState: true });
            }
            // goToOldAdminDashabord(this.props.baseUrl)
            this.props.history.push("/");
            return;
          } else {
            this.props.history.push("/");
          }
        } else {
          if (environment.isProduction) {
            if (!this.state.loadingState) {
              this.setState({ loadingState: true });
            }
            // goToOldEmployeeDashabord(this.props.baseUrl)
            this.props.history.push("/");
            return;
          }
          this.props.history.push("/");
        }
      }
    }
  };
  onModalClose = () => {
    this.setState({ modalOpen: false });
  };
  // onChange(e:React.ChangeEvent<HTMLInputElement>) {
  onChange(name, value) {
    if (name === "email") this.setState({ email: value });
    if (name === "rigoId") this.setState({ rigoId: value });
    if (name === "password") this.setState({ password: value });
    if (name === "code") this.setState({ code: value.replace(/\s/g, "") });
  }

  async onForgot(e) {
    e.preventDefault();
    const { email, forgot } = this.state;
    const data = { email };
    const { isAuth, errors } = this.onEmailValidate(data);
    this.setState({ errors });
    console.log(isAuth);
    if (!isAuth) {
      return false;
    }
    if (forgot === "password") {
      this.props.forgotPassword(email);
    }
    if (forgot === "id") {
      this.props.sendUsernameToEmail(email);
    }
    if (forgot === "2fa") {
      const res = await this.props.postTFARecoveryEmail({ userName: email });
      this.setState({ isCodeReset: res });
    }
    this.setState({ modalOpen: false, email: "" });
  }
  onSubmit(e) {
    e.preventDefault();
    const { isFeatured } = this.state;
    if (isFeatureEnabled(LOGIN_FEATURE)) {
      const { rigoId, password } = this.state;
      const data = {
        rigoId,
        password,
      };
      const { isAuth, errors } = this.onValidate(data);
      this.setState({ errors });
      if (!isAuth) {
        return false;
      }
      this.props.loginUser(data, null, false, this.props.history);
    } else {
      this.props.otherMessage("Please enable this feature to access login");
    }
  }
  onValidate(data) {
    let errors: errorsState = {};
    if (isEmpty(data.rigoId)) {
      errors.rigoId = "RigoID is required";
    }
    if (isEmpty(data.password)) {
      errors.password = "Password is required";
    }
    return { isAuth: isEmpty(errors), errors };
  }
  onCodeValidate(data) {
    let errors: errorsState = {};
    if (isEmpty(data)) {
      errors.code = "Reset Code is required";
    }
    this.setState({ errors });
    return isEmpty(errors);
  }

  onEmailValidate(data) {
    let errors: any = {};
    if (isEmpty(data.email)) {
      errors.email = "Email Field is required";
    }
    return { isAuth: isEmpty(errors), errors };
  }
  async onOTPSend(final = null) {
    const { verifyTFACode, tfaQrDetails } = this.props;
    const { otp } = this.state;
    const data = {
      code: final ? final : otp,
      userName: tfaQrDetails?.UserName,
    };
    const response = await verifyTFACode(data);
    if (typeof response === "string") {
      this.setState({ errors: { otp: response } });
      return;
    }
    if (response && response?.Status) {
      this.props.history.push(RouteEnum.HRDashboardPath);
      this.setState({ errors: {} });
    }
  }
  async onResetTFACode() {
    const { verifyTFACode, tfaQrDetails } = this.props;
    const data = {
      userName: tfaQrDetails?.UserName,
    };
    const res = await this.props.postTFARecoveryEmail(data);
    this.setState({ isCodeReset: res, modalOpen: false, email: "" });
  }
  _renderForgot() {
    const { modalOpen, forgot, errors } = this.state;
    const { sendUsernameToEmail } = this.props;
    const istfa = forgot === "2fa";
    return (
      <Modal
        open={modalOpen}
        onModalClose={this.onModalClose}
        className='forgot-div'
        title={
          istfa
            ? "Having trouble with 2FA?"
            : forgot === "password"
            ? "Forgot Password"
            : "Forgot Rigo Id"
        }
      >
        <div className='forgot-container forgot-otp'>
          <p className='forgot-container__text'>
            {!istfa
              ? `Forgot your Rigo ID/Password? Please enter the registered email. You
            will recieve a link to create a new password via email`
              : `If you are having trouble with your 2FA, please click Reset 2FA button below and we will send you a reset code in your email. \n \n You will be asked to enter the code in your mail on reset.`}
          </p>

          {istfa ? (
            <div className='forgot-otp__btn'>
              <button onClick={() => this.onResetTFACode()}>Reset 2FA</button>
            </div>
          ) : (
            <form className='forgot-form' onSubmit={this.onForgot.bind(this)}>
              {/* {errors.email && <div className="forgot-error">{errors.email}</div>} */}
              <div
                title={errors.email}
                className={classnames({
                  "forgot-input": true,
                  "forgot-error": !isEmpty(errors.email),
                })}
              >
                <input
                  type='email'
                  name='email'
                  onChange={e => this.onChange("email", e.target.value)}
                  value={this.state.email}
                  placeholder='Enter your registered Email'
                  disabled={istfa}
                />
                <button
                  // onClick={()=>this.onSendEmail()}
                  type='submit'
                  className='btn btn-primary'
                >
                  Send
                </button>
              </div>
            </form>
          )}
        </div>
      </Modal>
    );
  }
  async onCodeReset() {
    const Username = this.props.tfaQrDetails?.UserName;
    const isValid = this.onCodeValidate(this.state.code);
    if (!isValid) {
      return;
    }
    if (Username) {
      const data = {
        userName: Username,
        code: this.state.code,
      };
      const response = await this.props.postTFACodeReset(data);

      if (response) {
        this.setState({ code: null, isCodeReset: false });
        await this.props.getTFAQRCode(Username);
        this.props.history.push(RouteEnum.TwoFactorAuthPath);
      }
    }
  }
  _renderOtp() {
    const Username = this.props.tfaQrDetails?.UserName;
    const { errors } = this.state;
    if (this.state.isCodeReset) {
      return (
        <div className='login-form'>
          <Form
            formName='textinput'
            label='Please enter 2FA reset code sent to your email'
            placeholder=''
            // placeholder='Please enter your otp code recovered from your email address.'
            name='code'
            type='text'
            placeholder='Rigo ID'
            onChange={this.onChange}
            value={this.state.code}
            error={this.state.errors.code}
            onKeyUp={e => {
              if (e?.key === "Enter") {
                this.onCodeReset();
              }
            }}
            autoFocus
          />

          {Username && (
            <div className='small-links'>
              <div className='login-form__forgot'>
                <span
                  className='forgot-link'
                  onClick={async () => {
                    const res = await this.props.postTFARecoveryEmail({
                      userName: Username,
                    });
                  }}
                >
                  Did not recieve email. Resend code ?
                </span>
              </div>
            </div>
          )}

          <button
            // disabled={loginLoading}
            className={"login-submit flex"}
            type='submit'
            onClick={this.onCodeReset}
          >
            Reset 2FA
          </button>
        </div>
      );
    }
    const isLengthSix = this.state.otp.replace(/\s/g, "").length === 6;
    console.log({ errors });
    const err =
      Array.isArray(errors) || errors?.otp
        ? Array.isArray(errors)
          ? errors[0]
          : errors?.otp
        : null;
    return (
      <div className='login-form login-form__otp'>
        <div className='login-otp__header'>
          <span>
            Enter the 6-digit code found in your Mobile Authenticator App
          </span>
        </div>
        <div className='login-otp'>
          {/* <OtpInput 
          onEnter={(val)=>{
            if(isLengthSix){
              this.onOTPSend()
            }
          }}
          onChange={(val) => this.setState({ otp: val })} 
          value={this.state.otp} 
          valueLength={6} 
        /> */}
          <PinInput
            value={this.state.otp}
            onChange={value => this.setState({ otp: value })}
            autoFocus
            onComplete={val => this.onOTPSend(val)}
            otp
          >
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
          </PinInput>
        </div>
        {err && <div className='error__message center-text'>{err}</div>}

        <button
          // disabled={loginLoading}
          className={"login-submit flex"}
          type='submit'
          disabled={!isLengthSix}
          onClick={this.onOTPSend}
        >
          Submit
        </button>
        <div className='small-links'>
          <div className='login-form__forgot'>
            <span
              className='forgot-link'
              onClick={() =>
                this.setState({
                  modalOpen: true,
                  forgot: "2fa",
                })
              }
            >
              Having trouble with 2FA?
            </span>
          </div>
        </div>
      </div>
    );
  }
  public render() {
    const { loginLoading, loginViaVianetLoading } = this.props.auth;
    console.clear();
    console.log(this.props);

    const { oauthEnabled, loginRequestType, hideHRMLoginWhenOauthEnabled } =
      this.props;
    const { companyLogo, isTwoFactorEnabled, showRigoLogin } = this.state;
    const loginButtonClass = classnames("login-submit flex", {
      "login-loading": loginLoading,
    });

    const externalLoginProps = {
      oauthEnabled,
      loginRequestType,
      getSamlRequest: this.props.getSamlRequest,
      updateState: () => this.setState({ errors: {} }),
      loginViaVianet: this.props.loginViaVianet,
      loginViaVianetLoading,
      loginLoading,
    };
    return (
      <section className='wrapper-login'>
        <div className='login-title'>
          <img src={rigologong} alt='Rigo HRMS' />
          {/* <span className="capsule"> HRMS</span> */}
        </div>
        <div className='login-centered'>
          <div className='container-login'>
            {/* <LogoutMessage /> */}

            {/* <div className="login-logo">
              <img
                src={
                  companyLogo && companyLogo.CompanyLogo
                    ? // getUploadPath(companyLogo.CompanyLogo) :
                      companyLogo.CompanyLogo
                    : rigo
                }
                alt="CompanyLogo"
              />
            </div> */}

            {isTwoFactorEnabled ? (
              this._renderOtp()
            ) : (
              <form className='login-form' onSubmit={this.onSubmit}>
                {(!oauthEnabled ||
                  (oauthEnabled &&
                    (!hideHRMLoginWhenOauthEnabled || showRigoLogin))) && (
                  <>
                    <Form
                      formName='textinput'
                      label='Rigo ID'
                      name='rigoId'
                      type='text'
                      placeholder='Rigo ID'
                      onChange={this.onChange}
                      value={this.state.rigoId}
                      error={this.state.errors.rigoId}
                      autoFocus
                    />
                    <Form
                      formName='textinput'
                      label='Password'
                      name='password'
                      type='password'
                      placeholder='Password'
                      className='password-field'
                      onChange={this.onChange}
                      value={this.state.password}
                      error={this.state.errors.password}
                    />
                    {!isEmpty(this.state.errors) ? (
                      <div className='error__message'>
                        {typeof this.state.errors === "string" &&
                          this.state.errors}
                      </div>
                    ) : null}
                    <div className='small-links'>
                      <div className='login-form__forgot'>
                        <span
                          className='forgot-link'
                          onClick={() =>
                            this.setState({
                              modalOpen: true,
                              forgot: "password",
                            })
                          }
                        >
                          Forgot password
                        </span>
                        <span
                          onClick={() =>
                            this.setState({
                              modalOpen: true,
                              forgot: "id",
                            })
                          }
                          className='forgot-id'
                        >
                          Forgot Rigo ID
                        </span>
                      </div>
                    </div>

                    <button
                      disabled={loginLoading}
                      className={loginButtonClass}
                      type='submit'
                    >
                      {loginLoading ? (
                        <>
                          <span className='button-spin'></span>
                          <span>Logging In...</span>
                        </>
                      ) : (
                        "Log In"
                      )}
                    </button>
                  </>
                )}

                <ExternalLoginButton {...externalLoginProps} />
              </form>
            )}
            {oauthEnabled && hideHRMLoginWhenOauthEnabled && !showRigoLogin && (
              <div
                className='login__rigoid'
                onClick={e => {
                  this.setState({ ...this.state, showRigoLogin: true });
                }}
              >
                Log In with Rigo ID
              </div>
            )}
            <div className='login-footer'>
              <div className='login-footer__logo'>
                <a href='https://rigonepal.com/' target='_blank'>
                  <img src={rigologofooter} alt='RIGO HRMS' />
                  <h1>Rigo</h1>
                </a>
              </div>
            </div>
          </div>
        </div>
        {this._renderForgot()}
        {/* {this._renderLoading()} */}
        {environment.isProduction && (
          <Loader
            backgroundColor='rgba(255,255,255,1)'
            loading={this.state.loadingState}
            type='spinner'
          />
        )}
      </section>
    );
  }
}

const LogoutMessage = () => {
  const dispatch = useDispatch();
  const otherMessage = useSelector(
    (state: any) => state.validateReducer.otherMessage,
  );
  if (!isEmpty(otherMessage)) {
    return (
      <div className='login-message'>
        <div className='login-message__text'>{otherMessage.message}</div>
        <div
          onClick={() => dispatch(clearOtherMessage())}
          className='login-message__close'
        >
          <MdClose />
        </div>
      </div>
    );
  }
  return null;
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  tfaQrDetails: state.auth.tfaQrDetails,
  // loadingState:state.loadingReducer.loading,
  activeFeatureFlags: state.permissionReducer.activeFeatureFlags,
  companyLogo: state.commonReducer.companyLogo,
  oauthEnabled: state.commonReducer.oauthEnabled,
  loginRequestType: state.commonReducer.loginRequestType,
  hideHRMLoginWhenOauthEnabled:
    state.commonReducer.hideHRMLoginWhenOauthEnabled,
});

const mapDispatchToProps = {
  loginUser,
  getComapnyLogo,
  sendUsernameToEmail,
  otherMessage,
  forgotPassword,
  loginViaVianet,
  getSamlRequest,
  verifyTFACode,
  postTFACodeReset,
  postTFARecoveryEmail,
  getTFAQRCode,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
