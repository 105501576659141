import React, { Component } from "react";
import Avatar from "react-avatar";
import logo from "assets/images/rigo_logo.png";

export class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="footer-image">
          {/* <Avatar
            className="company-logo"
            name={"Logo"}
            size={30}
            round={true}
            src={logo}
          /> */}
          <img src={logo} alt="Rigo Logo"/>
        </div>
        <div className="footer-title">
          <h1>Rigo HRMS</h1>
        </div>
      </footer>
    );
  }
}

export default Footer;
