// @ts-nocheck 
import React, { useEffect, useState } from "react";
import FormGroup from "components/form";
import { checkBoxListInterface } from "ts/interfaces/formInterface";

function CheckBoxList(props: checkBoxListInterface) {
  const { onChange, selectedItems, name } = props;

  const [_items, setItems] = useState([]);

  useEffect(() => {
    if (props.items) {
      let nextItem = props.items || [];
      setItems(nextItem);
    }
  }, []);

  useEffect(() => {
    let nextItem = props.items || [];
    if (selectedItems) {
      nextItem = nextItem.map((x) => ({
        ...x,
        isSelected: selectedItems.includes(x.value),
      }));

      onChange && onChange(name, selectedItems);
    }
    setItems(nextItem);
  }, [props.selectedItems]);

  const updateItems = (index, isChecked) => {
    let nextItem = [..._items];
    nextItem[index]["isSelected"] = isChecked;
    setItems(nextItem);
  };

  const onCheckBoxChange = (index, isChecked) => {
    updateItems(index, isChecked);

    const selectedValues = _items
      .filter((x) => x.isSelected)
      .map((x) => x.value);
    onChange && onChange(name, selectedValues);
  };

  const renderItems = () => {
    var elItems = _items.map((item, index) => (
      <FormGroup
        formName="checkgroup"
        name={name}
        label={item.label}
        value={item.value}
        checked={item.isSelected}
        labelPosition="right"
        onChange={(e) => {
          onCheckBoxChange(index, e.target.checked);
        }}
      />
    ));
    return elItems;
  };
  return <div>{renderItems()}</div>;
}
export default CheckBoxList;
