// @ts-nocheck 
import React, {useState} from "react";
import "./styles.scss";
import classnames from "classnames";
import image from "assets/images/sample.jpg";
import {
  employeeCourseView,
  getAssignedCoursesDashboad,
  getCoursesDashboad,
} from "../helpers/action";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import RouteEnum from "constants/routeEnum";
import dayjs from "dayjs";
import {AiOutlineUser} from "react-icons/ai";
import {FaFeather} from "react-icons/fa";
import CourseStatusBar from "./courseStatusTabBar";
import AssigneCourses from "./assignedCourses";
import OtherAvailableCourses from "./otherAvailableCourses";
import {CourseDashboardStatus} from "../helpers/enums";
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

function Courses(props) {
  const {type} = props?.match?.params;
  const [status, setStatus] = useState(CourseDashboardStatus.All);

  useEffect(() => {
    if (type) {
      type === "assigned" && setStatus(CourseDashboardStatus.Assign);
    }
  }, [type]);

  const handleStatusChange = (status) => {
    setStatus(status);
  };
  return (
    <div className="courses ">
      <div className="row-bg">
        <div className="wrap-container">
          <div className="courses-status">
            <CourseStatusBar onStatusChange={handleStatusChange} status={status} />
          </div>
        </div>
      </div>
      <div className="wrap-container">
        <div className="list-courses">
          <AssigneCourses status={status} />
          <OtherAvailableCourses status={status} />
        </div>
      </div>
    </div>
  );
}

export default Courses;
