import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

import RigoFroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";

interface FroalaEditorViewProps {
  data: any;
}
export const FroalaEditorView = (props: FroalaEditorViewProps) => {
  const { data } = props;
  return <RigoFroalaEditorView model={data} />;
};

export default FroalaEditorView;
