import * as fromInterface from "../../../ts";
import { ReviewCycleState } from "./slice";

const currentReviewCycle = (state: any): ReviewCycleState => {
    return state.dataStore.reviewCycle;
};

export const selectActiveReviewCycleItems = (state: any): any => {
    return currentReviewCycle(state).activeItems;
};

export const selectActiveReviewCycleItemFilter = (state: any): any => {
    return currentReviewCycle(state).activeItemFilter;
};


export const selectActiveReviewCycle = (state: any): any => {
    const { isLoadingActiveItem, activeItems, activeItemFilter } = currentReviewCycle(state)
    return { isLoading: isLoadingActiveItem, items: activeItems, filter: activeItemFilter }

};

export const selectArchivedReviewCycle = (state: any): any => {
    const { isLoadingArchivedItem, archivedItems, archivedItemFilter } = currentReviewCycle(state)
    return { isLoading: isLoadingArchivedItem, items: archivedItems, filter: archivedItemFilter }

};

export const selectArchivedReviewCycleItemFilter = (state: any): any => {
    return currentReviewCycle(state).archivedItemFilter;
};