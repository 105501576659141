// @ts-nocheck
import types from "constants/types";
import { normalizeBy } from "helpers/normalizeData";

const initialState = {
  courseCategories: [],
  courseList: [],
  filteredCourseList: [],
  searchFilteredCourseList: [],
  courseResultSummary: [
    {
      Assigned: 0,
      Completed: 0,
      CourseId: 0,
      CourseTitle: "",
      InProgress: 0,
      NotStarted: 0,
    },
  ],
  courseResultDetail: [],
  courseSettings:[],
  courseResultStat: {},
  coursePageQuestions: [],
  resultSummaryDetail: [],
  courseEnrollements: [],
  courseNavigationPolicies: [],
  courseShowTo: [],
  courseOption: {},
  course: {},
  coursePages: [],
  coursePage: null,
  courseContent: [],
  assignCourses: [],
  branchesTable: [],
  answers: [],
  normalizedAnswers: {},
  departmentTable: [],
  courseDashboard: [],
  questionDetails: [],
  assignedCourseDashboard: [],
  courseCompleted: null,
  courseDashboardCount: {
    TotalAssigned: 0,
    TotalProgress: 0,
    TotalCompleted: 0,
    TotalNearDeadlines: 0,
  },
  filterValues: {
    courseId: -1,
    employeeName: "",
    branchId: -1,
    departmentId: -1,
    statusId: -1,
  },
  requesting: false,
};

export default function (state: any = initialState, action: any) {
  switch (action.type) {
    case types.GET_ASSIGNED_COURSE_DASHBOARD: {
      return {
        ...state,
        assignedCourseDashboard: action.payload,
      };
    }
    case types.GET_LMS_COURSE_CERTIFICATE: {
      return {
        ...state,
        courseSettings: action.payload,
      };
    }
    case types.ADD_UPDATE_LMS_COURSE_CERTIFICATE: {
      return {
        ...state,
        courseSettings:[action.payload],
      };
    }
    case types.GET_BRANCHES_DETAILS: {
      return {
        ...state,
        branchesTable: action.payload,
      };
    }
    case types.GET_COURSE_QUESTION_DETAILS: {
      return {
        ...state,
        questionDetails: action.payload,
      };
    }
    case types.ADD_COURSE_QUESTION_DETAILS: {
      return {
        ...state,
        questionDetails: [...state.questionDetails, ...action.payload],
      };
    }
    case types.UPDATE_COURSE_QUESTION_DETAILS: {
      return {
        ...state,
        questionDetails: [
          ...state.questionDetails.map((x) =>
            x.Id === action.payload.Id ? { ...action.payload } : x
          ),
        ],
      };
    }
    case types.ADD_ANSWER: {
      return {
        ...state,
        answers: [...action.payload],
      };
    }
    case types.GET_COURSE_COMPLETED: {
      return {
        ...state,
        courseCompleted: action.payload,
      };
    }
    case types.ADD_COURSE_COMPLETED: {
      return {
        ...state,
        course: {
          ...state.course,
          EmployeeCoursePageDetail:
            state?.course?.EmployeeCoursePageDetail?.map((x) => ({
              ...x,
              IsRead:
                x.CoursePageId === action?.payload.CoursePageId
                  ? true
                  : x.IsRead,
            })),
        },
      };
    }
    case types.UPDATE_COURSE_ASSIGN_STATUS: {
      return {
        ...state,
        course: {
          ...state.course,
          AssignedId: action.payload.Id,
          AssignedStatus: action.payload.Status,
        },
      };
    }
    case types.GET_ANSWERS: {
      return {
        ...state,
        answers: action.payload,
        normalizedAnswers: action.payload?.reduce(normalizeBy("Section"), {}),
      };
    }
    case types.DELETE_COURSE_QUESTION_DETAILS: {
      return {
        ...state,
        questionDetails: state.questionDetails.filter(
          (item) => item.Id !== action.payload
        ),
      };
    }
    case types.GET_DEPARTMENT_DETAILS: {
      return {
        ...state,
        departmentTable: action.payload,
      };
    }
    case types.GET_COURSE_DASHBOARD: {
      return {
        ...state,
        courseDashboard: action.payload,
      };
    }
    case types.GET_COURSE_DASHBOARD_CONCAT: {
      return {
        ...state,
        courseDashboard: [...state.courseDashboard, ...action.payload],
      };
    }
    case types.GET_COURSE_ENROLLMENT: {
      return {
        ...state,
        courseEnrollements: action.payload,
      };
    }
    case types.GET_COURSE_COURSE_NAVIGATION_POLICY: {
      return {
        ...state,
        courseNavigationPolicies: action.payload,
      };
    }
    case types.GET_COURSE: {
      const data = Array.isArray(action.payload)
        ? action.payload[0]
        : action.payload;
      return {
        ...state,
        course: data,
      };
    }
    case types.GET_COURSE_DASHBOARD_COUNT: {
      return { ...state, courseDashboardCount: action.payload };
    }
    case types.EDIT_COURSE_STATUS: {
      return {
        ...state,
        courseList:
          state.courseList &&
          state.courseList.map((item) => {
            if (item.Id === action.courseId) {
              return {
                ...item,
                status: action.status,
              };
            }
            return item;
          }),
      };
    }
    case types.GET_COURSE_OPTIONS: {
      return {
        ...state,
        courseOption: action.payload,
      };
    }
    case types.GET_SHOW_COURSE_TO: {
      return {
        ...state,
        courseShowTo: action.payload,
      };
    }
    case types.GET_COURSE_CATEGORIES: {
      return {
        ...state,
        courseCategories: action.payload?.map((x) => ({
          ...x,
          label: x.Name,
          value: x.Id,
        })),
      };
    }
    case types.ADD_COURSE_CATEGORY: {
      return {
        ...state,
        requesting: action.requesting,
      };
    }
    case types.UPDATE_COURSE_CATEGORY: {
      return {
        ...state,
        courseCategories: state.courseCategories.map((category) => {
          if (category.Id === action.payload?.Id) {
            return action.payload;
          } else {
            return category;
          }
        }),
        requesting: action.requesting,
      };
    }
    case types.DELETE_COURSE_CATEGORY: {
      return {
        ...state,
        requesting: action.payload,
      };
    }
    case types.GET_COURSE_LIST: {
      return {
        ...state,
        courseList: action.payload,
      };
    }
    case types.GET_FILTERED_COURSE_LIST: {
      return {
        ...state,
        filteredCourseList: state.courseList.filter(
          (course) => course.Status === action.payload
        ),
      };
    }
    case types.GET_COURSE_RESULTS_BY_COURSE_ID: {
      return {
        ...state,
        courseResultSummary: action.payload?.CourseResultSummary,
        courseResultDetail: action.payload?.CourseResultDetail,
        requesting: action.requesting,
      };
    }
    case types.GET_COURSE_RESULT_DETAIL_BY_COURSE_ID: {
      return {
        ...state,
        courseResultStat: action.payload?.CourseResultStat,
        coursePageQuestions: action.payload?.CoursePage,
        requesting: action.requesting,
      };
    }
    case types.GET_RESULT_SUMMARY_DETAIL: {
      return {
        ...state,
        resultSummaryDetail: action.payload?.CoursePage,
        requesting: action.requesting,
      };
    }
    case types.GET_COURSE_PAGES: {
      return {
        ...state,
        coursePages: action.payload,
      };
    }
    case types.GET_COURSE_PAGE: {
      return {
        ...state,
        coursePage: action.payload,
      };
    }
    case types.ADD_COURSE_PAGE: {
      return {
        ...state,
        coursePages: [action.payload, ...state.coursePages],
      };
    }
    case types.Update_COURSE: {
      return {
        ...state,
        course: action.payload,
      };
    }
    case types.ASSIGN_COURSES: {
      return {
        ...state,
        assignCourses: action.payload,
      };
    }
    case types.UPDATE_COURSE_PAGE: {
      return {
        ...state,
        coursePages: state.coursePages.map((item) => {
          if (item.Id === action.payload.Id) {
            return action.payload;
          }
          return item;
        }),
        coursePage: action.payload,
      };
    }
    case types.DELETE_COURSE_PAGE: {
      return {
        ...state,
        coursePages: [
          ...state.coursePages.filter((item) => item.Id !== action.payload),
        ],
      };
    }
    case types.GET_COURSE_CONTENT: {
      return {
        ...state,
        courseContent: action.payload,
      };
    }
    case types.ADD_COURSE_CONTENT: {
      return {
        ...state,
        courseContent: [...state.courseContent, ...action.payload],
      };
    }
    case types.UPDATE_COURSE_ORDER: {
      return {
        ...state,
        courseContent: [...action.payload],
      };
    }
    case types.GET_ASSIGNED_COURSE: {
      return {
        ...state,
        assignCourses: action.payload,
      };
    }
    case types.UPDATE_COURSE_CONTENT: {
      return {
        ...state,
        courseContent: state.courseContent.map((item) => {
          const newItem = action.payload.find((pay) => item.Id === pay.Id);
          if (newItem) {
            return newItem;
          }
          return item;
        }),
      };
    }
    case types.DELETE_COURSE_CONTENT: {
      return {
        ...state,
        courseContent: state.courseContent.filter(
          (item) => item.Id !== action.payload
        ),
      };
    }
    case types.HANDLE_CHANGE_FILTER_VALUE:
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          [action.payload.name]: action.payload.value,
        },
      };
    case types.CLEAR_STATE:
      return {
        state: {
          ...state,
          courseResultDetail: [],
        },
      };
    case types.CLEAR_COURSE:
      return {
        ...state,
        course: {},
      };
    default:
      return state;
  }
}
