import {
  SelectorTextBlockPageData,
  TextBlockReviewDetails,
} from "@rigotech/hrms-data/src/ts";

export const selectTextBlockPageData =
  (section: any) =>
  (state: any): SelectorTextBlockPageData => {
    const { PerformanceFormId, SectionName, IntroText, Contents } = section;

    const reviewDetails: TextBlockReviewDetails[] = Contents.map(
      ({ TextBlock }: any) => {
        return {
          TextBlock,
        };
      },
    );

    // =======================
    // DERIVE COMMENTS
    // =======================
    // let Comments: any = mapComments(SectionId, ContentId, state, ReviewInfo);

    // =======================
    // END DERIVE COMMENTS
    // =======================
    const mapped: SelectorTextBlockPageData = {
      Id: null, // THIS IS REVIEW ID
      PerformanceFormId,
      PerformanceRolloutId: 0,
      reviewDetails,
      signature: false,

      SectionName,
      IntroText,
      Comments: [],
    };

    return mapped;
  };
