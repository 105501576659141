import * as fromSlice from "./slice";

const selectCurrentGoalDatabaseSlice = (state: any): fromSlice.GoalDatabaseState => {
    return state.dataStore.goalDatabase;
};

export const selectGoalDatabaseItems = (state: any): any[] => {
    return selectCurrentGoalDatabaseSlice(state).items || [];
};

export const selectGoalDatabaseStatus = (state: any): boolean => {
    return selectCurrentGoalDatabaseSlice(state).loaded;
};

export const selectGoalDatabaseLoaded = (state: any): boolean => {
    return selectCurrentGoalDatabaseSlice(state).loaded;
};