
import * as fromSlice from "./slice";
import * as fromInterface from "../../../ts";
import { ApiClient } from "../../../network/apiClient";
import { rolloutFetchEmployeeList } from "./api";
import * as fromPerformanceRolloutEmployeeFilterStore from "./../performance-rollout-employee-filter"
import * as fromPerformanceRolloutAppraisalStore from "../performance-rollout-appraisal"

export const fetchPerformanceRolloutEmployeeList = () => async (dispatch: any, getState: any) => {
    dispatch(fromSlice.fetchRequest())

    const filters = fromPerformanceRolloutEmployeeFilterStore.selectPerformanceRolloutEmployeeFilter(getState())
    const mappedParams = {
        ...filters,
        reviewCycleId: filters.reviewCycleId?.value,
        branchId: filters.branchId?.value,
        departmentId: filters.departmentId?.value,
    }


    const response: fromInterface.ResponseWrapper<any> = await ApiClient.post<any>({
        endpoint: rolloutFetchEmployeeList,
        body: mappedParams,
        dispatch: dispatch
    })

    if (response?.Status) {
        dispatch(fromSlice.fetchSuccess(response?.Data))
        dispatch(fromPerformanceRolloutAppraisalStore.resetPerformanceRolloutAppraisalEmployeeIds())

    }

    return response
}