//enum contain featureName with menuId
export enum MenuEnum {
  CoreHR = 1,
  LeaveAndTime = 2,
  Payroll = 3,
  TaskManagement = 4,
  Recruitment = 5,
  TravelManagement = 6,
  Training = 7,
  ShiftRoster = 8,
  LMS = 9,
  PerfomanceAppraisal = 10,
  Settings = 11,
}

//may be required for reference
// [
//     {
//       "Id": 1,
//       "Name": "Human Resources"
//     },
//     {
//       "Id": 2,
//       "Name": "Leave & Time"
//     },
//     {
//       "Id": 3,
//       "Name": "Payroll"
//     },
//     {
//       "Id": 4,
//       "Name": "Tasks"
//     },
//     {
//       "Id": 5,
//       "Name": "Recruitment"
//     },
//     {
//       "Id": 6,
//       "Name": "Travel"
//     },
//     {
//       "Id": 7,
//       "Name": "Training"
//     },
//     {
//       "Id": 8,
//       "Name": "Shift Schedule"
//     },
//     {
//       "Id": 9,
//       "Name": "Learning"
//     },
//     {
//       "Id": 10,
//       "Name": "Performance"
//     },
//     {
//       "Id": 11,
//       "Name": "Settings"
//     }
//   ]
