import React from 'react';

export const RigoSwitchContext = React.createContext<any>({
  label: 'Sample label',
  name: '',
  control: undefined,
  errors: undefined,
  required: false,
  rule: undefined,
  value: '',
  onChange: undefined,
});
RigoSwitchContext.displayName = 'RigoSwitchContext';
