
import * as fromSlice from "./slice";
import * as fromInterface from "../../../ts";

const selectCurrentSlice = (
    state: any
): fromSlice.ShiftDashboardState => {
    return state.dataStore.shiftDashboardSlice
}


export const selectTodayShift = (state: any): any => {
    return selectCurrentSlice(state)?.todayShift;
};

export const selectTomorrowShift = (state: any): any => {
    return selectCurrentSlice(state)?.tomorrowShift;
};

export const selectPendingShift = (state: any): any => {
    return selectCurrentSlice(state)?.pendingShift;
};

export const selectPendingShiftPageInfo = (state: any): any => {
    const pendingShift = selectCurrentSlice(state)?.pendingShift
    return { pageIndex: pendingShift.pageIndex ?? 1, pageSize: pendingShift.pageSize ?? 20 }
};