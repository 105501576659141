import React from "react";
import { RadioGroup, Stack, Box } from "@chakra-ui/react";

import { Radio } from "@chakra-ui/react";

export const RigoRadioGroup: React.FunctionComponent<any> = (props) => {
  let {
    name,
    error,
    value,
    options,
    direction,
    onChange2,
    onChangeRHF,
    inputName,
    spacing,
    additionalActiveComponent,
    ...rest
  } = props;

  const handleChange = (value: string) => {
    if (options) {
      let completeValue = options.find((f: any) => f.value == value);
      onChange2 && onChange2(name, completeValue);
      onChangeRHF && onChangeRHF(completeValue);
    }
  };

  return (
    <RadioGroup
      {...rest}
      value={value && (value.value || value)}
      onChange={handleChange}
    >
      <Stack direction={direction || "row"}>
        {options &&
          options.map((item: any, index: number) => (
            <React.Fragment key={index}>
              <Box>
                <Radio colorScheme="blue" key={index} value={item.value}>
                  {item.label}
                </Radio>
                <span style={{ marginRight: "2rem" }} />
              </Box>
              <Box paddingLeft="25px" mt="0" width="100%">
                {additionalActiveComponent &&
                  value === item.value &&
                  additionalActiveComponent}
              </Box>
            </React.Fragment>
          ))}
      </Stack>
    </RadioGroup>
  );
};

export default RigoRadioGroup;
