// import * as fromReviewCycleStore from "../review-cycle";
import * as fromRatingScaleStore from "../raitng-scale";
import * as fromNewPerformanceSelectedFormOptinoStore from "../new-performance-selected-form-options";
import * as fromReviewCycleDropdownStore from "../review-cycle-dropdown";
import * as fromSectionStore from "../section";
import * as fromPerformanceFormStore from "../performance-form"
import isEmpty from "lodash/isEmpty"
// pass form id
export const fetchNewPerformanceReviewFormPageData = () => async (dispatch: any, getState: any) => {
    dispatch(fromReviewCycleDropdownStore.fetchReviewCycleDropDown())
    dispatch(fromRatingScaleStore.fetchRatingScale())
}

export const clearNewPerformanceReviewFormPageData = () => (dispatch: any) => {
    dispatch(fromNewPerformanceSelectedFormOptinoStore.clearSelectedNewPerformanceSelectedFormOption())
    dispatch(fromReviewCycleDropdownStore.clearReviewCycleDropdown())
    dispatch(fromRatingScaleStore.clearRatingScale())
    dispatch(fromSectionStore.clearSections())
}