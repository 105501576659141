import { selectReviewForSelf, selectReview } from ".";
import dayjs from 'dayjs'
import * as fromDataStore from "@rigotech/hrms-data"

export const selectEvaluationPageData = (state: any): any => {
    const review = selectReview(state);
    const selfReview = selectReviewForSelf(state);

    const evaluation = selectReview(state)?.Evaluation;

    if (evaluation) {

        return {
            ...evaluation,
            Comments: evaluation.Comments,
            SignatureMessage: review?.ReviewInfo?.SignatureMessage,
            selfMarking: {
                IsSignatureRequired: review?.ReviewInfo?.IsSignatureRequired,
                SignatureMessage: review?.ReviewInfo?.SignatureMessage,
                Signature: review?.ReviewInfo?.Signature
            }
        }
    }

    return {
        Comments: [],
        SignatureMessage: review?.ReviewInfo?.SignatureMessage,
        Signature: review?.ReviewInfo?.Signature,
        selfMarking: {
            IsSignatureRequired: review?.ReviewInfo?.IsSignatureRequired,
            SignatureMessage: review?.ReviewInfo?.SignatureMessage,
            Signature: review?.ReviewInfo?.Signature
        }
    };
};


export const EXECUTION_PLAN_RESPONSIBILITY = [
    {
        label: "Direct Manager",
        value: "Direct Manager",
    },
    {
        label: "Human Resources Department",
        value: "Human Resources Department",
    },
    {
        label: "Department Head",
        value: "Department Head",
    },
    {
        label: "Branch Head",
        value: "Branch Head",
    },
    {
        label: "Senior Management",
        value: "Senior Management",
    },
    // {
    //     label:"Other (Please Specify)",
    //     value:"Other (Please Specify)",
    // }
]


export const selectOverallPageData = (state: any): any => {
    const review = selectReview(state);

    const evaluation = selectReview(state)?.Evaluation;
    const DevelopmentArea = selectReview(state)?.DevelopmentArea;

    // DEFAULT VALUES
    let evaluationMapped: any = {

        Comments: [],
        SignatureMessage: review?.ReviewInfo?.SignatureMessage,
        Signature: review?.ReviewInfo?.Signature,
        selfMarking: {
            IsSignatureRequired: review?.ReviewInfo?.IsSignatureRequired,
            SignatureMessage: review?.ReviewInfo?.SignatureMessage,
            Signature: review?.ReviewInfo?.Signature
        }
    };

    let developmentAreaMapped: any = {
        DevelopmentAreas: [],
        Description: "",
        Justification: "",
        TargetDate: "",
        ResponsibleForExecutingPlans: [],
    };

    // OVERRIDE DEFAULTS AND TRANSFORM TO FORM VALUES
    if (evaluation) {
        evaluationMapped = {
            ...evaluation,
            Comments: evaluation.Comments,
            SignatureMessage: review?.ReviewInfo?.SignatureMessage,
            selfMarking: {
                IsSignatureRequired: review?.ReviewInfo?.IsSignatureRequired,
                SignatureMessage: review?.ReviewInfo?.SignatureMessage,
                Signature: review?.ReviewInfo?.Signature
            }
        }
    }



    if (DevelopmentArea) {
        developmentAreaMapped = {
            DevelopmentAreas: DevelopmentArea?.DevelopmentAreas?.map(developmentArea => ({
                label: developmentArea.Name,
                value: developmentArea.Id
            })),
            Description: DevelopmentArea?.Description,
            Justification: DevelopmentArea?.Justification,
            TargetDate: dayjs(DevelopmentArea?.TargetDate).format("YYYY-MM-DD"),
            ResponsibleForExecutingPlans: DevelopmentArea?.ResponsibleForExecutingPlans?.map((plan: any) => {
                const found = EXECUTION_PLAN_RESPONSIBILITY.find(workingPlan => workingPlan.value === plan)
                return found
            }) || [],
        }
    }

    const shouldHaveDevelopmentPlan = review?.ReviewInfo?.IncludeEmployeeDevelopmentPlanSection && review?.ReviewInfo?.ReviewType === fromDataStore.AprReviewerType.Manager

    return {
        evaluation: evaluationMapped,
        ...(shouldHaveDevelopmentPlan && { developmentArea: developmentAreaMapped }),
    };
};

