import { z } from "zod";
import { performanceFormWeightsContentSchema } from "./performance-form-weight-content";

export const performanceFormWeightsSectionSchema = z.object({
  Id: z.number().optional(),
  Name: z.string().optional(),
  Weight: z.number().optional(),
  Contents: z.array(performanceFormWeightsContentSchema).optional(),
});

export type IPerformanceFormWeightsSection = z.infer<typeof performanceFormWeightsSectionSchema>;
