
import * as fromInterface from "../../../../ts";

export const createSteps = (reviewDetail: any): any => {
    const { ReviewInfo, Sections } = reviewDetail;
    const {
        TotalSteps,
        CurrentStep,
        DisplayEvaluation,
        DisplayScoreSummary,
        IsSignatureRequired
    } = ReviewInfo

    let stepLength = TotalSteps as number;
    let sectionIndexToReduce = 0;
    let _CurrentStep = CurrentStep > TotalSteps - 1 ? TotalSteps - 1 : CurrentStep

    if (DisplayScoreSummary) {
        sectionIndexToReduce = sectionIndexToReduce + 1
    }

    const newSteps: fromInterface.Step[] = Array.from({
        length: stepLength
    }).map((item: any, idx: number) => {

        const isSummary = DisplayScoreSummary && idx === 0;
        const isLastStep = idx === stepLength - 1;
        const isFirstStep = idx === 0;

        let sectionContentType = 100;
        let sectionId = 100;

        const isEvaluation = (DisplayEvaluation || IsSignatureRequired) && idx === stepLength - 1;
        if (isEvaluation) {
            sectionContentType = 101;
            sectionId = 101;
        }

        const isBlock = !isSummary && !isEvaluation;

        if (isBlock) {
            sectionContentType = Sections[idx - sectionIndexToReduce].SectionContentType
            sectionId = Sections[idx - sectionIndexToReduce].Id
        }

        const isActive = idx <= _CurrentStep
        const isDisabled = !isActive
        const isSelected = idx === _CurrentStep

        const mapped: fromInterface.Step = {
            displayNumber: idx + 1,
            index: idx,
            isBlock,
            isSummary,
            sectionContentType,
            sectionId,
            actualCurrentStep: _CurrentStep,
            isActive,
            isSelected: isSelected,
            isDisabled,
            isLastStep,
            isFirstStep,
            isEvaluation
        }

        return mapped
    })

    return {
        newSteps,
        currentStep: { ...newSteps[_CurrentStep] }
    }

}