import React, { Component } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";

import { isFeatureEnabledValue } from "helpers/checkPermissions";
import { IoChevronDown } from "react-icons/io5";
import isEmpty from "isEmpty";

import SvgIcons from "components/icons/svgIcons";

const SideBarLink = ({
  side,
  selectedIndex,
  index,
  name,
  title,
  icon,
  onSideClick,
  type,
  link,
  feature,
  featureId,
  children
}) => {
  const [fea, setFea] = React.useState("");

  React.useEffect(() => {
    if (!isEmpty(feature)) {
      setFea(feature);
    }
  }, [feature]);
  if (!isFeatureEnabledValue(fea)) {
    return null;
  }
  if (type === "nav") {
    return (
      <NavLink
        to={link}
        className={side ? "not-link" : ""}
        activeClassName="active-link"
      >
        <li
          name={title}
          onClick={() => onSideClick("")}
          className="sidebar-link__item"
        >

          <span className="sidebar-link__icon">
            {/* <img className="sidebar-icon" src={icon} alt="sliders-icon" /> */}
            <SvgIcons size="23px" name={icon} color="#85c6f5" />
          </span>
          {title}

        </li>
      </NavLink>
    );
  }
  return (
    <li className={selectedIndex === index ? "active-link" : ""}>
      <a
        name={title}
        onClick={() => onSideClick(index)}
        className="sidebar-link__item"
      >

        <span className="sidebar-link__icon">
          {/* <img className="sidebar-icon" src={icon} alt="side-icon" /> */}
          <SvgIcons size="23px" name={icon} color="#85c6f5" />
        </span>
        <span className="link-title"> {title}</span>

      </a>
      {children}
    </li>
  );
};

export default SideBarLink;
