export default {
    SCORECARD_LOADING:'SCORECARD_LOADING',
    GET_JOB_SCORECARD:'GET_JOB_SCORECARD',
    HIRINGSTAGES_LOADING:'HIRINGSTAGES_LOADING',
    GET_JOB_HIRING_STAGES:'GET_JOB_HIRING_STAGES',
    APPLICATIONFORM_LOADING:'APPLICATIONFORM_LOADING',
    GET_JOB_APPLICATION_FORM:'GET_JOB_APPLICATION_FORM',
    JOB_CATEGORY_LOADING:'JOB_CATEGORY_LOADING',
    GET_JOB_CATEGORY:'GET_JOB_CATEGORY',
    CREATE_AND_UPDATE_JOB:'CREATE_AND_UPDATE_JOB',
    GET_EMPLOYEE_WITH_PHOTOS:"GET_EMPLOYEE_WITH_PHOTOS",
    GET_JOB_NUMBER:"GET_JOB_NUMBER",
    JOB_NUMBER_LOADING:"JOB_NUMBER_LOADING",
    CREATE_AND_UPDATE_JOB_LOADING:"CREATE_AND_UPDATE_JOB_LOADING",
    UPDATE_TRELLO_CARDS:'UPDATE_TRELLO_CARDS',
    CREATE_AND_UPDATE_JOB_REQ:"CREATE_AND_UPDATE_JOB_REQ",
    GET_JOB_STEPS_SETTING:"GET_JOB_STEPS_SETTING"
}