// @ts-nocheck 
import React, {ReactNode, ReactElement, useEffect} from "react";
import {FieldValues, useForm, UseFormMethods} from "react-hook-form";
import {deepMap} from "react-children-utilities";
import {useSelector} from "react-redux";
// import { RootStateTypes } from "ts/reducer";

interface FormGroupProps {
  defaultValues?: any;
  children: any;
  onSubmit: any;
  method?: UseFormMethods | UseFormMethods<any>;
  refs?: any;
}

export default function FormGroup(props: FormGroupProps) {
  const {defaultValues, children, onSubmit, method, refs} = props;
  // const methods = useForm({ defaultValues });

  // const {erroList} = useSelector((state:RootStateTypes)=>state.validateReducer);
  let methods = useForm({defaultValues});
  if (method) {
    methods = method;
  }

  const {handleSubmit, errors, control, getValues, register, setError, setValue} =
    methods;

  useEffect(() => {
    if (setValue) {
      for (let k in defaultValues) {
        console.log(k, defaultValues[k]);
        setValue(k, defaultValues[k]);
      }
    }
  }, [defaultValues]);
  // useEffect(()=>{
  //     if(erroList && Array.isArray(erroList) && erroList.length > 0){
  //         erroList.map(item=>{
  //             Object.keys(item).map(key=>{
  //                 setError(key, {
  //                     type: "manual",
  //                     message:item[key]
  //                   })
  //             }
  //               )
  //         })
  //     }
  // },[erroList])
  const newChildren = deepMap(children, (child: ReactNode) => {
    if (child && (child as ReactElement).props.name) {
      return React.createElement((child as ReactElement).type, {
        ...{
          ...(child as ReactElement).props,
          register: register,
          setError,
          setValue,
          control: control,
          formError: (child as ReactElement).props.error
            ? (child as ReactElement).props.error
            : errors,
          key: (child as ReactElement).props.name,
        },
      });
    }
    if (child && typeof (child as ReactElement).type !== "string") {
      return React.createElement((child as ReactElement).type, {
        ...{
          ...(child as ReactElement).props,
          register: register,
          setError,
          setValue,
          control: control,
          formError: errors,
          getValues,
          key: (child as ReactElement).props.name,
        },
      });
    }
    return child;
  });
  return (
    <form ref={refs} onSubmit={handleSubmit(onSubmit)}>
      {newChildren}
    </form>
  );
}
