// @ts-nocheck 
const API_URL = "v1";
export const Apis = {
  common: {
    uploadFiles: `/${API_URL}/common/files/upload`,
    downloadFiles: `/${API_URL}/common/files/download`,
  },
  //travel
  //allowance expense
  AllowanceExpenseApi: `/${API_URL}/travel/allowance-expense`,
  GetAllowanceExpenseApi: `/${API_URL}/travel/allowance-expenses`,
  //
  //

  //JobProfile
  JobProfileApi: `/${API_URL}/jp/job-profiles`,
  JobProfileSearchApi: `/${API_URL}/jp/job-profiles/search`,
  //

  //JobProfile
  JobDescriptionApi: `/${API_URL}/jp/job-descriptions`,
  JobDescriptionSearchApi: `/${API_URL}/jp/job-descriptions/search`,
  //

  //Leave Request
  GetLeaveRequestTypeApi: `/${API_URL}/leave-time/leaveTypes`,
  GetLeaveRequestApprover: `/${API_URL}/leave-time/leave/approvals`,
  LeaveRequestApi: `/${API_URL}/leave-time/leave/requests`,
  GetTeamMemberLeaveDetails: `/${API_URL}/leave-time/leave/team-members`,
  GetLeaveRequestDays: `/${API_URL}/leave-time/countLeaveDays`,
  //

  //Employee Requests
  GetEmployeeRequestsCount: `/${API_URL}/ltoa/count/requests`,
  GetEmployeeReviewRequest: `/${API_URL}/ltoa/ltOa/requests`,
  GetMyRequests: `/${API_URL}/ltoa/my-requests`,
  GetTeamRequestHistory: `/${API_URL}/ltoa/approved/requests`,
  GetMyRequestHistory: `/${API_URL}/ltoa/approved/my-requests`,
  CancelMyRequests: `/${API_URL}/ltoa/cancel/requests`,
  RejectReviewRequests: `/${API_URL}/ltoa/reject/requests`,
  ApproveReviewRequests: `/${API_URL}/ltoa/approve/requests`,
  SaveReviewRequestComment: `/${API_URL}/ltoa/comments`,
  GetAuditLogOfRequests: `/${API_URL}/ltoa/audit/log`,
  GetEmployeeListToAssignRequest: `/${API_URL}/ltoa/employees`,

  //
};
