// @ts-nocheck
import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import { Provider } from "react-redux";
import { ConfirmationProvider } from "@rigotech/ui";
import { AppTheme } from "../themes";
interface AppProviderProps {
  children: any
  store: any
}

const AppProvider = (props: AppProviderProps) => {
  const { children, store } = props
  // return <Provider store={store}>{children}</Provider>
  return (
    <ChakraProvider theme={AppTheme}>
      <ConfirmationProvider>
        <Provider store={store}>{children}</Provider>
      </ConfirmationProvider>
    </ChakraProvider>
  )
}

export default AppProvider
