import { selectReviewForSelf, selectCurrentStep, selectReview } from ".";
import * as fromPerformanceReviewCommentStore from "../../performance-review-comments";
import { mapComments } from "../helper/map-comments";

export const selectMatrixRatingPageData = (state: any): any => {
    const currentStep = selectCurrentStep(state);
    const review = selectReview(state);
    const selfReview = selectReviewForSelf(state);

    const { ReviewInfo } = review;

    const section = review.Sections?.find((section: any) => {
        return section.Id === currentStep.sectionId
    })

    const sectionIdx = review.Sections?.findIndex((section: any) => {
        return section.Id === currentStep.sectionId
    })

    if (section) {
        const {
            PerformanceFormId,
            SectionName,
            IntroText,
            RatingScaleOptions,
            Contents
        } = section

        const contentIdx = 0;
        const reviewDetails = Contents.map(content => {
            return content.Options.map(({
                Id,
                Name,
                SectionId,
                ContentId,
                RatingScaleOptionId,
            }: any, optionIdx) => {

                return {
                    sectionId: SectionId,
                    sectionContentId: ContentId,
                    sectionContentOptionId: Id,
                    RatingScaleOptionId: RatingScaleOptionId,
                    selfMarkingValue: selfReview?.Sections?.[sectionIdx as number].Contents[contentIdx].Options[optionIdx].RatingScore ?? 0,

                    // REQUIRED TO DISPLAY
                    Name
                }
            })
        })


        // =======================
        // DERIVE COMMENTS
        // =======================
        const { SectionId, Id: ContentId } = Contents[contentIdx];
        let Comments: any = mapComments(SectionId, ContentId, state, ReviewInfo);
        // =======================
        // END DERIVE COMMENTS
        // =======================

        const mapped = {
            Id: null, // THIS IS REVIEW ID
            PerformanceFormId,
            PerformanceRolloutId: ReviewInfo?.PerformanceRolloutId,
            reviewDetails,
            Comments,
            signature: false,

            // REQUIRED TO DISPLAY
            SectionName,
            IntroText,
            RatingScaleOptions,
            AllowComments: Contents[contentIdx].AllowComments
        }

        return mapped
    }

    return null;
};
