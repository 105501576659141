// @ts-nocheck 
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Formgroup from 'components/formGroup'
import FormItem from 'components/formGroup/formItem';
import "./reset.styles.scss";
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import Button from 'components/button';
import { changeOldHashPassword } from 'actions/authAction';


function checkPasswordSequential(s) {
    // Check for sequential numerical characters
    for(var i in s) 
        if (+s[+i+1] == +s[i]+1 && 
            +s[+i+2] == +s[i]+2) return false;
    // Check for sequential alphabetical characters
    for(var i in s) 
        if (String.fromCharCode(s.charCodeAt(i)+1) == s[+i+1] && 
            String.fromCharCode(s.charCodeAt(i)+2) == s[+i+2]) return false;
    return true;
}

const checkInclude = (password,user) => {
    let doesInclude = true;
    if(user){   
        if(user?.UserName?.includes(password)){
            doesInclude = false;
        }
        if(password.includes(user?.UserName)){
            doesInclude = false;
        }
        if(user?.EmployeeName?.includes(password)){
            doesInclude = false;
        }
        if(password.includes(user?.EmployeeName)){
            doesInclude = false;
        }
    }
    return doesInclude;
}

function ResetOldHash(props) {
    const {user} = useSelector((state:any)=>state.auth);
    const dispatch = useDispatch();
    const methods = useForm();
    const {watch} = methods;
    const passwordVal = watch("newPassword");
    const confirmPasswordVal = watch("confirmNewPassword");
    const isPassSeq = passwordVal && checkPasswordSequential(passwordVal)
    const isPasswordLenght = passwordVal && passwordVal.length>= 8;
    const isNameInclude =  passwordVal && passwordVal.length>= 2 && checkInclude(passwordVal,user);
    const isMatch = confirmPasswordVal && passwordVal && confirmPasswordVal === passwordVal
    const onSubmit = (data) => {
        if(isPassSeq && isPasswordLenght && isNameInclude && isMatch){
            dispatch(changeOldHashPassword(data,props.history))
        }
    }
    return (
        <div className="reset-old">
            <div className="reset-old__container">
                <div className="reset-old__heading mb-md">
                    Welcome to the Next Version of Rigo HRMS
                </div>
                <div className="reset-old__para">
                    <span>Dear {user?.UserName}</span>
                </div>
                <div className="reset-old__para">
                    <span>
                        We are releasing Next Version of Rigo HRMS software. During next few months, you will receive many new features and upgrades to your account.
                    </span>
                </div>
                <div className="reset-old__para">
                    <span>
                        As a first step towards the upgrade, we have made your account more secure by upgrading the security architecture of your software
                    </span>
                </div>
                <div className="reset-old__strong">
                    Please change your password and get ready for the Next Version of Rigo HRMS.
                </div>
                <div className="reset-old__form">
                    <Formgroup
                        method={methods}
                        onSubmit = {onSubmit}
                    >
                        {/* <FormItem 
                            name="currentPassword"
                            type="password"
                            label="Type current password"
                            width="300px"
                        /> */}
                        <FormItem 
                            name="newPassword"
                            type="password"
                            label="Type a new password"
                            width="300px"
                        />
                        <FormItem 
                            name="confirmNewPassword"
                            type="password"
                            label="Confirm the password you typed above"
                            width="300px"
                        />
                        <div className="reset-old__para">
                            <span>
                                Please make sure your password complies with these password guidelines
                            </span>
                            <ul className="reset-old__validations">
                                <li className={classnames("reset-old__validation",{"active":isPasswordLenght})}>
                                    Your password should have at least 8 characters
                                </li>
                                <li className={classnames("reset-old__validation",{"active":isPassSeq})}>
                                    Do not use sequential characters like 1234, aaa, abcd
                                </li>
                                <li className={classnames("reset-old__validation",{"active":isNameInclude})}>
                                    Do not include your username, name or surname in the password
                                </li>
                                <li className={classnames("reset-old__validation",{"active":isMatch})}>
                                    Password and Confirm password should match
                                </li>
                            </ul>
                        </div>
                        <div className="reset-old__submit">
                            <Button
                                bg="primary"
                                htmlButtonType="submit"
                            >
                                <span>Change Password</span>
                            </Button>
                        </div>
                    </Formgroup>
                </div>
            </div>
        </div>
    )
}

export default ResetOldHash
