// @ts-nocheck
import React from "react";
import {
  RouteEnum,
  featuresEnum,
  permissions as permissionsEnum,
} from "@rigotech/hrms-data";

//CHILDREN
import links from "./links";

//COMPONENTS
const DirectManagers = React.lazy(
  () => import("@rigotech/hrms-roles/src/directManagers")
);
const RolesPermission = React.lazy(() => import("@rigotech/hrms-roles/src"))

const roles = (
  features: any,// typeof featuresEnum,
  permissions?: typeof permissionsEnum
) => [
    {
      path: RouteEnum.ManagersLinks,
      exact: true,
      component: DirectManagers,
      title: "Direct Managers",
      feature: features.CoreHR,
      // permission: permissions.standardPermission.hrManagement,
      // viewOnlyPermission: permissions.viewOnlyPermission.hrDisplayName,
      doCheckViewOnly: true,
    },
    //CHILDREN LINK ALWAYS ON LAST
    // {
    //   path: RouteEnum.default,
    //   component: RolesPermission,
    //   children: links,
    //   feature: features.CoreHR,
    //   // permission: permissions.configurationPermission.userRoles,
    //   doCheckViewOnly: true,
    // },
  ];
export default roles;
