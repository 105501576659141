// @ts-nocheck 
import dayjs from "dayjs";
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

export enum Dates {
  week = 1,
  month = 2,
}

export const dateSelectOptions = [
  {label: "Weekly", value: Dates.week},
  {label: "Monthly", value: Dates.month, disabled: true},
];

export const getDateRange = (date, dateIndex = 0) => {
  const isWeek = date.value === Dates.week;
  const isMonth = date.value === Dates.month;
  const isToday = dateIndex === 0;
  var today = new Date(); // get current date
  var curr = isToday
    ? today
    : new Date(
        today.setDate(today.getDate() - (isWeek ? 7 * dateIndex : 30 * dateIndex))
      );
  var first = curr.getDate() - curr.getDay();
  var last = first + 6;
  var firstDayOfMonth = new Date(curr.getFullYear(), curr.getMonth(), 1);
  var lastDayOfMonth = new Date(curr.getFullYear(), curr.getMonth() + 1, 0);

  if (isWeek) {
    var firstDayOfWeek = dayjs(dayjs(curr).startOf("week").toDate()).format("YYYY-MM-DD");
    var lastDayOfWeek = dayjs(dayjs(curr).endOf("week").toDate()).format("YYYY-MM-DD");
    return {
      startDate: firstDayOfWeek,
      endDate: lastDayOfWeek,
    };
  }

  if (isMonth) {
    var startDate = dayjs(firstDayOfMonth).format("YYYY-MM-DD");
    var endDate = dayjs(lastDayOfMonth).format("YYYY-MM-DD");
    return {
      startDate: startDate,
      endDate: endDate,
    };
  }
};

export const getDatesInBetween = (startDate, endDate, scheduleList, employeeList) => {
  var dateArray = [];
  var currentDate = dayjs(startDate);
  var lastDate = dayjs(endDate);
  while (currentDate <= lastDate) {
    var commonDates =
      scheduleList &&
      scheduleList.filter((item) => {
        return (
          dayjs(item?.ScheduleDate).format("YYYY-MM-DD") ==
          currentDate.format("YYYY-MM-DD")
        );
      });
    let b = {};
    let isEmpError = false;

    let isLeaveError = false;
    let isWorkOffError = false;
    let isPositionError = false;
    let isEmptyEmployee = false;
    let workOffNo = 0;
    let commonTimes =
      commonDates &&
      commonDates.map((item) => {
        const time = item.BeginsAt + " - " + item?.EndsAt;
        const commonEmp = {};
        const employees =
          employeeList &&
          employeeList.filter((emp) => emp.ShiftScheduleId === item.ShiftScheduleId);

        employees &&
          employees.map((fin) => {
            const role = fin?.TeamRole;
            if (!commonEmp[role]) {
              commonEmp[role] = [fin];
            } else {
              commonEmp[role] = [...commonEmp[role], fin];
            }
          });
        const empLen = employees.length;
        const empErrors =
          employees && employees.length > 0
            ? employees.filter((item) => !item?.IsWorkDay)
            : [];
        const onLeaveError =
          employees && employees.length > 0
            ? employees.filter((item) => item?.IsOnLeave)
            : [];
        const remainingPositions = item?.Positions - empLen;

        if (!isLeaveError) {
          isLeaveError = onLeaveError.length > 0;
        }
        if (!isWorkOffError) {
          isWorkOffError = empErrors.length > 0;
        }
        if (!isPositionError) {
          isPositionError = remainingPositions > 0;
        }
        if (!isEmpError) {
          isEmpError = isWorkOffError || isPositionError;
        }
        if (!isEmptyEmployee) {
          isEmptyEmployee = employees.length == 0;
        }
        workOffNo += empErrors.length;
        const final = {
          ...item,
          employeeNum: empLen,
          remainingPosition: remainingPositions,
          totalPositions: item?.Positions,
          positionsError: empErrors.length,
          isLeaveError: onLeaveError.length > 0,
          isWorkOffError: empErrors.length > 0,
          isPositionError: remainingPositions > 0,
          isEmptyEmployee,
          isEmpError: empErrors.length > 0 || remainingPositions > 0,
          employees: commonEmp,
          allEmployees: employees,
          dateWithDay: dayjs(item?.ScheduleDate).format("dddd - D MMMM"),
        };
        if (!b[time]) {
          b[time] = [final];
        } else {
          if (b[time]?.BeginsAt === item?.BeginsAt) {
            b[time] = [...b[time], final];
          }
        }
      });
    dateArray.push({
      date: dayjs(currentDate).format("YYYY-MM-DD"),
      dateAlt: dayjs(currentDate).format("ll"),
      dateShort: dayjs(currentDate).format("DD MMM"),
      dateFull: dayjs(currentDate).format("dddd LL"),
      dateWeek: dayjs(currentDate).format("ddd D"),
      dayMonthWeek: dayjs(currentDate).format("D-MMM, dddd"),
      data: commonDates,
      data2: b,
      isEmpError,
      isLeaveError,
      isWorkOffError,
      isPositionError,
      isEmptyEmployee,
      workOffNo,
    });
    currentDate = dayjs(currentDate).add(1, "day");
  }
  return dateArray;
};
