import * as fromSlice from "./slice"
import * as fromInterface from "../../../ts"
import { ApiClient } from "../../../network/apiClient"
import { PARTICIPANT_SCORE_WEIGHT, PARTICIPANT_SCORE_WEIGHT_LIST } from "./api"

export const clearParticipantWeightsScore =
  (): any => async (dispatch: any) => {
    dispatch(fromSlice.clear())
  }

export const fetchParticipantWeights = () => async (dispatch: any) => {
  dispatch(fromSlice.fetchRequest())

  const response: fromInterface.ResponseWrapper<any> = await ApiClient.get<any>(
    {
      endpoint: PARTICIPANT_SCORE_WEIGHT_LIST,
      dispatch: dispatch,
    },
  )
  const { Data, Status } = response

  if (Status) {
    dispatch(fromSlice.fetchSuccess(Data))
  }
}

export const saveParticipantWeight =
  (data?: any): any =>
  async (dispatch: any) => {
    dispatch(fromSlice.fetchRequest())
    const param = {
      endpoint: PARTICIPANT_SCORE_WEIGHT,
      body: data,
      dispatch: dispatch,
    }
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.post<any>(param)
    const { Status } = response

    if (Status) {
      dispatch(fetchParticipantWeights())
    }
    dispatch(fromSlice.fetchComplete())
    return Status
  }
