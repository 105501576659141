import * as fromInterface from './../../../ts';
import { GenericState } from '../../@base-store';

const selectSlice = (state: any): GenericState<any, any> => {
  return state.dataStore.singleEmployee;
};

export const selectItems = (state: any): any[] | null => {
  return selectSlice(state).items;
};

export const selectStatus = (state: any): fromInterface.loadingType => {
  return selectSlice(state).status;
};

export const selectLoaded = (state: any): boolean => {
  return selectSlice(state).loaded;
};
