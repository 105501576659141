import * as fromInterface from "../../../ts";
import { PerformanceRolloutAppraisalListState } from "./slice";

const selectPerformanceRolloutAppraisalListSlice = (state: any): PerformanceRolloutAppraisalListState => {
    return state.dataStore.performanceRolloutAppraisalList;
};


export const selectPerformanceRolloutAppraisalListItems = (state: any) => {
    return selectPerformanceRolloutAppraisalListSlice(state).items;
};


export const selectPerformanceRolloutAppraisalListStatus = (state: any): fromInterface.loadingType => {
    return selectPerformanceRolloutAppraisalListSlice(state).status;
};

export const selectPerformanceRolloutAppraisalListLoaded = (state: any): boolean => {
    return selectPerformanceRolloutAppraisalListSlice(state).loaded;
};


