const changeRequestPrefix = 'employee-record/change-request';

export const PERSONAL_INFO_EMPLOYEE_DETAILS =
  'employee-record/employees/information';
export const PERSONAL_INFO_PENDING_REQUESTS = `${changeRequestPrefix}/pending-requests`;
export const PERSONAL_INFO_POST_EMPLOYEE_PERSONAL_INFO = `${changeRequestPrefix}/personal-detail`;
export const PERSONAL_INFO_HOME_CONTACT = `${changeRequestPrefix}/home-contact`;
export const PERSONAL_INFO_OFFICE_CONTACT = `${changeRequestPrefix}/office-contact`;
export const PERSONAL_INFO_PERMANENT_ADDRESS = `${changeRequestPrefix}/permanent-address`;
export const PERSONAL_INFO_CURRENT_ADDRESS = `${changeRequestPrefix}/current-address`;
export const FAMILY_CHANGE_REQUEST = `${changeRequestPrefix}/family`;
