
import * as fromInterface from "../../../../ts";
import * as fromSlice from "../slice";
import * as fromSelector from "../selectors";
import { createNextState } from "@reduxjs/toolkit";


export const setStep = (currentStep: number): any => (dispatch: any, getState: any) => {

    let _CurrentStep = currentStep
    const state = getState()

    const steps = fromSelector.selectSteps(state)

    const foundStep = steps[_CurrentStep]

    let maxStep = foundStep?.actualCurrentStep ?? 0// currentStep;

    if (_CurrentStep > steps?.length) {
        _CurrentStep = steps?.length - 1
    }

    if (_CurrentStep > maxStep) {
        maxStep = _CurrentStep
    }

    const nextStep = createNextState(steps, draftState => {
        draftState.forEach((step, idx) => {
            step.actualCurrentStep = maxStep
            step.isSelected = _CurrentStep === idx
            step.isActive = idx <= maxStep
        })
    })


    dispatch(fromSlice.setStep({ steps: nextStep, currentStep: nextStep[_CurrentStep] }))
}