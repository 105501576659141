import {
    GET_PAYROLL_DASHBOARD,
} from 'actions/types';

const initialState = {
    payrollDashboard: []
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case GET_PAYROLL_DASHBOARD:
        return {
          ...state,
          payrollDashboard: action.payload
        };
  
      default:
        return state;
    }
  }
  