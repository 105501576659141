import {
  HiChevronLeft,
  HiChevronDoubleLeft,
  HiChevronRight,
  HiChevronDoubleRight,
} from "react-icons/hi";

export const PAGINATION_CASES = {
  initialPage: 0,
  previousPage: 1,
  nextPage: 2,
  finalPage: 3,
  gotoPageIndex: 4,
  gotoPageSize: 5,
};

export const PAGINATION_BUTTONS = [
  {
    id: 1,
    className: "step_down--initial",
    type: PAGINATION_CASES.initialPage,
    content: HiChevronDoubleLeft,
  },
  {
    id: 2,
    className: "step_down",
    type: PAGINATION_CASES.previousPage,
    content: HiChevronLeft,
  },
  {
    id: 3,
    className: "step_up",
    type: PAGINATION_CASES.nextPage,
    content: HiChevronRight,
  },
  {
    id: 4,
    className: "step_up--final",
    type: PAGINATION_CASES.finalPage,
    content: HiChevronDoubleRight,
  },
];


export const PADDING_SIZE = {
  X: {
    sm: "6px",
    md: "8px",
    lg: "10px",
  },
  Y: {
    sm: "8px",
    md: "10px",
    lg: "12px",
  },
};

export const FONT_SIZE = {
  sm: "13px",
  md: "14px",
  lg: "16px",
};
