// @ts-nocheck 
import {
  GET_APPLICANTS,
  GET_APPLICANTS_LOADING,
  GET_EXPORT_APPLICANTS,
} from "store/types";

const initialState = {
  applicants: [],
  exportApplicants: [],
  applicantsLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EXPORT_APPLICANTS:
      return {
        ...state,
        exportApplicants: action.payload,
        applicantsLoading: false,
      };

    case GET_APPLICANTS:
      return {
        ...state,
        applicants: action.payload,
        applicantsLoading: false,
      };

    case GET_APPLICANTS_LOADING:
      return {
        ...state,
        applicantsLoading: action.payload,
      };

    default:
      return state;
  }
}
