import {
  GET_JOB_DETAILS,
  IS_ACTIVE_JOBS_LOADING,
  GET_ACTIVE_JOBS,
  SET_SPINNER_LOADING
} from "actions/types";
const initialState = {
  loading: true,
  spinnerLoading: false,
  activeJobs: [],
  jobDetails: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ACTIVE_JOBS:
      return {
        ...state,
        activeJobs: action.payload
      };
    case GET_JOB_DETAILS:
      return {
        ...state,
        jobDetails: action.payload
      };
    case SET_SPINNER_LOADING:
      return {
        ...state,
        spinnerLoading: action.payload
      };
    default:
      return state;
  }
}
