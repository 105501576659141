// @ts-nocheck 
import React, { useState, useEffect } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import classnames from "classnames";
import isEmpty from "helpers/isEmpty";
import {basicInputInterface} from 'ts/interfaces/formInterface'

function DateInput(props: basicInputInterface) {
  const { name, placeholder, value, disabled } = props;

  const [open, setOpen] = useState(false);
  const [date, setDate] = useState("");

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    props.onChange && props.onChange(name, value);
  };
  
  const handleKeyUp = (event) => {
    const { name, value } = event.target;
    props.onKeyUp && props.onKeyUp(event);
  };
  const handleSetDate = (date: any) => {
    props.onChange && props.onChange(name, date);
  };

  return (
    <>
      <div className="genericForm-group__date">
        <input
          className="genericForm-group__date-customInput"
          type="date"
          id={name}
          name={name}
          onInput={(e) => handleChange(e)}
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          onKeyUp={handleKeyUp}
        />
      </div>
    </>
  );
}

DateInput.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  value: PropTypes.any.isRequired,
  error: PropTypes.any,
  width: PropTypes.string,
  label: PropTypes.string,
  handleBlur: PropTypes.func,
  onChange: PropTypes.func,
};

DateInput.defaultProps = {
  name: "",
  placeholder: "MM / DD / YYYY",
};

export default DateInput;
