import React, { useState, useRef, useEffect } from "react";
import isEmpty from "isEmpty";
import Avatar from "react-avatar";
import classnames from "classnames";
import Modal from "components/modal";
import { FaRegBell } from "react-icons/fa";
import RouteEnum from "constants/routeEnum";
import { getUser } from "actions/authAction";
import ChangePassword from "../changePassword";
import OnlyDev from "components/common/onlyDev";
import { AiOutlineSearch } from "react-icons/ai";
import SvgIcons from "components/icons/svgIcons";
import { Link, useHistory } from "react-router-dom";
import { MdKeyboardArrowDown } from "react-icons/md";
import { getUploadPath } from "constants/userDetails";
import { useDispatch, useSelector } from "react-redux";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { isFeatureEnabledValue } from "helpers/checkPermissions";
import useEventListener from "helpers/customHooks/useEventListener";
import { useNotificationHeader } from "services/common/notification";
import ProfilePictureChange from "components/layout/profilePictureChange";
import { ClearTravelCount } from "scenes/travel/common/travelInformation/actions";

import TimeFunction from "../../taskTracker/timeFunction";
import AttendanceShortcutButton from "../../employee/time/attendance/AttendanceShortcutButton";

function useOutsideAlerter(
  action = "mousedown",
  ref,
  setDrop,
  isSearch = false
) {
  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      setDrop(false);
    }
    if (isSearch && ref.current === event.target) {
      setDrop(false);
    }
  }

  function handleKeyUp(event) {
    if (event.key === "Escape") {
      setDrop(false);
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener(
      action,
      action === "keyup" ? handleKeyUp : handleClickOutside
    );
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener(
        action,
        action === "keyup" ? handleKeyUp : handleClickOutside
      );
    };
  });
}

export default function HeaderTop({
  companyInfo,
  informationalVersion,
  logoutUser,
  loginUser,
  totalRequestCount,
}) {
  const [drop, setDrop] = useState(false);
  const [search, setSearch] = useState(false);
  const wrapperRef = useRef(null);
  const searchRef = useRef(null);
  const dispatch = useDispatch();
  useOutsideAlerter("mousedown", wrapperRef, setDrop);
  useOutsideAlerter("mousedown", searchRef, setSearch, true);
  useOutsideAlerter("keyup", searchRef, setSearch, true);
  const userDetails = useSelector((state) => state.auth.user);
  const oauthEnabled = useSelector((state) => state.commonReducer.oauthEnabled);
  const onLogoutUser = async (e) => {
    e.stopPropagation();
    await logoutUser();
    dispatch(ClearTravelCount());
  };
  const searched = document.getElementById("search");
  if (search) {
    const auto = searched.autofocus;

    if (!auto) {
      setTimeout(() => {
        searched.focus();
      }, 500);
    }
  }
  const [modalOpen, setModalOpen] = useState(false);
  const modalClose = () => {
    setModalOpen(false);
  };
  const onSwitchUser = async () => {
    const data = {
      rigoId: userDetails.MapToUserName,
      password: "",
      strategy: "switchuser",
    };

    await loginUser(data, changeHistory, true);
  };

  const changeHistory = async () => {
    await dispatch(getUser());
  };

  let userDetail =
    localStorage.UserDetail && JSON.parse(localStorage.UserDetail);
  let userRole = userDetail ? userDetail.RoleName : null;

  return (
    <div className="header-top-container">
      <div className="wrap-container">
        <div
          ref={searchRef}
          className={`header-top ${search ? "header-top-open" : ""}`}
        >
          <div className="header-top-row">
            <div className="header-top__left">
              <div className="header-top-company company">
                <Link
                  to={RouteEnum.EmployeeDashBoard}
                  className="flex align-center"
                >
                  <Avatar
                    className="company-logo"
                    name={companyInfo && companyInfo.companyName}
                    size={45}
                    round={false}
                    src={companyInfo && companyInfo.logo}
                  />
                  <div className="company-name">
                    <h3 className="company-header-title">
                      {companyInfo && companyInfo.companyName}
                    </h3>
                  </div>
                </Link>
                <div className="header-timer">
                  {isFeatureEnabledValue("TaskTracker")
                    ? userRole === "Employee" && <TimeFunction />
                    : null}
                </div>
                <div className="header-timer">
                  {isFeatureEnabledValue("LeaveAndTime")
                    ? userRole === "Employee" && <AttendanceShortcutButton />
                    : null}
                </div>
              </div>
            </div>

            <div className="header-top__right">
              <div className="header-top-icons">
                {userRole !== "OnboardingEmployee" && (
                  <>
                    <OnlyDev>
                      <Notifications />
                    </OnlyDev>
                    <Link
                      to={{
                        pathname: RouteEnum.EmployeeRequestReviewPath,
                        state: {},
                      }}
                    >
                      <div
                        style={{ fontSize: 24 }}
                        className="header-top-icon with-badge"
                      >
                        <IoIosCheckmarkCircleOutline />
                        {!isEmpty(totalRequestCount) &&
                          totalRequestCount !== 0 && (
                            <span className="badge badge-danger sm">
                              {totalRequestCount}
                            </span>
                          )}
                      </div>
                    </Link>
                  </>
                )}
                <div
                  ref={wrapperRef}
                  onClick={() => setDrop(!drop)}
                  className={`header-top-profile profile profile-drop ${
                    drop ? "active" : ""
                  }`}
                >
                  <div className="profile-image">
                    {userDetails ? (
                      <Avatar
                        className="user-logo"
                        name={userDetails && userDetails.UserName}
                        round={true}
                        size={30}
                        src={getUploadPath(userDetails.UserImage)}
                      />
                    ) : null}
                  </div>
                  <div className="profile-name">
                    <span>
                      {userDetails && userDetails.EmployeeName
                        ? userDetails.EmployeeName
                        : userDetails.UserName}
                    </span>
                  </div>
                  <div className="profile-down-caret">
                    <MdKeyboardArrowDown />
                  </div>
                  <div className="profile-drop__content">
                    <div className="header-drop__image">
                      {userDetails ? (
                        <Avatar
                          className="companys-logo"
                          name={"User Image"}
                          size={80}
                          round={true}
                          src={
                            userDetails && userDetails.UserImage
                              ? getUploadPath(userDetails.UserImage)
                              : ""
                          }
                        />
                      ) : null}
                    </div>
                    <div className="header-drop__name">
                      <h2>
                        {userDetails && userDetails.EmployeeName
                          ? userDetails.EmployeeName
                          : userDetails.UserName}
                      </h2>
                      <p>
                        {userDetails && userDetails.Designation ? (
                          userDetails.Designation
                        ) : (
                          <span className="no-found">No Desgination</span>
                        )}
                      </p>
                      <div className="header-drop__change">
                        <ProfilePictureChange />
                      </div>
                    </div>

                    <ul className="header-drop-list">
                      {userDetails && userDetails.SwitchToAdminAllowed && (
                        <li
                          onClick={() => onSwitchUser()}
                          className="header-drop-list__item with-hr"
                        >
                          <span className="header-drop-icon">
                            <SvgIcons size="16px" name="repeat" />
                          </span>
                          <span className="header-drop-name">
                            View as Admin
                          </span>
                        </li>
                        // </Link>
                      )}
                      {!oauthEnabled && (
                        <li
                          onClick={() => setModalOpen({ modalOpen: true })}
                          className="header-drop-list__item with-hr"
                        >
                          <span className="header-drop-icon">
                            <SvgIcons size="16px" name="key" />
                          </span>
                          <span className="header-drop-name">
                            Change Password
                          </span>
                        </li>
                      )}
                      <li
                        onClick={(e) => onLogoutUser(e)}
                        className="header-drop-list__item with-hr"
                      >
                        <span className="header-drop-icon">
                          <SvgIcons size="16px" name="sign-out" />
                        </span>
                        <span className="header-drop-name">Sign Out</span>
                      </li>
                    </ul>
                    <div className="header-app-version">
                      Version: {informationalVersion}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header-top-search-row">
            <div className="search-container">
              <div className="header-search">
                <div className="header-search__input">
                  <input
                    type="search"
                    id="search"
                    placeholder="Search a function or employee"
                    autoFocus
                  />
                </div>
                <div className="header-search__icon">
                  <AiOutlineSearch />
                </div>
              </div>
            </div>
          </div>
          <Modal
            onModalClose={modalClose}
            open={modalOpen}
            className=""
            width="400px"
            isPRimary
            title={"Change Password"}
          >
            <ChangePassword onModalClose={modalClose} />
          </Modal>
        </div>
      </div>
    </div>
  );
}

const Notifications = () => {
  const notif = useRef(null);
  const [drop, setDrop] = useState(false);
  useEventListener("click", (e) => {
    if (notif.current && !notif.current.contains(e.target)) {
      setDrop(false);
    }
  });
  const history = useHistory();
  const { data, status, error, isFetching } = useNotificationHeader();
  const noticClass = classnames("header-top-icon", {
    isUnread: !isEmpty(data) && data.Data.IsAnyUnread,
  });
  return (
    <div ref={notif} className="header-top-notif">
      <div
        onClick={() => history.push(RouteEnum.NotificationsPath)}
        className={noticClass}
      >
        <FaRegBell />
      </div>
      {
        <div className={`notifications ${drop ? "notifications-open" : null}`}>
          <div className="notifications-container">
            <div className="notifications-header">
              <h3>Notifications</h3>
            </div>
            <div className="notifications-body">
              <ul className="notifications-list">
                <NotifItem />
                <NotifItem />
                <NotifItem />
                <NotifItem />
                <NotifItem />
              </ul>
            </div>
            <div className="notifications-footer">
              <Link to={RouteEnum.NotificationsPath}>
                <span>View All</span>
              </Link>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

const NotifItem = (props) => {
  return (
    <li className="notifications-list__item">
      <div className="notification">
        <div className="notification-title">Leave Request</div>
        <div className="notification-container">
          <div className="notification__image">
            <gl-emoji
              title="woman tone 1"
              data-name="woman_tone1"
              data-unicode-version="8.0"
            >
              👩🏻
            </gl-emoji>
          </div>
          <div className="notification-body">
            <h4 className="notification-body__header">
              Meeting and Field visit
            </h4>
            <p className="notification-body__content">
              Kaski, Pokhara requested for 3 days from August 13 to 16
            </p>
            <div className="notification-body__date">Aug 26, 2:48 PM</div>
          </div>
        </div>
      </div>
    </li>
  );
};
