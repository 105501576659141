import * as fromSlice from "./slice";

export const setSelectedNewPerformanceSelectedFormOption = (params: any) => async (dispatch: any, getState: any) => {
    dispatch(fromSlice.setSelected(params))
}

export const clearSelectedNewPerformanceSelectedFormOption = () => async (dispatch: any, getState: any) => {
    dispatch(fromSlice.clear())
}

