// import { ApiClient } from "@/network/apiClient";
import * as fromConstant from "../../../constants";
import * as fromSlice from "./slice";

export const fetchStatus = (page?: any) => async (dispatch: any, getState: any) => {
    dispatch(fromSlice.fetchRequest())
    dispatch(fromSlice.fetchSuccess(fromConstant.status))
};

export const clearStatus = () => (dispatch: any) => {
    dispatch(fromSlice.clear())
}