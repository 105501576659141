import React from 'react';
import { InputTextProps } from './interface';

export const RigoFroalaEditorContext = React.createContext<InputTextProps>({
  label: 'Sample label',
  name: '',
  control: undefined,
  errors: undefined,
  required: false,
  rule: undefined,
  value: '',
  onChange: undefined,
});
RigoFroalaEditorContext.displayName = 'RigoFroalaEditorContext';
