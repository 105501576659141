// @ts-nocheck
import { useDispatch } from "react-redux";
import { useConfirmation } from "@rigotech/ui";

import { resetCourse } from "../../helpers/action";

export const useReset = (Id, onStatusChange) => {
  const dispatch = useDispatch();
  const { openConfirm } = useConfirmation();

  const handleReset = () =>
    openConfirm({
      onConfirm: async () => {
        let res = await dispatch(resetCourse(Id));
        res && onStatusChange();
      },
      confirmationBtnName: "Delete User Data and Reset",
      contentMessage:
        "If you reset the course, all the User Data will be deleted and cannot be restored. Make sure you want to really reset the course",
      title: "Reset",
      isCentered: true,
    });

  return {
    handleReset,
  };
};
