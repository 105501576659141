// @ts-nocheck 
import {v1} from "constants/urls";

let prefix = `${v1}/employee-record/change-request`;

export default {
    profileChangeRequest:{
        changeRequest:`${prefix}`,
        pendingRequest:`${prefix}/pending-requests`,
        employeeREquestList:`${prefix}/employee-request-list`
    },
    contactChnageRequests:{
        officeContact:`${prefix}/office-contact`,
        homeContact:`${prefix}/home-contact`,
        permanentAddress:`${prefix}/permanent-address`,
        currentAddress:`${prefix}/current-address`,
    },
    personalDetailsChangeRequest:{
        personalDetails:`${prefix}/personal-detail`
    }
}