// @ts-nocheck 
import React, { memo, useEffect, useState } from "react";
import "./styles.scss";
import "../addCourse/pageForm/pageFormStyle.scss";
import classnames from "classnames";
import { FaUserFriends, FaYoutube, FaLock } from "react-icons/fa";
import { BsClock } from "react-icons/bs";
import { MdCheckCircle, MdPlayCircleFilled } from "react-icons/md";
import {
  getCourse,
  getCoursePages,
  getEmployeeCourse,
  getCourseCompleted,
  getEmployeeCourseByAssignId,
  updateCourseAssignStatus,
  getCourseCertificate,
} from "../helpers/action";
import { useDispatch, useSelector } from "react-redux";
import RouteEnum from "constants/routeEnum";
import dayjs from "dayjs";
import ImageRenderer from "../common/imageRenderer";
import { BiChevronRight, BiLeftArrowAlt } from "react-icons/bi";
import { EmployeeCourseAssignStatus } from "../helpers/enums";
import PageList from "../common/pageList";
import CourseCertificate from './page/certificate'
import CourseCertificateIcon from 'assets/images/certificate.png'
import Modal from 'components/modal'

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

function Courses(props) {
  const { courseId, assignedId } = props.match.params;
  const [certificateOpen,setCertificateOpen] = useState(false);
  const dispatch = useDispatch();
  const { course, courseCompleted } = useSelector((state: any) => state.lmsReducer);
  const { user } = useSelector((state: any) => state.auth);

  useEffect(() => {
    fetchCourseDetail();
    fetchCourseCertificate();
  }, [courseId, assignedId]);

  const fetchCourseCertificate = () => {
    // {employeeId:59,courseId:61}
    // if(courseId && user?.EmployeeId){
    //   const data = {
    //     employeeId:user?.EmployeeId,
    //     courseId:courseId,

    //   }
      
    // }
    dispatch(getCourseCertificate({}));
  }

  const fetchCourseDetail = () => {
    if (courseId) dispatch(getEmployeeCourse(courseId, assignedId));
  };

  const onStart = async () => {
    if (courseId) {
      var res: any = await dispatch(
        updateCourseAssignStatus({
          courseId,
          assignedId,
          status: EmployeeCourseAssignStatus.InProgress,
        })
      );
      if (course?.EmployeeCoursePageDetail.length > 0 && res) {
        let page = course?.EmployeeCoursePageDetail[0];
        const { CourseId, CoursePageId } = page;
        let url = `${RouteEnum.lmsCourse}/${CourseId}/${res.Id}/pages/${CoursePageId}`;
        props.history.push(url);
      }
    }
  };

  const onResume = () => {
    if (course?.EmployeeCoursePageDetail?.length > 0) {
      let completedPages =
        course?.EmployeeCoursePageDetail?.filter((x) => x.IsRead === true) || [];
      if (
        completedPages.length === 0 &&
        course.AssignedStatus === EmployeeCourseAssignStatus.InProgress
      ) {
        // if not any page is marked as completed then select first index item
        completedPages = [course.EmployeeCoursePageDetail[0]];
      }
      const page = completedPages[completedPages.length - 1];
      const { CourseId, CoursePageId } = page;
      const { AssignedId } = course;
      let url = "";
      if (AssignedId) {
        url = `${RouteEnum.lmsCourse}/${CourseId}/${AssignedId}/pages/${CoursePageId}`;
      } else url = `${RouteEnum.lmsCourse}/${CourseId}/pages/${CoursePageId}`;
      props.history.push(url);
    }
  };

  const obtainedPercentage = () => {
    return ((course?.ObtainedScore || 0) / (course?.TotalScore || 1)) * 100;
  };
  const onCertificateOpen = () => {
    setCertificateOpen(true)
  }
  const onCertificateClose = () => {
    setCertificateOpen(false)
  }
  const isCourseComplete = course?.PercentComplete === 100;
  return course ? (
    <div className="wrap-container">
      <div className="course">
        <div className="course-container">
          <div className="course-page__bookmark">
            <div
              onClick={() => props.history.push(RouteEnum.lmsRoutes)}
              className="course-page__bookmark-item"
            >
              <BiLeftArrowAlt /> Home
            </div>

            <div className="course-page__bookmark-seperator">
              <BiChevronRight />
            </div>
            <div className="course-page__bookmark-item inactive">{course?.Title}</div>
          </div>
          <div className="course-header mb-md">
            <div className="course-header__image">
              <ImageRenderer src={course?.ServerFileName} />
            </div>
            <div className="course-header__body">
              <div className="course-header__title">
                <span>{course?.Title}</span>
              </div>
              {course?.Author && (
                <div className="course-header__by">
                  <span>by</span>
                  <a>{course.Author}</a>
                </div>
              )}
              {course?.CategoryName && (
                <div className="course__tags">
                  <div className="course__tag">{course.CategoryName}</div>
                </div>
              )}
              {course?.TotalColleagues && (
                <div className="course-header__num">
                  <FaUserFriends />
                  <span className="bold-500">{course?.TotalColleagues}</span>
                  <span>colleagues taking this course</span>
                </div>
              )}
              <div className="course-header__statuses">
                {course?.Duration && (
                  <div className="course-header__status">
                    <BsClock />
                    <span className="bold-500">{course.Duration}</span>
                  </div>
                )}
                {/* <div className="course-header__status">
                <FaYoutube />
                <span className="bold-500">15 min</span>
              </div> */}
                <div className="course-header__status">
                  Due{" "}
                  <span className="ml-sm bold-500">
                    {" "}
                    {dayjs(course.EndDate).format("ll")}
                  </span>
                </div>
              </div>
              <div className="course-header__action">
                {course.AssignedStatus === EmployeeCourseAssignStatus.NotStarted ? (
                  <button
                    onClick={() => onStart()}
                    className="button button-large  button-primary"
                  >
                    Start
                  </button>
                ) : isCourseComplete ? (
                  <>
                    <div className="course-completed">
                      <span>Completed</span> on{" "}
                      {dayjs(course?.StatusChangedOn).format("ll")}
                    </div>
                  </>
                ) : (
                  <button
                    onClick={() => onResume()}
                    className="button button-large  button-primary"
                  >
                    Resume
                  </button>
                )}
                {course?.PercentComplete < 10 && (
                  <div className="course-header__complete">
                    {course?.PercentComplete
                      ? parseFloat(course?.PercentComplete).toFixed(2)
                      : 0}{" "}
                    %
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="course-body coursebody-landing">
            <div className="course-body__container">
              {course?.AssignedStatus === EmployeeCourseAssignStatus.Completed && (
                <div className="course-status mb-md">
                  <div className="course-status__rankings">
                    <div className="course-status__rankings-title">Your Rankings</div>
                    <div className="course-status__rankings-place">
                      <div className="ranking-labels">
                        <div className="course-status__rankings-rank">

                          <span>{course?.RanksInBranch}</span>
                          <span>Branch</span>
                        </div>
                      </div>
                      <div className="ranking-labels">
                        <div className="course-status__rankings-rank">

                          <span>{course?.Ranks}</span>
                          <span>Organization</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="course-status__score">
                    <div className="course-status__score-text">
                      <span>Your score</span>
                      <span>
                        {course?.ObtainedScore} out of {course?.TotalScore}
                      </span>
                    </div>
                    <div className="courses-assigned__status-bar">
                      <div
                        style={{ width: `${obtainedPercentage()}%` }}
                        onClick={()=>onCertificateOpen()}
                        className="courses-assigned__status-active"
                      ></div>
                      {isCourseComplete && <div 
                        onClick={()=>onCertificateOpen()}
                        className="courses-assigned__status-bar-complete">
                        <img src={CourseCertificateIcon} alt="Course Completion Certificate" />
                      </div>}
                    </div>
                  </div>
                </div>
              )}
              <div className="course__contain">
                <div className="course__title">Description</div>

                <div
                  dangerouslySetInnerHTML={{ __html: course?.Description }}
                  className="course__text mt-sm"
                ></div>
              </div>
            </div>
            <div className="course-body__sidebar ">
              <div className="course-body__sidebar-title">Outline</div>
              <PageList course={course} />
            </div>
          </div>
        </div>
      </div>
      
      <Modal 
        open={certificateOpen}
        width="950px"
        onModalClose={onCertificateClose}
        title="Course Completion Certificate"
      >
        <CourseCertificate />
      </Modal>
    </div>
  ) : null;
}

export default memo(Courses);
