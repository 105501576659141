import * as fromInterface from "../../../ts";
import * as fromConstant from "../../../constants";
import { ApiClient } from "../../../network/apiClient";
import * as fromSlice from "./slice";
import * as fromSelector from "./selectors"
import { reviewCycleDropdown } from "./api";


export const fetchReviewCycleDropDown = () => async (dispatch: any, getState: any) => {
    const state = getState();

    const isLoaded = fromSelector.selectReviewCycleDropdownLoaded(state)
    if (!isLoaded) {
        dispatch(fromSlice.fetchRequest())
        const response: any = await ApiClient.get({
            endpoint: reviewCycleDropdown,
            dispatch: dispatch
        })

        if (response?.Status) {
            dispatch(fromSlice.fetchSuccess(response.Data))
        }

        return response
    }
    return fromSelector.selectReviewCycleDropdownItems(state)
}

export const clearReviewCycleDropdown = () => (dispatch: any) => dispatch(fromSlice.clear())
