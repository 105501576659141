import React from "react";
import "./icons/icons.styles.scss";

function RigoLogo() {
  return (
    <svg
      width="1039"
      height="1039"
      viewBox="0 0 1039 1039"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="rigo-logo-vector 1">
        <g clipPath="url(#clip0)">
          <path
            id="Vector"
            d="M510.333 1024.98C792.183 1024.98 1020.67 795.534 1020.67 512.492C1020.67 229.451 792.183 0 510.333 0C228.484 0 0 229.451 0 512.492C0 795.534 228.484 1024.98 510.333 1024.98Z"
            fill="white"
            stroke="white"
            strokeWidth="0.0128255"
            strokeMiterlimit="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <linearGradient id="lg" x1="0.5" y1="1" x2="0.5" y2="0">
              <stop offset="0%" stopOpacity="1" stopColor="#0167B1" />
              <stop offset="40%" stopOpacity="1" stopColor="#0167B1">
                <animate
                  attributeName="offset"
                  values="0;1;0"
                  repeatCount="indefinite"
                  dur="5s"
                  begin="0s"
                />
              </stop>
              <stop offset="40%" stopOpacity="0" stopColor="#0167B1">
                <animate
                  attributeName="offset"
                  values="0;1;0"
                  repeatCount="indefinite"
                  dur="5s"
                  begin="0s"
                />
              </stop>
              <stop offset="100%" stopOpacity="0" stopColor="#0167B1" />
            </linearGradient>
            <linearGradient id="red" x1="0.5" y1="0" x2="0.5" y2="1">
              <stop offset="0%" stopOpacity="1" stopColor="#D03B37" />
              <stop offset="40%" stopOpacity="1" stopColor="#D03B37">
                <animate
                  attributeName="offset"
                  values="0;1;0"
                  repeatCount="indefinite"
                  dur="5s"
                  begin="0s"
                />
              </stop>
              <stop offset="40%" stopOpacity="0" stopColor="#D03B37">
                <animate
                  attributeName="offset"
                  values="0;1;0"
                  repeatCount="indefinite"
                  dur="5s"
                  begin="0s"
                />
              </stop>
              <stop offset="100%" stopOpacity="0" stopColor="#D03B37" />
            </linearGradient>
          <g id="logo">
            <path
              id="Vector_2"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M501.306 516.163L180.861 859.792L181.078 196.334L501.306 516.163V516.163Z"
              // fill="#0167B1"
              fill="url(#lg)"
              stroke="#0167B1"
              strokeWidth="7"
            />
            <path
              id="Vector_3"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M875.553 181L866.68 875.552L181 189.873L875.553 181Z"
              // fill="#D03B37"
              stroke="white"
              strokeWidth="0.0128255"
              strokeMiterlimit="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              stroke="#D03B37"
              strokeWidth="7"
              fill="url(#red)"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="1039" height="1039" fill="white" >

          <animate attributeName="opacity"
             values="0;1;0" dur="1s"
             repeatCount="indefinite"/>
          </rect>
        </clipPath>
      </defs>
    </svg>
  );
}

export default RigoLogo;

{/* <svg
  width="696"
  height="696"
  viewBox="0 0 696 696"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g id="logo" clip-path="url(#clip0)">
    <linearGradient id="lg" x1="0.5" y1="1" x2="0.5" y2="0">
      <stop offset="0%" stop-opacity="1" stopColor="#0167B1" />
      <stop offset="40%" stop-opacity="1" stopColor="#0167B1">
        <animate
          attributeName="offset"
          values="0;1;0"
          repeatCount="indefinite"
          dur="5s"
          begin="0s"
        />
      </stop>
      <stop offset="40%" stop-opacity="0" stopColor="#0167B1">
        <animate
          attributeName="offset"
          values="0;1;0"
          repeatCount="indefinite"
          dur="5s"
          begin="0s"
        />
      </stop>
      <stop offset="100%" stop-opacity="0" stopColor="#0167B1" />
    </linearGradient>
    <linearGradient id="red" x1="0.5" y1="0" x2="0.5" y2="1">
      <stop offset="0%" stop-opacity="1" stopColor="#D03B37" />
      <stop offset="40%" stop-opacity="1" stopColor="#D03B37">
        <animate
          attributeName="offset"
          values="0;1;0"
          repeatCount="indefinite"
          dur="5s"
          begin="0s"
        />
      </stop>
      <stop offset="40%" stop-opacity="0" stopColor="#D03B37">
        <animate
          attributeName="offset"
          values="0;1;0"
          repeatCount="indefinite"
          dur="5s"
          begin="0s"
        />
      </stop>
      <stop offset="100%" stop-opacity="0" stopColor="#D03B37" />
    </linearGradient>
    <path
      d="M510.333 1024.98C792.183 1024.98 1020.67 795.534 1020.67 512.492C1020.67 229.451 792.183 0 510.333 0C228.484 0 0 229.451 0 512.492C0 795.534 228.484 1024.98 510.333 1024.98Z"
      fill="white"
      stroke="white"
      strokeWidth="0.0128255"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      id="Vector"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M321.306 336.163L0.861084 679.792L1.0775 16.3345L321.306 336.163V336.163Z"
      // fill="#0167B1"
      fill="url(#lg)"
      stroke="#0167B1"
      strokeWidth="7"
    />
    <path
      id="Vector_2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M695.553 1L686.68 695.552L1 9.87308L695.553 1Z"
      fill="url(#red)"
      stroke="white"
      strokeWidth="0.0128255"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="#D03B37"
      strokeWidth="7"
    />
  </g>
  <defs>
    <clipPath id="clip0">
      <rect width="1039" height="1039" fill="white" />
    </clipPath>
  </defs>
</svg>; */}
