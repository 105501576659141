import { createSlice } from "@reduxjs/toolkit";

export interface ShiftDashboardState {
    todayShift: any;
    pendingShift: any;
    tomorrowShift: any
}

const initialState: ShiftDashboardState = {
    todayShift: { data: null, isLoading: false },
    pendingShift: { data: null, isLoading: false, pageIndex: 1, pageSize: 20, showPager: true },
    tomorrowShift: { data: null, isLoading: false },
};

const shiftDashboardSlice = createSlice({
    name: "shift dashboard",
    initialState,
    reducers: {

        fetchTodayShift: (state) => {
            state.todayShift.isLoading = true;
        },
        fetchSuccesTodayShift: (state, action) => {
            const payload = action.payload;
            state.todayShift.isLoading = false;
            state.todayShift.data = payload;
        },
        fetchPendingShift: (state) => {
            state.pendingShift.isLoading = true;
        },
        fetchSuccesPendingShift: (state, action) => {
            const payload = action.payload;
            state.pendingShift.data = payload.data;
            state.pendingShift.isLoading = false;
            state.pendingShift.pageIndex = payload.pageIndex;
            state.pendingShift.pageSize = payload.pageSize;
            state.pendingShift.showPager = payload.showPager
        },
        fetchTomorrowShift: (state) => {
            state.tomorrowShift.isLoading = true;
        },
        fetchSuccesTomorrowShift: (state, action) => {
            const payload = action.payload;
            state.tomorrowShift.isLoading = false;
            state.tomorrowShift.data = payload;
        },

        clear: (state) => {
            state.todayShift = { data: null, isLoading: false };
            state.pendingShift = { data: null, isLoading: false, pageIndex: 1, pageSize: 20, showPager: true };
            state.tomorrowShift = { data: null, isLoading: false };

        },
    },
});

export const { fetchSuccesTodayShift,
    fetchTodayShift,
    fetchSuccesPendingShift,
    fetchPendingShift,
    fetchSuccesTomorrowShift,
    fetchTomorrowShift,
    clear
} = shiftDashboardSlice.actions;

export default shiftDashboardSlice.reducer;
