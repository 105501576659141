
export const deepMergeJSON = (target: any, source: any): any => {
    const output = JSON.parse(JSON.stringify(target));

    if (typeof target !== 'object' || typeof source !== 'object') {
        return source;
    }

    Object.keys(source).forEach(key => {
        if (typeof source[key] !== 'object' || !source[key]) {
            output[key] = source[key];
        } else if (!target[key]) {
            output[key] = source[key];
        } else {
            output[key] = deepMergeJSON(target[key], source[key]);
        }
    });

    return output;
}
