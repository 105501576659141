import { createSlice } from "@reduxjs/toolkit";
import * as fromInterface from "../../../ts";

export interface PerformanceScoreFilterState {
    pageIndex: number,
    pageSize: number,
    reviewCycleId?: any,
    reviewFormId?: any,
    branchId?: any,
    departmentId?: any,
    employeeName?: string | null,
}

const initialState: PerformanceScoreFilterState = {
    pageIndex: 0,
    pageSize: 0,
    reviewCycleId: -1,
    reviewFormId: -1,
    branchId: -1,
    departmentId: -1,
    employeeName: ""

};

const performanceScoreFilterSlice = createSlice({
    name: "performance score filter",
    initialState,
    reducers: {
        udpateFilter: (state, action) => {
            const payload = action.payload
            return { ...state, ...payload }
        },
        clear: (state) => {
            return initialState
        },
    },
});

export const {
    udpateFilter,
    clear,
} = performanceScoreFilterSlice.actions

export default performanceScoreFilterSlice.reducer;