import {
  TRAVEL_CATEGORY_LOADING,
  GET_TRAVEL_CATEGORY,
  ADD_TRAVEL_CATEGORY,
  LOAD_TRAVEL_CATEGORY,
  EDIT_TRAVEL_CATEGORY,
  DELETE_TRAVEL_CATEGORY,
  GET_ALLOWANCE_EXPENSE,
  GET_LEVELS,
} from "actions/types";

const initialState = {
  travelCategoryLoading: false,
  travelCategory: [],
  travelCategoryFormData: null,
  isEdit: false,
  spinnerLoading: false,
  allowanceExpense: [],
  levels: [],
  allowanceExpenseRate: [],
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TRAVEL_CATEGORY_LOADING:
      return {
        ...state,
        spinnerLoading: action.payload,
      };
    case GET_TRAVEL_CATEGORY:
      return {
        ...state,
        travelCategory: action.payload,
        //searchedPage: { index: action.page.index, total: action.page.total },
        isEdit: false,
        spinnerLoading: false,
        loading: false,
      };
    case LOAD_TRAVEL_CATEGORY:
      return {
        ...state,
        travelCategoryFormData: action.payload,
        isEdit: false,
        spinnerLoading: false,
        loading: false,
      };
    // case SET_EDIT_MODE:
    //   return {
    //     ...state,
    //     isEdit: true
    //   };
    case GET_ALLOWANCE_EXPENSE:
      return {
        ...state,
        allowanceExpense: action.payload,
      };
    case GET_LEVELS:
      return {
        ...state,
        levels: action.payload,
        spinnerLoading: false,
      };
    default:
      return state;
  }
}
