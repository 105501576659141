import { createAsyncThunk } from "@reduxjs/toolkit";

import { managersAPI } from "./api";
import { ApiClient } from "../../../network/apiClient";
import * as fromInterface from "../../../ts";

const NAMESPACE = "managers/prefix";

export const fetchAllManagers = createAsyncThunk(
  NAMESPACE.replace("prefix", "fetch/all/managers"),
  async (params: any, { dispatch }) => {
    const param = {
      endpoint: managersAPI.getManagers,
      body: {
        ...params,
      },
      dispatch: dispatch,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.post<any>(param);
    const { Data, Status } = response;
    if (Status) {
      return Data;
    }
    return null;
  }
);

export const fetchAssignedSummary = createAsyncThunk(
  NAMESPACE.replace("prefix", "fetch/assigned/summary"),
  async (params, { dispatch }) => {
    const param = {
      endpoint: managersAPI.assignedSummary,

      dispatch: dispatch,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.get<any>(param);
    const { Data, Status } = response;
    if (Status) {
      return Data;
    }
    return null;
  }
);

export const fetchEmployeeList = createAsyncThunk(
  NAMESPACE.replace("prefix", "fetch/common/employees"),
  async (params: any, { dispatch }) => {
    const param = {
      endpoint: managersAPI.getEmployees,

      dispatch: dispatch,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.get<any>(param);
    const { Data, Status } = response;
    if (Status) {
      return Data;
    }
    return null;
  }
);

export const fetchPositionList = createAsyncThunk(
  NAMESPACE.replace("prefix", "fetch/common/positions"),
  async (params, { dispatch }) => {
    const param = {
      endpoint: managersAPI.getPosition,

      dispatch: dispatch,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.get<any>(param);
    const { Data, Status } = response;
    if (Status) {
      return Data;
    }
    return null;
  }
);

export const updateIndividualManager = createAsyncThunk(
  NAMESPACE.replace("prefix", "update/individual/manager"),
  async ({ payload, cb1, cb2 }: any, { dispatch }) => {
    const param = {
      endpoint: managersAPI.managers,
      body: [...payload],
      dispatch: dispatch,
      showMessage: true,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.post<any>(param);
    const { Data, Status } = response;
    if (Status) {
      cb1();
      cb2();
      return Data;
    }
    return null;
  }
);

export const deleteIndividualManager = createAsyncThunk(
  NAMESPACE.replace("prefix", "delete/individual/manager"),
  async ({ id, cb }: any, { dispatch }) => {
    const param = {
      endpoint: `${managersAPI.managers}/${id}`,
      showMessage: true,
      dispatch: dispatch,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.remove<any>(param);
    const { Data, Status } = response;
    if (Status) {
      cb();
      dispatch(fetchAssignedSummary());
      return Data;
    }
    return null;
  }
);

export const saveSubordinates = ({payload, cb}:any) => async (dispatch: any) => {
  const response: fromInterface.ResponseWrapper<any> =
    await ApiClient.post<any>({
      endpoint: managersAPI.managers,
      dispatch: dispatch,
      body: payload,
    })

  const { Data, Status } = response

  if (Status) {
    cb();
    return Data;
  }
}


