// @ts-nocheck 
export const checkExt = (files, isMultiple = false) => {
    let isImage = false;
    if (files) {
      if (isMultiple) {
        files.map((item) => (isImage = checkIfImage(item)));
      } else {
        isImage = checkIfImage(files);
      }
    }
    return isImage;
  };
  
  export const checkIfImage = (file: any) => {
    return (
      file.UserFileName &&
      file.UserFileName.toLowerCase().match(/.(jpg|jpeg|png)$/i) !== null
    );
  };
  
  export const validateFile = (file, props, setError) => {
    const { maxSizeInMb, minSizeInKb, validExtensions } = props;
  
    let isValid = true;
    const fileSizeInKb = file.size / 1024;
    const fileSizeInMb = fileSizeInKb / 1024;
    const extension = file.name.split(".")[1].toLowerCase();
    if (fileSizeInMb > maxSizeInMb) {
      isValid = false;
      setError(`Max file size is ${maxSizeInMb} Mb`);
    } else if (fileSizeInKb < minSizeInKb) {
      isValid = false;
      setError(`Min file size is ${minSizeInKb} Kb`);
    }
  
    if (!validExtensions.find((f) => f == extension)) {
      isValid = false;
      setError(`Valid extensions are ${validExtensions}`);
    }
    return isValid;
  };
  