// LIBS
import { Controller } from 'react-hook-form';

// CHAKRA-UI
import {
  FormControl,
  FormHelperText,
  Input,
  InputProps,
} from '@chakra-ui/react';

// UTILITIES
import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';
import { resolveObjectValueByPath } from '../../../../helpers';

// COMPONENTS
import FormErrorLable from '../form-error-text';
import RenderWithLabelPosition from '../form-helper-components/render-with-label-position';
import FormLabel from '../form-label/form-label';

// FORM HELPERS;
import * as fromFormHelpers from '../@form-helper';

/**
 * SOME TYPES ARE OMITTED BECAUSE IT EXISTS AND COL=NFLICTS WITH THE CHILD INTERFACE (TextInputComponentProps)
 */
type TextInputComponentProps = Pick<
  fromFormHelpers.BaseFormProps,
  'name' | 'value' | 'onChangeRHF' | 'onChange'
> &
  InputProps;

export const TextInputComponent = (props: TextInputComponentProps) => {
  let { name, value, onChange: _onChange, onChangeRHF, ...rest } = props;

  const handleChange = (e: any) => {
    const { value } = e.target;
    _onChange?.(name, value);
    onChangeRHF?.(value);
  };

  return (
    <Input
      test-id='text-input'
      value={value}
      onChange={handleChange}
      w='full'
      bg='white'
      {...rest}
      border='1px solid'
      borderRadius='4px'
      borderColor='#CDD5DF'
      size='md'
      height='38px'
      _hover={{ borderColor: '#B6C3D3' }}
      _placeholder={{
        color: '#878787',
        fontWeight: '300',
        fontSize: '14px',
        textTransform: 'lowercase',
      }}
    />
  );
};

type TextInputProps = { type?: any; width?: any; onChange?: any } & Omit<
  fromFormHelpers.BaseFormProps,
  'onChange'
> &
  Omit<InputProps, 'onChange'>;

export function TextInput(props: TextInputProps) {
  const {
    label,
    control,
    ignoreControl = false,
    required = false,
    showOptionalLabel = true,
    errors,
    rule,
    width,
    customLabel,
    labelPosition,
    errorMessage,
    ...rest
  } = props;

  /**
   * GET THE ERROR FROM ERRORS
   * LATER USE TO DISPLAY ERROR
   */
  const error =
    errorMessage ||
    (errors && resolveObjectValueByPath(errors, props.name)?.message);

  /**
   * IF USER PASSES OWN RULE,
   * MERGE THE RULE WITH DEFAULT RULE
   * AND OVERWRITE IT
   */
  let _rule = fromFormHelpers.getDefaultRules({ required });

  if (!isEmpty(rule)) {
    _rule = merge(_rule, rule);
  }

  /**
   * IF NO CONTROL IS PROVIDED,
   * THEN USER IS USING THIS COMPONENT OUTSIDE FORM
   * NORMALYY AS FILTER OR CUSTOM USE CASE
   */
  if (!control || ignoreControl) {
    return (
      <FormControl
        display='flex'
        flexDirection='column'
        gap={2}
        id={props.name}
        isRequired={required}
        style={{ width }}
      >
        <RenderWithLabelPosition
          labelPosition={labelPosition}
          label={<FormLabel label={label} customLabel={customLabel} />}
          inputComponent={<TextInputComponent {...rest} />}
        />
      </FormControl>
    );
  }

  /**
   * USE THE COMPONENT WITH IN FORM PROVIDER
   */
  return (
    <FormControl
      display='flex'
      flexDirection='column'
      gap={1}
      id={props.name}
      isRequired={false}
      style={{ width }}
    >
      <RenderWithLabelPosition
        labelPosition={labelPosition}
        label={
          <FormLabel
            color='#000000b3'
            fontSize='14px'
            lineHeight='21px'
            label={label}
            customLabel={customLabel}
          />
        }
        inputComponent={
          <Controller
            control={control}
            name={props.name}
            rules={_rule}
            render={controllerProps => {
              const {
                field: { onChange: _onChange, value: _value },
              } = controllerProps;
              return (
                <TextInputComponent
                  {...rest}
                  value={_value}
                  onChangeRHF={_onChange}
                />
              );
            }}
          />
        }
        optionalPrompt={
          !required && showOptionalLabel ? (
            <FormHelperText
              m={0}
              pl='10px'
              color='gray.500'
              fontWeight='300'
              fontSize='14px'
            >
              optional
            </FormHelperText>
          ) : null
        }
        errorPrompt={
          <FormErrorLable py='2px' px={1} fontSize='14px' error={error} />
        }
      />
    </FormControl>
  );
}
export default TextInput;
