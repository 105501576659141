import * as fromSlice from "./slice";

export const selectCurrentEmployeeDataChangeRequestsFilterSlice = (
  state: any,
): fromSlice.EmployeeDataChangeRequestsFilterState => {
  return state.dataStore.employeeDataChangeRequestsFilter;
};

export const selectCurrentEmployeeDataChangeRequestsFilter = (
  state: any,
): fromSlice.EmployeeDataChangeRequestsFilterState => {
  return selectCurrentEmployeeDataChangeRequestsFilterSlice(state);
};
