
import { ApiClient } from "../../../../network/apiClient";
import * as fromInterface from "../../../../ts";
import { PERFORMANCE_EVALUATION } from "../api";
import * as fromSelector from "../selectors";

export const fetchPerformanceEvaluation = ({ cb }: { cb: any }): any => async (dispatch: any, getState: any) => {
    const state = getState();
    const reviewDetail = fromSelector.selectReview(state);

    const { ReviewInfo } = reviewDetail;

    const response: fromInterface.ResponseWrapper<fromInterface.PerformanceReview> = await ApiClient.get<any>({
        endpoint: `${PERFORMANCE_EVALUATION}/${ReviewInfo?.PerformanceRolloutId}`,
        dispatch: dispatch
    })

    const { Data, Status } = response

    if (Status) {
        cb?.(Data)
    }
}