// @ts-nocheck 
import * as AuthReducer from "./authReducer";
import * as ErrorReducer from "./errorReducer";
import * as ValidateReducer from "./validateReducer";
import * as LoadingReducer from "./loadingReducer";
import * as PermissionsReducer from "./permissionReducer";
import disciplinaryStagesReducer from "scenes/admin/disciplinaryStages/reducer";
import disciplinaryActionReducer from "scenes/admin/disciplinaryAction/reducer";
import commonReducer from "./commonReducer";
import empoloyeeReducer from "./employeeReducer";
import selectReducer from "./selectReducer";
import trelloReducer from "scenes/recruit/jobCreate/scorecard/trello/reducer";
import taskTrackerReducer from "scenes/taskTracker/reducer";
//travel
import allowanceExpenseReducer from "scenes/travel/admin/travelAllowanceExpense/reducer";
import travelCategoryReducer from "scenes/travel/admin/travelCategory/reducer";
import travelInformationReducer from "scenes/travel/common/travelInformation/reducer";
import employeeReducer from "scenes/employee/reducer";
import admin_EmployeeListReducer from "scenes/employee/employeeList/reducer";
import employeeDashboard from "scenes/employee/personal/reducer";
import employeeProfile from "scenes/employee/employeeProfile/reducer";
//Employee Leave
import employeeLeaveReducer from "scenes/employee/leaves/reducer";
import employeeTimeReducer from "scenes/employee/time/reducer";
import attendanceReducer from "scenes/employee/time/attendance/reducer";
import employeeLeaveRequestReducer from "scenes/employee/leaves/leaveRequest/reducer";
import employeeOvertimeRequestReducer from "scenes/employee/overtimeRequest/reducer";
import employeeAllowanceReducer from "scenes/employee/allowance/reducer";

//Employee request
import employeeRequestReducer from "scenes/employee/requests/reducers";
//

// import taxAdjustmentReducer from "../Scenes/Payroll/Tax_Adjustment/reducer";
import travelSettlementReducer from "scenes/travel/common/travelSettlement/reducer";
//
import hrReducer from "scenes/hr/helpers/reducer";
import payrollDashboardReducer from "scenes/payroll/dashboard/helpers/reducer";
import leaveTimeReducer from "scenes/leaveTime/helpers/reducer";
import empDeviceReducer from "scenes/leaveTime/mobileDevice/reducer";

//jobprofile
import jobProfileKSAReducer from "scenes/admin/jobProfile/ksaLibraries/reducer";
import jobProfileReducer from "scenes/admin/jobProfile/reducer";
//

//Task Reducer
import taskReducer from "scenes/tasks/helpers/reducer";

import settingsReducer from "scenes/settings/helpers/reducer";
import notificationReducer from "scenes/notifications/reducer";

// employeeOnboarding
import salaryTemplateReducer from "scenes/settings/salaryTemplate/salaryTemplateReducer";
import employeeOnboarding from "scenes/admin/employeeOnboarding/reducer";
import accessAndIdTemplateReducer from "scenes/onboarding/accessAndIdCategoryTemplate/accessAndIdTemplateReducer";
import checklistTemplateReducer from "scenes/onboarding/checklistTemplate/checklistTemplateReducer";
import messageTemplateReducer from "scenes/onboarding/messageTemplate/messageTemplateReducer";

//Recruitment
import jobRequisitionReducer from "scenes/recruit/jobRequisition/reducer";
import createJobReducer from "scenes/recruit/jobCreate/helpers/reducer";
import jobListingReducer from "scenes/recruit/jobListing/reducer";
import jobApplyReducer from "scenes/recruit/jobApply/reducer";

import allowanceReducer from "scenes/hr/allowance/helpers/reducer";
import overtimeReducer from "scenes/hr/overtime/helpers/reducer";

// Roles & Permission
import clusterReducer from "scenes/roles/clusters/helpers/reducer";
import authorizedReducer from "scenes/roles/authorized/helpers/reducer";
// import managersReducer from "scenes/roles/managers/reducer";

// payroll calculation
import incomeReducer from "../scenes/payroll/incomeCalculation/reducer";
import leaveReducer from "../scenes/payroll/leaveAbsence/reducer";
import payrollAttendanceReducer from "../scenes/payroll/attendanceRegister/reducer";
import salaryAdjustReducer from "../scenes/payroll/salaryAdjustment/reducer";
import stopPaymentReducer from "../scenes/payroll/stopPayment/reducer";
import taxAdjustmentReducer from "../scenes/payroll/taxAdjustment/reducer";

import loanReducer from "../scenes/loan/helpers/reducer";

import lmsReducer from "scenes/lms/helpers/reducer";
import sbiLoanReducer from "scenes/employee/sbiloan/helpers/reducer";
import sbiLoanSettingReducer from "scenes/sbiLoan/helpers/reducer";
import roosterReducer from "scenes/roster/helpers/reducer";
import taskTrackerDetailsReducer from "scenes/taskTracker/activityDashboard/helpers/reducer";


import globalUi from "store/global-ui/reducer";

import { dataStore } from "@rigotech/hrms-data";
import * as fromDataStore from "@rigotech/hrms-data"
export interface ApplicationState {
  auth: AuthReducer.AuthState | undefined;
  validateReducer: ValidateReducer.InitialState | undefined;
  loadingReducer: LoadingReducer.InitialState | undefined;
  permissionReducer: PermissionsReducer.InitialState | undefined;
}

export const reducers = {
  auth: AuthReducer.reducer,
  errors: ErrorReducer.reducer,
  validateReducer: ValidateReducer.reducer,
  loadingReducer: LoadingReducer.reducer,
  commonReducer,
  empoloyeeReducer,
  selectReducer,
  trelloReducer,
  disciplinaryStagesReducer,
  disciplinaryActionReducer,
  //travel
  allowanceExpenseReducer,
  travelCategoryReducer,
  travelInformationReducer,
  travelSettlementReducer,
  ///
  //employee related Reducers
  employeeReducer,
  employeeDashboard,
  employeeProfile,
  employeeLeaveReducer,
  employeeTimeReducer,
  attendanceReducer,
  admin_EmployeeListReducer,
  employeeLeaveRequestReducer,
  employeeOvertimeRequestReducer,
  employeeRequestReducer,
  employeeAllowanceReducer,
  roosterReducer,

  taskTrackerReducer,

  //Admin Dashboard
  hrReducer,
  payrollDashboardReducer,
  leaveTimeReducer,
  empDeviceReducer,

  //Jobprofile
  jobProfileKSAReducer,
  jobProfileReducer,

  //task Reducer
  taskReducer,

  //recruitment
  jobRequisitionReducer,
  createJobReducer,
  jobListingReducer,
  jobApplyReducer,

  //Permissions and Flags reducer
  permissionReducer: PermissionsReducer.reducer,
  settingsReducer,
  notificationReducer,

  // employee onboarding
  salaryTemplateReducer,
  employeeOnboarding,
  accessAndIdTemplateReducer,
  checklistTemplateReducer,
  messageTemplateReducer,

  //allowance & Overtime
  allowanceReducer,
  overtimeReducer,

  // payroll
  incomeReducer,
  leaveReducer,
  payrollAttendanceReducer,
  salaryAdjustReducer,
  stopPaymentReducer,
  taxAdjustmentReducer,
  //Roles & Permission
  clusterReducer,
  authorizedReducer,
  // managersReducer,
  loanReducer,

  //LMS
  lmsReducer,
  sbiLoanReducer,

  // sbi setting
  sbiLoanSettingReducer,

  // task tracker
  taskTrackerDetailsReducer,
  globalUi,
  dataStore,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
