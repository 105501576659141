import * as fromInterface from "../../../ts";
import { AttendanceMappingFilterCountState } from "./slice";

const selectAttendanceMappingFilterCountSlice = (
  state: any
): AttendanceMappingFilterCountState => {
  return state.dataStore.attendanceMapFilterCount;
};

export const selectAttMapFilterCountStatus = (
  state: any
): fromInterface.loadingType => {
  return selectAttendanceMappingFilterCountSlice(state).status;
};

export const selectAttMapFilterCountLoaded = (state: any): boolean => {
  return selectAttendanceMappingFilterCountSlice(state).loaded;
};

export const selectAttMapFilterCountItems = (state: any) => {
  return selectAttendanceMappingFilterCountSlice(state).items;
};
