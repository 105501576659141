import {
    GET_INCOME_OR_DEDCUTIONS,
    SEARCHED_SALARY_ADJUSTMENT,
    EDIT_SALARY_ADJUST,
    DELETE_SALARY_ADJUSTMENT,
    INCOME_OR_DEDCUTIONS_LOADING,
    SALARY_ADJUSTMENT_LOADING,
  } from '../../../actions/types';
  
  const initialState = {
    income_or_deductions: [],
    searchSalaryAdjustment:[],
    searchedPage:{index:0,total:0},
    loading: false,
    incomeDeductionLoading:false,
  };

  export default function(state = initialState, action) {
    switch (action.type) {
      case SALARY_ADJUSTMENT_LOADING:
        return {
          ...state,
          loading: true
        };
      case INCOME_OR_DEDCUTIONS_LOADING:
        return {
          ...state,
          incomeDeductionLoading: true
        };
      case GET_INCOME_OR_DEDCUTIONS:
        return {
          ...state,
          income_or_deductions: action.payload,
          incomeDeductionLoading: false
        };
      case EDIT_SALARY_ADJUST:
      return {
          ...state,
          searchSalaryAdjustment:state.searchSalaryAdjustment.map(
                        (salary, i) =>salary.EmployeeId === action.id ?  
                        action.payload
                        : salary
          )
      };
      case SEARCHED_SALARY_ADJUSTMENT:
            return {
              ...state,
              searchSalaryAdjustment: action.payload,
              searchedPage:{index:action.page.index,total:action.page.total},
              loading: false
            };
      case DELETE_SALARY_ADJUSTMENT:
        return {
          ...state,
          searchSalaryAdjustment: state.searchSalaryAdjustment.filter(salary => salary.EmployeeId !== action.payload.EmployeeId)
        };
      default:
        return state;
    }
  }
  