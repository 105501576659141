import { createSlice } from "@reduxjs/toolkit";
import * as fromInterface from "../../../ts";

export interface CurrentSectionFormDetailState {
    detail: any,
    status: fromInterface.loadingType;
    loaded: boolean;
}

const initialState: CurrentSectionFormDetailState = {
    detail: null,
    status: fromInterface.STATUS.LOADING,
    loaded: false,
};

const currentSectionFormDetailSlice = createSlice({
    name: "current section form detail",
    initialState,
    reducers: {
        fetchRequest: (state) => {
            state.status = fromInterface.STATUS.LOADING;
        },
        fetchSuccess: (state, action) => {
            const payload = action.payload

            state.detail = payload;
            state.status = fromInterface.STATUS.IDLE;
            state.loaded = true

        },
        clear: (state) => {
            state.detail = []
            state.status = fromInterface.STATUS.IDLE
            state.loaded = false
        },
    },
});

export const {
    fetchRequest,
    fetchSuccess,
    clear,
} = currentSectionFormDetailSlice.actions

export default currentSectionFormDetailSlice.reducer;