import {
  FlexProps,
  Flex,
  Spinner,
  SpinnerProps,
  Heading,
} from "@chakra-ui/react";

import isEmpty from "lodash/isEmpty";
import  {NodataFoundThree } from "@rigotech/ui";

// ANATOMY
// CONTAINER
// spinner
// children

export interface LoaderProps {
  containerProps?: FlexProps;
  spinnerProps?: SpinnerProps;
  children?: any;
  isLoading?: boolean;
  data?: any;
  emptyMessage?: string;
  emptyComponent?: JSX.Element;
}
export const Loader = (props: LoaderProps) => {
  const {
    children,
    isLoading,
    containerProps,
    spinnerProps,
    data,
    emptyComponent: EmptyComponent,
    emptyMessage,
  } = props;

  if (isLoading) {
    return (
      <Flex
        bg="white"
        justifyContent="center"
        alignItems="center"
        w="full"
        minH="200px"
        borderRadius='8px'
        {...containerProps}
      >
        <Spinner {...spinnerProps} />
      </Flex>
    );
  } else if (isEmpty(data)) {
    return (
      <>
        {EmptyComponent ? (
          EmptyComponent
        ) : (
          <Flex
            bg="white"
            justifyContent="center"
            alignItems="center"
            w="full"
            minH="200px"
            borderRadius='8px'
            {...containerProps}
          >
            <Heading
              fontSize="26px"
              color="#484848"
              fontWeight="500"
              mb={2}
              textTransform="capitalize"
            >
              {emptyMessage ? emptyMessage : <NodataFoundThree  />}
            </Heading>
          </Flex>
        )}
      </>
    );
  }
  return children;
};

export default Loader;
