// @ts-nocheck 
import React, {useEffect, useState} from "react";
import {RiBook2Line} from "react-icons/ri";
import {DateFormat} from "helpers/dateFormat";
import {getFullURL} from "helpers/fullURL";
import {useSelector} from "react-redux";
import Avatar from "react-avatar";

const Confirmation = (props) => {
  const {data, courses, getSelectedEmployees} = props;
  const [state, setState] = useState({selectedEmployees: []});
  useEffect(() => {
    const emp = getSelectedEmployees();
    setState({...state, selectedEmployees: emp});
  }, [data.selectedEmployees]);

  const noOfSelectedEmps = () => {
    let len = state.selectedEmployees.length;
    if (len === 0) return `No employees selected.`;
    if (len > 1) {
      return `${len} employees selected.`;
    }
    return `1 employee selected.`;
  };

  const formattedDate = (date) => {
    return date && DateFormat(new Date(date), "MMM DD, yyyy");
  };
  const getValidDays = () => {
    const {validDays} = data;
    if (validDays) {
      if (parseInt(validDays) > 1) return `${validDays} days`;
      return `${validDays} day`;
    }
    return "";
  };
  const baseURL = getFullURL(useSelector, "");
  const noOfCourse = (courses && courses.length) || 0;
  return (
    <div>
      <div className="confirmation__row">
        <div className="confirmation__title">
          <RiBook2Line />

          <span>
            {noOfCourse > 1
              ? `${noOfCourse} Courses selected`
              : `${noOfCourse} Course selected`}{" "}
          </span>
        </div>

        <ul>
          {courses && courses.map((item, index) => <li key={index}>{item.Title}</li>)}
        </ul>
      </div>
      <div className="confirmation__row">
        <div className="confirmation__title">{noOfSelectedEmps()}</div>
        <div className="confirmation__employees">
          {state.selectedEmployees?.map((x, ind) => (
            <div className="confirmation__employees-section" key={ind}>
              <div className="confirmation__employees-image">
                <Avatar size="25px" round src={x.Photo} name={x.EmployeeName} />
              </div>
              <div className="confirmation__employees-name">{x.EmployeeName}</div>
            </div>
          ))}
        </div>
        {/* <div>

          <ul className="emp">
            {state.selectedEmployees?.map((x, ind) => (
              <li key={ind}>{x.EmployeeName}</li>
            ))}
          </ul>
        </div> */}
      </div>
      <div className="confirmation__row">
        <div className="confirmation__row-block">
          <span className="confirmation__label">Start date</span>
          <span className="confirmation__info">
            {data.startDate && formattedDate(data.startDate)}
          </span>
        </div>
        <div className="confirmation__row-block">
          <span className="confirmation__label">Valid for</span>
          <span className="confirmation__info"> {getValidDays()}</span>
        </div>
        <div className="confirmation__row-block">
          <span className="confirmation__label">End date</span>
          <span className="confirmation__info">
            {data.endDate && formattedDate(data.endDate)}
          </span>
        </div>
      </div>
    </div>
  );
};
export default Confirmation;
