import { RenderCellsProps } from '../interfaces';
import { ScrollNumber } from './ScrollNumbers';
import React from 'react';
const RenderCells = (props: RenderCellsProps) => {
  const { show, handleClick, selectedValue, identifier, nums } = props;

  return show ? (
    <ScrollNumber
      nums={nums}
      cellIdentifier={identifier}
      handleNumClick={handleClick}
      selectedValue={selectedValue ?? 0}
    />
  ) : null;
};

export default RenderCells;
