// @ts-nocheck
export const getMonthsFirstDay = year => {
  var monthsGap = [
    [1970, 0, 3, 6, 3, 6, 2, 5, 0, 1, 3, 4, 6],
    [1971, 1, 4, 0, 4, 0, 4, 6, 1, 2, 4, 5, 0],
    [1972, 2, 5, 2, 5, 2, 5, 0, 2, 4, 5, 6, 1],
    [1973, 3, 5, 2, 5, 2, 5, 0, 2, 4, 5, 0, 1],
    [1974, 4, 0, 3, 0, 3, 6, 2, 4, 5, 0, 1, 3],
    [1975, 5, 1, 4, 1, 5, 1, 4, 6, 0, 2, 3, 5],
    [1976, 0, 3, 0, 3, 0, 3, 5, 0, 2, 3, 4, 6],
    [1977, 2, 4, 1, 4, 1, 4, 0, 1, 3, 4, 6, 0],
    [1978, 3, 6, 2, 6, 2, 5, 1, 3, 4, 6, 0, 2],
    [1979, 4, 0, 3, 0, 4, 0, 2, 4, 5, 0, 1, 3],
    [1980, 5, 1, 5, 1, 5, 1, 3, 5, 0, 1, 2, 4],
    [1981, 0, 3, 6, 2, 6, 2, 5, 6, 1, 3, 4, 6],
    [1982, 1, 4, 0, 4, 0, 3, 6, 1, 2, 4, 5, 0],
    [1983, 2, 5, 1, 5, 2, 5, 0, 2, 3, 5, 6, 1],
    [1984, 3, 6, 3, 6, 3, 6, 1, 3, 5, 6, 0, 2],
    [1985, 5, 1, 4, 0, 4, 0, 3, 4, 6, 1, 2, 4],
    [1986, 6, 2, 5, 2, 5, 1, 4, 6, 0, 2, 3, 5],
    [1987, 0, 3, 0, 3, 0, 3, 5, 0, 1, 3, 4, 6],
    [1988, 1, 4, 1, 4, 1, 4, 6, 1, 3, 4, 5, 0],
    [1989, 3, 6, 2, 5, 2, 5, 1, 3, 4, 6, 0, 2],
    [1990, 4, 0, 3, 0, 3, 6, 2, 4, 5, 0, 1, 3],
    [1991, 5, 1, 5, 1, 5, 1, 3, 5, 6, 1, 2, 4],
    [1992, 6, 2, 6, 2, 6, 2, 4, 6, 1, 2, 4, 5],
    [1993, 1, 4, 0, 3, 0, 3, 6, 1, 2, 4, 5, 0],
    [1994, 2, 5, 1, 5, 1, 4, 0, 2, 3, 5, 6, 1],
    [1995, 3, 6, 3, 6, 3, 6, 1, 3, 5, 6, 0, 2],
    [1996, 4, 0, 4, 0, 4, 0, 2, 4, 6, 0, 2, 3],
    [1997, 6, 2, 5, 2, 5, 1, 4, 6, 0, 2, 3, 5],
    [1998, 0, 3, 6, 3, 6, 2, 5, 0, 1, 3, 4, 6],
    [1999, 1, 4, 1, 4, 1, 4, 6, 1, 3, 4, 5, 0],
    [2000, 3, 5, 2, 5, 2, 5, 0, 2, 4, 5, 0, 1],
    [2001, 4, 0, 3, 0, 3, 6, 2, 4, 5, 0, 1, 3],
    [2002, 5, 1, 4, 1, 5, 1, 3, 5, 6, 1, 2, 4],
    [2003, 6, 2, 6, 2, 6, 2, 4, 6, 1, 2, 3, 5],
    [2004, 1, 3, 0, 3, 0, 3, 5, 0, 2, 3, 5, 6],
    [2005, 2, 5, 1, 5, 1, 4, 0, 2, 3, 5, 6, 1],
    [2006, 3, 6, 2, 6, 3, 6, 1, 3, 4, 6, 0, 2],
    [2007, 4, 0, 4, 0, 4, 0, 2, 4, 6, 0, 1, 3],
    [2008, 6, 2, 5, 1, 5, 1, 4, 5, 0, 2, 3, 4],
    [2009, 0, 3, 6, 3, 6, 2, 5, 0, 1, 3, 4, 6],
    [2010, 1, 4, 0, 4, 1, 4, 6, 1, 2, 4, 5, 0],
    [2011, 2, 5, 2, 5, 2, 5, 0, 2, 4, 5, 6, 1],
    [2012, 4, 0, 3, 6, 3, 6, 2, 3, 5, 0, 1, 3],
    [2013, 5, 1, 4, 1, 4, 0, 3, 5, 6, 1, 2, 4],
    [2014, 6, 2, 5, 2, 6, 2, 4, 6, 0, 2, 3, 5],
    [2015, 0, 3, 0, 3, 0, 3, 5, 0, 2, 3, 4, 6],
    [2016, 2, 5, 1, 4, 1, 4, 0, 1, 3, 5, 6, 1],
    [2017, 3, 6, 2, 6, 2, 5, 1, 3, 4, 6, 0, 2],
    [2018, 4, 0, 4, 0, 4, 0, 2, 4, 5, 0, 1, 3],
    [2019, 5, 1, 5, 1, 5, 1, 3, 5, 0, 1, 3, 4],
    [2020, 0, 3, 6, 2, 6, 2, 5, 0, 1, 3, 4, 6],
    [2021, 1, 4, 0, 4, 0, 3, 6, 1, 2, 4, 5, 0],
    [2022, 2, 5, 2, 5, 2, 5, 0, 2, 4, 5, 6, 1],
    [2023, 3, 6, 3, 6, 3, 6, 1, 3, 5, 6, 1, 2],
    [2024, 5, 1, 4, 0, 4, 0, 3, 5, 6, 1, 2, 4],
    [2025, 6, 2, 5, 2, 5, 1, 4, 6, 0, 2, 3, 5],
    [2026, 0, 3, 0, 3, 0, 3, 5, 0, 2, 3, 4, 6],
    [2027, 2, 4, 1, 4, 1, 4, 6, 1, 3, 4, 6, 0],
    [2028, 3, 6, 2, 6, 2, 5, 1, 3, 4, 6, 0, 2],
    [2029, 4, 0, 3, 0, 3, 0, 2, 4, 5, 0, 1, 3],
    [2030, 5, 1, 5, 1, 5, 1, 3, 5, 0, 1, 2, 4],
    [2031, 0, 2, 6, 2, 6, 2, 4, 6, 1, 2, 4, 5],
    [2032, 1, 4, 0, 4, 0, 3, 6, 1, 2, 4, 5, 0],
    [2033, 2, 5, 1, 5, 2, 5, 0, 2, 3, 5, 6, 1],
    [2034, 3, 6, 3, 6, 3, 6, 1, 3, 5, 6, 0, 2],
    [2035, 5, 0, 4, 0, 4, 0, 3, 4, 6, 1, 2, 3],
    [2036, 6, 2, 5, 2, 5, 1, 4, 6, 0, 2, 3, 5],
    [2037, 0, 3, 6, 3, 0, 3, 5, 0, 1, 3, 4, 6],
    [2038, 1, 4, 1, 4, 1, 4, 6, 1, 3, 4, 5, 0],
    [2039, 3, 6, 2, 5, 2, 5, 1, 2, 4, 6, 0, 2],
    [2040, 4, 0, 3, 0, 3, 6, 2, 4, 5, 0, 1, 3],
    [2041, 5, 1, 4, 1, 5, 1, 3, 5, 6, 1, 2, 4],
    [2042, 6, 2, 6, 2, 6, 2, 4, 6, 1, 2, 3, 5],
    [2043, 1, 4, 0, 3, 0, 3, 6, 0, 2, 4, 5, 0],
    [2044, 2, 5, 1, 5, 1, 4, 0, 2, 3, 5, 6, 1],
    [2045, 3, 6, 3, 6, 3, 6, 1, 3, 4, 6, 0, 2],
    [2046, 4, 0, 4, 0, 4, 0, 2, 4, 6, 0, 1, 3],
    [2047, 6, 2, 5, 1, 5, 1, 4, 6, 0, 2, 3, 5],
    [2048, 0, 3, 6, 3, 6, 2, 5, 0, 1, 3, 4, 6],
    [2049, 1, 4, 1, 4, 1, 4, 6, 1, 3, 4, 5, 0],
    [2050, 2, 5, 2, 5, 2, 5, 0, 2, 4, 5, 0, 1],
    [2051, 4, 0, 3, 6, 3, 6, 2, 4, 5, 0, 1, 3],
    [2052, 5, 1, 4, 1, 4, 0, 3, 5, 6, 1, 2, 4],
    [2053, 6, 2, 6, 2, 6, 2, 4, 6, 1, 2, 3, 5],
    [2054, 0, 3, 0, 3, 0, 3, 5, 0, 2, 3, 5, 6],
    [2055, 2, 5, 1, 5, 1, 4, 0, 2, 3, 5, 6, 1],
    [2056, 3, 6, 2, 6, 2, 6, 1, 3, 4, 6, 0, 2],
    [2057, 4, 0, 4, 0, 4, 0, 2, 4, 6, 0, 1, 3],
    [2058, 6, 1, 5, 1, 5, 1, 3, 5, 0, 1, 3, 4],
    [2059, 0, 3, 6, 3, 6, 2, 5, 0, 1, 3, 4, 6],
    [2060, 1, 4, 0, 4, 1, 4, 6, 1, 2, 4, 5, 0],
    [2061, 2, 5, 2, 5, 2, 5, 0, 2, 4, 5, 6, 1],
    [2062, 4, 6, 3, 6, 3, 6, 2, 3, 5, 6, 1, 2],
    [2063, 5, 1, 4, 1, 4, 0, 3, 5, 6, 1, 2, 4],
    [2064, 6, 2, 5, 2, 6, 2, 4, 6, 0, 2, 3, 5],
    [2065, 0, 3, 0, 3, 0, 3, 5, 0, 2, 3, 4, 6],
    [2066, 2, 5, 1, 4, 1, 4, 0, 1, 3, 5, 6, 0],
    [2067, 3, 6, 2, 6, 2, 5, 1, 3, 4, 6, 0, 2],
    [2068, 4, 0, 3, 0, 4, 0, 2, 4, 5, 0, 1, 3],
    [2069, 5, 1, 5, 1, 5, 1, 3, 5, 0, 1, 2, 4],
    [2070, 0, 3, 6, 2, 6, 2, 5, 6, 1, 3, 4, 6],
    [2071, 1, 4, 0, 4, 0, 3, 6, 1, 2, 4, 5, 0],
    [2072, 2, 5, 2, 5, 2, 5, 0, 2, 3, 5, 6, 1],
    [2073, 3, 6, 3, 6, 3, 6, 1, 3, 5, 6, 0, 2],
    [2074, 5, 1, 4, 0, 4, 0, 3, 5, 6, 1, 2, 4],
    [2075, 6, 2, 5, 2, 5, 1, 4, 6, 0, 2, 3, 5],
    [2076, 0, 3, 0, 3, 0, 3, 5, 0, 2, 3, 4, 6],
    [2077, 1, 4, 1, 4, 1, 4, 6, 1, 3, 4, 6, 0],
    [2078, 3, 6, 2, 5, 2, 5, 1, 3, 4, 6, 0, 2],
    [2079, 4, 0, 3, 0, 3, 6, 2, 4, 5, 0, 1, 3],
    [2080, 5, 1, 5, 1, 5, 1, 3, 5, 0, 1, 2, 4],
    [2081, 6, 2, 6, 2, 6, 2, 4, 6, 1, 2, 4, 6],
    [2082, 1, 3, 0, 3, 0, 3, 5, 0, 2, 3, 5, 0],
    [2083, 2, 5, 1, 5, 1, 4, 6, 1, 3, 4, 6, 1],
    [2084, 3, 6, 2, 6, 2, 5, 0, 2, 4, 5, 0, 2],
    [2085, 4, 0, 4, 0, 4, 6, 2, 4, 6, 0, 2, 4],
    [2086, 6, 1, 5, 1, 5, 1, 3, 5, 0, 1, 3, 5],
    [2087, 0, 3, 6, 3, 6, 2, 5, 0, 2, 3, 5, 0],
    [2088, 2, 4, 0, 4, 1, 3, 6, 1, 3, 4, 6, 1],
    [2089, 3, 5, 2, 5, 2, 5, 0, 2, 4, 5, 0, 2],
    [2090, 4, 6, 3, 6, 3, 6, 1, 3, 5, 6, 1, 3],
    [2091, 5, 1, 4, 1, 4, 0, 2, 4, 6, 0, 2, 4],
    [2092, 6, 2, 5, 2, 5, 1, 4, 6, 0, 2, 3, 5],
    [2093, 0, 3, 0, 3, 6, 2, 5, 0, 1, 3, 4, 6],
    [2094, 2, 5, 1, 4, 1, 4, 6, 1, 3, 4, 6, 1],
    [2095, 3, 6, 2, 6, 2, 5, 0, 2, 4, 5, 0, 2],
    [2096, 4, 0, 3, 0, 3, 6, 2, 4, 5, 0, 1, 3],
    [2097, 5, 1, 5, 0, 3, 6, 2, 4, 6, 0, 2, 3],
    [2098, 0, 3, 6, 2, 6, 2, 4, 6, 1, 2, 4, 6],
    [2099, 1, 4, 0, 4, 0, 3, 5, 0, 2, 3, 5, 0],
    [2100, 2, 5, 1, 5, 1, 4, 0, 2, 3, 5, 6, 1],
    [2101, 3, 6, 3, 6, 2, 5, 1, 3, 5, 6, 1, 2],
    [2102, 5, 1, 4, 0, 4, 0, 2, 4, 6, 0, 2, 4],
    [2103, 6, 2, 5, 2, 5, 1, 3, 5, 0, 2, 3, 5],
    [2104, 0, 3, 6, 3, 6, 2, 5, 0, 1, 3, 4, 6],
    [2105, 2, 4, 1, 4, 0, 3, 6, 1, 3, 4, 6, 0],
    [2106, 3, 6, 2, 5, 2, 5, 0, 2, 4, 5, 0, 2],
    [2107, 4, 0, 3, 0, 3, 6, 1, 3, 5, 0, 1, 3],
    [2108, 5, 1, 4, 1, 4, 0, 3, 5, 6, 1, 2, 4],
    [2109, 0, 2, 6, 2, 5, 1, 4, 6, 1, 2, 4, 5],
    [2110, 1, 4, 0, 3, 0, 3, 5, 0, 2, 3, 5, 0],
  ];

  const day = monthsGap.find(item => item[0] === year);
  return day;
};

export const getMonthsDays = year => {
  var months = [
    [1970, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [1971, 31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30],
    [1972, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
    [1973, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [1974, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [1975, 31, 31, 32, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    [1976, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [1977, 30, 32, 31, 32, 31, 31, 29, 30, 29, 30, 29, 31],
    [1978, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [1979, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [1980, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [1981, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
    [1982, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [1983, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [1984, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [1985, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
    [1986, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [1987, 31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [1988, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [1989, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    [1990, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [1991, 31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [1992, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [1993, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    [1994, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [1995, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
    [1996, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [1997, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [1998, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [1999, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [2000, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [2001, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2002, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [2003, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [2004, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [2005, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2006, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [2007, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [2008, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31],
    [2009, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2010, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [2011, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [2012, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
    [2013, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2014, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [2015, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [2016, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
    [2017, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2018, 31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [2019, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [2020, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    [2021, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2022, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
    [2023, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [2024, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    [2025, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2026, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [2027, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [2028, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2029, 31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30],
    [2030, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [2031, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [2032, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2033, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [2034, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [2035, 30, 32, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31],
    [2036, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2037, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [2038, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [2039, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
    [2040, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2041, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [2042, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [2043, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
    [2044, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2045, 31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [2046, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [2047, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    [2048, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2049, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
    [2050, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [2051, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    [2052, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2053, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
    [2054, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [2055, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2056, 31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30],
    [2057, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [2058, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [2059, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2060, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [2061, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [2062, 30, 32, 31, 32, 31, 31, 29, 30, 29, 30, 29, 31],
    [2063, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2064, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [2065, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [2066, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31],
    [2067, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2068, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [2069, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [2070, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
    [2071, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2072, 31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [2073, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [2074, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    [2075, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2076, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
    [2077, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [2078, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    [2079, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2080, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
    [2081, 31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [2082, 31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    [2083, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2084, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [2085, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [2086, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2087, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [2088, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [2089, 30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [2090, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2091, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [2092, 31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [2093, 31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31],
    [2094, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2095, 31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [2096, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2097, 31, 32, 30, 31, 31, 31, 30, 30, 29, 30, 29, 31],
    [2098, 31, 31, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
    [2099, 31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30],
    [2100, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [2101, 31, 32, 31, 31, 31, 31, 30, 30, 29, 30, 29, 31],
    [2102, 31, 31, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
    [2103, 31, 31, 32, 31, 31, 30, 30, 30, 30, 29, 30, 30],
    [2104, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 31],
    [2105, 30, 32, 31, 31, 31, 31, 30, 30, 29, 30, 29, 31],
    [2106, 31, 31, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
    [2107, 31, 31, 32, 31, 31, 30, 30, 30, 30, 29, 30, 30],
    [2108, 31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 31],
    [2109, 30, 32, 31, 31, 31, 31, 30, 30, 29, 30, 29, 31],
    [2110, 31, 31, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
  ];

  let month = months.find((item: any) => parseInt(item[0]) === parseInt(year));
  return month;
};
