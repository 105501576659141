import {
  GET_DISCIPLINARY_STAGES,
  EDIT_DISCIPLINARY_STAGES,
  DELETE_DISCIPLINARY_SUBSTAGES,
  ADD_DISCIPLINARY_SUBSTAGES,
  EDIT_DISCIPLINARY_SUB_STAGES,
  DISCIPLINARY_STAGES_LOADING
} from "actions/types";
import isEmpty from "helpers/isEmpty";

const initialState = {
  disciplinaryStages: [],
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case DISCIPLINARY_STAGES_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case GET_DISCIPLINARY_STAGES: {
      return {
        ...state,
        disciplinaryStages: action.payload,
        loading: false
      };
    }
    case EDIT_DISCIPLINARY_STAGES: {
      return {
        ...state,
        disciplinaryStages: state.disciplinaryStages.map(item => {
          item.StageName =
            item.ActionStageId === action.payload.ActionStageId
              ? action.payload.StageName
              : item.StageName;
          return item;
        })
      };
    }

    case EDIT_DISCIPLINARY_SUB_STAGES:{
      return {

      }
    }
    case DELETE_DISCIPLINARY_SUBSTAGES: {
      return {
        ...state,
        disciplinaryStages: state.disciplinaryStages.map(item => {
          item.SubStages = item.SubStages.filter(
            sub => sub.ActionSubStageId !== action.payload
          );
          return item;
        })
      };
    }
    case ADD_DISCIPLINARY_SUBSTAGES: {
      return {
        ...state,
        disciplinaryStages: state.disciplinaryStages.map(item => {
          if (item.ActionStageId === action.payload.DisciplinaryActionStageRefId) {
            let a = item.SubStages;
            if (isEmpty(a)) {
              item.SubStages = [action.payload];
              return item;
            }
            let b = a.find(sub => sub.ActionSubStageId === action.payload.ActionSubStageId);
         
            if (!isEmpty(b)){
              item.SubStages = a.map(sub => {
                if (sub.ActionSubStageId === action.payload.ActionSubStageId) {
                  sub.SubStageName = action.payload.SubStageName;
                  return sub;
                }
                return sub;
              });
              return item;
            }
            item.SubStages = [...item.SubStages, action.payload];
            return item;
          }
          return item;
        })
      };
    }
    default:
      return state;
  }
}
