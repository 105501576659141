import {
  DELETE_EDUCATION,
  DELETE_KSA_LIBRARIES_LOADING,
  GET_JOBPROFILE_KSA_EDUCATION_LIST,
  GET_JOBPROFILE_KSA_CERTIFICATION_LIST,
  GET_JOBPROFILE_KSA_SKILL_TYPE,
  GET_JOBPROFILE_KSA_BY_TYPE,
  GET_JOBPROFILE_KSA_EDUCATION_LEVEL,
  GET_JOBPROFILE_KSA_EDUCATION_TYPE,
  GET_JOBPROFILE_KSA_CERTIFICATION_TYPE,
  GET_JOBPROFILE_KSA_EDUCATION_BY_ID,
  GET_JOBPROFILE_KSA_CERTIFICATION_BY_ID,
  GET_JOBPROFILE_KSA__BY_ID,
  DELETE_CERTIFICATION,
  DELETE_KSA_LIST_BY_TYPE,
  DELETE_SKILL,
  DELETE_ABILITY,
} from "actions/types";

const initialState = {
  deleteKSALibrariesLoading: false,
  educationList: null,
  educationById: null,
  educationLevels: [],
  educationTypes: [],
  certificationList: null,
  certificationById: null,
  certificationTypes: [],
  ksaType: 1,
  ksaSkillType: [],
  ksaListByType: null,
  ksaDataById: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_JOBPROFILE_KSA_EDUCATION_LIST:
      return {
        ...state,
        educationList: action.payload,
      };
    case GET_JOBPROFILE_KSA_EDUCATION_LEVEL:
      return {
        ...state,
        educationLevels: action.payload,
      };
    case GET_JOBPROFILE_KSA_EDUCATION_TYPE:
      return {
        ...state,
        educationTypes: action.payload,
      };
    case GET_JOBPROFILE_KSA_EDUCATION_BY_ID:
      return {
        ...state,
        educationById: action.payload,
      };

    case GET_JOBPROFILE_KSA_CERTIFICATION_LIST:
      return {
        ...state,
        certificationList: action.payload,
      };
    case GET_JOBPROFILE_KSA_CERTIFICATION_BY_ID:
      return {
        ...state,
        certificationById: action.payload,
      };

    case GET_JOBPROFILE_KSA_CERTIFICATION_TYPE:
      return {
        ...state,
        certificationTypes: action.payload,
      };

    case GET_JOBPROFILE_KSA_SKILL_TYPE:
      return {
        ...state,
        ksaSkillType: action.payload,
      };

    case GET_JOBPROFILE_KSA_BY_TYPE:
      return {
        ...state,
        ksaType: action.ksaType,
        ksaListByType: action.payload,
      };

    case GET_JOBPROFILE_KSA__BY_ID:
      return {
        ...state,
        ksaDataById: action.payload,
      };
    case DELETE_EDUCATION:
      return {
        ...state,
        deleteKSALibrariesLoading: false,
        educationList: state.educationList.filter(
          (item) => item.EducationId !== action.payload
        ),
      };
    case DELETE_CERTIFICATION:
      return {
        ...state,
        deleteKSALibrariesLoading: false,
        certificationList: state.certificationList.filter(
          (item) => item.CertificationId !== action.payload
        ),
      };
    case DELETE_KSA_LIST_BY_TYPE:
      return {
        ...state,
        deleteKSALibrariesLoading: false,
        ksaListByType: state.ksaListByType.filter(
          (item) => item.KSAId !== action.payload
        ),
      };
    case DELETE_SKILL:
      return {
        ...state,
        deleteKSALibrariesLoading: false,
        ksaListByType: state.ksaListByType.filter(
          (item) => item.KSAId !== action.payload
        ),
      };
    case DELETE_ABILITY:
      return {
        ...state,
        deleteKSALibrariesLoading: false,
        ksaListByType: state.ksaListByType.filter(
          (item) => item.KSAId !== action.payload
        ),
      };
    case DELETE_KSA_LIBRARIES_LOADING:
      return {
        ...state,
        deleteKSALibrariesLoading: action.payload,
      };
    default:
      return state;
  }
}
