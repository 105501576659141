import { createSlice } from "@reduxjs/toolkit";
import * as fromInterface from "../../../ts";

export interface CurrentFormState {
    detail: fromInterface.PerformanceForm | null,
    status: fromInterface.loadingType;
    loaded: boolean;
}

const initialState: CurrentFormState = {
    detail: null,
    status: fromInterface.STATUS.IDLE,
    loaded: false,
};

const currentFormSlice = createSlice({
    name: "current configuration form",
    initialState,
    reducers: {
        updateFormDetailRequest: (state) => {
            state.status = fromInterface.STATUS.LOADING;
        },
        updateFormDetail: (state, action) => {
            const payload = action.payload

            state.detail = payload;
            state.status = fromInterface.STATUS.IDLE;
            state.loaded = true

        },
        clear: (state) => {
            state.detail = {}
            state.status = fromInterface.STATUS.IDLE
            state.loaded = false
        },
    },
});

export const {
    updateFormDetailRequest,
    updateFormDetail,
    clear,
} = currentFormSlice.actions

export default currentFormSlice.reducer;