import React, { FunctionComponent, useMemo } from 'react';
import './NepaliDatePicker.scss';
import { ConfigProvider } from './Config';
import NepaliDatePicker from './NepaliDatePicker';
import {
  ENGLISH,
  INepaliDatePicker,
  NEPALI,
  NepaliDatePickerProps,
} from './Types';

const NepaliDatePickerWrapper: FunctionComponent<NepaliDatePickerProps> = (
  props
) => {
  const calenderOptions = useMemo(
    () => ({
      closeOnSelect: true,
      calenderLocale: NEPALI,
      valueLocale: ENGLISH,
      ...props.options,
    }),
    [props.options]
  );

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <ConfigProvider>
      <NepaliDatePicker
        {...({ ...props, options: calenderOptions } as INepaliDatePicker)}
      />
    </ConfigProvider>
  );
};

NepaliDatePickerWrapper.defaultProps = {
  className: '',
  inputClassName: '',
  value: '',
  onChange: () => null,
  onSelect: () => null,
  options: {},
  disableDateAfter: "",
  disableDateBefore: "",
};

export default NepaliDatePickerWrapper;
