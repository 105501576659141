import { Box, Heading } from "@chakra-ui/react";
import { ConnectForm, Input, ModalForm } from "@rigotech/ui";
import React, { useEffect, useState } from "react";

interface paramType {
  note: string;
}

interface CheckoutBreakOutModalProps {
  attendanceAuthority: any;
  handleClose: () => {};
  isLunchInTimeExist: boolean;
  isOpen: boolean;
  saveAttendanceNote: (isCheckIn: boolean, param: paramType) => {};
  saveAttendanceTime: (isCheckIn: boolean) => {};
  saveLunchInNote: (isBreakIn: boolean, param: paramType) => {};
  saveLunchTime: (isBreakIn: boolean) => {};
}

const CheckoutBreakout = ({
  attendanceAuthority,
  handleClose,
  isLunchInTimeExist,
  isOpen,
  saveAttendanceNote,
  saveAttendanceTime,
  saveLunchInNote,
  saveLunchTime,
}: CheckoutBreakOutModalProps) => {
  const [attendanceOptions, setAttendanceOptions] = useState([
    {
      label: "Check Out",
      value: "checkout",
    },
  ]);

  useEffect(() => {
    if (attendanceAuthority?.IsLunchInOutEnabled && !isLunchInTimeExist) {
      setAttendanceOptions((prevVal) => [
        ...prevVal,
        { label: "Break Out", value: "breakout" },
      ]);
    }
  }, [attendanceAuthority, isLunchInTimeExist]);

  const handleSaveAttendanceNote = async (param) => {
    const { attendanceValue, note } = param;
    if (attendanceValue === "checkout") {
      await saveAttendanceNote(false, note);
    } else if (attendanceValue === "breakout") {
      await saveLunchInNote(true, note);
    }
  };

  const handleSaveAttendance = (attendanceValue: string) => {
    if (attendanceValue === "checkout") {
      saveAttendanceTime(false);
    } else if (attendanceValue === "breakout") {
      saveLunchTime(true);
    }
  };

  const handleSubmit = async (data: any) => {
    const attendanceValue = data?.attendance?.value;
    if (data?.note) {
      const note = { note: data?.note };
      await handleSaveAttendanceNote({ attendanceValue, note });
    }
    handleSaveAttendance(attendanceValue);
  };

  return (
    <ModalForm
      isCentered
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={handleSubmit}
      submitButtonLabel="Submit"
      renderHeader={() => {
        return (
          <Box>
            <Heading color="#121212" fontSize="24px" fontWeight="500">
              Select an option
            </Heading>
          </Box>
        );
      }}
    >
      <ConnectForm>
        {(formProps: any) => {
          const {
            control,
            formState: { errors },
          } = formProps;

          const inputProps = {
            control,
            errors,
          };

          return (
            <Box>
              <Input.RadioGroupV2
                direction="column"
                options={attendanceOptions}
                name="attendance"
                {...inputProps}
                required
              />
              <Input.TextArea label="Note" name="note" {...inputProps} />
            </Box>
          );
        }}
      </ConnectForm>
    </ModalForm>
  );
};

export default CheckoutBreakout;
