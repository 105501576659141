import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

class index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeInString: null,
      timeInMs: 0,
    };
    this.startTimer = this.startTimer.bind(this);
  }

  componentDidMount() {
    this.startTimer();
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  componentDidUpdate() {
    const { start, isSaveTickerTime } = this.props;
    if (start) {
      this.startTimer();
    } else {
      this.stopTimer();
      const { tickerStartTime, availableTimeInMS } = this.props;

      if (!this.state.isForceStopped) {
        this.calculateTimerTime(
          new Date(),
          tickerStartTime,
          availableTimeInMS,
          true
        );
      }
    }

    if (isSaveTickerTime) {
      const { timeInMs } = this.state;
      this.props.getTickerTime && this.props.getTickerTime(timeInMs);
    }
  }

  startTimer() {
    clearInterval(this.timer);
    this.timer = setInterval(this.tick.bind(this), 1000);
  }

  stopTimer() {
    clearInterval(this.timer);
  }

  calculateTimerTime = (
    now,
    then,
    miliSecondsToAdd = 0,
    isForceStopped = false
  ) => {
    let time = 0;
    let ms = moment(now, "DD/MM/YYYY HH:mm:ss").diff(
      moment(then, "DD/MM/YYYY HH:mm:ss")
    );

    if (miliSecondsToAdd) {
      ms = ms + miliSecondsToAdd;
    }

    let d = moment.duration(ms);
    time = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");

    this.setState({
      timeInString: time,
      timeInMs: ms,
      isForceStopped: isForceStopped,
    });

    this.props.getTimeText && this.props.getTimeText(time);
  };

  tick() {
    const { start, tickerStartTime, availableTimeInMS } = this.props;
    this.calculateTimerTime(new Date(), tickerStartTime, availableTimeInMS);
  }

  render() {
    return <p>{this.state.timeInString}</p>;
  }
}

index.propTypes = {
  start: PropTypes.bool,
  availableTimeInMS: PropTypes.string,
  tickerStartTime: PropTypes.any,
  isSaveTickerTime: PropTypes.bool,
};

index.defaultProps = {
  start: true,
  availableTimeInMS: null,
  isSaveTickerTime: false,
  tickerStartTime: new Date(),
};

export default index;
