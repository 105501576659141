import React, { useCallback } from "react";

//CHAKRA
import {
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  TabsProps,
  Box,
  Text,
} from "@chakra-ui/react";

//SCSS
import "./stepper.scss";

//ICONS
import { BsCheckLg } from "react-icons/bs";

//INTERFACE
export interface IRigoStepperData {
  label: string;
  content: JSX.Element;
}

interface IRigoStepperProps extends Omit<TabsProps, "children"> {
  data: IRigoStepperData[];
  onChange: (index: number) => void;
}

export const RigoStepper = (props: IRigoStepperProps) => {
  const { data, isLazy, align, size, onChange, index: activeIndex } = props;

  const isActive = useCallback(
    (tabIndex: number) => {
      return activeIndex === tabIndex;
    },
    [activeIndex]
  );

  const isCompleted = useCallback(
    (tabIndex: number) => {
      return Number(activeIndex) > tabIndex;
    },
    [activeIndex]
  );

  return (
    <Box className="rigo-stepper">
      <Tabs
        {...{ align, isLazy, size, onChange }}
        index={activeIndex}
        isFitted
        border="none"
      >
        <TabList
          bgColor="#fff"
          border="none"
          className="stepper"
          alignItems="flex-start"
        >
          {data.map((tab, tabIndex) => (
            <Tab
              key={tabIndex}
              isDisabled={tabIndex > Number(activeIndex)}
              padding="0"
              border="none"
              color="gray.500"
              className={`stepper-tab  ${
                isActive(tabIndex)
                  ? "isactive"
                  : isCompleted(tabIndex)
                  ? "iscompleted"
                  : ""
              }`}
            >
              <Box textAlign="center" className="stepper-list">
                <Box
                  position="relative"
                  zIndex={1}
                  className="stepper-icon"
                  margin="0 auto"
                  width="35px"
                  height="35px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="50%"
                  mb="4px"
                >
                  {isCompleted(tabIndex) ? (
                    <BsCheckLg fill="#fff" size="12px" fontSize="10px" />
                  ) : (
                    <Box color="#fff">{tabIndex + 1}</Box>
                  )}
                </Box>
                <Text
                  fontSize="14px"
                  fontWeight="500"
                  className="stepper-title"
                >
                  {tab.label}
                </Text>
              </Box>
            </Tab>
          ))}
        </TabList>
        <TabPanels padding="40px 0 0 ">
          {data.map((tab, index) => (
            <TabPanel key={index} p="0">
              {tab.content}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Box>
  );
};

RigoStepper.defaultProps = {
  isLazy: false,
  align: "left",
  size: "sm",
};

export default RigoStepper;
