// @ts-nocheck 
import React from "react";
import { toastMessage } from "../actions/validateAction";
import isEmpty from "./isEmpty";

export function dispatchMessage(dispatch: Function, error: any) {
  let response: any = {};
  response.MessageType = error.MessageType || error.messageType || "Warning";
  response.Message = error.Message || error.message || error;
  if (!isEmpty(response.Message))
    dispatch && toastMessage(dispatch, response);
}
