// LIBS
import { combineReducers } from 'redux';

// SLICES
import employeeDataChangeRequestsFilter from './admin/employee-data-change-requests-filter/slice';
import employeeDataChangeRequests from './admin/employee-data-change-requests/slice';

import { appraisalSlices } from './appraisal/slices';
import { geoFencingSlices } from './geo-fencing/slices';

import personalInfo from './personal-info/slice';
import uiLoader from './ui-loader/slice';

// ROLE
import directManager from './roles/managers/slice';
import { leaveTimeSlices } from './leave-time/slices';

//SHIFT ROSTER
import { rosterSlice } from './shift-roster/slice';

export default combineReducers({
  ...appraisalSlices,
  ...geoFencingSlices,
  uiLoader,
  personalInfo,
  employeeDataChangeRequests,
  employeeDataChangeRequestsFilter,
  directManager,
  ...leaveTimeSlices,
  ...rosterSlice
});
