// @ts-nocheck 
import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import { FaTimesCircle, FaExclamationCircle } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { IoMdClose } from 'react-icons/io';
import PropTypes from 'prop-types';
import isEmpty from 'helpers/isEmpty';
import classnames from 'classnames';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './index.style.scss';
// import useLockBodyScreen from 'helpers/customHooks/useLockBodyScroll';
import classNames from 'classnames';

let modalEl = document.getElementsByClassName('modal');
interface ModalInter {
  open?: boolean;
  onModalClose: Function;
  type: string;
  title?: string;
  width?: string;
  isPrimary?: boolean;
  className?: string;
  noCloseButton?: boolean;
  func?: Function;
  modalMessage?: string;
  // content?:React.ReactNode,
  // children?:React.ReactNode
  content?: any;
  children?: any;
  onStateClose?: Function;
  okButtonTitle?: string;
  isOkButtonPrimary?: boolean;
}

class Mode extends Component<ModalInter> {
  public modalTarget: any;
  state = {
    open: false,
    noCloseButton: false,
  };
  componentDidMount = () => {
    this.setState({ open: this.props.open });
  };
  componentWillUnmount() {
    // ReactDOM.unmountComponentAtNode(this.modalTarget);
    // document.body.removeChild(this.modalTarget);
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.open !== prevProps.open) {
      this.setState({ open: this.props.open });
    }
  }
  onClose = () => {
    this.setState({ open: false });
  };
  switchComponent() {
    const {
      func,
      onModalClose,
      type,
      title,
      content,
      noCloseButton,
      modalMessage,
      okButtonTitle,
      isOkButtonPrimary,
    } = this.props;
    switch (type) {
      case 'confirm':
        return (
          <Confirm
            onModalClose={onModalClose}
            func={func}
            open={this.props.open}
            confirmMessage={modalMessage}
            okButtonTitle={okButtonTitle}
            title={title}
            isOkButtonPrimary={isOkButtonPrimary}
          />
        );
      case 'success':
        return <Success onModalClose={onModalClose} open={this.props.open} />;
      case 'danger':
        return <Danger onModalClose={onModalClose} open={this.props.open} />;
      case 'dangerContent':
        return (
          <DangerWithContent
            onModalClose={onModalClose}
            title={title}
            content={content}
            noCloseButton={noCloseButton}
            open={this.props.open}
          />
        );
      default:
        return <DefaultModal onStateClose={this.onClose} {...this.props} />;
    }
  }

  render() {
    const dark = localStorage.getItem('dark');
    // const isDark = "dark" in localStorage;
    // const savedMode = JSON.parse(localStorage.getItem("dark"));
    const modalClasses = classnames({
      modal: true,
      [this.props.className]: !isEmpty(this.props.className),
      'primary-modal': this.props.isPrimary,
      // "dark-mode": isDark && savedMode,
    });
    return createPortal(
      <CSSTransition
        in={this.state.open}
        // key={item.module_name}
        timeout={300}
        classNames='modalOpen'
        unmountOnExit
      >
        <div className={modalClasses}>{this.switchComponent()}</div>
      </CSSTransition>,
      document.body
      // document.getElementById('#mode')
    );
  }
}

const DefaultModal = ({
  width,
  title,
  onModalClose,
  content,
  children,
  className,
  onStateClose,
  open,
}: ModalInter) => {
  // const closeModal = (event) => {
  //   let parent = document.getElementsByClassName(className)[0];
  //   let el = parent.querySelector(".modal__content");
  //   document
  //     .getElementsByClassName("modal")[0]
  //     .classList.toggle("bg-transparent");
  //   el.classList.toggle("fade-out");

  //
  // setTimeout(() => {
  //   onModalClose(event);
  //   el.classList.toggle("fade-out");
  // }, 250);
  // };
  const closeModal = (event) => {
    onStateClose();
    setTimeout(() => {
      onModalClose(event);
    }, 300);
    // onModalClose(event);
  };

  // useLockBodyScreen(open, modalEl);
  return (
    <div className='modal__content' style={!isEmpty(width) ? { width } : {}}>
      {!isEmpty(title) ? (
        <div className='modal__header'>
          <div className='modal__heading'>
            <h2>{title}</h2>
          </div>
          <div
            className='modal__header-close'
            onClick={(event) => closeModal(event)}
          >
            {/* <MdClose /> */}
            <IoMdClose className='modalclose' />
          </div>
        </div>
      ) : (
        <div
          className='modal__header-close modal__header-close-noheader'
          onClick={(event) => closeModal(event)}
        >
          <IoMdClose className='modalclose' />
        </div>
      )}
      <div className='modal__content-body'>
        {React.cloneElement(content ? content : children)}
        {/* {content ? content : children} */}
      </div>
    </div>
  );
};

const Confirm1 = ({ func, onModalClose, open, confirmMessage }) => {
  // useLockBodyScreen(open, modalEl);
  return (
    <div className='modal__confrim confirm'>
      <div className='confirm-icon'>
        <FaExclamationCircle />
      </div>
      <h2 className='confirm-title'>Are you Sure?</h2>
      {confirmMessage ? (
        <p>{confirmMessage}</p>
      ) : (
        <p>Are you sure? You won't be able to revert your action</p>
      )}
      <div className='confirm-buttons'>
        <button
          className='confirm-buttons__confirm'
          onClick={(e) => {
            func();
            onModalClose(e);
          }}
        >
          <span>Yes, I am Sure</span>
        </button>
        <button
          className='confirm-buttons__cancel'
          onClick={(e) => onModalClose(e)}
        >
          <span>Cancel</span>
        </button>
      </div>
    </div>
  );
};

const Confirm = ({
  func,
  onModalClose,
  open,
  confirmMessage,
  title,
  okButtonTitle,
  isOkButtonPrimary = false,
}) => {
  // useLockBodyScreen(open, modalEl);

  const message = confirmMessage
    ? confirmMessage
    : "Are you sure? You won't be able to revert your action";

  return (
    <div className='modal__confrim confirm'>
      <div className='modal__title'>
        <div>{title ? title : 'Delete'}</div>
        <div className='remove-icon' onClick={(e) => onModalClose(e)}>
          <MdClose />
        </div>
      </div>
      <p
        className='modal__message'
        dangerouslySetInnerHTML={{ __html: message }}
      ></p>
      <div className='modal__footer'>
        <button
          className='button button-secondary-dark'
          onClick={(e) => onModalClose(e)}
        >
          <span>Cancel</span>
        </button>
        <button
          className={classNames('button ', {
            'button-primary': isOkButtonPrimary,
            'button-danger': !isOkButtonPrimary,
          })}
          onClick={(e) => {
            func();
            onModalClose(e);
          }}
        >
          <span>{okButtonTitle ? okButtonTitle : 'Ok'}</span>
        </button>
      </div>
    </div>
  );
};
const Success = ({ onModalClose, open }) => {
  // useLockBodyScreen(open, modalEl);
  return (
    <div className='modal__confrim confirm success'>
      <div className='confirm-icon'>
        <i className='material-icons'>check_circle</i>
      </div>
      <h2 className='confirm-title'>Success</h2>
      <p>Your Current Action was a success</p>
      <div className='confirm-buttons'>
        <button
          className='btn btn--success'
          style={{ borderRadius: '12rem' }}
          onClick={(e) => onModalClose(e)}
        >
          Done
        </button>
      </div>
    </div>
  );
};

const Danger = ({ onModalClose, open }) => {
  // useLockBodyScreen(open, modalEl);
  return (
    <div className='modal__confrim confirm danger'>
      <div className='confirm-icon'>
        <FaTimesCircle />
      </div>
      <h2 className='confirm-title'>Error</h2>
      <p>
        Something Bad Occured
        <span>Please Try again Later</span>
      </p>
      <div className='confirm-buttons'>
        <button
          className='btn btn--danger'
          style={{ borderRadius: '12rem' }}
          onClick={(e) => onModalClose(e)}
        >
          Done
        </button>
      </div>
    </div>
  );
};

const DangerWithContent = ({
  onModalClose,
  title,
  content,
  open,
  noCloseButton,
}) => {
  // useLockBodyScreen(open, modalEl);
  return (
    <div className='modal__confrim confirm danger'>
      <div className='confirm-icon'>
        <FaTimesCircle />
      </div>
      <h2 className='confirm-title'>{title}</h2>
      <div className='confirm-content'>{content}</div>
      {noCloseButton ? null : (
        <div className='confirm-buttons'>
          <button
            className='btn btn-red'
            style={{ borderRadius: '12rem' }}
            onClick={(e) => onModalClose(e)}
          >
            Close
          </button>
        </div>
      )}
    </div>
  );
};

export class Modal extends Component<ModalInter> {
  public static defaultProps = {
    open: false,
    type: 'normal',
  };
  render() {
    const { open, ...props } = this.props;
    return <Mode {...this.props} />;
  }
}

export default Modal;
