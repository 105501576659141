import React from "react";

import { flexRender } from "@tanstack/react-table";
import { Box } from "@chakra-ui/react";

//icons
import { VscTriangleUp, VscTriangleDown } from "react-icons/vsc";

//CONSTANT
import { FONT_SIZE, PADDING_SIZE } from "../constants";

//INTERFACE
import { ITableChildrenComponentProps, IAdditionalProps } from "../ts";
import { GLOBAL_TABLE_HEADER_BG } from "./globalStyleConstant";

const TableHeaderContent = (props: ITableChildrenComponentProps) => {
  const {
    table,
    filteredColumns,
    scrollRight,
    stickyWidth,
    calculateStickyWidth,
  } = props;

  return (
    <>
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => {
              let additionalProps: IAdditionalProps = {
                isSticky: false,
                isLastSticky: false,
                textAlign: "left",
              };

              for (const column of filteredColumns) {
                if (column.accessorKey === header.id) {
                  if (column.textAlign)
                    additionalProps.textAlign = column.textAlign;
                  if (column.isSticky) {
                    additionalProps = { ...additionalProps, isSticky: true };
                    if (column.isLastSticky) {
                      additionalProps = {
                        ...additionalProps,
                        isLastSticky: true,
                      };
                    }
                    calculateStickyWidth(header.index, header.getSize());
                  }

                  break;
                }
              }

              return (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  className={`${
                    additionalProps.isSticky
                      ? scrollRight > 0
                        ? "sticky scrolled"
                        : "sticky"
                      : ""
                  } ${additionalProps.isLastSticky ? "last" : ""}`}
                  {...{
                    style: {
                      background: GLOBAL_TABLE_HEADER_BG,
                      width: header.getSize(),
                      padding: `${PADDING_SIZE.Y[props.tableSize || "md"]} ${
                        PADDING_SIZE.X[props.tableSize || "md"]
                      }`,
                      fontSize: FONT_SIZE[props.tableSize || "md"],
                      fontWeight: 500,
                      minWidth: header.getSize(),
                      maxWidth: header.getSize(),
                      textAlign: additionalProps.textAlign,
                      position: additionalProps.isSticky
                        ? "sticky"
                        : "relative",
                      left: additionalProps.isSticky
                        ? stickyWidth[header.index]
                        : "0",
                    },
                  }}
                >
                  {header.isPlaceholder ? null : (
                    <>
                      <Box
                        display='flex'
                        alignItems='center'
                        justifyContent={additionalProps.textAlign}
                        {...{
                          className: header.column.getCanSort()
                            ? "cursor-pointer select-none"
                            : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        <>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {{
                            asc: <VscTriangleUp />,
                            desc: <VscTriangleDown />,
                          }[header.column.getIsSorted() as string] ?? null}
                        </>
                      </Box>

                      <div
                        {...{
                          onMouseDown: header.getResizeHandler(),
                          onTouchStart: header.getResizeHandler(),

                          className: `resizable ${
                            header.column.getIsResizing() ? "isResizing" : ""
                          }`,
                        }}
                      />
                    </>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
    </>
  );
};
export default TableHeaderContent;
