import {
  GET_TRAVEL_SETTINGS,
  UPDATE_TRAVEL_SETTINGS,
  SET_SETTINGS_LOADING,
  SAVE_LOAN_APPROVERS,
  GET_LOAN_APPROVER_LIST_ADMIN,
  DELETE_ADMIN_LOAN_APPROVER,
} from "../../../actions/types";

const initialState = {
  travelSettings: {},
  loading: false,
  // adminLoanApproverDeleted: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SETTINGS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_TRAVEL_SETTINGS:
      return {
        ...state,
        travelSettings: action.payload,
        loading: false,
      };
    case UPDATE_TRAVEL_SETTINGS:
      return {
        ...state,
        travelSettings: action.payload,
        loading: false,
      };
    case SAVE_LOAN_APPROVERS:
      return {
        ...state,
        loanApproversSaved: action.payload,
        loading: false,
      };
    case GET_LOAN_APPROVER_LIST_ADMIN:
      return {
        ...state,
        adminLoanApproverList: action.payload,
        loading: false,
      };
    case DELETE_ADMIN_LOAN_APPROVER:
      return {
        ...state,
        adminLoanApproverDeleted: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
