export interface TimePickerProps {
  value?      : string;
  format      : string;
  showConfirm : boolean;
  showHour    : boolean;
  showMin     : boolean;
  showSec     : boolean;
  disabledHrs : number[];
  disabledMins: number[];
  disabledSecs: number[];
  onChange    : any;
}

export type UseTimePickerProps = TimePickerProps;

type cellIdentifiedType = 'hr' | 'min' | 'sec';
export interface ScrollNumberProps {
  selectedValue  : number;
  cellIdentifier?: cellIdentifiedType;
  handleNumClick : any;
  nums           : number[];
}

export interface TimePickerStateProps
  extends Omit<
    TimePickerProps,
    'onChange' | 'disabledHrs' | 'disabledMins' | 'disabledSecs'
  > {
  inputValue?  : string;
  defaultVal?  : string;
  hr?          : number;
  min?         : number;
  sec?         : number;
  modulation?  : string;
  hrsToRender? : number[];
  minsToRender?: number[];
  secsToRender?: number[];
}

export enum TimePickerActions {
  SET_INITIAL_STATE = 'SET_INITIAL_STATE',
  SET_STATE         = 'SET_STATE',
}

export interface RenderCellsProps {
  show          : boolean;
  nums          : number[];
  handleClick?  : any;
  selectedValue?: number;
  identifier?   : cellIdentifiedType;
}

export interface RenderModulationProps {
  show          : boolean;
  handleClick?  : any;
  selectedValue?: string;
}
