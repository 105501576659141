// @ts-nocheck 
import React, {useMemo, useState} from "react";

import Button from "components/button";
import {useSelector} from "react-redux";
import {
  PublishedFrame,
  AssignedFrame,
  CourseLink,
  CourseDate,
  AssignedButton,
  AssignedDrop,
} from "./frameworkComponents";
import CommonPagination from "components/common/pagination";
import Table from "components/customTable";
import {LMSCourseShowCourseTo} from "../helpers/enums";
import isEmpty from "helpers/isEmpty";

const CourseTable = ({
  handleAssignCourseButtonClick,
  handleStatusChange,
  handleRowChange,
  handleIndexChange,
  pageIndex,
  pageSize,
  selectedRow,
  setSelectedRow,
}) => {
  const {courseList} = useSelector((state: any) => state.lmsReducer);

  const onRowSelect = (rows) => setSelectedRow(rows);

  const cols = useMemo(
    () => [
      {
        Header: "Course Name",
        accessor: "Title",
        width: 120,
        Cell: CourseLink,
      },
      {Header: "Category", accessor: "CategoryName"},
      {Header: "Assigned", accessor: "Assigned", Cell: AssignedFrame, width: 80},
      {Header: "Published", accessor: "Status", Cell: PublishedFrame, width: 80},
      {accessor: "CreatedOn", Header: "Created", Cell: CourseDate, width: 80},
      {
        accessor: "ShowCourseTo",
        Header: "Course Type",
        Cell: (cellProps) =>
          LMSCourseShowCourseTo.AssignedUser == cellProps.value ? "Private" : "Public",
        width: 80,
      },

      {
        Header: "",
        accessor: "none",
        Cell: (cellProps) => (
          <AssignedButton {...cellProps} onAssignClick={handleAssignCourseButtonClick} />
        ),
        width: 100,
      },
      {
        Header: "",
        accessor: "none1",
        Cell: (cellProps) => (
          <AssignedDrop {...cellProps} onStatusChange={handleStatusChange} />
        ),
        width: 80,
      },
    ],
    []
  );

  const data = useMemo(
    () =>
      courseList.map((x) => ({
        ...x,
        isDisabled: x.CourseOptionId <= 0 || x.ShowCourseTo === 2 || x.Status === 3,
      })),
    [courseList]
  );

  return (
    <div className="course-management__table">
      {selectedRow && selectedRow.length > 0 && (
        <div className="assign-all-courses">
          <Button
            title="Assign Courses"
            bg="primary"
            onClick={() => handleAssignCourseButtonClick(selectedRow)}
          />
        </div>
      )}
      <div
        id="myGrid"
        style={{
          height: "100%",
        }}
        className="ag-theme-alpine"
      >
        <Table
          columns={cols}
          data={data}
          isRowSelect
          isFixedWidth
          onRowSelect={onRowSelect}
        />
      </div>

      {courseList && courseList.length > 0 && (
        <CommonPagination
          handleRowChange={handleRowChange}
          handleIndexChange={handleIndexChange}
          pageIndex={pageIndex}
          rowsPerPage={pageSize}
          total={courseList[0].TotalRows}
        />
      )}
    </div>
  );
};
export default CourseTable;
