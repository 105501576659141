import { InputTextProps } from './interface';
import { RigoSwitchContext } from './rigo-switch-context';

export const RigoSwitch = (props: InputTextProps) => {
  const { children, ...rest } = props;
  return (
    <RigoSwitchContext.Provider
      value={{
        ...rest,
      }}
    >
      {children}
    </RigoSwitchContext.Provider>
  );
};
