// @ts-nocheck 
import React from "react";
import {Card} from "components/card";
import {Text} from "components/text";
import {COMPLETED, IN_CORRECT, PRIMARY} from "constants/colors";

import CourseTitle from "../courseTitle";

const CourseScoreDetail = ({detail, selectedCourseResult}) => {
  return (
    <>
      {Object.keys(detail).length > 0 && (
        <>
          <div className="course-detail">
            <CourseTitle title={detail.CourseTitle} />

            <Card
              alignItems="center"
              borderRadius="3px"
              flexDirection="column"
              marginTop="10px"
              marginRight="8px"
              padding="6px 16px"
            >
              <Text title={detail.TotalQuestion} />
              <Text fontSize="12px" letterSpacing="0.2px" title="Questions" />
            </Card>

            <Card
              alignItems="center"
              borderRadius="3px"
              flexDirection="column"
              marginTop="10px"
              marginRight="8px"
              padding="6px 16px"
            >
              <Text color={COMPLETED} title={detail.Correct} />
              <Text fontSize="12px" letterSpacing="0.2px" title="Correct" />
            </Card>

            <Card
              alignItems="center"
              borderRadius="3px"
              flexDirection="column"
              marginTop="10px"
              marginRight="8px"
              padding="6px 16px"
            >
              <Text color={PRIMARY} title={detail.Unmarked} />
              <Text fontSize="12px" letterSpacing="0.2px" title="Unmarked" />
            </Card>

            <Card
              alignItems="center"
              borderRadius="3px"
              flexDirection="column"
              marginTop="10px"
              marginRight="8px"
              padding="6px 16px"
            >
              <Text color={IN_CORRECT} title={detail.TotalQuestion - detail.Correct} />
              <Text fontSize="12px" letterSpacing="0.2px" title="Incorrect" />
            </Card>

            <Card
              borderRadius="3px"
              flexDirection="column"
              marginTop="10px"
              marginRight="8px"
              padding="6px 16px"
            >
              <span>
                <Text
                  color={PRIMARY}
                  marginRight="4px"
                  title={`${selectedCourseResult.ObtainedPer}%`}
                />
                <Text fontSize="12px" letterSpacing="0.2px" title="score" />
              </span>
              <Text
                fontSize="12px"
                letterSpacing="0.2px"
                title={`${selectedCourseResult.ObtainedScore} out of ${selectedCourseResult.TotalScore}`}
              />
            </Card>

            <Card
              borderRadius="3px"
              flexDirection="column"
              marginTop="10px"
              marginRight="8px"
              padding="6px 16px"
            >
              <span>
                <Text
                  color={PRIMARY}
                  marginRight="4px"
                  title={`${selectedCourseResult.Ranks}`}
                />
                <Text fontSize="12px" letterSpacing="0.2px" title="rank" />
              </span>
              <Text
                fontSize="12px"
                letterSpacing="0.2px"
                title={`on ${selectedCourseResult.Total}`}
              />
            </Card>
          </div>
        </>
      )}
    </>
  );
};

export default CourseScoreDetail;
