import logo from "assets/images/rigo-logo-full.svg";
import RouteEnum from "constants/routeEnum";
import { Features } from "helpers/getPermissions";
import isEmpty from "isEmpty";
import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter, Link } from "react-router-dom";
import SideLink from "./sideLink";

import { Box, Flex } from "@chakra-ui/react";
import CustomScrollbar from "components/customScrollbar";

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      side: "",
      features: {},
      companyInfo: null,
      selectedIndex: -1,
      isSideBarOpen: true,
      toggleActiveLink: false,
    };
    this.timerID = null;
    // this.wrapperRef = React.createRef();
    this.onSideClick = this.onSideClick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this._renderSideBar = this._renderSideBar.bind(this);
    this.handleMove = this.handleMove.bind(this);
    this.getCategoryIcon = this.getCategoryIcon.bind(this);
    this.getLinkForCategory = this.getLinkForCategory.bind(this);
  }

  onLogoutClick(e) {
    e.preventDefault();
    this.props.logoutUser();
  }
  onSideClick(index) {
    this.setState({
      selectedIndex: index,
      toggleActiveLink:
        index === this.state.selectedIndex
          ? !this.state.toggleActiveLink
          : true,
    });
    if (!this.state.isSideBarOpen) {
      this.handleSidebarClick();
    }
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.timerID = setInterval(() => {
      this.handleMove();
    }, 500);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    clearInterval(this.timerID);
  }
  handleMove(e) {
    const features = Features();
    if (isEmpty(this.state.feature)) {
      if (!isEmpty(features)) {
        this.setState({ features });
        clearInterval(this.timerID);
      }
    }
  }
  handleClickOutside(e) {
    if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
      this.setState({ selectedIndex: -1 });
    }
  }

  handleSidebarClick = () => {
    this.setState({ isSideBarOpen: !this.state.isSideBarOpen });
  };

  getCategoryIcon(catId) {
    switch (catId) {
      case 1:
        return "techo";
      case 2:
        return "tasks";
      case 3:
        return "scroll";
      case 4:
        return "sliders";
      default:
        return "";
    }
  }

  getLinkForCategory(catId) {
    const { selectedIndex } = this.state;
    const { activeFeaturesCategories } = this.props;
    const sideContent = activeFeaturesCategories[selectedIndex];

    if (catId === 1) {
      //dashboard
      const FeatureCategories = sideContent.FeatureCategories.find(
        x => x.Category === 1,
      );
      const LinkCategories =
        (FeatureCategories &&
          FeatureCategories.LinkCategories &&
          FeatureCategories.LinkCategories) ||
        [];
      let link = "/";
      if (LinkCategories.length > 0) {
        const links = LinkCategories[0].Links || [];
        link = `${"/"}${links.length > 0 ? links[0].Link : ""}`;
      }
      return link;
    } else {
      return `${RouteEnum.Links}/${sideContent.Feature}/${catId}`;
    }
  }
  getLinkTitle(category) {
    let catName = category.CategoryName;
    if (category.Category === 1) {
      const links =
        category.LinkCategories &&
        category.LinkCategories.length > 0 &&
        category.LinkCategories[0].Links;
      catName = links.length > 0 ? links[0].LinkTitle : category.CategoryName;
    }
    return catName;
  }
  _renderSideBar(index) {
    const { activeFeaturesCategories } = this.props;
    const { selectedIndex } = this.state;
    if (selectedIndex === null || selectedIndex === -1) {
      return null;
    }
    const sideContent = activeFeaturesCategories[selectedIndex];
    return (
      <>
        {this.state.isSideBarOpen ? (
          <div
            className={`right-side ${
              this.state.toggleActiveLink && index === selectedIndex
                ? "isactive"
                : ""
            } `}
          >
            <div className='right-side-body'>
              <ul className='right-side-links'>
                {sideContent.FeatureCategories.map((cat, index) => {
                  return (
                    <li className='right-side-links__item' key={index}>
                      <NavLink
                        to={this.getLinkForCategory(cat.Category)}
                        activeClassName='active-link'
                      >
                        <span className='right-side-links_name'>
                          {this.getLinkTitle(cat)}
                        </span>
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        ) : null}
      </>
    );
  }
  render() {
    const { activeFeaturesCategories } = this.props;
    // const { companyInfo } = this.state;

    const { side, selectedIndex } = this.state;
    // const features = Features();

    return (
      <Box
        id='sidebar'
        ref={ref => (this.wrapperRef = ref)}
        className={`sidebar ${
          this.state.isSideBarOpen ? "sidebar__opened" : "sidebar__closed"
        }`}
      >
        <Flex direction='column'>
          <Box w='full'>
            {/* REDNER LOGO */}
            <Box className='sidebar-logo'>
              <Box className='sidebar-logo-image'>
                <img src={logo} height='23' width='23' alt='Rigo Logo' />
              </Box>
            </Box>
            {/* ./REDNER LOGO */}

            {/* RENDER COLLAPSE */}
            <Box
              className={`hammenu ${
                this.state.isSideBarOpen ? "ham-open" : ""
              }`}
              onClick={this.handleSidebarClick}
            >
              <Box className='hamicon'>
                <Box className='hamicon-inner'></Box>
              </Box>
              <span>Collapse</span>
            </Box>
            {/* RENDER COLLAPSE */}
          </Box>

          <Box
            h='calc(100vh - 125px)'
            w='full'
            overflowY='auto'
            paddingTop='25px'
          >
            <CustomScrollbar>
              <Box>
                <ul className='sidebar-link'>
                  {activeFeaturesCategories &&
                    activeFeaturesCategories.map((feature, index) => (
                      <SideLink
                        key={index}
                        side={side}
                        selectedIndex={selectedIndex}
                        index={index}
                        onSideClick={this.onSideClick}
                        name={feature.Feature}
                        title={feature.Title}
                        feature={feature.Feature}
                        featureId={feature.FeatureId}
                        icon={feature.Icon}
                      >
                        {this._renderSideBar(index)}
                      </SideLink>
                    ))}
                </ul>
              </Box>
            </CustomScrollbar>
          </Box>
        </Flex>
      </Box>
    );
  }
}

const mapStateToProps = state => ({
  activeFeaturesCategories: state.permissionReducer.activeFeaturesCategories,
});

export default withRouter(connect(mapStateToProps, null)(Sidebar));
