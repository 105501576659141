// @ts-nocheck 
import React,{useEffect,useState} from 'react'
import {monthsValue,getYears,getDays} from 'constants/ydmValue';
import ReactSelect from './reactSelect';
import isEmpty from 'isEmpty'
import {ydmWholeInterface} from 'ts/interfaces/formInterface';
import classnames from 'classnames'
import { type } from 'os';

function YDMInput(props:ydmWholeInterface) {
    const [types,setTypes] = useState([]);
    useEffect(()=>{
        if (props.inputShow === 'all')  setTypes(['months','days','years'])
        if (props.inputShow === 'months')  setTypes(['months'])
        if (props.inputShow === 'days')  setTypes(['days'])
        if (props.inputShow === 'years')  setTypes(['years'])
        if (Array.isArray(props.inputShow)) setTypes(props.inputShow)
    },[])
    const handleChange = (type,val)=>{
        // console.log({type,val})
        let newValue ={months:null,days:null,years:null}
        props.onChange && props.onChange(props.name,{...newValue,...props.value,[type]:val})
    }
    const ydmClass = classnames({
        "ydm-input-group":true,
        "ydm-input-group-1":types.length === 1,
        "ydm-input-group-2":types.length === 2,
        "ydm-input-group-3":types.length === 3,
    })
    return (
        <div className={ydmClass}>
            {
                types.map(item=>{
                    return(
                        <SingleInput 
                            type={item}    
                            onChange={handleChange}
                            value={!isEmpty(props.value[item]) ? props.value[item] : null}
                        />
                    )
                })
            }
            
        </div>
    )
}

const SingleInput = (props:any)=>{
    const {
        type,
        onChange,
        value,
    } = props;
    const [options,setOptions] = useState([]);
    const [label,setLabel] = useState('');
    // console.log(monthsValue)
    useEffect(()=>{
        if(type==='months') {setOptions(monthsValue);setLabel('Month')}
        if(type==='years') {setOptions(getYears());setLabel('Years')}
        if(type==='days') {setOptions(getDays());setLabel('Days')}
    },[]);
    return(
        <div className="ydm-input-col">
            {/* <label htmlFor={type} className="ydm-input-col__label">
                {label}
            </label> */}
            <ReactSelect 
                name={type}
                value={value}
                options={options}
                placeholder={label}
                width="120px"
                onChange = {onChange}
            />
        </div>
    )
}



export default YDMInput
