import { createSlice } from "@reduxjs/toolkit";
import * as fromInterface from "../../../ts";

export interface SelectedSectionOptionState {
    selectedSectionId: number,
    status: fromInterface.loadingType;
    loaded: boolean;
}

const initialState: SelectedSectionOptionState = {
    selectedSectionId: -1,
    status: fromInterface.STATUS.LOADING,
    loaded: false,
};

const selectedSectionOptionSlice = createSlice({
    name: "selected section option",
    initialState,
    reducers: {
        selectRequest: (state) => {
            state.status = fromInterface.STATUS.LOADING;
        },
        selectSuccess: (state, action) => {
            const payload = action.payload

            state.selectedSectionId = payload;
            state.status = fromInterface.STATUS.IDLE;
            state.loaded = true

        },
        clear: (state) => {
            state.selectedSectionId = -1
            state.status = fromInterface.STATUS.IDLE
            state.loaded = false
        },
    },
});

export const {
    selectRequest,
    selectSuccess,
    clear,
} = selectedSectionOptionSlice.actions

export default selectedSectionOptionSlice.reducer;