import { createSlice } from "@reduxjs/toolkit";
import isEmpty from "lodash/isEmpty";
import * as fromTs from "../../../ts";

// const MOCK_DATA_BASE = {
//     id: 0,
//     PerformanceFormId: 0,
//     SectionContentType: 4,
//     SectionContentTypeName: "Free Text Question",
//     SectionOrder: 0,
//     SectionName: "Performance Summary",
//     IntroText: "This is intro text.",
//     RatingScaleId: 0,
//     RatingScaleName: 0,
//     SectionWeight: 0,
//     ToBeAnsweredBy: "Self,Manage,Peers",
//     AssessmentType: 0,
//     AllowSelfMarking: false,
//     ImportGoals: {},
//     ImportKSA: {},
//     Contents: []
// }

// const TEXT_BOCK_CONTENT = [
//     {
//         "question": "asdasd",
//         "maxMark": "1",
//         "allowComment": true,
//         "markQuestion": true
//     },
//     {
//         "question": "asd",
//         "maxMark": "2",
//         "allowComment": true,
//         "markQuestion": true
//     },
//     {
//         "question": "sdf",
//         "maxMark": "3",
//         "allowComment": true,
//         "markQuestion": true
//     }
// ]

// const SECTION_TYPE_MATRIX_RATING_QUESTION = 1;
// const SECTION_TYPE_FREE_TEXT = 3;
// const SECTION_TYPE_TEXT_BLOCK = 1;
// const SECTION_TYPE_KSA = 5;
// const SECTION_TYPE_GOAL_AND_ACTION = 4;

// const MOCK_DATA = {
//     ...MOCK_DATA_BASE,
//     SectionContentTypeName: "Goal And Action",
//     SectionContentType: SECTION_TYPE_GOAL_AND_ACTION,
//     // Contents: TEXT_BOCK_CONTENT
// }

export interface FormState {
  editMode: boolean;
  editModeIndex: number;
  formState: fromTs.AppraisalSection;
  status: fromTs.loadingType;
}

const initialState: FormState = {
  editMode: false,
  editModeIndex: -1,
  formState: {
    id: 0,
    PerformanceFormId: 0,
    ContentType: 0,
    ContentTypeName: "",
    Order: 0,
    Name: "",
    IntroText: "",
    RatingScaleId: 0,
    RatingScaleName: 0,
    Weight: 0,
    ToBeAnsweredBy: "",
    AssessmentType: 0,
    AllowSelfMarking: false,
    ImportGoals: false,
    ImportKSA: false,
    Contents: [],
  },
  status: fromTs.STATUS.LOADING,
};

const formStateSlice = createSlice({
  name: "form state",
  initialState,
  reducers: {
    syncFormState: (state, action) => {
      const payload = action.payload;

      state.formState = payload;
      state.status = fromTs.STATUS.IDLE;
      state.editMode = false;
      state.editModeIndex = -1;
    },
    addContent: state => {
      state.formState.Contents.push({});
      state.editMode = true;
      state.editModeIndex = state.formState.Contents.length - 1;
    },
    saveContent: (state, action) => {
      const { index, contentData } = action.payload;
      state.formState.Contents[index] = contentData;
      state.editMode = false;
      state.editModeIndex = -1;
    },
    enableEditMode: (state, action) => {
      const { index } = action.payload;
      state.editMode = true;
      state.editModeIndex = index;
    },
    cancelEdit: state => {
      state.editMode = false;
      state.editModeIndex = -1;
      state.formState.Contents = state.formState.Contents.filter(
        (content: any) => !isEmpty(content),
      );
    },
    removeContent: (state, action) => {
      const id = action.payload;
      state.formState.Contents = state.formState.Contents.filter(
        (content: any, workingIdx: number) => content?.Id !== id,
      );
    },
    clear: state => {
      state = initialState;
    },
  },
});

export const {
  syncFormState,
  addContent,
  saveContent,
  enableEditMode,
  cancelEdit,
  removeContent,
  clear,
} = formStateSlice.actions;

export default formStateSlice.reducer;
