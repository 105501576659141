// @ts-nocheck 
interface option {label:String,value:any}
export default (data: any,isValueKey=false) => {
  let list: any = [];
  data &&
    data.forEach((d: any) => {
      isValueKey ? 
      list.push({ ...d, label: d.Value, value: d.Key})
      :
      list.push({ ...d, label: d.Text || d.Name || d.Key, value: d.Value || d.Id });
    });
  return list;
};


export const valuKeyFormator = (data: any) => {
  let list: any = [];
  data &&
    data.forEach((d: any) => {
      list.push({ ...d, label: d.Value, value: d.Key});
    });
  return list;
};
