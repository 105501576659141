import { ApiClient } from "../../../network/apiClient";
import * as fromInterface from "../../../ts";
import { performanceFormSectionGet } from "./api";
import * as fromSlice from "./slice";

export const fetchPerformanceFormSection = ({ formId }: { formId: any }) => async (dispatch: any, getState: any) => {
    dispatch(fromSlice.fetchRequest())

    const response: fromInterface.ResponseWrapper<fromInterface.ReviewCycle> = await ApiClient.post<any>({
        endpoint: performanceFormSectionGet,
        dispatch: dispatch
    })

    if (response?.Status) {
        dispatch(fromSlice.fetchSuccess(response?.Data))
    }

    return response
}

export const clearPerformanceFormSection = () => (dispatch: any) => {
    dispatch(fromSlice.clear())
}