export enum Spacing {
  xxs = '.25rem', // 4px
  xs = '.5rem', // 8px
  s = '.75rem', // 12px
  ms = '1rem', // 16px
  m = '1.25rem', // 20px
  ml = '1.5rem', // 20px
  l = '2rem', // 32px
  xl = '3rem', //48px
  xxl = '4rem', //64px
  pageSpacingSmall = '.75rem 1rem',
  pageSpacinglarge = '1.25rem 1.5rem',

  employeeCard = '.75rem 1rem',
  employeeCardLg = '1rem 1.25rem',
}
