import * as fromModel from "@rigotech/hrms-model";
import { createSlice, SliceCaseReducers, ValidateSliceCaseReducers } from "@reduxjs/toolkit";
import { GenericState } from "./generic-state";

/**
 * USUAGE 
 
const wrappedSlice = createGenericSlice({
    name: 'test',
    initialState: { status: 'loading' } as GenericState<string>,
    reducers: {

    },
})

export const {
    fetchRequest,
    fetchSuccess,
    fetchError,
    updateFilters,
    clear,
} = wrappedSlice.actions

export default wrappedSlice.reducer

*/
export const createGenericSlice = <T, K, Reducers extends SliceCaseReducers<GenericState<T, K>>>({
    name = '',
    initialState,
    reducers,
}: {
    name: string
    initialState: GenericState<T, K>
    reducers: ValidateSliceCaseReducers<GenericState<T, K>, Reducers>
}) => {
    return createSlice({
        name,
        initialState,
        reducers: {
            fetchRequest: (state) => {
                state.status = fromModel.STATUS.LOADING;
            },

            fetchSuccess: (state, action) => {
                const payload: any = action.payload

                state.items = payload
                state.status = fromModel.STATUS.IDLE;
                state.loaded = true

            },

            updateFilters: (state, action) => {
                const payload = action.payload

                state.filters = { ...state.filters, ...payload }
            },

            fetchError: (state) => {
                state.items = [];
                state.status = fromModel.STATUS.IDLE;
                state.loaded = true
            },

            clear: (state) => {
                state.items = null
                state.status = fromModel.STATUS.IDLE
                state.loaded = false
            },
            ...reducers,
        },
    })
}


