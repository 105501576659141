import React from "react";
import styled from "styled-components";

const TextContainer = styled.span`
  color: ${(props) => props.color};
  display: ${(props) => props.display};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  letter-spacing: ${(props) => props.letterSpacing};
  margin: ${(props) => props.margin};
  margin-top: ${(props) => props.marginTop};
  margin-right: ${(props) => props.marginRight};
  margin-bottom: ${(props) => props.marginBottom};
  margin-left: ${(props) => props.marginLeft};
  opacity: ${(props) => props.opacity};
  text-decoration: ${(props) => props.textDecoration};
  text-underline-offset: ${(props) => props.textUnderlineOffset};
`;

export const Text = ({
  color,
  display,
  fontSize,
  fontWeight,
  letterSpacing,
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  opacity,
  textDecoration,
  textUnderlineOffset,
  title,
}) => {
  return (
    <TextContainer
      display={display}
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
      letterSpacing={letterSpacing}
      margin={margin}
      marginTop={marginTop}
      marginRight={marginRight}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      opacity={opacity}
      textDecoration={textDecoration}
      textUnderlineOffset={textUnderlineOffset}
    >
      {title}
    </TextContainer>
  );
};

Text.defaultProps = {
  color: "#000",
  display: "",
  fontSize: "14px",
  fontWeight: "400",
  letterSpacing: "",
  margin: "",
  marginTop: "",
  marginRight: "",
  marginBottom: "",
  marginLeft: "",
  opacity: 1,
  textDecoration: "none",
  textUnderlineOffset: "",
  title: "",
};
