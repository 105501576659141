import React, { lazy } from 'react';
import RouteEnum from '../../../constants/routeEnum';

const Travel = React.lazy(() => import('../../../scenes/travel'));

const AllowanceExpense = React.lazy(
  () =>
    import(
      'scenes/travel/admin/travelAllowanceExpense/allowanceExpense'
    ),
);
const TravelCategory = React.lazy(
  () => import('scenes/travel/admin/travelCategory'),
);

const CreateNewTravelRequest = React.lazy(
  () =>
    import(
      '../../../scenes/travel/common/travelInformation/createTravelRequest/createNewTravelRequest'
    ),
);
const MyTravelRequestScreen = React.lazy(
  () =>
    import(
      '../../../scenes/travel/common/travelInformation/myTravelRequest/myTravelRequestScreen'
    ),
);
const OthersTravelRequestScreen = React.lazy(
  () =>
    import(
      '../../../scenes/travel/common/travelInformation/otherTravelRequest/otherTravelRequestScreen'
    ),
);
const TravelRequestTeamHistoryScreen = React.lazy(
  () =>
    import(
      '../../../scenes/travel/common/travelInformation/travelTeamHistory/travelTeamHistoryScreen'
    ),
);
const TravelRequestHistoryScreen = React.lazy(
  () =>
    import(
      '../../../scenes/travel/common/travelInformation/travelHistory/travelHistoryScreen'
    ),
);
const SettlementRequests = React.lazy(
  () =>
    import(
      '../../../scenes/travel/common/travelSettlement/settlementRequest/settlementRequests'
    ),
);
const PendingSettlement = React.lazy(
  () =>
    import(
      '../../../scenes/travel/common/travelSettlement/pendingSettlement/pendingSettlement'
    ),
);

const routes = features => [
  {
    path: RouteEnum.TravelPath,
    // exact:true,
    component: Travel,
    // title: "Travel",
    feature: features.TravelManagement,
    children: travelLinks,
  },
];

const travelLinks = [
  {
    path: RouteEnum.TravelAllowanceExpensePath,
    exact: true,
    component: AllowanceExpense,
    title: 'Travel Allowance',
  },
  {
    path: RouteEnum.TravelCategoryPath,
    exact: true,
    component: TravelCategory,
    isAdmin: true,
    title: 'Travel Category',
  },
  {
    path: RouteEnum.CreateTravelRequestPath,
    // exact:true,
    component: CreateNewTravelRequest,
    title: 'Create New Travel Request',
    isTravelAnimate: true,
  },
  {
    path: RouteEnum.UpdateTravelRequestPath,
    // exact:true,
    component: CreateNewTravelRequest,
    title: 'Update Travel Request',
    isTravelAnimate: true,
  },
  {
    path: RouteEnum.TeamTravelRequestPath,
    exact: true,
    component: OthersTravelRequestScreen,
    title: 'Travel Request',
  },
  {
    path: RouteEnum.MyTravelRequestPath,
    exact: true,
    component: MyTravelRequestScreen,
    title: 'My Travel Request',
  },
  {
    path: RouteEnum.SettlementRequestPath,
    exact: true,
    component: SettlementRequests,
    title: 'Settlement Requests',
  },
  {
    path: RouteEnum.PendingSettlementPath,
    exact: true,
    component: PendingSettlement,
    title: 'Pending Settlement',
  },
  {
    path: RouteEnum.SettlementHistoryPath,
    exact: true,
    component: SettlementRequests,
    title: 'Settlement History',
  },
  {
    path: RouteEnum.TravelHistoryPath,
    exact: true,
    component: TravelRequestHistoryScreen,
    title: 'Travel History',
  },
  {
    path: RouteEnum.TeamTravelHistoryPath,
    exact: true,
    component: TravelRequestTeamHistoryScreen,
    title: 'Team Travel History',
  },
  {
    path: RouteEnum.TeamSettlementHistoryPath,
    exact: true,
    component: SettlementRequests,
    title: 'Team Settlement History',
  },
];
export default routes;
