import * as fromInterface from "../../../ts";
import { CurrentSectionFormDetailState } from "./slice";

const selectCurrentSectionFormDetailSlice = (state: any): CurrentSectionFormDetailState => {
    return state.dataStore.currentSectionFormDetail;
};

export const selectCurrentSectionFormDetailStatus = (state: any): fromInterface.loadingType => {
    return selectCurrentSectionFormDetailSlice(state).status;
};

export const selectCurrentSectionFormDetailLoaded = (state: any): boolean => {
    return selectCurrentSectionFormDetailSlice(state).loaded;
};

export const selectCurrentSectionFormDetail = (state: any) => {
    return selectCurrentSectionFormDetailSlice(state).detail;
};