import PzTimePicker from './pzTimePicker';
import React from "react";

export function CustomTimePicker(props: any) {
  const { name, value, onChange2, onChangeRHF, ...rest } = props;
  const handleChange = (data: any) => {
    onChange2?.(name, data);
    onChangeRHF?.(data);
  };
  return (
    <PzTimePicker
      {...rest}
      format="12hr"
      value={value}
      onChange={handleChange}
    />
  );
}

export default CustomTimePicker;
