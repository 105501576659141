import { DEVELOPMENT_AREA } from "./api"
import * as fromInterface from "../../../ts"
import { ApiClient } from "../../../network/apiClient"

export const fetchDevelopmentAreas =
  (isArchived: boolean) => async (dispatch: any) => {
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.get<any>({
        endpoint: `${DEVELOPMENT_AREA}/${isArchived}`,
        dispatch: dispatch,
      })
    const { Data, Status } = response

    return { Data, Status }
  }

export const fetchDevelopmentAreasDropdown = (): any => async (dispatch: any) => {
  const response = await ApiClient.get<any>({
    endpoint: `${DEVELOPMENT_AREA}`,
    dispatch: dispatch,
  })

  return response
}

export const saveDevelopmentArea =
  (data?: any, cb?: any): any =>
    async (dispatch: any) => {
      const param = {
        endpoint: DEVELOPMENT_AREA,
        body: data,
        dispatch: dispatch,
      }
      const response: fromInterface.ResponseWrapper<any> =
        await ApiClient.post<any>(param)
      const { Status } = response

      // Status && dispatch(fetchDevelopmentAreas(false))
      cb?.(Status)
    }

export const deleteDevelopmentArea =
  (id: number): any =>
    async (dispatch: any) => {
      const param = {
        endpoint: `${DEVELOPMENT_AREA}/${id}`,
        dispatch: dispatch,
      }
      const response: any = await ApiClient.remove(param)
      const { Status } = response
      return Status
    }
