// @ts-nocheck
import { useState } from "react";
import { useDispatch } from "react-redux";
import { CourseStatus } from "scenes/lms/helpers/enums";

import { duplicateCourse, updateCourseStatus } from "../../helpers/action";

export const useAssignDrop = (props) => {
  const [confirm, setConfirm] = useState({
    open: false,
    title: "",
    message: "",
    type: "",
    okButtonText: "",
  });

  const dispatch = useDispatch();
  const { Assigned, AssignedCompleted, Id, Status } = props.row?.original;

  const onModalClose = () =>
    setConfirm({
      open: false,
      title: "",
      message: "",
      type: "",
      okButtonText: "",
    });

  const onResponse = () => {
    onModalClose();
    props.onStatusChange();
  };

  const onStatusChanges = async () => {
    let res = await dispatch(
      updateCourseStatus(
        Id,
        Status === CourseStatus.Publish || Status === CourseStatus.Discontinue
          ? CourseStatus.Unpublish
          : CourseStatus.Publish
      )
    );
    res && onResponse();
  };

  const onConfirmed = async () => {
    if (confirm.type === "statusChange") {
      onStatusChanges();
    } else if (confirm.type === "duplicate") {
      const res = await dispatch(duplicateCourse(Id));
      res && onResponse();
    }
  };

  const showConfirm = (title, message, type, okButton) => {
    setConfirm({ title, message, type, open: true, okButtonText: okButton });
  };

  const getTitle = () =>
    props.row?.original?.Status === CourseStatus.Discontinue
      ? "Enable"
      : props.row?.original?.Status === CourseStatus.Publish
      ? "Unpublish"
      : "Publish";

  const getStatusMessage = () =>
    props.row?.original?.Status === CourseStatus.Discontinue
      ? "Are you sure you want to enable this course?"
      : props.row?.original?.Status === CourseStatus.Publish
      ? "Are you sure you want to unpublish this course?"
      : "Are you sure you want to publish this course?";

  const isPrimarButtonType = () => {
    if (
      confirm.type === "discontinue" ||
      confirm.type === "delete" ||
      confirm.title === "Unpublish" ||
      confirm.type === "reset"
    )
      return false;
    return true;
  };

  const showDiscontinueAction = () => {
    if (Status === CourseStatus.Discontinue) return false;
    if (Assigned > 0 && Assigned !== AssignedCompleted) return false;
    return true;
  };

  return {
    confirm,
    onConfirmed,
    onModalClose,
    showConfirm,
    setConfirm,
    getTitle,
    getStatusMessage,
    isPrimarButtonType,
    showDiscontinueAction,
  };
};
