import { ApiClient } from "../../../../network/apiClient";
import { FETCH_PERFORMANCE_REVIEW_EVALUATION } from "../api";
import * as fromInterface from "../../../../ts";
import * as fromSlice from "../slice";

export const fetchPerformanceReviewEvaluation = ({ rolloutId }: { rolloutId: number; }): any => async (dispatch: any) => {
    dispatch(fromSlice.fetchRequest());
    const response: fromInterface.ResponseWrapper<fromInterface.PerformanceReview> = await ApiClient.get<any>({
        endpoint: FETCH_PERFORMANCE_REVIEW_EVALUATION.replace(":roloutId", rolloutId + ""),
        dispatch: dispatch
    });

    const { Data, Status } = response;

    if (Status) {
        dispatch(fromSlice.fetchSuccess(Data));
    } else {
        dispatch(fromSlice.fetchError());
    }

};
