import { ApiClient } from "../../../../network/apiClient";
import { FETCH_REVIEW_CYCLE_DROPDOWN } from "./../api";
import * as fromSlice from "./../slice";


export const fetchReviewCycleFilterDropDown = () => async (dispatch: any, getState: any) => {

    dispatch(fromSlice.fetchReviewCycleRequest())

    const response: any = await ApiClient.get({
        endpoint: FETCH_REVIEW_CYCLE_DROPDOWN,
        dispatch: dispatch
    })

    if (response?.Status) {
        dispatch(fromSlice.fetchReviewCycleSuccess(response.Data))
    }

}

