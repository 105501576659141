import React from "react";

import { flexRender } from "@tanstack/react-table";

//INTERFACE
import { ITableContentProps } from "../ts";

const TableFooterContent = (props: ITableContentProps) => {
  const { table } = props;

  return (
    <>
      <tfoot>
        {table.getFooterGroups().map((footerGroup) => (
          <tr key={footerGroup.id}>
            {footerGroup.headers.map((header) => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.footer,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </tfoot>
    </>
  );
};
export default TableFooterContent;
