// @ts-nocheck 
const copyObject = (value: any): any => {
    try {
        return JSON.parse(JSON.stringify(value));

    } catch (error) {
        return null
    }


}

export default copyObject;
