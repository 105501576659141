import * as fromSlice from "./slice";
import * as fromPerformanceFormListStore from "../list"

export const udpatPerformanceFormListFilter = (newFilters: any): any => (dispatch: any, getState: any) => {
    dispatch(fromSlice.udpateFilter(newFilters))
}

export const udpatePerformanceFormListTablePagination = (pageInfo: any) => (dispatch: any, getState: any) => {
    dispatch(fromSlice.udpateFilter(pageInfo))
    dispatch(fromPerformanceFormListStore.fetchPerformanceForms())
}

export const clearPerformanceFormListFilter = () => (dispatch: any, getState: any) => {
    dispatch(fromSlice.clear())
}
