export const colors = {
    primary: {
        50: "#e6f4ff",
         100: "#bae0ff",
          200: "#91caff",
           300: "#69b1ff",
            400: "#4096ff",
             500: "#1677ff",
              600: "#0958d9",
               700: "#003eb3",
                800: "#002c8c",
                 900: "#001d66",
    },
}
