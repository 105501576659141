// @ts-nocheck
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_CURRENT_TOKEN = "SET_CURRENT_TOKEN";
export const SET_TWO_FACTOR_USER_DETAILS = "SET_TWO_FACTOR_USER_DETAILS";
export const GET_TFA_QR_CODE = "GET_TFA_QR_CODE";
export const VERIFY_TFA_CODE = "VERIFY_TFA_CODE";
export const SET_USER_VISIBLE = "SET_USER_VISIBLE";
export const CHANGE_PROFILE_PICTURE = "CHANGE_PROFILE_PICTURE";

export const EMPLOYEES_LOADING = "EMPLOYEES_LOADING";
export const ADD_EMPLOYEE = "ADD_EMPLOYEE";
export const EDIT_EMPLOYEE = "EDIT_EMPLOYEE";
export const GET_EMPLOYEES = "GET_EMPLOYEES";
export const GET_EMPLOYEE = "GET_EMPLOYEE";
export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";

// Common
export const BRANCH_LOADING = "BRANCH_LOADING";
export const ADD_BRANCH = "ADD_BRANCH";
export const EDIT_BRANCH = "EDIT_BRANCH";
export const GET_BRANCHES = "GET_BRANCHES";
export const GET_BRANCH = "GET_BRANCH";
export const DELETE_BRANCH = "DELETE_BRANCH";
export const SEARCHED_BRANCHES = "SEARCHED_BRANCHES";
export const GET_BRANCHES_FOR_NOW = "GET_BRANCHES_FOR_NOW";
export const GET_LEVELS = "GET_LEVELS";
export const GET_LEAVE_TYPES = "GET_LEAVE_TYPES";
export const LEAVE_TYPES_SELECT_LOADING = "LEAVE_TYPES_SELECT_LOADING";
export const GET_SALARY_TYPES = "GET_SALARY_TYPES";
export const SALARY_TYPES_SELECT_LOADING = "SALARY_TYPES_SELECT_LOADING";
export const GET_COMPANY_LOGO = "GET_COMPANY_LOGO";
export const SORT_DRAG = "SORT_DRAG";
export const SET_PDF_DATA = "SET_PDF_DATA";
export const GET_BASE_URL = "GET_BASE_URL";
export const GET_PUBLIC_RECRUITMENT_BASE_URL =
  "GET_PUBLIC_RECRUITMENT_BASE_URL";
export const GET_IS_RECOMMENDER_OR_APPORVER = "GET_IS_RECOMMENDER_OR_APPORVER";
export const GET_HR_COMMON_SETTINGS = "GET_HR_COMMON_SETTINGS";
export const GET_HIRING_METHODS = "GET_HIRING_METHODS";

export const GET_LOCATION = "GET_LOCATION";
export const LOCATION_SELECT_LOADING = "LOCATION_SELECT_LOADING";
export const GET_GENDERS = "GET_GENDERS";
export const GENDER_SELECT_LOADING = "GENDER_SELECT_LOADING";
export const GET_DESIGNATION = "GET_DESIGNATION";
export const DESIGNATION_SELECT_LOADING = "DESIGNATION_SELECT_LOADING";
export const GET_MARITAL_STATUS = "GET_MARITAL_STATUS";
export const MARITAL_STATUS_SELECT_LOADING = "MARITAL_STATUS_SELECT_LOADING";
export const GET_JOB_STATUS = "GET_JOB_STATUS";
export const GET_JOB_STATUS_SELECT = "GET_JOB_STATUS_SELECT";
export const JOB_STATUS_SELECT_LOADING = "JOB_STATUS_SELECT_LOADING";
export const GET_EMPLOYEE_STATES = "GET_EMPLOYEE_STATES";
export const EMPLOYEE_STATES_SELECT_LOADING = "EMPLOYEE_STATES_SELECT_LOADING";
export const GET_EDUCATION_LEVELS = "GET_EDUCATION_LEVELS";
export const GET_EXPERIENCE_DURATION = "GET_EXPERIENCE_DURATION";
export const GET_SALARY_PAY_DURATION = "GET_SALARY_PAY_DURATION";
export const GET_COMPANY_INFORMATION = "GET_COMPANY_INFORMATION";
export const COMPANY_INFORMATION_REQUESTED = "COMPANY_INFORMATION_REQUESTED";
export const GET_SERVER_DATE_TIME = "GET_SERVER_DATE_TIME";
export const UPDATE_COMPANY_INFORMATION = "UPDATE_COMPANY_INFORMATION";
export const GET_NATIONALITY = "GET_NATIONALITY";
export const GET_PAYROLL_INCOME_HEADS = "GET_PAYROLL_INCOME_HEADS";
export const GET_DISTRIBUTED_INCOME_BY_AMOUNT =
  "GET_DISTRIBUTED_INCOME_BY_AMOUNT";
export const SALARY_TEMPLATE_LIST = "SALARY_TEMPLATE_LIST";
export const SAVE_SALARY_TEMPLATE = "SAVE_SALARY_TEMPLATE";
export const SALARY_TEMPLATE_DETAILS = "SALARY_TEMPLATE_DETAILS";
export const DELETE_SAVE_SALARY_TEMPLATE = "DELETE_SAVE_SALARY_TEMPLATE";
export const SAVE_ACCESS_AND_ID_TEMPLATE = "SAVE_ACCESS_AND_ID_TEMPLATE";
export const DELETE_SAVE_ACCESS_AND_ID_TEMPLATE =
  "DELETE_SAVE_ACCESS_AND_ID_TEMPLATE";
export const CLEAR_SAVE_ACCESS_AND_ID_TEMPLATE =
  "CLEAR_SAVE_ACCESS_AND_ID_TEMPLATE";
export const SAVE_CHECKLIST_TEMPLATE = "SAVE_CHECKLIST_TEMPLATE";
export const DELETE_SAVE_CHECKLIST_TEMPLATE = "DELETE_SAVE_CHECKLIST_TEMPLATE";
export const SAVE_MESSAGE_TEMPLATE = "SAVE_MESSAGE_TEMPLATE";
export const DELETE_SAVE_MESSAGE_TEMPLATE = "DELETE_SAVE_MESSAGE_TEMPLATE";
export const CLEAR_SAVE_MESSAGE_TEMPLATE = "CLEAR_SAVE_MESSAGE_TEMPLATE";

export const SAVE_ONBOARDING_EMPLOYEE = "SAVE_ONBOARDING_EMPLOYEE";
export const REQUEST_ONBOARDING_EMPLOYEE = "REQUEST_ONBOARDING_EMPLOYEE";
export const GET_ONBOARDING_EMPLOYEE_DETAIL = "GET_ONBOARDING_EMPLOYEE_DETAIL";
export const SAVE_EMPLOYEE_ONBOARDING_CHECKLIST =
  "SAVE_EMPLOYEE_ONBOARDING_CHECKLIST";
export const SET_CHECKLIST = "SET_CHECKLIST";
export const CLEAR_EMPLOYEE_ONBOARDING_CHECKLIST =
  "CLEAR_EMPLOYEE_ONBOARDING_CHECKLIST";
export const GET_ONBOARDING_EMPLOYEE_LIST = "GET_ONBOARDING_EMPLOYEE_LIST";
export const SAVE_EMPLOYEE_SALARY_TEMPLATE = "SAVE_EMPLOYEE_SALARY_TEMPLATE";
export const GET_ON_BOARDING_ACCESS_ID_CATEGORIES =
  "GET_ON_BOARDING_ACCESS_ID_CATEGORIES";
export const GET_ON_BOARDING_CHECKLIST_CATEGORIES =
  "GET_ON_BOARDING_CHECKLIST_CATEGORIES";
export const SAVE_ONBOARDING_ACCESSID = "SAVE_ONBOARDING_ACCESSID";
export const SAVE_ONBOARDING_CHECKLISTS = "SAVE_ONBOARDING_CHECKLISTS";
export const SAVE_EMPLOYEE_INTRO = "SAVE_EMPLOYEE_INTRO";
export const DELETE_ONBOARDING_EMPLOYEE = "DELETE_ONBOARDING_EMPLOYEE";
export const GET_ONBOAREDING_EMPLOYEE_EMAIL_PREVIEW =
  "GET_ONBOAREDING_EMPLOYEE_EMAIL_PREVIEW";
export const GET_EMPLOYEE_SALARY_TEMPLATE_DETAILS =
  "GET_EMPLOYEE_SALARY_TEMPLATE_DETAILS";
export const GET_APP_VERSION = "GET_APP_VERSION";
export const GET_EMP_MENUS = "GET_EMP_MENUS";
export const ACCESSANDIDTEMPLATELIST = "ACCESSANDIDTEMPLATELIST";
export const CHECKLIST_TEMPLATE_LIST = "CHECKLIST_TEMPLATE_LIST";
export const MESSAGE_TEMPLATE_LIST = "MESSAGE_TEMPLATE_LIST";
export const APPROVE_EMPLOYEE_ONBOARDING = "APPROVE_EMPLOYEE_ONBOARDING";
export const COMPLETE_ONBOARDING_EMPLOYEE = "COMPLETE_ONBOARDING_EMPLOYEE";
export const SEND_ONBOARDING_EMAIL = "SEND_ONBOARDING_EMAIL";
export const RESEND_ONBOARDING_EMAIL = "RESEND_ONBOARDING_EMAIL";

// export const GET_LEVELS="GET_LEVELS";
export const LEVELS_SELECT_LOADING = "LEVELS_SELECT_LOADING";
export const GET_PROVINCE_LIST = "GET_PROVINCE_LIST";
export const GET_DISTRICT_BY_PROVINCE = "GET_DISTRICT_BY_PROVINCE";
export const GET_LOCALBODY_BY_DISTRICT = "GET_LOCALBODY_BY_DISTRICT";
export const GET_LEVELS_WITH_GROUPS = "GET_LEVELS_WITH_GROUPS";
export const SET_LEVELS_WITH_GROUPS_LOADING = "SET_LEVELS_WITH_GROUPS_LOADING";

//Leave Request
export const GET_LEAVE_REQUEST_STATUS = "GET_LEAVE_REQUEST_STATUS";
export const SEARCHED_LEAVE_ABSENCE = "SEARCHED_LEAVE_ABSENCE";
export const LEAVE_ABSENCE_LOADING = "LEAVE_ABSENCE_LOADING";
export const LEAVE_REQUEST_STATUS_LOADING = "LEAVE_REQUEST_STATUS_LOADING";
export const LEAVE_SOURCE_SELECT_LOADING = "LEAVE_SOURCE_SELECT_LOADING";
export const GET_LEAVE_SOURCE = "GET_LEAVE_SOURCE";

//Branch
export const BRANCH_SELECT_LOADING = "BRANCH_SELECT_LOADING";
export const EMPLOYEE_SELECT_LOADING = "EMPLOYEE_SELECT_LOADING";
export const DEPARTMENT_SELECT_LOADING = "DEPARTMENT_SELECT_LOADING";
export const GET_DEPARTMENTS = "GET_DEPARTMENTS";
export const SUB_DEPARTMENT_SELECT_LOADING = "SUB_DEPARTMENT_SELECT_LOADING";
export const GET_SUB_DEPARTMENTS = "GET_SUB_DEPARTMENTS";
export const GET_FISCAL_YEAR = "GET_FISCAL_YEAR";
export const FISCAL_LOADING = "FISCAL_LOADING";

//PAyroll Periods
export const PAYROLL_PERIOD_LOADING = "PAYROLL_PERIOD_LOADING";
export const GET_PAYROLL_PERIOD = "GET_PAYROLL_PERIOD";
export const SELECTED_PAYROLL = "SELECTED_PAYROLL";
export const GET_PAYROLL_DASHBOARD = "GET_PAYROLL_DASHBOARD";
export const GET_PAYROLL_SLIP = "GET_PAYROLL_SLIP";

//Salary Adjustment
export const GET_INCOME_OR_DEDCUTIONS = "GET_INCOME_OR_DEDCUTIONS";
export const SEARCHED_SALARY_ADJUSTMENT = "SEARCHED_SALARY_ADJUSTMENT";
export const INCOME_OR_DEDCUTIONS_LOADING = "INCOME_OR_DEDCUTIONS_LOADING";
export const SALARY_ADJUSTMENT_LOADING = "SALARY_ADJUSTMENT_LOADING";
export const EDIT_SALARY_ADJUST = "EDIT_SALARY_ADJUST";
export const DELETE_SALARY_ADJUSTMENT = "DELETE_SALARY_ADJUSTMENT";

//Tax Adjustment
export const SEARCHED_TAX_ADJUSTMENT = "SEARCHED_TAX_ADJUSTMENT";
export const TAX_ADJUSTMENT_LOADING = "TAX_ADJUSTMENT_LOADING";

//Attendance Register
export const SEARCHED_ATTENDANCE_REGISTER = "SEARCHED_ATTENDANCE_REGISTER";
export const ATTENDANCE_LOADING = "ATTENDANCE_LOADING";
export const COLORS_LOADING = "COLORS_LOADING";
export const GENERATE_ATTENDANCE_REGISTER = "GENERATE_ATTENDANCE_REGISTER";
export const GET_COLORS = "GET_COLORS";

//Income Calculation
export const GET_CALCULATION_HEADER = "GET_CALCULATION_HEADER";
export const CALCULATION_HEADER_LOADING = "CALCULATION_HEADER_LOADING";
export const INCOME_CALCULATION_LOADING = "INCOME_CALCULATION_LOADING";
export const SEARCH_CALCULATION_LIST = "SEARCH_CALCULATION_LIST";
export const GET_PAYTYPE_VISIBILITY = "GET_PAYTYPE_VISIBILITY";
export const PAYTYPE_VISIBILITY_LOADING = "PAYTYPE_VISIBILITY_LOADING";

//Stop Payment
export const SEARCHED_STOP_PAYMENT = "SEARCHED_STOP_PAYMENT";
export const DELETE_STOP_PAYMENT = "DELETE_STOP_PAYMENT";
export const STOP_PAYMENT_LOADING = "STOP_PAYMENT_LOADING";
export const EDIT_STOP_PAYMENT = "EDIT_STOP_PAYMENT";
export const GET_STOP_PAYMENT = "GET_STOP_PAYMENT";
export const ADD_STOP_PAYMENT = "ADD_STOP_PAYMENT";

//travel
//allowance-expense
export const ALLOWANCE_EXPENSE_LOADING = "ALLOWANCE_EXPENSE_LOADING";
export const GET_ALLOWANCE_EXPENSE = "GET_ALLOWANCE_EXPENSE";
export const ADD_ALLOWANCE_EXPENSE = "ADD_ALLOWANCE_EXPENSE";
export const LOAD_ALLOWANCE_EXPENSE = "LOAD_ALLOWANCE_EXPENSE";
export const SET_EDIT_MODE = "SET_EDIT_MODE";
export const EDIT_ALLOWANCE_EXPENSE = "EDIT_ALLOWANCE_EXPENSE";
export const DELETE_ALLOWANCE_EXPENSE = "DELETE_ALLOWANCE_EXPENSE";
export const EMPLOYEE_SINGLE_ALLOWANCE_REQUEST =
  "EMPLOYEE_SINGLE_ALLOWANCE_REQUEST";
//

//travel-category
export const TRAVEL_CATEGORY_LOADING = "TRAVEL_CATEGORY_LOADING";
export const GET_TRAVEL_CATEGORY = "GET_TRAVEL_CATEGORY";
export const ADD_TRAVEL_CATEGORY = "ADD_TRAVEL_CATEGORY";
export const LOAD_TRAVEL_CATEGORY = "LOAD_TRAVEL_CATEGORY";
export const EDIT_TRAVEL_CATEGORY = "EDIT_TRAVEL_CATEGORY";
export const DELETE_TRAVEL_CATEGORY = "DELETE_TRAVEL_CATEGORY";
export const GET_TRAVEL_CATEGORY_LIST = "GET_TRAVEL_CATEGORY_LIST";
export const LOAD_APPLICABLE_EXPENSES = "LOAD_APPLICABLE_EXPENSES";
//

//Travel request
export const COUNT_TRAVEL_REQUESTS = "COUNT_TRAVEL_REQUESTS";
export const GET_MY_TRAVEL_REQUESTS = "GET_MY_TRAVEL_REQUESTS";
export const GET_OTHERS_TRAVEL_REQUESTS = "GET_OTHERS_TRAVEL_REQUESTS";
export const GET_OTHERS_TRAVEL_REQUESTS_EXCEL_EXPORT =
  "GET_OTHERS_TRAVEL_REQUESTS_EXCEL_EXPORT";
export const GET_TRAVEL_HISTORY_EXCEL_EXPORT =
  "GET_TRAVEL_HISTORY_EXCEL_EXPORT";
export const GET_SETTLEMENT_HISTORY_EXCEL_EXPORT =
  "GET_SETTLEMENT_HISTORY_EXCEL_EXPORT";
export const GET_TRAVEL_REQUEST_REPORT = "GET_TRAVEL_REQUEST_REPORT";
export const EDIT_TRAVEL_REQUESTS = "EDIT_TRAVEL_REQUESTS";
export const RELOAD_TRAVEL_REQUESTS = "RELOAD_TRAVEL_REQUESTS";
// export const UPDATE_TRAVEL_REQUESTS = "UPDATE_TRAVEL_REQUESTS";
// export const DELETE_TRAVEL_REQUESTS = "DELETE_TRAVEL_REQUESTS";
// export const CREATE_MY_TRAVEL_REQUESTS = "CREATE_MY_TRAVEL_REQUESTS";
export const GET_HOST_AND_HOTEL = "GET_HOST_AND_HOTEL";
export const GET_MODE_OF_TRANSPORT = "GET_MODE_OF_TRANSPORT";
export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST";
export const GET_CURRENCY_LIST = "GET_CURRENCY_LIST";
export const GET_DISTRICT_LIST = "GET_DISTRICT_LIST";
export const GET_EMPLOYEE_LIST = "GET_EMPLOYEE_LIST";
export const GET_EMPLOYEE_LIST_WITH_PHOTO = "GET_EMPLOYEE_LIST_WITH_PHOTO";
export const GET_EMPLOYEE_BY_BRANCH_DEPARTMENT =
  "GET_EMPLOYEE_BY_BRANCH_DEPARTMENT";
export const GET_CATEGORY_ALL_EXPENSE_LIST = "GET_CATEGORY_ALL_EXPENSE_LIST";
export const GET_APPROVAL_AUTHORITIES = "GET_APPROVAL_AUTHORITIES";
export const CLEAR_APPROVAL_AUTHORITIES = "CLEAR_APPROVAL_AUTHORITIES";
export const APPROVAL_AUTHORITIES_LOADING = "APPROVAL_AUTHORITIES_LOADING";
export const CANCEL_TRAVEL_REQUEST = "CANCEL_TRAVEL_REQUEST";
export const PUT_APPROVE_TRAVEL_REQUEST = "PUT_APPROVE_TRAVEL_REQUEST";
export const GET_TRAVEL_APPROVAL_HISTORY = "GET_TRAVEL_APPROVAL_HISTORY";
export const APPROVAL_HISTORY_LOADING = "APPROVAL_HISTORY_LOADING";
export const TRAVEL_EDIT_LOADING = "TRAVEL_EDIT_LOADING";
export const GET_TRAVEL_APPROVAL_FLOW_STEPS = "GET_TRAVEL_APPROVAL_FLOW_STEPS";
export const GET_TRAVEL_SETTINGS = "GET_TRAVEL_SETTINGS";
export const UPDATE_TRAVEL_SETTINGS = "UPDATE_TRAVEL_SETTINGS";
export const GET_SETTLEMENT_HISTORY = "GET_SETTLEMENT_HISTORY";
export const GET_TRAVEL_HISTORY = "GET_TRAVEL_HISTORY";
export const TRAVEL_HISTORY_LOADING = "TRAVEL_HISTORY_LOADING";
export const TRAVEL_TEAM_HISTORY_LOADING = "TRAVEL_TEAM_HISTORY_LOADING";
export const UPDATE_TRAVEL_COUNT = "UPDATE_TRAVEL_COUNT";
export const CLEAR_TRAVEL_COUNT = "CLEAR_TRAVEL_COUNT";
export const IS_DROPDOWN_VALUE_CHANGED = "IS_DROPDOWN_VALUE_CHANGED";
export const RESET_SELECTED_ADVANCED_AND_EXPENSE_LIST =
  "RESET_SELECTED_ADVANCED_AND_EXPENSE_LIST";

export const GET_COST_CODES = "GET_COST_CODES";
export const GET_UNIT_LIST = "GET_UNIT_LIST";
export const UNIT_LIST_LOADING = "UNIT_LIST_LOADING";
export const COST_CODES_LOADING = "COST_CODES_LOADING";

export const ADD_MESSAGE = "ADD_MESSAGE";
export const ADD_MESSAGES = "ADD_MESSAGES";
export const ADD_OTHER_MESSAGES = "ADD_OTHER_MESSAGES";
export const REMOVE_MESSAGE = "REMOVE_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const ADD_LOADING = "ADD_LOADING";
export const ADD_REFRESH_LOADING = "ADD_REFRESH_LOADING";
export const REMOVE_LOADING = "REMOVE_LOADING";

//#region Travel Settlement
//Pending Settlement
export const GET_PENDING_SETTLEMENT = "GET_PENDING_SETTLEMENT";
export const GET_TRAVEL_REQUEST_DETAILS = " GET_TRAVEL_REQUEST_DETAILS";
export const GET_OTHER_EXPENSES_LIST = "GET_OTHER_EXPENSES_LIST";
export const GET_BILLS_AND_CLAIMS_LIST = "GET_BILLS_AND_CLAIMS_LIST";
export const HANDLE_SETTLEMENT_ACTIVE = "HANDLE_SETTLEMENT_ACTIVE";
export const SETTLEMENT_LOADING = "SETTLEMENT_LOADING";
export const PENDING_SETTLEMENT_LOADING = "PENDING_SETTLEMENT_LOADING";
export const TRAVEL_REQUEST_DETAILS_LOADING = "TRAVEL_REQUEST_DETAILS_LOADING";
export const REMOVE_OTHER_EXPENSE = "REMOVE_OTHER_EXPENSE";
//Settlement Requests
export const GET_SETTLEMENT_REQUESTS = "GET_SETTLEMENT_REQUESTS";
export const GET_SETTLEMENT_REQUESTS_EXCEL_EXPORT =
  "GET_SETTLEMENT_REQUESTS_EXCEL_EXPORT";
export const GET_SETTLEMENT_APPROVAL_AUTHORITIES =
  "GET_SETTLEMENT_APPROVAL_AUTHORITIES";
export const SETTLEMENT_APPROVAL_AUTHORITIES_LOADING =
  "SETTLEMENT_APPROVAL_AUTHORITIES_LOADING";
export const GET_SETTLEMENT_APPROVAL_HISTORY =
  "GET_SETTLEMENT_APPROVAL_HISTORY";
export const SETTLEMENT_APPROVAL_HISTORY_LOADING =
  "SETTLEMENT_APPROVAL_HISTORY_LOADING";
export const HANDLE_SETTLEMENT_REPORT_ACTIVE =
  "HANDLE_SETTLEMENT_REPORT_ACTIVE";
export const GET_SETTLEMENT_REPORT = "GET_SETTLEMENT_REPORT";
export const SETTLEMENT_REPORT_LOADING = "SETTLEMENT_REPORT_LOADING";
export const GET_SETTLEMENT_AUDIT_LOG = "GET_SETTLEMENT_AUDIT_LOG";
export const SETTLEMENT_AUDIT_LOG_LOADING = "SETTLEMENT_AUDIT_LOG_LOADING";
export const GET_SETTLEMENT_REQUEST_REPORT_PDF =
  "GET_SETTLEMENT_REQUEST_REPORT_PDF";
export const SETTLEMENT_REQUEST_LOADING = "SETTLEMENT_REQUEST_LOADING";
export const HANDLE_SETTLEMENT_SAVE = "HANDLE_SETTLEMENT_SAVE";
//#endregion

//#region Recruitment
export const GET_DASHBOARD_DETAIL = "GET_DASHBOARD_DETAIL";
export const GET_JOB_STAGES = "GET_JOB_STAGES";
export const GET_APPLIED_CANDIDATE_LIST_BY_JOBID =
  "GET_APPLIED_CANDIDATE_LIST_BY_JOBID";
export const GET_CANDIDATE_LIST_BY_JOB_AND_STAGE_ID =
  "GET_CANDIDATE_LIST_BY_JOB_AND_STAGE_ID";
export const GET_CANDIDATE_DETAIL = "GET_CANDIDATE_DETAIL";
export const GET_MILESTONE_DETAIL = "GET_MILESTONE_DETAIL";
export const CHANGE_CANDIDATE_STAGE = "CHANGE_CANDIDATE_STAGE";
export const GET_REQUISITION_NUMBER = "GET_REQUISITION_NUMBER";
export const GET_MY_REQUISITION = "GET_MY_REQUISITION";
export const GET_REQUISITION_DETAILS = "GET_REQUISITION_DETAILS";
export const GET_APPROVER_REQUISITION = "GET_APPROVER_REQUISITION";
export const IS_REQUISITION_DETAILS_LOADING = "IS_REQUISITION_DETAILS_LOADING";
export const SAVE_JOB_REQUISITION = "SAVE_JOB_REQUISITION";
export const SAVE_JOB_APPLY = "SAVE_JOB_APPLY";
export const GET_JOB_DETAIL_BY_ID = "GET_JOB_DETAIL_BY_ID";
export const GET_REQUISITION_LIST_COUNT = "GET_REQUISITION_LIST_COUNT";
export const CANCEL_JOB_REQUISITION = "CANCEL_JOB_REQUISITION";
export const GET_APPROVED_REQUISITION = "GET_APPROVED_REQUISITION";
export const APPROVED_REQUISITION_LOADING = "APPROVED_REQUISITION_LOADING";
export const GET_CANDIDATE_DETAILS_BY_JOB_ID =
  "GET_CANDIDATE_DETAILS_BY_JOB_ID";
export const CANDIDATE_DETAILS_LOADING = "CANDIDATE_DETAILS_LOADING";
export const UPDATE_CANDIDATE_DETAILS = "UPDATE_CANDIDATE_DETAILS";
export const UPDATE_CANDIDATE_DETAILS_FAV = "UPDATE_CANDIDATE_DETAILS_FAV";
export const GET_HIRING_STAGES_BY_JOB_ID = "GET_HIRING_STAGES_BY_JOB_ID";
export const HIRING_STAGES_BY_JOB_ID_LOADING =
  "HIRING_STAGES_BY_JOB_ID_LOADING";

export const GET_HIRING_STAGE_AND_SUBSTAGE_BY_JOB_ID =
  "GET_HIRING_STAGE_AND_SUBSTAGE_BY_JOB_ID";
export const GET_HIRING_STAGE_AND_SUBSTAGE_BY_JOB_ID_LOADING =
  "GET_HIRING_STAGE_AND_SUBSTAGE_BY_JOB_ID_LOADING";

export const UPDATE_TAGS = "UPDATE_TAGS";

// Disciplinary Action Stages
export const GET_DISCIPLINARY_STAGES = "GET_DISCIPLINARY_STAGES";
export const EDIT_DISCIPLINARY_STAGES = "EDIT_DISCIPLINARY_STAGES";
export const DELETE_DISCIPLINARY_SUBSTAGES = "DELETE_DISCIPLINARY_SUBSTAGES";
export const ADD_DISCIPLINARY_SUBSTAGES = "ADD_DISCIPLINARY_SUBSTAGES";
export const EDIT_DISCIPLINARY_SUBSTAGES = "EDIT_DISCIPLINARY_SUBSTAGES";
export const DISCIPLINARY_STAGES_LOADING = "DISCIPLINARY_STAGES_LOADING";
export const GET_DISCIPLINARY_ACTION_LIST = "GET_DISCIPLINARY_LIST";
export const MOVE_DISCIPLINARY_ACTION_TO_NS = "MOVE_DISCIPLINARY_ACTION_TO_NS";

// Disciplinary Action
export const DISCIPLINARY_CASE_DETAILS_LOADING =
  "DISCIPLINARY_CASE_DETAILS_LOADING";
export const GET_DISCIPLINARY_CASE_DETAILS = "GET_DISCIPLINARY_CASE_DETAILS";

//  Dashboard
export const GET_HR_DASHBOARD = "GET_HR_DASHBOARD";
export const GET_DASHBOARD_MENUS = "GET_DASHBOARD_MENUS";
export const ADD_DASHBOARD_MENUS = "ADD_DASHBOARD_MENUS";
export const REMOVE_DASHBOARD_MENUS = "REMOVE_DASHBOARD_MENUS";
export const GET_LEAVE_TIME_DASHBOARD = "GET_LEAVE_TIME_DASHBOARD";
export const GET_LEAVE_BY_DATE = "GET_LEAVE_BY_DATE";
export const LEAVE_LOADING = "LEAVE_LOADING";
export const GET_DASHBOARD_BLOCK = "GET_DASHBOARD_BLOCK";
export const ADD_DASHBOARD_BLOCK = "ADD_DASHBOARD_BLOCK";

//Task Tracker
export const GET_LEGAL_CLIENT_TYPES = "GET_LEGAL_CLIENT_TYPES";
export const GET_LEGAL_CLIENT_LIST = "GET_LEGAL_CLIENT_LIST";
export const GET_LEGAL_CLIENT_BY_ID = "GET_LEGAL_CLIENT_BY_ID";
export const SET_LEGAL_CLIENT_LOADING = "SET_LEGAL_CLIENT_LOADING";
export const GET_PROJECT_ACTIVITIES = "GET_PROJECT_ACTIVITIES";
export const GET_UNSUBMITTED_ACTIVITIES = "GET_UNSUBMITTED_ACTIVITIES";
export const GET_UNSUBMITTED_ACTIVITIES_EXPORT_EXCEL =
  "GET_UNSUBMITTED_ACTIVITIES_EXPORT_EXCEL";
export const GET_PROJECT_ACTIVITY_DETAIL = "GET_PROJECT_ACTIVITY_DETAIL";
export const GET_PROJECTS_FOR_TIME_FUNCTION_FORMATED =
  "GET_PROJECTS_FOR_TIME_FUNCTION_FORMATED";
export const GET_PROJECTS_FOR_TIME_FUNCTION = "GET_PROJECTS_FOR_TIME_FUNCTION";
export const GET_RUNNING_TASK = "GET_RUNNING_TASK";
export const GET_CLIENT_PROJECT_DDL_LIST = "GET_CLIENT_PROJECT_DDL_LIST";
export const GET_BILLING_DESIGNATIONS = "GET_BILLING_DESIGNATIONS";
export const GET_ACTIVE_CLIENTS_LIST = "GET_ACTIVE_CLIENTS_LIST";
export const GET_TODAYS_CLOCK_WORK = "GET_TODAYS_CLOCK_WORK";
export const GET_MY_TEAM_ACTIVITIES = "GET_MY_TEAM_ACTIVITIES";
export const GET_CALENDAR_ACTIVITIES = "GET_CALENDAR_ACTIVITIES";
export const GET_NATURE_OF_WORK_DDL = "GET_NATURE_OF_WORK_DDL";
export const CONTRACT_SAVED = "CONTRACT_SAVED";
export const GET_ACTIVITY_AND_BILLING_ROLES = "GET_ACTIVITY_AND_BILLING_ROLES";
export const GET_HOURS_WORKED_BY_CLIENT = "GET_HOURS_WORKED_BY_CLIENT";
export const GET_HOURS_WORKED_BY_PEOPLE = "GET_HOURS_WORKED_BY_PEOPLE";
export const GET_RAW_REPORT = "GET_RAW_REPORT";
export const GET_HOURS_WORKED_BY_PEOPLE_TO_EXCEL_EXPORT =
  "GET_HOURS_WORKED_BY_PEOPLE_TO_EXCEL_EXPORT";
export const GET_HOURS_COMMITTED = "GET_HOURS_COMMITTED";
export const GET_ACTIVITY_DASHBOARD = "GET_ACTIVITY_DASHBOARD";
export const GET_PEOPLE_IN_PROJECT_VIEW_BY_PROJECT =
  "GET_PEOPLE_IN_PROJECT_VIEW_BY_PROJECT";
export const GET_PEOPLE_IN_PROJECT_VIEW_BY_PEOPLE =
  "GET_PEOPLE_IN_PROJECT_VIEW_BY_PEOPLE";
export const GET_MEMBER_BY_NAME = "GET_MEMBER_BY_NAME";
export const GET_COUNTER_REPORT = "GET_COUNTER_REPORT";

// Settings
export const UPDATE_COMPANNY_SETTINGS = "UPDATE_COMPANNY_SETTINGS";
export const SET_SETTINGS_LOADING = "SET_SETTINGS_LOADING";
export const SAVE_LOAN_APPROVERS = "SAVE_LOAN_APPROVERS";
export const GET_LOAN_APPROVER_LIST_ADMIN = "GET_LOAN_APPROVER_LIST_ADMIN";
export const DELETE_ADMIN_LOAN_APPROVER = "DELETE_ADMIN_LOAN_APPROVER";

//#region job-lisiting
export const IS_ACTIVE_JOBS_LOADING = "IS_ACTIVE_JOBS_LOADING";
export const GET_JOB_DETAILS = "GET_JOB_DETAILS";
export const GET_ACTIVE_JOBS = "GET_ACTIVE_JOBS";
//#endregion
//#endregion

// Roles & Permission
export const GET_REGION_CLUSTER = "GET_REGION_CLUSTER";
export const GET_CLUSTER = "GET_CLUSTER";
export const ADD_CLUSTER = "ADD_CLUSTER";
export const UPDATE_CLUSTER = "UPDATE_CLUSTER";
export const GET_GROUP_MEMBER_USER_POOL_OPTIONS =
  "GET_GROUP_MEMBER_USER_POOL_OPTIONS";

//Modal
export const HANDLE_MODAL_SHOW = "HANDLE_MODAL_SHOW";

//Spinner
export const SET_SPINNER_LOADING = "SET_SPINNER_LOADING";

export const UPLOAD_FILES = "UPLOAD_FILES";
export const UPLOAD_FILE_STATUS = "UPLOAD_FILE_STATUS";

export const IS_LOADING = "IS_LOADING";
export const SET_USER_LOADING = "SET_USER_LOADING";
export const SET_LOGIN_LOADING = "SET_LOGIN_LOADING";

// Employee-Dashboard
export const GET_EMPLOYEE_DASHBOARD_DETAIL = "GET_EMPLOYEE_DASHBOARD_DETAIL";
export const GET_EMPLOYEE_DASHBOARD_MESSAGES =
  "GET_EMPLOYEE_DASHBOARD_MESSAGES";
export const GET_EMPLOYEE_DASHBOARD_NEWS = "GET_EMPLOYEE_DASHBOARD_NEWS";
export const GET_EMPLOYEE_DASHBOARD_LOADING = "GET_EMPLOYEE_DASHBOARD_LOADING";
export const GET_EMPLOYEE_TRAVEL_DASHBOARD_LOADING =
  "GET_EMPLOYEE_TRAVEL_DASHBOARD_LOADING";
export const UPDATE_OFFICE_CONTACT = "UPDATE_OFFICE_CONTACT";
export const GET_EMPLOYEE_LMS_DASHBOARD = "GET_EMPLOYEE_LMS_DASHBOARD";

// onChange Req
export const GET_EMPLOYEE_PROFILE_PENDING_REQUEST =
  "GET_EMPLOYEE_PROFILE_PENDING_REQUEST";
export const POST_EMPLOYEE_HOME_CONTACT = "POST_EMPLOYEE_HOME_CONTACT";
export const POST_EMPLOYEE_OFFICE_CONTACT = "POST_EMPLOYEE_OFFICE_CONTACT";

export const IS_EMPLOYEE_DETAIL_LOADING = "IS_EMPLOYEE_DETAIL_LOADING";

export const IS_EMPLOYEE_PROFILE_LOADING = "IS_EMPLOYEE_PROFILE_LOADING";
export const GET_EMPLOYEE_PROFILE_DETAIL = "GET_EMPLOYEE_PROFILE_DETAIL";
export const GET_EMPLOYEE_MANAGERS_FOR_JOB = "GET_EMPLOYEE_MANAGERS_FOR_JOB";
export const GET_EMPLOYEE_MANAGER_HISTORY = "GET_EMPLOYEE_MANAGER_HISTORY";
export const GET_EMPLOYEE_ROLES_HISTORY = "GET_EMPLOYEE_ROLES_HISTORY";
export const GET_JOB_DESC_BY_LEVEL = "GET_JOB_DESC_BY_LEVEL";
export const ASSIGN_JOB_DESCRIPTION = "ASSIGN_JOB_DESCRIPTION";
export const ASSIGN_JOB_DESCRIPTION_HISTORY = "ASSIGN_JOB_DESCRIPTION_HISTORY";
export const GET_EMPLOYEE_JOB_DESCRIPTION = "GET_EMPLOYEE_JOB_DESCRIPTION";
export const CHANGE_ASSIGNED_JOB_DESCRIPTION =
  "CHANGE_ASSIGNED_JOB_DESCRIPTION";
export const GET_EMPLOYEE_ASSIGNED_JOB_DESCRIPTION =
  "GET_EMPLOYEE_ASSIGNED_JOB_DESCRIPTION";
export const SET_MANAGER_FOR_EMPLOYEE = "SET_MANAGER_FOR_EMPLOYEE";
export const EMPLOYEE_KSA_RATING = "EMPLOYEE_KSA_RATING";
export const UPDATE_EMPLOYEE_KSA_RATING = "UPDATE_EMPLOYEE_KSA_RATING";
export const EMPLOYEE_EDUCATION_LIST = "EMPLOYEE_EDUCATION_LIST";
export const SAVE_EMPLOYEE_EDUCATION_DETAILS =
  "SAVE_EMPLOYEE_EDUCATION_DETAILS";
export const DELETE_EMPLOYEE_EDUCATION_DETAIL =
  "DELETE_EMPLOYEE_EDUCATION_DETAIL";
export const CURRENTLY_SELECTED_EDU_SECTION = "CURRENTLY_SELECTED_EDU_SECTION";
export const GET_EMPLOYEE_TRAINING_LIST = "GET_EMPLOYEE_TRAINING_LIST";
export const SAVE_EMPLOYEE_TRAINING_DETAILS = "SAVE_EMPLOYEE_TRAINING_DETAILS";
export const DELETE_EMPLOYEE_TRAINING_DETAIL =
  "DELETE_EMPLOYEE_TRAINING_DETAIL";
export const GET_EMPLOYEE_EXPERIENCE_LIST = "GET_EMPLOYEE_EXPERIENCE_LIST";
export const SAVE_EMPLOYEE_EXPERIENCE_DETAILS =
  "SAVE_EMPLOYEE_EXPERIENCE_DETAILS";
export const DELETE_EMPLOYEE_EXPERIENCE_DETAIL =
  "DELETE_EMPLOYEE_EXPERIENCE_DETAIL";
export const GET_EMPLOYEE_REFERENCE_LIST = "GET_EMPLOYEE_REFERENCE_LIST";
export const SAVE_EMPLOYEE_REFERENCE_DETAILS =
  "SAVE_EMPLOYEE_REFERENCE_DETAILS";
export const DELETE_EMPLOYEE_REFERENCE_DETAIL =
  "DELETE_EMPLOYEE_REFERENCE_DETAIL";
export const GET_EMPLOYEE_LEAVE_LIST = "GET_EMPLOYEE_LEAVE_LIST";
export const GET_EDUCTION_QUALIFICATION_LIST =
  "GET_EDUCTION_QUALIFICATION_LIST";
export const GET_EMPLOYEE_ADDITIONAL_DOCUMENTS =
  "GET_EMPLOYEE_ADDITIONAL_DOCUMENTS";
export const ADD_EMPLOYEE_ADDITIONAL_DOCUMENTS =
  "ADD_EMPLOYEE_ADDITIONAL_DOCUMENTS";
export const EDIT_EMPLOYEE_ADDITIONAL_DOCUMENTS =
  "EDIT_EMPLOYEE_ADDITIONAL_DOCUMENTS";
export const DELETE_EMPLOYEE_ADDITIONAL_DOCUMENTS =
  "DELETE_EMPLOYEE_ADDITIONAL_DOCUMENTS";
export const GET_EMPLOYEE_INCOMES = "GET_EMPLOYEE_INCOMES";
export const GET_EMPLOYEE_INCOMES_LOADING = "GET_EMPLOYEE_INCOMES_LOADING";
export const GET_EMPLOYEE_DEDUCTIONS = "GET_EMPLOYEE_DEDUCTIONS";
export const GET_EMPLOYEE_DEDUCTIONS_LOADING =
  "GET_EMPLOYEE_DEDUCTIONS_LOADING";
export const GET_EMPLOYEE_ADDRESS_DETAILS = "GET_EMPLOYEE_ADDRESS_DETAILS";
export const SAVE_EMPLOYEE_ADDRESS_DETAILS = "SAVE_EMPLOYEE_ADDRESS_DETAILS";
export const GET_EMPLOYEE_CITIZENSHIP_DETAILS =
  "GET_EMPLOYEE_CITIZENSHIP_DETAILS";
export const GET_EMPLOYEE_DRIVING_DETAILS = "GET_EMPLOYEE_DRIVING_DETAILS";
export const GET_EMPLOYEE_PASSPORT_DETAILS = "GET_EMPLOYEE_PASSPORT_DETAILS";
export const DELETE_EMPLOYEE_DOCS = "DELETE_EMPLOYEE_DOCS";

export const SAVE_EMPLOYEE_FAMILY_INFO = "SAVE_EMPLOYEE_FAMILY_INFO";
export const SAVE_EMPLOYEE_HEALTH_INFO = "SAVE_EMPLOYEE_HEALTH_INFO";
export const EMPLOYEE_FAMILIES_DETAIL = "EMPLOYEE_FAMILIES_DETAIL";
export const EMPLOYEE_HEALTH_DETAIL = "EMPLOYEE_HEALTH_DETAIL";
export const GET_BLOOD_GROUP_LIST = "GET_BLOOD_GROUP_LIST";
export const GET_MY_WORK_SCHEDULE = "GET_MY_WORK_SCHEDULE";
//Employee Dashboard
export const GET_EMPLOYEE_LIST_FOR_ADMIN = "GET_EMPLOYEE_LIST_FOR_ADMIN";
export const GET_EMPLOYEE_DASHBOARD = "GET_EMPLOYEE_DASHBOARD";

export const GET_EMPLOYEE_DASHBOARD_TEAMMEMBERS =
  "GET_EMPLOYEE_DASHBOARD_TEAMMEMBERS";
export const GET_EMPLOYEE_DASHBOARD_ONLEAVE = "GET_EMPLOYEE_DASHBOARD_ONLEAVE";
export const GET_EMPLOYEE_DASHBOARD_ABSENT = "GET_EMPLOYEE_DASHBOARD_ABSENT";
export const GET_EMPLOYEE_DASHBOARD_BIRTHDAYS =
  "GET_EMPLOYEE_DASHBOARD_BIRTHDAYS";

export const GET_EMPLOYEE_TRAVEL_DASHBOARD = "GET_EMPLOYEE_TRAVEL_DASHBOARD";
export const GET_EMPLOYEE_DASHBOARD_JOBINFORMATION =
  "GET_EMPLOYEE_DASHBOARD_JOBINFORMATION";
export const GET_JOBPROFILE_KSA_EDUCATION_LIST =
  "GET_JOBPROFILE_KSA_EDUCATION_LIST";
export const GET_JOBPROFILE_KSA_CERTIFICATION_LIST =
  "GET_JOBPROFILE_KSA_CERTIFICATION_LIST";
export const GET_JOBPROFILE_KSA_SKILL_TYPE = "GET_JOBPROFILE_KSA_SKILL_TYPE";
export const GET_JOBPROFILE_KSA_BY_TYPE = "GET_JOBPROFILE_KSA_BY_TYPE";
export const GET_JOBPROFILE_KSA_EDUCATION_LEVEL =
  "GET_JOBPROFILE_KSA_EDUCATION_LEVEL";
export const GET_JOBPROFILE_KSA_EDUCATION_TYPE =
  "GET_JOBPROFILE_KSA_EDUCATION_TYPE";
export const GET_JOBPROFILE_KSA_CERTIFICATION_TYPE =
  "GET_JOBPROFILE_KSA_CERTIFICATION_TYPE";
export const GET_JOBPROFILE_KSA_EDUCATION_BY_ID =
  "GET_JOBPROFILE_KSA_EDUCATION_BY_ID";
export const GET_JOBPROFILE_KSA_CERTIFICATION_BY_ID =
  "GET_JOBPROFILE_KSA_CERTIFICATION_BY_ID";
export const GET_JOBPROFILE_KSA__BY_ID = "GET_JOBPROFILE_KSA__BY_ID";
export const GET_JOB_PROFILE = "GET_JOB_PROFILE";
export const GET_DEFAULT_JOB_PROFILE = "GET_DEFAULT_JOB_PROFILE";
export const GET_JOB_PROFILE_BY_ID = "GET_JOB_PROFILE_BY_ID";
export const SET_JOB_PROFILE_BY_ID_LOADING = "SET_JOB_PROFILE_BY_ID_LOADING";
export const GET_JOBPROFILE_KNOWLEDGES = "GET_JOBPROFILE_KNOWLEDGES";
export const GET_JOBPROFILE_SKILLS = "GET_JOBPROFILE_SKILLS";
export const GET_JOBPROFILE_ABILITIES = "GET_JOBPROFILE_ABILITIES";
export const GET_JOB_DESCRIPTION = "GET_JOB_DESCRIPTION";
export const GET_JOB_DESCRIPTION_BY_ID = "GET_JOB_DESCRIPTION_BY_ID";
export const GET_JOB_PROFILE_DROP_LIST = "GET_JOB_PROFILE_DROP_LIST";
export const SET_JOB_PROFILE_DROP_LIST_LOADING =
  "SET_JOB_PROFILE_DROP_LIST_LOADING";
export const GET_JOB_DESCRIPTION_DROP_LIST = "GET_JOB_DESCRIPTION_DROP_LIST";
export const SET_JOB_DESCRIPTION_DROP_LIST_LOADING =
  "SET_JOB_DESCRIPTION_DROP_LIST_LOADING";
export const SET_SELECTED_RECORD = "SET_SELECTED_RECORD";
export const GET_COMPANY_HOLIDAYS = "GET_COMPANY_HOLIDAYS";
export const GET_COMPANY_HOLIDAYS_WHOLE = "GET_COMPANY_HOLIDAYS_WHOLE";
export const GET_DEPARTMENTS_TO_HANDLE_PROJECT_CASE =
  "GET_DEPARTMENTS_TO_HANDLE_PROJECT_CASE";
export const DELETE_EDUCATION = "DELETE_EDUCATION";
export const DELETE_CERTIFICATION = "DELETE_CERTIFICATION";
export const DELETE_KSA_LIST_BY_TYPE = "DELETE_KSA_LIST_BY_TYPE";
export const DELETE_SKILL = "DELETE_SKILL";
export const DELETE_ABILITY = "DELETE_ABILITY";
export const DELETE_KSA_LIBRARIES_LOADING = "DELETE_KSA_LIBRARIES_LOADING";

export const GET_TRACKED_ATTENDANCE_LIST = "GET_TRACKED_ATTENDANCE_LIST";
//Adding Project to Clinet
export const GET_PROJECT_MATTER_ID = "GET_PROJECT_MATTER_ID";
export const SAVE_CLIENT_PROJECT = "SAVE_CLIENT_PROJECT";
export const GET_SECRETARIAL_CHARGES_TYPES = "GET_SECRETARIAL_CHARGES_TYPES";
export const GET_PROJECT_DETAILS = "GET_PROJECT_DETAILS";
export const DELETE_PROJECT_CONTRACT = "DELETE_PROJECT_CONTRACT";

export const GET_CONTRACT_DETAILS = "GET_CONTRACT_DETAILS";

//Role and Permission
export const GET_EMPLOYEE_MANAGERS = "GET_EMPLOYEE_MANAGERS";
export const GET_EMPLOYEE_MANAGERS_FOR_EXPORT =
  "GET_EMPLOYEE_MANAGERS_FOR_EXPORT";
export const GET_EMPLOYEE_MANAGERS_BY_ID = "GET_EMPLOYEE_MANAGERS_BY_ID";
export const REPORTING_TO_MANAGERS = "REPORTING_TO_MANAGERS";

export const EDIT_DISCIPLINARY_SUB_STAGES = "EDIT_DISCIPLINARY_SUB_STAGES";
export const GET_EMPLOYEE_LIST_SEARCH = "GET_EMPLOYEE_LIST_SEARCH";

//Feature Flag
export const GET_FEATURE_FLAG = "GET_FEATURE_FLAG";
export const GET_ACTIVE_FEATURE_FLAG = "GET_ACTIVE_FEATURE_FLAG";
export const GET_ACTIVE_FEATURES_CATEGORIES = "GET_ACTIVE_FEATURES_CATEGORIES";

//#region Employee leave
export const GET_EMPLOYEE_LEAVE_LIST2 = "GET_EMPLOYEE_LEAVE_LIST2";
export const GET_LEAVE_REQUEST_TYPE = "GET_LEAVE_REQUEST_TYPE";
export const GET_LEAVE_REQUEST_APPROVER = "GET_LEAVE_REQUEST_APPROVER";
export const GET_LEAVE_REQUEST_BY_ID = "GET_LEAVE_REQUEST_BY_ID";
export const GET_TEAM_MEMBER_LEAVE_DETAILS = "GET_TEAM_MEMBER_LEAVE_DETAILS";
export const SET_LEAVE_REQUEST_TYPE_LOADING = "SET_LEAVE_REQUEST_TYPE_LOADING";
export const SET_LEAVE_REQUEST_APPROVER_LOADING =
  "SET_LEAVE_REQUEST_APPROVER_LOADING";
export const SAVE_EMPLOYEE_OVERTIME_REQUESTS =
  "SAVE_EMPLOYEE_OVERTIME_REQUESTS";
export const EMPLOYEE_SINGLE_OVERTIME_REQUEST =
  "EMPLOYEE_SINGLE_OVERTIME_REQUEST";
export const EMPLOYEE_OVERTIME_HISTORY = "EMPLOYEE_OVERTIME_HISTORY";
export const GET_OVERTIME_TYPES = "GET_OVERTIME_TYPES";
export const EMPLOYEE_OVERTIME_REVIEWAPPROVAL_LIST =
  "EMPLOYEE_OVERTIME_REVIEWAPPROVAL_LIST";
export const SET_LEAVE_REQUEST_DETAILS_LOADING =
  "SET_LEAVE_REQUEST_DETAILS_LOADING";
export const GET_LEAVE_REQUEST_DAYS = "GET_LEAVE_REQUEST_DAYS";
export const SET_LEAVE_REQUEST_DAYS_LOADING = "SET_LEAVE_REQUEST_DAYS_LOADING";
export const CLEAR_LEAVE_REQUEST_DATAS = "CLEAR_LEAVE_REQUEST_DATAS";
//#endregion

//#region Employee time
export const GET_TIME_REQUEST_DETAIL = "GET_TIME_REQUEST_DETAIL";
export const GET_TIME_REQUEST_APPROVAL_RECOMMENDED =
  "GET_TIME_REQUEST_APPROVAL_RECOMMENDED";
export const GET_ATTENDANCE_BY_DATE = "GET_ATTENDANCE_BY_DATE";
export const CHECK_CLOCK_IN_OUT_AUTHORITY = "CHECK_CLOCK_IN_OUT_AUTHORITY";
export const GET_ATTENDANCE_REPORT = "GET_ATTENDANCE_REPORT";
export const SAVE_ATTENDANCE_TIME = "SAVE_ATTENDANCE_TIME";
export const SAVE_ATTENDANCE_NOTE = "SAVE_ATTENDANCE_NOTE";
export const CREATE_TIME_REQUEST_REQUEST = "CREATE_TIME_REQUEST_REQUEST";
export const CREATE_TIME_REQUEST_SUCCESS = "CREATE_TIME_REQUEST_SUCCESS";
export const CREATE_TIME_REQUEST_FAILURE = "CREATE_TIME_REQUEST_FAILURE";
export const UPDATE_TIME_REQUEST_REQUEST = "UPDATE_TIME_REQUEST_REQUEST";
export const UPDATE_TIME_REQUEST_SUCCESS = "UPDATE_TIME_REQUEST_SUCCESS";
export const UPDATE_TIME_REQUEST_FAILURE = "UPDATE_TIME_REQUEST_FAILURE";
export const GET_SHIFT_NAMES_LIST = "GET_SHIFT_NAMES_LIST";
//#endregion

//#region Employee request
export const GET_EMPLOYEE_REVIEW_REQUEST = "GET_EMPLOYEE_REVIEW_REQUEST";
export const UPDATE_EMPLOYEE_REVIEW_REQUEST = "UPDATE_EMPLOYEE_REVIEW_REQUEST";
export const GET_MY_REQUESTS = "GET_MY_REQUESTS";
export const GET_TEAM_REQUEST_HISTORY = "GET_TEAM_REQUEST_HISTORY";
export const GET_MY_REQUEST_HISTORY = "GET_MY_REQUEST_HISTORY";
export const SET_EMPLOYEE_REVIEW_REQUEST_LOADING =
  "SET_EMPLOYEE_REVIEW_REQUEST_LOADING";
export const SET_MY_REQUESTS_LOADING = "SET_MY_REQUESTS_LOADING";
export const SET_TEAM_REQUEST_HISTORY_LOADING =
  "SET_TEAM_REQUEST_HISTORY_LOADING";
export const SET_MY_REQUEST_HISTORY_LOADING = "SET_MY_REQUEST_HISTORY_LOADING";
export const GET_EMPLOYEE_REQUEST_COUNT = "GET_EMPLOYEE_REQUEST_COUNT";
export const GET_EMPLOYEE_REQUEST_AUDIT_LOG = "GET_EMPLOYEE_REQUEST_AUDIT_LOG";
export const SET_EMPLOYEE_REQUEST_AUDIT_LOG_LOADING =
  "SET_EMPLOYEE_REQUEST_AUDIT_LOG_LOADING";
//#endregion

// Notifications
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const SET_NOTIFICATIONS_READ = "SET_NOTIFICATIONS_READ";
export const SET_NOTIFICATIONS_LOADING = "SET_NOTIFICATIONS_LOADING";
export const GET_NOTIFICATIONS_LOGS = "GET_NOTIFICATIONS_LOGS";

//#region Employee allowance request
export const EMPLOYEE_ALLOWANCE_HISTORY = "EMPLOYEE_ALLOWANCE_HISTORY";
export const EMPLOYEE_ALLOWANCE_TYPELIST = "EMPLOYEE_ALLOWANCE_TYPELIST";
export const EMPLOYEE_ALLOWANCE_APPROVER_REVIEWER_LIST =
  "EMPLOYEE_ALLOWANCE_APPROVER_REVIEWER_LIST";
export const SAVE_EMPLOYEE_ALLOWANCE_REQUESTS =
  "SAVE_EMPLOYEE_ALLOWANCE_REQUESTS";
//#endregion

//Allowance && Overtime Management
export const GET_ALLOWANCES = "GET_ALLOWANCES";
export const GET_OVERTIME_REQUEST = "GET_OVERTIME_REQUEST";

// loan request module
export const SAVE_HOME_LOAN_REQUEST = "SAVE_HOME_LOAN_REQUEST";
export const GET_HOME_LOAN_PURPOSE_LIST = "GET_HOME_LOAN_PURPOSE_LIST";
export const GET_CONSTRUCTION_STAGES = "GET_CONSTRUCTION_STAGES";
export const GET_HOME_LOAN_DETAILS = "GET_HOME_LOAN_DETAILS";
export const GET_VEHICLE_LOAN_PURPOSE_LIST = "GET_VEHICLE_LOAN_PURPOSE_LIST";
export const SAVE_VEHICLE_LOAN_REQUEST = "SAVE_VEHICLE_LOAN_REQUEST";
export const GET_VEHICLE_LOAN_DETAILS = "GET_VEHICLE_LOAN_DETAILS";
export const GET_STAFFOD_LOAN_PURPOSE_LIST = "GET_STAFFOD_LOAN_PURPOSE_LIST";
export const SAVE_STAFFOD_LOAN_REQUEST = "SAVE_STAFFOD_LOAN_REQUEST";
export const GET_STAFFOD_LOAN_DETAILS = "GET_STAFFOD_LOAN_DETAILS";
export const SAVE_STAFF_PERSONAL_LOAN_REQUEST =
  "SAVE_STAFF_PERSONAL_LOAN_REQUEST";
export const GET_STAFF_PERSONAL_LOAN_DETAILS =
  "GET_STAFF_PERSONAL_LOAN_DETAILS";
export const GET_EMPLOYEE_OUTSTANDING_LOAN = "GET_EMPLOYEE_OUTSTANDING_LOAN";
export const GET_PAYROLL_LOAN_HEADINGS = "GET_PAYROLL_LOAN_HEADINGS";
export const PULL_EMPLOYEE_OUTSTANDING_LOAN = "PULL_EMPLOYEE_OUTSTANDING_LOAN";
export const SAVE_EMPLOYEE_OUTSTANDING_LOAN = "SAVE_EMPLOYEE_OUTSTANDING_LOAN";
export const SAVE_MULTIPLE_OUTSTANDING_LOAN = "SAVE_MULTIPLE_OUTSTANDING_LOAN";
export const EMPLOYEE_DETAIL_FOR_LOAN = "EMPLOYEE_DETAIL_FOR_LOAN";
export const CHANGE_LOAN_RECOMMENDATION = "CHANGE_LOAN_RECOMMENDATION";
export const GET_LOAN_LIST_FOR_ADMIN = "GET_LOAN_LIST_FOR_ADMIN";
export const GET_PRIME_LOAN_SALARY_HEAD = "GET_PRIME_LOAN_SALARY_HEAD";
export const EMPLOYEE_LOAN_PULLED_STATUS = "EMPLOYEE_LOAN_PULLED_STATUS";
export const DELETE_PRIME_OUTSTANDING_LOAN = "DELETE_PRIME_OUTSTANDING_LOAN";
export const SAVE_MULTIPLE_PRIMELOAN_SALARY_HEAD =
  "SAVE_MULTIPLE_PRIMELOAN_SALARY_HEAD";
export const EMPLOYEE_APPLIED_LOAN_LIST = "EMPLOYEE_APPLIED_LOAN_LIST";
export const GET_LOAN_APPROVER_LIST = "GET_LOAN_APPROVER_LIST";
export const GET_FORWARED_LOAN_LIST_TO_REVIEW =
  "GET_FORWARED_LOAN_LIST_TO_REVIEW";
export const FORWARD_LOAN_TO_FINANCE = "FORWARD_LOAN_TO_FINANCE";
export const FORWARD_LOAN_TO_HR = "FORWARD_LOAN_TO_HR";
export const FORWARD_LOAN_TO_CAD = "FORWARD_LOAN_TO_CAD";
export const SET_USER_LOAN_MODULE_ROLE = "SET_USER_LOAN_MODULE_ROLE";
export const APPROVE_OR_REJECT_LOAN_STATUS = "APPROVE_OR_REJECT_LOAN_STATUS";
export const ASSIGN_FORWARED_LOAN = "ASSIGN_FORWARED_LOAN";
export const ADD_PRIME_LOAN_COMMENT = "ADD_PRIME_LOAN_COMMENT";
export const PRIME_LOAN_COMMENTS = "PRIME_LOAN_COMMENTS";
export const DELETE_PRIME_LOAN_COMMENTS = "DELETE_PRIME_LOAN_COMMENTS";
export const GET_IS_HR_OR_FINANCE = "GET_IS_HR_OR_FINANCE";
export const PRIME_LOAN_PRINT_DETAIL = "PRIME_LOAN_PRINT_DETAIL";
export const LOGIN_VIA_VIANET = "LOGIN_VIA_VIANET";
