export const validFileExtension = ["txt", "csv"];

export const validImageExtension = ["png", "jpg", "jpeg"];

export const validApplicationExtension = ["pdf", "xml"];

export const validUploadDocExtension = [
  "png",
  "jpg",
  "jpeg",
  "pdf",
  "docx",
  "xlsx",
];
