import React from 'react'
import EmpHeader from "scenes/common/header";

function OnboardLayout(props) {
    const { isEmployee } = props
    return (
        <div>
            {isEmployee ? (
                <div className="emp-dashboard">
                    {' '}
                    <EmpHeader />
                </div>
            ) : (
                <EmpHeader />
            )}
            <div className="emp-dashboard">{props.children}</div>
        </div>
    )
}

export default OnboardLayout