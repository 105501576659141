import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { DisplayClientSideMessage } from "actions/commonAction";
import classnames from "classnames";
import FormGroup from "components/form";
import isEmpty from "isEmpty";
import React, { Component } from "react";
import FlipMove from "react-flip-move";
import { AiFillPlusCircle } from "react-icons/ai";
import { FaRegTimesCircle } from "react-icons/fa";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { connect } from "react-redux";
import Select from "react-select";

import { GetTravelCategoryList } from "../travelInformation/actions";
export class Create_TravelInformationTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      enterLeaveAnimation: "accordianVertical",
      // selectedDateRangeDay: 1,
      selectedDateRangeDay: null,
      // selectedDateRangeNight: 0,
      selectedDateRangeNight: null,
      dateSelected: false,
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.setDateRangeRef = this.setDateRangeRef.bind(this);
    //this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    if (
      this.props.travelInformationState &&
      this.props.travelInformationState.travelTypeId
    ) {
      //this.props.GetTravelCategoryList(this.props.travelInformationState.travelTypeId);
    }
  }

  setWrapperRef(node) {
    if (!isEmpty(node)) {
      let allNodes = !isEmpty(this.wrapperRef) ? this.wrapperRef : [];
      allNodes.push({ Id: "ref" + node.tabIndex, Node: node });
      this.wrapperRef = allNodes;
    }
  }
  setDateRangeRef(node) {
    if (!isEmpty(node)) {
      let allNodes = !isEmpty(this.dateRangeRef) ? this.dateRangeRef : [];
      allNodes.push({ Id: "ref" + node.tabIndex, Node: node });
      this.dateRangeRef = allNodes;
    }
  }
  handleDropdown = (name, selected) => {
    this.props.handleTravelInfoTabState &&
      this.props.handleTravelInfoTabState(name, selected);
  };

  handleDropdownForMultiple = (name, selected, key) => {
    let { selectedTravelDestList } = this.props.travelInformationState;
    let selectedTravelDest = selectedTravelDestList.find((f) => f.key === key);

    if (!isEmpty(selectedTravelDest)) {
      selectedTravelDest.travelDestination[name] = selected;
      selectedTravelDestList[key].travelDestination =
        selectedTravelDest.travelDestination;

      this.props.handleTravelInfoTabState &&
        this.props.handleTravelInfoTabState(
          "selectedTravelDestList",
          selectedTravelDestList
        );
    }
  };

  handleMultiSelect = (list, selected, key) => {
    if (isEmpty(list)) {
      list = [];
    }
    let { selectedTravelDestList } = this.props.travelInformationState;
    let selectedTravelDest = selectedTravelDestList.find((f) => f.key === key);

    if (!isEmpty(selectedTravelDest)) {
      selectedTravelDest.travelDestination.selectedModeOfTransport = list;
      selectedTravelDestList[key].travelDestination =
        selectedTravelDest.travelDestination;

      this.props.handleTravelInfoTabState &&
        this.props.handleTravelInfoTabState(
          "selectedTravelDestList",
          selectedTravelDestList
        );
    }
  };

  handleHostAndHotelDropdown = (event, selected, key, name) => {
    let { selectedTravelDestList } = this.props.travelInformationState;
    let selectedTravelDest = selectedTravelDestList.find((f) => f.key === key);
    let label = !isEmpty(selected) ? selected.label : null;
    let value = !isEmpty(selected) ? selected.value : null;

    if (!isEmpty(selected)) {
      if (!isEmpty(selectedTravelDest)) {
        const selectedPhoneDetail = this.props.travelHostAndHotelPhoneList.find(
          (f) => f.value === value
        );
        const selectedPhoneNumber = !isEmpty(selectedPhoneDetail)
          ? selectedPhoneDetail.label
          : "";

        selectedTravelDest.travelDestination[name] = isEmpty(selected)
          ? []
          : selected;
        if (name === "selectedHost") {
          selectedTravelDest.travelDestination.hostName = label;
        } else if (name === "selectedHotel") {
          selectedTravelDest.travelDestination.hotelName = label;
        }

        selectedTravelDest.travelDestination.phoneNumber = selectedPhoneNumber;
      }
    } else {
      if (!isEmpty(selectedTravelDest)) {
        selectedTravelDest.travelDestination[name] = [];
        if (name === "selectedHost") {
          selectedTravelDest.travelDestination.hostName = "";
        } else if (name === "selectedHotel") {
          selectedTravelDest.travelDestination.hotelName = "";
        }
      }
    }
    selectedTravelDestList[key].travelDestination =
      selectedTravelDest.travelDestination;

    this.props.handleTravelInfoTabState &&
      this.props.handleTravelInfoTabState(
        "selectedTravelDestList",
        selectedTravelDestList
      );
  };

  handleTextChange = (name, value) => {
    //const { name, value } = e && e.target;
    this.props.handleTravelInfoTabState &&
      this.props.handleTravelInfoTabState(name, value);
  };

  handleTextChangeForMultiple = (name, value, key) => {
    //const { name, value } = e && e.target;

    let { selectedTravelDestList } = this.props.travelInformationState;
    let selectedTravelDest = selectedTravelDestList.find((f) => f.key === key);

    if (!isEmpty(selectedTravelDest)) {
      selectedTravelDest.travelDestination[name] = value;
      selectedTravelDestList[key].travelDestination =
        selectedTravelDest.travelDestination;

      this.props.handleTravelInfoTabState &&
        this.props.handleTravelInfoTabState(
          "selectedTravelDestList",
          selectedTravelDestList
        );
      this.props.handleDateChange && this.props.handleDateChange();
    }
  };

  handleDateSelectForMultiple = (range, key) => {
    let { startDate, endDate } = range;
    if (isEmpty(endDate)) {
      endDate = startDate;
    }

    let { selectedTravelDestList } = this.props.travelInformationState;
    let selectedTravelDest = selectedTravelDestList.find((f) => f.key === key);

    if (!isEmpty(selectedTravelDest)) {
      selectedTravelDest.travelDestination.startDate = startDate;
      selectedTravelDest.travelDestination.startDateEng = startDate;

      selectedTravelDest.travelDestination.endDate = endDate;
      selectedTravelDest.travelDestination.endDateEng = endDate;

      //Calculate day and night starts
      const date1 = new Date(startDate);
      const date2 = new Date(endDate);
      const Difference_In_Time = date2.getTime() - date1.getTime();
      // const Difference_In_Time = date2 - date1;
      const Difference_In_Days = Math.floor(
        Difference_In_Time / (1000 * 3600 * 24)
      );

      //Selected Travel Destination days and night
      selectedTravelDest.travelDestination.days = Difference_In_Days + 1;
      selectedTravelDest.travelDestination.nights =
        Difference_In_Days > 0 ? Difference_In_Days : 0;

      //Calculate day and night ends

      selectedTravelDestList[key].travelDestination =
        selectedTravelDest.travelDestination;

      this.props.handleTravelInfoTabState &&
        this.props.handleTravelInfoTabState(
          "selectedTravelDestList",
          selectedTravelDestList,
          true
        );
      this.props.handleDateChange && this.props.handleDateChange();
      this.setState({
        selectedDateRangeDay: Difference_In_Days,
        selectedDateRangeNight: Difference_In_Days - 1,
        dateSelected: true,
      });
      if (this.props.handleResetSelectedTravelCategory) {
        this.props.handleResetSelectedTravelCategory();
      }
    }
  };

  handleAddNewPlaceInformation = () => {
    const { companyInformation } = this.props;

    let { selectedTravelDestList } = this.props.travelInformationState;
    let newKey = selectedTravelDestList.length;
    let newTravelDest = {
      key: newKey,
      travelDestination: this.props.createTravelDestination,
    };

    let prevDestEndDate =
      selectedTravelDestList[selectedTravelDestList.length - 1]
        .travelDestination.endDateEng;
    if (!prevDestEndDate) {
      let message = {
        MessageType: "danger",
        Message: "Please add duration of previous destination.",
      };
      this.props.DisplayClientSideMessage(message);
      return;
    }

    selectedTravelDestList.push(newTravelDest);
    this.props.handleTravelInfoTabState &&
      this.props.handleTravelInfoTabState(
        "selectedTravelDestList",
        selectedTravelDestList
      );
  };

  handleRemovePlaceInformation = (key) => {
    let { selectedTravelDestList } = this.props.travelInformationState;
    selectedTravelDestList = selectedTravelDestList.filter((f) => f.key != key);
    selectedTravelDestList = selectedTravelDestList.map((f, index) => {
      f.key = index;
      return f;
    });
    this.props.handleTravelInfoTabState &&
      this.props.handleTravelInfoTabState(
        "selectedTravelDestList",
        selectedTravelDestList,
        true
      );
  };

  handleTravelTypeChange = (typeId) => {
    const { travelInformationState } = this.props;
    if (
      travelInformationState?.travelTypeId !== typeId &&
      this.props.handleResetSelectedTravelCategory
    ) {
      this.props.handleResetSelectedTravelCategory();
    }
    this.props.handleTravelInfoTabState &&
      this.props.handleTravelInfoTabState("travelTypeId", typeId);
    this.props.GetTravelCategoryList(typeId);
  };

  handleDestinationTypeChange = (typeId) => {
    const { selectedTravelDestList } = this.props.travelInformationState;
    if (typeId === 1 && selectedTravelDestList.length > 1) {
      let message = {
        MessageType: "danger",
        Message: "Please remove multiple destinations first.",
      };
      this.props.DisplayClientSideMessage(message);
    } else {
      this.props.handleTravelInfoTabState &&
        this.props.handleTravelInfoTabState("destinationTypeId", typeId);
    }
  };

  handleDateClick = (event, key) => {
    if (
      this.wrapperRef
        .find((x) => x.Id === "ref" + key)
        .Node.contains(event.target) &&
      !this.dateRangeRef
        .find((x) => x.Id === "ref" + key)
        .Node.contains(event.target)
    )
      this.wrapperRef
        .find((x) => x.Id === "ref" + key)
        .Node.classList.toggle("active");
  };

  render() {
    let {
      selectedCountry,
      selectedCurrency,
      travelTypeId,
      destinationTypeId,
      reason,
      selectedTravelDestList,
    } = this.props.travelInformationState;
    const {
      countryList,
      currencyList,
      districtList,
      hideTravelTypeBadges,
      isAllRequiredFieldValid,
      isViewMode,
      modeOfTransportList,
      travelHostList,
      travelHotelList,
    } = this.props;
    const { selectedDateRangeDay, selectedDateRangeNight, dateSelected } =
      this.state;

    const travelClasses = (typeId) => {
      return classnames({
        badges: true,
        "badges-rounded": true,
        "left-icon": travelTypeId === typeId,
        "badges-primary": travelTypeId === typeId,
      });
    };

    const addNewPlaceBlock = (key, destination, isViewMode) => {
      const customStyles = {
        control: (base, state) => ({
          ...base,
          borderColor:
            !isAllRequiredFieldValid &&
            destination.selectedModeOfTransport.length === 0
              ? "#de2e21"
              : "#b7b7b7",
        }),
      };

      return (
        <div className="travel-detail-information" key={key}>
          <div className="flex row-num">
            {destinationTypeId === 2 && (
              <div className="">
                <span className="badge lg badge-dark-light">{key + 1}</span>
              </div>
            )}
            {destinationTypeId === 2 && selectedTravelDestList.length > 1 && (
              <div className="">
                <div
                  className={"icon-btn"}
                  onClick={() => this.handleRemovePlaceInformation(key)}
                >
                  <FaRegTimesCircle />
                </div>
              </div>
            )}
          </div>
          {travelTypeId === 1 ? (
            <div className="travel-detail-row">
              <div className="travel-field">
                <label>District</label>
                <FormGroup
                  error={
                    !isAllRequiredFieldValid &&
                    destination.selectedDistrict.length === 0
                  }
                  formName="reactselect"
                  placeholder="Select district"
                  name="selectedDistrict"
                  onChange={(name, selected) =>
                    this.handleDropdownForMultiple(name, selected, key)
                  }
                  value={destination.selectedDistrict}
                  disabled={isViewMode}
                  options={districtList}
                  width="250px"
                />

                {!isAllRequiredFieldValid &&
                destination.selectedDistrict.length === 0 ? (
                  <span className="auto-error__message">Required</span>
                ) : null}
              </div>
            </div>
          ) : null}

          <div className="travel-detail-row">
            <div className="travel-field">
              <label>Place</label>
              <FormGroup
                name={"placeName"}
                placeholder="type destination"
                type="text"
                className="no-margin"
                label=""
                error={
                  !isAllRequiredFieldValid && isEmpty(destination.placeName)
                    ? "Required"
                    : null
                }
                value={
                  !isEmpty(destination.placeName) ? destination.placeName : ""
                }
                onChange={(name, value) =>
                  this.handleTextChangeForMultiple(name, value, key)
                }
                disabled={isViewMode}
                width="250px"
              />
            </div>
            <div className="travel-field">
              <label>Mode of transportation</label>
              <Select
                className="isMulti"
                closeMenuOnSelect={false}
                components={{
                  IndicatorSeparator: () => null,
                }}
                error={
                  !isAllRequiredFieldValid &&
                  destination.selectedModeOfTransport.length === 0
                }
                isDisabled={isViewMode}
                isMulti
                onChange={(list, selected) =>
                  this.handleMultiSelect(list, selected, key)
                }
                options={modeOfTransportList}
                styles={customStyles}
                tabSelectsValue={false}
                value={destination.selectedModeOfTransport}
              />
              {!isAllRequiredFieldValid &&
              destination.selectedModeOfTransport.length === 0 ? (
                <span className="auto-error__message mt-xxsm">Required</span>
              ) : null}
            </div>

            <div className="travel-field">
              <label>Duration</label>
              <FormGroup
                disabled={isViewMode}
                enableOkButton
                error={
                  !isAllRequiredFieldValid &&
                  (isEmpty(destination.startDateEng) ||
                    isEmpty(destination.endDateEng))
                }
                formName="customdateinput"
                isDateRange
                name="startDate"
                onChange={(name, range) =>
                  this.handleDateSelectForMultiple(range, key)
                }
                placeholder="Select date"
                value={{
                  startDate: destination?.startDateEng ?? "",
                  endDate: destination?.endDateEng ?? "",
                }}
                validators={["required"]}
                width="250px"
              />
              {!isAllRequiredFieldValid &&
              (isEmpty(destination.startDateEng) ||
                isEmpty(destination.endDateEng)) ? (
                <span className="auto-error__message">Required</span>
              ) : null}
            </div>
            <div className="travel-field small-input">
              <label>Days</label>
              <FormGroup
                name={"days"}
                className="small-input"
                type="number"
                value={!isEmpty(destination.days) ? destination.days : 0}
                onChange={(name, value) =>
                  this.handleTextChangeForMultiple(name, value, key)
                }
                disabled
                maxValue={dateSelected ? selectedDateRangeDay : ""}
              />
            </div>
            <div className="travel-field small-input">
              <label>Nights</label>
              <FormGroup
                name={"nights"}
                className="small-input"
                type="number"
                value={!isEmpty(destination.nights) ? destination.nights : 0}
                onChange={(name, value) =>
                  this.handleTextChangeForMultiple(name, value, key)
                }
                disabled
                maxValue={dateSelected ? selectedDateRangeNight : ""}
                minValue={0}
              />
            </div>
          </div>

          <div className="travel-detail-row">
            <div
              className={classnames({
                "travel-field autocomplete": true,
              })}
            >
              <label>Host</label>

              <Autocomplete
                freeSolo
                style={{ width: 250 }}
                options={travelHostList.map((option) => option.label)}
                includeInputInList
                autoComplete
                value={destination.hostName}
                onChange={(event, value) => {
                  let selected = travelHostList.find((x) => x.label === value);
                  this.handleHostAndHotelDropdown(
                    event,
                    selected,
                    key,
                    "selectedHost"
                  );
                }}
                disableClearable={isViewMode}
                disabled={isViewMode}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name={"hostName"}
                    placeholder={"Type or select"}
                    variant="outlined"
                    fullWidth
                    onChange={(event) => {
                      let { name, value } = event.target;
                      this.handleTextChangeForMultiple(name, value, key);
                    }}
                    disabled={isViewMode}
                  />
                )}
                renderOption={(option) => {
                  return (
                    <div className="input-row">
                      <span className="input-value">{option}</span>
                    </div>
                  );
                }}
              />
            </div>

            <div
              className={classnames({
                "travel-field autocomplete": true,
              })}
            >
              <label>Hotel</label>
              <Autocomplete
                freeSolo
                style={{ width: 250 }}
                options={travelHotelList.map((option) => option.label)}
                includeInputInList
                autoComplete
                value={destination.hotelName}
                onChange={(event, value) => {
                  let selected = travelHotelList.find((x) => x.label === value);
                  this.handleHostAndHotelDropdown(
                    event,
                    selected,
                    key,
                    "selectedHotel"
                  );
                }}
                disabled={isViewMode}
                disableClearable={isViewMode}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name={"hotelName"}
                    placeholder={"Type or select"}
                    variant="outlined"
                    fullWidth
                    onChange={(event) => {
                      let { name, value } = event.target;
                      this.handleTextChangeForMultiple(name, value, key);
                    }}
                    disabled={isViewMode}
                  />
                )}
                renderOption={(option) => {
                  return (
                    <div className="input-row">
                      <span className="input-value">{option}</span>
                    </div>
                  );
                }}
              />
            </div>

            <div className="travel-field">
              <label>Host or Hotel Phone Number</label>
              <FormGroup
                disabled={isViewMode}
                error={
                  !isAllRequiredFieldValid &&
                  isEmpty(destination.phoneNumber) &&
                  (!isEmpty(destination.hostName) ||
                    !isEmpty(destination.hotelName))
                    ? "Required"
                    : null
                }
                label=""
                name={"phoneNumber"}
                onChange={(name, value) =>
                  this.handleTextChangeForMultiple(name, value, key)
                }
                placeholder="host or hotel phone number"
                type="number"
                value={
                  !isEmpty(destination.phoneNumber)
                    ? destination.phoneNumber
                    : ""
                }
                width="250px"
                validators={["phone"]}
              />
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className="travel-info-create pl">
        <div className="travel-create__title">
          <h3>Travel Information</h3>
        </div>
        <div className="travel-create__body">
          {!hideTravelTypeBadges && (
            <div className="badges-group">
              <div
                className={travelClasses(1)}
                onClick={() =>
                  isViewMode ? null : this.handleTravelTypeChange(1)
                }
              >
                {travelTypeId === 1 ? <IoIosCheckmarkCircle /> : null}
                Domestic travel
              </div>
              <div
                className={travelClasses(2)}
                onClick={() =>
                  isViewMode ? null : this.handleTravelTypeChange(2)
                }
              >
                {travelTypeId === 2 ? <IoIosCheckmarkCircle /> : null}
                International travel
              </div>
            </div>
          )}

          {travelTypeId === 2 ? (
            <div className="travel-detail-row" style={{ alignItems: "unset" }}>
              <div className="travel-field">
                <label>Country</label>
                <FormGroup
                  disabled={isViewMode}
                  error={!isAllRequiredFieldValid && isEmpty(selectedCountry)}
                  formName="reactselect"
                  name="selectedCountry"
                  onChange={this.handleDropdown}
                  options={countryList}
                  placeholder="Select country"
                  value={selectedCountry}
                  width="250px"
                />
                {!isAllRequiredFieldValid && isEmpty(selectedCountry) ? (
                  <span className="auto-error__message">Required</span>
                ) : null}
              </div>

              <div className="travel-field">
                <label>Currency</label>
                <FormGroup
                  disabled={isViewMode}
                  error={!isAllRequiredFieldValid && isEmpty(selectedCountry)}
                  formName="reactselect"
                  name="selectedCurrency"
                  onChange={this.handleDropdown}
                  options={currencyList}
                  placeholder="Select currency"
                  value={selectedCurrency}
                  width="250px"
                />
                {!isAllRequiredFieldValid && isEmpty(selectedCurrency) ? (
                  <span className="auto-error__message">Required</span>
                ) : null}
              </div>
            </div>
          ) : null}
          <FlipMove>
            {selectedTravelDestList.map((data) => {
              if (!isEmpty(data.travelDestination)) {
                return addNewPlaceBlock(
                  data.key,
                  data.travelDestination,
                  isViewMode
                );
              }
            })}
          </FlipMove>
          {destinationTypeId === 2 && !isViewMode && (
            <div style={{ display: "block", padding: "20px 0 10px" }}>
              <button
                className="btn-rounded blue left-icon"
                onClick={this.handleAddNewPlaceInformation}
              >
                <AiFillPlusCircle />
                Add place
              </button>
            </div>
          )}

          <div className="travel-field">
            <FormGroup
              disabled={isViewMode}
              error={
                !isAllRequiredFieldValid && isEmpty(reason) ? "Required" : null
              }
              formName="textarea"
              label="Reason"
              maxLength={4000}
              name="reason"
              onChange={this.handleTextChange}
              placeholder="type why you are travelling.."
              value={reason}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  countryList: state.commonReducer.countryList,
  currencyList: state.commonReducer.currencyList,
  districtList: state.commonReducer.districtList,
  modeOfTransportList: state.travelInformationReducer.modeOfTransportList,
  travelHostList: state.travelInformationReducer.travelHostList,
  travelHotelList: state.travelInformationReducer.travelHotelList,
  travelHostAndHotelPhoneList:
    state.travelInformationReducer.travelHostAndHotelPhoneList,
  companyInformation: state.commonReducer.companyInformation,
});
const mapDispatchToProps = {
  DisplayClientSideMessage,
  GetTravelCategoryList,
};

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(Create_TravelInformationTab)
);
