import * as fromInterface from "../../../ts";
import { AttendenceDeviceRulesState } from "./slice";

const selectAttendenceDeviceRulesCurrentSlice = (
  state: any
): AttendenceDeviceRulesState => {
  return state.dataStore.attendanceDeviceRules;
};

export const selectAttendenceDeviceRulesStatus = (
  state: any
): fromInterface.loadingType => {
  return selectAttendenceDeviceRulesCurrentSlice(state).status;
};

export const selectAttendenceDeviceRulesLoaded = (state: any): boolean => {
  return selectAttendenceDeviceRulesCurrentSlice(state).loaded;
};

export const selectAttendenceDeviceRulesItems = (state: any) => {
  return selectAttendenceDeviceRulesCurrentSlice(state).items;
};
