import React from 'react'
import SetupCompleteImage from 'assets/vectors/authSetupComplete.svg';
import { Box, Text, Image } from "@chakra-ui/react";
import { Button } from "@rigotech/ui";
import { useHistory } from 'react-router';
import RouteEnum from 'constants/routeEnum';

function Completed() {
  const history = useHistory();
  const onComplete = () =>{
    history.push(RouteEnum.HRDashboardPath);
  }
  return (
    <Box maxW={"600px"} margin="48px auto" display={"flex"} flexDir={"column"} gap="0px" alignItems="center" padding="36px" >
        <Image src={SetupCompleteImage} w="200px"  />
        <Text color="#030511" fontSize="4xl" fontWeight={700}  textAlign="center">Setup Complete</Text>
        <Text color="#465060" fontSize="md" textAlign="center" margin="32px 0 " fontWeight={500} >Next time you log in, you will need to open your authenticator app and enter the six digit code from the app to your login prompt</Text>
        <Button 
          w="400px" 
          mt="24px"
          onClick={onComplete} 
          buttonType="primary" 
          variant="solid" 
          padding="24px 60px" 
          fontSize={"16px"} 
          fontWeight={400} 
        >
          Continue to Rigo HR
        </Button>
    </Box>
  )
}

export default Completed