// @ts-nocheck
import React from "react";

import RouteEnum from "constants/routeEnum";
import * as fromDataSource from "@rigotech/hrms-data";
import geoFencinglLinks from "./links";

const GeoFencing = React.lazy(() => import("@rigotech/hrms-geofencing/src"));

const geofencing = (features) => [
  {
    path: fromDataSource.RouteEnum.geofencingRoutes,
    component: GeoFencing,
    children: geoFencinglLinks,
    feature: features.GeoFencing,
  },
];

export default geofencing;
