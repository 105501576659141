import { useEffect, useMemo, useState, useCallback } from 'react';
import { If, Then, Else, When, Switch, Case, Default } from 'react-if';

//CHAKRA
import { Box } from '@chakra-ui/react';

//TABLE
import {
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';

//COMPONENTS
import LoadingTable from './loadingTable';
import NoDataFound from './noDataFound';
import PaginationComponent from './pagination';
import TableContent from './tableContent';
import TableCheckbox from './tableContent/tableCheckbox';

//CONSTANT
import { PAGINATION_CASES } from './constants';

//STYlES
import './index.scss';

//ICONS
import { RxCaretDown, RxCaretRight } from 'react-icons/rx';

//INTERFACE
import { IColumnProps, IPaginationProps, IReactTableProps } from './ts';
import InitialTableView from '../initial-table-view/initial-table-view';

const initialPaginationState = {
  pageIndex: 0,
  pageSize: 0,
};
export const DataTable = (props: IReactTableProps) => {
  const {
    data,
    columns,
    showFooter,
    isSortable,
    isColumnResizable,
    tableWidth,
    tableSize,
    borderVariant,
    isLoading,
    expandField,
    paginationField,
    rowSelectionField,
    customInitialTableView = null,
  } = props;

  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [pagination, setPagination] = useState<PaginationState>(
    initialPaginationState,
  );

  // const prevRowSelection = usePrevious(rowSelection);

  const calculatePageCount = (totalCount = 0, pageSize = 0) => {
    if (Number(totalCount) === 0 || Number(pageSize) === 0) {
      return 1;
    }
    return Math.ceil(totalCount / pageSize);
  };

  // const handleSelectedRow = (object: any, prevRowSelection: any) => {
  //   const nextRowSelection = { ...rowSelection, ...object };

  //   if (!_.isEqual(prevRowSelection, nextRowSelection))
  //     setRowSelection(nextRowSelection);
  // };

  //pass the default data in case data is not active;
  const defaultData = useMemo(() => [], []);

  const filteredColumns: any = useMemo(() => {
    let filteredColumnsBasedOnRowSelection: any = [];
    filteredColumnsBasedOnRowSelection = columns.map(
      (column: IColumnProps, index: number) => {
        return {
          ...column,
          header: () => (
            <Box className=' stick-title' paddingRight='10px'>
              {column.header}
            </Box>
          ),
          accessorKey: column.accessorKey,
          cell: ({ row, getValue }: any) => {
            return column.isExpandableCell ? (
              <Box
                className=' stick-title'
                paddingRight='10px'
                paddingLeft={`${row.depth * 16}px`}
                display='flex'
                flexWrap='wrap'
                onClick={row.getToggleExpandedHandler()}
                cursor='pointer'
                transition='0.3s ease'
                _hover={{
                  color: row.getCanExpand() ? 'blue.500' : '',
                }}
              >
                {column?.cell?.({ row, getValue }) ?? getValue()}
                {row.getCanExpand() && (
                  <Box as='span' alignSelf='center' paddingLeft='6px'>
                    {row.getIsExpanded() ? (
                      <RxCaretDown size='20px' />
                    ) : (
                      <RxCaretRight size='20px' />
                    )}
                  </Box>
                )}
              </Box>
            ) : (
              <Box className='stick-title' paddingRight='10px'>
                {column?.cell?.({ row, getValue }) ?? getValue()}
              </Box>
            );
          },

          footer: (props: any) => {
            if (column.footer) {
              return (
                <Box className=' stick-title' paddingRight='10px'>
                  {column.footer}
                </Box>
              );
            } else return null;
          },
        };
      },
    );
    if (rowSelectionField?.enableRowSelection) {
      const rowSelctionColumn = {
        accessorKey: 'select',
        size: '40',
        textAlign: 'center',
        isSticky: true,
        enableSorting: false,
        header: ({ table }: any) => (
          <TableCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }: any) => {
          const isSelectDisable = row.original?.isSelectDisable;
          // const isSelected = row.original?.isSelected;
          // if (isSelected) {
          //   handleSelectedRow({ [row.id]: isSelected }, rowSelection);
          // }
          return !isSelectDisable ? (
            <TableCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          ) : null;
        },
      };

      filteredColumnsBasedOnRowSelection.unshift({ ...rowSelctionColumn });
    }
    return filteredColumnsBasedOnRowSelection;
  }, [columns]);

  const pageCount = useMemo(() => {
    return (
      paginationField?.pageCount ??
      calculatePageCount(paginationField?.totalCount, paginationField?.pageSize)
    );
  }, [paginationField]);

  const isEverypaginationValueDefined = useMemo(() => {
    if (paginationField) {
      const filteredPaginationField = {
        ...paginationField,
      } as Partial<IPaginationProps>; //make all optional in order to delete onPaginationChange
      delete filteredPaginationField.onPaginationChange;
      return Object.values(filteredPaginationField).every(
        (field: any) => field || field !== undefined,
      );
    } else return false;
  }, [paginationField]);

  const isSelectedRowEmpty = useCallback(
    (rows: any) => {
      if (rowSelectionField) {
        return Object.values(rows).length === 0 || rows.length === 0;
      }
      return true;
    },
    [rowSelectionField, rowSelection],
  );

  const table = useReactTable({
    data: data ?? defaultData,
    columns: filteredColumns,

    pageCount: pageCount,
    state: {
      sorting,
      pagination: pagination,
      rowSelection,
    },
    //sorting
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    enableSorting: true,

    //pagination
    onPaginationChange: setPagination,
    manualPagination: true,

    //column resizing
    enableColumnResizing: true,
    columnResizeMode: 'onChange',

    //core modal
    getCoreRowModel: getCoreRowModel(),

    //expanding modal
    enableExpanding: expandField?.enableExpanding, //in order to enable expanding
    autoResetExpanded: false,
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: row => row.subRows, //in order to get the sub rows

    //row selection
    onRowSelectionChange: setRowSelection,
  });

  const handlePagination = (type: number, value: number = 0) => {
    switch (type) {
      case PAGINATION_CASES.initialPage:
        return table.setPageIndex(0);
      case PAGINATION_CASES.previousPage:
        return table.previousPage();
      case PAGINATION_CASES.nextPage:
        return table.nextPage();
      case PAGINATION_CASES.finalPage:
        return table.setPageIndex(table.getPageCount() - 1);
      case PAGINATION_CASES.gotoPageIndex:
        return table.setPageIndex(value);
      case PAGINATION_CASES.gotoPageSize:
        return table.setPageSize(value);
      default:
        return null;
    }
  };

  const shouldPaginationBtnDisabled = (type: number) => {
    switch (type) {
      case PAGINATION_CASES.initialPage:
        return !table.getCanPreviousPage();
      case PAGINATION_CASES.previousPage:
        return !table.getCanPreviousPage();
      case PAGINATION_CASES.nextPage:
        return !table.getCanNextPage();
      case PAGINATION_CASES.finalPage:
        return !table.getCanNextPage();
      default:
        return false;
    }
  };

  //
  useEffect(() => {
    if (
      paginationField &&
      isEverypaginationValueDefined &&
      pagination.pageSize !== 0 &&
      (pagination.pageIndex !== paginationField?.pageIndex ||
        pagination.pageSize !== paginationField?.pageSize)
    ) {
      paginationField?.onPaginationChange(pagination);
    }
  }, [pagination]);

  useEffect(() => {
    if (paginationField && isEverypaginationValueDefined) {
      setPagination({
        pageIndex: paginationField.pageIndex,
        pageSize: paginationField.pageSize,
      });
    }
  }, [paginationField]);

  useEffect(() => {
    if (expandField?.defaultAllRowsExpand) {
      table.toggleAllRowsExpanded();
    }
  }, []);

  useEffect(() => {
    const isPreviousRowSelectionStillRemains =
      rowSelectionField &&
      isSelectedRowEmpty(rowSelection) &&
      rowSelectionField.selectedRow.length > 0;

    if (
      (rowSelectionField && !isSelectedRowEmpty(rowSelection)) ||
      isPreviousRowSelectionStillRemains
    ) {
      const flattenedSelectedRows = table.getSelectedRowModel().flatRows;
      rowSelectionField.setSelectedRow(flattenedSelectedRows);
    }
  }, [rowSelection]);

  useEffect(() => {
    if (
      rowSelectionField &&
      isSelectedRowEmpty(rowSelectionField.selectedRow)
    ) {
      setRowSelection({});
    }
  }, [rowSelectionField]);

  return (
    <Box className='data-table' style={{ width: tableWidth }}>
      <Switch>
        <Case condition={isLoading}>
          <LoadingTable />
        </Case>
        <Case condition={data === null}>
          <If condition={customInitialTableView !== null}>
            <Then>{customInitialTableView}</Then>
            <Else>
              <InitialTableView />
            </Else>
          </If>
        </Case>
        <Case condition={data !== null && data?.length > 0}>
          <>
            <Box mt='15px'>
              <TableContent
                {...{
                  table,
                  filteredColumns,
                  showFooter,
                  isSortable,
                  isColumnResizable,
                  tableSize,
                  borderVariant,
                }}
              />
            </Box>
            {/* pagination */}

            {paginationField ? (
              <PaginationComponent
                {...{
                  data,
                  handlePagination,
                  table,
                  shouldPaginationBtnDisabled,
                }}
                totalCount={paginationField?.totalCount}
              />
            ) : null}
          </>
        </Case>
        <Default>
          <NoDataFound />
        </Default>
      </Switch>
    </Box>
  );
};

DataTable.defaultProps = {
  showFooter: false,
  isSortable: true,
  isColumnResizable: true,
  tableWidth: '100%',
  tableSize: 'sm',
  borderVariant: 'bordered',
  enableRowSelection: false,
};

export default DataTable;
