// @ts-nocheck 
import React, {useState} from "react";
import {FaFont, FaPlusCircle} from "react-icons/fa";
import {MdCheckBox} from "react-icons/md";
import {BsTextareaT} from "react-icons/bs";
import {IoMdRadioButtonOn} from "react-icons/io";
import shortid from "shortid";
import QuestionLabel from "./questionLabel";
import Question from "./question";
import {useDispatch} from "react-redux";
import Button from "components/button";
import {addCourseQuestion} from "scenes/lms/helpers/action";
import {v4 as uuidv4} from "uuid";

const questions = [
  {label: <QuestionLabel label="Short Text" icon={<FaFont />} />, value: 1},
  {label: <QuestionLabel label="Long Text" icon={<BsTextareaT />} />, value: 2},
  {label: <QuestionLabel label="Radio Buttons" icon={<IoMdRadioButtonOn />} />, value: 3},
  {label: <QuestionLabel label="Check Boxes" icon={<MdCheckBox />} />, value: 4},
];

const questionData = [
  {
    question: "",
    questionType: questions[0],
    id: shortid.generate(),
    section: uuidv4(),
    radioOptions: null,
    isRandomised: false,
    selectionFrom: 0,
    selectionTo: 0,
  },
];

const QuestionContent = (props) => {
  alert("question content");
  const [state, setState] = useState(questionData);
  const dispatch = useDispatch();
  const onChange = (id, name, value) => {
    setState((prev) =>
      prev.map((item) => (item.id === id ? {...item, [name]: value} : item))
    );
  };
  const onQuestionAdd = () => {
    setState((prev) => [
      ...prev,
      {
        question: "",
        questionType: questions[0],
        id: shortid.generate(),
        section: uuidv4(),
        radioOptions: null,
        selectionFrom: 0,
        isRandomised: false,
        selectionTo: 0,
      },
    ]);
  };
  const onQuestionRemove = (id) => {
    setState((prev) => prev.filter((item) => item.id !== id));
  };
  const onQuestionSave = () => {
    let data =
      (state &&
        state.map((item) => ({
          courseOutlineId: props.pageId,
          question: item.question,
          section: item.section,
          questionType: item?.questionType?.value,
          isRandomised: item.isRandomised,
          answerLimitTo: item.selectionTo,
          answerLimitFrom: item.selectionFrom,
          answerOptions: item.radioOptions
            ? item.radioOptions.map((rad) => ({
                questionId: 0,
                answerOption: rad?.answer,
                isCorrect: rad?.isChecked,
                point: rad?.marks,
              }))
            : [],
        }))) ||
      [];

    dispatch(addCourseQuestion(data));
    props.onClear();
  };
  return (
    <div className="courseForm-questions">
      {state &&
        state.map((item) => {
          return (
            <Question
              onQuestionAdd={onQuestionAdd}
              onQuestionRemove={onQuestionRemove}
              onChange={onChange}
              changeState={setState}
              item={{...item}}
            />
          );
        })}
      <div className="courseForm-questions-add">
        <div
          onClick={() => onQuestionAdd()}
          className="courseForm-questions-add__sections"
        >
          <FaPlusCircle />
          <span>Add a section</span>
        </div>
      </div>
      <div className="courseForm-questions-btn">
        <Button
          onClick={() => onQuestionSave()}
          title="Save Questions"
          bg="green"
          size="large"
        />
        <Button onClick={() => props.onClear()} title="Cancel" size="large" />
      </div>
    </div>
  );
};
export default QuestionContent;
