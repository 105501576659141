//@ts-nocheck
import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from "react-redux";
import {
    getCourseCertificate,
    getCourse,
    postCourseCertificate
} from "../helpers/action";
import './styles.scss';
import { useForm } from 'react-hook-form';
import FormGroup from "components/formGroup";
import FormItem from "components/form";
import { Paper } from "@material-ui/core";
import ImageRenderer from "../common/imageRenderer";
import isEmpty from "isEmpty";
import { UploadCoverPhoto } from "../addCourse/uploadCoverPhoto";
import { IoMdCloseCircle } from "react-icons/io";
import { GrAttachment } from "react-icons/gr";
import Button from "components/button";
import Modal from "components/modal";
import { useHistory } from "react-router";
import RouteEnum from "constants/routeEnum";
import { IoMdArrowBack } from "react-icons/io";
import {getFullURL} from "helpers/fullURL";
import EmployeeSearch from "components/common/employeeSearch";

const initState = {
    CertificateName: "",
    ServerFileName: null,
    CourseId: "",
    SignatoryEmployeeId: "",
    FooterText: "",
    CoverPicture: [],
};

function CourseCertificateSettings(props) {
    const [state, setState] = useState(initState);
    const history = useHistory();
    const {
        course,
        courseSettings
    } = useSelector((state: any) => state.lmsReducer);
    const ref = useRef();
    const [isEdit, setIsEdit] = useState(false);
    const [open, setOpen] = useState(false);
    const [errors, setErrors] = useState(null);
    const method = useForm();
    const { } = method;
    const dispatch = useDispatch();
    const { courseId } = props.match.params;
    useEffect(() => {
        // dispatch(getCourse(courseId));
        // dispatch(getCourseList({}));
        fetchCourseCertificates()

    }, []);
    const fetchCourseCertificates = () => {
        dispatch(getCourseCertificate());
    }
    useEffect(() => {
        if(courseSettings && courseSettings.length>0){
            setIsEdit(true)
            setState(prev=>({
                ...prev,
                FooterText:courseSettings[courseSettings.length-1]?.FooterText,
                ServerFileName:courseSettings[courseSettings.length-1]?.SignatureImage,
            }))
        }
    }, [courseSettings])
    
    const validate = () => {
        setErrors(null);
        const errs = {}
        if(isEmpty(state.CoverPicture) && !isEdit){
            errs['CoverPicture'] = "Signature Picture field is required";
        }
        if(isEmpty(state.FooterText)){
            errs['FooterText'] = "Footer Text field is required";
        }
        setErrors(prev=>isEmpty(errs) ? null : errs);
        return isEmpty(errs);
    }   

    const onSubmit = async () => {
        const isValid = validate();
        if(!isValid){
            return;
        }
        const data = {
            // ...dt,
            signatoryEmployeeId:state.SignatoryEmployeeId ? state.SignatoryEmployeeId?.value : null,
            FooterText:state.FooterText,
            courseId:courseId,
            signaturePicture: !isEmpty(state.CoverPicture) ? state.CoverPicture[0] : null,
            isFileEdit: !isEmpty(state.CoverPicture),
        };
        if(isEdit){
            data.id = courseSettings[0].Id;
            if(isEmpty(state.CoverPicture)){
                delete data.signaturePicture;
                delete data.isFileEdit;
            }
        };
        const fd = new FormData();

        Object.keys(data).map((item) => {
            data[item] && fd.append(item, data[item]);
        });

        // if (isEdit) {
        // fd.append("Id", course.Id);
        // console.log({fd, data});
        // await dispatch(postCourseCertificate(fd));
        // setState({...state});
        // return;
        // }
        const res = await dispatch(postCourseCertificate(fd));
        if(res){
            setState(initState);
            setErrors(null);
            fetchCourseCertificates();
        }
    }
    const renderCoverImage = () => {
        let imgSrc = null;
        if (!isEmpty(state.CoverPicture)) {
            imgSrc = (
                <ImageRenderer src={URL.createObjectURL(state.CoverPicture[0])} />
            );
        } 
        // else if (!isEmpty(state.ServerFileName)) {
        //     imgSrc = <ImageRenderer disableBaseUrl src={state.ServerFileName} />;
        // }

        return !isEmpty(imgSrc) ? (
            <div className="courseForm__form-image">
                {imgSrc}
                <div
                    onClick={() =>
                        setState((prev) => ({ ...prev, SignaturePicture: [], ServerFileName: null }))
                    }
                    className="courseForm__form-icon"
                >
                    <IoMdCloseCircle />
                </div>
            </div>
        ) : (
            <div className="attachment">
                <div className="attachment__upload mt-sm">
                    <Paper onClick={() => setOpen(true)}>
                        <p>
                            <span className="text-blue dragAndDropUploadHover">
                                Upload Signature
                            </span>
                        </p>
                    </Paper>
                </div>
            </div>
        );
    };

    const onEmployeeChange = async(name,value) => {
        await setState(prev=>({...prev,[name]:value}))
        validate()
    };
    return (
        <>
            {/* <div className="course-settings__header">
                <div
                    onClick={() => props.history.push(RouteEnum.lmsCourseManagement)}
                    className="courseForm__header-back"
                >
                    <IoMdArrowBack />
                </div>
                <div className="course-settings__header-image">
                    <img src={`${baseURL}${course?.ServerFileName}`} alt="" />
                </div>
                <div className="course-settings__header-title">{course?.Title}</div>
            </div> */}
            <div className="course-settings">
                <div className="course-settings__container">
                    <div className='course-certificate'>
                        <div className="course-certificate-container">
                            <div className="course-certificate-form">
                                {/* <FormGroup method={method} onSubmit={onSubmit}> */}
                                    <div className="course-settings__body">
                                        <CoursePanel title="Course Certificate Settings">
                                            {/* <CourseRow label="Signature Employee">
                                                <EmployeeSearch
                                                    onChange={onEmployeeChange}
                                                    width={"100%"}
                                                    name="SignatoryEmployeeId"
                                                    isMultiple={false}
                                                    value={state?.SignatoryEmployeeId}
                                                    // placeholder="Search (3 letters..) and Select"
                                                    label=""
                                                />
                                               {!isEmpty(errors?.SignatoryEmployeeId) && <div className="course-error">{errors['SignatoryEmployeeId']}</div>}
                                            </CourseRow> */}
                                            <CourseRow label="Signature Image">
                                               {
                                                state.ServerFileName && 
                                                    <div className="course-certificate-image">
                                                        <ImageRenderer src={state.ServerFileName} />
                                                    </div>
                                                }
                                                <FormItem 
                                                    name="CoverPicture"
                                                    formName="dragAndDropUpload"
                                                    isLabel={false}
                                                    placeholder=""
                                                    innerLabel={<span className="text-blue dragAndDropUploadHover">Upload  signature</span>}
                                                    onChange = {
                                                        (name,value) => {
                                                            setState(prev=>({...prev,[name]:value}))
                                                        }
                                                    }
                                                    value={state?.CoverPicture}
                                                />
                                                {/* {renderCoverImage()} */}
                                                {!isEmpty(errors?.CoverPicture) && <div className="course-error">{errors['CoverPicture']}</div>}
                                            </CourseRow>
                                            <CourseRow label="Footer Text">
                                                <FormItem 
                                                    name="FooterText"
                                                    formName="textarea"
                                                    placeholder=""
                                                    onChange = {
                                                        (name,value) => {
                                                            console.log({value})
                                                            setState(prev=>({...prev,[name]:value}))
                                                        }
                                                    }
                                                    value={state?.FooterText}
                                                    maxLength = { 200 }
                                                    onKeyUp={(name,value,event)=>{
                                                        const textarea = event.target;
                                                        const limit = 3;
                                                        if(!textarea){
                                                            return;
                                                        }
                                                        var lines = state.FooterText.split("\n");
                                                        if(lines.length>limit)
                                                         {
                                                             textarea.style.color = 'red';
                                                             setErrors(prev=>isEmpty(errors) ?  {FooterText:"You are only allowed to add 3 lines of text or less."} : {...errors,FooterText:"You are only allowed to add 3 lines of text or less."});
                                                            //  setTimeout(function(){
                                                            //      textarea.style.color = '';
                                                            //  },500);
                                                         } else{
                                                            textarea.style.color = '#333';
                                                            setErrors(prev=>isEmpty(errors) ?  {} : {...errors,FooterText:""});
                                                         }  
                                                    }}
                                                />
                                                {!isEmpty(errors?.FooterText) && <div className="course-error">{errors['FooterText']}</div>}
                                            </CourseRow>
                                            <div className="text-center">
                                                <Button
                                                    bg="primary"
                                                    onClick={()=>onSubmit()}
                                                    htmlButtonType="submit"
                                                    title={isEdit ? "Update" : "Add Certificate"}
                                                />
                                            </div>
                                        </CoursePanel>
                                    </div>
                                {/* </FormGroup> */}
                            </div>
                        </div>
                        {/* <Modal
                            className="profile-change__modal"
                            open={open}
                            onModalClose={() => setOpen(false)}
                        >
                            <UploadCoverPhoto setState={setState} onModalClose={() => setOpen(false)} />
                        </Modal> */}
                    </div>
                </div>
            </div>

        </>

    )
}

const CourseRow = ({ label, children }) => {
    return (
        <div className="course-settings__row">
            <div className="course-settings__label">{label}</div>
            <div className="course-settings__form">{children}</div>
        </div>
    );
};


const CoursePanel = ({ title, children }) => {
    return (
        <div className="course-settings__panel">
            <div className="course-settings__panel-header">{title}</div>
            <div className="course-settings__panel-body">{children}</div>
        </div>
    );
};

export default CourseCertificateSettings