import * as fromSlice from "./slice";
import * as fromRolloutAction from "../performance-rollout-appraisal-list/actions"
export const udpatePerformanceRolloutAppraisalFilter = (newFilters: any): any => (dispatch: any, getState: any) => {
    dispatch(fromSlice.udpateFilter(newFilters))
}

export const udpatePerformanceRolloutAppraisalFilterAndFetch = (newFilters: any): any => async (dispatch: any, getState: any) => {
    dispatch(fromSlice.udpateFilter(newFilters))
    dispatch(fromRolloutAction.fetchPerformanceRolloutAppraisals())
}
export const clearPerformanceRolloutAppraisalFilter = () => (dispatch: any) => {
    dispatch(fromSlice.clear())
}