export const components = {
    // Checkbox: {
    //     baseStyle: {
    //         label: {
    //             fontSize: '25px',
    //             fontWeight: 'bold',
    //             color: 'green.200',
    //         },
    //     },
    // },
}