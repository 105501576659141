// @ts-nocheck 
import RouteEnum from "constants/routeEnum";
import React from "react";
import {AiOutlineUser} from "react-icons/ai";
import {FaFeather} from "react-icons/fa";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import ImageRenderer from "scenes/lms/common/imageRenderer";
import {employeeCourseView} from "scenes/lms/helpers/action";
import {EmployeeCourseAssignStatus} from "scenes/lms/helpers/enums";

const CourseCard = (props) => {
  const {item} = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const {user} = useSelector((state: any) => state.auth);

  return (
    <div
      className="courses-assigned__card"
      onClick={() => {
        const {AssignedStatus} = item;
        //if (EmployeeCourseAssignStatus.Completed === AssignedStatus) return;
        if (user?.EmployeeId !== item.EmployeeId) {
          dispatch(employeeCourseView({courseId: item.Id}));
        }
        history.push(RouteEnum.lmsCourse + "/" + item.Id);
      }}
    >
      <div className="courses-assigned__image">
        <ImageRenderer src={item.ServerFileName} alt="" />
      </div>
      <div className="courses-assigned__body">
        <div className="courses-assigned__title flex flex-wrap">
          <div className="course-mainTitle">{item?.Title}</div>
          <div className="flex course-views">
            <span>{item?.TotalView}</span> Views
          </div>
        </div>
        <div className="courses-assigned__to">
          <FaFeather /> {item?.Author}
        </div>
        <div className="courses-assigned__type">{item.CategoryName}</div>
        <div className="courses-assigned__footer">
          <span>{item?.rate}</span>
        </div>
        <div className="courses-assigned__status-bar">
          <div
            style={{width: `${item?.PercentComplete}%`}}
            className="courses-assigned__status-active"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
