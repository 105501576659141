import * as fromInterface from "../../../ts";
import { PerformanceFormDropdownState } from "./slice";

const currentPerformanceFormDropdown = (state: any): PerformanceFormDropdownState => {
    return state.dataStore.performanceFormDropdown;
};

export const selectPerformanceFormDropdownStatus = (state: any): fromInterface.loadingType => {
    return currentPerformanceFormDropdown(state).status;
};

export const selectPerformanceFormDropdownLoaded = (state: any): boolean => {
    return currentPerformanceFormDropdown(state).loaded;
};

export const selectPerformanceFormDropdownItems = (state: any) => {
    return currentPerformanceFormDropdown(state).items;
};

