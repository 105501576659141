
import { ApiClient } from "../../../network/apiClient";
import * as fromInterface from "../../../ts";
import { PERFORMANCE_REVIEW_PREVIEW } from "./api";
import * as fromSlice from "./slice";

export const fetchPerformanceReviewPreviewPageData = (params: { rolloutId: number }): any => async (dispatch: any) => {
    dispatch(fetchPerformanceReviewPreview(params))
}

export const fetchPerformanceReviewPreview = ({ rolloutId }: { rolloutId: number }): any => async (dispatch: any) => {
    dispatch(fromSlice.fetchRequest())
    const response: fromInterface.ResponseWrapper<fromInterface.PerformanceReview> = await ApiClient.get<any>({
        endpoint: PERFORMANCE_REVIEW_PREVIEW.replace(":rolloutId", rolloutId + ""),
        dispatch: dispatch
    })

    const { Data, Status } = response

    if (Status) {
        dispatch(fromSlice.fetchSuccess(Data))
    } else {
        dispatch(fromSlice.fetchError())
    }

}

export const clearPreformanceReviewPreviewPageData = (): any => async (dispatch: any) => dispatch(fromSlice.clear())
