// @ts-nocheck 
import {v1} from "constants/urls";

const prefix = `${v1}/employee-device/`;

export default {
  list: prefix + "list",
  history: prefix + "history",
  approve: prefix + "approve",
  reject: prefix + "reject",
  reset: prefix + "reset",
};
