import "isomorphic-fetch";
import React, { useState, useEffect, Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FaTimesCircle } from "react-icons/fa";
import * as Animated from "react-select/animated";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import { getEmployees } from "../dataselect/action";
import { ArraysWithImage } from "helpers/getTextLabel";
import { getUploadPath, UserByStore } from "constants/userDetails";
import Avatar from "react-avatar";
import isEmpty from "isEmpty";

export class EmployeeSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      selectedOption: "",
      employees: [],
    };
    this.onChange = this.onChange.bind(this);
  }
  async getEmployeesForSelect(input) {
    let newValue = input.replace(/\W/g, "");
    if (!isEmpty(newValue) && newValue.length >= 3) {
      const res = await this.props.getEmployees(newValue);
      // debugger;
      if (res?.status === 200) {
        let formatedList =
          res.data && res.data.length > 0
            ? res.data.map((m) => {
                return {
                  ...m,
                  label: m.FullName,
                  value: m.EmployeeId,
                  img: m.Imagepath,
                  designation: m.Designation,
                };
              })
            : [];
        return formatedList;
      }
      // return this.props.employeeSearchList;
    } else {
      return "";
    }
  }
  onChange(e) {
    // console.log(e);
  }
  imageOption(props) {
    const { img, label, value, auth } = props.data;
    let imageURI = getUploadPath(img);
    // console.log({ img });
    // let imageURI = auth?.baseUrl?.NetFrameworkAppBaseUrl ? auth.baseUrl.NetFrameworkAppBaseUrl + ''
    return (
      <components.Option {...props}>
        <div className="input-row">
          <div className="input-image">
            {/* <img src={imageURI} alt='' /> */}
            <Avatar
              className="company-logo"
              name={label}
              size={"30"}
              round={true}
              src={imageURI}
            />
          </div>
          <span className="input-value">{label}</span>
        </div>
      </components.Option>
    );
  }
  // DropdownIndicator = (props) => {
  //   console.log(components);
  //    <div aria-hidden="true" class=" css-tlfecz-indicatorContainer"><svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-tj5bde-Svg"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg></div>
  //   return (
  //     <components.DropdownIndicator {...props}>
  //       <FaTimesCircle />
  //     </components.DropdownIndicator>
  //   );
  // };
  render() {
    const {
      value,
      onChange,
      width,
      name,
      label,
      isMultiple,
      placeholder,
      disabled,
    } = this.props;
    return (
      <div className="autocomplete" style={{ width: width || 250 }}>
        {label ? (
          <label htmlFor="employees" className="autocomplete-label">
            {label}
          </label>
        ) : null}
        <AsyncSelect
          className="employees-select"
          classNamePrefix="tmdb_select"
          isSearchable="true"
          isClearable="true"
          cacheOptions
          onCloseResetsInput={false}
          components={{
            Option: this.imageOption,
          }}
          defaultValue={value}
          onChange={(value) => onChange(name, value)}
          defaultOptions
          isMulti={isMultiple}
          // loadOptions={this.promiseOptions}
          loadOptions={(e) => this.getEmployeesForSelect(e)}
          placeholder={placeholder}
          isDisabled={disabled}
          value={isEmpty(value) ? null : value}
        />
      </div>
    );
  }
}

EmployeeSearch.propTypes = {
  getEmployees: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  label: PropTypes.string,
  width: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  isMultiple: PropTypes.bool,
  employeeSearchList: PropTypes.any.isRequired,
};

EmployeeSearch.defaultProps = {
  isMultiple: false,
  name: "employee",
  label: "Employees",
  placeholder: "Type and Select",
};

const mapStateToProps = (state) => ({
  employeeSearchList: state.commonReducer.employeeSearchList,
  auth: state.auth,
});

const mapDispatchToProps = {
  getEmployees,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeSearch);
// export default EmployeeSearch
