// @ts-nocheck
import {
  GET_COUNTRY_LIST,
  GET_CURRENCY_LIST,
  GET_DISTRICT_LIST,
  GET_EMPLOYEE_LIST,
  GET_EMPLOYEE_LIST_WITH_PHOTO,
  GET_EMPLOYEE_BY_BRANCH_DEPARTMENT,
  GET_EDUCATION_LEVELS,
  GET_PROVINCE_LIST,
  GET_COMPANY_HOLIDAYS_WHOLE,
  GET_DISTRICT_BY_PROVINCE,
  GET_LOCALBODY_BY_DISTRICT,
  GET_EXPERIENCE_DURATION,
  GET_JOB_STATUS,
  GET_SALARY_PAY_DURATION,
  GET_COMPANY_INFORMATION,
  GET_COMPANY_LOGO,
  ADD_LOADING,
  GET_NATIONALITY,
  GET_DEPARTMENTS,
  GET_SUB_DEPARTMENTS,
  GET_DESIGNATION,
  SORT_DRAG,
  GET_LEVELS,
  GET_BRANCHES,
  UPLOAD_FILES,
  GET_LEVELS_WITH_GROUPS,
  GET_JOB_PROFILE_DROP_LIST,
  GET_JOB_DESCRIPTION_DROP_LIST,
  SET_SELECTED_RECORD,
  GET_COMPANY_HOLIDAYS,
  GET_OVERTIME_TYPES,
  SET_LEVELS_WITH_GROUPS_LOADING,
  SET_JOB_PROFILE_DROP_LIST_LOADING,
  SET_JOB_DESCRIPTION_DROP_LIST_LOADING,
  GET_BLOOD_GROUP_LIST,
  GET_PAYROLL_INCOME_HEADS,
  GET_HIRING_METHODS,
  GET_APP_VERSION,
  GET_EMP_MENUS,
  GET_SERVER_DATE_TIME,
  COMPANY_INFORMATION_REQUESTED,
} from "./types";
import axios from "helpers/axiosinstance";
import { EmployeeID } from "constants/userDetails";
import { toastMessage } from "./validateAction";
//   import { getBaseAxios } from "../helpers/Constants";
import { Arrays } from "helpers/getTextLabel";
import dispatchError from "helpers/dispatchError";
import { loadingHeaders } from "helpers/getHeaders";
import async from "react-select/async";
import environment from "environment";
import isEmpty from "helpers/isEmpty";
import { exportFile_post } from "networkService";
import { get } from "networkService";

export const GetAppVersion =
  (): any =>
  async (dispatch: any): Promise<any> => {
    try {
      var res = await get(environment.api.v1.common.appVersion, dispatch);
      dispatch({
        type: GET_APP_VERSION,
        payload: res,
      });
    } catch (ex) {}
  };

export const GetEmpMenus =
  (): any =>
  async (dispatch: any): Promise<any> => {
    try {
      var res: any = await get(environment.api.v1.common.empMenus, dispatch);
      dispatch({
        type: GET_EMP_MENUS,
        payload: res?.Data || [],
      });
    } catch (ex) {}

    // await axios
    //   .get(environment.api.v1.common.empMenus, loadingHeaders)
    //   .then((response) => {
    //     dispatch({
    //       type: GET_EMP_MENUS,
    //       payload: (response.data && response.data.Data) || [],
    //     });
    //   })
    //   .catch((err) => {
    //     dispatchError(err, dispatch, GET_BRANCHES, "Failed to get country list.");
    //   });
  };

export const GetCountryList = () => async (dispatch: Function) => {
  const res = await axios
    .get(environment.api.v1.common.countries, loadingHeaders)
    .then(response => {
      const data = response.data.map(x => {
        return { value: x.CountryId, label: x.CountryName };
      });
      dispatch({
        type: GET_COUNTRY_LIST,
        payload: response.data,
      });
      return data;
    })
    .catch(err => {
      dispatchError(err, dispatch, GET_BRANCHES, "Failed to get country list.");
    });
  return res;
};

export const SortDrag = (
  droppableIdStart,
  droppableIdEnd,
  droppableIndexStart,
  droppableIndexEnd,
  draggableId,
  type,
) => {
  return (dispatch, getState) => {
    dispatch({
      type: SORT_DRAG,
      payload: {
        droppableIdStart,
        droppableIdEnd,
        droppableIndexEnd,
        droppableIndexStart,
        draggableId,
        type,
      },
    });
  };
};

export const GetCurrencyList = () => async (dispatch: Function) => {
  await axios
    .get(environment.api.v1.common.currencies, loadingHeaders)
    .then(response => {
      dispatch({
        type: GET_CURRENCY_LIST,
        payload: response.data,
      });
    })
    .catch(err =>
      dispatchError(
        err,
        dispatch,
        GET_CURRENCY_LIST,
        "Failed to get currency list.",
      ),
    );
};

export const GetDistrictList = () => async (dispatch: Function) => {
  await axios
    .get(environment.api.v1.common.districts, loadingHeaders)
    .then(response => {
      dispatch({
        type: GET_DISTRICT_LIST,
        payload: Arrays(response.data),
      });
    })
    .catch(err =>
      dispatchError(
        err,
        dispatch,
        GET_DISTRICT_LIST,
        "Failed to get district list.",
      ),
    );
};

export const GetEmployeeList = () => async (dispatch: Function) => {
  await axios
    .get(environment.api.v1.common.employees)
    .then(response => {
      if (response.status == 200) {
        let formatedEmployeeListWithPhoto = [];
        let formatedMyDetail = null;
        response.data.length > 0 &&
          response.data.map((m: any) => {
            if (m.EmployeeId == EmployeeID()) {
              formatedMyDetail = {
                label: m.FullName,
                value: m.EmployeeId,
                Imagepath: m.Imagepath,
              };
            } else {
              formatedEmployeeListWithPhoto.push({
                label: m.FullName,
                value: m.EmployeeId,
                Imagepath: m.Imagepath,
              });
            }
          });
        // const empId = EmployeeID();

        dispatch({
          type: GET_EMPLOYEE_LIST_WITH_PHOTO,
          formatedEmployeeListWithPhoto,
          formatedMyDetail,
        });
        dispatch({
          type: GET_EMPLOYEE_LIST,
          payload: response.data,
        });
      }
    })
    .catch(err =>
      dispatchError(
        err,
        dispatch,
        GET_EMPLOYEE_LIST,
        "Failed to get employee list.",
      ),
    );
};

export const GetPrefixedEmpNames =
  (prefix: any): any =>
  async (dispatch: any): Promise<any> => {
    var res = await axios
      .get(
        `${environment.api.v1.common.prefixedEmpNames}?prefix=${prefix}`,
        loadingHeaders,
      )
      .catch(err => {
        dispatchError(
          err,
          dispatch,
          GET_BRANCHES,
          "Failed to get country list.",
        );
        return null;
      });

    if (res && res.status) return res.data;

    return null;
  };
// export const GetAllEmployeeListWithPhoto = () => async (dispatch: Function) => {
//   await axios
//     .get(`/v1/common/employees-with-photo`)
//     .then((response) => {
//       if (response.status == 200) {
//         let formatedEmployeeListWithPhoto = [];
//         let formatedMyDetail = null;

//         response.data.length > 0 &&
//           response.data.map((m: any) => {
//             if (m.EmployeeId == EmployeeID()) {
//               formatedMyDetail = {
//                 label: m.fullName,
//                 value: m.employeeId,
//                 Imagepath: m.imagepath,
//               };
//             } else {
//               formatedEmployeeListWithPhoto.push({
//                 label: m.fullName,
//                 value: m.employeeId,
//                 Imagepath: m.imagepath,
//               });
//             }
//           });

//         dispatch({
//           type: GET_EMPLOYEE_LIST_WITH_PHOTO,
//           formatedEmployeeListWithPhoto,
//           formatedMyDetail,
//         });
//       }
//     })
//     .catch((err) =>
//       dispatchError(
//         err,
//         dispatch,
//         GET_EMPLOYEE_LIST_WITH_PHOTO,
//         "Failed to get employee list."
//       )
//     );
// };

// export const GetAllEmployeeByBranchDepartment = () => async (dispatch:Function) => {
//   await axios
//     .get(`/v1/common/shared/employees-by-branch-department`)
//     .then((response) => {
//       if (response.status == 200) {
//         dispatch({
//           type: GET_EMPLOYEE_BY_BRANCH_DEPARTMENT,
//            formatedEmployeeListWithPhoto,
//            formatedMyDetail,
//         });
//       }
//     })
//     .catch((err) => {
//       toastMessage(dispatch, err.message);
//     });
// };

export const GetEducationLevel = () => async (dispatch: Function) => {
  await axios
    //.get(`/v1/common/shared/education-level`)
    .get(environment.api.v1.common.educationLevel)
    .then(response => {
      dispatch({
        type: GET_EDUCATION_LEVELS,
        // payload: response.data.map((x) => {
        //   return { value: x.Key, label: x.Value };
        // }),
        payload: response.data?.Data?.map(x => {
          return { value: x.EducationId, label: x.Name };
        }),
      });
    })
    .catch(err =>
      dispatchError(
        err,
        dispatch,
        GET_EDUCATION_LEVELS,
        "Failed to get education level list.",
      ),
    );
};

export const GetProvienceList = () => async (dispatch: Function) => {
  const res = await axios
    .get(environment.api.v1.common.provinces)
    .then(response => {
      if (response.status === 200) {
        const payload = response.data.map(x => {
          return { value: x.ProvinceId, label: x.Name };
        });
        dispatch({
          type: GET_PROVINCE_LIST,
          payload,
        });
        return payload;
      }
    })
    .catch(err =>
      dispatchError(
        err,
        dispatch,
        GET_PROVINCE_LIST,
        "Failed to get province list.",
      ),
    );
  return res;
};

export const GetDistrictByProvince =
  (provinceId: any) => async (dispatch: Function) => {
    const res = await axios
      .get(environment.api.v1.common.provinceDistrict(provinceId))
      .then(response => {
        if (response.status === 200) {
          const payload = response.data.map(x => {
            return { value: x.DistrictId, label: x.District };
          });
          dispatch({
            type: GET_DISTRICT_BY_PROVINCE,
            payload,
          });
          return payload;
        }
      })
      .catch(err =>
        dispatchError(
          err,
          dispatch,
          GET_DISTRICT_BY_PROVINCE,
          "Failed to get district list.",
        ),
      );
    return res;
  };

export const GetLocalBodyByDistrict =
  (districtId: any) => async (dispatch: Function) => {
    const res = await axios
      .get(environment.api.v1.common.localBodyDistrict(districtId))
      .then(response => {
        if (response.status === 200) {
          const payload = response.data.map(x => {
            return { value: x.Id, label: x.Name };
          });
          dispatch({
            type: GET_LOCALBODY_BY_DISTRICT,
            payload,
          });
          return payload;
        }
      })
      .catch(err =>
        dispatchError(
          err,
          dispatch,
          GET_LOCALBODY_BY_DISTRICT,
          "Failed to get local body list.",
        ),
      );
    return res;
  };

export const GetJobStatus = () => async (dispatch: Function) => {
  await axios
    .get(environment.api.v1.common.jobStatus)
    .then(response => {
      dispatch({
        type: GET_JOB_STATUS,
        payload: response.data.Data.map(x => {
          return { value: x.StatusId, label: x.Name };
        }),
      });
    })
    .catch(err =>
      dispatchError(
        err,
        dispatch,
        GET_JOB_STATUS,
        "Failed to get job status list.",
      ),
    );
};

export const GetExperienceDuration = () => async (dispatch: Function) => {
  await axios
    .get(environment.api.v1.common.expDuration)
    .then(response => {
      dispatch({
        type: GET_EXPERIENCE_DURATION,
        payload: Arrays(response.data),
      });
    })
    .catch(err =>
      dispatchError(
        err,
        dispatch,
        GET_EXPERIENCE_DURATION,
        "Failed to get exepeience duration list.",
      ),
    );
};

export const GetSalaryPayDuration = () => async (dispatch: Function) => {
  await axios
    .get(environment.api.v1.common.salesPayDuration)
    .then(response => {
      let data = Arrays(response.data);
      dispatch({
        type: GET_SALARY_PAY_DURATION,
        payload: Arrays(response.data.Data),
      });
    })
    .catch(err =>
      dispatchError(
        err,
        dispatch,
        GET_SALARY_PAY_DURATION,
        "Failed to get saalary pay duration list.",
      ),
    );
};

export const getComapnyLogo = () => async (dispatch: Function) => {
  await axios
    .get(environment.api.v1.common.companyLogo, {
      headers: { Authorization: "a" },
    })
    .then(response => {
      dispatch({
        type: GET_COMPANY_LOGO,
        payload: response.data,
      });
    })
    .catch(err =>
      dispatchError(
        err,
        dispatch,
        GET_COMPANY_LOGO,
        "Failed to get company information",
      ),
    );
};

export const UploadFiles =
  (data: any, isCompany = false, type = "") =>
  async dispatch => {
    let apiURI =
      type == "logo" || type == "banner" || type == "coverImage"
        ? environment.api.v1.common.logoUpload
        : environment.api.v1.common.uploadFiles;
    await axios
      .post(`${apiURI}`, data)
      .then(response => {
        if (response.data.Status) {
          dispatch({
            type: UPLOAD_FILES,
            payload: response.data.Data,
            uploadType: type,
          });
        }
        toastMessage(dispatch, response);
      })
      .catch(err => {
        dispatch({
          type: UPLOAD_FILES,
          payload: [],
          uploadType: "",
        });
        const response = {
          data: {
            MessageType: "Danger",
            Message: "Failed to upload files.",
          },
        };
        toastMessage(dispatch, response);
      });
  };

export const DeleteFiles = (fileInfos: any) => async (dispatch: Function) => {
  await axios
    .delete(environment.api.v1.common.deletedFiles, fileInfos)
    .then(response => {
      toastMessage(dispatch, response);
    })
    .catch(err => {
      toastMessage(dispatch, "Failed to delete files");
    });
};

export const GetCompanyInformation =
  (isAdmin, baseUrl) => async (dispatch: Function) => {
    dispatch({ type: COMPANY_INFORMATION_REQUESTED, payload: true });
    let response = await get(
      environment.api.v1.common.companyInformation,
      dispatch,
      null,
      false,
    ).catch(ex => {});

    if (response) {
      dispatch({
        type: GET_COMPANY_INFORMATION,
        payload: response,
      });
    }

    if (!response && isAdmin && !isEmpty(baseUrl)) {
      window.location.replace(baseUrl + "/CP/ManageCompany.aspx");
    }
  };

export const GetServerDateTime =
  (isAdmin, baseUrl) => async (dispatch: Function) => {
    await axios
      .get(environment.api.v1.common.serverDateTime)
      .then(response => {
        let date = response.data && response.data.DateTime;

        dispatch({
          type: GET_SERVER_DATE_TIME,
          payload: date,
        });
      })
      .catch(err =>
        dispatchError(
          err,
          dispatch,
          GET_SERVER_DATE_TIME,
          "Failed to get company information",
        ),
      );
  };

export const GetNationality = () => async (dispatch: Function) => {
  await axios
    .get(environment.api.v1.common.nationalities)
    .then(response => {
      dispatch({
        type: GET_NATIONALITY,
        payload: response.data.map(x => {
          return { value: x.NationalityId, label: x.Nationality };
        }),
      });
    })
    .catch(err =>
      dispatchError(
        err,
        dispatch,
        GET_NATIONALITY,
        "Failed to get nationality list.",
      ),
    );
};

export const GetBranches = () => async (dispatch: Function) => {
  await axios
    .get(environment.api.v1.common.branches, loadingHeaders)
    .then(res => {
      if (res.status === 200) {
        const formatedData = [];
        res.data.map(d => {
          formatedData.push({ label: d.Text, value: d.Value });
        });
        dispatch({
          type: GET_BRANCHES,
          payload: formatedData,
        });
      } else {
        toastMessage(dispatch, "Failed to get branches.");
      }
    })
    .catch(err =>
      dispatchError(err, dispatch, GET_BRANCHES, "Failed to get branch list."),
    );
};

export const GetDepartments = () => async (dispatch: Function) => {
  await axios
    .get(environment.api.v1.common.departments)
    .then(res => {
      if (res.status === 200) {
        const formatedData = [];
        res.data.map(d => {
          formatedData.push({ label: d.Text, value: d.Value });
        });
        dispatch({
          type: GET_DEPARTMENTS,
          payload: formatedData,
        });
      } else {
        toastMessage(dispatch, "Failed to get departments.");
      }
    })
    .catch(err =>
      dispatchError(
        err,
        dispatch,
        GET_DEPARTMENTS,
        "Failed to get departments list.",
      ),
    );
};

export const GetSubDepartments = () => async (dispatch: Function) => {
  await axios
    .get(environment.api.v1.common.subDepartments)
    .then(res => {
      if (res.status === 200) {
        const formatedData = [];
        res.data.map(d => {
          formatedData.push({ label: d.Text, value: d.Value });
        });
        dispatch({
          type: GET_SUB_DEPARTMENTS,
          payload: formatedData,
        });
      } else {
        toastMessage(dispatch, "Failed to get sub department");
      }
    })
    .catch(err =>
      dispatchError(
        err,
        dispatch,
        GET_SUB_DEPARTMENTS,
        "Failed to get sub department list.",
      ),
    );
};

//Position or designation are same
export const GetDesignations = () => async (dispatch: Function) => {
  await axios
    .get(environment.api.v1.common.designations)
    .then(res => {
      if (res.status === 200) {
        const formatedData = [];
        res.data.map(d => {
          formatedData.push({ label: d.Text, value: d.Value });
        });
        dispatch({
          type: GET_DESIGNATION,
          payload: formatedData,
        });
      } else {
        toastMessage(dispatch, "Failed to get designation");
      }
    })
    .catch(err =>
      dispatchError(
        err,
        dispatch,
        GET_DESIGNATION,
        "Failed to get designation list.",
      ),
    );
};

//Level or job role are same
export const GetLevels = () => async (dispatch: Function) => {
  await axios
    .get(environment.api.v1.common.sharedLevels)
    .then(res => {
      if (res.status === 200) {
        const formatedData = [];
        res.data.map(d => {
          formatedData.push({ label: d.Text, value: d.Value });
        });
        dispatch({
          type: GET_LEVELS,
          payload: formatedData,
        });
      } else {
        toastMessage(dispatch, "Failed to get levels");
      }
    })
    .catch(err =>
      dispatchError(err, dispatch, GET_LEVELS, "Failed to get levels list."),
    );
};

export const DisplayClientSideMessage = (data: any) => (dispatch: Function) => {
  let response = null;
  if (data.MessageType) {
    response = data;
  } else {
    response = {
      MessageType: "Danger",
      Message: data,
    };
  }
  toastMessage(dispatch, response);
};

export const GetJobProfiles = () => async (dispatch: Function) => {
  dispatch({
    type: SET_JOB_PROFILE_DROP_LIST_LOADING,
    payload: true,
  });
  await axios
    .get(environment.api.v1.common.jobProfiles, loadingHeaders)
    .then(response => {
      dispatch({
        type: GET_JOB_PROFILE_DROP_LIST,
        payload: response.data.Data,
      });
    })
    .catch(err =>
      dispatchError(
        err,
        dispatch,
        GET_JOB_PROFILE_DROP_LIST,
        "Failed to get job profile list.",
      ),
    );
};

export const GetJobDescriptions = () => async (dispatch: Function) => {
  dispatch({
    type: SET_JOB_DESCRIPTION_DROP_LIST_LOADING,
    payload: true,
  });
  await axios
    .get(environment.api.v1.common.jobDescription, loadingHeaders)
    .then(response => {
      dispatch({
        type: GET_JOB_DESCRIPTION_DROP_LIST,
        payload: Arrays(response.data.Data),
      });
    })
    .catch(err =>
      dispatchError(
        err,
        dispatch,
        GET_JOB_DESCRIPTION_DROP_LIST,
        "Failed to get job description list.",
      ),
    );
};

export const GetLevelsWithGroups = () => async (dispatch: Function) => {
  dispatch({
    type: SET_LEVELS_WITH_GROUPS_LOADING,
    payload: true,
  });
  await axios
    .get(environment.api.v1.common.levelWithGroups, loadingHeaders)
    .then(response => {
      dispatch({
        type: GET_LEVELS_WITH_GROUPS,
        payload: response.data,
      });
    })
    .catch(err =>
      dispatchError(
        err,
        dispatch,
        GET_LEVELS_WITH_GROUPS,
        "Failed to get level with group list.",
      ),
    );
};

export const GetCompanyHolidays = (data: any) => async (dispatch: Function) => {
  // ;
  await axios
    .post(environment.api.v1.common.companyHolidays, data, loadingHeaders)
    .then(resp => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: GET_COMPANY_HOLIDAYS,
          payload: response.Data,
        });
      }
    })
    .catch(err =>
      dispatchError(
        err,
        dispatch,
        GET_COMPANY_HOLIDAYS,
        "Failed to get company holidays.",
      ),
    );
};

export const GetCompanyHolidaysWhole =
  (): any => async (dispatch: Function) => {
    const data = {
      holidayCategory: 0,
      pageIndex: 0,
      pageSize: 300,
    };
    await axios
      .post(environment.api.v1.common.companyHolidays, data, loadingHeaders)
      .then(resp => {
        const response = resp.data;
        if (response.Status) {
          dispatch({
            type: GET_COMPANY_HOLIDAYS_WHOLE,
            payload: response.Data,
          });
        }
      })
      .catch(err =>
        dispatchError(
          err,
          dispatch,
          GET_COMPANY_HOLIDAYS_WHOLE,
          "Failed to get company holidays.",
        ),
      );
  };

export const SetSelectedRecords = selectedRecord => async dispatch => {
  try {
    dispatch({
      type: SET_SELECTED_RECORD,
      payload: selectedRecord,
    });
  } catch (err) {
    toastMessage(dispatch, err.message);
  }
};

export const GetOverTimeTypes = () => async (dispatch: Function) => {
  var response = await get(
    `${environment.api.v1.common.overtimeType}`,
    dispatch,
    null,
    false,
  ).catch(ex => {});

  if (response?.Status) {
    dispatch({
      type: GET_OVERTIME_TYPES,
      payload: response.Data.map(x => {
        return { value: x.OvertimeTypeId, label: x.Name };
      }),
    });
  } else {
    dispatchError(
      err,
      dispatch,
      GET_OVERTIME_TYPES,
      "Failed to get level with group list.",
    );
  }
};

//get blood group types
export const GetBloodGroupList = () => async (dispatch: Function) => {
  await axios
    .get(environment.api.v1.common.bloodGroup)
    .then(res => {
      if (res.status === 200) {
        const formatedData = [];
        res.data.Data.map(d => {
          formatedData.push({ label: d.BloodGroupName, value: d.Id });
        });
        dispatch({
          type: GET_BLOOD_GROUP_LIST,
          payload: formatedData,
        });
      } else {
        toastMessage(dispatch, "Failed to get levels");
      }
    })
    .catch(err =>
      dispatchError(
        err,
        dispatch,
        GET_BLOOD_GROUP_LIST,
        "Failed to get levels list.",
      ),
    );
};

// Get Level-Dropdown
export const getLevels = () => async dispatch => {
  //dispatch(setAllowancExpenseLoading());
  await axios
    .get(environment.api.v1.common.levels)
    .then(res => {
      dispatch({
        type: GET_LEVELS,
        payload: res.data,
        //page:{index:res.data.PageIndex,total:res.data.RecordsTotal}
      });
    })
    .catch(err =>
      dispatch({
        type: GET_LEVELS,
        payload: null,
      }),
    );
};

// Get Payroll-Income-head-Dropdown
export const GetPayrollIncomeHeads = () => async dispatch => {
  //dispatch(setAllowancExpenseLoading());
  await axios
    .get(environment.api.v1.common.payrollIncomeHead)
    .then(res => {
      dispatch({
        type: GET_PAYROLL_INCOME_HEADS,
        payload: res.data.Data,
        //page:{index:res.data.PageIndex,total:res.data.RecordsTotal}
      });
    })
    .catch(err =>
      dispatch({
        type: GET_PAYROLL_INCOME_HEADS,
        payload: null,
      }),
    );
};

// get hiring methods
export const GetHiringMethods = () => async (dispatch: Function) => {
  await axios
    .get(environment.api.v1.common.hiremethod)
    .then(res => {
      if (res.status === 200) {
        const formatedData = [];
        res.data.map(d => {
          formatedData.push({ label: d.Text, value: d.Value });
        });
        dispatch({
          type: GET_HIRING_METHODS,
          payload: formatedData,
        });
      } else {
        toastMessage(dispatch, "Failed to get hirign");
      }
    })
    .catch(err =>
      dispatchError(
        err,
        dispatch,
        GET_HIRING_METHODS,
        "Failed to get Hiring methods list.",
      ),
    );
};

export const exportFilePost = (url, param) => async (dispatch: Function) => {
  await exportFile_post(url, dispatch, param).catch(ex => {});
};
