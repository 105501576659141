import merge from 'lodash/merge';
import { Controller } from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import { resolveObjectValueByPath } from './../../../../helpers';

// CHAKRA-UI
import { Flex, FormControl, FormHelperText } from '@chakra-ui/react';
import FormErrorLable from '../form-error-text';
import FormLabel from '../form-label/form-label';

interface BaseCheckboxProps {
  name: any;
  label?: any;
  value?: any;
  width?: any;
  control?: any;
  ignoreControl?: boolean;
  required?: boolean;
  errors?: any;
  rule?: any;
  onChange?: any;
  onChangeRHF?: any;
  customLabel?: any;
}

interface ReactSelectComponentProps
  extends BaseCheckboxProps,
    Record<any, any> {
  options: any[];
}

export const ReactSelectAsyncComponent = (props: ReactSelectComponentProps) => {
  let {
    label,
    name,
    onChange: _onChange,
    onChangeRHF,
    customLabel,
    ...rest
  } = props;

  const handleChange = (value: any) => {
    _onChange?.(name, value);
    onChangeRHF?.(value);
  };

  return (
    <AsyncSelect
      cacheOptions
      defaultOptions
      onChange={handleChange}
      components={{
        ...props.components,
        IndicatorSeparator: () => null,
      }}
      {...rest}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: state.isFocused ? '#B6C3D3' : '#CDD5DF',
        }),
        placeholder: (baseStyles, state) => ({
          ...baseStyles,
          color: '#878787',
          fontWeight: '300',
          fontSize: '14px',
          textTransform: 'lowercase',
        }),
        option: baseStyles => ({
          ...baseStyles,
          fontSize: '14px',
        }),
      }}
    />
  );
  // return <Select
  //     value={value}
  //     options={options}
  //     onChange={handleChange}
  //     components={{
  //         ...props.components,
  //         IndicatorSeparator: () => null,
  //     }}
  //     {...rest}
  // />
};

export function ReactSelectAsync(props: any) {
  const {
    label,
    customLabel,
    width,
    control,
    value,
    ignoreControl = false,
    required = false,
    errors,
    rule,
    ...rest
  } = props;

  const error = errors && resolveObjectValueByPath(errors, props.name)?.message;

  let _rule = {
    required: {
      value: required,
      message: 'Required',
    },
  };

  if (rule) {
    _rule = merge(_rule, rule);
  }

  if (!control || ignoreControl) {
    return (
      <FormControl
        display='flex'
        flexDirection='column'
        gap={1}
        id={props.name}
        isRequired={false}
        style={{ width }}
      >
        <FormLabel label={label} customLabel={customLabel} />
        <ReactSelectAsyncComponent value={value} {...rest} />
        <FormErrorLable error={error} fontSize='14px' />
      </FormControl>
    );
  }

  return (
    <FormControl
      display='flex'
      flexDirection='column'
      gap={1}
      id={props.name}
      isRequired={false}
      style={{ width }}
    >
      <Flex gap={1} alignItems='center'>
        <FormLabel
          color='#000000b3'
          fontSize='14px'
          lineHeight='21px'
          label={label}
          customLabel={customLabel}
        />
        {control && !required && (
          <FormHelperText m={0} color='gray.400'>
            optional
          </FormHelperText>
        )}
      </Flex>

      <Controller
        control={control}
        name={props.name}
        rules={_rule}
        render={controllerProps => {
          const {
            field: { onChange: _onChange, value: _value },
          } = controllerProps;

          return (
            <ReactSelectAsyncComponent
              {...rest}
              value={_value}
              onChangeRHF={_onChange}
            />
          );
        }}
      />
      <FormErrorLable error={error} fontSize='14px' />
    </FormControl>
  );
}

// ReactSelect.defaultProps = {
//     placeholder: "Select...",
//     noOptionMessage: "No Options",
// };

export default ReactSelectAsync;
