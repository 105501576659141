export const CATEGORY_VIEW_TYPE = {
  NUMERIC: "NUMERIC",
  CALCULATED: "CALCULATED",
  SUBJECTIVE: "SUBJECTIVE",
};

export const GOAL_TYPE = {
  NUMERIC: 1,
  SUBJECTIVE: 2,
};

export const APR_REVIEW_TYPE_BG_COLOR = {
  MANAGER: "#FF9D00", // YELLOW
  SELF: "#5FB701", // GREEN
  PEERS: "#B620E0", // PURPLE
};


export const SCORE_TYPE_MAP = {
  employeeAndManagerScore: {
    label: "Employee and Manager Score",
    value: 1,
  },
  peerScore: {
    label: "Peer Score",
    value: 2,
  },
};

export const CONTENT_TYPE_MAP = {
  matrixRatingQuestion: {
    label: "Matrix / Rating Question",
    value: 1,
  },
  freeTextQuestion: {
    label: "Free Text Question",
    value: 2,
  },
  textBlock: {
    label: "Text Block",
    value: 3,
  },
  goalAndActionRating: {
    label: "Goal And Action Rating",
    value: 4,
  },
  KSA: {
    label: "KSA",
    value: 5,
  },
  affinityLevel: {
    label: "Affinity ",
    value: 102,
  },
  additionalScore: {
    label: "Additional Score ",
    value: 6,
  },
};

export const EXECUTION_PLAN_RESPONSIBILITY = [
  {
    label: "Direct Manager",
    value: "Direct Manager",
  },
  {
    label: "Human Resources Department",
    value: "Human Resources Department",
  },
  {
    label: "Department Head",
    value: "Department Head",
  },
  {
    label: "Branch Head",
    value: "Branch Head",
  },
  {
    label: "Senior Management",
    value: "Senior Management",
  },
  // {
  //     label:"Other (Please Specify)",
  //     value:"Other (Please Specify)",
  // }
];

export const ASSESSMENT = {
  // none: {
  //     label: "None",
  //     value: 0,
  // },
  marks: {
    label: "Marks",
    value: 1,
  },
  ratingScale: {
    label: "Rating Scale",
    value: 2,
  },
  calculatedScore: {
    label: "Calculated Score",
    value: 3,
  },
};

// const CONTENT_TYPE_MAP_OMITED = CONTENT_TYPE_MAP.filter((contentType:any) => contentType.value !== 6 )


export const getContentTypeFromMapByValue = (value: number) => {
  if (!value) {
    return null;
  }
  return Object.keys(CONTENT_TYPE_MAP).find((item: any) => item.value === value);
};

export const MOCK_FORM_DATA = {
  reviewCycleOptionsn: [
    {
      label: "2021",
      value: 2021,
    },
    {
      label: "2022",
      value: 2022,
    },
  ],
  ratingScaleOptions: [
    {
      label: "5 Points Rating Scale",
      value: 1,
    },
    {
      label: "6 Points Rating Scale",
      value: 2,
    },
  ],
  unknownCheckboxOptions: [
    {
      label: "Allow different rating scale for each section",
      value: 1,
    },
    {
      label: "Set different weights for each rating section",
      value: 2,
    },
    {
      label: "Define respondents (Specify who fills out each section)",
      value: 3,
    },
  ],
  additionalInformationOptions: [
    {
      label: "Recent Job History",
      value: 1,
    },
    {
      label: "Recent Compensation History",
      value: 2,
    },
    {
      label: "Job Description",
      value: 3,
    },
    {
      label: "Qualification Summary",
      value: 4,
    },
    {
      label: "Employee Journal",
      value: 5,
    },
    {
      label: "Attendance Analytics",
      value: 6,
    },
    {
      label: "Task Analytics",
      value: 7,
    },
  ]
  ,
  formDragOptions: [
    "Company Values",
    "Goals and Actions",
  ],

  contenttype: Object.values(CONTENT_TYPE_MAP).filter((contentType: any) => contentType.value !== CONTENT_TYPE_MAP.affinityLevel.value),

  assessment: Object.values(ASSESSMENT),

  toBeAnsweredOptions: [
    {
      label: "Self",
      value: 1,
    },
    {
      label: "Manager",
      value: 2,
    },
    {
      label: "Peers",
      value: 3,
    },
  ],
  ksaRatingOptions: [
    {
      label: "Very Poor",
      value: 1,
    },
    {
      label: "Poor",
      value: 2,
    },
    {
      label: "Average",
      value: 3,
    },
    {
      label: "Good",
      value: 4,
    },
    {
      label: "Very Good",
      value: 5,
    },
    {
      label: "Exceptional",
      value: 6,
    },
  ],
};
