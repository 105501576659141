import { createAsyncThunk } from "@reduxjs/toolkit";

import { roleAPI } from "./api";
import { ApiClient } from "../../../network/apiClient";
import * as fromInterface from "../../../ts";
import { clearIndividualRole } from "./slice";

const NAMESPACE = "roles/prefix";
const NAMESPACE_COMMON = "common/prefix";

export const fetchRoles = createAsyncThunk(
  NAMESPACE.replace("prefix", "fetch/all"),
  async (params: any, { dispatch }) => {
    const param = {
      endpoint: roleAPI.roles,
      query: {
        ...params,
      },
      dispatch: dispatch,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.get<any>(param);
    const { Data, Status } = response;
    if (Status) {
      return Data;
    }
  }
);

export const fetchIndividualRole = createAsyncThunk(
  NAMESPACE.replace("prefix", "fetch/individual/userGroup"),
  async (id: any, { dispatch }) => {
    const param = {
      endpoint: `${roleAPI.roles}/${id}`,
      dispatch,
    };
    //clear the individual role
    dispatch(clearIndividualRole());
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.get<any>(param);
    const { Data, Status } = response;

    if (Status) {
      return Data;
    }
    return null;
  }
);

export const addOrUpdateIndividualRole = createAsyncThunk(
  NAMESPACE.replace("prefix", "update/individual/role"),
  async ({ payload, isUpdate, cb1, cb2 }: any, { dispatch }) => {
    const param = {
      endpoint: roleAPI.roles,
      body: {
        ...payload,
      },
      showMessage: true,
      dispatch,
    };
    const response: fromInterface.ResponseWrapper<any> = isUpdate
      ? await ApiClient.put<any>(param)
      : await ApiClient.post<any>(param);
    const { Data, Status } = response;
    if (Status) {
      cb1();
      cb2();
      return Data;
    }
    return null;
  }
);

export const deleteRole = createAsyncThunk(
  NAMESPACE.replace("prefix", "delete/individual/role"),
  async ({ id, cb }: any, { dispatch }) => {
    const param = {
      endpoint: `${roleAPI.roles}/${id}`,
      showMessage: true,
      dispatch,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.remove<any>(param);
    const { Data, Status } = response;
    if (Status) {
      cb();
      return Data;
    }
    return null;
  }
);

export const updateRoleStatus = createAsyncThunk(
  NAMESPACE.replace("prefix", "update/individual/status"),
  async ({ params, cb }: any, { dispatch }) => {
    const param = {
      endpoint: `${roleAPI.updateRoleStatus}?roleId=${params.roleId}&status=${params.status}`,
      showMessage: true,
      dispatch,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.put<any>(param);
    const { Data, Status } = response;
    if (Status) {
      cb();
      return Data;
    }
    return null;
  }
);

export const fetchRoleAssignedToUserGroup = createAsyncThunk(
  NAMESPACE.replace("prefix", "fetch/assign-to/user-group"),
  async ({ payload, cb }: any, { dispatch }) => {
    const param = {
      endpoint: roleAPI.targetPopulation,
      query: { ...payload },
      dispatch,
    };
    //clear the individual role
    dispatch(clearIndividualRole());

    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.get<any>(param);
    const { Data, Status } = response;

    if (Status) {
      cb();
      return Data;
    }
    return null;
  }
);

export const fetchUsersOptions = createAsyncThunk(
  NAMESPACE_COMMON.replace("prefix", "fetch/users/options"),
  async (params, { dispatch }) => {
    const param = {
      endpoint: roleAPI.userDropDown,
      dispatch,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.get<any>(param);
    const { Data, Status } = response;

    if (Status) {
      return Data;
    }
  }
);

export const updateAssignRoleOrPermission = createAsyncThunk(
  NAMESPACE.replace("prefix", "update/assign-role"),
  async ({ payload, cb1, cb2 }: any, { dispatch }) => {
    const param = {
      endpoint: roleAPI.targetPopulation,
      body: { ...payload },
      showMessage: true,
      dispatch,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.put<any>(param);

    const { Data, Status } = response;
    if (Status) {
      cb1();
      cb2();
      return Data;
    }
  }
);

export const fetchRoleTargetPopulationOption = createAsyncThunk(
  NAMESPACE.replace("prefix", "fetch/tp"),
  async ({ id, cb }: any, { dispatch }) => {
    const param = {
      endpoint: roleAPI.tpOption.replace("roleTypeId", id),
      dispatch,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.get<any>(param);

    const { Data, Status } = response;
    if (Status) {
      cb();
      return Data;
    }
    return null;
  }
);

export const fetchCustomPermission = createAsyncThunk(
  NAMESPACE.replace("prefix", "fetch/custom-permission"),
  async ({ payload, cb }: any, { dispatch }) => {
    const param = {
      endpoint: roleAPI.permissions,
      dispatch,
      query: {
        ...payload,
      },
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.get<any>(param);

    const { Data, Status } = response;
    if (Status) {
      cb();
      return Data;
    }
    return null;
  }
);

export const updateCustomPermission = createAsyncThunk(
  NAMESPACE.replace("prefix", "update/custom-permission"),
  async ({ payload, cb1, cb2 }: any, { dispatch }) => {
    const param = {
      endpoint: roleAPI.permissions,
      body: { ...payload },
      showMessage: true,
      dispatch,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.put<any>(param);

    const { Data, Status } = response;
    if (Status) {
      cb1();
      cb2();
      return Data;
    }
  }
);
