import React from "react";
import { DevTool } from "@hookform/devtools";
import { FormProvider as RHFFormProvider, useForm } from "react-hook-form";

/* eslint-disable-next-line */
export interface FormProviderProps {
  defaultValues?: any;
  children?: any;
  showDevTool?: boolean;
}

/**
 * <FormProvider
 defaultValues={{
            [name]: localMatrixRating?.reviewDetails,
            Comments: localMatrixRating?.Comments,
        }}
 >
 {({ methods }: any) => {
            return (
            <>
                <ConnectForm>
                {(formProps: any) => {
                    const {
                    control,
                    getValues,
                    formState: { errors },
                    } = formProps;

                    return (
                    // ... rest of the code here
                    );
                }}
                </ConnectForm>
            </>
            );
        }}
 </FormProvider>
 * @param props
 * @returns
 */
export function FormProviderWithMethods(props: FormProviderProps) {
  const { defaultValues, children, showDevTool = false } = props;
  const methods = useForm({ mode: 'all', defaultValues: defaultValues });

  return (
    <RHFFormProvider {...methods}>
      {showDevTool && <DevTool control={methods.control} />}

      <form>
        {children({
          methods: methods.handleSubmit,
        })}
      </form>
    </RHFFormProvider>
  );
}

export default FormProviderWithMethods;
