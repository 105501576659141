// MODELS
import * as fromModel from '@rigotech/hrms-model';

// NETWORK
import { ApiClient } from '../../../network/apiClient';
import { EMPLOYEE_DEVELOPMENT_PLAN } from './api';

// SLICE
import * as fromSlice from './slice';
import * as fromSelectors from './selectors';

export const fetchEmployeeDevelopmentPlans = (isExport?: boolean) => async (dispatch: any, getState: any) => {
  const state = getState()
  const { filters } = fromSelectors.selectEmployeeDevelopmentPlanState(state);

  if (!filters?.reviewCycleId?.value) {
    return
  }

  const param = {
    ReviewCycleId: filters?.reviewCycleId?.value,
    ReviewFormId: filters?.reviewFormId?.value || null,
    BranchId: filters?.branchId?.value || null,
    DepartmentId: filters?.departmentId?.value || null,
    isExport
  }

  if (isExport) {
    await ApiClient.exportFile_post({
      endpoint: EMPLOYEE_DEVELOPMENT_PLAN,
      body: param,
      dispatch: dispatch,
      showLoader: true,
    })

    return;
  }

  dispatch(fromSlice.fetchRequest());

  const response: fromModel.ResponseWrapper<fromModel.IEmployeeDevelopmentPlan> = await ApiClient.post<any>(
    {
      endpoint: EMPLOYEE_DEVELOPMENT_PLAN,
      dispatch: dispatch,
      body: param,
    }
  );

  const { Data, Status } = response;

  if (Status) {
    dispatch(fromSlice.fetchSuccess(Data));
  }

};

export const updateFilters = (newFilters: any) => async (dispatch: any, getState: any) => {
  dispatch(fromSlice.updateFilters(newFilters));
};


export const clearEmployeeDevelopmentPlans =
  (): any => async (dispatch: any) => {
    dispatch(fromSlice.clear());
  };
