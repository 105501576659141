import * as fromInterface from "../../../ts";
import * as fromConstant from "../../../constants";
import { ApiClient } from "../../../network/apiClient";
import * as fromSlice from "./slice";
import {
  getGeoOfficeSites,
  geoOfficeSitesCrude,
  updateGeoOfficeLocation,
  temporaryDeleteOfficeSites,
} from "./api";

export const fetchBranchesWorkSites =
  (data?: any) => async (dispatch: any, getState: any) => {
    dispatch(fromSlice.fetchRequest());

    const response: any = await ApiClient.post({
      endpoint: getGeoOfficeSites,
      dispatch: dispatch,
      body: { ...data },
    });

    dispatch(fromSlice.fetchSuccess(response?.Data));

    return response;
  };

export const saveWorkBranchesWorkSites =
  (data?: any, cb?: any): any =>
  async (dispatch: any, getState: any) => {
    const param = {
      endpoint: geoOfficeSitesCrude,
      body: {
        ...data,
      },
      dispatch: dispatch,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.post<any>(param);
    const { Data, Status } = response;
    if (Status) {
      dispatch(
        fetchBranchesWorkSites({
          pageIndex: 0,
          pageSize: 0,
          isActive: true,
          officeName: "",
        })
      );
    }
    cb?.(Status);
  };
export const updateWorkBranchesWorkSites =
  (data?: any, cb?: any): any =>
  async (dispatch: any, getState: any) => {
    const param = {
      endpoint: geoOfficeSitesCrude,
      body: {
        ...data,
      },
      dispatch: dispatch,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.put<any>(param);
    const { Data, Status } = response;
    if (Status) {
      dispatch(
        fetchBranchesWorkSites({
          pageIndex: 0,
          pageSize: 0,
          isActive: true,
          officeName: "",
        })
      );
    }
    cb?.(Status);
  };

export const deleteBranchesWorkSites =
  (id: any, tabValue: any): any =>
  async (dispatch: any, getState: any) => {
    const param = {
      endpoint: `${geoOfficeSitesCrude}/${id}`,
      dispatch: dispatch,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.remove<any>(param);
    const { Data, Status } = response;

    if (Status) {
      dispatch(
        fetchBranchesWorkSites({
          pageIndex: 0,
          pageSize: 0,
          isActive: tabValue,
          officeName: "",
        })
      );
    }
    return Status;
  };

export const updateWorkBranchesWorkSitesLocation =
  (data?: any, cb?: any): any =>
  async (dispatch: any, getState: any) => {
    const param = {
      endpoint: updateGeoOfficeLocation,
      body: {
        ...data,
      },
      dispatch: dispatch,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.put<any>(param);
    const { Data, Status } = response;
    if (Status) {
      dispatch(
        fetchBranchesWorkSites({
          pageIndex: 0,
          pageSize: 0,
          isActive: null,
          officeName: "",
        })
      );
    }
    cb?.(Status);
  };

export const temporaryDeleteBranchesWorkSites =
  (id: any, tabValue: any): any =>
  async (dispatch: any, getState: any) => {
    const param = {
      endpoint: `${temporaryDeleteOfficeSites}/${id}`,
      dispatch: dispatch,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.put<any>(param);
    const { Data, Status } = response;

    if (Status) {
      dispatch(
        fetchBranchesWorkSites({
          pageIndex: 0,
          pageSize: 0,
          isActive: tabValue,
          officeName: "",
        })
      );
    }
    return Status;
  };

export const clearBranchesWorkSites = () => (dispatch: any) => {
  dispatch(fromSlice.clear());
};
