// @ts-nocheck 
import React, {useState, useEffect, memo} from "react";
import Modal from "components/modal";
import isEmpty from "helpers/isEmpty";
import AvatarEdit from "react-avatar-edit";
import Button from "components/button";

const CropModal = ({src, open, setOpen, setImages, cropImages, setCropImage}) => {
  const [small, setSmall] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!isEmpty(src)) {
      setOpen(true);
    }
  }, [src]);

  useEffect(() => {
    if (!isEmpty(cropImages)) {
      setSmall(cropImages);
    }
  }, [cropImages]);

  const onCrop = (preview) => {
    setSmall(preview);
  };

  const onClose = () => {
    setSmall(null);
  };

  const onCropSave = () => {
    setCropImage(small);
    setOpen(false);
  };

  const onBeforeFileLoad = (elem) => {
    const validImageExtension = ["png", "jpg", "jpeg"];
    const file = elem.target.files[0];
    const validExtensions = validImageExtension;
    const extension = file.name.split(".")[1];
    const isValidExtension = validExtensions.find((f) => f == extension) ? true : false;
    if (file.size > 556000) {
      //556000 Byte==556 Kilobyte
      setError("Maximum valid file size is 556 Kb.");
      elem.target.value = "";
    } else if (!isValidExtension) {
      setError("Valid extension are " + validExtensions);
      elem.target.value = "";
    } else {
      setImages(file);
    }
  };

  if (!isEmpty(src)) {
    return (
      <Modal
        className="crop__modal"
        open={open}
        title="Crop Profile Photo"
        onModalClose={() => setOpen(false)}
      >
        <div className="crop-profile">
          <div className="crop-profile__container">
            <div className="crop-profile__crop">
              <AvatarEdit
                width={550}
                height={300}
                onCrop={onCrop}
                onClose={onClose}
                onBeforeFileLoad={onBeforeFileLoad}
                src={src}
                label={"Upload photo"}
                borderStyle={{
                  border: "2px solid #979797",
                  borderStyle: "dashed",
                  borderRadius: "8px",
                  textAlign: "center",
                }}
                exportAsSquare
                exportSize={150}
                exportQuality={1}
              />
            </div>
            <div className="crop-profile__preview">
              {!isEmpty(small) ? <img src={small} alt="" /> : null}
            </div>
          </div>
          <div className="crop-profile__footer">
            <Button onClick={() => onCropSave()} bg="primary">
              Crop Image
            </Button>
            <Button onClick={() => setOpen(false)} bg="secondary-dark">
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
  return null;
};

export default memo(CropModal);
