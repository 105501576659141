// @ts-nocheck 
import React,{createRef} from 'react'
import IdleTimer from 'react-idle-timer';
import { withRouter } from "react-router-dom";
import Modal from 'components/modal'
import {connect} from 'react-redux'
import {refreshToken,logoutUser} from 'actions/authAction'
import Button from 'components/button'

class CheckIdleTime extends React.Component<any,any>{
    public idleTimer = null;
    public warningTimeOut = null;
    public onAction:any;
    public myInterval:any;
    public onActive:any;
    public onIdle:any;
    constructor(props){
        super(props)
        this.idleTimer = null;
        this.state = {
            // timeout:1000 * 5 * 1,
            timeout:1000 * 58 * 60,
            showModal: false,
            userLoggedIn: false,
            seconds:0, 
            minutes:2,
            isTimedOut: false
        }

        this.idleTimer = createRef();
        this.warningTimeOut = createRef();
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this);
        this.handleClose = this.handleClose.bind(this)
        this.handleLogout = this.handleLogout.bind(this)
    }
    componentDidMount(){
      // console.log(this.props)
    }
    componentDidUpdate(prevProps){
    
      if(prevProps.auth.tokenDetails !== this.props.auth.tokenDetails){
        if(this.props.auth.tokenDetails){
          if(this.props.auth.tokenDetails.IdleTimeoutMinutes){
            const timeout = 1000 * 60 * (this.props.auth.tokenDetails.IdleTimeoutMinutes - 2);
            this.setState({timeout})
          }
        }
      }
    }
    componentWillUnmount() {
      clearInterval(this.myInterval)
      clearInterval(this.warningTimeOut.current);
    }
    // public static _onIdle;

    _onAction(e) {
    //   console.log('user did something', e)
      this.setState({isTimedOut: false})
    }
   
    _onActive(e) {
    //   console.log('user is active', e)
      this.setState({isTimedOut: false})
    }
   
    _onIdle(e) {
      console.log('user is idle', e, new Date().toLocaleTimeString())
      const isTimedOut = this.state.isTimedOut;
      this.warningTimeOut.current = setTimeout(this.handleLogout,2 * 60 * 1000);
      // if (isTimedOut) {
      //     console.log('Loged Out',new Date().toLocaleTimeString())
      //   //   this.props.history.push('/logout')
      // } else {
      //   this.setState({showModal: true})
      //   this.idleTimer.reset();
      //   this.setState({isTimedOut: true})
      // }
      this.myInterval = setInterval(() => {
          const { seconds, minutes } = this.state
          if (seconds > 0) {
              this.setState(({ seconds }) => ({
                  seconds: seconds - 1
              }))
          }
          if (seconds === 0) {
              if (minutes === 0) {
                  clearInterval(this.myInterval)
              } else {
                  this.setState(({ minutes }) => ({
                      minutes: minutes - 1,
                      seconds: 59
                  }))
              }
          } 
      }, 1000)
      this.setState({showModal: true})
      
    }
    handleClose() {
      
        this.setState({showModal: false,seconds:0,minutes:2});
        clearTimeout(this.warningTimeOut.current)
        clearInterval(this.myInterval)
    }
    handleLogout(isClicked = null) {
        console.log('user logout', new Date().toLocaleTimeString())
        this.setState({showModal: false,seconds:0,minutes:2});
        clearTimeout(this.warningTimeOut.current)
        clearInterval(this.myInterval);
        if(isClicked === 'clicked'){
          this.props.logoutUser();
          return;
        }
        this.props.logoutUser('inactivity');
        // this.props.history.push('/logout')
      }
  
    render(){
      const { match,auth:{isAuthenticated} } = this.props;
      const { seconds, minutes } = this.state;
      if(!isAuthenticated){
        return null;
      };
      return(
        <>
          <IdleTimer
            ref={this.idleTimer}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            
            timeout={this.state.timeout} />
            {
              this.state.showModal ? (
                <Modal 
                  open={this.state.showModal} 
                  onModalClose={this.handleClose} 
                  title={"You are about to be logged out"}
                  type="dangerContent"
                  noCloseButton
                  content={
                    <>
                      <div>
                          <p>Time Remaining: {minutes}:{seconds < 10 ? `0${seconds}` : seconds} seconds</p>
                      </div>
                      <div className="flex justify-center mt-sm">
                          <Button roundValue={4} title="Go Back To Work" onClick={this.handleClose} bg="primary" />
                          <Button roundValue={4} title="Logout" onClick={()=>this.handleLogout('clicked')} bg="danger" />
                      </div>
                    </>

                  }
               />
              ) :null
            }

        </>
      )
   }

}

const mapStateToProps = state=>({
  auth:state.auth
})

const mapDispatchToProps = {
  logoutUser,
  refreshToken
}

// export default withRouter(CheckIdleTime)
export default connect(mapStateToProps,mapDispatchToProps)(CheckIdleTime)