import { createSlice } from "@reduxjs/toolkit";
import * as fromInterface from "../../../ts";

export interface GoalDatabaseFilterState {
    "pageIndex": number,
    "pageSize": number,
    "reviewCycleId"?: any,
    "employee"?: string | null,
    "branchId"?: any,
    "departmentId"?: any,
    "levelId"?: any,
}

const initialState: GoalDatabaseFilterState = {
    "pageIndex": 0,
    "pageSize": 20,
    "reviewCycleId": null,
    "employee": null,
    "branchId": null,
    "departmentId": null,
    "levelId": null,
};

const goalDatabaseFilterSlice = createSlice({
    name: "goal database filter",
    initialState,
    reducers: {
        udpateFilter: (state, action) => {
            const payload = action.payload
            return { ...state, ...payload }
        },
        clear: (state) => {
            return initialState
        },
    },
});

export const {
    udpateFilter,
    clear,
} = goalDatabaseFilterSlice.actions

export default goalDatabaseFilterSlice.reducer;