import * as fromInterface from "../../../ts";
import { PerformanceRolloutAppraisalState } from "./slice";

const selectPerformanceRolloutAppraisalSlice = (state: any): PerformanceRolloutAppraisalState => {
    return state.dataStore.performanceRolloutAppraisal;
};


export const selectPerformanceRolloutAppraisalItem = (state: any): fromInterface.PerformanceRolloutAppraisal => {
    return selectPerformanceRolloutAppraisalSlice(state).item;
};

export const selectPerformanceRolloutAppraisalIsValidTab1 = (state: any): boolean => {
    return selectPerformanceRolloutAppraisalSlice(state).isValidTab1;
};
export const selectPerformanceRolloutAppraisalIsValidTab2 = (state: any): boolean => {
    return selectPerformanceRolloutAppraisalSlice(state).isValidTab2;
};
export const selectPerformanceRolloutAppraisalIsValidTab3 = (state: any): boolean => {
    return selectPerformanceRolloutAppraisalSlice(state).isValidTab3;
};

export const selectPerformanceRolloutAppraisalStatus = (state: any): fromInterface.loadingType => {
    return selectPerformanceRolloutAppraisalSlice(state).status;
};

export const selectPerformanceRolloutAppraisalLoaded = (state: any): boolean => {
    return selectPerformanceRolloutAppraisalSlice(state).loaded;
};