// @ts-nocheck
import { Time_Format } from "helpers/dateFormat";

export const useParam = () => {
  const currentTime = Time_Format(new Date());
  const param = { ManualVisibleTime: currentTime };

  return {
    param,
  };
};
