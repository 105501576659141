import { AprPMSConfigType } from "../../../enums/appraisal"
import * as fromInterface from "../../../ts"
import { PMSConfigState, PMSConfigTypeState } from "./slice"

const selectPMSConfigSlice = (state: any): PMSConfigState => {
  return state.dataStore.pmsConfig
}

export const selectPMSConfig = (state: any): any =>
  selectPMSConfigSlice(state).items

export const selectEmployeeSignature = (state: any): PMSConfigTypeState => {
  let obj = selectPMSConfigSlice(state).items.find(
    x => x.ConfigType === AprPMSConfigType.EmployeeSignature,
  )
  return (
    obj ??
    ({
      ConfigValue: "",
      ConfigType: AprPMSConfigType.EmployeeSignature,
    } as PMSConfigTypeState)
  )
  //  return selectPMSConfigSlice(state).items.find(x => x.ConfigType === AprPMSConfigType.EmployeeSignature);
}

export const selectPeerSignatureMessage = (state: any): PMSConfigTypeState => {
  let obj = selectPMSConfigSlice(state).items.find(
    x => x.ConfigType === AprPMSConfigType.PeerSignature,
  )
  return (
    obj ??
    ({
      ConfigValue: "",
      ConfigType: AprPMSConfigType.PeerSignature,
    } as PMSConfigTypeState)
  )
}

export const selectManagerSignatureMessage = (
  state: any,
): PMSConfigTypeState => {
  let obj = selectPMSConfigSlice(state).items.find(
    x => x.ConfigType === AprPMSConfigType.ManagerSignature,
  )
  return (
    obj ??
    ({
      ConfigValue: "",
      ConfigType: AprPMSConfigType.ManagerSignature,
    } as PMSConfigTypeState)
  )
}

export const selectEmployeeConsentMessage = (
  state: any,
): PMSConfigTypeState => {
  let obj = selectPMSConfigSlice(state).items.find(
    x => x.ConfigType === AprPMSConfigType.EmployeeConsent,
  )
  return (
    obj ??
    ({
      ConfigValue: "",
      ConfigType: AprPMSConfigType.EmployeeConsent,
    } as PMSConfigTypeState)
  )
}

export const selectEmailTemplateForInvitationToCompleteReviewMessage = (
  state: any,
): PMSConfigTypeState => {
  let obj = selectPMSConfigSlice(state).items.find(
    x =>
      x.ConfigType ===
      AprPMSConfigType.EmailTemplateForInvitationToCompleteReview,
  )
  return (
    obj ??
    ({
      ConfigValue: "",
      ConfigType: AprPMSConfigType.EmailTemplateForInvitationToCompleteReview,
    } as PMSConfigTypeState)
  )
}

export const selectEmailTemplateForInvitationToNominateParticipationMessage = (
  state: any,
): PMSConfigTypeState => {
  let obj = selectPMSConfigSlice(state).items.find(
    x =>
      x.ConfigType ===
      AprPMSConfigType.EmailTemplateForInvitationToNominateParticipation,
  )
  return (
    obj ??
    ({
      ConfigValue: "",
      ConfigType:
        AprPMSConfigType.EmailTemplateForInvitationToNominateParticipation,
    } as PMSConfigTypeState)
  )
}

export const selectEmailTemplateForInvitationForPeersToProvideFeedbackMessage =
  (state: any): PMSConfigTypeState => {
    let obj = selectPMSConfigSlice(state).items.find(
      x =>
        x.ConfigType ===
        AprPMSConfigType.EmailTemplateForInvitationForPeersToProvideFeedback,
    )
    return (
      obj ??
      ({
        ConfigValue: "",
        ConfigType:
          AprPMSConfigType.EmailTemplateForInvitationForPeersToProvideFeedback,
      } as PMSConfigTypeState)
    )
  }

export const selectEmailTemplateForManagerAfterSelfReviewCompletionMessage = (
  state: any,
): PMSConfigTypeState => {
  let obj = selectPMSConfigSlice(state).items.find(
    x =>
      x.ConfigType ===
      AprPMSConfigType.EmailTemplateForManagerAfterSelfReviewCompletion,
  )
  return (
    obj ??
    ({
      ConfigValue: "",
      ConfigType:
        AprPMSConfigType.EmailTemplateForManagerAfterSelfReviewCompletion,
    } as PMSConfigTypeState)
  )
}

export const selectEmailTemplateForManagerToCompleteReviewMessage = (
  state: any,
): PMSConfigTypeState => {
  let obj = selectPMSConfigSlice(state).items.find(
    x =>
      x.ConfigType === AprPMSConfigType.EmailTemplateForManagerToCompleteReview,
  )
  return (
    obj ??
    ({
      ConfigValue: "",
      ConfigType: AprPMSConfigType.EmailTemplateForManagerToCompleteReview,
    } as PMSConfigTypeState)
  )
}

export const selectEmailTemplateForEmployeeToCompleteConsentMessage = (
  state: any,
): PMSConfigTypeState => {
  let obj = selectPMSConfigSlice(state).items.find(
    x =>
      x.ConfigType ===
      AprPMSConfigType.EmailTemplateForEmployeeToCompleteConsent,
  )
  return (
    obj ??
    ({
      ConfigValue: "",
      ConfigType: AprPMSConfigType.EmailTemplateForEmployeeToCompleteConsent,
    } as PMSConfigTypeState)
  )
}

export const selectEmailTemplateToInviteAdditionalEvaluatorsMessage = (
  state: any,
): PMSConfigTypeState => {
  let obj = selectPMSConfigSlice(state).items.find(
    x =>
      x.ConfigType ===
      AprPMSConfigType.EmailTemplateToInviteAdditionalEvaluators,
  )
  return (
    obj ??
    ({
      ConfigValue: "",
      ConfigType: AprPMSConfigType.EmailTemplateToInviteAdditionalEvaluators,
    } as PMSConfigTypeState)
  )
}
