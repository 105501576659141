// @ts-nocheck 
import { objectToFormData } from "object-to-formdata";
import isEmpty from "isEmpty";
import { api, post, deletion } from "../../../networkService";
import environment from 'environment'

export const UploadFiles = (formData: any) => async (dispatch: Function) => {
  // const formData = objectToFormData({});
  // formData.append(`files`, file);

  const res: any = await post(
    environment.api.v1.common.uploadFiles,
    dispatch,
    formData,
    false,
    true
  ).catch(ex => { });
  if (res && res.Status)
    return res.Data;
  return null
};

export const DeleteFiles = (fileInfos: any, showResponseMessage = true) => async (dispatch: Function) => {
  const res: any = await post(
    environment.api.v1.common.deletedFiles,
    dispatch,
    fileInfos,
    showResponseMessage
  ).catch(e => { return false });
  if (res && res.Status) return res.Status
  return false
};
