import React, { useRef } from 'react';

//REACT_TABLE
import { Table } from '@tanstack/react-table';

//CHAKRA
import { Box, Input, Select, Text } from '@chakra-ui/react';

//CUSTOM-HOOKS
import { DOTS, usePagination } from './usePagination';

//COMPONENTS
import CustomPaginationButtons from './customPaginationButtons';

//CONSTANT
import { PAGINATION_CASES } from '../constants';

//INTERFACE
interface IPaginationProps {
  data: any;
  table: Table<any>;
  handlePagination: (type: number, value?: number) => void | null;
  shouldPaginationBtnDisabled: (type: number) => boolean;
  totalCount: number;
}

const Pagination = (props: IPaginationProps) => {
  const {
    data,
    table,
    handlePagination,
    shouldPaginationBtnDisabled,
    totalCount,
  } = props;

  const pageRef = useRef<HTMLInputElement>(null);

  const [DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE] = React.useMemo(
    () => [0, 10],
    [],
  );
  const pageSizeOptions = React.useMemo(() => [10, 20, 30, 40, 50], []);

  const MAX_PAGE_SIZE = table.getPageCount();
  const PAGE_INDEX = table.getState().pagination.pageIndex;
  const PAGE_SIZE = table.getState().pagination.pageSize;
  const CURRENT_PAGE = table.getState().pagination.pageIndex + 1;

  const paginationRange = usePagination({
    currentPage: CURRENT_PAGE,
    totalCount: totalCount,
    siblingCount: 1,
    pageSize: MAX_PAGE_SIZE,
  });

  return (
    <Box className='custom_pagination' display={{ lg: 'flex' }} pt='15px'>
      <Box
        className='custom_pagination__left'
        display='flex'
        alignItems='center'
        marginBottom={{ sm: '8px', lg: '0' }}
        justifyContent={{ sm: 'space-between', md: 'flex-start' }}
      >
        <Box
          className='pagination-total-counts'
          fontWeight={400}
          fontSize='16px'
          color='gray.600'
        >
          {/* {`${PAGE_INDEX * PAGE_SIZE + 1}-${
            PAGE_INDEX * PAGE_SIZE + data.length
          } of ${totalCount} items`} */}
          <Text as='span' fontSize={16} fontWeight={500}>
            {PAGE_INDEX * PAGE_SIZE + 1}-{PAGE_INDEX * PAGE_SIZE + data.length}{' '}
            of {totalCount}{' '}
          </Text>
          <span>items</span>
        </Box>
        <CustomPaginationButtons
          {...{
            DOTS,
            paginationRange,
            CURRENT_PAGE,
            handlePagination,
            shouldPaginationBtnDisabled,
          }}
        />
      </Box>
      <Box
        className='custom-pagination__right'
        marginLeft={{ lg: '10px' }}
        display='flex'
        alignItems='center'
        flexWrap={{ base: 'wrap' }}
      >
        <Box
          className='pagination__info'
          fontWeight={400}
          fontSize='14px'
          color='gray.600'
          paddingRight='35px'
          minWidth={{ base: '100%', md: 'auto' }}
          mb={{ sm: '8px', md: '0' }}
        >
          <span>Page </span>
          <Text as='span' fontSize='16px' fontWeight={500}>
            {CURRENT_PAGE} of {MAX_PAGE_SIZE}
          </Text>
        </Box>

        <Box
          className='pagiantion__goto-page'
          display='flex'
          paddingRight='20px'
          alignItems='center'
        >
          <Text
            as='span'
            fontWeight={400}
            fontSize='16px'
            color='gray.600'
            whiteSpace='nowrap'
            paddingRight='5px'
          >
            Go to
          </Text>
          <Input
            ref={pageRef}
            type='number'
            bgColor='#fff'
            minWidth='65px'
            size='sm'
            fontWeight={500}
            height='38px'
            min={DEFAULT_PAGE_INDEX + 1}
            max={MAX_PAGE_SIZE}
            onFocus={() => pageRef.current?.select()}
            value={table.getState().pagination.pageIndex + 1}
            onChange={e => {
              const pageIndex = e.target.value
                ? Number(e.target.value) - 1
                : DEFAULT_PAGE_INDEX;
              handlePagination(PAGINATION_CASES.gotoPageIndex, pageIndex);
            }}
          />
        </Box>

        <div className='pagination__page-size'>
          <Select
            fontSize='14px'
            fontWeight={500}
            size='sm'
            height='38px'
            width='140px'
            minW='140px'
            bgColor='#fff'
            value={PAGE_SIZE}
            onChange={e => {
              const pageSize = e.target.value
                ? Number(e.target.value)
                : DEFAULT_PAGE_SIZE;
              handlePagination(PAGINATION_CASES.gotoPageSize, pageSize);
            }}
          >
            {pageSizeOptions.map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize}/page
              </option>
            ))}
          </Select>
        </div>
      </Box>
    </Box>
  );
};

export default Pagination;
