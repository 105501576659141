// @ts-nocheck 
import React from "react";
import PropTypes from "prop-types";
import Icon from 'components/icons';

interface Props {
  name: string,
  value: any,
  autoFocus: boolean,
  placeholder: string,
  disabled:boolean,
  onChange:Function,
  onKeyUp:Function,
  onBlur:Function,
  onSearchClick?:Function
};

const TextInput = (props:Props): JSX.Element => {
  const {
    name,
    value,
    autoFocus,
    disabled,
    placeholder,
    onSearchClick
  } = props;

  const handleChange = (event) => {
    const { name, value } = event.target;
    props.onChange && props.onChange(name, value);
  };

  const handleKeyUp = (event) => {
    const { name, value } = event.target;
    props.onKeyUp && props.onKeyUp(name, value);
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    props.onBlur && props.onBlur(name, value);
  };

  return (
      <div className="genericForm-group__search">
            <input
                className="genericForm-group__search-input"
                placeholder={placeholder}
                value={value}
                type="search"
                name={name}
                autoFocus={autoFocus}
                disabled={disabled}
                onKeyUp={handleKeyUp}
                onBlur={handleBlur}
                onChange={handleChange}
            />
            <div 
                onClick={()=>onSearchClick && onSearchClick()}
                className="genericForm-group__search-icon"
            >
                <Icon name="Search" justSVG />
            </div>
      </div>

  );
};

TextInput.defaultProps = {
  name: "",
  type: "text",
  autoFocus: false,
  placeholder: "",
};

export default TextInput;
