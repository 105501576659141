import { Box, Center, Spinner } from "@chakra-ui/react";
import React from "react";

export const ModalSpinner = () => {
  return (
    <Box
      position="fixed"
      width="100%"
      height="100%"
      top={0}
      left={0}
      color="#fff"
      zIndex={99}
      backdropFilter="brightness(0.80)"
      cursor="wait"
    >
      <Center position="absolute" top="50%" left="50%">
        <Spinner />
      </Center>
    </Box>
  );
};
export default ModalSpinner;
