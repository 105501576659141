import { createSlice } from "@reduxjs/toolkit";
import * as fromInterface from "../../ts";

export interface PersonalInfoState {
  employeeDetails: any;
  pendingRequests: any;
  employeeDetailsStatus: fromInterface.loadingType;
  employeeDetailsLoaded: boolean;
  pendingRequestsStatus: fromInterface.loadingType;
  pendingRequestsLoaded: boolean;
  savePersonalInfoStatus: fromInterface.loadingType;
}

const initialState: PersonalInfoState = {
  employeeDetails: null,
  pendingRequests: null,
  employeeDetailsStatus: fromInterface.STATUS.IDLE,
  employeeDetailsLoaded: false,
  pendingRequestsStatus: fromInterface.STATUS.IDLE,
  pendingRequestsLoaded: false,
  savePersonalInfoStatus: fromInterface.STATUS.IDLE,
};

const personalInfoSlice = createSlice({
  name: "personal info",
  initialState,
  reducers: {
    fetchEmployeeDetailsRequest: (state) => {
      state.employeeDetailsStatus = fromInterface.STATUS.LOADING;
    },
    fetchEmployeeDetailsSuccess: (state, action) => {
      const payload = action.payload;

      state.employeeDetails = payload;
      state.employeeDetailsStatus = fromInterface.STATUS.IDLE;
      state.employeeDetailsLoaded = true;
    },
    fetchPendingRequestsRequest: (state) => {
      state.pendingRequestsStatus = fromInterface.STATUS.LOADING;
    },
    fetchPendingRequestsSuccess: (state, action) => {
      const payload = action.payload;

      state.pendingRequests = payload;
      state.pendingRequestsStatus = fromInterface.STATUS.IDLE;
      state.pendingRequestsLoaded = true;
    },
    saveEmployeePersonalInfoRequest: (state) => {
      state.savePersonalInfoStatus = fromInterface.STATUS.LOADING;
    },
    saveEmployeePersonalInfoSuccess: (state) => {
      state.savePersonalInfoStatus = fromInterface.STATUS.IDLE;
    },
  },
});

export const {
  fetchEmployeeDetailsRequest,
  fetchEmployeeDetailsSuccess,
  fetchPendingRequestsRequest,
  fetchPendingRequestsSuccess,
  saveEmployeePersonalInfoRequest,
  saveEmployeePersonalInfoSuccess,
} = personalInfoSlice.actions;

export default personalInfoSlice.reducer;
