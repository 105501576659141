import * as fromInterface from "../../../ts";
import { LocationAttendenceReportState } from "./slice";

const selectLocationAttendenceReportCurrentSlice = (
  state: any
): LocationAttendenceReportState => {
  return state.dataStore.locationAttendanceReport;
};

export const selectLocationAttendenceReportStatus = (
  state: any
): fromInterface.loadingType => {
  return selectLocationAttendenceReportCurrentSlice(state).status;
};

export const selectLocationAttendenceReportLoaded = (state: any): boolean => {
  return selectLocationAttendenceReportCurrentSlice(state).loaded;
};

export const selectLocationAttendenceReportItems = (state: any) => {
  return selectLocationAttendenceReportCurrentSlice(state).items;
};
