import { Switch } from '@chakra-ui/react';
import { UncontrollerComponentProps } from './interface';
import { useSwitch } from './use-switch';

export const RigoUncontrolledComponent = (
  props: UncontrollerComponentProps,
) => {
  const { onChangeRHF, value: rhfValue, ...propsRest } = props;
  const {
    name,
    value,
    label,
    control,
    errors,
    required,
    rule,
    onChange: _onChange,

    ...contextRest
  } = useSwitch();

  const handleChange = (e: any) => {
    const { checked } = e.target;
    console.log(checked);
    _onChange?.(name, checked);
    onChangeRHF?.(checked);
  };

  const valueNormalized = rhfValue ?? value;

  const inputProps = {
    name,
    isChecked: valueNormalized,
    ...contextRest,
    ...propsRest,
  };

  return <Switch onChange={handleChange} id={name} {...inputProps} />;
};
