import React, {Component} from "react";
import {Time_Format} from "helpers/dateFormat";
import {connect} from "react-redux";
import {GetServerDateTime} from "src/actions/commonAction";

class index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: new Date(),
    };
  }
  componentDidMount() {
    this.props.GetServerDateTime();
    const {serverDateTime} = this.props.common;
    if (serverDateTime) {
      this.setState({
        time: new Date(serverDateTime),
      });
    }
    this.intervalID = setInterval(() => this.tick(), 1000);
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  tick() {
    // const date = new Date();
    let date = this.state.time || new Date();
    date.setSeconds(date.getSeconds() + 1);

    this.setState({
      time: date,
    });
    this.props.getTimeText && this.props.getTimeText(Time_Format(date));
  }
  render() {
    return <p>{Time_Format(this.state.time)}</p>;
  }
}

//export default index;

const mapStateToProps = (state) => ({
  common: state.commonReducer,
});

export default connect(mapStateToProps, {
  GetServerDateTime,
})(index);
