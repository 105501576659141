// @ts-nocheck
import React, { lazy } from 'react'
import RouteEnum from '../../../constants/routeEnum'

// Recruit Componenets
const Recruit = lazy(() => import('../../../scenes/recruit'))

// const Recruit = React.lazy(() => import('../../../scenes/recruit'))
const JobCreate = lazy(() => import('../../../scenes/recruit/jobCreate/jobCreateIndex'))
// const JobCreate = React.lazy(() => import('../../../scenes/recruit/jobCreate'))
const PositionDetails = React.lazy(() =>
  import('../../../scenes/recruit/jobCreate/positionDetails'),
)
const Applications = React.lazy(() =>
  import('../../../scenes/recruit/jobCreate/applications'),
)
const HiringStage = React.lazy(() =>
  import('../../../scenes/recruit/jobCreate/hiringStage'),
)
const Scorecard = React.lazy(() => import('../../../scenes/recruit/jobCreate/scorecard'))
const HiringTeam = React.lazy(() =>
  import('../../../scenes/recruit/jobCreate/hiringTeam'),
)
const RecruitDashboard = React.lazy(() => import('../../../scenes/recruit/dashboard'))
const RecruitDashboardOpening = React.lazy(() =>
  import('../../../scenes/recruit/dashboard/opening'),
)
const DashboardInformation = React.lazy(() =>
  import('../../../scenes/recruit/dashboard/information'),
)
const ApproverRequistionRequestScreen = React.lazy(() =>
  import('../../../scenes/recruit/jobRequisition/approverRequisitionRequestScreen'),
)
const JobRequisitionScreen = React.lazy(() =>
  import('../../../scenes/recruit/jobRequisition/jobRequisition'),
)
const MyRequistionRequestScreen = React.lazy(() =>
  import('../../../scenes/recruit/jobRequisition/myRequistionRequestScreen'),
)
const JobDetails = React.lazy(() =>
  import('../../../scenes/recruit/jobListing/jobDetails'),
)
const JobListing = React.lazy(() =>
  import('../../../scenes/recruit/jobListing/jobListing'),
)
const JobApplyScreen = React.lazy(() =>
  import('../../../scenes/recruit/jobApply/jobApplyScreen'),
)
const ApplicantsReportScreen = React.lazy(() =>
  import('../../../scenes/recruit/report/application'),
)
const EmailTemplateScreen = React.lazy(() =>
  import('../../../scenes/recruit/settings/emailTemplate'),
)

const routes = (features) => [
  {
    path: RouteEnum.RecruitPath,
    component: Recruit,
    // title: "Recruit",
    feature: features.Recruitment,
    children: recruitLinks,
  },
]

const recruitLinks = [
  {
    path: RouteEnum.RecruitDashboardPath,
    exact: true,
    isAdmin: true,
    component: RecruitDashboard,
    title: 'Recruit Dashboard',
  },
  {
    path: RouteEnum.RecruitDashboardOpeningPath,
    exact: true,
    isAdmin: true,
    component: RecruitDashboardOpening,
    title: 'Opening Dashboard',
  },
  {
    path: RouteEnum.RecruitDashboardInformationPath,
    exact: true,
    component: DashboardInformation,
    title: 'Recruit Dashboard Info',
  },
  {
    path: RouteEnum.RequistionRequestPath,
    exact: true,
    isAdmin: true,
    component: ApproverRequistionRequestScreen,
    title: 'Requisition Request',
  },
  {
    path: RouteEnum.MyRequistionRequestPath,
    exact: true,
    isAdmin: true,
    component: MyRequistionRequestScreen,
    title: 'My Requisition',
  },
  {
    path: RouteEnum.CreateNewJobRequisitionPath,
    exact: true,
    component: JobRequisitionScreen,
    isAdmin: true,
    title: 'Create Job Requisition',
  },
  {
    path: RouteEnum.UpdateJobRequisitionPath,
    exact: true,
    isAdmin: true,
    component: JobRequisitionScreen,
    title: 'Update Job Requisition',
  },
  {
    path: RouteEnum.JobListingPath,
    exact: true,
    isAdmin: true,
    component: JobListing,
    isNotPrivate: true,
    title: 'Job Listing Path',
  },
  {
    path: RouteEnum.JobDetailsPath + ':jobId',
    exact: true,
    component: JobDetails,
    title: 'Job Details',
  },
  {
    path: RouteEnum.JobApplyPath,
    exact: true,
    component: JobApplyScreen,
    title: 'Job Apply Path',
  },
  {
    path: RouteEnum.JobCreatePath,
    exact: true,
    component: JobCreate,
    // title: "Job Create",
    isAdmin: true,
    children: [
      {
        path: RouteEnum.PositionDetailsPath,
        exact: true,
        component: PositionDetails,
        title: 'Position Details',
        isAdmin: true,
      },
      {
        path: RouteEnum.ApplicationsPath,
        exact: true,
        component: Applications,
        title: 'Applications',
        isAdmin: true,
      },
      {
        path: RouteEnum.HiringStagePath,
        exact: true,
        component: HiringStage,
        title: 'Hiring Stage',
        isAdmin: true,
      },
      {
        path: RouteEnum.ScorecardPath,
        exact: true,
        component: Scorecard,
        title: 'Scored Card',
        isAdmin: true,
      },
      {
        path: RouteEnum.HiringTeamPath,
        exact: true,
        component: HiringTeam,
        title: 'Hiring Team',
        isAdmin: true,
      },
    ],
  },
  {
    path: RouteEnum.Applicants,
    exact: true,
    component: ApplicantsReportScreen,
    title: 'Applicants',
  },
  {
    path: RouteEnum.RecruitEmailTemplate,
    exact: true,
    component: EmailTemplateScreen,
    title: 'Email Template',
  },
]

export default routes
