import { ApiClient } from "../../../network/apiClient";
import { attendanceMappingFilterCountapi } from "./api";
import * as fromSlice from "./slice";

export const fetchAttendanceMappingFilterCount =
  (data?: any) => async (dispatch: any, getState: any) => {
    dispatch(fromSlice.fetchRequest());

    const response: any = await ApiClient.get({
      endpoint: `${attendanceMappingFilterCountapi}?branchId=${data?.branchId}&departmentId=${data?.departmentId}&employeeName=${data?.employeeName}`,

      // ?employeeName=%22%22&departmentId=-1&branchId=-1'
      dispatch: dispatch,
    });

    dispatch(fromSlice.fetchSuccess(response?.Data));

    return response;
  };

export const clearAttendanceMappingFilterCount = () => (dispatch: any) => {
  dispatch(fromSlice.clear());
};
