import React from 'react'
import RouteEnum from "../../../constants/routeEnum";

const RosterShifts = React.lazy(() => import("../../../scenes/roster/shifts"));
const RosterSchedule = React.lazy(() => import("../../../scenes/roster/schedule"));
const ShiftPattern = React.lazy(() => import("../../../scenes/roster/shiftPattern"));
const SchedulePdf = React.lazy(() => import("../../../scenes/roster/schedule/schedules/schedulePdf"));
const ShiftEmployeeAttendance = React.lazy(() => import("../../../scenes/roster/shiftEmployeeAttendance"));
const WorkHours = React.lazy(() => import("../../../scenes/roster/workHours"));
const Team = React.lazy(() => import("../../../scenes/roster/schedule/team"));
const ShiftDashboard = React.lazy(() => import("../../../scenes/roster/shift-dashboard"))
const ShiftRoles = React.lazy(() => import("../../../scenes/roster/schedule/roles"))


const roosterLinks = [
    {
        path: RouteEnum.roosterScheduleRoutes,
        exact: true,
        isAdmin: true,
        isTeamManager: true,
        component: RosterSchedule,
        title: "Roster Schedules",
        // feature:features.CoreHR
    },


    {
        path: RouteEnum.roosterSchedulePatternRoutes,
        exact: true,
        isAdmin: true,
        component: ShiftPattern,
        title: "Roster Shift Pattern",
        // feature:features.CoreHR
    },
    {
        path: RouteEnum.roosterShiftsRoutes,
        exact: true,
        isAdmin: true,
        component: RosterShifts,
        title: "Roster Shifts",
        // feature:features.CoreHR
    },
    {
        // path: RouteEnum.roosterSchedulePdfRoutes + '/:teamid/:startDate:/:endDate',
        path: RouteEnum.roosterSchedulePdfRoutes,
        exact: true,
        isAdmin: true,
        component: SchedulePdf,
        title: "Roster Shifts PDF",
        // feature:features.CoreHR
    },
    {
        // path: RouteEnum.roosterSchedulePdfRoutes + '/:teamid/:startDate:/:endDate',
        path: RouteEnum.roosterWorkHousRoutes,
        exact: true,
        isAdmin: true,
        component: WorkHours,
        title: "Roster Working Hours",
        // feature:features.CoreHR
    },
    {
        path: RouteEnum.roosterEmployeeAttendance,
        exact: true,
        // isAdmin: true,
        component: ShiftEmployeeAttendance,
        title: "Attendance",
    },
    {
        path: RouteEnum.roosterTeam,
        exact: true,
        // isAdmin: true,
        component: Team,
        title: "Team",
        // feature:features.CoreHR
    },
    {
        path: RouteEnum.roosterShiftDashboard,
        exact: true,
        isAdmin: true,
        isTeamManager: true,
        component: ShiftDashboard,
        title: "Dashboard",
        // feature:features.CoreHR
    },

    {
        path: RouteEnum.roosterShiftRoles,
        exact: true,
        isAdmin: true,
        isTeamManager: false,
        component: ShiftRoles,
        title: "Roles",
        // feature:features.CoreHR
    },

];

export default roosterLinks