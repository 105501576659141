import { createSlice } from '@reduxjs/toolkit'

export interface EmployeeDataChangeRequestsFilterState {
  pageIndex: number
  pageSize: number
  action?: any
  employeeName?: string
  section?: any
  fromDate?: any
  toDate?: any
  status?: string[]
}

const initialState: EmployeeDataChangeRequestsFilterState = {
  pageIndex: 0,
  pageSize: 10,
  action: '',
  employeeName: '',
  section: '',
  fromDate: '',
  toDate: '',
  status: ['0'],
}

const employeeDataChangeRequestsFilterSlice = createSlice({
  name: 'employee data change requests filter',
  initialState,
  reducers: {
    udpateFilter: (state, action) => {
      const payload = action.payload
      return { ...state, ...payload }
    },
    clear: (state) => {
      return initialState
    },
  },
})

export const { udpateFilter, clear } =
  employeeDataChangeRequestsFilterSlice.actions

export default employeeDataChangeRequestsFilterSlice.reducer
