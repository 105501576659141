
import React, { lazy } from "react";
import RouteEnum from "../../../constants/routeEnum";


// Task tracker components
const ActivityDashboardScreen = React.lazy(() => import("../../../scenes/taskTracker/activityDashboard"));
const TaskTrackerCommonTaskScreen = React.lazy(() => import("../../../scenes/taskTracker/commonTasks"));
const ActivityAndBillingRolesScreen = React.lazy(() => import("../../../scenes/taskTracker/commonTasks/activityAndBillingRoles"));
const LegalClientScreen = React.lazy(() => import("../../../scenes/taskTracker/commonTasks/legalClient"));
const TaskTrackerReportScreen = React.lazy(() => import("../../../scenes/taskTracker/reports"));
const ActivityReportScreen = React.lazy(() => import("../../../scenes/taskTracker/reports/activityReport"));
const ActivityNotSubmittedReportScreen = React.lazy(() => import("../../../scenes/taskTracker/reports/activityNotSubmittedReport"));
const HoursWorkedByClientScreen = React.lazy(() => import("../../../scenes/taskTracker/reports/hoursWorkedByClient"));
const HoursWorkedByPeopleScreen = React.lazy(() => import("../../../scenes/taskTracker/reports/hoursWorkedByPeople"));
const HoursCommittedScreen = React.lazy(() => import("../../../scenes/taskTracker/reports/hoursCommitted"));
const PeopleInProjectScreen = React.lazy(() => import("../../../scenes/taskTracker/reports/peopleInProject"));
const RawReportScreen = React.lazy(() => import("../../../scenes/taskTracker/reports/rawReport"));
const ProjectInformation = React.lazy(() => import("../../../scenes/taskTracker/activityDashboard/projectInformation"));

const Routes = (features) => [
    {
        path: RouteEnum.ActivityDashboardPath,
        exact: true,
        component: ActivityDashboardScreen,
        title: "Dashboard",
        feature: features.TaskTracker,
    },
    {
        path: RouteEnum.ActivityProjectReview,
        exact: true,
        component: ProjectInformation,
        title: "Project Review",
        feature: features.TaskTracker,
    },
    {
        path: RouteEnum.TaskTrackerReportPath,
        exact: true,
        component: TaskTrackerReportScreen,
        title: "Reports",
        feature: features.TaskTracker,
    },
    {
        path: RouteEnum.TaskTrackerCommonTaskPath,
        exact: true,
        component: TaskTrackerCommonTaskScreen,
        title: "Common Tasks",
        feature: features.TaskTracker,
    },
    {
        path: RouteEnum.LegalClientPath,
        exact: true,
        component: LegalClientScreen,
        title: "Clients",
        feature: features.TaskTracker,
    },
    {
        path: RouteEnum.ActivityAndBillingRolesPath,
        exact: true,
        component: ActivityAndBillingRolesScreen,
        title: "Activity and Billing Roles",
        feature: features.TaskTracker,
    },
    {
        path: RouteEnum.ActivityReportPath,
        exact: true,
        component: ActivityReportScreen,
        title: "Activity Report",
        feature: features.TaskTracker,
    },
    {
        path: RouteEnum.ActivityNotSubmittedReportPath,
        exact: true,
        component: ActivityNotSubmittedReportScreen,
        title: "Activity Not Submitted",
        feature: features.TaskTracker,
    },
    {
        path: RouteEnum.HoursWorkedByClientPath,
        exact: true,
        component: HoursWorkedByClientScreen,
        title: "Hours Worked by Client",
        feature: features.TaskTracker,
    },
    {
        path: RouteEnum.HoursWorkedByPeoplePath,
        exact: true,
        component: HoursWorkedByPeopleScreen,
        title: "Hours Worked by People",
        feature: features.TaskTracker,
    },
    {
        path: RouteEnum.HoursCommittedPath,
        exact: true,
        component: HoursCommittedScreen,
        title: "Hours Committed",
        feature: features.TaskTracker,
    },
    {
        path: RouteEnum.PeopleInProjectPath,
        exact: true,
        component: PeopleInProjectScreen,
        title: "People in Project",
        feature: features.TaskTracker,
    },
    {
        path: RouteEnum.RawReportPath,
        exact: true,
        component: RawReportScreen,
        title: "Raw Report",
        feature: features.TaskTracker,
    }]

export default Routes;