import { AuthToken, Expires, ExpiresMinutes, IdleTimeOut, RefreshToken, UserDetail } from "../constants";

export const TokenHelper = {
    saveAllToken: ({
        AuthTokenVal,
        RefreshTokenVal,
        IdleTimeOutVal,
        ExpiresMinutesVal,
        ExpiresVal,
    }: any) => {
        localStorage.setItem(AuthToken, AuthTokenVal);
        localStorage.setItem(ExpiresMinutes, ExpiresMinutesVal);
        localStorage.setItem(RefreshToken, RefreshTokenVal);
        localStorage.setItem(IdleTimeOut, IdleTimeOutVal);
        localStorage.setItem(Expires, ExpiresVal);
    },
    clearAllToken: () => {
        localStorage.setItem(ExpiresMinutes, "");
        localStorage.setItem(AuthToken, "");
        localStorage.setItem(RefreshToken, "");
        localStorage.setItem(IdleTimeOut, "");
        localStorage.setItem(UserDetail, "");
        localStorage.setItem(Expires, "");
    },
    adduserDetails: (userDetails: any) => {
        localStorage.setItem(UserDetail, userDetails);
    },
    getAuthToken: () => {
        return localStorage.getItem(AuthToken)
        // return state?.auth?.token ?? "";
    },
    updateAuthToken  : (token: any) => localStorage.setItem(AuthToken, token),
    getExpiresMinutes: () => localStorage.getItem(ExpiresMinutes),
    getRefreshToken  : () => localStorage.getItem(RefreshToken),
    getIdleTimeOut   : () => localStorage.getItem(IdleTimeOut),
    getUserDetail    : () => JSON.parse(localStorage.getItem(UserDetail) || "{}"),
    getExpires       : () => localStorage.getItem(Expires)
}
