import { ADToBS, BSToAD } from 'bikram-sambat-js';
import dayjs from 'dayjs';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { CalenderData, useConfig } from '../../../Config';
import { useTrans } from '../../../Locale';
import { localeType, ParsedDate, SplittedDate } from '../../../Types';
import {
  getNumberOfDaysInBSMonth,
  range,
  splitDate,
} from '../../../Utils/common';

interface DayPickerBodyProps {
  selectedDate: ParsedDate;
  calenderDate: ParsedDate;
  onSelect: (date: SplittedDate) => void;
  disableDateBefore?: any;
  disableDateAfter?: any;
}

interface DayInfo {
  day: number;
  month: number;
  year: number;
  isCurrentMonth: boolean;
  isToday: boolean;
  isSelected: boolean;
}

const DayPickerBody: FunctionComponent<DayPickerBodyProps> = ({
  selectedDate,
  calenderDate: date,
  onSelect,
  disableDateBefore,
  disableDateAfter,
}) => {
  const weeksInMonth = useMemo(
    () =>
      Math.ceil(
        (date.firstAdDayInBSMonth.getDay() + date.numberOfDaysInBSMonth) / 7
      ) - 1,
    [date]
  );
  const previousMonth = useMemo(
    () => (date.bsMonth - 1 !== 0 ? date.bsMonth - 1 : 12),
    [date]
  );
  const previousYear = useMemo(
    () => (previousMonth === 12 ? date.bsYear - 1 : date.bsYear),
    [previousMonth, date]
  );
  const previousMonthDays = useMemo(
    () =>
      previousYear >= CalenderData.minBSYear
        ? getNumberOfDaysInBSMonth({
          month: previousMonth,
          year: previousYear,
        })
        : 30,
    [previousYear]
  );

  const { getConfig } = useConfig();
  const { numberTrans } = useTrans(getConfig<localeType>('currentLocale'));

  const getDayInfo = useCallback(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (weekNum, weekDayNum): DayInfo => {
      let day = weekNum * 7 + weekDayNum - date.firstAdDayInBSMonth.getDay();
      const month = date.bsMonth;
      const year = date.bsYear;

      let isCurrentMonth = true;

      if (day <= 0) {
        day = previousMonthDays + day;
        isCurrentMonth = false;
      } else if (day > date.numberOfDaysInBSMonth) {
        day = day - date.numberOfDaysInBSMonth;
        isCurrentMonth = false;
      }

      const today = splitDate(ADToBS(new Date()));

      const isToday = isCurrentMonth
        ? today.day === day &&
        today.month === date.bsMonth &&
        today.year === date.bsYear
        : false;
      const isSelected = isCurrentMonth
        ? selectedDate.bsDay === day &&
        selectedDate.bsMonth === date.bsMonth &&
        selectedDate.bsYear === date.bsYear
        : false;

      return { day, month, year, isCurrentMonth, isToday, isSelected };
    },
    [date, selectedDate, previousMonthDays]
  );

  const onDateSelectHandler = useCallback(
    (dayInfo: DayInfo) => {
      if (shouldDisable(dayInfo)) {
        return;
      }
      if (dayInfo.isCurrentMonth) {
        onSelect({
          year: dayInfo.year,
          month: dayInfo.month,
          day: dayInfo.day,
        });
      }
    },
    [onSelect]
  );

  // const checkIfDisabled = useCallback(
  //   (dayInfo: DayInfo) => {
  //     if (dayInfo.isCurrentMonth) {

  //     }
  //   },
  //   [onSelect]
  // );
  const shouldDisable = (dayInfo: DayInfo) => {
    // check if dsialbedatebefore and disable dateafter is presetn
    // if converted date is after ddb set false
    // if converted date is before dda set false
    // else set true

    let shouldDisable = false;
    const implodedDate = [dayInfo.year, dayInfo.month, dayInfo.day].join(
      '-'
    ) as string;
    const adConverted = BSToAD(implodedDate);

    if (disableDateBefore) {
      const adConvertedDisableDateBefore = BSToAD(disableDateBefore);

      if (dayjs(adConverted).isBefore(dayjs(adConvertedDisableDateBefore))) {
        shouldDisable = true;
      }
      // else {
      //   shouldDisable = false;
      // }
    }

    if (disableDateAfter) {
      const adConvertedDisableDateAfter = BSToAD(disableDateAfter);
      if (dayjs(adConverted).isAfter(dayjs(adConvertedDisableDateAfter))) {
        shouldDisable = true;
      }
      //  else {
      //   shouldDisable = true;
      // }
    }
    return shouldDisable;
  };

  return (
    <tbody>
      {range(0, weeksInMonth).map((weekNum) => (
        <tr key={weekNum}>
          {range(1, 7).map((weekDayNum) => {
            const dayInfo = getDayInfo(weekNum, weekDayNum);

            return (
              <td
                key={weekDayNum}
                className={`month-day ${dayInfo.isCurrentMonth ? 'current' : 'disabled'
                  }
                ${shouldDisable(dayInfo) ? 'disabled' : ''} l
                ${dayInfo.isToday ? 'today' : ''} l
                ${dayInfo.isSelected ? 'selected' : ''}`}
                onClick={() => onDateSelectHandler(dayInfo)}
              >
                {numberTrans(dayInfo.day)}
              </td>
            );
          })}
        </tr>
      ))}
    </tbody>
  );
};

export default DayPickerBody;
