import * as fromInterface from "../../../ts";
import * as fromPerformanceReviewBase from "../performance-review-base";

const performanceReviewConsentSlice = fromPerformanceReviewBase.createGenericSlice({
    name: 'performance review consent',
    initialState: {} as fromPerformanceReviewBase.GenericState<fromInterface.PerformanceReview>,
    reducers: {

    },
})

export const {
    fetchRequest,
    fetchSuccess,
    fetchError,
    fetchSelfRequest,
    fetchSelfSuccess,
    setStep,
    syncReviewDetail,
    clear,
} = performanceReviewConsentSlice.actions

export default performanceReviewConsentSlice.reducer
