import { Heading, HeadingProps } from "@chakra-ui/react";
interface PageHeadingProps extends HeadingProps {
    children: any
}

export const PageHeading = (props: PageHeadingProps) => {
    const { children,...rest } = props;

    return (
        <Heading fontWeight="medium" fontSize="22px" lineHeight="26px" color="#333333" {...rest} >{children}</Heading>
    )
}
