import { createGenericSlice, GenericState } from "../../@base-store"
import * as fromModel from "@rigotech/hrms-model"

const employeeDevelopmentPlanSlice = createGenericSlice({
  name: 'Employee Development Plan',
  initialState: { status: 'loading' } as GenericState<fromModel.IEmployeeDevelopmentPlan, fromModel.IEmployeeDevelopmentPlanFilters>,
  reducers: {

  },
})

export const {
  fetchRequest,
  fetchSuccess,
  updateFilters,
  fetchError,
  clear,
} = employeeDevelopmentPlanSlice.actions

export default employeeDevelopmentPlanSlice.reducer