// @ts-nocheck
import React from "react";
import { RegularList } from "components/common/regularList";
import { useSelector } from "react-redux";

import SummaryListItem from "./summaryListItem";

const Summary = ({ handleResultsSummaryClick }) => {
  const { courseResultSummary, requesting } = useSelector(
    (state: any) => state.lmsReducer
  );

  if (requesting) {
    return null;
  }

  return (
    <RegularList
      items={courseResultSummary}
      resourceName="summaryItem"
      itemComponent={SummaryListItem}
      handleClick={handleResultsSummaryClick}
    />
  );
};

Summary.defaultProps = {
  handleResultsSummaryClick: () => {},
};

export default Summary;
