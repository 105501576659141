import * as fromSlice from "./slice";

const selectCurrentAdditionalScoreSlice = (state: any): fromSlice.AdditionalScoreState => {
    return state.dataStore.additionalScore;
};

export const selectAdditionalScoreItems = (state: any): any => {
    return selectCurrentAdditionalScoreSlice(state).items;
};

export const selectAdditionalScoreStatus = (state: any): boolean => {
    return selectCurrentAdditionalScoreSlice(state).loaded;
};

export const selectAdditionalScoreLoaded = (state: any): boolean => {
    return selectCurrentAdditionalScoreSlice(state).loaded;
};