// @ts-nocheck 
const prefix = "/v1/tm";
export const taskManagement = {
  board: `${prefix}/boards`,
  recurringBoard: `${prefix}/boards/recurring`,
  duplicateBoard: `${prefix}/boards/duplicate`,
  boardLabel: `${prefix}/board-label`,
  boardDetails: `${prefix}/board-details`,
  boardMembers: `${prefix}/boards/member`,
  archive: `${prefix}/boards/archive`,
  activity: `${prefix}/boards/activity`,
  activityViewed: `${prefix}/boards/activity-viewed`,
  boards:{
    cardView:`${prefix}/boards/card-view`
  },
  task: {
    tasks:`${prefix}/tasks`,
    tasksDuplicate:`${prefix}/tasks/duplicate`,
    tasksMove:`${prefix}/tasks/move`,
    tasksOrder:`${prefix}/tasks-order`,
    tableView: `${prefix}/tasks/table-view`,
    boardView: `${prefix}/tasks/board-view`,
    toDo: `${prefix}/tasks/to-do`,
    taskDetail: `${prefix}/tasks-detail`,
    taskRating: `${prefix}/tasks-rating`,
    taskComments: `${prefix}/tasks-comments`,
    updateDetails: `${prefix}/tasks/update-details`,
    checkList: `${prefix}/tasks/check-list`,
  },

  Comment: {
    getCommentByTask: `${prefix}/comments/task`,
    comment: `${prefix}/comments`,
    addReaction: `${prefix}/comments/add-reaction`,
    removeReaction: `${prefix}/comments/remove-reaction`,
  },
};
