import React from "react";
import { AD2BS } from "components/calendar/backup/helpers/dateConvert";
import { getDateSlash, getNepDate } from "components/calendar/helpers/calendar";
import { ne, en } from "../constants/monthName";
import moment from "moment";
import dayjs from "dayjs";

export const NewDateFormat = (date, format) => {
  return dayjs(date).format(format);
};

export const DateFormat = (date, format) => {
  return moment(new Date(date)).format(format);
};

export const Date_Format = (date) => {
  // return moment(new Date(date)).format("YYYY/MM/DD");
  return moment(new Date(date)).format("YYYY/MM/DD");
};

export const Date_Format_ALT = (date) => {
  // return moment(new Date(date)).format("YYYY/MM/DD");
  return moment(new Date(date)).format("YYYY/MM/DD");
};
export const Nepali_Date_Format = (date) => {
  // return moment(new Date(date)).format("YYYY/MM/DD");
  return moment(new Date(date)).format("YYYY/MM/DD");
};

export const Time_Format = (date) => {
  return moment(new Date(date)).format("hh:mm:ss a");
};

export const Time_Format_HM = (date) => {
  return moment(new Date(date)).format("hh:mm a");
};

export const DateTime_Format = (date) => {
  return moment(new Date(date)).format("MM/DD/YYYY HH:mm:ss");
};

export const formatedMonthDayYear = (date) => {
  return (
    moment(new Date(date)).format("MMMM") +
    " " +
    moment(new Date(date)).format("DD") +
    ", " +
    moment(new Date(date)).format("YYYY")
  );
};

export const formatedDayMonthYearWDay = (date, isEnglishDate = true) => {
  if (isEnglishDate) {
    const d = moment(new Date(date));
    return (
      <>
        <span style={{ fontWeight: "500" }}>
          {d.format("DD") +
            " " +
            d.format("MMMM") +
            ", " +
            d.format("YYYY") +
            " "}
        </span>
        <span>{d.format("dddd")}</span>
      </>
    );
  } else {
    const slashDate = getDateSlash(date);
    const bsDate = AD2BS(slashDate);
    const nepaliDateArray = bsDate.en;

    return (
      <>
        <span style={{ fontWeight: "500" }}>
          {nepaliDateArray.day +
            " " +
            nepaliDateArray.strMonth +
            ", " +
            nepaliDateArray.year +
            " "}
        </span>
        <span>{nepaliDateArray.strDayOfWeek}</span>
      </>
    );
  }
};

export const momentDate = (date) => {
  const d = moment(new Date(date));
  return d;
};

export const formattedEnglishDateMonthYear = (date, styles) => {
  const d = momentDate(date);

  return (
    <>
      <span style={styles}>
        {d.format("dddd") +
          ", " +
          d.format("MMMM") +
          " " +
          d.format("DD") +
          ", " +
          d.format("YYYY")}
      </span>
    </>
  );
};

export const formattedNepaliDateMonthYear = (date, styles) => {
  const slashDate = getDateSlash(date);
  const bsDate = AD2BS(slashDate);
  const nepaliDateArray = bsDate.en;

  return (
    <>
      <span style={styles}>
        {nepaliDateArray.strDayOfWeek +
          ", " +
          nepaliDateArray.strMonth +
          " " +
          nepaliDateArray.day +
          ", " +
          nepaliDateArray.year}
      </span>
    </>
  );
};

export const formatedDottDateWDay = (date, isEnglishDate = true) => {
  if (isEnglishDate) {
    const d = moment(new Date(date));
    return (
      <>
        <span style={{ fontWeight: "500" }}>{d.format("YYYY.MM.DD")}</span>
        <span className="ml-xsm">{d.format("dddd")}</span>
      </>
    );
  } else {
    const slashDate = getDateSlash(date);
    const bsDate = AD2BS(slashDate);
    const nepaliDateArray = bsDate.en;

    return (
      <>
        <span style={{ fontWeight: "500" }}>
          {nepaliDateArray.year +
            "." +
            nepaliDateArray.strMonth +
            ", " +
            nepaliDateArray.day +
            " "}
        </span>
        <span className="ml-xsm">{nepaliDateArray.strDayOfWeek}</span>
      </>
    );
  }
};

export const formatedDottDateWDayForExcel = (date, isEnglishDate = true) => {
  if (isEnglishDate) {
    const d = moment(new Date(date));
    return d.format("YYYY.MM.DD") + "    " + d.format("dddd");
  } else {
    const slashDate = getDateSlash(date);
    const bsDate = AD2BS(slashDate);
    const nepaliDateArray = bsDate.en;

    return (
      nepaliDateArray.year +
      "." +
      nepaliDateArray.strMonth +
      ", " +
      nepaliDateArray.day +
      "    " +
      nepaliDateArray.strDayOfWeek
    );
  }
};

export const formatedShortMonthDayYear = (date) => {
  return (
    moment(new Date(date)).format("MMM") +
    " " +
    moment(new Date(date)).format("DD") +
    ", " +
    moment(new Date(date)).format("YYYY")
  );
};
export const formatedFullDayMMYearTime = (date) => {
  const d = moment(new Date(date));
  return `${d.format("dddd")} ${d.format("MMMM")} ${d.format("DD")}, ${d.format(
    "YYYY"
  )} ${d.format("hh:mm a")}`;
};

export const formatedToMonthDay = (date) => {
  return (
    moment(new Date(date)).format("MMMM") +
    " " +
    moment(new Date(date)).format("DD")
  );
};
export const formatToNepaliMonthDay = (nepaliDate) => {
  const nepaliMonthName = en.name;
  let month = -1;
  let dayDate = 0;
  if (nepaliDate) {
    const splitedNepaliDate = nepaliDate.split("/");
    month = parseInt(splitedNepaliDate[1]);
    dayDate = parseInt(splitedNepaliDate[2]);
  }
  return nepaliMonthName[month - 1] + " " + dayDate;
};

export const startDatetime = (date) => {
  return moment(date, "YYYYMMDDHHmmss")
    .startOf("day")
    .format("MM/DD/YYYY HH:mm:ss");
};

export const endDatetime = (date) => {
  return moment(date, "YYYYMMDDHHmmss")
    .endOf("day")
    .format("MM/DD/YYYY HH:mm:ss");
};

export const getHrsMinFormatFromMinutes = (mins) => {
  let hours = mins / 60;
  let rhours = Math.floor(hours);
  let minutes = (hours - rhours) * 60;
  let rminutes = Math.round(minutes);
  return rhours + "h " + rminutes + "m";
};

export const isDateTodayOrAfter = (toCheck, givenDate) => {
  return (
    moment(new Date(toCheck)).isAfter(new Date(givenDate)) ||
    moment(new Date(toCheck)).isSame(new Date(givenDate), "day")
  );
};

export const geDateDifference = (start, end) => {
  var a = moment(start);
  var b = moment(end);
  return b.diff(a, "days");
};
