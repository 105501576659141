// @ts-nocheck 
import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import Table from "components/customTable";

import {
  EmployeeNameFrame,
  RankFrame,
  ScoreFrame,
  StatusFrame,
  ResultsFrame,
  StatusChangedOnFrame,
} from "./frameworkComponents";

const CourseTable = ({handleReportsClick, courseResultDetailData}) => {
  const {courseResultDetail} = useSelector((state: any) => state.lmsReducer);

  // const onRowSelect = (rows) => setSelectedRow(rows);

  const cols = useMemo(
    () => [
      {
        Header: "Employee Name",
        accessor: "EmployeeName",
        Cell: EmployeeNameFrame,
        width: 240,
      },
      {Header: "Position", accessor: "Position"},
      {Header: "Completed on", accessor: "StatusChangedOn", Cell: StatusChangedOnFrame},
      {Header: "Score", accessor: "ObtainedScore", Cell: ScoreFrame},
      {Header: "Rank", accessor: "Ranks", Cell: RankFrame},
      {Header: "Status", accessor: "StatusName", Cell: StatusFrame},
      {
        Header: "",
        accessor: "none",
        Cell: (cellProps) => (
          <ResultsFrame {...cellProps} onReportsClick={handleReportsClick} />
        ),
      },
    ],
    []
  );
  const data = useMemo(() => courseResultDetailData, [courseResultDetailData]);

  return (
    <div className="course-results__table">
      <div
        id="myGrid"
        style={{
          height: "100%",
        }}
        className="ag-theme-alpine"
      >
        <Table isFixedWidth data={data} columns={cols} />
      </div>
    </div>
  );
};
export default CourseTable;
