import isEmpty from "lodash/isEmpty";
import * as fromUILoaderStore from "../store/ui-loader";
import { toastMessage } from "../store/validation";
import * as fromInterface from "../ts";
import AxiosConfig from "./axiosConfig";
import * as XLSX from "xlsx";
// enums and modals
export enum RequestMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}
export enum HttpMethods {
  Get = "GET",
  Post = "POST",
  Patch = "PATCH",
  Delete = "DELETE",
  Put = "PUT",
}
const DEFAULT_HEADER = {
  "content-type": "application/json; charset=utf-8",
};
const MULTIPART_HEADER = {
  "content-type": "multipart/form-data",
};
const BLOB_HEADER = {
  responseType: "blob",
};
export const axios = new AxiosConfig();
// INTERFACES
export interface ApiRequest<T = any> {
  body?: T;
  query?: Record<string, any>;
  model?: T;
}
type FILE_ACTION_TYPE = "download" | "upload";
interface IRequest<T> {
  method: HttpMethods;
  dispatch: Function;
  endpoint: string;
  request?: ApiRequest<T>;
  headers?: any;
  showMessage?: boolean;
  showLoader?: boolean;
  filtActionType?: FILE_ACTION_TYPE;
  convertXlsx?: boolean;
}
interface BaseRequestProps {
  endpoint: string;
  dispatch: Function;
  showMessage?: boolean;
  showLoader?: boolean;
  convertXlsx?: boolean;
}
interface GetRequestProps extends BaseRequestProps {
  query?: Record<string, any>;
}
interface PostRequestProps<RequestType> extends BaseRequestProps {
  body?: RequestType;
}
interface PutRequestProps<RequestType> extends BaseRequestProps {
  body?: RequestType;
}
interface DeleteRequestProps extends BaseRequestProps {}
export class ApiClient {
  private static async request<ResponseType = any, RequestType = any>({
    method,
    dispatch,
    endpoint,
    request,
    headers = DEFAULT_HEADER,
    showMessage = true,
    showLoader = false,
    filtActionType = "download",
    convertXlsx = false,
  }: IRequest<RequestType>) {
    if (showLoader) {
      dispatch(fromUILoaderStore.show());
    }
    if (request?.query) {
      const query = Object.entries(request.query)
        .filter(([, value]) => typeof value !== "undefined")
        .map(([field, value]) => field + "=" + value)
        .join("&");
      endpoint += query ? "?" + query : "";
    }
    if (!axios.axiosInstance) {
      return null;
    }
    return await axios
      .axiosInstance({
        method,
        url: endpoint,
        data: request?.body,
        headers,
      })
      .then((res: any) => {
        if (
          headers?.["responseType"] === "blob" &&
          filtActionType === "download"
        ) {
          dispatch(fromUILoaderStore.hide());
          return this.downloadFile(res, convertXlsx);
        }
        const { data } = res;
        const hasAllProprty =
          data.hasOwnProperty("MessageType") &&
          data.hasOwnProperty("Message") &&
          data.hasOwnProperty("Status");
        if (!hasAllProprty) {
          return {
            Status: !data ? false : true,
            Data: data,
          } as ResponseType;
        }
        let nextData = {
          ...(data.Status === undefined && { Status: true }),
          ...(data?.Status !== undefined && { Status: data?.Status }),
          ...(data?.Status && { Status: data?.Status }),
          ...(data?.Data && { Data: data?.Data }),
          ...(data?.Message === undefined && { Message: [] }),
          ...(data?.Message && {
            Message:
              typeof data?.Message === "string"
                ? [data?.Message]
                : data?.Message,
          }),
          ...(data?.MessageType && { MessageType: data?.MessageType }),
          ...(data?.MessageType === undefined && {
            MessageType: fromInterface.MessageType.Info,
          }),
        };
        if (showMessage) {
          this.dispatchMessage({ dispatch, response: nextData });
        }
        dispatch(fromUILoaderStore.hide());
        return nextData as ResponseType;
      })
      .catch((err: any) => {
        const formattedErr: fromInterface.ResponseWrapper<any> = {
          Status: false,
          Message: err?.message,
          MessageType: fromInterface.MessageType.Danger,
        };
        this.dispatchMessage({ dispatch, response: formattedErr });
        dispatch(fromUILoaderStore.hide());
        return formattedErr;
      });
  }
  public static get<T>(params: GetRequestProps) {
    const getArgs = {
      ...params,
      method: HttpMethods.Get,
      request: { query: params.query },
      showMessage: params.showMessage || true,
    };
    return this.request<T>(getArgs)!;
  }
  public static post<RequestType, ResponseType = any>(
    params: PostRequestProps<RequestType>,
    isMultipart = false,
  ) {
    const postArgs: any = {
      ...params,
      request: { body: params.body },
      method: HttpMethods.Post,
      showMessage: params.showMessage || true,
    };
    if (isMultipart) postArgs.headers = MULTIPART_HEADER;
    return this.request<ResponseType>(postArgs)!;
  }
  public static put<RequestType, ResponseType = any>(
    params: PutRequestProps<RequestType>,
  ) {
    const putArgs = {
      ...params,
      method: HttpMethods.Put,
      request: { body: params.body },
      showMessage: params.showMessage || true,
    };
    return this.request<ResponseType>(putArgs)!;
  }
  public static remove<RequestType, ResponseType = any>(
    params: DeleteRequestProps,
  ) {
    const deleteArgs = {
      ...params,
      method: HttpMethods.Delete,
      showMessage: params.showMessage || true,
    };
    return this.request<ResponseType>(deleteArgs)!;
  }
  public static exportFile_get<T>(params: GetRequestProps) {
    const exportFileGetArgs = {
      ...params,
      request: { query: params.query },
      showMessage: params.showMessage || true,
      method: HttpMethods.Get,
      headers: BLOB_HEADER,
    };
    return this.request<T>(exportFileGetArgs)!;
  }
  public static exportFile_post<RequestType, ResponseType = any>(
    params: PostRequestProps<RequestType>,
  ) {
    const exportFilePostArgs = {
      ...params,
      request: { body: params.body },
      showMessage: params.showMessage || true,
      method: HttpMethods.Post,
      headers: BLOB_HEADER,
    };
    return this.request<ResponseType>(exportFilePostArgs)!;
  }
  public static uploadFile<RequestType, ResponseType = any>(
    params: PostRequestProps<RequestType>,
  ) {
    const exportFilePostArgs = {
      ...params,
      request: { body: params.body },
      showMessage: params.showMessage || true,
      method: HttpMethods.Post,
      headers: BLOB_HEADER,
      fileActionType: "upload",
    };
    return this.request<ResponseType>(exportFilePostArgs)!;
  }
  public static exportFile_put<RequestType, ResponseType = any>(
    params: PutRequestProps<RequestType>,
  ) {
    const exportFilePutArgs = {
      ...params,
      request: { body: params.body },
      showMessage: params.showMessage || true,
      method: HttpMethods.Put,
      headers: BLOB_HEADER,
    };
    return this.request<ResponseType>(exportFilePutArgs)!;
  }
  public static downloadFile(res: any, convertXlsx: boolean = true) {
    const data = res?.data;
    // should dispatch error message
    // todo review later
    if (res?.headers["content-length"] === 0) {
      // const message = data["message"];
      // if (message) dispatchMessage(dispatch, message);
      // resolve(null);
      return;
    }

    const contentDisposition: any = res.headers["content-disposition"];
    let filename = "";
    if (contentDisposition && contentDisposition.indexOf("attachment") !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
    }

    let url: any = "";
    var ext: any = filename.split(".").pop();

    if ((ext === "xlsx" || ext === "xls") && convertXlsx === true) {
      // Converts to XLSX format
      filename = filename.replaceAll(ext, "xlsx");
      const workbook = XLSX.read(data, { type: "string" });
      const excelData = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "buffer",
      });

      const blobdata = new Blob([excelData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      url = window.URL.createObjectURL(new Blob([blobdata]));
    } else {
      url = window.URL.createObjectURL(new Blob([data]));
    }
    const link: any = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    const responseType: any = {
      Status: true,
      Message: [],
      MessageType: fromInterface.MessageType.Info,
    };
    return responseType as ResponseType;
    // resolve(null);
  }
  public static dispatchMessage({
    dispatch,
    response,
  }: {
    dispatch: Function;
    response?: any;
  }) {
    !isEmpty(response.Message) && dispatch && toastMessage(dispatch, response);
  }
  // public static dispatchLoader({ dispatch }: {
  //     dispatch: Function,
  // }) {
  //     !isEmpty(response.Message) && dispatch && toastMessage(dispatch, response);
  // }
}
