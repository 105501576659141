import * as fromInterface from "../../../ts";
import { PerformanceRolloutEmployeeFilterState } from "./slice";

const selectPerformanceRolloutEmployeeFilterSlice = (state: any): PerformanceRolloutEmployeeFilterState => {
    return state.dataStore.performanceRolloutEmployeeFilter;
};

export const selectPerformanceRolloutEmployeeFilter = (state: any): PerformanceRolloutEmployeeFilterState => {
    return selectPerformanceRolloutEmployeeFilterSlice(state);
};
