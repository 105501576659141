import { ADToBS } from "bikram-sambat-js";
import React, { FunctionComponent, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Calender } from "./Calender";
import { useConfig } from "./Config";
import { useTrans } from "./Locale";
import { ENGLISH, INepaliDatePicker, localeType, NepaliDatepickerEvents } from "./Types";
import { childOf, executionDelegation, stitchDate } from "./Utils/common";
import { Input } from "../input/input";
import { Box } from "@chakra-ui/react";


export const CustomInput = (props: any) => {
  const { onChange, value, ...rest } = props;

  const [inputDate, setInputDate] = React.useState("");
  const dateFormat = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD format

  React.useEffect(() => {
    setInputDate(value);
  }, [value]);
  const handleInputChange = (inputValue: any) => {
    const formattedValue = formatInputDate(inputValue);
    setInputDate(formattedValue);

    const isValid = handleValidation(formattedValue);
    // todo mark2
    if (isValid) {
      props?.onChange(formattedValue);
    }
  };

  const handleValidation = (inputDate: any) => {
    return dateFormat.test(inputDate);
  };

  const formatInputDate = (inputValue: any) => {
    const numericValue = inputValue.replace(/\D/g, "");
    const year = numericValue.slice(0, 4);
    const month = numericValue.slice(4, 6);
    const day = numericValue.slice(6, 8);

    let formattedValue = "";
    if (year) {
      formattedValue += year;
    }
    if (month) {
      formattedValue += "-" + month;
    }
    if (day) {
      formattedValue += "-" + day;
    }

    return formattedValue;
  };

  return (
    <Box maxW={80}>
      <Input.TextInput
        value={inputDate}
        onChange={(_name: string, _value: any) => [
          handleInputChange(_value),
        ]}
        placeholder="YYYY-MM-DD"
        {...rest}
      />
    </Box>
  );
};

export const CustomInputWithRefForwarded = React.forwardRef((props: any, ref) => {
  return <CustomInput ref={ref} {...props} />;
});


const NepaliDatePicker: FunctionComponent<INepaliDatePicker> = (props) => {
  const {
    className,
    inputClassName,
    value,
    onChange,
    onSelect,
    options,
    disableDateBefore,
    disableDateAfter,
  } = props;


  const nepaliDatePickerWrapper = useRef<HTMLDivElement>(null);
  const nepaliDatePickerInput = useRef<HTMLInputElement>(null);

  const [date, setDate] = useState<string>("");
  const [showCalendar, setShowCalendar] = useState<boolean>(false);

  const { setConfig, getConfig } = useConfig();
  const { numberTrans } = useTrans(getConfig<localeType>("currentLocale"));

  const toEnglish = useCallback(
    (val: string): string => numberTrans(val, ENGLISH),
    [],
  );

  const returnDateValue = useCallback(
    (val: string): string => numberTrans(val, options.valueLocale),
    [options.valueLocale],
  );

  useEffect(() => {
    setConfig("currentLocale", options.calenderLocale);
  }, [options.calenderLocale]);

  useEffect(() => {
    setDate(toEnglish(value || ADToBS(new Date())));
  }, [value]);

  const handleClickOutside = useCallback((event: any) => {
    if (
      nepaliDatePickerWrapper.current &&
      childOf(event.target, nepaliDatePickerWrapper.current)
    ) {
      return;
    }

    setShowCalendar(false);
  }, []);

  useLayoutEffect(() => {
    if (showCalendar) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showCalendar]);

  useLayoutEffect(() => {
    if (showCalendar && nepaliDatePickerWrapper.current) {
      const nepaliDatePicker =
        nepaliDatePickerWrapper.current.getBoundingClientRect();
      const screenHeight = window.innerHeight;

      const calender: HTMLDivElement | null =
        nepaliDatePickerWrapper.current.querySelector(".calender");
      if (calender) {
        setTimeout(() => {
          const calenderHeight = calender.clientHeight;

          if (calenderHeight + nepaliDatePicker.bottom > screenHeight) {
            if (calenderHeight < nepaliDatePicker.top) {
              calender.style.bottom = `${nepaliDatePicker.height}px`;
            }
          }
        }, 0);
      }
    }
  }, [showCalendar]);

  const handleOnChange = useCallback(
    (changedDate: string) => {
      executionDelegation(
        () => {
          setDate(changedDate);
        },
        () => {
          if (onChange) {
            onChange(returnDateValue(changedDate));
          }
        },
      );
    },
    [onChange],
  );

  const handleOnDaySelect = useCallback(
    (selectedDate: any) => {
      executionDelegation(
        () => {
          if (options.closeOnSelect) {
            setShowCalendar(false);
          }
        },
        () => {
          if (onSelect) {
            onSelect(returnDateValue(stitchDate(selectedDate)));
          }
        },
      );
    },
    [onSelect],
  );

  const datepickerEvents: NepaliDatepickerEvents = {
    change: handleOnChange,
    daySelect: handleOnDaySelect,
    todaySelect: handleOnDaySelect,
  };

  return (
    <div
      ref={nepaliDatePickerWrapper}
      className={`nepali-date-picker ${className}`}
    >
      {/* todo mark1*/}
      <CustomInputWithRefForwarded
        ref={nepaliDatePickerInput}
        value={date}
        onChange={(value: any) => {
          setDate(value);
        }}
        onClick={() => setShowCalendar((visible) => !visible)}
      />
      {/*<Input*/}
      {/*  type="text"*/}
      {/*  ref={nepaliDatePickerInput}*/}
      {/*  readOnly={true}*/}
      {/*  value={numberTrans(date)}*/}
      {/*  onClick={() => setShowCalendar((visible) => !visible)}*/}
      {/*/>*/}
      {showCalendar && date && (
        <Calender
          value={date}
          events={datepickerEvents}
          disableDateBefore={disableDateBefore}
          disableDateAfter={disableDateAfter}
        />
      )}
    </div>
  );
};

export default NepaliDatePicker;
