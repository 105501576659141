import { selectCurrentStep, selectReview, selectReviewForSelf } from ".";
import * as fromInterface from "../../../../ts";
import { mapComments } from "../helper/map-comments";

export const selectKSAPageData = (state: any): fromInterface.KSAPageDataSelector | null => {
    const currentStep = selectCurrentStep(state);
    const review = selectReview(state);
    const selfReview = selectReviewForSelf(state);

    const { ReviewInfo } = review;

    const section = review.Sections?.find((section: any) => {
        return section.Id === currentStep.sectionId
    })

    const sectionIdx = review.Sections?.findIndex((section: any) => {
        return section.Id === currentStep.sectionId
    })

    const contentIdx = 0;

    if (section) {
        const {
            PerformanceFormId,
            SectionName,
            IntroText,
            RatingScaleOptions,
            Contents
        } = section

        const KSAOptions = Contents[contentIdx]?.KSAOptions;

        const reviewDetails = KSAOptions.map(({
            KsaName,
            SectionId,
            ContentId,
            RatingScore,
            Expected,
            KSAId,
            RolloutKSADetailId
        }: any, ksaOptionIdx: number) => {

            return {
                sectionId: SectionId,
                sectionContentId: ContentId,
                RatingScore: RatingScore ?? 0,
                Ksa: {
                    Ksaid: KSAId,
                    RatingName: "",
                    RatingScore: RatingScore ?? 0,
                    Expected: Expected,
                    RolloutKSADetailId: RolloutKSADetailId,
                },
                selfMarkingValue: selfReview?.Sections?.[sectionIdx as number]?.Contents[contentIdx]?.KSAOptions[ksaOptionIdx]?.RatingScore ?? 0,

                // REQUIRED TO DISPLAY
                KsaName,
                Expected
            }
        })

        // =======================
        // DERIVE COMMENTS
        // =======================
        const { SectionId, Id: ContentId } = Contents[contentIdx];//KSAOptions[0] --check--
        let Comments: any = mapComments(SectionId, ContentId, state, ReviewInfo);
        // =======================
        // END DERIVE COMMENTS
        // =======================

        const mapped = {
            Id: null, // THIS IS REVIEW ID
            PerformanceFormId,
            PerformanceRolloutId: ReviewInfo?.PerformanceRolloutId,
            reviewDetails,
            Comments,
            signature: false,

            // REQUIRED TO DISPLAY
            SectionName,
            IntroText,
            RatingScaleOptions,
            AllowComments: Contents[contentIdx].AllowComments
        }

        return mapped
    }

    return null;
};

