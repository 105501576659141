// @ts-nocheck 
import {RegularList} from "components/common/regularList";
import React from "react";
import CoursePageListItem from "./coursePageListItem";

const CoursePage = ({coursePageQuestions}) => {
  return (
    <>
      {coursePageQuestions.length > 0 && (
        <RegularList
          items={coursePageQuestions}
          resourceName="coursePage"
          itemComponent={CoursePageListItem}
        />
      )}
    </>
  );
};

export default CoursePage;
