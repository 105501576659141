import { createAsyncThunk } from "@reduxjs/toolkit";

import { userManagementAPI } from "./api";
import { ApiClient } from "../../../network/apiClient";
import * as fromInterface from "../../../ts";

const NAMESPACE_COMMON = "user/management/prefix";
const NAMESPACE_ADDITIONAL = "additional/user/management/prefix";

export const fetchUsers = createAsyncThunk(
  NAMESPACE_COMMON.replace("prefix", "fetch/all"),
  async (params: any, { dispatch }) => {
    const payload = {
      endpoint: userManagementAPI.users,
      query: {
        ...params,
      },
      dispatch: dispatch,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.get<any>(payload);
    const { Data, Status } = response;

    if (Status) {
      return Data;
    }
  }
);

export const fetchIndividualAdditionalUser = createAsyncThunk(
  NAMESPACE_ADDITIONAL.replace("prefix", "fetch/individual"),
  async (params: any, { dispatch }) => {
    const payload = {
      endpoint: `${userManagementAPI.additonal}/${params.id}`,
      dispatch: dispatch,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.get<any>(payload);
    const { Data, Status } = response;

    if (Status) {
      return Data;
    }
  }
);

export const updateIndividualAdditionalUser = createAsyncThunk(
  NAMESPACE_ADDITIONAL.replace("prefix", "update/individual"),
  async ({ params, cb1, cb2 }: any, { dispatch }) => {
    const payload = {
      endpoint: userManagementAPI.additonal,
      dispatch: dispatch,
      body: { ...params },
      showMessage: true,
    };

    //if userId is present then update else create user
    const response: fromInterface.ResponseWrapper<any> = params.userId
      ? await ApiClient.put<any>(payload)
      : await ApiClient.post<any>(payload);
    const { Data, Status } = response;

    if (Status) {
      cb1();
      cb2();
      return Data;
    }
  }
);

export const updateUserStatus = createAsyncThunk(
  NAMESPACE_COMMON.replace("prefix", "update/individual/status"),
  async ({ params, cb }: any, { dispatch }) => {
    const payload = {
      endpoint: userManagementAPI.updateStatus,
      dispatch,
      body: { ...params },
      showMessage: true,
    };

    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.put<any>(payload);
    const { Data, Status } = response;
    if (Status) {
      cb();
      return Data;
    }
  }
);

export const deleteUser = createAsyncThunk(
  NAMESPACE_COMMON.replace("prefix", "delete/individual"),
  async ({ userId, cb }: any, { dispatch }) => {
    const param = {
      endpoint: `${userManagementAPI.users}/${userId}`,
      showMessage: true,
      dispatch,
    };
    const resposne: fromInterface.ResponseWrapper<any> =
      await ApiClient.remove<any>(param);
    const { Data, Status } = resposne;
    if (Status) {
      cb();
      return Data;
    }
  }
);

export const updateUserPassword = createAsyncThunk(
  NAMESPACE_COMMON.replace("prefix", "update/individual/password"),
  async ({ params, cb1, cb2 }: any, { dispatch }) => {
    const payload = {
      endpoint: userManagementAPI.changePassword,
      dispatch,
      body: { ...params },
      showMessage: true,
    };

    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.post<any>(payload);
    const { Data, Status } = response;
    if (Status) {
      cb1();
      cb2();
      return Data;
    }
  }
);
