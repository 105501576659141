import travelRoutes from "./travelRoutesEnum"
import initial from "./initialRoutesEnum"
import LMSRoutes from "./lmsRoutesEnum"
import reportRoutes from "./reportRoutesEnum"
import toBeSeperated from "./toBeSeperatedRoutesEnum"
import travelManagementRoutes from "./travelManagementRoutesEnum"
import appraisal from "./appraisalRoutesEnum"
import GeoFencing from "./geofencingEnum"
import auth from "./auth"

export const RouteEnum = {
  ...initial,
  ...travelManagementRoutes,
  ...travelRoutes,
  ...LMSRoutes,
  ...reportRoutes,
  ...toBeSeperated,
  ...appraisal,
  ...GeoFencing,
  ...auth,
}

export default RouteEnum
