import { InputTextProps } from './interface';
import { RigoFroalaEditorContext } from './rigo-froala-editor-context';

export const RigoFroalaEditor = (props: InputTextProps) => {
  const { children, ...rest } = props;
  return (
    <RigoFroalaEditorContext.Provider
      value={{
        ...rest,
      }}
    >
      {children}
    </RigoFroalaEditorContext.Provider>
  );
};
