import {
  APPROVAL_AUTHORITIES_LOADING,
  APPROVAL_HISTORY_LOADING,
  COUNT_TRAVEL_REQUESTS,
  EDIT_TRAVEL_REQUESTS,
  GET_APPROVAL_AUTHORITIES,
  GET_CATEGORY_ALL_EXPENSE_LIST,
  IS_DROPDOWN_VALUE_CHANGED,
  GET_HOST_AND_HOTEL,
  GET_MODE_OF_TRANSPORT,
  GET_MY_TRAVEL_REQUESTS,
  GET_OTHERS_TRAVEL_REQUESTS,
  GET_OTHERS_TRAVEL_REQUESTS_EXCEL_EXPORT,
  GET_TRAVEL_APPROVAL_FLOW_STEPS,
  GET_TRAVEL_APPROVAL_HISTORY,
  GET_TRAVEL_CATEGORY_LIST,
  GET_TRAVEL_HISTORY,
  GET_SETTLEMENT_HISTORY,
  GET_TRAVEL_REQUEST_REPORT,
  IS_LOADING,
  RELOAD_TRAVEL_REQUESTS,
  TRAVEL_EDIT_LOADING,
  TRAVEL_HISTORY_LOADING,
  CLEAR_TRAVEL_COUNT,
  UPDATE_TRAVEL_COUNT,
  UPLOAD_FILES,
  UPLOAD_FILE_STATUS,
  RESET_SELECTED_ADVANCED_AND_EXPENSE_LIST,
  CLEAR_APPROVAL_AUTHORITIES,
  GET_TRAVEL_HISTORY_EXCEL_EXPORT,
  GET_SETTLEMENT_HISTORY_EXCEL_EXPORT,
} from 'actions/types';
import isEmpty from 'isEmpty';
import environment from 'environment';
import { get } from 'src/networkService';
import axios from 'helpers/axiosinstance';
import { Arrays } from 'helpers/getTextLabel';
import RouteEnum from 'constants/routeEnum';
import { EmployeeID } from 'constants/userDetails';
import { loadingHeaders } from 'helpers/getHeaders';
import { toastMessage } from 'actions/validateAction';
import { objectToFormData } from 'object-to-formdata';
import { numberToNepaliCurrency } from 'helpers/regex';
import { ADMIN_ROLE_ID, TRAVEL_ROLE_ID } from 'constants/constants';

export async function CountRequests(dispatch) {
  await axios
    .get(`${environment.api.v1.travel.travelRequestCount}`, loadingHeaders)
    .then(response => {
      if (response.data.Status) {
        dispatch({
          type: COUNT_TRAVEL_REQUESTS,
          totalTravelRequests: response.data.Data.TotalRows,
          totalMyTravelRequests: response.data.Data.MyTotalRows,
          payload: response.data.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch(err => {
      const response = {
        data: {
          MessageType: 'Danger',
          Message: 'Failed to count travel requests.',
        },
      };
      toastMessage(dispatch, response);
    });
}

export const ClearTravelCount = () => dispatch => {
  dispatch({
    type: CLEAR_TRAVEL_COUNT,
  });
};

export const GetMyTravelRequests = data => async dispatch => {
  dispatch({
    type: IS_LOADING,
    isLoading: true,
  });
  const { pageNumber, pageSize } = data;

  await CountRequests(dispatch);

  await axios
    .get(
      `/v1/travel/my-travel-request?pageindex=${pageNumber}&pagesize=${pageSize}`,
      loadingHeaders,
    )
    .then(response => {
      if (response.data.Status) {
        dispatch({
          type: GET_MY_TRAVEL_REQUESTS,
          payload: response.data.Data,
        });
        dispatch({
          type: UPDATE_TRAVEL_COUNT,
          payload: {
            Name: 'MyTotalRows',
            Value: !isEmpty(response.data.Data)
              ? response.data.Data[0].TotalRows
              : 0,
          },
        });
      } else {
        toastMessage(dispatch, response);
      }
      dispatch({
        type: IS_LOADING,
        isLoading: false,
      });
    })
    .catch(err => {
      const response = {
        data: {
          MessageType: 'Danger',
          Message: 'Failed to get my travel requests.',
        },
      };
      toastMessage(dispatch, response);
      dispatch({
        type: IS_LOADING,
        isLoading: false,
      });
    });
};

export const UpdateApprovalAuthoritiesForEdit = payload => dispatch => {
  console.log({ payload });
  dispatch({
    type: GET_APPROVAL_AUTHORITIES,
    payload,
  });
};

export const GetApprovalAuthorities = (StatusId, empId) => async dispatch => {
  dispatch({ type: APPROVAL_AUTHORITIES_LOADING });
  var response = await get(
    `/v1/travel/approval-authorities?currentStep=${StatusId}&employeeId=${
      !isEmpty(empId) ? empId : 0
    }`,
    dispatch,
    null,
    false,
  ).catch(ex => {});
  console.log({ response });

  if (response?.AuthoritiesList) {
    let authorizerList = [];
    response.AuthoritiesList.map(authorizer => {
      authorizerList.push({
        ...authorizer,
        label: authorizer.Text,
        value: authorizer.Value,
      });
    });
    response.AuthoritiesList = authorizerList;
    dispatch({
      type: GET_APPROVAL_AUTHORITIES,
      payload: response,
    });
  } else {
    const res = {
      data: {
        MessageType: 'Danger',
        Message: 'Failed to get my approval authorities.',
      },
    };
    toastMessage(dispatch, res);
  }
};

export const GetApprovalHistory = reqId => async dispatch => {
  dispatch({ type: APPROVAL_HISTORY_LOADING });
  if (reqId === 0) {
    dispatch({
      type: GET_TRAVEL_APPROVAL_HISTORY,
      payload: null,
    });
    return false;
  }
  await axios
    .get(`v1/travel/travel-approval-history/${reqId}`)
    .then(response => {
      if (response.status === 200) {
        dispatch({
          type: GET_TRAVEL_APPROVAL_HISTORY,
          payload: response.data.Data,
        });
      } else {
        dispatch({
          type: GET_TRAVEL_APPROVAL_HISTORY,
          payload: null,
        });
        const res = {
          data: {
            MessageType: 'Danger',
            Message: 'Failed to get my approval authorities.',
          },
        };
        toastMessage(dispatch, res);
      }
    })
    .catch(err => {
      const response = {
        data: {
          MessageType: 'Danger',
          Message: 'Failed to get travel approval history.',
        },
      };
      toastMessage(dispatch, response);
      dispatch({
        type: GET_TRAVEL_APPROVAL_HISTORY,
        payload: null,
      });
    });
};

export const EditApproveTravelRequest = data => async dispatch => {
  await axios
    .put(`/v1/travel/approve-travel-request`, data)
    .then(response => {
      if (response.status === 200) {
      } else {
        const res = {
          data: {
            MessageType: 'Danger',
            Message: 'Failed to get my approval authorities.',
          },
        };
        toastMessage(dispatch, res);
      }
    })
    .catch(err => {
      const response = {
        data: {
          MessageType: 'Danger',
          Message: 'Failed to get my approval authorities.',
        },
      };
      toastMessage(dispatch, response);
      dispatch({
        type: GET_APPROVAL_AUTHORITIES,
        payload: null,
      });
    });
};

export const ClearApprovalAuthorities = () => dispatch => {
  dispatch({
    type: CLEAR_APPROVAL_AUTHORITIES,
  });
};

export const GetOthersTravelRequests = data => async dispatch => {
  dispatch({
    type: IS_LOADING,
    isLoading: true,
  });

  let {
    pageNumber,
    pageSize,
    searchEmployeeText,
    branchId,
    searchFlowSteps,
    fromDate,
    toDate,
  } = data;

  if (isEmpty(searchEmployeeText)) {
    searchEmployeeText = -1;
  }

  await CountRequests(dispatch);

  var res = await get(
    `${environment.api.v1.travel.getOthersTravelRequests}?pageindex=${pageNumber}&pagesize=${pageSize}&empname=${searchEmployeeText}&branchId=${branchId}&fromDate=${fromDate}&toDate=${toDate}&stepName=${searchFlowSteps}`,
    dispatch,
    null,
    false,
  ).catch(ex => {});

  if (res?.Status) {
    dispatch({
      type: GET_OTHERS_TRAVEL_REQUESTS,
      payload: res.Data,
    });
    dispatch({
      type: UPDATE_TRAVEL_COUNT,
      payload: {
        Name: 'TotalRows',
        Value: !isEmpty(res.Data) ? res.Data[0].TotalRows : 0,
      },
    });
  } else {
    toastMessage(dispatch, res);
  }

  dispatch({
    type: IS_LOADING,
    isLoading: false,
  });
};

export const GetTravelHistoryRequests_ExcelExport = data => async dispatch => {
  dispatch({
    type: GET_TRAVEL_HISTORY_EXCEL_EXPORT,
    payload: null,
  });
  let { searchEmployeeText, branchId, fromDate, toDate } = data;

  let pageNumber = 1;
  let pageSize = -1;
  if (isEmpty(searchEmployeeText)) {
    searchEmployeeText = -1;
  }

  await axios
    .get(
      `${environment.api.v1.travel.travelHistory}?pageindex=${pageNumber}&pagesize=${pageSize}&empname=${searchEmployeeText}&branchId=${branchId}&fromDate=${fromDate}&toDate=${toDate}`,
    )
    .then(async response => {
      if (response.data.Status) {
        let filteredData = [];
        Promise.all(
          response.data.Data.map(data => {
            filteredData.push({
              name: !isEmpty(data.Name)
                ? data.Name + ' (' + data.ICardNo + ')'
                : '',
              position: data.Branch + ' ,' + data.Desingnation,
              placeOfTravel: data.PlaceName,
              dates: data.TravelStartEndDate,
              status: !isEmpty(data.StatusName) ? data.StatusName : '',
              approvedBy: !isEmpty(data.ApprovedBy) ? data.ApprovedBy : '',
              approveOn: !isEmpty(data.ApproveOn) ? data.ApproveOn : '',
            });
          }),
        );

        dispatch({
          type: GET_TRAVEL_HISTORY_EXCEL_EXPORT,
          payload: filteredData,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch(err => {
      const response = {
        data: {
          MessageType: 'Danger',
          Message: 'Failed to get travel history to export.',
        },
      };
      toastMessage(dispatch, response);
    });
};

export const GetSettlementHistoryRequests_ExcelExport =
  data => async dispatch => {
    dispatch({
      type: GET_SETTLEMENT_HISTORY_EXCEL_EXPORT,
      payload: null,
    });
    let { searchEmployeeText, branchId, fromDate, toDate } = data;

    let pageNumber = 1;
    let pageSize = -1;
    if (isEmpty(searchEmployeeText)) {
      searchEmployeeText = -1;
    }

    await axios
      .get(
        `${
          environment.api.v1.travel.settlementHistory
        }?pageindex=${pageNumber}&pagesize=${pageSize}&employeeId=${EmployeeID()}&empname=${searchEmployeeText}&branchId=${branchId}&fromDate=${fromDate}&toDate=${toDate}&flag=${'History'}&stepName=""`,
      )
      .then(async response => {
        if (response.data.Status) {
          let filteredData = [];
          Promise.all(
            response.data.Data.map(data => {
              filteredData.push({
                name: !isEmpty(data.Name)
                  ? data.Name + ' (' + data.ICardNo + ')'
                  : '',
                position: data.Branch + ' ,' + data.Designation,
                placeOfTravel: data.PlaceName,
                dates: data.TravelStartEndDate,
                returnedOn: data?.ReturnDate,
                eligibleAmount:
                  'NRP ' + numberToNepaliCurrency(data?.AssessedEligibilityAmt),
                advance:
                  'NRP ' + numberToNepaliCurrency(data.AdvanceTakenAmount),
                claim: 'NRP ' + numberToNepaliCurrency(data.ClaimAmount),
                approvedBy: !isEmpty(data.ApprovedBy) ? data.ApprovedBy : '',
                approveOn: !isEmpty(data.ApproveOn) ? data.ApproveOn : '',
              });
            }),
          );

          dispatch({
            type: GET_SETTLEMENT_HISTORY_EXCEL_EXPORT,
            payload: filteredData,
          });
        } else {
          toastMessage(dispatch, response);
        }
      })
      .catch(err => {
        const response = {
          data: {
            MessageType: 'Danger',
            Message: 'Failed to get settlement history to export.',
          },
        };
        toastMessage(dispatch, response);
      });
  };

export const GetOthersTravelRequests_ExcelExport = data => async dispatch => {
  dispatch({
    type: GET_OTHERS_TRAVEL_REQUESTS_EXCEL_EXPORT,
    payload: null,
  });
  let { searchEmployeeText, branchId, searchFlowSteps, fromDate, toDate } =
    data;

  let pageNumber = 1;
  let pageSize = -1;
  if (isEmpty(searchEmployeeText)) {
    searchEmployeeText = -1;
  }

  await axios
    .get(
      `${environment.api.v1.travel.getOthersTravelRequests}?pageindex=${pageNumber}&pagesize=${pageSize}&empname=${searchEmployeeText}&branchId=${branchId}&fromDate=${fromDate}&toDate=${toDate}&stepName=${searchFlowSteps}`,
    )
    .then(async response => {
      if (response.data.Status) {
        let filteredData = [];
        Promise.all(
          response.data.Data.map(data => {
            filteredData.push({
              name: !isEmpty(data.Name)
                ? data.Name + ' (' + data.ICardNo + ')'
                : '',
              position: data.Branch + ' ,' + data.Desingnation,
              placeOfTravel: data.PlaceName,
              dates: data.TravelStartEndDate,
              status: !isEmpty(data.StatusName) ? data.StatusName : '',
              approvedBy: !isEmpty(data.ApprovedBy) ? data.ApprovedBy : '',
              approveOn: !isEmpty(data.ApproveOn) ? data.ApproveOn : '',
            });
          }),
        );

        dispatch({
          type: GET_OTHERS_TRAVEL_REQUESTS_EXCEL_EXPORT,
          payload: filteredData,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch(err => {
      const response = {
        data: {
          MessageType: 'Danger',
          Message: 'Failed to get travel requests to export.',
        },
      };
      toastMessage(dispatch, response);
    });
};

export const GetTravelRequestReport = id => async dispatch => {
  dispatch({
    type: GET_TRAVEL_REQUEST_REPORT,
    payload: null,
  });
  await axios
    .get(`/v1/travel/${id}/travel-request-report`)
    .then(response => {
      if (response.data.Status) {
        dispatch({
          type: GET_TRAVEL_REQUEST_REPORT,
          payload: response.data.Data,
        });
      } else {
        toastMessage(dispatch, response.data);
      }
    })
    .catch(err => {
      const response = {
        data: {
          MessageType: 'Danger',
          Message: 'Failed to get travel request report.',
        },
      };
      toastMessage(dispatch, response.data);
    });
};

export const ApproveOthersTravelRequests =
  (data, actionData) => async dispatch => {
    dispatch({
      type: RELOAD_TRAVEL_REQUESTS,
      reloadData: false,
      approvalLoading: true,
    });
    await axios
      .put(`/v1/travel/approve-travel-request/${data.requestId}`, actionData)
      .then(response => {
        if (response.data.Status) {
          dispatch({
            type: RELOAD_TRAVEL_REQUESTS,
            reloadData: true,
            approvalLoading: false,
          });
        }
        toastMessage(dispatch, response);
      })
      .catch(err => {
        const response = {
          data: {
            MessageType: 'Danger',
            Message: 'Failed to approve travel requests.',
          },
        };
        dispatch({
          type: RELOAD_TRAVEL_REQUESTS,
          reloadData: false,
          approvalLoading: false,
        });
        toastMessage(dispatch, response);
      });
  };
export const clearEditTravelRequest = () => dispatch => {
  dispatch({ type: EDIT_TRAVEL_REQUESTS, payload: {} });
};
export const EditTravelRequests = travelRequestId => async dispatch => {
  dispatch({ type: EDIT_TRAVEL_REQUESTS, payload: null });
  dispatch({ type: TRAVEL_EDIT_LOADING, payload: true });
  let response = await axios
    .get(`/v1/travel/travel-information/${travelRequestId}`)
    .catch(err => {
      dispatch({ type: TRAVEL_EDIT_LOADING, payload: false });
      const response = {
        data: {
          MessageType: 'Danger',
          Message: 'Failed to get travel requests.',
        },
      };
      toastMessage(dispatch, response);
    });
  let data = null;
  if (response.status === 200) {
    dispatch({
      type: EDIT_TRAVEL_REQUESTS,
      payload: response.data,
    });
    data = response.data;
  } else {
    toastMessage(dispatch, response);
  }
  dispatch({ type: TRAVEL_EDIT_LOADING, payload: false });

  return data;

  // await axios
  //   .get(`/v1/travel/travel-information/${travelRequestId}`)
  //   .then((response) => {
  //     if (response.status === 200) {
  //       dispatch({
  //         type: EDIT_TRAVEL_REQUESTS,
  //         payload: response.data,
  //       });
  //     } else {
  //       toastMessage(dispatch, response);
  //     }
  //     dispatch({type: TRAVEL_EDIT_LOADING, payload: false});
  //   })
  //   .catch((err) => {
  //     dispatch({type: TRAVEL_EDIT_LOADING, payload: false});
  //     const response = {
  //       data: {
  //         MessageType: "Danger",
  //         Message: "Failed to get travel requests.",
  //       },
  //     };
  //     toastMessage(dispatch, response);
  //   });
};

export const GetTravelHostAndHotel = () => dispatch => {
  axios
    .get(`/v1/travel/host-and-hotel`)
    .then(response => {
      dispatch({
        type: GET_HOST_AND_HOTEL,
        payload: response.data,
      });
    })
    .catch(err => {
      const response = {
        data: {
          MessageType: 'Danger',
          Message: 'Failed to get travel host and hotel.',
        },
      };
      toastMessage(dispatch, response);
    });
};

export const GetTravelModeOfTransport = () => dispatch => {
  axios
    .get(`/v1/travel/mode-of-transport`)
    .then(response => {
      dispatch({
        type: GET_MODE_OF_TRANSPORT,
        payload: Arrays(response.data),
      });
    })
    .catch(err => {
      const response = {
        data: {
          MessageType: 'Danger',
          Message: 'Failed to get travel mode of transport.',
        },
      };
      toastMessage(dispatch, response);
    });
};

export const GetTravelCategoryList = travelType => dispatch => {
  axios
    .get(`/v1/travel/travel-categories/list?travelType=${travelType}`)
    .then(response => {
      dispatch({
        type: GET_TRAVEL_CATEGORY_LIST,
        payload: response.data,
      });
    })
    .catch(err => {
      const response = {
        data: {
          MessageType: 'Danger',
          Message: 'Failed to get travel category list.',
        },
      };
      toastMessage(dispatch, response);
    });
};

export const GetAdvanceAndExpensesList = data => async dispatch => {
  const {
    categoryId,
    overAllDestinationFromDate,
    overAllDestinationToDate,
    selectedEmployeeId,
    totalDays,
    totalNights,
  } = data;

  await axios
    .get(
      `/v1/travel/category-all-expense?categoryId=${categoryId}&employeeId=${selectedEmployeeId}&startdate=${overAllDestinationFromDate}&enddate=${overAllDestinationToDate}&totalDays=${totalDays}&totalNights=${totalNights}`,
    )
    .then(response => {
      dispatch({
        type: GET_CATEGORY_ALL_EXPENSE_LIST,
        payload: response.data,
      });
    })
    .catch(err => {
      const response = {
        data: {
          MessageType: 'Danger',
          Message: 'Failed to get category wise advance and expenses.',
        },
      };
      toastMessage(dispatch, response);
    });
};

export const GetSavedAdvanceAndExpensesList = data => async dispatch => {
  const { requestId, totalDays, totalNights } = data;

  await axios
    .get(
      `/v1/travel/allowance-expense-by-Id?requestId=${requestId}&days=${totalDays}&nights=${totalNights}`,
    )
    .then(response => {
      if (!isEmpty(response.data)) {
        let data = response.data.map(x => {
          return {
            allowance_id: x.AllowanceRef_Id,
            allowance_name: x.AllowanceName,
            units: x.Units,
            rate: x.Rate,
            amount: x.Amount,
            isbillrequired: x.IsBillRequired,
            calculationmethod: x.CalculationMethod,
          };
        });
        dispatch({
          type: GET_CATEGORY_ALL_EXPENSE_LIST,
          payload: data,
        });
      }
    })
    .catch(err => {
      const response = {
        data: {
          MessageType: 'Danger',
          Message: 'Failed to get category wise advance and expenses.',
        },
      };
      toastMessage(dispatch, response);
    });
};

export const ClearAdvanceAndExpensesList = () => dispatch => {
  dispatch({
    type: GET_CATEGORY_ALL_EXPENSE_LIST,
    payload: [],
  });
};

export const UploadFiles = data => dispatch => {
  dispatch({
    type: UPLOAD_FILE_STATUS,
    isFileUploadSuccess: false,
  });
  const { fileList, folderNameToSaveFile = '' } = data;

  let fd = new FormData();
  // fileList.map((file, index) => {
  //   fd.append("files[]", file);
  // });
  fileList.map((file, i) => {
    fd.append(file.name, file);
  });

  axios
    .post(
      `/v1/common/shared/UploadFiles?folderName=${folderNameToSaveFile}`,
      fd,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      },
    )
    .then(response => {
      if (response.data.Status) {
        dispatch({
          type: UPLOAD_FILES,
          payload: response.data.Data,
          isFileUploadSuccess: true,
        });
      } else {
        dispatch({
          type: UPLOAD_FILE_STATUS,
          isFileUploadSuccess: false,
        });
      }
      toastMessage(dispatch, response.data);
    })
    .catch(err => {
      dispatch({
        type: UPLOAD_FILE_STATUS,
        isFileUploadSuccess: false,
      });
      const response = {
        data: {
          MessageType: 'Danger',
          Message: 'Failed to upload files.',
        },
      };
      toastMessage(dispatch, response);
    });
};

export const DeleteTravelRequests = data => dispatch => {
  dispatch({ type: RELOAD_TRAVEL_REQUESTS, reloadData: false });
  axios
    .delete(`/v1/travel/delete-travel-request/${data.requestId}`)
    .then(response => {
      if (response.data.Status) {
        dispatch({
          type: RELOAD_TRAVEL_REQUESTS,
          reloadData: true,
        });
      }
      toastMessage(dispatch, response);
    })
    .catch(err => {
      const response = {
        data: {
          MessageType: 'Danger',
          Message: 'Failed to delete travel request.',
        },
      };
      toastMessage(dispatch, response);
    });
};

export const SaveAllTravelRequest = data => dispatch => {
  let fd = new FormData();
  data.documentsState.fileList.map((x, i) => {
    fd.append(`Files`, x);
  });

  const object = {
    TravelInformationDto: data.TravelInformationDto,
    TravelInformationDestinationDto: data.TravelInformationDestinationDto,
    TravelInformationAllowanceExpenseDetailsDto:
      data.TravelInforamtionAllowanceExpenseDetailsDto,
    TravelInformationDocumentsDto: data.TravelInformationDocumentsDto,
    TravelInformationGroupDto: data.TravelInformationGroupDto,
    TravelInformationApprovalHistoryDto: data.TravelApprovalFlow,
    Attachment: data.TravelInformationDocumentsDto.map(x => {
      return {
        AttachmentId: x.AttachmentId,
        UserFileName: x.OriginalName,
        FileLocation: x.FileLocation,
        Size: x.Size,
      };
    }),
  };
  const options = {
    indices: true,
    nullsAsUndefineds: false,
    booleansAsIntegers: false,
  };
  const redirectionRoutePath =
    data.props &&
    data.props.user &&
    data.props.user.RoleId &&
    (data.props.user.RoleId === ADMIN_ROLE_ID ||
      data.props.user.RoleId === TRAVEL_ROLE_ID)
      ? RouteEnum.TeamTravelRequestPath
      : RouteEnum.MyTravelRequestPath;

  const formData = objectToFormData(object, options);
  data.documentsState.fileList.map((x, i) => {
    formData.append(`Files`, x);
  });
  dispatch({ type: TRAVEL_EDIT_LOADING, payload: true });
  data.TravelInformationDto.RequestId === 0
    ? axios
        .post(`/v1/travel/save-travel-information`, formData, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        })
        .then(response => {
          if (response.data.Status) {
            data.props &&
              data.props.history &&
              data.props.history.push(redirectionRoutePath);
            toastMessage(dispatch, response);
          } else {
            toastMessage(dispatch, response);
          }
          dispatch({ type: TRAVEL_EDIT_LOADING, payload: false });
        })
        .catch(err => {
          dispatch({ type: TRAVEL_EDIT_LOADING, payload: false });
          const response = {
            data: {
              MessageType: 'Danger',
              Message: 'Failed to save travel request.',
            },
          };
          toastMessage(dispatch, response);
        })
    : axios
        .put(`/v1/travel/update-travel-information`, formData, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        })
        .then(response => {
          if (response.data.Status) {
            data.props &&
              data.props.history &&
              data.props.history.push(RouteEnum.MyTravelRequestPath);
            toastMessage(dispatch, response);
          } else {
            toastMessage(dispatch, response);
          }
          dispatch({ type: TRAVEL_EDIT_LOADING, payload: false });
        })
        .catch(err => {
          dispatch({ type: TRAVEL_EDIT_LOADING, payload: false });
          const response = {
            data: {
              MessageType: 'Danger',
              Message: 'Failed to save travel request.',
            },
          };
          toastMessage(dispatch, response);
        });
};

export const GetApprovalFlowSteps = flowType => async dispatch => {
  dispatch({ type: RELOAD_TRAVEL_REQUESTS, reloadData: false });
  await axios
    .get(`/v1/travel/approval-flow-steps/${flowType}`, loadingHeaders)
    .then(response => {
      dispatch({
        type: GET_TRAVEL_APPROVAL_FLOW_STEPS,
        payload: response.data.map(x => {
          return {
            value: x.StepName,
            label: x.StepName,
          };
        }),
      });
    })
    .catch(err => {
      const response = {
        data: {
          MessageType: 'Danger',
          Message: 'Failed to get flow types.',
        },
      };
      toastMessage(dispatch, response);
    });
};
export const RejectTravelRequest = data => async dispatch => {
  dispatch({ type: TRAVEL_EDIT_LOADING, payload: true });
  await axios
    .put(
      `/v1/travel/reject-travel-request/${data.RequestId}`,
      data.FlowHistoryDto,
    )
    .then(response => {
      if (response.data.Status) {
        if (data.type && data.type === 'directReject') {
          data.props.getTravelRequest();
          data.props.closeModal(false);
        } else
          data.props &&
            data.props.history &&
            data.props.history.push(RouteEnum.TeamTravelRequestPath);
        toastMessage(dispatch, response);
      } else {
        toastMessage(dispatch, response);
      }
      dispatch({ type: TRAVEL_EDIT_LOADING, payload: false });
    })
    .catch(err => {
      dispatch({ type: TRAVEL_EDIT_LOADING, payload: false });
      const response = {
        data: {
          MessageType: 'Danger',
          Message: 'Failed to reject travel request.',
        },
      };
      toastMessage(dispatch, response);
    });
};

//#region travel history
export const GetSettlementHistory = data => async dispatch => {
  dispatch({
    type: TRAVEL_HISTORY_LOADING,
  });

  await CountRequests(dispatch);

  await axios
    .get(
      `/v1/travel/settlements/employee/history?pageindex=${
        data.PageIndex
      }&pagesize=${data.PageSize}&employeeId=${EmployeeID()}&empName=${
        data.SearchEmployeeText
      }&branchId=${data.BranchId}&fromDate=${data.FromDate}&toDate=${
        data.ToDate
      }&flag=${'History'}&stepName=${data.StepName}`,
      loadingHeaders,
    )
    .then(response => {
      dispatch({
        type: GET_SETTLEMENT_HISTORY,
        payload: response.data.Data,
        page: {
          index: data.PageIndex,
          total: !isEmpty(response.data.Data)
            ? response.data.Data[0].TotalRows
            : 0,
        },
      });
      dispatch({
        type: UPDATE_TRAVEL_COUNT,
        payload: {
          Name: 'SettlementHistoryRows',
          Value: !isEmpty(response.data.Data)
            ? response.data.Data[0].TotalRows
            : 0,
        },
      });
    })
    .catch(err => {
      const response = {
        data: {
          MessageType: 'Danger',
          Message: 'Failed to get travel history.',
        },
      };
      toastMessage(dispatch, response);
    });
};
//#endregion

//#region travel team history
export const GetTravelHistory = data => async dispatch => {
  dispatch({
    type: IS_LOADING,
    isLoading: true,
  });

  let {
    api,
    pageNumber,
    pageSize,
    searchEmployeeText,
    branchId,
    fromDate,
    toDate,
    travelCountName,
  } = data;

  if (isEmpty(searchEmployeeText)) {
    searchEmployeeText = -1;
  }

  await CountRequests(dispatch);

  var res = await get(
    `${api}?pageindex=${pageNumber}&pagesize=${pageSize}&empname=${searchEmployeeText}&branchId=${branchId}&fromDate=${fromDate}&toDate=${toDate}`,
    dispatch,
    null,
    false,
  ).catch(ex => {});

  if (res?.Status) {
    dispatch({
      type: GET_TRAVEL_HISTORY,
      payload: res.Data,
    });
    dispatch({
      type: UPDATE_TRAVEL_COUNT,
      payload: {
        Name: travelCountName,
        Value: !isEmpty(res.Data) ? res.Data[0].TotalRows : 0,
      },
    });
  } else {
    toastMessage(dispatch, res);
  }

  dispatch({
    type: IS_LOADING,
    isLoading: false,
  });
};
//#endregion

export const IsDropdownValueChanged = payload => dispatch => {
  dispatch({ type: IS_DROPDOWN_VALUE_CHANGED, payload });
};

export const ResetSelectedAdvanceAndExpenseList = () => dispatch => {
  dispatch({ type: RESET_SELECTED_ADVANCED_AND_EXPENSE_LIST });
};
