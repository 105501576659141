import { createSlice } from "@reduxjs/toolkit";
import * as fromInterface from "../../../ts";
import * as fromConstants from "./constants";

const statusList: any[] = [
    { label: "All", value: "" },
    { label: "Incomplete Form", value: "Incomplete Form" },
    { label: "Not Used", value: "Not Used" },
    { label: "Rolled Out", value: "Rolled Out" },
]

export interface GlobalFilterState {
    statuses: {
        [fromConstants.STATUS_REVIEW_CYCLE]: fromInterface.loadingType
        [fromConstants.STATUS_STATUS]: fromInterface.loadingType
        applyFilterLoading: fromInterface.loadingType,
    },
    data: {
        [fromConstants.DATA_REVIEW_CYCLE]: any[],
        [fromConstants.DATA_STATUS]: any[]
    },
    filters: {
        [fromConstants.SELECTED_REVIEW_CYCLE]: any | null;
        [fromConstants.SELECTED_STATUS]: any | null;
        [fromConstants.SELECTED_FORM_NAME]: string;
    }
}

const initialState: GlobalFilterState = {
    statuses: {
        [fromConstants.STATUS_REVIEW_CYCLE]: fromInterface.STATUS.LOADING,
        [fromConstants.STATUS_STATUS]: fromInterface.STATUS.LOADING,
        applyFilterLoading: fromInterface.STATUS.IDLE
    },
    data: {
        [fromConstants.DATA_REVIEW_CYCLE]: [],
        [fromConstants.DATA_STATUS]: statusList
    },
    filters: {
        [fromConstants.SELECTED_REVIEW_CYCLE]: null,
        [fromConstants.SELECTED_STATUS]: null,
        [fromConstants.SELECTED_FORM_NAME]: "",
    }
};

const globalFilterSlice = createSlice({
    name: "global filter slice",
    initialState,
    reducers: {
        fetchReviewCycleRequest: (state) => {
            state.statuses.reviewCycleStatus = fromInterface.STATUS.LOADING;
        },
        fetchReviewCycleSuccess: (state, action) => {
            state.data.reviewCycles = action.payload;
            state.statuses.reviewCycleStatus = fromInterface.STATUS.LOADING;
        },
        updateFilters: (state, action) => {
            state.filters = { ...state.filters, ...action.payload };
        },
        clear: (state) => {
            state = initialState
        },
    },
});

export const {
    fetchReviewCycleRequest,
    fetchReviewCycleSuccess,
    updateFilters,
    clear,
} = globalFilterSlice.actions

export default globalFilterSlice.reducer;