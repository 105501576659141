import { ApiClient } from "../../../network/apiClient";
import * as fromInterface from "../../../ts";
import {
  ADDITIONAL_SCORE_LIST, ADDITIONAL_SCORE_UPLOAD_EXCEL, ADDITIONAL_SCORE_SAVE_BULK,
} from "./api";
import * as fromSlice from "./slice";
// STORES
import * as fromAdditionalScoreFilterStore from "../additional-score-filter";
import * as fromReviewCycleDropdownStore from "../review-cycle-dropdown";


export const fetchAdditionalScore = (isExport: boolean = false): any => async (dispatch: any, getState: any) => {

  const state = getState()
  const currentFilterState = fromAdditionalScoreFilterStore.selectCurrentAdditionalScoreFilter(state);
  const body = {
    reviewCycleId: currentFilterState?.reviewCycleId?.value,
    formId: currentFilterState?.formId?.value,
    branchId: currentFilterState?.branchId?.value,
    departmentId: currentFilterState?.departmentId?.value,
    isExport
  }
  if (isExport) {
    await ApiClient.exportFile_post<any>({
      endpoint: ADDITIONAL_SCORE_LIST,
      body,
      dispatch: dispatch,
      showLoader: true,
      convertXlsx: true
    })
  }
  else {
    dispatch(fromSlice.fetchRequest())
    const response: fromInterface.ResponseWrapper<any> = await ApiClient.post<any>({
      endpoint: ADDITIONAL_SCORE_LIST,
      body,
      showLoader: true,
      dispatch: dispatch
    })
    const { Data, Status } = response

    if (Status) {
      dispatch(fromSlice.fetchSuccess(Data))
    }
  }
}

export const fetchInitialAdditionalScore = (reviewCycleId: any): any => async (dispatch: any, getState: any) => {

  await dispatch(fromReviewCycleDropdownStore.fetchReviewCycleDropDown())
  const state = getState()
  const dropdownItems = fromReviewCycleDropdownStore.selectReviewCycleDropdownItems(state)
  const currentFilterState = fromAdditionalScoreFilterStore.selectCurrentAdditionalScoreFilter(state);
  const reviewCYcleDropdownItemFound = dropdownItems.find((item: any) => item.value === parseInt(reviewCycleId))
  dispatch(fromAdditionalScoreFilterStore.udpateAdditionalScoreFilter({ ...currentFilterState, reviewCycleId: reviewCYcleDropdownItemFound }))
  await dispatch(fetchAdditionalScore(false))
}
export const uploadAdditionalScoreExcelFile = (fileData: any) => async (dispatch: any, getState: any) => {
  const formData = new FormData();
  formData.append("file", fileData);

  const param = {
    endpoint: ADDITIONAL_SCORE_UPLOAD_EXCEL,
    body: formData,
    dispatch: dispatch,
    showLoader: true
  };

  const response: fromInterface.ResponseWrapper<any> =
    await ApiClient.post<any>(param, true);

  const { Data, Status } = response;
  if (Status) {
    dispatch(fetchAdditionalScore())
  }

}

export const saveScores = (obj: any, cb?: any) => async (dispatch: any, getState: any) => {


  const param = {
    endpoint: ADDITIONAL_SCORE_SAVE_BULK,
    body: obj,
    dispatch: dispatch,
    showLoader: true
  };

  const response: fromInterface.ResponseWrapper<any> =
    await ApiClient.post<any>(param);

  const { Data, Status } = response;

  if (Status) {
    cb?.(Status)
    dispatch(fetchAdditionalScore())
  }

}

export const clearAdditionalScoreInitialData = (filter?: any): any => async (dispatch: any) => {
  dispatch(clearAdditionalScore())
  dispatch(fromAdditionalScoreFilterStore.clearAdditionalScoreFilter())
}
export const clearAdditionalScore = (): any => async (dispatch: any, getState: any) => {
  dispatch(fromSlice.clear())
}
