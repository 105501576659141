import {
  GET_HR_DASHBOARD,
  GET_DASHBOARD_MENUS,
  ADD_DASHBOARD_MENUS,
  REMOVE_DASHBOARD_MENUS,
  GET_DASHBOARD_BLOCK,
} from "actions/types";
import types from "constants/types";

const initialState = {
  hrDashboard: [],
  dashboardMenu: [],
  dashboardBlock: [],
  advanceTravelCategoryList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_HR_DASHBOARD:
      return {
        ...state,
        hrDashboard: action.payload,
      };
    case GET_DASHBOARD_MENUS:
      return {
        ...state,
        dashboardMenu: action.payload,
      };
    case ADD_DASHBOARD_MENUS:
      return {
        ...state,
        // dashboardMenu:action.payload
        dashboardMenu: [...state.dashboardMenu, action.payload],
      };
    case REMOVE_DASHBOARD_MENUS:
      return {
        ...state,
        dashboardMenu: state.dashboardMenu.filter((item) => item.Id !== action.id),
      };
    case GET_DASHBOARD_BLOCK:
      return {
        ...state,
        dashboardBlock: action.payload,
      };
    case types.GET_ADVANCE_TRAVEL_CATEGORY_LIST:
      return {
        ...state,
        advanceTravelCategoryList: action.payload,
      };
    case types.GET_ADVANCE_TRAVEL_REQUEST:
      return {
        ...state,
        advancetravelRequest: action.payload,
      };
    case types.EXPORT_ADVANCE_TRAVEL_REQUEST:
      return {
        ...state,
        advanceExportList: action.payload,
      };

    default:
      return state;
  }
}
