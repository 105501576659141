// @ts-nocheck 
import { getFullURL } from "helpers/fullURL";
import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BlankCourseCover from "src/assets/images/blank-course-cover.png";
interface IProps {
  src: any;
  alt?: any;
  disableBaseUrl?: boolean;
}

const ImageRenderer = (props: IProps) => {
  const { src, alt, disableBaseUrl } = props;
  const [imgSrc, setImgSrc] = useState(src);
  var baseUrl = getFullURL(useSelector, "");
  useEffect(() => {
    if (disableBaseUrl) setImgSrc(src);
    else setImgSrc(baseUrl + src);
  }, [src]);
  return (
    <>
      <img
        src={imgSrc}
        alt={alt}
        onError={(tar: any) => {
          console.log({tar})
          tar.onError = null;
          setImgSrc(BlankCourseCover);
        }}
      />
    </>
  );
};
export default memo(ImageRenderer);
