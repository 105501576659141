import React from "react";

import { flexRender } from "@tanstack/react-table";

//INTERFACE
import { ITableChildrenComponentProps, IAdditionalProps } from "../ts";
import { FONT_SIZE, PADDING_SIZE } from "../constants";

const TableBodyContent = (props: ITableChildrenComponentProps) => {
  const { table, filteredColumns, scrollRight } = props;

  return (
    <>
      <tbody>
        {table.getRowModel().rows.map(row => (
          <tr key={row.id}>
            {row.getVisibleCells().map(cell => {
              let additionalProps: IAdditionalProps = {
                isSticky: false,
                isLastSticky: false,
                textAlign: "left",
              };

              for (const column of filteredColumns) {
                if (column.accessorKey === cell.column.id) {
                  if (column.textAlign)
                    additionalProps.textAlign = column.textAlign;
                  if (column.isSticky) {
                    additionalProps = { ...additionalProps, isSticky: true };
                    if (column.isLastSticky) {
                      additionalProps = {
                        ...additionalProps,
                        isLastSticky: true,
                      };
                    }
                  }
                  break;
                }
              }

              return (
                <td
                  key={cell.id}
                  className={`${
                    additionalProps.isSticky
                      ? scrollRight > 0
                        ? "sticky scrolled"
                        : "sticky"
                      : ""
                  } ${additionalProps.isLastSticky ? "last" : ""}`}
                  {...{
                    style: {
                      padding: PADDING_SIZE.X[props.tableSize || "md"],
                      fontSize: FONT_SIZE[props.tableSize || "md"],
                      textAlign: additionalProps.textAlign,
                      width: cell.column.getSize(),
                      minWidth: cell.column.getSize(),
                      maxWidth: cell.column.getSize(),
                      position: additionalProps.isSticky
                        ? "sticky"
                        : "relative",
                      left: additionalProps.isSticky
                        ? props.stickyWidth[
                            filteredColumns.findIndex(
                              (column: typeof filteredColumns[number]) =>
                                column.accessorKey === cell.column.id,
                            )
                          ]
                        : "0",
                    },
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </>
  );
};

export default TableBodyContent;
