// @ts-nocheck
import React, { useState, useRef, useEffect, useMemo } from 'react'
import AccessValidityForm from './accessValidityForm'
import Confirmation from './confirmation'
import SelectEmployee from './selectEmployees'
import classnames from 'classnames'
import { useDispatch } from 'react-redux'
import Modal from 'components/modal'
import Button from 'components/button'
import isEmpty from 'helpers/isEmpty'
import { assignCourse } from 'scenes/lms/helpers/action'
const initialState = {
  active: 0,
  selectedEmployees: {},
  startDate: null,
  endDate: null,
  validDays: '',
  filters: {
    pageIndex: 0,
    pageSize: 10,
    employeeName: '',
    branchId: -1,
    departmentId: -1,
  },
}
function AssignCourse(props) {
  const { open, onModalClose, courses, onSuccessSubmitting } = props

  const [state, setState] = useState(initialState)

  const dispatch = useDispatch()
  const onBackClick = () => setActiveIndexAs(state.active - 1)
  const onNextClick = () => {
    const { active } = state
    if (active < 2) setActiveIndexAs(active + 1)
    else if (active == 2) submitAssignCourses()
  }
  const setActiveIndexAs = (index) => setState({ ...state, active: index })

  const handleChange = (name, value) => {
    let nextState = { ...state }
    if (name === 'selectedEmployees') {
      let selEmps = { ...nextState.selectedEmployees }
      selEmps[state.filters.pageIndex] = value
      nextState.selectedEmployees = selEmps
    } else {
      nextState[name] = value
    }
    setState(nextState)
  }

  const handleFilterChange = (name, value) =>
    setState({ ...state, [name]: value, selectedEmployees: {} })

  const isDisabled = () => {
    const { active } = state
    let isValid = false
    if (active === 0) isValid = isValidEmployeeTab()
    else if (active === 1) isValid = isValidAccessValidityTab()
    else
      isValid =
        isValidAccessValidityTab() &&
        isValidEmployeeTab() &&
        courses &&
        courses.length > 0

    return !isValid
  }
  const isValidEmployeeTab = () => {
    const { active, selectedEmployees } = state
    for (var k in selectedEmployees) {
      let emps = selectedEmployees[k] || []
      if (emps.length > 0) {
        return true
      }
    }
    return false
  }

  const isValidAccessValidityTab = () => {
    const { startDate, endDate, validDays } = state
    return !isEmpty(startDate) && !isEmpty(endDate) && !isEmpty(validDays)
  }

  const handleModalClose = () => {
    setState((prev) => {
      onModalClose && onModalClose()
      return { ...initialState }
    })
  }
  const getSelectedEmployees = () => {
    const { selectedEmployees } = state
    let list = []
    for (var key in selectedEmployees) {
      var emps = selectedEmployees[key]
      list = [...list, ...emps]
    }

    return list
  }
  const submitAssignCourses = async () => {
    var courseIds = props.courses?.map((x) => x.Id)
    var employeeIds = getSelectedEmployees().map((x) => x.EmployeeId)
    const { startDate, endDate, validDays } = state
    const param = { courseIds, employeeIds, startDate, endDate, validDays }
    const res: any = await dispatch(assignCourse(param))
    if (res) {
      handleModalClose()
      onSuccessSubmitting()
    }
  }
  

  return (
    <Modal
      open={open}
      onModalClose={handleModalClose}
      title="Assign Employee"
      width="1000px"
    >
      <div className="course-assignment">
        <div className="course-assignment__body">
          <div className="common">
            <div className="common-tabs">
              <div
                onClick={() => setActiveIndexAs(0)}
                className={classnames('common-tab', {
                  active: state.active === 0,
                })}
              >
                <span>1. Select Employees</span>
              </div>
              <div
                onClick={() => setActiveIndexAs(1)}
                className={classnames('common-tab', {
                  active: state.active === 1,
                })}
              >
                <span>2. Access Validity</span>
              </div>
              <div
                onClick={() => setActiveIndexAs(2)}
                className={classnames('common-tab', {
                  active: state.active === 2,
                })}
              >
                <span>3. Confirmation</span>
              </div>
            </div>
            <div className="common-tabs__body">
             

              {state.active === 0 && (
                <SelectEmployee
                  {...props}
                  name="selectedEmployees"
                  onChange={handleChange}
                  onFilterChange={handleFilterChange}
                  filters={state.filters}
                  value={state.selectedEmployees}
                />
              )}
              {state.active === 1 && (
                <AccessValidityForm
                  {...props}
                  onChange={handleChange}
                  value={state}
                  setValue={(val) => setState({ ...state, ...val })}
                />
              )}
              {state.active === 2 && (
                <Confirmation
                  {...props}
                  data={state}
                  getSelectedEmployees={getSelectedEmployees}
                />
              )}
            </div>
          </div>
        </div>
        <div className="course-assignment__footer">
          <Button title="Cancel" bg="white" onClick={handleModalClose} />
          {state.active !== 0 && (
            <Button title="Back" bg="white" onClick={() => onBackClick()} />
          )}
          <Button
            isDisabled={isDisabled()}
            title={state.active === 2 ? 'Assign' : 'Next'}
            buttonClass="btn btn-primary"
            onClick={() => onNextClick()}
          />
        </div>
      </div>
    </Modal>
  )
}

export default AssignCourse
