import { createAsyncThunk } from "@reduxjs/toolkit";

import { commonAPI, userGroupAPI } from "./api";
import { camelCaseKeys } from "../../../helper";
import { ApiClient } from "../../../network/apiClient";
import * as fromInterface from "../../../ts";

const NAMESPACE = "user/groups/prefix";
const NAMESPACE_COMMON = "common/prefix";

export const fetchUserGroups = createAsyncThunk(
  NAMESPACE.replace("prefix", "fetch/all"),
  async (params: any, { dispatch }) => {
    const param = {
      endpoint: userGroupAPI.groups,
      query: {
        ...params,
      },
      dispatch: dispatch,
    };

    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.get<any>(param);
    const { Data, Status } = response;
    if (Status) {
      return Data;
    }
  }
);

export const updateUserGroup = createAsyncThunk(
  NAMESPACE.replace("prefix", "update/all"),
  async ({ param: payload, isUpdate, cb1, cb2 }: any, { dispatch }) => {
    const param = {
      endpoint: userGroupAPI.groups,
      body: {
        ...payload,
      },
      dispatch: dispatch,
      showMessage: true,
    };
    const response: fromInterface.ResponseWrapper<any> = isUpdate
      ? await ApiClient.put<any>(param)
      : await ApiClient.post<any>(param);
    const { Data, Status } = response;
    if (Status) {
      cb1();
      cb2();
      return Data;
    }
  }
);

export const updateUserGroupStatus = createAsyncThunk(
  NAMESPACE.replace("prefix", "update/individual/status"),
  async ({ userGroupId, param: payload }: any, { dispatch }) => {
    const param = {
      endpoint: userGroupAPI.groupStatus.replace("userGroupId", userGroupId),
      body: { ...payload },
      showMessage: true,
      dispatch,
    };

    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.put<any>(param);
    const { Data, Status } = response;
    if (Status) {
      return Data;
    }
    return null;
  }
);

export const deleteIndividualUserGroup = createAsyncThunk(
  NAMESPACE.replace("prefix", "delete/individual/userGroup"),
  async ({ id, cb }: any, { dispatch }) => {
    const param = {
      endpoint: `${userGroupAPI.groups}/${id}`,
      showMessage: true,
      dispatch,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.remove<any>(param);
    const { Data, Status } = response;
    if (Status) {
      cb();

      return Data;
    }
    return null;
  }
);

export const fetchIndividualUserGroup = createAsyncThunk(
  NAMESPACE.replace("prefix", "fetch/individual/userGroup"),
  async (id: any, { dispatch }) => {
    const param = {
      endpoint: `${userGroupAPI.groups}/${id}`,
      dispatch,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.get<any>(param);
    const { Data, Status } = response;
    if (Status) {
      const resDataAfterCamelCase = camelCaseKeys(Data);
      return resDataAfterCamelCase;
    }
    return null;
  }
);

export const fetchCommonBranch = createAsyncThunk(
  NAMESPACE_COMMON.replace("prefix", "branch"),
  async (params, { dispatch }) => {
    const param = {
      endpoint: commonAPI.branches,
      dispatch,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.get<any>(param);
    const { Data, Status } = response;
    if (Status) {
      return Data;
    }
    return null;
  }
);

export const fetchCommonDepartment = createAsyncThunk(
  NAMESPACE_COMMON.replace("prefix", "department"),
  async (params, { dispatch }) => {
    const param = {
      endpoint: commonAPI.departments,
      dispatch,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.get<any>(param);
    const { Data, Status } = response;
    if (Status) {
      return Data;
    }
    return null;
  }
);

export const fetchCommonEmployeeStatus = createAsyncThunk(
  NAMESPACE_COMMON.replace("prefix", "employee/status"),
  async (params, { dispatch }) => {
    const param = {
      endpoint: commonAPI.status,
      dispatch,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.get<any>(param);
    const { Data, Status } = response;
    if (Status) {
      return Data;
    }
    return null;
  }
);

export const fetchCommonEmployeeLevel = createAsyncThunk(
  NAMESPACE_COMMON.replace("prefix", "employee/level"),
  async (params, { dispatch }) => {
    const param = {
      endpoint: commonAPI.level,
      dispatch,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.get<any>(param);
    const { Data, Status } = response;
    if (Status) {
      return Data;
    }
    return null;
  }
);


export const fetchUserGroupOptions = createAsyncThunk(
  NAMESPACE_COMMON.replace("prefix", "fetch/users-group/dropdown"),
  async (params, { dispatch }) => {
    const param = {
      endpoint: userGroupAPI.userGroupDropDown,
      dispatch,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.get<any>(param);
    const { Data, Status } = response;

    if (Status) {
      return Data;
    }
  }
);

export const fetchGroupMembersById = createAsyncThunk(
  NAMESPACE_COMMON.replace("prefix", "fetch/individual/users-group"),
  async (id: number, { dispatch }) => {
    const param = {
      endpoint: userGroupAPI.individualGroupMembers.replace(
        "userGroupId",
        id.toString()
      ),
      dispatch,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.get<any>(param);
    const { Data, Status } = response;

    if (Status) {
      return Data;
    }
  }
);

export const updateMembers = createAsyncThunk(
  NAMESPACE.replace("prefix", "update/members-count"),
  async ({ param: payload }: any, { dispatch }) => {
    const param = {
      endpoint: userGroupAPI.updateGroupMembers,
      body: {
        ...payload,
      },
      dispatch: dispatch,
      showMessage: true,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.post<any>(param);
    const { Data, Status } = response;
    if (Status) {
      console.clear();
      console.log("Test", Data); //temp for QA testing
      return Data;
    }
  }
);