import { createSlice } from "@reduxjs/toolkit";
import * as fromInterface from "../../../ts";

export interface GoalDatabaseState {
    items: any[] | null,
    status: fromInterface.loadingType;
    loaded: boolean;
}

const initialState: GoalDatabaseState = {
    items: null,
    status: fromInterface.STATUS.LOADING,
    loaded: false,
};

const goalDatabaseSlice = createSlice({
    name: "goal database",
    initialState,
    reducers: {
        fetchRequest: (state) => {
            state.status = fromInterface.STATUS.LOADING;
        },
        fetchSuccess: (state, action) => {
            const payload = action.payload

            state.items = payload;
            state.status = fromInterface.STATUS.IDLE;
            state.loaded = true

        },
        clear: (state) => {
            state = initialState
        },
    },
});

export const {
    fetchRequest,
    fetchSuccess,
    clear,
} = goalDatabaseSlice.actions

export default goalDatabaseSlice.reducer;