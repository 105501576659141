import React from "react";

//CHAKRA
import { Center, Spinner } from "@chakra-ui/react";

const LoadingTable = () => {
  return (
    <Center
      mt="15px"
      padding="20px 25px"
      background="#fff"
      borderRadius="5px"
      minHeight="200px"
      width="100%"
      textAlign="center"
      flexDirection="column"
    >
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Center>
  );
};

export default LoadingTable;
