import {
  GET_LEAVE_TIME_DASHBOARD,
  GET_LEAVE_BY_DATE,
  LEAVE_LOADING
} from 'actions/types';

const initialState = {
    leaveTimeDashboard: [],
    leaveByTime:[],
    loading:false
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case LEAVE_LOADING:
        return {
          ...state,
          loading:true,
        }
      case GET_LEAVE_TIME_DASHBOARD:
        return {
          ...state,
          leaveTimeDashboard: action.payload
        };
        case GET_LEAVE_BY_DATE:
          return{
            ...state,
            leaveByTime:action.payload,
            loading:false
          }
      default:
        return state;
    }
  }
  