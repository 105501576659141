
import activeFeatureFlag from "./active-feature-flag/slice";
import attendanceDeviceRules from "./attendance-device-rules/slice";
import attendanceMapFilterCount from "./attendance-mapping-filter-count/slice";
import attendanceReportFilterCount from "./attendance-report-filter-count/slice";
import branchesWorkSites from "./branches-work-sites/slice";
import breakStatus from "./check-break-enabled-disabled/slice";
import employeeType from "./employeeType/slice";
import geoOfficeSites from "./geo-office-sites/slice";
import locationAttendanceReport from "./location-attendance-report/slice";
import showHideColumns from "./show-hide-columns/slice";

export const geoFencingSlices = {
    activeFeatureFlag,
    attendanceDeviceRules,
    attendanceMapFilterCount,
    attendanceReportFilterCount,
    branchesWorkSites,
    breakStatus,
    employeeType, geoOfficeSites,
    locationAttendanceReport,
    showHideColumns
} 