import coreHR from "./modules/corHR";
import lms from "./modules/lms";
import loan from "./modules/loan";
import recruit from "./modules/recruit";
import rolesPermission from "./modules/roles";
import taskManagement from "./modules/taskManagement";
import taskTracker from "./modules/taskTracker";
import travelManagement from "./modules/travelManagement";
import shiftRoster from "./modules/roster";
import geoFencing from "./modules/geofencing";
import appraisal from "./modules/appraisal";
import leaveTime from "./modules/leave-time";

const routes = features => {
  return [
    ...coreHR(features),
    ...leaveTime(features),
    ...shiftRoster(features),
    ...lms(features),
    ...loan(features),
    ...recruit(features),
    ...taskManagement(features),
    ...taskTracker(features),
    ...travelManagement(features),
    ...geoFencing(features),
    ...appraisal(features),
    ...rolesPermission(features),
  ];
};

export default routes;
