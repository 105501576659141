import * as fromInterface from "../../../ts";
import { BreakStatusState } from "./slice";

const selectBreakStatusSlice = (state: any): BreakStatusState => {
  return state.dataStore.breakStatus;
};

export const selectBreakStatusStatus = (
  state: any
): fromInterface.loadingType => {
  return selectBreakStatusSlice(state).status;
};

export const selectBreakStatusLoaded = (state: any): boolean => {
  return selectBreakStatusSlice(state).loaded;
};

export const selectBreakStatusItems = (state: any) => {
  return selectBreakStatusSlice(state).items;
};
