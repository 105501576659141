import React, {useState, useRef, useEffect} from "react";

import ReactCrop, {centerCrop, makeAspectCrop} from "react-image-crop";
import {canvasPreview} from "./canvasPreview";
import {useDebounceEffect} from "./useDebounceEffect";

import "react-image-crop/dist/ReactCrop.css";
import Modal from "components/modal";
import Button from "components/button";
import isEmpty from "helpers/isEmpty";

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export default function ImageCropModal({
  cropImages,
  src: imgSrc,
  open,
  setOpen,
  setCropImage,
}) {
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [aspect, setAspect] = useState(3 / 2);
  const [small, setSmall] = useState(null);

  useEffect(() => {
    if (!isEmpty(imgSrc)) {
      setOpen(true);
    }
  }, [imgSrc]);

  useEffect(() => {
    if (!isEmpty(cropImages)) {
      setSmall(cropImages);
    }
  }, [cropImages]);

  function onImageLoad(e) {
    if (aspect) {
      const {width, height} = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
      }
    },
    100,
    [completedCrop]
  );

  const onCropSave = () => {
    const dataURL = previewCanvasRef.current.toDataURL();
    setCropImage(dataURL);
    setOpen(false);
  };

  return (
    <Modal
      className="crop__modal"
      open={open}
      title="Crop Profile Photo"
      onModalClose={() => setOpen(false)}
    >
      <div className="crop-profile">
        <div className="crop-profile__container">
          <div className="crop-profile__crop">
            <div>
              {Boolean(imgSrc) && (
                <ReactCrop
                  aspect={aspect}
                  crop={crop}
                  keepSelection
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  onComplete={(c) => setCompletedCrop(c)}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                    width: 550,
                  }}
                >
                  <img ref={imgRef} alt="Crop me" src={imgSrc} onLoad={onImageLoad} />
                </ReactCrop>
              )}
            </div>
          </div>
          <div className="crop-profile__preview">
            {Boolean(completedCrop) && (
              <canvas
                ref={previewCanvasRef}
                style={{
                  objectFit: "contain",
                  width: 150,
                }}
              />
            )}
          </div>
        </div>

        <div className="crop-profile__footer">
          <Button onClick={() => onCropSave()} bg="primary">
            Crop Image
          </Button>
          <Button onClick={() => setOpen(false)} bg="secondary-dark">
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
}
