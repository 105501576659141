import * as fromSlice from "./slice";
import * as fromInterface from "../../../../ts";

const selectPerformanceFormListSlice = (state: any): fromSlice.PerformaneFormListState => {

    return state.dataStore.performanceFormList;
};

export const selectPerformanceFormListItems = (state: any): fromInterface.PerformanceForm[] | null => {
    return selectPerformanceFormListSlice(state).items;
};

export const selectPerformanceFormListStatus = (state: any): fromInterface.loadingType => {
    return selectPerformanceFormListSlice(state).status;
};

export const selectPerformanceFormListLoaded = (state: any): boolean => {
    return selectPerformanceFormListSlice(state).loaded;
};