import React from "react";

import { ModalBody, ModalBodyProps } from "@chakra-ui/react";

interface CommonModalBodyProps extends ModalBodyProps {
}
export const CommonModalBody = (props: CommonModalBodyProps) => {
    const { children, ...rest } = props;
    return <ModalBody {...rest}>{children}</ModalBody>;
}

export default CommonModalBody;
