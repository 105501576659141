import * as fromSlice from "./slice";
import * as fromInterface from "../../../ts";

import { ApiClient } from "../../../network/apiClient";
import { resetRolledoutPerformanceAppraisal, rolloutList, rolloutPerfomanceAppraisal } from "./api";
import * as datastore from "../../../index";
import { AprPerformanceFormReviewType } from "../../../enums/appraisal";
import * as fromReviewCycleDropdownStore from "../review-cycle-dropdown";
import * as fromPerformanceRolloutAppraisalFilterStore from "../performance-rollout-appraisal-filter";

export const fetchPerformanceRolloutAppraisalsInitialData = ({ reviewCycleId }: {
  reviewCycleId: number
}) => async (dispatch: any, getState: any) => {
  if (reviewCycleId) {

    await dispatch(fromReviewCycleDropdownStore.fetchReviewCycleDropDown());

    const state = getState();
    const dropdownItems = fromReviewCycleDropdownStore.selectReviewCycleDropdownItems(state);

    const reviewCycleDropdownItemFound = dropdownItems.find((item: any) => item.value === reviewCycleId);

    await dispatch(fromPerformanceRolloutAppraisalFilterStore.udpatePerformanceRolloutAppraisalFilter({
      reviewCycleId: reviewCycleDropdownItemFound,
    }));

    dispatch(fetchPerformanceRolloutAppraisals());
  }

};

export const fetchPerformanceRolloutAppraisals = () => async (dispatch: any, getState: any) => {

  const state = getState();

  const filterSlice = datastore.selectPerformanceRolloutAppraisalFilter(state);

  //fetch info
  dispatch(
    datastore?.fetchPerformanceRolloutInfo(AprPerformanceFormReviewType.StandardReview, filterSlice?.reviewCycleId?.value),
  );

  dispatch(fromSlice.fetchRequest());

  const bodyParam = {
    ...filterSlice,
    branchId: filterSlice?.branchId?.value || -1,
    departmentId: filterSlice?.departmentId?.value || -1,
    levelId: filterSlice?.levelId?.value || -1,
    reviewCycleId: filterSlice?.reviewCycleId?.value || -1,
    employeeName: filterSlice?.employee || "",
    managerId: filterSlice?.managerId?.value || -1,
    stat: filterSlice?.stat || "",
  };

  const response: fromInterface.ResponseWrapper<any> = await ApiClient.post<any>({
    endpoint: rolloutList,
    body: bodyParam,
    dispatch: dispatch,
  });


  if (response?.Status) {
    dispatch(fromSlice.fetchSuccess(response?.Data));
  }

  return response;
};

export const deletePerformanceRolloutAppraisal = (id: number) => async (dispatch: any, getState: any) => {
  const response: fromInterface.ResponseWrapper<any> = await ApiClient.remove<any>({
    endpoint: `${rolloutPerfomanceAppraisal}/${id}`,
    dispatch: dispatch,
  });
  return response;
};

export const resetPerformanceRolloutAppraisal = (id: number) => async (dispatch: any, getState: any) => {
  const response: fromInterface.ResponseWrapper<any> = await ApiClient.put<any>({
    endpoint: `${resetRolledoutPerformanceAppraisal}/${id}`,
    dispatch: dispatch,
  });
  return response;
};

export const clearPerformanceRolloutAppraisalList = () => (dispatch: any) => {
  dispatch(fromSlice.clear());
  dispatch(datastore.clearPerformanceRolloutAppraisalFilter());
  dispatch(datastore.clearPerformanceRolloutInfo());
  dispatch(datastore.clearPerformanceRolloutEmployeeItems());
};

