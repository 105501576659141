// @ts-nocheck 
import isEmpty from "helpers/isEmpty";

export const validateImage = (elem) => {
  const validImageExtension = ["png", "jpg", "jpeg"];
  const file = elem[0];
  const validExtensions = validImageExtension;
  const extension = file.name.split(".")[1];
  let errs: any = {};
  const isValidExtension = validExtensions.find((f) => f.toLowerCase() == extension.toLowerCase()) ? true : false;
  if (file.size > 556000) {
    //556000 Byte==556 Kilobyte
    errs.images = "Maximum valid file size is 556 Kb.";
  } else if (!isValidExtension) {
    errs.images = "Invalid Extension! Valid extension are " + validExtensions;
  }
  return {errs, isValid: isEmpty(errs)};
};
