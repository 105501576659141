import { ApiClient } from "../../../network/apiClient"
import { FETCH_AFFINITY_LEVEL } from "./api"


export const fetchAffinityLevelOptions = (): any => async (dispatch: any) => {
    const response = await ApiClient.get<any>({
        endpoint: `${FETCH_AFFINITY_LEVEL}`,
        dispatch: dispatch,
    })

    return response
}