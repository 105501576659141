// @ts-nocheck
import React from "react";

import RouteEnum from "constants/routeEnum";

const PerformanceScore = React.lazy(
  () => import("@rigotech/hrms-appraisal/src/pages/performance-score"),
);
const PerformanceForm = React.lazy(
  () => import("@rigotech/hrms-appraisal/src/pages/performance-form"),
);
const GoalCategory = React.lazy(
  () => import("@rigotech/hrms-appraisal/src/pages/goal-category"),
);
const GoalDatabase = React.lazy(
  () => import("@rigotech/hrms-appraisal/src/pages/goal-database"),
);
const ReviewsCycle = React.lazy(
  () => import("@rigotech/hrms-appraisal/src/pages/review-cycle"),
);
const RatingScale = React.lazy(
  () => import("@rigotech/hrms-appraisal/src/pages/rating-scale"),
);
const PerformanceFormList = React.lazy(
  () => import("@rigotech/hrms-appraisal/src/pages/performance-form-list"),
);
const PerformanceRolloutAppraisal = React.lazy(
  () =>
    import("@rigotech/hrms-appraisal/src/pages/performance-rollout-appraisal"),
);
const PerformanceRolloutThreeSixty = React.lazy(
  () =>
    import(
      "@rigotech/hrms-appraisal/src/pages/performance-rollout-three-sxity"
    ),
);
const PerformanceReview = React.lazy(
  () => import("@rigotech/hrms-appraisal/src/pages/performance-review"),
);
const PerformanceReviewActivities = React.lazy(
  () =>
    import("@rigotech/hrms-appraisal/src/pages/performance-review-activities"),
);
const ReviewWorkFlow = React.lazy(
  () => import("@rigotech/hrms-appraisal/src/pages/review-workflow"),
);
const PerformanceReviewPreview = React.lazy(
  () => import("@rigotech/hrms-appraisal/src/pages/performance-review-preview"),
);
const PerformanceReviewConsent = React.lazy(
  () => import("@rigotech/hrms-appraisal/src/pages/performance-review-consent"),
);
const FinalScore = React.lazy(
  () => import("@rigotech/hrms-appraisal/src/pages/final-score"),
);
const PMSConfig = React.lazy(
  () => import("@rigotech/hrms-appraisal/src/pages/pms-configuration"),
);
const PerformanceReviewEvaluation = React.lazy(
  () =>
    import("@rigotech/hrms-appraisal/src/pages/performance-review-evaluation"),
);
const DevelopmentAreas = React.lazy(
  () => import("@rigotech/hrms-appraisal/src/pages/development-areas"),
);
const ParticipantWeights = React.lazy(
  () => import("@rigotech/hrms-appraisal/src/pages/participant-weights"),
);
const AffinityWeight = React.lazy(
  () => import("@rigotech/hrms-appraisal/src/pages/affinity-weight"),
);

const PMSDashboard = React.lazy(
  () => import("@rigotech/hrms-appraisal/src/pages/pms-dashboard"),
);
const AdditionalScore = React.lazy(
  () => import("@rigotech/hrms-appraisal/src/pages/additional-scores"),
);
const EmployeeDevelopmentPlan = React.lazy(
  () => import("@rigotech/hrms-appraisal/src/pages/employee-development-plan"),
);
const PMSAnalytics = React.lazy(
  () => import("@rigotech/hrms-appraisal/src/pages/pms-analytics"),
);

/**
 * SUPPLY LINKS TO RENDER IN SIDDEBAR
 */
const appraisalLinks = [
  {
    path: RouteEnum.pmsDashboard,
    exact: true,
    component: PMSDashboard,
    title: "PMS Dashoard",
  },
  {
    path: RouteEnum.pmsAnalytics,
    exact: true,
    component: PMSAnalytics,
    title: "PMS Analytics",
  },
  {
    path: RouteEnum.aprPerfromanceForm,
    exact: true,
    component: PerformanceFormList,
    title: "Review Form",
  },
  {
    path: `${RouteEnum.performanceRollout}/:reviewCycleId?`,
    exact: true,
    component: PerformanceRolloutAppraisal,
    title: "Performance Apprisals",
  },
  {
    path: `${RouteEnum.threeSixtyReviewRollout}/:reviewCycleId?`,
    exact: true,
    component: PerformanceRolloutThreeSixty,
    title: "360 Degree Review",
  },
  {
    path: RouteEnum.appraisalsWorkFlowRoutes,
    exact: true,
    component: ReviewWorkFlow,
    title: "Appraisal Work Flow",
  },
  {
    path: RouteEnum.appraisalsReviewsRoutes,
    exact: true,
    component: ReviewsCycle,
    title: "Reviews Cycle",
  },
  {
    path: RouteEnum.appraisalsRatingScaleRoutes,
    exact: true,
    component: RatingScale,
    title: "Rating Scale",
  },
  {
    path: RouteEnum.appraisalsPMSConfigRoutes,
    exact: true,
    component: PMSConfig,
    title: "PMS Configuration",
  },

  {
    path: RouteEnum.appraisalsGoalCategoryRoutes,
    exact: true,
    component: GoalCategory,
    title: "Goal Categolry",
  },
  {
    path: RouteEnum.appraisalsGoalDatabaseRoutes,
    exact: true,
    component: GoalDatabase,
    title: "Goal Database",
  },
  {
    path: RouteEnum.appraisalsFinalScoreRoutes,
    exact: true,
    component: FinalScore,
    title: "Final Score",
  },
  {
    path: `${RouteEnum.appraisalsPerformanceScoreRoutes}/:reviewCycleId?`,
    exact: true,
    component: PerformanceScore,
    title: "Performance Score",
  },
  {
    path: `${RouteEnum.newAppraisalPerformanceDashboard}/:formId?/:sectionId?`,
    exact: true,
    component: PerformanceForm,
    title: "Performance Form",
  },
  {
    path: `${RouteEnum.performanceReview}/:reviewId?`,
    exact: true,
    component: PerformanceReview,
    title: "Performance Review",
  },
  {
    path: `${RouteEnum.performancePreview}/:rolloutId?`,
    exact: true,
    component: PerformanceReviewPreview,
    title: "Performance Review Preview",
  },
  {
    path: `${RouteEnum.performanceConsent}/:rolloutId?`,
    exact: true,
    component: PerformanceReviewConsent,
    title: "Performance Review Consent",
  },
  {
    path: `${RouteEnum.performanceEvaluation}/:rolloutId?`,
    exact: true,
    component: PerformanceReviewEvaluation,
    title: "Performance Review Evaluation",
  },
  {
    path: `${RouteEnum.performanceReviewActivities}`,
    exact: true,
    component: PerformanceReviewActivities,
    title: "Performance Review Activities",
  },
  {
    path: RouteEnum.appraisalsDevelopmentAreasRoutes,
    exact: true,
    component: DevelopmentAreas,
    title: "Development Areas",
  },
  {
    path: RouteEnum.appraisalsParticipantWeights,
    exact: true,
    component: ParticipantWeights,
    title: "Participant Weights",
  },
  {
    path: RouteEnum.affinityWeight,
    exact: true,
    component: AffinityWeight,
    title: "Affinity Weight",
  },
  {
    path: `${RouteEnum.additionalScore}/:reviewCycleId?`,
    exact: true,
    component: AdditionalScore,
    title: "Additional Scores",
  },
  {
    path: RouteEnum.employeeDevelopmentPlan,
    exact: true,
    component: EmployeeDevelopmentPlan,
    title: "Employee Development Plan",
  },
];

export default appraisalLinks;
