import { createSlice } from "@reduxjs/toolkit";
import * as fromInterface from "../../../ts";

export interface EmployeeTypeState {
  items: any[];
  status: fromInterface.loadingType;
  loaded: boolean;
}

const initialState: EmployeeTypeState = {
  items: [],
  status: fromInterface.STATUS.LOADING,
  loaded: false,
};

const employeeTypeSlice = createSlice({
  name: "new employee type",
  initialState,
  reducers: {
    fetchRequest: (state) => {
      state.status = fromInterface.STATUS.LOADING;
    },
    fetchSuccess: (state, action) => {
      const payload = action.payload;

      state.items = payload;
      state.status = fromInterface.STATUS.IDLE;
      state.loaded = true;
    },
    clear: (state) => {
      state.items = [];
      state.status = fromInterface.STATUS.IDLE;
      state.loaded = false;
    },
  },
});

export const { fetchRequest, fetchSuccess, clear } = employeeTypeSlice.actions;

export default employeeTypeSlice.reducer;
