import { createSlice } from "@reduxjs/toolkit";
import * as fromInterface from "../../../ts";

export interface ReviewCycleState {
    activeItems: any,
    activeItemFilter: any
    isLoadingActiveItem: boolean,

    archivedItems: any,
    archivedItemFilter: any
    isLoadingArchivedItem: boolean,


}

const initialState: ReviewCycleState = {

    activeItems: [],
    activeItemFilter: { pageIndex: 0, pageSize: 20 },
    isLoadingActiveItem: false,
    archivedItems: [],
    archivedItemFilter: { pageIndex: 0, pageSize: 20 },
    isLoadingArchivedItem: false,
};

const reviewCycleSlice = createSlice({
    name: "review cycle",
    initialState,
    reducers: {
        fetchRequest_Active: (state) => {
            state.isLoadingActiveItem = true;
        },
        fetchSucces_Active: (state, action) => {
            const payload = action.payload
            state.activeItems = payload;
            state.isLoadingActiveItem = false;
        },

        fetchRequest_Archived: (state) => {
            state.isLoadingArchivedItem = true;
        },
        updateFilter_Active: (state, action) => {
            state.activeItemFilter = action.payload;
            return state
        },
        fetchSucces_Archived: (state, action) => {
            const payload = action.payload
            state.archivedItems = payload;
            state.isLoadingArchivedItem = false;
        },
        updateFilter_Archived: (state, action) => {
            state.archivedItemFilter = action.payload;
            return state
        },


        clear: (state) => {
            state = { ...initialState }
        },
    },
});

export const {
    fetchRequest_Active,
    fetchSucces_Active,
    updateFilter_Active,
    fetchRequest_Archived,
    fetchSucces_Archived,
    updateFilter_Archived,
    clear,
} = reviewCycleSlice.actions

export default reviewCycleSlice.reducer;
