import * as fromInterface from "../../../ts";
import * as fromConstant from "../../../constants";
import { ApiClient } from "../../../network/apiClient";
import * as fromSlice from "./slice";
import {
  attendenceDeviceRulesGet,
  attendenceDeviceRulesPut,
  postcsvFile,
} from "./api";

export const fetchAttendanceDeviceRules =
  (data?: any) => async (dispatch: any, getState: any) => {
    if (data?.isExport) {
      await ApiClient.exportFile_post<any>({
        endpoint: attendenceDeviceRulesGet,
        dispatch: dispatch,
        body: { ...data },
        convertXlsx: true,
        showLoader: true,
      });
    } else {
      dispatch(fromSlice.fetchRequest());
      const response: any = await ApiClient.post({
        endpoint: attendenceDeviceRulesGet,
        dispatch: dispatch,
        body: { ...data },
      });
      dispatch(fromSlice.fetchSuccess(response?.Data));

      return response;
    }
  };

export const postCsvData =
  ({ fileData, cb }: { fileData?: any; cb?: any }): any =>
  async (dispatch: any, getState: any) => {
    const formData = new FormData();
    formData.append("employeeMapping", fileData);

    const param = {
      endpoint: postcsvFile,
      body: formData,
      dispatch: dispatch,
    };

    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.post<any>(param, true);

    const { Data, Status } = response;

    cb?.(Status);
  };

export const updateAttendanceDeviceRules =
  (data?: any, cb?: any): any =>
  async (dispatch: any, getState: any) => {
    const param = {
      endpoint: attendenceDeviceRulesPut,
      body: {
        ...data,
      },
      dispatch: dispatch,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.put<any>(param);
    const { Data, Status } = response;
    // if (Status) {
    //   dispatch(
    //     fetchAttendanceDeviceRules({
    //       pageIndex: 0,
    //       pageSize: 0,
    //       employeeName: "",
    //       // departmentId: 0,
    //       // branchId: 0,
    //     })
    //   );
    // }
    cb?.(Status);
  };

export const clearAttendanceDeviceRules = () => (dispatch: any) => {
  dispatch(fromSlice.clear());
};
