import React from 'react';
import { RigoSwitchContext } from './rigo-switch-context';

export const useSwitch = () => {
  const context = React.useContext(RigoSwitchContext);
  if (context === undefined) {
    throw new Error('useSwitch must be used within a <RigoSwitch />');
  }
  return context;
};
