import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {leaveApprovalFlowFormSchema,selectSchema} from '../leaveApprovalFlow';

const BaseApprovalFormSchema = z.object({
    // "Timerequest type isrequired"
    TimeRequestType:selectSchema.required(),
    LeaveApprovalFlow:selectSchema.optional(),
    FlowName: z.string({
        required_error: "Flow Name is required",
        invalid_type_error: "Flow Name must be a string",
    }).optional(),
    AppliesTo: selectSchema.optional(),
    FlowActivities:z.array(selectSchema,{required_error: "Leave Flow activities field is required. Please choose atlease one flow activity item",}).nonempty({
        message:"Leave Flow activities field is required. Please choose atlease one flow activity item"
    }).optional(),
    FlowInitiators:z.array(selectSchema).nonempty({
        message:"Flow Initiators field is required. Please choose atlease one item"
    }).optional(),
    AdditionalApprovers:z.array(selectSchema).max(2,{message:'You are only allowed to select atmost 2 approvers'}).optional(),
    AdditionalApprovalRequired:z.boolean().optional(),
    AdditionalApprovalActivationTime:z.any().optional(),
    LeeavesCount:selectSchema.optional(),
    Approvals:z.array(
        z.object({
            Approvers:
                z.array(selectSchema.optional())
                .nonempty({message:"Approvers field is required"})
                .max(2,{message:"Please pick atmost of 2 approvers"})
        }).optional()
    ).max(5,{message:"You are allowed to add atmost of 5 levels"}).optional()
})

export const timeApprovalFlowFormSchema = BaseApprovalFormSchema

timeApprovalFlowFormSchema.superRefine((values, ctx) => {
    console.log({values:values})
    if (values.TimeRequestType?.value == 1) {
        ctx.addIssue({
            message: 'LeaveApprovalFlow is required.',
            code: z.ZodIssueCode.custom,
            path: ['LeaveApprovalFlow'],
        });
    }
    if (values.TimeRequestType?.value == 2) {
        if(!values.FlowName){
            ctx.addIssue({
                message: 'FlowName is required.',
                code: z.ZodIssueCode.custom,
                path: ['FlowName'],
            });
        }

    }
});


export type TimeApprovalFlowForm = z.infer<typeof timeApprovalFlowFormSchema>;
export const TimeApprovalResolver = zodResolver(timeApprovalFlowFormSchema)