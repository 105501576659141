// @ts-nocheck 
import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import useOutsideClick from "helpers/outsideClickHook";
import rootStore from "src/store/configureStore";
import { Provider } from "react-redux";

// interface Props {
//   containerClassName?: String;
//   open: boolean;
//   height?: number;
//   width?: number;
//   parentRef: any;
//   handleOutsideClick: any;
// }
const CustomPopup = (props: any) => {
  const { open, containerClassName } = props;
  var popupRef = useRef(null);
  const [target, setTarget] = useState(document.createElement("div"));
  const [state, setState] = useState({
    left: -1,
    top: -1,
    visibility: "hidden",
  });

  useOutsideClick(popupRef, props.handleOutsideClick, props.parentRef);
  useEffect(() => {
    document.body.appendChild(target);
    document.addEventListener("scroll", handleSCroll);
    return () => {
      ReactDOM.unmountComponentAtNode(target);
      document.body.removeChild(target);
      document.removeEventListener("scroll", handleSCroll);
    };
  }, []);

  useEffect(() => {
    props.children && render();
  }, [props.children]);

  useEffect(() => {
    render();
  }, [state]);

  const handleSCroll = () => {
    open && props.handleOutsideClick && props.handleOutsideClick(false);
  };

  const setPosition = () => {
    if (popupRef && popupRef.current) {
      const { parentRef } = props;

      const { width: popupWidth, height: popupHeight } =
        popupRef.current.getBoundingClientRect();

      const { x, y, width, height } =
        parentRef && parentRef.current.getBoundingClientRect();
      let newTop = y + height;
      let newLeft = x;

      if (newTop + popupHeight > window.innerHeight) {
        newTop = y - popupHeight;
      }
      if (newTop < 0) {
        newTop = 10;
      }
      if (newLeft + popupWidth > window.innerWidth) {
        newLeft = window.innerWidth - popupWidth - width;
      }

      let newState = {
        top: newTop,
        left: newLeft,
        visibility: "visible",
      };

      if (!isEqual(newState)) setState(newState);
    }
  };
  const isEqual = (newState) => {
    if (state.top !== newState.top) return false;
    if (state.left !== newState.left) return false;
    if (state.visibility !== newState.visibility) return false;
    return true;
  };
  const render = () => {
    const style: any = { ...state };
    const { parentRef } = props;

    ReactDOM.render(
      <Provider store={rootStore}>
        <div className={props.containerClassName} style={style} ref={popupRef}>
          {props.children}
        </div>
      </Provider>,
      target,
      () => {
        setPosition();
      }
    );
  };

  return <noscript />;
};
CustomPopup.defaultProps = {
  containerClassName: "custom-popup",
  // height: 360,
  // width: 300,
};
export default CustomPopup;
