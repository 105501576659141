import {GET_EMPLOYEES_DEVICES, GET_EMPLOYEES_DEVICE_HISTORY} from "./types";

const initialState = {employeeDevices: null, deviceHistories: null};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEES_DEVICES:
      return {
        ...state,
        employeeDevices: action.payload,
      };

    case GET_EMPLOYEES_DEVICE_HISTORY:
      return {
        ...state,
        deviceHistories: action.payload,
      };

    default:
      return state;
  }
}
