import { createSlice } from "@reduxjs/toolkit";
import * as fromInterface from "../../../ts";
import { createSteps } from "./helper/create-steps";

export interface PerformanceReviewState {
    item: fromInterface.PerformanceReview,
    status: fromInterface.loadingType;
    loaded: boolean;

    selfItem: fromInterface.PerformanceReview,
    selfStatus: fromInterface.loadingType;
    selfLoaded: boolean;

    currentStep: fromInterface.Step;
    uiCurrentStep: fromInterface.Step;
    steps: fromInterface.Step[];
}

const initialState: PerformanceReviewState = {
    item: {},
    status: fromInterface.STATUS.LOADING,
    selfStatus: fromInterface.STATUS.LOADING,

    selfItem: {},
    selfLoaded: false,
    loaded: false,

    currentStep: {},
    uiCurrentStep: {},
    steps: []
};

const performanceReviewSlice = createSlice({
    name: "performance review",
    initialState,
    reducers: {
        fetchRequest: (state) => {
            state.status = fromInterface.STATUS.LOADING;
        },
        fetchSuccess: (state, action) => {
            const payload: any = action.payload


            const { ReviewInfo, Evaluation } = payload;
            const { OverallPerfomanceRatingScaleOptions } = ReviewInfo


            const { newSteps, currentStep }: any = createSteps(payload)

            console.log({ newSteps, currentStep })

            let newEvaluation = Evaluation
            if (Evaluation) {

                const ratingScaleFound = OverallPerfomanceRatingScaleOptions.find((rs: any) => {
                    return rs.value === Evaluation.RatingScaleOptionId
                })
                newEvaluation = { ...Evaluation, ratingScaleOptionId: ratingScaleFound }
            }

            state.item = { ...payload, Evaluation: newEvaluation };
            state.steps = newSteps;
            state.currentStep = currentStep;

            state.status = fromInterface.STATUS.IDLE;
            state.loaded = true

        },

        fetchError: (state) => {
            state.item = {};
            state.status = fromInterface.STATUS.IDLE;
            state.loaded = true
        },

        fetchSlefRequest: (state) => {
            state.selfStatus = fromInterface.STATUS.LOADING;
        },

        fetchSelfSuccess: (state, action) => {
            const payload = action.payload

            state.selfItem = payload
            state.selfStatus = fromInterface.STATUS.IDLE;
            state.selfLoaded = true

        },

        syncReviewDetail: (state, action) => {
            state.item = action.payload;
        },

        setStep: (state, action) => {
            const payload = action.payload

            state.currentStep = payload.currentStep;
            state.steps = payload.steps;
        },

        clear: (state) => {
            // state.item = {}
            // state.status = fromInterface.STATUS.IDLE
            // state.loaded = false
            state = initialState
        },
    },
});

export const {
    fetchRequest,
    fetchSuccess,
    fetchError,
    fetchSlefRequest,
    fetchSelfSuccess,
    setStep,
    syncReviewDetail,
    clear,
} = performanceReviewSlice.actions

export default performanceReviewSlice.reducer;