import * as fromInterface from "../../../ts";
import { PerformanceRolloutThreeSixtyListState } from "./slice";

const selectPerformanceRolloutThreeSixtyListSlice = (state: any): PerformanceRolloutThreeSixtyListState => {
    return state.dataStore.performanceRolloutThreeSixtyList as PerformanceRolloutThreeSixtyListState;
};


export const selectPerformanceRolloutThreeSixtyListItems = (state: any) => {
    return selectPerformanceRolloutThreeSixtyListSlice(state).items;
};


export const selectPerformanceRolloutThreeSixtyListStatus = (state: any): fromInterface.loadingType => {
    return selectPerformanceRolloutThreeSixtyListSlice(state).status;
};

export const selectPerformanceRolloutThreeSixtyListLoaded = (state: any): boolean => {
    return selectPerformanceRolloutThreeSixtyListSlice(state).loaded;
};


