import { createSlice } from "@reduxjs/toolkit";
import * as fromInterface from "../../../ts";

export interface ActiveFeatureFlagState {
  items: any;
  status: fromInterface.loadingType;
  loaded: boolean;
}

const initialState: ActiveFeatureFlagState = {
  items: [],
  status: fromInterface.STATUS.LOADING,
  loaded: false,
};

const activeFeatureFlagSlice = createSlice({
  name: "Active Feature Flag",
  initialState,
  reducers: {
    fetchRequest: (state) => {
      state.status = fromInterface.STATUS.LOADING;
    },
    fetchSuccess: (state, action) => {
      const payload = action.payload;

      state.items = payload;
      state.status = fromInterface.STATUS.IDLE;
      state.loaded = true;
    },
    clear: (state) => {
      state.items = [];
      state.status = fromInterface.STATUS.IDLE;
      state.loaded = false;
    },
  },
});

export const { fetchRequest, fetchSuccess, clear } =
  activeFeatureFlagSlice.actions;

export default activeFeatureFlagSlice.reducer;
