import { AprPMSConfigType } from "../../../enums/appraisal"
import * as frommodels from "@rigotech/hrms-model"
import { PMSAnalytics } from "./slice"

export const selectCurrentSlice = (state: any): PMSAnalytics => {
  return state.dataStore.pmsAnalytics
}

export const selectStatus = (state: any): frommodels.loadingType => {
  return selectCurrentSlice(state).status;
}

export const selectItems = (state: any): frommodels.loadingType => {
  return selectCurrentSlice(state).status;
}

export const selectLoaded = (state: any): boolean => {
  return selectCurrentSlice(state).loaded;
}
