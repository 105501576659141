//Appraisals
enum Appraisal {
  appraisalsRoutes = "/appraisals",
  appraisalsPerformanceRoutes = "/appraisals/performance-appraisals",
  appraisalsQuestionairesRoutes = "/appraisals/questionairies",
  appraisalsReviewsRoutes = "/appraisals/reviews",
  appraisalsEmailConfigRoutes = "/appraisals/email-config",
  appraisalsRatingScaleRoutes = "/appraisals/rating-scale",
  appraisalsWorkFlowRoutes = "/appraisals/work-flow",
  appraisalsGoalDatabaseRoutes = "/appraisals/goal-database",
  appraisalsGoalCategoryRoutes = "/appraisals/goal-category",
  appraisalsFinalScoreRoutes = "/appraisals/final-score",
  appraisalsPerformanceScoreRoutes = "/appraisals/performance-score",
  appraisalsthreeSixtyDegreeReviewRoutes = "/appraisals/three-sixty-degree-view",
  aprPerfromanceForm = "/appraisals/performance-form",
  appraisalsPMSConfigRoutes = "/appraisals/pms-configuration",
  appraisalsDevelopmentAreasRoutes = "/appraisals/development-areas",
  affinityWeight = "/appraisals/affinity-weight",
  appraisalsParticipantWeights = "/appraisals/participant-weights",
  pmsDashboard = "/appraisals/pms-dashboard",
  pmsAnalytics = "/appraisals/pms-analytics",
  // Rollout
  performanceRollout = "/appraisals/rollout/performance-appraisal",
  threeSixtyReviewRollout = "/appraisals/rollout/360-degree",

  // NEW APPRAISAL
  newAppraisalPerformanceDashboard = "/appraisals/performance-form/questionnaires",
  newPerformanceReviewForm = "/appraisals/new-performance-review-form",
  performanceReview = "/appraisals/performance-review/review",
  performanceReviewActivities = "/appraisals/performance-review/activities",
  performancePreview = "/appraisals/performance-review/preview",
  performanceConsent = "/appraisals/performance/consent",
  ReviewWorkFlow = "/appraisals/review-workflow",
  performanceEvaluation = "/appraisals/performance/evaluation",
  additionalScore = "/appraisals/additional-scores",
  employeeDevelopmentPlan = "/appraisals/employee-development-plan"
}

export default Appraisal
