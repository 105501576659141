import * as fromSlice from "./slice";
import * as fromGoalDataStore from "./../goal-database"
export const udpateGoalDatabaseFilter =(newFilters: any):any => (dispatch: any, getState: any) => {
    dispatch(fromSlice.udpateFilter(newFilters))
}

export const clearGoalDatabaseFilter =() => (dispatch: any, getState: any) => {
    dispatch(fromSlice.clear())
}

export const udpateGoalDatabaseTablePagination =(pageInfo: any) => (dispatch: any, getState: any) => {
    // OPTIMISTIC UI UPDATE
    dispatch(fromSlice.udpateFilter(pageInfo))
    dispatch(fromGoalDataStore.fetchGoalDatabase())
}
