import React, { useMemo, useState } from "react";

//CONTEXT
import { ConfirmationContext } from "./confirmationContext";

//CHAKRA
import { useDisclosure } from "@chakra-ui/react";

//INTERFACE
import ConfirmationModal, {
  ICommonConfirmationModalProps,
} from "../../confirmation-modal";
interface IConfirmationProvider {
  children: JSX.Element;
}

const initialConfirmationState = {
  onConfirm: () => undefined,
  onCancel: () => undefined,
};

const initialLoadingState = {
  isConfirm: false,
  isCancel: false,
};
export const ConfirmationProvider = (props: IConfirmationProvider) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  //state
  const [loading, setLoading] = useState(initialLoadingState);

  const [confirmationState, setConfirmationState] =
    useState<ICommonConfirmationModalProps>(initialConfirmationState);

  const openConfirm = (args: ICommonConfirmationModalProps) => {
    setConfirmationState((prev) => {
      return { ...prev, ...args };
    });
    onOpen();
  };

  const onCancel = async () => {
    setLoading({ ...loading, isCancel: true });
    const status = await confirmationState?.onCancel();
    setLoading({ ...loading, isCancel: false });
    if (status || status === undefined) onClose();
  };

  const onConfirm = async () => {
    setLoading({ ...loading, isConfirm: true });
    const status = await confirmationState?.onConfirm();
    setLoading({ ...loading, isConfirm: false });
    if (status || status === undefined) onClose();
  };

  //context value
  const contextValue = useMemo(() => ({ openConfirm }), []);

  const onReset = () => {
    setLoading(initialLoadingState);
    setConfirmationState(initialConfirmationState);
  };

  return (
    <ConfirmationContext.Provider value={contextValue}>
      {props.children}
      {isOpen && (
        <ConfirmationModal
          {...{ isOpen, onClose }}
          {...confirmationState}
          closeOnOverlayClick={false}
          onCancel={onCancel}
          onConfirm={onConfirm}
          isConfirmLoading={loading.isConfirm}
          isCancelLoading={loading.isCancel}
          onReset={onReset}
        />
      )}
    </ConfirmationContext.Provider>
  );
};
