import * as fromTs from "../../../ts";
import * as fromSlice from "./slice";


const selectCurrentFormStateSlice = (state: any): fromSlice.FormState => {
    return state.dataStore.preformanceFormState;
};

export const selectFormState = (state: any): fromTs.AppraisalSection => {
    return selectCurrentFormStateSlice(state).formState;
};

export const selectFormStateEditMode = (state: any): boolean => {
    return selectCurrentFormStateSlice(state).editMode;
};

export const selectFormStateEditModeIndex = (state: any): number => {
    return selectCurrentFormStateSlice(state).editModeIndex;
};
export const selectFormStatus = (state: any): fromTs.loadingType => {
    return selectCurrentFormStateSlice(state).status;
};
