// @ts-nocheck 
import types from "constants/types";

const initialState = {
  taskTrackerPorjectDetailsList: [],
  taskTrackerProjectTypes: [],
  exportTaskTrackerPorjectDetailsList: [],
};

export default function (state: any = initialState, action: any) {
  switch (action.type) {
    case types.TASK_TRACKER_PROJECT_DETAILS_LIST: {
      return {
        ...state,
        taskTrackerPorjectDetailsList: action.payload,
      };
    }
    case types.EXPORT_TASK_TRACKER_PROJECT_DETAILS_LIST: {
      return {
        ...state,
        exportTaskTrackerPorjectDetailsList: action.payload,
      };
    }
    case types.TASK_TRACKER_GET_PROJECT_TYPES: {
      return {
        ...state,
        taskTrackerProjectTypes: action.payload,
      };
    }

    default:
      return state;
  }
}
