import { SelectorFreeTextPageData } from "@rigotech/hrms-data/src/ts";
import { selectCurrentStep, selectReview, selectReviewForSelf } from ".";
import { mapComments } from "../helper/map-comments";

/*
    get current step
    get review detail
    get self review detail
    extract reviewinfo from review detail
    get section based on current step
    get section index based on section
    extract info from section
    map new review detail
        get comments for current page adata
        map through review detail and create new comments based on section id , review id, section content id 
        set new comment aarray as comments on review detal
    revturn new mapped review 
*/
export const selectFreeTextPageData = (
  state: any,
): SelectorFreeTextPageData => {
  const currentStep = selectCurrentStep(state);
  const review = selectReview(state);
  const selfReview = selectReviewForSelf(state);

  const { ReviewInfo } = review;

  const section = review.Sections?.find((section: any) => {
    return section.Id === currentStep.sectionId;
  });

  const sectionIdx = review.Sections?.findIndex((section: any) => {
    return section.Id === currentStep.sectionId;
  });

  if (section) {
    const {
      Id: SectionId,
      PerformanceFormId,
      RatingScaleName,
      SectionName,
      IntroText,
      RatingScaleOptions,
      Contents,
      AssessmentType,
      AllowSelfMarking,
    } = section;

    const reviewDetails = Contents.map((content: any, contentIdx) => {
      const {
        Id,
        Title,
        AllowComments,
        DoNotMark,
        RatingScaleOptionId,
        Text,
        RatingScore,
      } = content;
      let Comments: any = mapComments(SectionId, Id, state, ReviewInfo);

      return {
        SectionId,
        sectionContentId: Id,
        RatingScaleOptionId,
        RatingScore: (RatingScore as number) || 0,
        Text,
        AllowComments,
        Comments,

        selfMarking: {
          Text: selfReview?.Sections?.[sectionIdx as number].Contents[
            contentIdx
          ].Text,
          RatingScore:
            selfReview?.Sections?.[sectionIdx as number].Contents[contentIdx]
              .RatingScore,
          RatingScaleOptionId:
            selfReview?.Sections?.[sectionIdx as number].Contents[contentIdx]
              .RatingScaleOptionId,
        },

        // REQUIRED TO DISPLAY
        Title,
        DoNotMark,
      };
    });

    const mappedReview: SelectorFreeTextPageData = {
      Id: null, // THIS IS REVIEW ID
      PerformanceFormId,
      PerformanceRolloutId: ReviewInfo?.PerformanceRolloutId as number,
      reviewDetails,
      signature: false,

      // REQUIRED TO DISPLAY
      SectionName,
      IntroText,
      RatingScaleName: RatingScaleName as string,
      RatingScaleOptions,
      AssessmentType,
      AllowSelfMarking,
      ReviewType: ReviewInfo?.ReviewType as string,
    };

    return mappedReview;
  }

  return {
    Id: null, // THIS IS REVIEW ID
    PerformanceFormId: 0,
    PerformanceRolloutId: 0,
    reviewDetails: [],
    signature: false,

    // REQUIRED TO DISPLAY
    SectionName: "",
    IntroText: "",
    RatingScaleName: "",
    RatingScaleOptions: [],
    AssessmentType: 0,
    AllowSelfMarking: false,
    ReviewType: "",
  };
};
