import React, { useEffect } from "react";
import { Box, ModalProps } from "@chakra-ui/react";
import { Button, Modal } from "@rigotech/ui";

//INTERFACE
type IConfirmationModalPropsOmited = Omit<ModalProps, "children">;
export interface ICommonConfirmationModalProps {
  title?: string;
  onConfirm: any;
  //additional props
  size?: string;
  onCancel?: any;
  contentMessage?: JSX.Element;
  confirmationBtnName?: string;
  confirmBtnType?: any;
  isConfirmLoading?: boolean;
  isCancelLoading?: boolean;
  onReset?: any;
}

type IConfirmationModalProps = IConfirmationModalPropsOmited &
  ICommonConfirmationModalProps;

export const ConfirmationModal = (props: IConfirmationModalProps) => {
  const {
    size,
    title,
    confirmationBtnName,
    contentMessage,
    onCancel,
    onConfirm,
    onClose,
    isCancelLoading,
    isConfirmLoading,
    onReset,
    confirmBtnType,
    ...rest
  } = props;

  useEffect(() => {
    return () => {
      onReset ? onReset() : onClose();
    };
  }, []);

  return (
    <Modal {...rest} onClose={onClose}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Body>
          {contentMessage ? (
            contentMessage
          ) : (
            <Box fontSize='16px' color='gray.600'>
              Are you sure? You can't undo this action afterwards.
            </Box>
          )}
        </Modal.Body>
        <Modal.Footer mt='0'>
          <Button
            width='auto'
            height='36px'
            fontSize='16px'
            marginRight='10px'
            buttonType='secondary'
            onClick={onCancel ? onCancel : onClose}
            isLoading={isCancelLoading}
            isDisabled={isConfirmLoading || isCancelLoading}
          >
            Cancel
          </Button>
          <Button
            width='auto'
            height='36px'
            fontSize='16px'
            buttonType={confirmBtnType ? confirmBtnType : 'danger'}
            onClick={onConfirm}
            isLoading={isConfirmLoading}
            isDisabled={isConfirmLoading || isCancelLoading}
          >
            {confirmationBtnName ? confirmationBtnName : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default ConfirmationModal;

ConfirmationModal.defaultProps = {
  size: "sm",
  title: "Confirmation",
};
