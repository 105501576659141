// @ts-nocheck 
import React from "react";
import ButtonsSkeleton from "./buttonsSkeleton";
import CardSkeleton from "./cardSkeleton";
import TableSkeleton from "./tableSkeleton";
import TableSkeletonMaterial from "./tableSkeletonMaterial";

interface Loading {
  loading?: boolean;
  name?: string;
  numberOfBtns?: number | string;
}

const Skeleton = (props: Loading) => {
  const {loading} = props;
  if (!loading) {
    return null;
  }
  const switchComponent = () => {
    const {name} = props;
    switch (name) {
      case "card":
        return CardSkeleton;
      case "table":
        return TableSkeleton;
      case "buttons":
        return ButtonsSkeleton;
      case "custom-table":
        return TableSkeletonMaterial;
      default:
        return null;
    }
  };
  const Component = switchComponent();
  return (
    <div className="skeleton-loading">
      <Component {...props} />
    </div>
  );
};

export default Skeleton;
