import { createSlice } from "@reduxjs/toolkit";
import * as fromInterface from "../../../ts";

export interface PerformanceFormState {
    items   : any,
    filters : any,
    pageInfo: any,
    status  : fromInterface.loadingType;
    loaded  : boolean;
}

const initialState: PerformanceFormState = {
    items  : [],
    filters: {
        reviewCycleId: null,
        formName     : "asd",
        isRolledOut  : null,
    },
    pageInfo: {
        page    : 0,
        pageSize: 10,
    },
    status: fromInterface.STATUS.LOADING,
    loaded: false,
};

const performanceFormSlice = createSlice({
    name: "performance form",
    initialState,
    reducers: {
        fetchRequest: (state) => {
            state.status = fromInterface.STATUS.LOADING;
        },
        fetchSuccess: (state, action) => {
            const payload = action.payload

            state.items  = payload;
            state.status = fromInterface.STATUS.IDLE;
            state.loaded = true

        },
        updateFilter: (state, action) => {
            const payload       = action.payload
                  state.filters = payload;
        },
        updatePageInfo: (state, action) => {
            const payload        = action.payload
                  state.pageInfo = payload;
        },
        clear: (state) => {
            state.items    = []
            state.filters  = {}
            state.pageInfo = {}
            state.status   = fromInterface.STATUS.IDLE
            state.loaded   = false
        },
    },
});

export const {
    fetchRequest,
    fetchSuccess,
    updateFilter,
    updatePageInfo,
    clear,
} = performanceFormSlice.actions

export default performanceFormSlice.reducer;
