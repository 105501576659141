import * as fromSlice from "./slice"
import * as fromSelector from "./selector"
import * as fromDashboardStore from "../dashboard"

export const updateFilter = (name: any, value: any) => async (dispatch: any, getState: any) => {
    const state = getState();
    var filter = fromSelector.selectFilter(state)
    dispatch(fromSlice.setFilter({ ...filter, [name]: value }))
    dispatch(fromDashboardStore.fetchTodaySchedule())
    dispatch(fromDashboardStore.fetchPendingSchedule(1, 20))

}

export const clear = () => (dispatch: any, getState: any) => {
    dispatch(fromSlice.clear())
}