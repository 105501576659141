import { ApiClient } from "../../../network/apiClient";
import * as dataStore from "../../../index"
import { performanceRollout, performanceRolloutThreeSixty } from "./api";
import * as fromInterface from "../../../ts";

export const savePerformanceRolloutThreeSixty = (data: fromInterface.PerformanceRolloutThreeSixty) => async (dispatch: any, getState: any) => {
    
    const param = {
        endpoint: performanceRolloutThreeSixty,
        body: data,
        dispatch: dispatch
    }
    const response = await ApiClient.post(param)
  

    return response.Status
}

export const getPerformanceRolloutThreeSixty = (id: number) => async (dispatch: any, getState: any) => {

    const param = {
        endpoint: `${performanceRollout}/${id}`,
        dispatch: dispatch,
        showLoader: true
    }
    const response: any = await ApiClient.get(param)
    return response
}

export const clearPerformanceRolloutThreeSixty = () => (dispatch: any) => {
   
    dispatch(dataStore.clearPerformanceRolloutEmployeeFilter())

}