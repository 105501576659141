// @ts-nocheck 
import React, {useEffect, useState} from "react";
import FormGroup from "components/formGroup";
import FormItem from "components/formGroup/formItem";
import Button from "components/button";
import {useSelector} from "react-redux";
import {useForm} from "react-hook-form";

const CourseCategoryForm = ({
  editViewData,
  handleChange,
  handleSubmit,
  closeForm,
  editView,
}) => {
  const {requesting} = useSelector((state: any) => state.lmsReducer);

  const method = useForm();
  const {setValue} = method;

  useEffect(() => {
    if (editViewData) {
      editView &&
        Object.keys(editViewData).map((item) => {
          console.log({item});
          setValue(item, editViewData[item]);
        });
    }
  }, [editViewData]);

  return (
    <div className="salaryTemplate-form">
      <div className="salaryTemplate-form__header ml-12">Course Category</div>
      <FormGroup method={method} onSubmit={handleSubmit}>
        <div className="salaryTemplate-form__body">
          <div className="form-row">
            <FormItem
              onChange={handleChange}
              label="Category Name"
              name="Name"
              value={editViewData.Name}
              width="500px"
              rules={{required: "Name is required!"}}
            />
          </div>
        </div>
        <div className="salaryTemplate-form__footer flex justify-center">
          <Button bg="highlight" title="Cancel" onClick={closeForm} />
          <Button
            bg="primary"
            title={editView ? "Update" : "Save"}
            isDisabled={requesting}
            htmlButtonType="submit"
          />
        </div>
      </FormGroup>
    </div>
  );
};

export default CourseCategoryForm;
