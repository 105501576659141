// @ts-nocheck
import React, { lazy } from 'react'
import RouteEnum from 'constants/routeEnum'
import roosterLinks from './links'

const Rosters = React.lazy(() => import("scenes/roster"));

const shiftRoster = (features) => [{
    path: RouteEnum.roosterRoutes,
    // exact:true,
    component: Rosters,
    // title: "Rosters",
    feature: features.CoreHR,
    children: roosterLinks,
},
]

export default shiftRoster



