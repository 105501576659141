// @ts-nocheck 
import React from "react";
import {RegularList} from "components/common/regularList";

import CoursePageListItem from "./coursePageListItem";

const CoursePage = ({coursePage}) => {
  if (coursePage.length === 0) {
    return null;
  }

  return (
    <RegularList
      items={coursePage}
      resourceName="coursePage"
      itemComponent={CoursePageListItem}
    />
  );
};

CoursePage.defaultProps = {
  coursePage: [],
};

export default CoursePage;
