export const status = [
  {
    id: 1,
    label: "All",
    value: 1,
  },
  {
    id: 2,
    label: "Not Used",
    value: 2,
  },
  {
    id: 3,
    label: "Rolled out",
    value: 3,
  },
];

export * from "./roles";
export * from "./common";
export * from "./landingView";
export const AuthToken = "AuthToken";
export const RefreshToken = "RefreshToken";
export const IdleTimeOut = "IdleTimeOut";
export const ExpiresMinutes = "ExpiresMinutes";
export const Expires = "Expires";
export const UserDetail = "UserDetail";

export * from "./unified-dashboard";
