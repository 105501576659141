import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
// import axios from "axios";
import { axios } from "./apiClient";

// const axiosClient = axios.create({
//     baseURL: 'https://api.example.com',
//     headers: {
//         'Content-Type': 'application/json',
//     },
// });

export const axiosBaseQuery: any = () => async ({ url, method, data, params }: any) => {
    try {

        if (!axios.axiosInstance) {
            return null;
        }
        const result = await axios.axiosInstance({ url: url, method, data, params });
        /*
        - we always get status 200 from backend
        - instead we get data = {Data: [response data], Status: boolean, Message: string[]} from backend
        - when request fail, this staus is false, and we also get Message on error
        -
        */
        return { data: result.data };
    } catch (axiosError) {
        let err = axiosError as any;
        return {
            error: {
                status: err.response?.status,
                data: err.response?.data || err.message,
            },
        };
    }
};