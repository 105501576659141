import * as fromInterface from "../../../ts";
import * as fromConstant from "../../../constants";
import { ApiClient } from "../../../network/apiClient";
import * as fromSlice from "./slice";
import { ratingScale } from "./api";

export const fetchRatingScale = (page?: any) => async (dispatch: any, getState: any) => {

    
    dispatch(fromSlice.fetchRequest())

    const response: any = await ApiClient.get({
        endpoint: ratingScale,
        dispatch: dispatch
    })

    if (response?.Status) {
        dispatch(fromSlice.fetchSuccess(response?.Data))
    }

    return response

};

export const saveRatingScale = (data?: any, cb?: any): any => async (dispatch: any, getState: any) => {

    const param = {
        endpoint: ratingScale,
        body: {
            ...data,
        },
        dispatch: dispatch
    }
    const response: fromInterface.ResponseWrapper<any> = await ApiClient.post<any>(param)
    const { Data, Status } = response
    if (Status) {
        dispatch(fetchRatingScale())
    }
    cb?.(Status)
};

export const deleteRatingScale = (id: any): any => async (dispatch: any, getState: any) => {

    const param = {
        endpoint: `${ratingScale}/${id}`,
        dispatch: dispatch
    }
    const response: fromInterface.ResponseWrapper<any> = await ApiClient.remove<any>(param)
    const { Data, Status } = response

    if (Status) {
        dispatch(fetchRatingScale())
    }
    return Status
};

export const clearRatingScale = () => (dispatch: any) => {
    dispatch(fromSlice.clear())
};