import React from "react";
import RouteEnum from "../../../constants/routeEnum";

const LeaveTimeDashboard = React.lazy(() => import("scenes/leaveTime"));
const LeaveCommonLinks = React.lazy(
  () => import("scenes/leaveTime/commonTasks"),
);
const LeaveReports = React.lazy(() => import("scenes/leaveTime/reports"));
const LeaveSettings = React.lazy(() => import("scenes/leaveTime/settings"));

const routes = features => [
  {
    path: RouteEnum.LeaveTimeDashboardPath,
    exact: true,
    component: LeaveTimeDashboard,
    isAdmin: true,
    title: "Leave & Time Dashboard",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.LeaveTimeDashboardCommonTaskPath,
    exact: true,
    component: LeaveCommonLinks,
    isAdmin: true,
    title: "Leave & Time Common Task",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.LeaveTimeDashboardReportsPath,
    exact: true,
    component: LeaveReports,
    isAdmin: true,
    title: "Leave & Time Reports",
    feature: features.CoreHR,
  },
  {
    path: RouteEnum.LeaveTimeDashboardSettingsPath,
    exact: true,
    component: LeaveSettings,
    isAdmin: true,
    title: "Leave & Time Settings",
    feature: features.CoreHR,
  },
];

export default routes;
