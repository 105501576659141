import { createSlice } from "@reduxjs/toolkit"

import * as fromInterface from "../../../ts"
import { AprPerformanceFormReviewType } from "../../../enums/appraisal"

export interface ParticipantWeightsState {
  items: any[]
  standardReviewItems: any[]
  threeSixtyItems: any[]
  status: fromInterface.loadingType
}

const initialState: ParticipantWeightsState = {
  items: [],
  standardReviewItems: [],
  threeSixtyItems: [],
  status: fromInterface.STATUS.LOADING,
}

const participantWeightsSlice = createSlice({
  name: "participant weights",
  initialState,
  reducers: {
    fetchRequest: state => {
      state.status = fromInterface.STATUS.LOADING
    },
    fetchSuccess: (state, action) => {
      const payload = action.payload

      state.items = payload
      state.standardReviewItems = payload?.filter(
        (item: any) =>
          item.FormType === AprPerformanceFormReviewType.StandardReview,
      )
      state.threeSixtyItems = payload?.filter(
        (item: any) =>
          item.FormType === AprPerformanceFormReviewType.ThreeSxity,
      )
      state.status = fromInterface.STATUS.IDLE
    },
    fetchComplete: state => {
      state.status = fromInterface.STATUS.IDLE
    },
    clear: state => {
      state.items = []
      state.status = fromInterface.STATUS.IDLE
    },
  },
})

export const { fetchRequest, fetchSuccess, fetchComplete, clear } =
  participantWeightsSlice.actions

export default participantWeightsSlice.reducer
