// @ts-nocheck
import isEmpty from "helpers/isEmpty";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getQuestionDetails,
  getAnswers,
  postAnswers,
} from "scenes/lms/helpers/action";
import {
  EmployeeCourseAssignStatus,
  LMSQuestionTypeEnum,
} from "scenes/lms/helpers/enums";
import Question from "../question";

function QuestionDetails({ pageId, assignedId, course }) {
  const dispatch = useDispatch();
  const [answers, setAnswers] = useState([]);
  const [questionSections, setQuestionSections] = useState([]);
  const {
    questionDetails,
    answers: prevAnswers,
    normalizedAnswers,
  } = useSelector((state: any) => state.lmsReducer);
  const questionSettings = {
    answers,
    prevAnswers,
    setAnswers,
    coursePageId: pageId,
  };

  useEffect(() => {
    setAnswers(prevAnswers || []);
  }, [prevAnswers]);

  useEffect(() => {
    if (questionDetails) {
      let sections = [];
      var sectionIds =
        questionDetails
          ?.filter((x) => x.CourseOutlineId === parseInt(pageId))
          .map((x) => x.Section)
          .filter((val, index, self) => self.indexOf(val) === index) || [];

      for (var i = 0; i < sectionIds.length; i++) {
        let qns = questionDetails
          .filter((x) => x.Section === sectionIds[i])
          .map((x) => ({ ...x }));
        sections.push(qns);
      }
      setQuestionSections(sections);
    }
  }, [questionDetails]);

  useEffect(() => {
    if (pageId) {
      dispatch(getQuestionDetails(pageId));
      dispatch(getAnswers(assignedId, pageId));
    }
  }, [pageId]);

  const isValidOptionSelected = (question, item) => {
    const { AnswerOptions, AnswerLimitFrom, AnswerLimitTo } = question;
    let isValid = true;
    if (AnswerLimitFrom && AnswerLimitTo) {
      for (var i = 0; i < item?.MultipleAnswerRefId?.length; i++) {
        const x = item.MultipleAnswerRefId[i];
        var index = (AnswerOptions.findIndex((opt) => opt.Id == x) || 0) + 1;
        if (index < AnswerLimitFrom || index > AnswerLimitTo) isValid = false;
      }
    }

    return isValid;
  };

  const checkAndGetAnswers = (selectedSectionId) => {
    let valid = true;

    var newAnswers = answers?.map((item) => {
      const question = questionDetails.find((q) => q.Id === item.QuestionRefId);

      let newItem = { ...item };
      newItem["Id"] = 0;
      newItem["AssignedId"] = assignedId;
      const selecteMultipleAnswers = item.MultipleAnswerRefId.length;
      if (question && question?.Section === selectedSectionId) {
        newItem["QuestionType"] = question.QuestionType;

        if (question.QuestionType === LMSQuestionTypeEnum.CheckBox) {
          if (selecteMultipleAnswers === 0) {
            valid = false;
            newItem["errors"] = `Please select at least one option.`;
          } else if (!isValidOptionSelected(question, item)) {
            valid = false;
            newItem[
              "errors"
            ] = `Please choose in between ${question.AnswerLimitFrom} - ${question.AnswerLimitTo} choices.`;
          } else {
            newItem["errors"] = null;
          }
        } else if (
          question.QuestionType === LMSQuestionTypeEnum.LongText ||
          question.QuestionType === LMSQuestionTypeEnum.ShortText
        ) {
          if (isEmpty(item.Answer)) {
            valid = false;
            newItem["errors"] = "Answer is required.";
          } else newItem["errors"] = null;
        } else if (question.QuestionType === LMSQuestionTypeEnum.RadioButton) {
          if (isEmpty(item.AnswerRefId)) {
            valid = false;
            newItem["errors"] = "Please choose at least one option.";
          } else newItem["errors"] = null;
        }
      }
      return newItem;
    });
    setAnswers(newAnswers);
    return valid ? newAnswers : null;
  };
  const onSubmit = async (selectedSectionId) => {
    const answers = checkAndGetAnswers(selectedSectionId);
    const selectedSectionAnswers = answers?.filter(
      (answer) => answer?.Section === selectedSectionId
    );
    // console.log({ selectedSectionAnswers });
    let res;
    if (selectedSectionAnswers)
      res = await dispatch(postAnswers(selectedSectionAnswers));
    res && dispatch(getAnswers(assignedId, pageId));
  };
  if (course.AssignedStatus == 0) return null;

  return (
    <div className="">
      {questionDetails?.length > 0 && (
        <div className="course-page__quiz">
          <div className="course-page__quiz-title">Quiz</div>
          {questionSections.map((questions) => (
            <div>
              {questions.map((item) => (
                <Question {...questionSettings} question={item} />
              ))}
              <div className="course-page__quiz-footer">
                <button
                  className="submit-quiz"
                  onClick={() => onSubmit(questions[0]?.Section ?? "")}
                  type="submit"
                  disabled={
                    course?.AssignedStatus ==
                      EmployeeCourseAssignStatus.Completed ||
                    normalizedAnswers[questions[0]?.Section]?.AssignedId
                  }
                >
                  Submit
                </button>
              </div>
            </div>
          ))}
          {/* <div className="course-page__quiz-footer">
            <button
              className="submit-quiz"
              onClick={() => onSubmit(questions[0]?.SectionOrder ?? "")}
              type="submit"
              disabled={
                course?.AssignedStatus == EmployeeCourseAssignStatus.Completed
              }
            >
              Submit
            </button>
          </div> */}
        </div>
      )}
    </div>
  );
}

export default QuestionDetails;
