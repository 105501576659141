import { ApiClient } from "../../../network/apiClient";
import { reviewFlow, reviewFlowList } from "./api"


export const getReviewFlow = (id: any) => async (dispatch: any, getState: any) => {

    const response: any = await ApiClient.get({
        endpoint: `${reviewFlow}/${id}`,
        dispatch: dispatch,
        showLoader: true
    })

    return response?.Data

}

export const fetchReviewFlowList = (isThreeSixty: boolean) => async (dispatch: any, getState: any) => {

    const response: any = await ApiClient.get({
        endpoint: `${reviewFlowList}/${isThreeSixty}`,
        dispatch: dispatch
    })

    return response?.Data ?? []


}

export const saveReviewFlow = (obj: any) => async (dispatch: any, getState: any) => {

    const response: any = await ApiClient.post({
        endpoint: reviewFlow,
        body: obj,
        dispatch: dispatch
    })

    return response?.Status

}
export const deleteReviewFlow = (id: any) => async (dispatch: any, getState: any) => {

    const response: any = await ApiClient.remove({
        endpoint: `${reviewFlow}/${id}`,

        dispatch: dispatch
    })

    return response?.Status

}
