import {
    GET_GROUP_MEMBER_USER_POOL_OPTIONS,
  } from "actions/types";

const initialState = {
    userGroupMember: [],
    loading:false,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_GROUP_MEMBER_USER_POOL_OPTIONS:
            return{
                ...state,
                userGroupMember:action.payload,
            }
        default:
            return state;
        }
  }
  