import { ApiClient } from "../../../network/apiClient";
import { CLOSE_REVIEW, PMSDashboardInfo } from "./api";
import * as fromModels from "@rigotech/hrms-model";

export const fetchPMSDashboardInfo =
  (isClosed: boolean): any =>
  async (dispatch: any) => {
    const response: fromModels.ResponseWrapper<fromModels.IPMSDashboardItemCollection> =
      await ApiClient.get<any>({
        endpoint: `${PMSDashboardInfo}?isClosed=${isClosed}`,
        dispatch: dispatch,
        showLoader: true,
      });

    return response?.Data ?? [];
  };

export const closeReview =
  ({ FormId, ReviewCycleId }: { FormId: number; ReviewCycleId: number }): any =>
  async (dispatch: any) => {
    return await ApiClient.put<any>({
      endpoint: `${CLOSE_REVIEW}/${FormId}?reviewCycleId=${ReviewCycleId}`,
      dispatch: dispatch,
      showLoader: true,
    });
  };
