// @ts-nocheck 
import React from "react";
import Avatar from "react-avatar";
import moment from "moment";
import {getUploadPath} from "constants/userDetails";
import {Card} from "components/card";
import {Text} from "components/text";
import {COMPLETED, IN_PROGRESS, NOT_STARTED, PRIMARY, WHITE} from "constants/colors";
import Icons from "components/icons";

const EmployeeNameFrame = (props) => {
  const {ImagePath, EmployeeName} = props.row?.original;

  return (
    <div className="flex employee-profile">
      <div className="employee-profile__image">
        <Avatar
          name={EmployeeName}
          size={"28"}
          round={true}
          src={getUploadPath(ImagePath)}
        />
      </div>
      <div className="employee-profile__name">{EmployeeName}</div>
    </div>
  );
};

const StatusChangedOnFrame = (props) => {
  const {StatusChangedOn} = props.row?.original;

  return <span>{StatusChangedOn ? moment(StatusChangedOn).format("ll") : ""}</span>;
};

const ScoreFrame = (props) => {
  const {ObtainedPer, ObtainedScore, TotalScore, Status} = props.row?.original;

  return (
    <div className="flex">
      {Status === 2 ? (
        <div className="course-results__table-obtained">{ObtainedPer}%</div>
      ) : null}
      <div className="course-results__table-total">
        {Status === 2 ? ObtainedScore + " on " + TotalScore : "-"}
      </div>
    </div>
  );
};

const RankFrame = (props) => {
  const {Ranks, Total, Status} = props.row?.original;

  return (
    <div className="flex">
      {Status === 2 ? (
        <Card gap="8px">
          <Card background={PRIMARY} borderRadius="3px" padding="0px 8px">
            <Text color={WHITE} title={Ranks} />
          </Card>
          <Text title={`on ${Total}`} />
        </Card>
      ) : (
        <>-</>
      )}
    </div>
  );
};

const StatusFrame = (props) => {
  const {
    row: {original},
  } = props;

  return (
    <Card
      background={
        original.Status === 0
          ? NOT_STARTED
          : original.Status === 1
          ? IN_PROGRESS
          : COMPLETED
      }
      borderRadius="3px"
      padding="2px 12px"
    >
      <Text color={WHITE} title={props.value} />
    </Card>
  );
};

const ResultsFrame = (props) => {
  const {onReportsClick} = props;
  const {StatusName} = props.row?.original;

  return (
    <Card
      alignItems="center"
      background={StatusName === "Completed" ? PRIMARY : NOT_STARTED}
      borderRadius="3px"
      cursor="pointer"
      gap="8px"
      justifyContent="center"
      handleClick={() => onReportsClick(props.row?.original)}
      padding="2px 12px"
      pointerEvents={StatusName === "Completed" ? "auto" : "none"}
    >
      <Icons color={WHITE} name="BarChart" size={16} />
      <Text color={WHITE} title="Results" />
    </Card>
  );
};

export {
  EmployeeNameFrame,
  StatusChangedOnFrame,
  ScoreFrame,
  RankFrame,
  StatusFrame,
  ResultsFrame,
};
