import { AprPerformanceFormReviewType } from "../../../enums/appraisal";
import { createSlice } from "@reduxjs/toolkit";
import * as fromInterface from "../../../ts";

export interface PeformanceRolloutThreeSixtyFilterState {
    pageIndex: number,
    pageSize: number,
    reviewCycleId?: any,
    employee?: string | null,
    managerId?: any,
    branchId?: any,
    departmentId?: any,
    levelId?: any,
    stat?: any
    formType: number
}
const initialState: PeformanceRolloutThreeSixtyFilterState = {
    pageIndex: 0,
    pageSize: 20,
    reviewCycleId: null,
    employee: "",
    branchId: null,
    departmentId: null,
    levelId: null,
    stat: "",
    formType: AprPerformanceFormReviewType.ThreeSxity
};

const performancRolloutThreeSixtylFilterSlice = createSlice({
    name: "performance rollout 360 filter",
    initialState,
    reducers: {
        udpateFilter: (state, action) => {
            const payload = action.payload
            state = { ...state, ...payload }
            return state

        },
        clear: (state) => {
            state = initialState
            return state;
        },
    },
});

export const {
    udpateFilter,
    clear,
} = performancRolloutThreeSixtylFilterSlice.actions

export default performancRolloutThreeSixtylFilterSlice.reducer;
