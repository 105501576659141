// @ts-nocheck
import isEmpty from "helpers/isEmpty";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";

import {
  CheckClockInOutAuthority,
  SaveAttendanceNote,
  SaveAttendanceTime,
  SaveLunchInNote,
  SaveLunchTime,
} from "../action";
import { useParam } from "./useParam";

export const useAttendance = () => {
  const [isCheckInTimeExist, setIsCheckInTimeExist] = useState(false);
  const [isLunchInTimeExist, setIsLunchInTimeExist] = useState(false);
  const [isLunchOutTimeExist, setIsLunchOutTimeExist] = useState(false);
  const [isCheckInModalOpen, setIsCheckInModalOpen] = useState(false);
  const [isCheckOutBreakOutModalOpen, setIsCheckOutBreakOutModalOpen] =
    useState(false);

  const { param } = useParam();

  const dispatch = useDispatch();
  const attendanceAuthority = useSelector(
    (state: any) => state.attendanceReducer.attendanceAuthority
  );

  const initialize = () => {
    dispatch(CheckClockInOutAuthority());
  };

  // useEffect(() => {
  //   initialize();
  // }, []);

  useEffect(() => {
    if (!isEmpty(attendanceAuthority)) {
      checkLunchInOutTimeExist();
      checkInTimeExist();
    }
  }, [attendanceAuthority]);

  const checkLunchInOutTimeExist = () => {
    let isLunchInTimeExist = false;
    let isLunchOutTimeExist = false;
    if (attendanceAuthority?.LunchIn) {
      isLunchInTimeExist = true;
    }
    if (attendanceAuthority?.LunchOut) {
      isLunchOutTimeExist = true;
    }
    setIsLunchInTimeExist(isLunchInTimeExist);
    setIsLunchOutTimeExist(isLunchOutTimeExist);
  };

  const checkInTimeExist = async () => {
    let isCheckInTimeExist = false;
    if (attendanceAuthority?.CheckIn) {
      isCheckInTimeExist = true;
    }
    setIsCheckInTimeExist(isCheckInTimeExist);
  };

  const handleCheckInModalClose = () => {
    setIsCheckInModalOpen(false);
  };

  const handleCheckoutBreakoutModalClose = () => {
    setIsCheckOutBreakOutModalOpen(false);
  };

  const saveAttendanceNote = (isCheckIn, param) => {
    dispatch(SaveAttendanceNote(isCheckIn, param));
  };

  const saveAttendanceTime = async (isCheckIn: boolean) => {
    let res = await dispatch(SaveAttendanceTime(isCheckIn, param));
    if (res) {
      setIsCheckInModalOpen(false);
      setIsCheckOutBreakOutModalOpen(false);
      initialize();
    }
  };

  const saveLunchInNote = (isBreakIn, param) => {
    dispatch(SaveLunchInNote(isBreakIn, param));
  };

  const saveLunchTime = async (isBreakIn: boolean) => {
    let res = await dispatch(SaveLunchTime(isBreakIn, param));
    if (res) {
      setIsCheckOutBreakOutModalOpen(false);
      initialize();
    }
  };

  const handleClick = async () => {
    if (!isCheckInTimeExist) {
      setIsCheckInModalOpen(true);
    } else if (isLunchInTimeExist && !isLunchOutTimeExist) {
      // ;
      saveLunchTime(false);
    } else {
      setIsCheckOutBreakOutModalOpen(true);
    }
  };

  const getTitle = () => {
    let title = "";
    if (!isCheckInTimeExist) {
      title = "Clock In";
    } else if (isLunchInTimeExist && !isLunchOutTimeExist) {
      title = "Break In";
    } else {
      title = "Clock Out";
    }
    return title;
  };

  return {
    attendanceAuthority,
    checkInTimeExist,
    checkLunchInOutTimeExist,
    getTitle,
    handleCheckInModalClose,
    handleCheckoutBreakoutModalClose,
    handleClick,
    initialize,
    isCheckInModalOpen,
    isCheckOutBreakOutModalOpen,
    isCheckInTimeExist,
    isLunchInTimeExist,
    isLunchOutTimeExist,
    saveAttendanceNote,
    saveAttendanceTime,
    saveLunchInNote,
    saveLunchTime,
  };
};
