import { ApiClient } from '../../../network/apiClient';
import * as fromInterface from '../../../ts';
import { FETCH_SINGLE_EMPLOYEE_ATTENDANCE_REPORT } from './api';
import * as fromSlice from './slice';

export const fetchAttendanceReport =
  (params: { employeeId: number } | any): any =>
  async (dispatch: any, getState: any) => {
    dispatch(fromSlice.fetchRequest());

    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.post<any>({
        endpoint: FETCH_SINGLE_EMPLOYEE_ATTENDANCE_REPORT,
        dispatch: dispatch,
        body: {
          attPeriod: 1,
          pageIndex: 0,
          pageSize: 10,
          employeeId: 2, //params?.employeeId,
          startDate: '2023-08-14T06:41:07.273Z',
          endDate: '2023-08-15T06:41:07.273Z',
        },
      });

    const { Data, Status } = response;

    if (Status) {
      dispatch(fromSlice.fetchSuccess(Data));
    }
    return response;
  };

export const clear = (): any => async (dispatch: any) =>
  dispatch(fromSlice.clear());
