// @ts-nocheck 
import {
    ADD_MESSAGE,
    REMOVE_MESSAGE,
    ADD_MESSAGES,
    ADD_OTHER_MESSAGES,
    CLEAR_MESSAGE,
  } from '../actions/types';
import {CommonAction} from 'ts/interfaces/reduxInterfaces';
import { Action, Reducer } from 'redux';

export interface InitialState {
    messages:object,
    otherMessage:object,
    arrayMessages?:any
}

const initialState:InitialState = {
    messages: {},
    otherMessage:{},
    arrayMessages: [],
};

export const reducer:Reducer<InitialState> = (state:InitialState | undefined,incomingAction:Action):InitialState =>{
    if (state === undefined) {
        return initialState;
    }
    const action = incomingAction as CommonAction;
    switch (action.type) {
        case ADD_MESSAGE:
        return {
            ...state,
            messages: action.payload
        };
        case ADD_MESSAGES:
        return {
            ...state,
            arrayMessages: [action.payload, ...state.arrayMessages]
        };
        case ADD_OTHER_MESSAGES:
        return {
            ...state,
            otherMessage: action.payload
        };
        case REMOVE_MESSAGE:
        return {
            ...state,
            arrayMessages: state.arrayMessages.filter(message => message.id !== action.payload)
        };
        case CLEAR_MESSAGE:
        return {
            ...state,
            messages: {}
        };
        default:
        return state;
    }

}
