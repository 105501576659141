import SvgIcons from "components/svgIcons";
import { getBaseAxios } from "helpers/constants";
import isEmpty from "isEmpty";
import React, { useEffect, useRef, useState } from "react";
import Avatar from "react-avatar";
import ReactTooltip from "react-tooltip";
import {getUploadPath} from 'constants/userDetails'

function EmployeeDetails(props) {
  const { id, empDetails, size, className, showApprovalStatus } = props;
  const employeeText = useRef(null);
  const designationText = useRef(null);
  const [hasOverflowingChildren, setOverflowingChildren] = useState({
    empText: false,
    desgText: false,
  });
  useEffect(() => {
    const employeeEl = employeeText.current;
    const designationEl = designationText.current;

    let hasOverFlowingEmployeeText =
      employeeEl?.offsetHeight < employeeEl?.scrollHeight ||
      employeeEl?.offsetWidth < employeeEl?.scrollWidth;
    let hasOverFlowingDesignationText =
      designationEl?.offsetHeight < designationEl?.scrollHeight ||
      designationEl?.offsetWidth < designationEl?.scrollWidth;
    setOverflowingChildren({
      empText: hasOverFlowingEmployeeText,
      desgText: hasOverFlowingDesignationText,
    });
  }, []);
  //console.log("sss", showApprovalStatus, empDetails)
  return (
    <div
      className={
        isEmpty(className) ? "emp-details" : "emp-details " + className
      }
    >
      <Avatar
        alt={empDetails.FullName}
        name={empDetails.FullName}
        src={getUploadPath(empDetails.Imagepath)}
        className="emp-image"
        round
        size={!isEmpty(size) ? size : 100}
      />
      {showApprovalStatus &&
        (empDetails.ApprovalStatus === "Approved" ? (
          <SvgIcons name="ApprovedCircle" className="approved-icon ml-xsm" />
        ) : (
          empDetails.ApprovalStatus === "Rejected" && (
      
            <SvgIcons name="RejectedCircle" className="rejected-icon ml-xsm" />
          )
        ))}

      <div className="desg-depart">
        {!hasOverflowingChildren.empText ? (
          <span className="emp-name" ref={employeeText}>
            {empDetails?.FullName}
          </span>
        ) : (
          !isEmpty(id) &&
          hasOverflowingChildren.empText && (
            <div>
              <span
                className="emp-name"
                data-tip
                data-for={`employeeName${id}`}
              >
                {empDetails?.FullName}
              </span>
              <ReactTooltip id={`employeeName${id}`} type="info">
                <span>{empDetails?.FullName}</span>
              </ReactTooltip>
            </div>
          )
        )}
        {!hasOverflowingChildren.desgText ? (
          <span className="desg" ref={designationText}>
            {empDetails?.Designation}
          </span>
        ) : (
          !isEmpty(id) &&
          hasOverflowingChildren.desgText && (
            <div>
              <span className="desg" data-tip data-for={`designation${id}`}>
                {empDetails?.Designation}
              </span>
              {/* <ReactTooltip id={`designation${id}`} type="info">
                <span> {empDetails?.Designation}</span>
              </ReactTooltip> */}
            </div>
          )
        )}

        <span className="depart">
          {empDetails?.Department}
          {!isEmpty(empDetails?.Branch) ? ", " + empDetails?.Branch : null}
        </span>
      </div>
    </div>
  );
}

export default EmployeeDetails;
