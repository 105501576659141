// @ts-nocheck 
import * as React from "react";
import classNames from "classnames";
import isEmpty from "isEmpty";
import FormGroup from "components/form";
import EmployeeDetails from "scenes/common/employeeDetails";
import {IoIosCheckmarkCircle, IoIosRadioButtonOff} from "react-icons/io";
import {MdRadioButtonUnchecked, MdCheckCircle} from "react-icons/md";
import {checkdGroupProps} from "ts/interfaces/formInterface";
import {Skeleton} from "@material-ui/lab";

interface IAppProps {
  checkGroupArray: any[];
  checkedGroupValue: any;
  onChange: Function;
  name: string;
  multiple: boolean;
  disabled?: boolean;
  loading: boolean;
  label?: string;
  validators: [];
  hide?: boolean;
}
const renderLoading = (props) => {
  const {loading, loadingType, width} = props;
  if (loadingType === "skeleton") {
    return (
      <div className="form-group mb-sm">
        <Skeleton variant="text" width={75} height={20} />
        <div className="skeleton-card mt-xsm" style={{width: "250px", padding: "5px"}}>
          <div className="flex">
            <Skeleton animation="wave" variant="circle" width={20} height={20} />
            <div className="ml-sm">
              <Skeleton animation="wave" variant="circle" width={40} height={40} />
            </div>

            <div className="ml-sm">
              <Skeleton animation="wave" height={15} width="150px" />
              <Skeleton animation="wave" height={10} width="120px" />
            </div>
          </div>
        </div>
      </div>
    );
  } else if (loadingType === "circle") {
    return <h2>Loading...</h2>;
  } else {
    return <h2>Loading...</h2>;
  }
};
const CheckBoxGroup: React.FunctionComponent<IAppProps> = (props) => {
  const {
    checkGroupArray,
    checkedGroupValue,
    onChange,
    name,
    multiple,
    disabled,
    loading,
    label,
    validators,
    hide,
    ...rest
  } = props;
  const checkBoxChange = (id) => {
    let checkedValue = null;
    if (multiple) {
      let checked = checkedGroupValue.includes(id);
      checkedValue = checkedGroupValue;
      if (checked) {
        checkedValue = checkedValue.filter((x) => x !== id);
      } else {
        checkedValue.push(id);
      }
    } else {
      if (checkedGroupValue !== id) {
        checkedValue = id;
      }
    }
    onChange(name, checkedValue);
  };
  const load = !isEmpty(loading) ? loading : false;
  if (load) {
    return renderLoading(props);
  }
  // console.log({checkGroupArray,checkedGroupValue})

  return (
    <>
      {!isEmpty(checkGroupArray) && (
        <div className="genericForm-group__label">
          <label htmlFor={name} className="genericForm-group__label">
            {label}
            {!isEmpty(validators) && validators.find((f) => f == "required") ? " *" : ""}
          </label>
        </div>
      )}
      {/* <div className="genericForm-group__label">
        <label htmlFor={name} className="genericForm-group__label">
          {label}
          {!isEmpty(validators) && validators.find((f) => f == "required") ? " *" : ""}
        </label>
      </div> */}

      {!isEmpty(checkGroupArray) && (
        <div
          className={classNames({
            "flex items-start checkbox-group": true,
            "flex-row": checkGroupArray?.length <= 2,
            disabled: disabled,
          })}
        >
          {checkGroupArray?.map((item) => {
            return (
              <div
                className={classNames({
                  "flex approver cursor-pointer": true,
                  "row-el": checkGroupArray?.length <= 2,
                })}
                onClick={(event) => !disabled && checkBoxChange(item.Id)}
              >
                {hide ? (
                  <span style={{marginRight: 10}}></span>
                ) : (multiple && checkedGroupValue.includes(item.Id)) ||
                  checkedGroupValue === item.Id ? (
                  <MdCheckCircle className="group-icon checked" />
                ) : (
                  <MdRadioButtonUnchecked className="group-icon" />
                )}
                <EmployeeDetails empDetails={item} size={40} id={item.Id} />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default CheckBoxGroup;
