// @ts-nocheck
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "components/card";
import Icons from "components/icons";
import { Text } from "components/text";
import {
  COMPLETED,
  COURSE_BACKGROUND,
  IN_PROGRESS,
  NOT_STARTED,
} from "constants/colors";
import Button from "components/button";
import { exportFilePost } from "src/actions/commonAction";
import environment from "environment";

import CourseTitle from "./courseTitle";

const SummaryListItem = ({ summaryItem, handleClick }) => {
  const { filterValues } = useSelector((state: any) => state.lmsReducer);

  const dispatch = useDispatch();

  const handleFileExport = () => {
    const params = {
      ...filterValues,
      branchId: filterValues.branchId?.value ?? -1,
    };
    dispatch(exportFilePost(environment.api.v1.lms.resultExport, params));
  };

  return (
    <Card
      background={COURSE_BACKGROUND}
      borderRadius="3px"
      flexDirection={"column"}
      gap="1rem"
      marginTop={"20px"}
      padding="14px 16px"
      width={"100%"}
    >
      <CourseTitle title={summaryItem.CourseTitle} />
      <Card alignItems="center" background={"none"} width="100%">
        <Card
          flex="1"
          alignItems="center"
          borderRadius="3px"
          gap="6px"
          padding="8px 12px"
          margin="0px 8px 0px 0px"
        >
          <Text title={summaryItem.Assigned} fontSize="18px" fontWeight="600" />
          <Text title="assigned" fontSize="15px" />
        </Card>

        <Card
          flex="1"
          alignItems="center"
          borderRadius="3px"
          gap="6px"
          padding="8px 12px"
          margin="0px 8px 0px 0px"
        >
          <Text
            color={COMPLETED}
            title={summaryItem.Completed}
            fontSize="18px"
          />
          <Text title="completed" fontSize="15px" />
        </Card>

        <Card
          flex="1"
          alignItems="center"
          borderRadius="3px"
          gap="6px"
          padding="8px 12px"
          margin="0px 8px 0px 0px"
        >
          <Text
            color={IN_PROGRESS}
            title={summaryItem.InProgress}
            fontSize="18px"
            fontWeight="600"
          />
          <Text title="in progress" fontSize="15px" />
        </Card>

        <Card
          flex="1"
          alignItems="center"
          borderRadius="3px"
          gap="6px"
          padding="8px 12px"
          margin="0px 8px 0px 0px"
        >
          <Text
            color={NOT_STARTED}
            title={summaryItem.NotStarted}
            fontSize="18px"
            fontWeight="600"
          />
          <Text title="not started" fontSize="15px" />
        </Card>

        <Card
          flex="1"
          alignItems="center"
          borderRadius="3px"
          cursor="pointer"
          gap="6px"
          handleClick={() => handleClick(summaryItem.CourseId, 0)}
          padding="8px 12px"
          margin="0px 20px 0px 8px"
        >
          <Icons name="BarChart" size={14} />
          <Text title="Results Summary" fontSize="15px" />
        </Card>
        <Button onClick={handleFileExport} title="Download" bg="primary" />
      </Card>
    </Card>
  );
};

export default SummaryListItem;
