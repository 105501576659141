import * as fromSlice from "./slice";
import * as fromInterface from "./../../../ts";

const selectCurrentLevelSlice = (state: any): fromSlice.LevelState => {
    return state.dataStore.level;
};

export const selectLevelItems = (state: any): any[] => {
    return selectCurrentLevelSlice(state).items;
};

export const selectLevelDropdownItems = (state: any): any[] => {
    return selectLevelItems(state)?.map(item => {
        return {
            label: item.Text,
            value:item.Value
        }
    }) || [];
};

export const selectLevelStatus = (state: any): fromInterface.loadingType => {
    return selectCurrentLevelSlice(state).status;
};

export const selectLevelLoaded = (state: any): boolean => {
    return selectCurrentLevelSlice(state).loaded;
};