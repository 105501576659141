import { Text, TextProps } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';

interface FormErrorLabelProps extends TextProps {
    error?: any
}
export const FormErrorLable = (props: FormErrorLabelProps) => {
    const { error, ...rest } = props;

    if (!isEmpty(error)) {
        return <Text color="red.500" {...rest}>{error}</Text>
    }
    return null
}

export default FormErrorLable