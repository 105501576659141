import * as fromSlice from "./../slice";
import * as fromSelectors from "../selectors"
export const updateFilters = (filters: any) => (dispatch: any, getState: any) => {
    // FIND THE NUMBERINGS
    // 1. GET OLD FILTERS
    // 2. MERGE OLD FILTERS WITH NEW FILTERS
    // 3. OPTIMISTIC UPDATE THE FILTER UI STATE

    const state = getState()
    // 1.
    const oldFilters = fromSelectors.selectFilters(state)

    // 2.
    let newFilters = { ...oldFilters, ...filters }

    // 3.
    dispatch(fromSlice.updateFilters(newFilters))
}