import React from "react";

import { ModalFooter, ModalFooterProps } from "@chakra-ui/react";
interface FooterProps extends ModalFooterProps {
}
export const Footer = ({ children, ...rest }: FooterProps) => {
  return (
    <ModalFooter border="none" {...rest}>
      {children}
    </ModalFooter>
  );
}

export default Footer;
