// @ts-nocheck 
import React, {useState} from "react";
import Modal from "../modal";
import {useSelector} from "react-redux";
import "./profile.styles.scss";
import {UploadProfile} from "components/uploadProfile";

function ProfilePictureChange() {
  const [open, setOpen] = useState(false);
  const user = useSelector((state: any) => state.auth.user);
  const EmpID =
    user.RoleId === 2
      ? user.EmployeeId
      : user.IsEmployeeType
      ? user.EmployeeId
      : user.MappedEmployeeId;
  return (
    <>
      <button onClick={() => setOpen(true)} className="header-change-profile__btn">
        <span>Change Profile Picture</span>
      </button>
      <Modal
        className="profile-change__modal"
        open={open}
        onModalClose={() => setOpen(false)}
      >
        <UploadProfile EmployeeId={EmpID} onModalClose={() => setOpen(false)} />
      </Modal>
    </>
  );
}

export default ProfilePictureChange;
