
import { ApiClient } from "../../network/apiClient";
import * as fromInterface from "../../ts";
import isEmpty from "lodash/isEmpty";
import { commonEmployeeSearch } from "./api"

export const fetchEmployeesForSelect = (input: any) => async (dispatch: any) => {

    if (isEmpty(input) || input?.trim?.().length < 3) {
        return []
    }

    let data = !isEmpty(input) ? `?searchTerm=${input}` : "";
    const response: fromInterface.ResponseWrapper<any> = await ApiClient.get<any>({
        endpoint: `${commonEmployeeSearch}${data}`,
        dispatch: dispatch
    })

    const { Data, Status } = response
    const employees = Data?.map?.((m: any, idx: number) => {
        return {
            ...m,
            label: m.FullName,
            value: m.Empid,
            img: m.Imagepath,
            EmployeeName: m.FullName,
            designation: m.Designation,
        };
    }) || []
    return employees;

}