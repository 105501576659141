import { ApiClient } from '../../../network/apiClient';
import { FETCH_PERFORMANCE_FORM_WEIGHTS } from './api';

// SLICE
import * as fromSlice from './slice';

// interface
import * as fromModel from '@rigotech/hrms-model';
import { selectCurrentFormDetail } from '../current-form-detail';

const validateAll = (data: any) => {
  const EXPECTED_SUM = 100;
  let sectionWeightsSum = 0;
  for (const section of data.Sections) {
    if (!section.Weight || section?.Weight === 0) {
      return false;
    }

    let contentWeightsSum = 0;

    for (const content of section.Contents) {
      if (!content.Weight || content?.Weight === 0) {
        return false;
      }
      contentWeightsSum += content.Weight;
    }

    if (contentWeightsSum !== EXPECTED_SUM) {
      return false;
    }
    sectionWeightsSum += section.Weight;
  }
  if (sectionWeightsSum !== EXPECTED_SUM) {
    return false;
  }
  return true;
};

const filterNullContent = (data: fromModel.IPerformanceFormWeights) => {
  return {
    ...data,
    Sections: data?.Sections?.filter(
      (section: any) => section.Contents?.length !== 0,
    ),
  };
};

// export const isWeightValid = (params: fromModel.IPerformanceFormWeights): boolean => {
//   return false;
// };

/**
 * FETCH PERFORMANCE FORM WEIGHT
 * @param param0
 * @returns
 */
export const fetchPerformanceFormWeights =
  ({ formId }: { formId?: number }) =>
  async (dispatch: any, getState: any) => {
    dispatch(fromSlice.fetchRequest());

    const state = getState();

    const currentFormDetail = selectCurrentFormDetail(state);

    if (!currentFormDetail?.AllowDifferentWeight) {
      return;
    }

    const response: fromModel.ResponseWrapper<fromModel.IPerformanceFormWeights> =
      await ApiClient.get<any>({
        endpoint: FETCH_PERFORMANCE_FORM_WEIGHTS.replace(
          ':formId',
          formId + '',
        ),
        dispatch: dispatch,
      });

    const { Status } = response;

    const Data = response.Data as fromModel.IPerformanceFormWeights;

    if (Status) {
      const filteredData = filterNullContent(Data);
      const isValid = validateAll(filteredData);

      dispatch(
        syncWeights({
          isValid,
          performanceFormWeights: filteredData,
        }),
      );
    }
  };

export const invalidateWeights = () => (dispatch: any) => {
  dispatch(fromSlice.invalidateWeight());
};

export const syncWeights =
  (params: {
    isValid: boolean;
    performanceFormWeights: fromModel.IPerformanceFormWeights;
  }) =>
  (dispatch: any) => {
    dispatch(fromSlice.sync(params));
  };

export const clear = () => (dispatch: any) => {
  dispatch(fromSlice.clear());
};
