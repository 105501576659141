import * as fromSlice from "./slice";

export const toggleLoader = () => (dispatch: any) => {
    dispatch(fromSlice.toggle())
}

export const show = () => (dispatch: any) => {
    dispatch(fromSlice.show())
}

export const hide = () => (dispatch: any) => {
    dispatch(fromSlice.hide())
}

export const clear = () => (dispatch: any) => {
    dispatch(fromSlice.clear())
}