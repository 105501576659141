import * as fromSlice from "./slice"
import * as fromInterface from "../../../ts"

const selectCurrentPeerAffinityLevelSlice = (
  state: any,
): fromSlice.PeerAffinityLevelState => {
  return state.dataStore.peerAffinityLevel
}

export const selectPeerAffinityLevelItems = (state: any): any[] => {
  return selectCurrentPeerAffinityLevelSlice(state).items
}

export const selectPeerAffinityLevelStatus = (
  state: any,
): fromInterface.loadingType => {
  return selectCurrentPeerAffinityLevelSlice(state).status
}
