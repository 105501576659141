import * as fromSlice from "./slice";

export const selectCurrentAdditionalScoreFilterSlice = (state: any): fromSlice.AdditionalScoreFilterState => {
    return state.dataStore.additionalScoreFilter;
};

export const selectCurrentAdditionalScoreFilter = (state: any): fromSlice.AdditionalScoreFilterState => {
    return selectCurrentAdditionalScoreFilterSlice(state);
};

