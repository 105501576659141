import { createSlice } from '@reduxjs/toolkit';
import * as fromInterface from '../../../ts';

export interface EmployeeDataChangeRequestsState {
  items: any[];
  status: fromInterface.loadingType;
  loaded: boolean;
}

const initialState: EmployeeDataChangeRequestsState = {
  items: [],
  status: fromInterface.STATUS.LOADING,
  loaded: false,
};

const employeeDataChangeRequestsSlice = createSlice({
  name: 'employee data change requests',
  initialState,
  reducers: {
    fetchRequest: (state) => {
      state.status = fromInterface.STATUS.LOADING;
    },
    fetchSuccess: (state, action) => {
      const payload = action.payload;
      state.items = payload;
      state.status = fromInterface.STATUS.IDLE;
      state.loaded = true;
    },
    clear: (state) => {
      state.items = [];
      state.status = fromInterface.STATUS.IDLE;
      state.loaded = false;
    },
  },
});

export const { fetchRequest, fetchSuccess, clear } =
  employeeDataChangeRequestsSlice.actions;

export default employeeDataChangeRequestsSlice.reducer;
