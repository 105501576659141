// @ts-nocheck 
import {v1} from 'constants/urls';

const prefix = `${v1}/recruitment/`;

export default {
    recruitment: prefix,
    dashboard: {
        detail: prefix + "jobs/dashboard-detail",
        info: prefix + "dashboard-info",
        jobDetails: prefix + "dashboard-info/job-details",
        jobs: `${prefix}dashboard-info/jobs`
    },
    hiring: {
        stages: prefix + "jobs/hiring-stages",
        // stagesByJobId: prefix + "jobHiringStages",
        stagesByJobId:(jobId) => prefix + `jobs/${jobId}/hiring-stages`,
        subStagesByJobAndStage: prefix + "jobs",
        stageAndSubStagesByJobId: prefix + "jobHiringStageAndSubStages",
        stageChangeDetail: prefix + "hiring-stages-changes-details", //milestone detail
        teams: prefix + "job-hiring-teams",
    },
    candidate: {
        candidates: prefix + "candidates",
        details: prefix + "candidatesDetails",
        selectedCandidateWithDetailsByJobId:
            prefix + "selectedCandidate/candidatesDetails/jobId",
        detailsByJobId: prefix + "candidatesDetails/jobId",
        listByJobAndStage: prefix + "candidatesList/stageId",
        status: prefix + "candidates/Status",
        makeFavAndUnfav: prefix + "candidate/favourite-unfavourite",
        tag: prefix + "candidate/tag",
        multipleStatus: prefix + "candidate/multiple-status",
        moveToNextStage: prefix + "candidate/move-to-next-stage",
        setDeleteStatus: prefix + "candidate/set-delete-status",
        dashboardDetail: prefix + "candidate/recruitment-dashboard-detail",
        undoDelete: prefix + "candidate/undo-reject",
    },
    job: {
        job: prefix + "jobs",
        apply: prefix + "applyJob",
        detail: prefix + "detail",
        number: prefix + "jobs/hr-number",
        scorecard: prefix + "jobs/score-cards",
        applicationTitle: prefix + "job-applicationform-title",
        category: prefix + "jobCategory",
        requisition: prefix + "requisition",
        stepSetting: prefix + "job-steps-setting",
        activeJobs: prefix + "jobs/active",
    },
    requisition: {
        requisition: prefix + "requisition",
        approval: prefix + "requisitions/approval",
        approved: prefix + "requisition/approved",
        approve: prefix + "requisition/approve",
        cancel: prefix + "requisition/cancel",
        my: prefix + "requisitions/my",
        number: prefix + "requisitions/number",
        count: prefix + "requisition/count",
    },
    email: {
        template: prefix + "email-template",
        saveTemplate: prefix + "email-template/save",
        deleteTemplate: prefix + "email-template/delete",
        getScheduledInterviewTemplate:
            prefix + "interview-scheduled-email-template",
    },
    candidateEmail: {
        email: prefix + "email",
        send: prefix + "email/send",
        sendForInterviewSchedule: prefix + "email/send-for-interview-schedule",
    },
    candidateComment: {
        comment: prefix + "comments",
    },
    reject: {
        reasons: prefix + "reject/reasons",
        save: prefix + "reject/save",
    },
    interview: {
        jobInterViewMedium: prefix + "jobs/interview-mediums",
        schedule: prefix + "shedule-candidate-interview",
        getSchedule: prefix + "get-schedule-candidate-interview",
    },
    common: {
        educations: "jp/educations", //Note: no "commonPrefix"
    },
}