import Modal from "components/modal";
import {getBaseAxios} from "helpers/constants";
import isEmpty from "isEmpty";
import React, {Component} from "react";
import {AiFillCloseCircle} from "react-icons/ai";
import getFullStringUrl from "helpers/getFullStringUrl";
import {FaRegFilePdf} from "react-icons/fa";
import {hostedUrl} from "constants/urls";
import {Apis} from "src/config/api";
import {getUploadPath} from "constants/userDetails";
import {connect} from "react-redux";
import {folderNameToSaveFile} from "../../constants";
import {UploadFiles} from "../actions";
const BaseUrl = getBaseAxios() + "/";

export class Create_DocumentsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      modalFile: null,
      docInfo: {},
    };
    this.removeFile = this.removeFile.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
    this.onModalOpen = this.onModalOpen.bind(this);
    this.renderView = this.renderView.bind(this);
  }

  componentDidUpdate() {
    const {isFileUploading} = this.props.documentsState;
    const {isFileUploadSuccess, succesFileInfoList} = this.props;
    if (isFileUploading && isFileUploadSuccess) {
      let fileList = [
        ...succesFileInfoList,
        ...this.props.documentsState.succesFileInfoList,
      ];
      this.props.handleDocumentsTabState &&
        this.props.handleDocumentsTabState("fileList", []);
      this.props.handleDocumentsTabState &&
        this.props.handleDocumentsTabState("succesFileInfoList", fileList);
      this.props.handleDocumentsTabState &&
        this.props.handleDocumentsTabState("isFileUploading", false);
    }
  }

  getDuplicateArray = (array1, array2) => {
    return array1.filter((object1) => {
      return array2.some((object2) => {
        return object1.name === object2.name;
      });
    });
  };

  fileSelectedHandler = (event) => {
    const {fileList} = this.props.documentsState;
    let fileListArr = fileList || [];
    let files = [...event.target.files] || [];
    if (files.length > 0) {
      let duplicateArray = [];
      duplicateArray = [...this.getDuplicateArray(fileListArr, files)];

      if (duplicateArray.length === 0) {
        fileListArr = [...fileListArr, ...files];
      }
    }

    this.props.handleDocumentsTabState &&
      this.props.handleDocumentsTabState("fileList", fileListArr);
    event.target.value = null;
  };

  handleFileUpload = () => {
    const {fileList} = this.props.documentsState;
    this.props.handleDocumentsTabState &&
      this.props.handleDocumentsTabState("isFileUploading", true);

    this.props.UploadFiles({fileList, folderNameToSaveFile});
  };

  removeFile(name, type) {
    const {fileList, succesFileInfoList} = this.props.documentsState;
    let newFiles = fileList;
    if (type === "local") {
      newFiles = fileList.filter((item) => item.name !== name);
    }
    let newSuccesFileInfoList = succesFileInfoList.filter(
      (item) => item.OriginalFileName !== name
    );

    this.props.handleDocumentsTabState &&
      this.props.handleDocumentsTabState("fileList", newFiles);
    this.props.handleDocumentsTabState &&
      this.props.handleDocumentsTabState("succesFileInfoList", newSuccesFileInfoList);
  }
  onModalClose() {
    this.setState({modalOpen: false});
  }
  onModalOpen(info, isLocal) {
    this.setState({modalOpen: true, modalFile: {file: info, isLocal}});
  }
  renderView() {
    const {
      modalFile,
      modalFile: {file, isLocal},
    } = this.state;
    let imgType = ["jpg", "png", "jpeg", "svg"];
    if (isLocal) {
      if (file.type.split(".").pop().includes("image")) {
        return (
          <div className="file-view">
            <img src={URL.createObjectURL(file)} alt="File" />
          </div>
        );
      }
      return (
        <div className="file-view">
          <iframe
            className={"filetype"}
            width="100%"
            height="600"
            frameborder="0"
            src={URL.createObjectURL(file)}
          ></iframe>
        </div>
      );
    } else {
      // let fileDest = getBaseAxios() + file.FileLocation + file.GeneratedFileName;
      let fileDest = getUploadPath(file.FileLocation + file.GeneratedFileName);
      if (imgType.includes(file.GeneratedFileName.split(".").pop())) {
        return (
          <div className="file-view">
            <img src={fileDest} alt="File" />
          </div>
        );
      } else {
        return (
          <div className="file-view">
            <iframe
              className={"filetype"}
              width="100%"
              height="600"
              frameborder="0"
              src={fileDest}
            ></iframe>
          </div>
        );
      }
    }
  }

  downloadTravelFile = (file) => {
    if (file) {
      const param = {
        serverFileName: file.GeneratedFileName.replace("Uploads/", ""),
        userFileName: file.OriginalFileName,
        fileType: file.FileType,
      };
      window.open(getFullStringUrl(`${hostedUrl}${Apis.common.downloadFiles}`, param));
    }
  };
  render() {
    const {fileList, succesFileInfoList} = this.props.documentsState;
    const {isViewMode} = this.props;
    const validImageExtension = ["png", "jpg", "jpeg"];
    return (
      <div className="travel-document-create">
        <div className="travel-create__title">
          <h3>Documents</h3>
        </div>
        <div className="travel-create__body">
          <div className="mt-sm mb-sm flex wd-100 flex-wrap">
            {succesFileInfoList && succesFileInfoList.length > 0
              ? succesFileInfoList.map((file, index) => {
                  return (
                    <div className="flex doc-uploaded pr-sm">
                      {validImageExtension.find(
                        (x) => x === file.OriginalFileName.split(".").pop()
                      ) ? (
                        <a
                          onClick={() => this.onModalOpen(file, false)}
                          className="flex"
                          key={index}
                        >
                          <span className="document-icon">
                            <FaRegFilePdf />
                          </span>
                          <label className="document-name">
                            {!isEmpty(file.OriginalFileName)
                              ? file.OriginalFileName
                              : null}
                          </label>
                        </a>
                      ) : (
                        <abbr
                          onClick={() => this.downloadTravelFile(file)}
                          className="flex"
                          key={index}
                        >
                          <span className="document-icon">
                            <FaRegFilePdf />
                          </span>
                          <label className="document-name">
                            {!isEmpty(file.OriginalFileName)
                              ? file.OriginalFileName
                              : null}
                          </label>
                        </abbr>
                      )}

                      {isViewMode ? null : (
                        <span
                          className="document-icon cancel-icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            this.removeFile(
                              !isEmpty(file.OriginalFileName) ? file.OriginalFileName : ""
                            );
                          }}
                        >
                          <AiFillCloseCircle />
                        </span>
                      )}
                    </div>
                    //</Link>
                  );
                })
              : null}
            {fileList && fileList.length > 0 ? (
              fileList.map((file, index) => {
                let renderModal = false;
                if (
                  file.type.split(".").pop().includes("image") ||
                  file.type.split(".").pop().includes("pdf")
                ) {
                  renderModal = true;
                }
                return (
                  <>
                    {file && !isEmpty(file.name) ? (
                      renderModal ? (
                        <div
                          className="flex pr-sm doc-uploaded flex-wrap"
                          key={index}
                          onClick={() => this.onModalOpen(file, true)}
                        >
                          <span className="document-icon">
                            <FaRegFilePdf />
                          </span>
                          <label className="document-name">
                            {!isEmpty(file.name)
                              ? file.name.split(".")[0] + "." + file.name.split(".")[1]
                              : null}
                            {/* {file.name.split} */}
                          </label>
                          {isViewMode ? null : (
                            <span
                              className="document-icon cancel-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                this.removeFile(
                                  !isEmpty(file.name) ? file.name : "",
                                  "local"
                                );
                              }}
                            >
                              <AiFillCloseCircle />
                            </span>
                          )}
                        </div>
                      ) : (
                        <div className="doc-uploaded flex">
                          <a
                            className="flex flex-wrap"
                            key={index}
                            // onClick={() => URL.createObjectURL(file)}
                            href={URL.createObjectURL(file)}
                            download
                          >
                            <span className="document-icon">
                              <FaRegFilePdf />
                            </span>
                            <label className="document-name">
                              {!isEmpty(file.name)
                                ? file.name.split(".")[0] + "." + file.name.split(".")[1]
                                : null}
                              {/* {file.name.split} */}
                            </label>
                          </a>
                          {isViewMode ? null : (
                            <span
                              className="document-icon cancel-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                this.removeFile(
                                  !isEmpty(file.name) ? file.name : "",
                                  "local"
                                );
                              }}
                            >
                              <AiFillCloseCircle />
                            </span>
                          )}
                        </div>
                      )
                    ) : null}
                  </>
                );
              })
            ) : fileList.length === 0 && succesFileInfoList.length === 0 ? (
              <span className="document-no">No Documents Selected</span>
            ) : null}
          </div>
          <div className="badges-group">
            <div className="upload-btn-wrapper">
              <button className="upload-btn">Add document</button>
              <input
                type="file"
                name="file"
                onChange={this.fileSelectedHandler}
                disabled={isViewMode}
              />
            </div>
            {/* <input
              type="file"
              name="file"
              onChange={this.fileSelectedHandler}
              disabled={isViewMode}
              multiple
            /> */}
          </div>
          <Modal
            open={this.state.modalOpen}
            onModalClose={this.onModalClose}
            title="Documents"
            className="travel-request-document"
          >
            {this.state.modalFile && this.renderView()}
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isFileUploadSuccess: state.travelInformationReducer.isFileUploadSuccess,
  succesFileInfoList: state.travelInformationReducer.succesFileInfoList,
});
const mapDispatchToProps = {
  UploadFiles,
};
export default connect(mapStateToProps, mapDispatchToProps)(Create_DocumentsTab);
