import { SelectorFreeTextPageData } from "@rigotech/hrms-data/src/ts";

/**
 * GET FREE TEXT PAGE DATA BASES ON PROVIDED SECTION
 * @param section
 * @returns
 */
export const selectBaseFreeTextPageData =
  (section: any) =>
  (state: any): SelectorFreeTextPageData => {
    const {
      Id: SectionId,
      PerformanceFormId,
      RatingScaleName,
      SectionName,
      IntroText,
      RatingScaleOptions,
      Contents,
      AssessmentType,
      AllowSelfMarking,
    } = section;

    const reviewDetails = Contents.map(
      ({
        Id,
        Title,
        AllowComments,
        DoNotMark,
        RatingScaleOptionId,
        Text,
        RatingScore,
      }: any) => {
        // let Comments: any = mapComments(SectionId, Id, state, ReviewInfo);

        return {
          SectionId,
          sectionContentId: Id,
          RatingScaleOptionId,
          RatingScore: (RatingScore as number) || 0,
          Text,
          AllowComments,
          Comments: [],

          // selfMarking: {
          //     Text: selfReview?.Sections?.[sectionIdx as number].Contents[contentIdx].Text,
          //     RatingScore: selfReview?.Sections?.[sectionIdx as number].Contents[contentIdx].RatingScore,
          //     RatingScaleOptionId: selfReview?.Sections?.[sectionIdx as number].Contents[contentIdx].RatingScaleOptionId,
          // },

          // REQUIRED TO DISPLAY
          Title,
          DoNotMark,
        };
      },
    );

    const mappedReview: SelectorFreeTextPageData = {
      Id: null, // THIS IS REVIEW ID
      PerformanceFormId,
      PerformanceRolloutId: 0,
      reviewDetails,
      signature: false,

      // REQUIRED TO DISPLAY
      SectionName,
      IntroText,
      RatingScaleName: RatingScaleName as string,
      RatingScaleOptions,
      AssessmentType,
      AllowSelfMarking,
      ReviewType: "",
    };

    return mappedReview;
  };
