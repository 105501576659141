import * as fromSlice from './slice';
import { FINAL_SCORES, FINAL_SCORE_LIST } from './api';
import * as fromInterface from '../../../ts';
import { ApiClient } from '../../../network/apiClient';

export const clearFinalScore =
  (): any => async (dispatch: any, getState: any) => {
    dispatch(fromSlice.clear());
  };

export const fetchFinalScore = () => async (dispatch: any, getState: any) => {
  dispatch(fromSlice.fetchRequest());

  const response: fromInterface.ResponseWrapper<any> = await ApiClient.get<any>(
    {
      endpoint: FINAL_SCORE_LIST,
      dispatch: dispatch,
    }
  );
  const { Data, Status } = response;

  if (Status) {
    dispatch(fromSlice.fetchSuccess(Data));
  }
};

export const saveFinalScore =
  (data?: any, cb?: any): any =>
  async (dispatch: any, getState: any) => {
    const param = {
      endpoint: FINAL_SCORES,
      body: data,
      dispatch: dispatch,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.post<any>(param);
    const { Status } = response;

    if (Status) {
      dispatch(fetchFinalScore());
    }
    cb?.(Status);
  };
