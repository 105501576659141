import React, { useContext } from 'react'
import Container from './container';
import {Box,Text,Image} from "@chakra-ui/react";
import {Button} from "@rigotech/ui";
import playstore from 'src/assets/icons/playstore.svg';
import appstore from 'src/assets/icons/app-store.svg';
import StepperContext,{StepperEnums} from '../context'; 

function Authentication() {
  const values = useContext(StepperContext);
  const onClick = () => {
    values.setActiveStepper(StepperEnums.setUp);
  }
  return (
    <Box>
        <Text fontSize='3xl' mb={"6px"} fontWeight={600} textAlign="center">Add Multi - Factor Authentication</Text>
        <Text fontSize='md' mb={"3px"} textAlign="center">For additional security, we have added multi-factor authentication.</Text>
        <Text fontSize='md' mb={"3px"} textAlign="center">Please setup Authenticator app as per instruction to secure your login.</Text>
        <Container title="Preparation">
            <Box>
                <Text fontSize='sm' mb={"12px"}>To setup multi-factor authentication, you will need</Text>
                <Text fontSize='sm' mb={"6px"}>1. Your Smart Phone</Text>
                <Text fontSize='sm' mb={"6px"}>2. Google Authenticator or similar app installed in your phone.</Text>
                <Text fontSize='sm' mt={"20px"}>Download google authenticator in your phone from the following links</Text>
                <Box className='authenticate-stores-logo'  display="flex" alignItems="center" gap={"16px"} padding='14px 0'  >
                  <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US&pli=1" target="_blank" >
                    <Image src={playstore} h={"50px"} alt="Play Store"  /> 
                  </a>
                  <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank" >
                    <Image src={appstore} h={"50px"} alt="App Store"  /> 
                  </a>
                </Box>
                <Box display="flex" gap="10px" mt="6px" alignItems={"center"}>
                  <Text fontSize='sm' fontWeight={600}>Need Help?</Text>
                  <Button buttonType="link-primary" variant="link" >
                    <Text fontSize='sm' fontWeight={600}>Check this video.</Text>
                  </Button>
                </Box>
            </Box>
        </Container>
        <Box padding="16px" textAlign="center" >
          <Button w="300px" onClick={onClick} buttonType="primary" variant="solid" padding="24px 60px" fontSize={"16px"} fontWeight={400} >Setup Authenticator App</Button>
        </Box>
    </Box>
  )
}

export default Authentication