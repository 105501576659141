import { useState } from "react";

const useScrollRight = (ref: any) => {
  const [scrollRight, setScrollRight] = useState<number>(0);

  const onScroll = (e: any) => {
    setScrollRight(ref?.current?.scrollLeft);
  };

  return { scrollRight, onScroll };
};

export default useScrollRight;
