import { createSlice } from "@reduxjs/toolkit";
import * as fromInterface from "../../../ts";

export interface AdditionalScoreFilterState {

    "reviewCycleId"?: any,
    "formId"?: any,
    "branchId"?: any,
    "departmentId"?: any,
}

const initialState: AdditionalScoreFilterState = {

    "reviewCycleId": null,
    "formId": null,
    "branchId": null,
    "departmentId": null,
};

const additionalScoreFilterSlice = createSlice({
    name: "additional score filter",
    initialState,
    reducers: {
        udpateFilter: (state, action) => {
            const payload = action.payload
            return { ...state, ...payload }
        },
        clear: (state) => {
            return initialState
        },
    },
});

export const {
    udpateFilter,
    clear,
} = additionalScoreFilterSlice.actions

export default additionalScoreFilterSlice.reducer;