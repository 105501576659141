import React, { Component } from 'react';
import {
  DisplayClientSideMessage,
  GetCountryList,
  GetCurrencyList,
  GetDistrictList,
  GetEmployeeList,
} from 'actions/commonAction';
import isEmpty from 'isEmpty';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Button from 'components/button';
import Loader from 'components/loaders';
import { Link } from 'react-router-dom';
import RouteEnum from 'constants/routeEnum';
import { RoleId } from 'constants/userDetails';
import { RiArrowRightSLine } from 'react-icons/ri';
import { EmployeeID } from 'constants/userDetails';
import { Tab, Tabs } from 'components/tabs/index.d';
import { ADMIN_ROLE_ID, TRAVEL_ROLE_ID } from 'constants/constants';
import { Nepali_Date_Format } from 'helpers/dateFormat';
import { PhoneNumberRegexValidation } from 'helpers/regex';
import { ValidationComponent } from 'helpers/getComponents';
import CommonEmployeeSearch from 'components/common/employeeSearch';

import {
  ClearAdvanceAndExpensesList,
  EditTravelRequests,
  GetAdvanceAndExpensesList,
  GetApprovalAuthorities,
  GetApprovalHistory,
  GetTravelCategoryList,
  GetTravelHostAndHotel,
  GetTravelModeOfTransport,
  ResetSelectedAdvanceAndExpenseList,
  RejectTravelRequest,
  SaveAllTravelRequest,
  UpdateApprovalAuthoritiesForEdit,
} from '../actions';
import CreateApproversTab from './createApproversTab';
import CreateDocumentsTab from './createDocumentsTab';
import CreateAdvanceAndExpenses from './createAdvanceAndExpenses';
import CommonCommentsHistory from '../../commonLayout/commentsHistory';
import CreateTravelInformationTab from '../../commonLayout/travelInformationTab';

function createTravelDestination() {
  return {
    selectedDistrict: [],
    placeName: null,
    selectedModeOfTransport: [],
    startDate: null,
    endDate: null,
    startDateEng: null,
    endDateEng: null,
    days: 0,
    nights: 0,
    hostName: null,
    selectedHost: [],
    hotelName: null,
    selectedHotel: [],
    phoneNumber: '',
  };
}

function removeFirstZeroFromInteger(value) {
  // parseInt(PhoneNumber, "10")  //it also works
  if (!isEmpty(value) && value > 0 && value.substr(0, 1) == 0) {
    value = value.substr(1);
  }
  return value;
}
export class CreateNewTravelRequest extends Component {
  constructor(props) {
    super(props);
    let travelCategoryList = props.travelCategoryList
      ? props.travelCategoryList
      : [];
    this.state = {
      TravelInformationDestinationDto: [],
      TravelInformationGroupDto: [],
      TravelInforamtionAllowanceExpenseDetailsDto: [],
      TravelInformationDocumentsDto: [],
      TravelInformationDto: {
        RequestId: 0,
        TravelType: 0,
        DestinationType: 0,
        Reason: null,
        StartDate: null,
        EndDate: null,
        StartDateEng: null,
        EndDateEng: null,
        AdvanceRequestAmt: 0,
        NoteForAdvanceRequest: null,

        LeaveApprovalDelegationId: 0, //done
        IsDelegateLeave: true, //done
        TimeApprovalDelegateId: 0, //done
        IsDelegateTime: true, //done
        TravelApprovalDelegateId: 0, //done
        IsDelegateTravel: true, //done

        CountryCode: 0,
        CurrencyCode: null, //done
        ApprovalEmployeeId: 0,
        StatusId: 0, //save as draft=0 save=1
        IsGroup: 0, //only me=0 group=1
        GroupRequestLinkId: null,
        StatusName: null,
        ApprovedBy: 0,
        ApprovedOn: null,
        ApprovedOnEng: new Date().toJSON(),
        IsHRAssigned: false,
        IsCancelled: false,
        RequestNumber: null,
        RequestedBy: '',
      },

      travelInformationState: {
        travelRequestId: 0,
        travelTypeId: 1,
        destinationTypeId: 1,
        selectedTravelDestList: [
          { key: 0, travelDestination: createTravelDestination() },
        ],
        selectedCountry: null,
        selectedCurrency: null,
        reason: '',
        RequestedOn: '',
        RequestedBy: '',
        StatusId: 0,
        RequestedOnEng: new Date().toJSON(),
        employeeToAssign: null,
      },

      delegationState: {
        selectedSubstitutePerson: null,
        delegationList: [
          {
            ApprovalTypeId: 1,
            ApprovalType: 'Leave Approval',
            SubstituteId: 0,
            SubstituteName: '-',
            IsDelegate: true,
            IsChangeClicked: false,
          },
          {
            ApprovalTypeId: 2,
            ApprovalType: 'Travel Approval',
            SubstituteId: 0,
            SubstituteName: '-',
            IsDelegate: true,
            IsChangeClicked: false,
          },
          {
            ApprovalTypeId: 3,
            ApprovalType: 'Time Approval',
            SubstituteId: 0,
            SubstituteName: '-',
            IsDelegate: true,
            IsChangeClicked: false,
          },
        ],
        isSubstituteSelected: true,
      },

      groupState: {
        groupTypeId: 1,
        selectedTravellerList: [],
      },

      advanceAndExpensesState: {
        selectedTravelCategory: null,
        advanceRequestAmount: 0,
        note: '',
      },

      documentsState: {
        fileList: [],
        succesFileInfoList: [],
        isFileUploading: false,
      },

      approverState: {
        selectedApprovalList: [],
        selectedContributorList: [],
      },

      create_tabs: [
        {
          label: 'Travel Information',
          id: 0,
          component: CreateTravelInformationTab,
        },
        {
          label: 'Advance & Expenses',
          id: 1,
          component: CreateAdvanceAndExpenses,
        },
        { label: 'Documents', id: 2, component: CreateDocumentsTab },
        { label: 'Approval', id: 3, component: CreateApproversTab },
      ],

      selectedCreateTabId: 0,
      isAllRequiredFieldValid: true,
      isEditPage: false,
      isViewPage: false,
      isEditDataFetched: false,
      submitStatusId: 1,
      RequestRef_Id: 0,
      selectedEmployee: { error: '', value: 0 },
      selectedEmployeeId: 0,
      isModalShow: false,
      roleId: null,
      isLoading: false,
      travelCategoryList,
    };
    this.commonComments = '';
    this.handleEscape = this.handleEscape.bind(this);
  }

  async componentDidMount() {
    await this.initialize();

    let selectedTabId = null;
    let isAssign = false;
    let isEditPage = false;
    let isViewPage = false;
    let travelRequestId = 0;
    let requestDetails = '';
    if (this.props.location && !isEmpty(this.props.location.state)) {
      //for same tab view
      selectedTabId = this.props.location.state.selectedTabId || null;
      isAssign = this.props.location.state.isAssign;
      isEditPage = !isEmpty(this.props.location.state.isEditPage)
        ? this.props.location.state.isEditPage
        : false;
      isViewPage = !isEmpty(this.props.location.state.isViewPage)
        ? this.props.location.state.isViewPage
        : false;
      travelRequestId = !isEmpty(this.props.location.state.travelRequestId)
        ? this.props.location.state.travelRequestId
        : 0;
      requestDetails = this.props.location.state.requestDetails;
    } else if (localStorage.travelRequestDataToNewTab) {
      //for new tab view
      let travelRequestDataToNewTab = JSON.parse(
        localStorage.travelRequestDataToNewTab,
      );

      selectedTabId = travelRequestDataToNewTab.selectedTabId || null;
      isAssign = travelRequestDataToNewTab.isAssign || false;
      isEditPage = travelRequestDataToNewTab.isEditPage || false;
      isViewPage = travelRequestDataToNewTab.isViewPage || false;
      travelRequestId = travelRequestDataToNewTab.travelRequestId || 0;
      requestDetails = travelRequestDataToNewTab.requestDetails || 0;
    } else {
    }

    const roleId = RoleId();
    const { GetTravelCategoryList } = this.props;

    this.setState(
      {
        selectedTabId,
        isAssign,
        isEditPage,
        isViewPage,
        travelRequestId,
        roleId,
      },
      async () => {
        const empId = EmployeeID();
        if (!isAssign)
          await this.getApproverDetails(
            travelRequestId,
            this.state.submitStatusId,
            empId,
          );
        if (isEditPage || isViewPage) {
          this.setState({ requestDetails });
          var travelInfo = await this.props.EditTravelRequests(travelRequestId);
          var travelType = travelInfo?.TravelInformationDto?.TravelType || 1;
          GetTravelCategoryList(travelType);
          this.setState({ isEditDataFetched: true });
        } else {
          this.setState({ isLoading: false });
          GetTravelCategoryList(1);
        }
      },
    );
  }

  componentWillUnmount() {
    // document.removeEventListener("keyup", this.handleEscape);
  }

  handleEscape(e) {
    if (e.key === 'Escape') {
      this.setState({ isModalShow: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let {
      isEditPage,
      isViewPage,
      travelInformationState,
      delegationState,
      groupState,
      advanceAndExpensesState,
      documentsState,
      approverState,
      travelRequestId,
      requestDetails,
    } = this.state;

    const {
      countryList,
      currencyList,
      districtList,
      modeOfTransportList,
      travelCategoryList,
      travelHostList,
      travelHotelList,
      travelInformationToEdit,
    } = this.props;
    const employeeListWithPhoto = this.props.employeeListWithPhoto;

    if (
      prevProps.travelInformationToEdit !== this.props.travelInformationToEdit
    ) {
      if (
        (isEditPage || isViewPage) &&
        travelRequestId ===
          travelInformationToEdit?.TravelInformationDto?.RequestId
      ) {
        this.setState({ isEditDataFetched: true });

        let TravelInformationDtoToEdit =
          travelInformationToEdit.TravelInformationDto;
        let TravelInformationDestinationDtoToEdit =
          travelInformationToEdit.TravelInformationDestinationDto;
        let TravelInformationGroupDtoToEdit =
          travelInformationToEdit.TravelInformationGroupDto;
        let TravelInforamtionAllowanceExpenseDetailsDtoToEdit =
          travelInformationToEdit.TravelInformationAllowanceExpenseDetailsDto;
        let TravelInformationDocumentsDtoToEdit =
          travelInformationToEdit.TravelAttachment;

        //Travel Information Tab starts
        let selectedCountry = countryList.find(
          f => f.value == TravelInformationDtoToEdit.CountryId,
        );
        let selectedCurrency = currencyList.find(
          f => f.label == TravelInformationDtoToEdit.CurrencyCode,
        );

        let newSelectedTravelDestList = [];
        TravelInformationDestinationDtoToEdit.map((destination, index) => {
          let selectedDistrict = districtList.find(
            f => f.value == destination.DistrictRefId,
            // f => f.value == 4
          );

          let TransportModeIdList = destination.TransportModeRefId.split(',');
          // let TransportModeIdList = ("1,2").split(",");
          let selectedModeOfTransport = [];

          if (modeOfTransportList && modeOfTransportList.length > 0) {
            TransportModeIdList.map(tm => {
              let currentModeOfTransport = modeOfTransportList.find(
                f => f.value == tm,
              );
              if (!isEmpty(currentModeOfTransport)) {
                selectedModeOfTransport.push(currentModeOfTransport);
              }
            });
          }

          let selectedHost = travelHostList.find(
            f => f.label == destination.HostName,
          );

          let selectedHotel = travelHotelList.find(
            f => f.label == destination.HotelName,
          );

          let newTravelDestination = {
            selectedDistrict: !isEmpty(selectedDistrict)
              ? selectedDistrict
              : [],
            placeName: destination.PlaceName,
            selectedModeOfTransport: selectedModeOfTransport,
            startDate: Nepali_Date_Format(destination.StartDate),
            endDate: Nepali_Date_Format(destination.EndDate),
            startDateEng: Nepali_Date_Format(destination.StartDateEng),
            endDateEng: Nepali_Date_Format(destination.EndDateEng),
            days: destination.TransportDays,
            nights: destination.TransportNights,
            hostName: destination.HostName,
            selectedHost: !isEmpty(selectedHost) ? selectedHost : [],
            hotelName: destination.HotelName,
            selectedHotel: !isEmpty(selectedHotel) ? selectedHotel : [],
            phoneNumber: destination.PhoneNo,
          };
          newSelectedTravelDestList.push({
            key: index,
            travelDestination: newTravelDestination,
          });
        });

        this.getOverAllDates(newSelectedTravelDestList);
        // debugger;
        travelInformationState['travelRequestId'] =
          TravelInformationDtoToEdit.RequestId;
        // travelInformationState["RequestedOn"] = TravelInformationDtoToEdit.RequestedOn;
        travelInformationState['RequestedOn'] = Nepali_Date_Format(new Date());
        travelInformationState['RequestedOnEng'] = Nepali_Date_Format(
          new Date(),
        );
        travelInformationState['travelTypeId'] =
          TravelInformationDtoToEdit.TravelType;
        travelInformationState['destinationTypeId'] =
          TravelInformationDtoToEdit.DestinationType;
        travelInformationState['selectedCountry'] = !isEmpty(selectedCountry)
          ? selectedCountry
          : [];
        travelInformationState['selectedCurrency'] = !isEmpty(selectedCurrency)
          ? selectedCurrency
          : [];
        travelInformationState['reason'] = TravelInformationDtoToEdit.Reason;
        travelInformationState['selectedTravelDestList'] =
          newSelectedTravelDestList;
        travelInformationState['RequestedBy'] =
          TravelInformationDtoToEdit.RequestedBy;
        travelInformationState['StatusId'] =
          TravelInformationDtoToEdit.StatusId;

        //Travel Information Tab ends

        // Delegation Tab Starts

        let newDelegateList = [];

        delegationState.delegationList.map((m, i) => {
          if (m.ApprovalType == 'Leave Approval') {
            let nameToFill = '-';
            let selectedEmployee =
              employeeListWithPhoto &&
              employeeListWithPhoto.find(
                f =>
                  f.value ==
                  TravelInformationDtoToEdit.LeaveApprovalDelegationId,
              );
            if (!isEmpty(selectedEmployee)) {
              nameToFill = selectedEmployee.label;
            }
            m.SubstituteId =
              TravelInformationDtoToEdit.LeaveApprovalDelegationId;
            m.SubstituteName = nameToFill;
            m.IsDelegate = TravelInformationDtoToEdit.IsDelegateLeave;
          } else if (m.ApprovalType == 'Travel Approval') {
            let nameToFill = '-';
            let selectedEmployee =
              employeeListWithPhoto &&
              employeeListWithPhoto.find(
                f =>
                  f.value ==
                  TravelInformationDtoToEdit.TravelApprovalDelegateId,
              );
            if (!isEmpty(selectedEmployee)) {
              nameToFill = selectedEmployee.label;
            }
            m.SubstituteId =
              TravelInformationDtoToEdit.TravelApprovalDelegateId;
            m.SubstituteName = nameToFill;
            m.IsDelegate = TravelInformationDtoToEdit.IsDelegateTravel;
          } else if (m.ApprovalType == 'Time Approval') {
            let nameToFill = '-';
            let selectedEmployee =
              employeeListWithPhoto &&
              employeeListWithPhoto.find(
                f =>
                  f.value == TravelInformationDtoToEdit.TimeApprovalDelegateId,
              );
            if (!isEmpty(selectedEmployee)) {
              nameToFill = selectedEmployee.label;
            }
            m.SubstituteId = TravelInformationDtoToEdit.TimeApprovalDelegateId;
            m.SubstituteName = nameToFill;
            m.IsDelegate = TravelInformationDtoToEdit.IsDelegateTime;
          }

          newDelegateList.push(m);
        });

        delegationState['delegationList'] = newDelegateList;

        //Delegation Tab Ends

        //Group Tab Starts
        let newSelectedTravellerList = [];
        let isGroup = false;
        TravelInformationGroupDtoToEdit.map((m, i) => {
          isGroup = m.IsGroup;
          let selectedEmployeeListWithPhoto =
            employeeListWithPhoto &&
            employeeListWithPhoto.find(f => f.value == m.EmployeeId);
          if (!isEmpty(selectedEmployeeListWithPhoto)) {
            newSelectedTravellerList.push(selectedEmployeeListWithPhoto);
          }
        });
        let requesterInfo =
          employeeListWithPhoto &&
          employeeListWithPhoto.find(
            f => f.value == TravelInformationDtoToEdit.RequestedBy,
          );
        groupState['groupTypeId'] = isGroup ? 2 : 1;
        groupState['selectedTravellerList'] = newSelectedTravellerList;
        groupState['requesterInformation'] = requesterInfo;
        //Group Tab Ends

        //AdvanceAndExpenses Tab starts
        const { overAllDestinationFromDate, overAllDestinationToDate } =
          this.state;

        let categoryId = 0;
        if (TravelInforamtionAllowanceExpenseDetailsDtoToEdit.length > 0) {
          const firstDate =
            TravelInforamtionAllowanceExpenseDetailsDtoToEdit[0];
          categoryId = firstDate.CategoryRefId;
        }

        let travelCategory = travelCategoryList.find(
          f => f.value == categoryId,
        );
        if (
          !isEmpty(overAllDestinationFromDate) &&
          !isEmpty(overAllDestinationToDate) &&
          categoryId !== 0
        ) {
          const fromDate = overAllDestinationFromDate;
          const toDate = overAllDestinationToDate;
          this.props.GetAdvanceAndExpensesList({
            categoryId,
            overAllDestinationFromDate: fromDate,
            overAllDestinationToDate: toDate,
          });
        }
        advanceAndExpensesState['selectedTravelCategory'] = !isEmpty(
          travelCategory,
        )
          ? travelCategory
          : null;

        advanceAndExpensesState['advanceRequestAmount'] =
          TravelInformationDtoToEdit.AdvanceRequestAmt;
        advanceAndExpensesState['note'] =
          TravelInformationDtoToEdit.NoteForAdvanceRequest;

        //AdvanceAndExpenses Tab ends

        //Document section starts

        let newSuccesFileInfoList = [];
        TravelInformationDocumentsDtoToEdit.map((m, i) => {
          newSuccesFileInfoList.push({
            AttachmentId: m.AttachmentId,
            OriginalFileName: m.UserFileName,
            GeneratedFileName: m.ServerFileName,
            FileLocation: m.FileLocation,
            DocumentPath: m.FileLocation,
            Size: m.Size,
            FileType: m.FileType,
          });
        });

        documentsState['succesFileInfoList'] = newSuccesFileInfoList;

        //Document section ends

        let statusId =
          isEmpty(TravelInformationDtoToEdit.StatusId) ||
          (!isEmpty(requestDetails) && requestDetails?.update)
            ? 1
            : TravelInformationDtoToEdit.StatusId;
        this.setState({
          travelInformationState,
          delegationState,
          groupState,
          advanceAndExpensesState,
          documentsState,
          // approverState,
          submitStatusId: statusId,
          RequestRef_Id: TravelInformationDtoToEdit.RequestId,
          isLoading: false,
        });
        const empId = travelInformationState?.RequestedBy
          ? travelInformationState.RequestedBy
          : EmployeeID();

        this.getApproverDetails(
          this.state.travelRequestId,
          statusId,
          this.state.isAssign ? 0 : empId,
        );

        if (travelInformationToEdit?.TravelInformationDto?.StatusId !== 0) {
          this.updateApprovalAuthoritiesForEdit();
        }
      }
    }

    if (prevProps.travelCategoryList !== this.props.travelCategoryList) {
      if ((isEditPage || isViewPage) && travelInformationToEdit) {
        let TravelInforamtionAllowanceExpenseDetailsDtoToEdit =
          travelInformationToEdit?.TravelInformationAllowanceExpenseDetailsDto;
        let categoryId = 0;
        if (TravelInforamtionAllowanceExpenseDetailsDtoToEdit?.length > 0) {
          const firstDate =
            TravelInforamtionAllowanceExpenseDetailsDtoToEdit[0];
          categoryId = firstDate?.CategoryRefId;
        }

        let travelCategory = travelCategoryList.find(
          f => f.value == categoryId,
        );
        advanceAndExpensesState['selectedTravelCategory'] = !isEmpty(
          travelCategory,
        )
          ? travelCategory
          : [];
        this.setState({ advanceAndExpensesState });
      }
    }

    if (
      prevProps.approvalAuthorities.value !==
      this.props.approvalAuthorities.value
    ) {
      const { value } = this.props.approvalAuthorities;
      const { travelInformationToEdit } = this.props;

      if (
        !value?.IsFinalApprover &&
        value?.AuthoritiesList &&
        value?.AuthoritiesList.length === 0 &&
        this.state.submitStatusId === 1
      ) {
        const empId = EmployeeID();
        this.props.GetApprovalAuthorities(this.state.submitStatusId + 1, empId);
        this.setState(prevState => ({
          ...prevState,
          submitStatusId: prevState.submitStatusId + 1,
        }));
      }

      //Approver section starts
      const { approverState } = this.state;
      let TravelInformationDtoToEdit =
        travelInformationToEdit &&
        travelInformationToEdit?.TravelInformationDto;
      let { selectedApprovalList } = approverState;

      if (!isEmpty(this.props.approvalAuthorities.value)) {
        let selectedApproval =
          this.props.approvalAuthorities.value.AuthoritiesList.find(
            f => f.value === TravelInformationDtoToEdit?.ApprovalEmployeeId,
          );
        if (selectedApproval) {
          const existingObj = selectedApprovalList?.find(
            item => item?.Value === selectedApproval?.Value,
          );
          if (!existingObj) {
            selectedApprovalList.push(selectedApproval);
          }
        }

        approverState['selectedApprovalList'] = selectedApprovalList;
        // console.log({
        //   approvalAuthorities: this.props.approvalAuthorities,
        //   approverState,
        //   selectedApprovalList,
        //   selectedApproval,
        //   TravelInformationDtoToEdit,
        // });
      }
      //Approver section ends
    }
  }

  getApproverDetails = async (requestId, statusId, empId) => {
    const { isEditPage } = this.state;
    const {
      GetApprovalAuthorities,
      GetApprovalHistory,
      travelInformationToEdit,
    } = this.props;
    await GetApprovalAuthorities(statusId, empId ? empId : 0);
    await GetApprovalHistory(requestId);
    if (
      !this.props.approvalAuthorities.loading &&
      isEmpty(this.props.approvalAuthorities.value) &&
      (this.state.roleId === ADMIN_ROLE_ID ||
        this.state.roleId === TRAVEL_ROLE_ID)
    ) {
      this.setState({ submitStatusId: 15 });
    }
    if (
      isEditPage &&
      travelInformationToEdit?.TravelInformationDto?.StatusId !== 0
    ) {
      this.updateApprovalAuthoritiesForEdit();
    }
  };

  updateApprovalAuthoritiesForEdit = () => {
    // debugger;
    const {
      approvalAuthorities,
      travelInformationToEdit,
      UpdateApprovalAuthoritiesForEdit,
    } = this.props;

    const authoritiesList = [
      {
        Selected: false,
        Text:
          travelInformationToEdit?.TravelInformationDto?.ApprovalEmployeeName ??
          '',
        Value:
          travelInformationToEdit?.TravelInformationDto?.ApprovalEmployeeId ??
          0,
        label:
          travelInformationToEdit?.TravelInformationDto?.ApprovalEmployeeName ??
          '',
        value:
          travelInformationToEdit?.TravelInformationDto?.ApprovalEmployeeId ??
          0,
      },
    ];

    const payload = {
      AuthoritiesList: authoritiesList,
      IsFinalApprover: approvalAuthorities.value?.IsFinalApprover,
    };

    UpdateApprovalAuthoritiesForEdit(payload);
  };

  initialize = async () => {
    const {
      ClearAdvanceAndExpensesList,
      GetCountryList,
      GetCurrencyList,
      GetDistrictList,
      GetTravelModeOfTransport,
      GetTravelHostAndHotel,
      // GetEmployeeList,
      // GetTravelCategoryList,
    } = this.props;
    let ajaxApi = [
      ClearAdvanceAndExpensesList(),
      GetCountryList(),
      GetCurrencyList(),
      GetDistrictList(),
      GetTravelModeOfTransport(),
      GetTravelHostAndHotel(),
      // GetEmployeeList(),
      // GetTravelCategoryList(1),
    ];
    const roleId = RoleId();

    // if (roleId === ADMIN_ROLE_ID)
    Promise.all(
      ajaxApi.map(async item => {
        return await item;
      }),
    );
  };

  getOverAllDates = selectedTravelDestList => {
    let overAllDestinationFromDate = null;
    let overAllDestinationToDate = null;
    if (!isEmpty(selectedTravelDestList)) {
      let selectedTravelDestLength = selectedTravelDestList.length;

      overAllDestinationFromDate = !isEmpty(
        selectedTravelDestList[0].travelDestination.startDateEng,
      )
        ? selectedTravelDestList[0].travelDestination.startDateEng
        : null;
      overAllDestinationToDate = !isEmpty(
        selectedTravelDestList[selectedTravelDestLength - 1].travelDestination
          .endDateEng,
      )
        ? selectedTravelDestList[selectedTravelDestLength - 1].travelDestination
            .endDateEng
        : null;
    }

    this.setState({
      overAllDestinationFromDate,
      overAllDestinationToDate,
    });
  };

  handleTravelInfoTabState = (name, value, isDestinationDateChanged) => {
    let { travelInformationState } = this.state;
    travelInformationState[name] = value;
    this.setState({ travelInformationState });
    if (isDestinationDateChanged) {
      this.getOverAllDates(value);
    }
  };

  handleDelegationTabState = (name, value) => {
    let { delegationState } = this.state;
    delegationState[name] = value;
    this.setState({ delegationState });
  };

  handleGroupTabState = (name, value) => {
    let { groupState } = this.state;
    groupState[name] = value;
    this.setState({ groupState });
  };

  handleAdvanceAndExpensesTabState = (name, value) => {
    let { advanceAndExpensesState } = this.state;
    if (name == 'advanceRequestAmount') {
      value = removeFirstZeroFromInteger(value);
    }
    advanceAndExpensesState[name] = value;
    this.setState({ advanceAndExpensesState });
  };

  handleDocumentsTabState = (name, value) => {
    this.setState(prevState => ({
      ...prevState,
      documentsState: {
        ...prevState.documentsState,
        [name]: value,
      },
    }));
  };

  handleApproversTabState = (name, value) => {
    let { approverState } = this.state;
    approverState[name] = value;
    this.setState({ approverState: approverState });
  };

  manageTravelInformationTabData = async (statusId, id) => {
    let {
      TravelInformationDto,
      travelInformationState,
      overAllDestinationFromDate,
      overAllDestinationToDate,
      RequestRef_Id,
      selectedEmployee,
      submitStatusId,
      isAssign,
      advanceAndExpensesState,
      isEditPage,
    } = this.state;
    let TravelInformationDestinationDto = [];
    let isAllRequiredFieldValid = false;
    let showMessage = false;
    if (id == 4) {
      if (!isEmpty(advanceAndExpensesState)) {
        const { selectedTravelCategory } = advanceAndExpensesState;
        if (isEmpty(selectedTravelCategory)) showMessage = true;
      } else showMessage = true;
    }
    if (showMessage) {
      const { travelCategoryList } = this.props;
      if (isEmpty(travelCategoryList)) {
        let message = {
          MessageType: 'danger',
          Message:
            'Travel Configuration Rules are not complete, Please talk to your HR Department',
        };
        this.props.DisplayClientSideMessage(message);
      }
      return showMessage;
    }
    if (!isEmpty(travelInformationState)) {
      const {
        travelRequestId,
        RequestedOn,
        RequestedOnEng,
        selectedTravelDestList,
        travelTypeId,
        selectedCountry,
        selectedCurrency,
        destinationTypeId,
        reason,
        employeeToAssign,
        RequestedBy,
      } = travelInformationState;

      let countryId = 0;
      let currencyCode = '';
      if (travelTypeId == 2) {
        if (!isEmpty(selectedCountry)) {
          countryId = selectedCountry.value;
        }

        if (!isEmpty(selectedCurrency)) {
          currencyCode = selectedCurrency.label;
        }
      }

      //Fill TravelInformationDto data section starts
      TravelInformationDto.RequestId = travelRequestId;
      TravelInformationDto.RequestedOn = RequestedOn;
      TravelInformationDto.RequestedOnEng = RequestedOnEng;
      TravelInformationDto.StatusId = statusId;
      TravelInformationDto.TravelType = travelTypeId;
      TravelInformationDto.CountryId = countryId;
      TravelInformationDto.CurrencyCode = currencyCode;
      TravelInformationDto.DestinationType = destinationTypeId;
      TravelInformationDto.Reason = !isEmpty(reason) ? reason : null;
      TravelInformationDto.StartDate = overAllDestinationFromDate;
      TravelInformationDto.StartDateEng = overAllDestinationFromDate;
      TravelInformationDto.EndDate = overAllDestinationToDate;
      TravelInformationDto.EndDateEng = overAllDestinationToDate;
      TravelInformationDto.RequestedBy = isEditPage ? RequestedBy : 0;

      (await selectedTravelDestList) &&
        selectedTravelDestList.map(data => {
          let destination = data.travelDestination;

          const districtId =
            travelTypeId === 1 && !isEmpty(destination.selectedDistrict)
              ? destination.selectedDistrict.value
              : null;

          let transferModes = '';
          destination.selectedModeOfTransport.map((mode, index) => {
            if (index > 0) {
              transferModes = transferModes + ',' + mode.value;
            } else {
              transferModes = mode.value;
            }
          });

          if (
            ((travelTypeId === 2 &&
              !isEmpty(selectedCountry) &&
              !isEmpty(selectedCurrency)) ||
              districtId > 0) &&
            !isEmpty(destination.placeName) &&
            !isEmpty(transferModes) &&
            !isEmpty(destination.startDateEng) &&
            !isEmpty(destination.endDateEng) &&
            !isEmpty(TravelInformationDto.Reason) &&
            ((isEmpty(destination.hostName) &&
              isEmpty(destination.hotelName) &&
              isEmpty(destination.phoneNumber)) ||
              ((!isEmpty(destination.hostName) ||
                !isEmpty(destination.hotelName)) &&
                !isEmpty(destination.phoneNumber) &&
                PhoneNumberRegexValidation(destination.phoneNumber)))
          ) {
            isAllRequiredFieldValid = true;

            TravelInformationDestinationDto.push({
              RequestRefId: RequestRef_Id,
              DistrictRefId: districtId,
              PlaceName: destination.placeName,
              TransportModeRefId: transferModes,
              StartDate: destination.startDate,
              StartDateEng: destination.startDateEng,
              EndDate: destination.endDate,
              EndDateEng: destination.endDateEng,
              TransportDays: destination.days,
              TransportNights: destination.nights,
              HostName: destination.hostName,
              HotelName: destination.hotelName,
              PhoneNo: destination.phoneNumber,
            });
          } else {
            isAllRequiredFieldValid = false;
          }
        });
    }

    if (
      (this.state.roleId === ADMIN_ROLE_ID ||
        this.state.roleId === TRAVEL_ROLE_ID) &&
      !this.state.isViewPage &&
      !this.state.isEditPage &&
      selectedEmployee.value === 0
    ) {
      isAllRequiredFieldValid = false;
    }
    this.setState(prevState => ({
      TravelInformationDestinationDto: TravelInformationDestinationDto,
      TravelInformationDto: TravelInformationDto,
      isAllRequiredFieldValid: isAllRequiredFieldValid,
      selectedEmployee: {
        ...prevState.selectedEmployee,
        error: selectedEmployee.value === 0 ? 'Required' : '',
      },
    }));
  };

  manageDelegateTabData = async () => {
    let { delegationState, TravelInformationDto } = this.state;

    if (!isEmpty(delegationState)) {
      const { delegationList } = delegationState;

      let IsDelegateLeave = true;
      let LeaveApprovalDelegationId = 0;
      let IsDelegateTravel = true;
      let TravelApprovalDelegateId = 0;
      let IsDelegateTime = true;
      let TimeApprovalDelegateId = 0;

      (await delegationList) &&
        delegationList.map(m => {
          if (m.ApprovalType === 'Leave Approval') {
            IsDelegateLeave = m.IsDelegate;
            LeaveApprovalDelegationId = m.SubstituteId;
          } else if (m.ApprovalType === 'Travel Approval') {
            IsDelegateTravel = m.IsDelegate;
            TravelApprovalDelegateId = m.SubstituteId;
          } else if (m.ApprovalType === 'Time Approval') {
            IsDelegateTime = m.IsDelegate;
            TimeApprovalDelegateId = m.SubstituteId;
          }
        });

      //Fill TravelInformationDto data section starts
      TravelInformationDto.LeaveApprovalDelegationId =
        LeaveApprovalDelegationId;
      TravelInformationDto.IsDelegateLeave = IsDelegateLeave;
      TravelInformationDto.TimeApprovalDelegateId = TimeApprovalDelegateId;
      TravelInformationDto.IsDelegateTime = IsDelegateTime;
      TravelInformationDto.TravelApprovalDelegateId = TravelApprovalDelegateId;
      TravelInformationDto.IsDelegateTravel = IsDelegateTravel;
      //Fill TravelInformationDto data section ends
    }

    this.setState({ TravelInformationDto: TravelInformationDto });
  };

  manageGroupTabData = async () => {
    let {
      TravelInformationDto,
      groupState,
      RequestRef_Id,
      isViewPage,
      isEditPage,
      selectedEmployee,
    } = this.state;
    let TravelInformationGroupDto = [];
    if (!isEmpty(groupState)) {
      const { groupTypeId, selectedTravellerList } = groupState;
      const { myDetail } = this.props;

      let isGroup = groupTypeId == 2 ? true : false;

      //Fill TravelInformationDto data section starts
      TravelInformationDto.IsGroup = isGroup;
      //Fill TravelInformationDto data section ends

      if (isGroup) {
        (await selectedTravellerList) &&
          selectedTravellerList.map(m => {
            TravelInformationGroupDto.push({
              RequestId: RequestRef_Id,
              IsGroup: isGroup,
              EmployeeId: m.value,
            });
          });
      } else if (!isGroup && !isEmpty(myDetail)) {
        let requesterId =
          isViewPage || isEditPage
            ? this.props.travelInformationToEdit.TravelInformationDto
                .RequestedBy
            : this.state.roleId === ADMIN_ROLE_ID ||
              this.state.roleId === TRAVEL_ROLE_ID
            ? selectedEmployee.value
            : myDetail.value;
        TravelInformationGroupDto.push({
          RequestId: RequestRef_Id,
          IsGroup: isGroup,
          EmployeeId: requesterId,
        });
      }
    }

    this.setState({
      TravelInformationGroupDto: TravelInformationGroupDto,
      TravelInformationDto: TravelInformationDto,
    });
  };

  manageAdvanceAndExpensesTabData = async statusId => {
    let { TravelInformationDto, advanceAndExpensesState, RequestRef_Id } =
      this.state;
    let TravelInforamtionAllowanceExpenseDetailsDto = [];
    const { selectedAdvanceAndExpensesList, travelCategoryList } = this.props;

    if (isEmpty(travelCategoryList)) {
      let message = {
        MessageType: 'danger',
        Message:
          'Travel Configuration Rules are not complete, Please talk to your HR Department',
      };
      this.props.DisplayClientSideMessage(message);
      return;
    }
    if (!isEmpty(advanceAndExpensesState)) {
      const { advanceRequestAmount, note, selectedTravelCategory } =
        advanceAndExpensesState;

      //Fill TravelInformationDto data section starts
      TravelInformationDto.AdvanceRequestAmt = advanceRequestAmount;
      TravelInformationDto.NoteForAdvanceRequest = !isEmpty(note) ? note : null;
      //Fill TravelInformationDto data section ends
      const categoryId = !isEmpty(selectedTravelCategory)
        ? selectedTravelCategory.value
        : 0;
      let totalAmount = 0;
      (await selectedAdvanceAndExpensesList) &&
        selectedAdvanceAndExpensesList.map(m => {
          totalAmount = totalAmount + parseFloat(m.amount);
          TravelInforamtionAllowanceExpenseDetailsDto.push({
            CategoryRefId: categoryId,
            RequestRefId: RequestRef_Id,
            AllowanceName: m.allowance_name,
            Units: m.units,
            Rate: m.rate,
            Amount: m.amount,
            IsBillRequired: m.isbillrequired,
            CalculationMethod: m.calculationmethod,
            AllowanceRef_Id: m.allowance_id,
          });
        });

      TravelInformationDto.AssessedEligibilityAmt = totalAmount;
    }
    this.setState({
      TravelInformationDto: TravelInformationDto,
      TravelInforamtionAllowanceExpenseDetailsDto:
        TravelInforamtionAllowanceExpenseDetailsDto,
    });
  };

  manageDocumentTabData = async () => {
    const { documentsState, RequestRef_Id } = this.state;
    let TravelInformationDocumentsDto = [];

    if (!isEmpty(documentsState)) {
      const { succesFileInfoList } = await documentsState;
      succesFileInfoList &&
        succesFileInfoList.map((data, index) => {
          TravelInformationDocumentsDto.push({
            RequestRefId: RequestRef_Id,
            AttachmentId: data.AttachmentId,
            OriginalName: data.OriginalFileName,
            DocumentName: data.GeneratedFileName,
            DocumentPath: data.DocumentPath,
            FileLocation: data.FileLocation,
            Size: data.Size,
          });
        });
    }

    this.setState({
      TravelInformationDocumentsDto: TravelInformationDocumentsDto,
    });
  };

  manageApproversTabData = async () => {
    const { TravelInformationDto, approverState } = this.state;
    const { myDetail } = this.props;
    const { value } = this.props.approvalAuthorities;

    let showMessage = false;

    if (!isEmpty(approverState)) {
      const { selectedApprovalList } = approverState;
      if (selectedApprovalList.length > 0) {
        TravelInformationDto.ApprovalEmployeeId = selectedApprovalList[0].value;
      } else if (value.IsFinalApprover) {
        TravelInformationDto.ApprovalEmployeeId = myDetail.value;
        TravelInformationDto.StatusId = 15;
      } else showMessage = true;

      this.setState({
        TravelInformationDto: TravelInformationDto,
      });
    }
    return showMessage;
  };

  handleSaveAll = async (statusId, updateOrApprove) => {
    let showMessageTC = await this.manageTravelInformationTabData(statusId);
    if (showMessageTC) {
      return;
    }

    await this.manageDelegateTabData();
    await this.manageGroupTabData();
    await this.manageAdvanceAndExpensesTabData(statusId);
    await this.manageDocumentTabData();
    let showMessage = await this.manageApproversTabData();
    const { approvalAuthorities } = this.props;
    if (
      this.state.roleId === ADMIN_ROLE_ID ||
      this.state.roleId === TRAVEL_ROLE_ID
    )
      statusId = 15;
    if (isEmpty(approvalAuthorities) && statusId == 1) {
      let message = {
        MessageType: 'danger',
        Message:
          'Travel Configuration Rules are not complete, Please talk to your HR Department',
      };
      this.props.DisplayClientSideMessage(message);
      return;
    } else if (
      showMessage &&
      statusId === 1 &&
      (this.state.roleId !== ADMIN_ROLE_ID ||
        this.state.roleId === TRAVEL_ROLE_ID)
    ) {
      let message = {
        MessageType: 'danger',
        Message: `Please Select ${
          this.state.isAssign ? 'an Employee and ' : 'Approver to continue'
        }`,
      };
      this.props.DisplayClientSideMessage(message);
      return;
    } else {
      const props = this.props;
      const {
        advanceAndExpensesState,
        documentsState,
        isAllRequiredFieldValid,
        isAssign,
        submitStatusId,
        TravelInforamtionAllowanceExpenseDetailsDto,
        TravelInformationDto,
        TravelInformationDocumentsDto,
        TravelInformationDestinationDto,
        TravelInformationGroupDto,
        travelInformationState,
      } = this.state;
      let approvalData = {};
      let newTravelInfo = TravelInformationDto;

      if (isAllRequiredFieldValid) {
        if (!isEmpty(this.commonComments)) {
          if (statusId !== 0) {
            if (
              isEmpty(this.commonComments.state.employee) &&
              (this.state.roleId !== ADMIN_ROLE_ID ||
                this.state.roleId !== TRAVEL_ROLE_ID) &&
              submitStatusId !== 15
            ) {
              let message = {
                MessageType: 'danger',
                Message: 'You cannot forward without choosing an employee',
              };
              this.setState({ selectedCreateTabId: 5 });
              this.props.DisplayClientSideMessage(message);
              return false;
            }
            // this.commonComments.onApprove(1)
            approvalData = this.commonComments.onApproveReturn(
              this.state.submitStatusId,
            );
            newTravelInfo.ApprovalEmployeeId = approvalData.ApprovalEmployeeId;
          }
        }
        if (
          statusId !== 0 &&
          isEmpty(advanceAndExpensesState.selectedTravelCategory)
        ) {
          let message = {
            MessageType: 'danger',
            Message: 'You cannot update without choosing a travel category',
          };
          this.props.DisplayClientSideMessage(message);
          return false;
        }
        if (
          (this.state.roleId === ADMIN_ROLE_ID ||
            this.state.roleId === TRAVEL_ROLE_ID) &&
          !this.state.isViewPage &&
          !this.state.isEditPage
        ) {
          TravelInformationDto.RequestedBy = this.state.selectedEmployee.value;
          TravelInformationDto.IsHRAssigned = true;
          approvalData.ApprovalEmployeeId = this.props.myDetail.value;
        }
        if (this.state.isEditPage) {
          newTravelInfo.ApprovalEmployeeId = !isEmpty(
            this.state.approverState.selectedApprovalList,
          )
            ? this.state.approverState.selectedApprovalList[0].value
            : !isEmpty(this.props.myDetail)
            ? this.props.myDetail.value
            : 0;
        }
        if (
          this.state.roleId === ADMIN_ROLE_ID ||
          this.state.roleId === TRAVEL_ROLE_ID
        )
          newTravelInfo.StatusId = 15;

        if (isAssign) {
          newTravelInfo.RequestedBy = this.state.selectedEmployee.value;
        } else if (
          this.state.roleId === ADMIN_ROLE_ID ||
          this.state.roleId === TRAVEL_ROLE_ID
        ) {
          TravelInformationDto.RequestedBy = this.state.selectedEmployee.value;
          newTravelInfo.RequestedBy = this.state.selectedEmployee.value;
        } else if (!this.state.isEditPage) {
          newTravelInfo.RequestedBy = 0;
        } else {
          newTravelInfo.RequestedBy = TravelInformationDto.RequestedBy;
          newTravelInfo.StatusId = travelInformationState.StatusId;
        }
        if (
          this.state.isEditPage &&
          updateOrApprove == 1 &&
          newTravelInfo.StatusId == 0
        ) {
          newTravelInfo.StatusId = 1;
        }
        if (
          !isEmpty(this.props.approvalAuthorities) &&
          this.props.approvalAuthorities.value &&
          this.props.approvalAuthorities.value.IsFinalApprover &&
          this.state.roleId !== TRAVEL_ROLE_ID
        ) {
          newTravelInfo.StatusId = 15;
          newTravelInfo.RequestedBy = !isEmpty(this.props.myDetail)
            ? this.props.myDetail.value
            : 0;
        }
        if (!isEmpty(statusId) && statusId === 0) {
          if (!this.state.isEditPage) {
            newTravelInfo.RequestedBy = 0;
          }
          newTravelInfo.StatusId = statusId;
        }
        await this.props.SaveAllTravelRequest({
          TravelInformationDto: newTravelInfo,
          TravelInformationDestinationDto,
          TravelInformationGroupDto,
          TravelInforamtionAllowanceExpenseDetailsDto,
          TravelInformationDocumentsDto,
          TravelApprovalFlow: approvalData,
          props,
          documentsState,
        });
      } else {
        let message = {
          MessageType: 'danger',
          Message: 'Please complete the minimum required information.',
        };
        this.props.DisplayClientSideMessage(message);
      }
    }
  };

  handleEmployeeChange = (name, selected) => {
    const { employeeListWithPhoto } = this.props;
    // debugger;
    this.setState(
      prevState => ({
        selectedEmployee: {
          ...prevState.selectedEmployee,
          value: isEmpty(selected) ? '' : selected.value,
          label: isEmpty(selected) ? '' : selected.label,
          error: isEmpty(selected) ? 'Required' : '',
        },
        selectedEmployeeId: isEmpty(selected) ? 0 : selected.value,
        groupState: {
          ...prevState.groupState,
          requesterInformation: isEmpty(selected)
            ? null
            : employeeListWithPhoto?.find(f => f.value == selected.value),
        },
      }),
      () => {
        const { travelRequestId, submitStatusId } = this.state;
        if (!isEmpty(selected))
          this.getApproverDetails(
            travelRequestId,
            submitStatusId,
            selected.value,
          );
      },
    );
  };

  closeModal = () => {
    this.setState({ isModalShow: false });
    this.props.closeModal && this.props.closeModal();
  };

  handleTabChange = async id => {
    let showMessage = await this.manageTravelInformationTabData(null, id);
    const { isAllRequiredFieldValid, isEditPage, isViewPage } = this.state;
    let message = {};
    if (showMessage && id == 4 && !isViewPage) {
      const { travelCategoryList } = this.props;
      if (isEmpty(travelCategoryList))
        message = {
          MessageType: 'danger',
          Message:
            'Travel Configuration Rules are not complete, Please talk to your HR Department',
        };
      else
        message = {
          MessageType: 'danger',
          Message: 'Please fill Travel Category to continue',
        };
      this.props.DisplayClientSideMessage(message);
    } else if (isAllRequiredFieldValid) {
      this.setState({ selectedCreateTabId: id });
    } else {
      message = {
        MessageType: 'danger',
        Message: 'Please fill all your details for this destination.',
      };
      this.props.DisplayClientSideMessage(message);
    }
  };

  handleResetSelectedTravelCategory = () => {
    this.setState(prevState => ({
      ...prevState,
      advanceAndExpensesState: {
        ...prevState.advanceAndExpensesState,
        selectedTravelCategory: null,
      },
    }));
    this.props.ResetSelectedAdvanceAndExpenseList();
  };

  renderBody = () => {
    const {
      create_tabs,
      selectedCreateTabId,
      overAllDestinationToDate,
      isAllRequiredFieldValid,
      selectedEmployee,
      RequestRef_Id,
    } = this.state;
    const { user } = this.props;
    const label = (text, index) => {
      return (
        <div className='tab-title'>
          <span className='tab-title__count'>{index + 1}</span>
          <span className='tab-title__text'>{text}</span>
        </div>
      );
    };
    const getKeyValue = (data, val) => {
      return data.find(item => item.value === val);
    };
    return (
      <div className='travel-create'>
        {(this.state.roleId === ADMIN_ROLE_ID ||
          this.state.roleId === TRAVEL_ROLE_ID) &&
        !this.state.isViewPage &&
        !this.state.isEditPage ? (
          <div className='employee'>
            <ValidationComponent
              rootClassName='select'
              error={selectedEmployee.error}
              errorClassName='error__message'
            >
              <CommonEmployeeSearch
                onChange={this.handleEmployeeChange}
                value={selectedEmployee}
                placeholder='Select Employee'
                name='employee'
                minWidth='300'
                disabled={this.state.isViewPage}
              />
            </ValidationComponent>{' '}
          </div>
        ) : null}

        <div className='travel-create__body'>
          {this.props.renderTest && this.props.renderTest()}
          <Tabs type='block' activeTab={selectedCreateTabId}>
            {create_tabs &&
              create_tabs.map((ct, i) => {
                let TabComponent = ct.component;
                return (
                  <Tab
                    label={label(ct.label, i)}
                    name={ct.id}
                    id={ct.id}
                    key={i + Math.random()}
                    isActive={ct.id === selectedCreateTabId}
                    onClick={() => this.handleTabChange(ct.id)}
                  >
                    <div className='filters-input'>
                      <div className='filters-input__item'>
                        <TabComponent
                          {...this.props}
                          {...this.state}
                          createTravelDestination={createTravelDestination()}
                          handleAdvanceAndExpensesTabState={
                            this.handleAdvanceAndExpensesTabState
                          }
                          handleApproversTabState={this.handleApproversTabState}
                          handleDelegationTabState={
                            this.handleDelegationTabState
                          }
                          handleDocumentsTabState={this.handleDocumentsTabState}
                          handleGroupTabState={this.handleGroupTabState}
                          handleResetSelectedTravelCategory={
                            this.handleResetSelectedTravelCategory
                          }
                          handleTravelInfoTabState={
                            this.handleTravelInfoTabState
                          }
                          isAllRequiredFieldValid={isAllRequiredFieldValid}
                          isAssign={this.state.isAssign}
                          isViewMode={this.state.isViewPage}
                          overAllDestinationToDate={overAllDestinationToDate}
                          requesterId={
                            isEmpty(this.props.travelInformationToEdit)
                              ? ''
                              : this.props.travelInformationToEdit
                                  .TravelInformationDto.RequestedBy
                          }
                          RequestId={RequestRef_Id}
                          selectedEmployee={this.state.selectedEmployee}
                          selectedEmployeeId={
                            user.RoleId === 2
                              ? user.EmployeeId
                              : this.state.selectedEmployeeId
                          }
                        />
                      </div>
                    </div>
                  </Tab>
                );
              })}
          </Tabs>
        </div>
      </div>
    );
  };

  render() {
    const {
      isEditPage,
      isViewPage,
      isEditDataFetched,
      selectedCreateTabId,
      requestDetails,
      isLoading,
      isAssign,
    } = this.state;
    const { travelEditLoading, approvalAuthorities, user, history } =
      this.props;

    let isApprove =
      this.state.isEditPage &&
      ((!isEmpty(this.commonComments) && this.commonComments.state.isApprove) ||
        this.state.submitStatusId === 15 ||
        (!isEmpty(this.props.approvalAuthorities.value) &&
          this.props.approvalAuthorities.value.IsFinalApprover));

    let selfApprover =
      !isEmpty(approvalAuthorities.value) &&
      approvalAuthorities.value.IsFinalApprover;

    if (isLoading) {
      return <Loader type={'spinner'} loading={isLoading} />;
    }
    return (
      <>
        {travelEditLoading ? (
          <Loader open={travelEditLoading} loading={travelEditLoading} />
        ) : null}

        {isViewPage && isEditDataFetched && (
          <TravelBreadcrumb>
            <Link to={RouteEnum.EmployeeDashBoard}>
              <span className='link'>Dashboard</span>
            </Link>
            <RiArrowRightSLine className='right-arrow' />

            <Link to={RouteEnum.TeamTravelRequestPath}>
              <span className='link'>Travel Request</span>
            </Link>

            <RiArrowRightSLine className='right-arrow' />
            <span>{requestDetails?.Name ?? ''}</span>
          </TravelBreadcrumb>
        )}

        {(!isViewPage && !isEditPage) || isEditDataFetched ? (
          <div className='travel p-0'>
            <div className='travel-fixed-column'>
              {/* <div className="travelreq-flex flex alignStart"> */}
              <div className='travel-fixed-main'>
                <div className='travel-header'>
                  <div className=' m-0'>{this.renderBody(true, true)}</div>
                </div>
              </div>
              <div className='travelreq-comments'>
                <div
                  className='comment-history'
                  style={{
                    display: 'block',
                    width: '1296px',
                    maxWidth: '100%',
                    backgroundColor: '#fff',
                    borderRadius: '0px 0px 4px 4px',
                    marginTop: '3px',
                    marginLeft: '2px',
                  }}
                >
                  {requestDetails && (
                    <CommonCommentsHistory
                      isApprover={requestDetails?.page === 'otherTravelRequest'}
                      childRef={ref => (this.commonComments = ref)}
                      RequestId={this.state.travelRequestId}
                      StatusId={this.state.submitStatusId}
                      approvalAuthorities={this.props.approvalAuthorities}
                      approvalHistory={this.props.approvalHistory}
                      isEditPage={this.state.isEditPage}
                      isViewMode={this.state.isViewPage}
                      disableApprover={true}
                      hideCommentSection={isEmpty(
                        this.props.approvalHistory.value,
                      )}
                      isUpdate={
                        !isEmpty(requestDetails)
                          ? requestDetails?.update
                          : false
                      }
                      isRequester={
                        requestDetails?.EmployeeId == user.EmployeeId
                      }
                      selectedCreateTabId={selectedCreateTabId}
                    />
                  )}
                </div>
              </div>
              {/* </div> */}
              <footer className='travel-fixed-footer'>
                <div className='travel-footer-div flex justify-end'>
                  {/* <div className="travel-footer"> */}
                  <Button
                    onClick={() => this.props.history.goBack()}
                    bg='subtle'
                  >
                    Close
                  </Button>
                  {/* </div> */}
                  {this.state.isViewPage ? null : (
                    <>
                      {(!isEditPage && !selectedCreateTabId != 5) ||
                      (isEditPage &&
                        requestDetails?.update &&
                        requestDetails?.StatusId == 0 &&
                        (this.state.roleId !== ADMIN_ROLE_ID ||
                          this.state.roleId !== TRAVEL_ROLE_ID)) ? (
                        isAssign ||
                        this.state.roleId === 1 ||
                        this.state.roleId === 42 ? null : (
                          <Button
                            bg='primary'
                            onClick={() => this.handleSaveAll(0)}
                            isDisabled={travelEditLoading}
                          >
                            Save as Draft
                          </Button>
                        )
                      ) : null}
                      {selectedCreateTabId != 0 && (
                        <Button
                          bg='primary-light'
                          onClick={() =>
                            this.handleTabChange(selectedCreateTabId - 1)
                          }
                        >
                          Previous
                        </Button>
                      )}

                      {/* {selectedCreateTabId < 5 && (  */}
                      {selectedCreateTabId < 3 && (
                        <Button
                          bg='primary-light'
                          onClick={() =>
                            this.handleTabChange(selectedCreateTabId + 1)
                          }
                        >
                          Next
                        </Button>
                      )}
                      {(!isEditPage && selectedCreateTabId == 3) ||
                      (isEditPage && requestDetails?.update) ? (
                        <Button
                          bg='success'
                          onClick={() =>
                            this.handleSaveAll(this.state.submitStatusId, 1)
                          }
                          isDisabled={travelEditLoading}
                        >
                          {!isEmpty(this.commonComments)
                            ? isApprove
                              ? 'Approve'
                              : !isEmpty(requestDetails) &&
                                requestDetails?.StatusId === 0
                              ? 'Send for Approval'
                              : !isEmpty(requestDetails) &&
                                requestDetails?.update
                              ? 'Update'
                              : 'Send for Approval'
                            : selfApprover
                            ? 'Approve'
                            : user?.RoleId === ADMIN_ROLE_ID || TRAVEL_ROLE_ID
                            ? 'Approve'
                            : 'Send for Approval'}
                        </Button>
                      ) : null}
                    </>
                  )}
                </div>
              </footer>
            </div>
          </div>
        ) : (
          <Loader open={!isEditDataFetched} />
        )}
      </>
    );
  }
}

const TravelBreadcrumb = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0.5rem 0px;

  span {
    font-size: 14px;
  }

  a > .link {
    font-size: 14px;
    color: #0052ab;
  }
`;

const mapStateToProps = state => ({
  selectedAdvanceAndExpensesList:
    state.travelInformationReducer.selectedAdvanceAndExpensesList,

  countryList: state.commonReducer.countryList,
  currencyList: state.commonReducer.currencyList,
  districtList: state.commonReducer.districtList,
  employeeList: state.commonReducer.employeeList,
  employeeListWithPhoto: state.commonReducer.employeeListWithPhoto,
  myDetail: state.commonReducer.myDetail,

  modeOfTransportList: state.travelInformationReducer.modeOfTransportList,
  travelHostList: state.travelInformationReducer.travelHostList,
  travelHotelList: state.travelInformationReducer.travelHotelList,

  travelCategoryList: state.travelInformationReducer.travelCategoryList,

  travelInformationToEdit:
    state.travelInformationReducer.travelInformationToEdit,
  travelEditLoading: state.travelInformationReducer.travelEditLoading,
  approvalAuthorities: state.travelInformationReducer.approvalAuthorities,
  approvalHistory: state.travelInformationReducer.approvalHistory,
  companyInformation: state.commonReducer.companyInformation,
  user: state.auth.user,
});

const mapDispatchToProps = {
  DisplayClientSideMessage,
  EditTravelRequests,
  ClearAdvanceAndExpensesList,
  GetCountryList,
  GetCurrencyList,
  GetDistrictList,
  GetTravelModeOfTransport,
  GetTravelHostAndHotel,
  GetEmployeeList,
  GetTravelCategoryList,
  GetAdvanceAndExpensesList,
  GetApprovalAuthorities,
  GetApprovalHistory,
  RejectTravelRequest,
  ResetSelectedAdvanceAndExpenseList,
  SaveAllTravelRequest,
  UpdateApprovalAuthoritiesForEdit,
};
export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(CreateNewTravelRequest),
);
