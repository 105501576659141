import isEmpty from "lodash/isEmpty";

interface EvaluationSelectorData {

}

/**
 * GET EVALUATION PAGE DATA BASED ON PROVIDED REVIEW DETAIL
 * @param section 
 * @returns 
 */
export const selectBaseEvaluationPageData = (reviewDetail: any) => (state: any): any => {

    const evaluation = reviewDetail?.Evaluation;

    if (evaluation) {

        return {
            ...evaluation,
            Comments: evaluation.Comments,
            SignatureMessage: reviewDetail?.ReviewInfo?.SignatureMessage,
            employeeConsent: isEmpty(evaluation?.ConsentOn),

            // selfMarking: {
            //     IsSignatureRequired: selfReview?.ReviewInfo?.IsSignatureRequired,
            //     SignatureMessage: selfReview?.ReviewInfo?.SignatureMessage,
            //     Signature: selfReview?.ReviewInfo?.Signature
            // }
        }
    }

    return {
        Comments: [],
        SignatureMessage: reviewDetail?.ReviewInfo?.SignatureMessage,
        employeeConsent: isEmpty(evaluation?.ConsentOn),
        selfMarking: {
        }
    };
};