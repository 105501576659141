// @ts-nocheck
import React, { memo, useEffect } from "react";
import isEmpty from "helpers/isEmpty";
import Button from "components/button";
import SvgIcons from "components/icons/svgIcons";
import NewClock from "components/clock/newClock";

import "./breakInOutModal.style.scss";
import { useAttendance } from "./hooks/useAttendance";
import CheckInModal from "./components/modals/CheckIn";
import CheckoutBreakoutModal from "./components/modals/CheckoutBreakout";

function AttendanceShortcutButton(props: any) {
  const {
    attendanceAuthority,
    getTitle,
    handleCheckInModalClose,
    handleCheckoutBreakoutModalClose,
    handleClick,
    initialize,
    isCheckInModalOpen,
    isCheckOutBreakOutModalOpen,
    isLunchInTimeExist,
    saveAttendanceNote,
    saveAttendanceTime,
    saveLunchInNote,
    saveLunchTime,
  } = useAttendance();

  useEffect(() => {
    initialize();
  }, []);

  return (
    <>
      {!isEmpty(attendanceAuthority) && attendanceAuthority.IsManualAttendance && (
        <div className="attendance-shortcut">
          <Button
            buttonClass={isLunchInTimeExist ? "color-out" : ""}
            onClick={handleClick}
            buttonType="icon-button"
            type="button-outline"
            bg="primary"
            icon={<SvgIcons color="#8a8a8a" name="thumb-print" size={16} />}
          >
            <span className="mr-sm">{getTitle()}</span>
            <NewClock />
          </Button>
        </div>
      )}

      {isCheckInModalOpen && (
        <CheckInModal
          handleClose={handleCheckInModalClose}
          isOpen={isCheckInModalOpen}
          saveAttendanceTime={saveAttendanceTime}
          saveAttendanceNote={saveAttendanceNote}
        />
      )}

      {isCheckOutBreakOutModalOpen && (
        <CheckoutBreakoutModal
          attendanceAuthority={attendanceAuthority}
          handleClose={handleCheckoutBreakoutModalClose}
          isLunchInTimeExist={isLunchInTimeExist}
          isOpen={isCheckOutBreakOutModalOpen}
          saveAttendanceNote={saveAttendanceNote}
          saveAttendanceTime={saveAttendanceTime}
          saveLunchInNote={saveLunchInNote}
          saveLunchTime={saveLunchTime}
        />
      )}
    </>
  );
}

export default memo(AttendanceShortcutButton);
