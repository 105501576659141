export const loadingHeaders = {
    headers: {
        'loading': false
    }
};


export const rigoLoadingHeaders = {
    headers: {
        'type': 'rigoLoading'
    }
};