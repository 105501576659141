export const selectManagers = (state: any) =>
  state.dataStore.directManager.managers;

export const selectAssignedSummary = (state: any) =>
  state.dataStore.directManager.assignedSummary;

export const selectEmployeeList = (state: any) =>
  state.dataStore.directManager.employeeList;

export const selectPositionList = (state: any) =>
  state.dataStore.directManager.positionList;

export const selectLoading = (state: any) =>
  state.dataStore.directManager.loading;
