import React, { useContext, useState } from 'react'
import StepperContext, { StepperEnums } from '../context';
import Container from './container';
import { Box, Text, Image, useToast, Flex, PinInput,PinInputField } from "@chakra-ui/react";
import { Button,Modal, Input } from "@rigotech/ui";
import OtpInput from './otpInput'
import { useDispatch,useSelector } from 'react-redux';
import { getTFAQRCode, postTFACodeReset, postTFARecoveryEmail, verifyTFACode } from 'actions/authAction';
import { AuthState } from '@/reducers/authReducer';
import { useHistory } from 'react-router-dom';
import RouteEnum from 'constants/routeEnum';

function EnterCode() {
  const [otp,setOtp] = useState('')
  const values = useContext(StepperContext);
  const history = useHistory();
  const auth:AuthState = useSelector((state:any)=>state.auth)
  const {twoFactorUserDetails,tfaQrDetails} = auth;
  const Username = twoFactorUserDetails && twoFactorUserDetails.Username
  const dispatch = useDispatch()
  const onStepChange = async (final = null) => {
    // values.setActiveStepper(StepperEnums.completed);
    const data = {
      code:final ? final :otp,
      userName:Username
    }
    const response = await dispatch(verifyTFACode(data))
    if(response && response?.Status){
      if(tfaQrDetails?.AuthKeyUsedAleady){
        history.push(RouteEnum.HRDashboardPath);
        return;
      }
      values.setActiveStepper(StepperEnums.completed);
    }
  }
  const onChange = async (value) =>{
    await setOtp(value)
  }
  const isLengthSix = otp.replace(/\s/g, '').length === 6;

  return (
    <Box>
      <Container isBack={!tfaQrDetails?.AuthKeyUsedAleady} title="Set up Authenticator App">
        <Box display="flex" flexDir="column" alignItems="center" padding="40px 24px" > 
          <Text color="#030511" fontSize="xl" fontWeight={700} textAlign="center" maxW="350px" >Enter the 6-digit code found in your authenticator app</Text>
          <Box display="flex" justifyContent="center" gap={"12px"} mt="80px" mb="12px" >
            {/* <OtpInput onChange={onChange} value={otp} valueLength={6} /> */}
            <PinInput
                value={otp}
                onChange={onChange}
                autoFocus
                onComplete={(value)=>onStepChange(value)}
                otp  
                size='lg'
              >
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
          </Box>
          <Button 
            isDisabled={!isLengthSix}
            w="385px" 
            mt="24px"
            onClick={onStepChange} 
            buttonType="primary" 
            variant="solid" 
            padding="20px 60px" 
            fontSize={"16px"} 
            _disabled={{
              bg:"#7F838C !important",
              borderColor:'#7F838C',
              color:"#fff !important"
            }}
            fontWeight={400} 
          >
            Confirm
          </Button>
          {tfaQrDetails?.AuthKeyUsedAleady && <Box display="flex" gap="10px" mt="12px" alignItems={"center"}>
            <Text fontSize='sm' fontWeight={600}>Having trouble with 2FA?</Text>
            <RecoveryEmail />
          </Box>}
        </Box>
      </Container>
    </Box>
  )
}

const RecoveryEmail = () => {
  const [open,setOpen] = React.useState(false);
  const [codeView,setCodeView] = React.useState(false);
  const values = useContext(StepperContext);
  const [code,setCode] = React.useState(null);
  const auth:AuthState = useSelector((state:any)=>state.auth)
  const toast = useToast()
  const dispatch = useDispatch()
  const {tfaQrDetails,twoFactorUserDetails} = auth;
  const [loading,setLoading] = React.useState(false);
  const onOpen = ()=>setOpen(true);
  const onClose = ()=>setOpen(false);
  
  const handleSubmit = () => {
    onClose()
  }
  const Username = twoFactorUserDetails?.Username ?? null;
  const sendRecoveryEmail = async () =>{
    if(Username){
      const response = await dispatch(postTFARecoveryEmail({userName:Username}));
      setCodeView(response)
    }
  }
  const sendResetCode = async () =>{
    if(Username){
      const data = {
        userName:Username,
        code,
      }
      const response = await dispatch(postTFACodeReset(data));
      if(response){
        setCode(null)
        setCodeView(false)
        values.setActiveStepper(StepperEnums.setUp);
        await dispatch(getTFAQRCode(Username));
      }
    }
  }
  // 
  return(
    <>
      <Button onClick={onOpen} buttonType="link-primary" variant="link" >
        <Text fontSize='sm' fontWeight={600}>Recover your code.</Text>
      </Button>
      <Modal  isOpen={open} size="2xl" onClose={onClose} isCentered >
        <Modal.Overlay 
          bg='blackAlpha.300'
          backdropFilter='blur(4px) hue-rotate(24deg)'
        />
        <Modal.Content className="rigo-common-modal-1">
          {/* <Modal.CloseBtn /> */}
          <Modal.Header>Reset Code</Modal.Header>
          <Modal.Body>
            <Box padding="24px" textAlign="center" >
              {/* <Text fontSize='xl' color="#030511" fontWeight={600} >Send.</Text> */}
              {/* <Text fontSize='5xl' color="#0055BA" fontWeight={800} letterSpacing={12} margin="32px 0" >966542</Text> */}
              <Box 
                boxShadow="0 2px 3px rgba(0,0,0,0.12)"
                display="flex" flexDir="row" margin="48px 0" >
                <Box flex="1" padding="8px" background={"#efefef"} >
                  {codeView ? <Input.TextInput 
                    name="code"
                    onChange={(name,value)=>setCode(value)}
                    value={code}
                    placeholder='Please enter your otp code recovered from your email address.'
                  
                  />: <Text fontSize='md' color="#030511" fontWeight={600}>{Username}</Text>}
                </Box>
                <Box 
                  onClick={() => {
                    codeView ? sendResetCode() : sendRecoveryEmail()
                    // navigator.clipboard.writeText(tfaQrDetails?.AuthenticatorKey)
                    // toast({
                    //   title: 'Text copied.',
                    //   description: `${tfaQrDetails?.AuthenticatorKey} copied successfully`,
                    //   status: 'success',
                    //   duration:3000,
                    //   isClosable: true,
                    // })
                  }}
                  border="1px solid #dfdfdf"
                  padding="8px 24px" background={"#1677ff"}
                  cursor="pointer"
                  _hover={{background:"#4096ff"}}
                >
                  <Text fontSize="md" color="#fff" >Send Email</Text>
                </Box>
              </Box>              
            </Box>
          </Modal.Body>
          <Modal.Footer>
            <Flex gap="15px" justifyContent={"center"}>
              <Button
                buttonType="secondary"
                onClick={onClose}
              >
                Close
              </Button>
              <Button
                buttonType="primary"
                onClick={handleSubmit}
                isLoading={loading}
                type="submit"
              >
                Done
              </Button>
            </Flex>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  )
}


export default EnterCode