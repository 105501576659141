
import { ApiClient } from "../../../network/apiClient";
import * as fromInterface from "../../../ts";
import { DEPARTMENT_LIST } from "./api";
import * as fromSlice from "./slice";
import * as fromSelector from "./selectors"


export const fetchDepartments = (): any => async (dispatch: any, getState: any) => {
    const state = getState();
    const isLoaded = fromSelector.selectDepartmentLoaded(state)
    if (!isLoaded) {
        dispatch(fromSlice.fetchRequest())

        const response: fromInterface.ResponseWrapper<any> = await ApiClient.get<any>({
            endpoint: DEPARTMENT_LIST,
            dispatch: dispatch
        })

        const { Data, Status } = response

        if (Status) {
            dispatch(fromSlice.fetchSuccess(Data))
        }
        return fromSelector.selectDepartmentItems(state)

    }
}

export const clearDepartments = (): any => async (dispatch: any) => {
    dispatch(fromSlice.clear())
}

