import * as fromSlice from "./slice";
import * as fromInterface from "./../../../ts";

const selectCurrentBranchSlice = (state: any): fromSlice.BranchState => {
    return state.dataStore.branch;
};

export const selectBranchItems = (state: any): any[] => {
    return selectCurrentBranchSlice(state).items;
};

export const selectBranchDropdownItems = (state: any): any[] => {
    return selectBranchItems(state)?.map(item => {
        return {
            label: item.Text,
            value:item.Value
        }
    }) || [];
};
export const selectBranchStatus = (state: any): fromInterface.loadingType => {
    return selectCurrentBranchSlice(state).status;
};

export const selectBranchLoaded = (state: any): boolean => {
    return selectCurrentBranchSlice(state).loaded;
};