import * as fromSlice from "./slice";
import * as fromSectionStore from "../section";
import * as fromPerformanceFormStateStore from "../performance-form-state";
import isEmpty from "lodash/isEmpty";

export const selectSectionOption = ({ PerformanceSectionId }: { PerformanceSectionId?: number }) => async (dispatch: any) => {
    // dispatch(fromSlice.selectRequest())
    dispatch(fromSlice.selectSuccess(PerformanceSectionId))
    // dispatch(fromPerformanceFormStateStore.(PerformanceSectionId))

    // MIGHT NOT NEED
    if (!isEmpty(PerformanceSectionId)) {
        await dispatch(fromSectionStore?.fetchSectionDetail({ PerformanceSectionId }))
    }
}