import { createSelector } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import { selectCurrentStep, selectReview, selectReviewForSelf } from ".";
import { selectAllComments } from "../../performance-review-comments/selectors";
import { mapComments, } from "../helper/map-comments";

const selectPerformanceReview = (state: any) => state.dataStore.performanceReview;


export const selectMemoizedGoaldAndActionsPageData = createSelector(
    [selectPerformanceReview],
    (performanceReview,) => {
        const { item: review, currentStep, selfItem: selfReview } = performanceReview;

        const section = review?.Sections?.find((section: any) => {
            return section.Id === currentStep.sectionId
        })

        const selfSection = selfReview.Sections?.find((section: any) => {
            return section.Id === currentStep.sectionId
        })

        const sectionIdx = review?.Sections?.findIndex((section: any) => {
            return section.Id === currentStep.sectionId
        })

        if (section) {
            const {
                Id,
                PerformanceFormId,
                SectionName,
                IntroText,
                RatingScaleOptions,
                Contents,
                AssessmentType
            } = section

            const contentIdx = 0;

            const GoalActionOptions = Contents[contentIdx]?.GoalActionOptions;
            const selfGoalActionOptions = selfSection?.Contents[contentIdx]?.GoalActionOptions;

            const reviewDetails = GoalActionOptions.map(({
                Id,
                Goal,
                GoalDatabaseId,
                ContentId,
                Target,
                Achievement,
                Weight,
                SectionId,
                RatingScaleOptionId
            }: any, gaaIdx: number) => {

                return {
                    SectionId: SectionId,
                    SectionContentId: ContentId,
                    SectionContentOptionId: Id,
                    RatingScaleOptionId: RatingScaleOptionId,
                    selfMarkingValue: selfReview?.Sections?.[sectionIdx as number].Contents[contentIdx].GoalActionOptions[gaaIdx]?.RatingScore,
                    GoalAction: {
                        GoalDatabaseId,
                        Target: Target ?? selfGoalActionOptions?.[gaaIdx].Target,
                        Achievement: Achievement ?? selfGoalActionOptions?.[gaaIdx].Achievement,
                        Weight
                    },


                    // REQUIRED TO DISPLAY
                    Goal,
                }
            })

            // =======================
            // DERIVE COMMENTS
            // =======================
            // const { SectionId, Id: ContentId } = Contents[contentIdx];
            // let Comments: any = mapComments(SectionId, ContentId, comments, item?.review?.ReviewInfo);

            // =======================
            // END DERIVE COMMENTS
            // =======================

            const mapped: any = {
                Id,
                PerformanceFormId,
                PerformanceRolloutId: review?.ReviewInfo?.PerformanceRolloutId,
                reviewDetails,
                Comments: [],
                signature: false,

                // REQUIRED TO DISPLAY
                SectionName,
                IntroText,
                RatingScaleOptions,
                AssessmentType,
                AllowComments: Contents[contentIdx].AllowComments
            }
            return mapped
        }


        return null;
    })

export const selectGoaldANdActionsPageData = (state: any): any => {
    const currentStep = selectCurrentStep(state);
    const review = selectReview(state);
    const selfReview = selectReviewForSelf(state);

    const { ReviewInfo } = review;

    const section = review.Sections?.find((section: any) => {
        return section.Id === currentStep.sectionId
    })

    const selfSection = selfReview.Sections?.find((section: any) => {
        return section.Id === currentStep.sectionId
    })

    const sectionIdx = review.Sections?.findIndex((section: any) => {
        return section.Id === currentStep.sectionId
    })

    if (section) {
        const {
            Id,
            PerformanceFormId,
            SectionName,
            IntroText,
            RatingScaleOptions,
            Contents,
            AssessmentType
        } = section

        const contentIdx = 0;

        const GoalActionOptions = Contents[contentIdx]?.GoalActionOptions;
        const selfGoalActionOptions = selfSection?.Contents[contentIdx]?.GoalActionOptions;

        const reviewDetails = GoalActionOptions.map(({
            Id,
            Goal,
            GoalDatabaseId,
            ContentId,
            Target,
            Achievement,
            Weight,
            SectionId,
            RatingScaleOptionId
        }: any, gaaIdx: number) => {

            return {
                SectionId: SectionId,
                SectionContentId: ContentId,
                SectionContentOptionId: Id,
                RatingScaleOptionId: RatingScaleOptionId,
                selfMarkingValue: selfReview?.Sections?.[sectionIdx as number].Contents[contentIdx].GoalActionOptions[gaaIdx]?.RatingScore,
                GoalAction: {
                    GoalDatabaseId,
                    Target: Target ?? selfGoalActionOptions?.[gaaIdx].Target,
                    Achievement: Achievement ?? selfGoalActionOptions?.[gaaIdx].Achievement,
                    Weight
                },


                // REQUIRED TO DISPLAY
                Goal,
            }
        })

        // =======================
        // DERIVE COMMENTS
        // =======================
        const { SectionId, Id: ContentId } = Contents[contentIdx];
        let Comments: any = mapComments(SectionId, ContentId, state, ReviewInfo);

        // =======================
        // END DERIVE COMMENTS
        // =======================

        const mapped = {
            Id,
            PerformanceFormId,
            PerformanceRolloutId: ReviewInfo?.PerformanceRolloutId,
            reviewDetails,
            Comments,
            signature: false,

            // REQUIRED TO DISPLAY
            SectionName,
            IntroText,
            RatingScaleOptions,
            AssessmentType,
            AllowComments: Contents[contentIdx].AllowComments
        }
        return mapped
    }


    return null;
};
