import { createSlice } from "@reduxjs/toolkit";
import * as fromInterface from "../../../../ts";

export interface PerformanceFormListFilterState {
    pageIndex: number,
    pageSize: number,
    formName?: string,
    assessmentOptionType: number,
    isRolledOut?: boolean | null,
}

const initialState: PerformanceFormListFilterState = {
    pageIndex: 0,
    pageSize: 20,
    formName: '',
    isRolledOut: null,
    assessmentOptionType: 1
};

const performaceFormListFilterSlice = createSlice({
    name: "performance from list filter",
    initialState,
    reducers: {
        udpateFilter: (state, action) => {
            const payload = action.payload

            state = { ...state, ...payload }

        },
        clear: (state) => {
            state = initialState
        },
    },
});

export const {
    udpateFilter,
    clear,
} = performaceFormListFilterSlice.actions

export default performaceFormListFilterSlice.reducer;