// @ts-nocheck 
import Http from "src/utils/http/http";
import Action from "src/utils/http/actionUtility";
import environment from "environment";
import types from "constants/types";
import RouteEnum from "constants/routeEnum";
import queryUrl from "helpers/getQueryUrl";
import { exportFile_get, exportFile_post } from "src/networkService";

export const GetShiftEmployeeAttendance = (filter) => async (dispatch) => {
  const res = Http.get(environment.api.v1.roster.roosterShiftEmployeeAttendaces, filter);

  const actionConfig = {
    dispatch,
    actionType: "",
    effect: res,
    isLoading: true,
  };
  return await Action.createThunkEffect(actionConfig);
};

export const CreateTimeRequest = (param) => async (dispatch) => {
  const res = Http.post(environment.api.v1.roster.leaveTimeRequest, param);
  const actionConfig = {
    dispatch,
    actionType: "",
    effect: res,
    // isLoading: true,
    isReturn: true,
  };
  var actRes = await Action.createThunkEffect(actionConfig);
  return actRes;
};

export const SaveEmployeeAttendance = (param) => async (dispatch) => {
  const res = Http.post(environment.api.v1.roster.roosterShiftEmployeeAttendaces, param);
  const actionConfig = {
    dispatch,
    actionType: "",
    effect: res,
    isLoading: false,
    isReturn: true,
  };
  var actRes: any = await Action.createThunkEffect(actionConfig);
  return actRes?.Status || false;
};

export const AssingEmployeeAttendance = (param) => async (dispatch) => {
  const res = Http.post(environment.api.v1.roster.roosterShiftEmployeeAttendace, param);
  const actionConfig = {
    dispatch,
    actionType: "",
    effect: res,
    isLoading: false,
    isReturn: true,
  };
  var actRes: any = await Action.createThunkEffect(actionConfig);
  return actRes;
};
export const GetEmployeeShiftAttendances = (param) => async (dispatch) => {
  const res = Http.post(environment.api.v1.roster.roosterEmployeeShiftAttendaces, param);

  const actionConfig = {
    dispatch,
    actionType: '',
    effect: res,
    isLoading: false,
    isReturn: false,
  };
  var actRes: any = await Action.createThunkEffect(actionConfig);
  return actRes?.Data || [];
};

export const UpdateAllShiftAtttendance = (param) => async (dispatch) => {
  const res = Http.put(environment.api.v1.roster.updateAllShiftEmployeeAttendances,
    param
  );

  const actionConfig = {
    dispatch,
    actionType: "",
    effect: res,
    isLoading: true,
  };
  return await Action.createThunkEffect(actionConfig);
};
