import React from "react";
import { ModalHeader, ModalHeaderProps } from "@chakra-ui/react";

interface HeaderProps extends ModalHeaderProps {
}

export const Header = ({ children, ...rest }: HeaderProps) => {
    return (
      <ModalHeader fontWeight="700" fontSize="21px" {...rest}>
        {children}
      </ModalHeader>
    );
}

export default Header;
