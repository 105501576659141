// @ts-nocheck 
import React, {useEffect, useState} from "react";
import Modal from "components/modal";
import Avatar from "react-avatar";
import {getFullURL} from "helpers/fullURL";
import {useDispatch, useSelector} from "react-redux";
import Button from "components/button";
import FormItem from "components/form";
import {RiBook2Line} from "react-icons/ri";
import AccessValidityForm from "./assign/accessValidityForm";
import {editAssignCourse} from "../helpers/action";
import isEmpty from "helpers/isEmpty";

const EditAssignment = (props) => {
  const {open, onModalClose, onSuccessSubmitting, employee} = props;

  const dispatch = useDispatch();
  useEffect(() => {
    if (employee) {
      setState({
        courseId: employee.CourseId,
        employeeId: employee.EmployeeId,
        validDays: employee.ValidDays,
        startDate: employee.StartDate,
        endDate: employee.EndDate,
        id: employee.AssignedId,
      });
    }
  }, [employee]);
  const [state, setState] = useState({
    courseId: 0,
    employeeId: 0,
    startDate: null,
    endDate: null,
    validDays: "",
    id: 0,
  });

  const baseURL = getFullURL(useSelector, "");

  const updateAssignCourses = async () => {
    const param = {...state};
    const res: any = await dispatch(editAssignCourse(param));
    if (res) {
      onModalClose();
      onSuccessSubmitting();
    }
  };
  const isDisabled = () => {
    const {startDate, endDate, validDays} = state;
    return isEmpty(startDate) || isEmpty(endDate) || isEmpty(validDays);
  };

  return (
    employee && (
      <Modal
        open={open}
        onModalClose={onModalClose}
        title="Edit Assignment"
        width="650px"
      >
        <div className="course-assign__editmodal-content">
          <div className="course-assign__editmodal-body">
            <div className="course-assign__editmodal-row">
              <div className="course-assign__editmodal-employee">
                <Avatar
                  className="team-image"
                  name={employee.EmployeeName}
                  size={"34"}
                  round={true}
                  maxInitials={2}
                  textSizeRatio={4}
                  fgColor={"#fff"}
                  src={`${baseURL}`}
                />
                <span className="course-assign__editmodal-employee-name">
                  {employee.EmployeeName}
                </span>
              </div>
            </div>
            <div className="course-assign__editmodal-row flex">
              <div className="course-assign__editmodal-course">
                <RiBook2Line />
                <span>{employee.CourseTitle}</span>
              </div>
            </div>
            <div className="course-assign__editmodal-row">
              <AccessValidityForm
                value={state}
                setValue={(val) => setState({...state, ...val})}
              />
            </div>
          </div>
          <div className="course-assign__editmodal-footer">
            <Button
              title="Cancel"
              bg="white"
              onClick={() => {
                onModalClose();
              }}
            />
            <Button
              isDisabled={isDisabled()}
              title="Save"
              buttonClass="btn btn-primary"
              onClick={() => updateAssignCourses()}
            />
          </div>
        </div>
      </Modal>
    )
  );
};

export default EditAssignment;
