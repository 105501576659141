// @ts-nocheck 
import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import FormGroup from "components/form";
import {GetBranches, GetDepartments} from "actions/commonAction";

import {getCourseResultsByCourseId, handleChangeFilterValue} from "../helpers/action";

const CourseResultsDetailFilter = ({handleSearchEmployee, employeeName}) => {
  const {branchList, departmentList} = useSelector((state: any) => state.commonReducer);
  const {filterValues} = useSelector((state: any) => state.lmsReducer);

  const {branchId, departmentId} = filterValues;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetBranches());
    dispatch(GetDepartments());
  }, []);

  useEffect(() => {
    dispatch(
      getCourseResultsByCourseId({
        ...filterValues,
        branchId: branchId?.value ?? -1,
        departmentId: departmentId?.value ?? -1,
      })
    );
  }, [branchId, departmentId]);

  const handleAutoSelect = (name, selected) => {
    dispatch(handleChangeFilterValue(name, selected));
  };

  return (
    <div className="flex">
      <FormGroup
        className="search"
        label="Search Employee"
        onChange={handleSearchEmployee}
        name="employeeName"
        value={employeeName}
        placeholder=""
        width="226px"
      />

      <FormGroup
        formName="reactselect"
        onChange={handleAutoSelect}
        name="branchId"
        label={"Branch"}
        value={branchId}
        options={branchList}
        placeholder="Select Branch"
        width="226px"
      />

      <FormGroup
        formName="reactselect"
        onChange={handleAutoSelect}
        name="departmentId"
        label={"Department"}
        value={departmentId}
        options={departmentList}
        placeholder="Select Department"
        width="226px"
      />
    </div>
  );
};

export default CourseResultsDetailFilter;
