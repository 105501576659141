// @ts-nocheck 
import ReactPDF, {
    Document,
    Page,
    Text,
    PDFViewer,
    View,
    Image,
    StyleSheet,
  } from "@react-pdf/renderer";
import parseQuery from 'helpers/parseQuery';
import isEmpty from 'isEmpty';
import React from "react";
// Create styles
const styled = StyleSheet.create({
    page: {
      display: "flex",
      flexDirection: 'column',
      // backgroundColor: '#E4E4E4',
      border: '1px solid #8e8e8e',
    },
    pageSchedule: {
      display: "flex",
      flexDirection: 'row',
      // backgroundColor: '#E4E4E4',
      border: '1px solid #8e8e8e',
    },
    section: {
      fontSize: 13,
      flexGrow: 1,
      flexDirection: 'column',
      minWidth: 175,
      borderRight: '1px solid #8e8e8e',
    },
    sec: {
      flexGrow: 1,
      flexDirection: 'column',
      display: 'flex',
      padding: 12,
      fontSize: 13,
      width: 175,
      minWidth: 175,
      borderBottom: '1px solid #8e8e8e',
      // borderTop: '1px solid #8e8e8e',
      // textAlign:'center',
      backgroundColor: "#f2f2f2",
      borderRight: '1px solid #8e8e8e',
      maxHeight: 60,
    },
    date: {
      display: 'flex',
      borderRight: '1px solid #8e8e8e',
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: 14,
      width: 175,
      minWidth: 175,
      // minHeight:48,
      maxHeight: 34,
      flexGrow: 1,
      padding: 8,
      borderBottom: '1px solid #8e8e8e',
      backgroundColor: '#bfbfbf'
    },
    textBold: {
      // textAlign:' center',
      fontWeight: 'bold',
      fontSize: 14,
    },
    textNormal: {
      // textAlign:'center',
      fontWeight: 'normal',
      fontSize: 13,
    },
    company:{
      fontWeight: 'bold',
      fontSize: 17,
      marginBottom:12
    },
    lightCompany:{
      fontWeight: 'normal',
      fontSize: 17,
      marginBottom:12
    },
    row:{
      display: 'flex',
      flexDirection:'row',
      alignItems:'center',
      gap:12
    },
    role: {
      flexGrow: 1,
      flexDirection: 'column',
      display: 'flex',
      padding: 8,
      backgroundColor: '#ffffff',
      borderBottom: '1px solid #8e8e8e',
    },
    companyContainer:{
      display: 'flex',
      flexDirection:'column',
      marginBottom:2,
      padding: 16,
    },
    container: {
      flex: 1,
      justifyContent: "flex-start",
      alignItems: "stretch",
      height: "100%",
      width: "100%",
    },
  });
  


const SchedulePdfContainer = (props) => {
    console.log({props})
    const {dates,companyInformation,schedules} = props;
    return(
        // <PDFViewer style={styled.container}>
        
        <Document>
          <Page size="A2" style={styled.page}>
            <View style={styled.companyContainer} >
              <Text style={styled.company}>{companyInformation?.Name}</Text> 
              <Text style={styled.company}>{companyInformation?.Address}</Text> 
              <View style={styled.row}>
                <Text style={styled.company}>Shift Schedule : </Text>
                <Text style={styled.lightCompany}> {dates?.startDate + ' - ' + dates?.endDate}</Text>
              </View> 
            </View>
            <View style={styled.pageSchedule}>
            {
              schedules && schedules.map(item => {
                return (<View style={styled.section}>
                  <View style={styled.date} >
                    <Text>{item?.dayMonthWeek}</Text>
                  </View>
                  {
                    !isEmpty(item.data2) &&
                    Object.keys(item.data2).map((duration) => {
                      let timeData = item.data2[duration] && item.data2[duration][0];
                      return (
                        <>
                          <View style={styled.sec} >
                            <Text style={{ ...styled.textBold, fontWeight: 'bold', marginBottom: 6 }}>{timeData?.ShiftName}</Text>
                            <Text style={styled.textNormal}>{duration}</Text>
                          </View>
                          {
                            timeData.employees && Object.keys(timeData.employees) && Object.keys(timeData.employees).map((role) => {
                              return <View style={styled.role} >
                                <Text style={
                                  {
                                    ...styled.textBold,
                                    // borderBottom:'1px solid #333',
                                    textDecoration: 'underline',
                                    marginBottom: 8
                                  }
                                }>{role}</Text>
                                {
                                  timeData.employees[role]
                                  && timeData.employees[role].map((emp, index) => {
                                    return <Text style={{ ...styled.textNormal, marginBottom: 4 }}>{emp.EmployeeName}</Text>
                                  })
                                }
                              </View>
                            })
                          }
                        </>

                      )

                    })
                  }

                </View>)
              })
            }
            </View>
          </Page>
        </Document>
    //   </PDFViewer>
    )
}


export default SchedulePdfContainer