// @ts-nocheck 
import RouteEnum from "constants/routeEnum";
import dayjs from "dayjs";
import React from "react";
import { FaFeather } from "react-icons/fa";
import { useHistory } from "react-router";
import ImageRenderer from "scenes/lms/common/imageRenderer";
import { EmployeeCourseAssignStatus } from "scenes/lms/helpers/enums";
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const AssigneCourseCard = (props) => {
  const {item} = props;

  const history = useHistory();
  const getDecimalPercentage = (number) => {
    let num = parseFloat(number);
    if (num % 1 === 0) return num;
    else return num.toFixed(2);
  };
  return (
    <div
      className="courses-assigned__card"
      onClick={() => {
        const {AssignedStatus} = item;
        // if (EmployeeCourseAssignStatus.Completed === AssignedStatus) return;
        history.push(`${RouteEnum.lmsCourse}/${item.Id}/${item.AssignedId}`);
      }}
    >
      <div className="courses-assigned__image">
        <ImageRenderer src={item.ServerFileName} />
      </div>
      <div className="courses-assigned__body">
        <div className="courses-assigned__title">
          <span>{item.Title}</span>
        </div>
        <div className="courses-assigned__to">
          <FaFeather />
          <span>{item.Author}</span>
        </div>

        <div className="courses-assigned__type">{item.CategoryName}</div>
        <div className="courses-assigned__status">
          {EmployeeCourseAssignStatus.NotStarted === item.AssignedStatus ? (
            <span className="bold">Not Started</span>
          ) : (
            <span>
              <span className="bold">{getDecimalPercentage(item.PercentComplete)}</span>%
              Complete
            </span>
          )}
          <span>
            Due <span className="bold">{dayjs(item.DueDate).format("ll")}</span>
          </span>
        </div>
        <div className="courses-assigned__status-bar">
          <div
            style={{width: `${item?.PercentComplete}%`}}
            className="courses-assigned__status-active"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default AssigneCourseCard;
