import {
    GET_LEAVE_REQUEST_STATUS,
    LEAVE_ABSENCE_LOADING,
    LEAVE_REQUEST_STATUS_LOADING,
    SEARCHED_LEAVE_ABSENCE,
    LEAVE_SOURCE_SELECT_LOADING,
    GET_LEAVE_SOURCE,
  } from '../../../actions/types';
  
  const initialState = {
    leaveStatus: {loading:false,value:[]},
    searchedLeaveAbsence:[],
    searchedPage:{index:0,total:0},
    loading: false,
    leaveSource: {loading:false,value:[]}
  };

  export default function(state = initialState, action) {
    switch (action.type) {
      case LEAVE_ABSENCE_LOADING:
        return {
          ...state,
          loading: true
        };
      case LEAVE_REQUEST_STATUS_LOADING:
        return {
          ...state,
          leaveStatus: {loading:true,value:[]},
        };
        case LEAVE_SOURCE_SELECT_LOADING:
          return {
            ...state,
            leaveSource: {loading:true,value:[]}
          };
        case GET_LEAVE_SOURCE:
          return {
            ...state,
          leaveSource: {loading:false,value:action.payload},
        };
      case GET_LEAVE_REQUEST_STATUS:
        return {
          ...state,
          leaveStatus: {loading:false,value:action.payload},
        };
      case SEARCHED_LEAVE_ABSENCE:
            return {
              ...state,
              searchedLeaveAbsence: action.payload,
              searchedPage:{index:action.page.index,total:action.page.total},
              loading: false
            };
      default:
        return state;
    }
  }
  