
import { z } from "zod"
import { exceptionEmployeeSchema } from "./employee-exceptions"

const teamAttendanceSchema = z.object({
    ExceptionEmployee: z.array(
        exceptionEmployeeSchema
    ),
})

export type ITeamAttendance = z.infer<typeof teamAttendanceSchema> | null;