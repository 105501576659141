// @ts-nocheck 
import React, {useState} from "react";
import {FaFont, FaPlusCircle} from "react-icons/fa";
import {MdCheckBox} from "react-icons/md";
import {BsTextareaT} from "react-icons/bs";
import {IoMdRadioButtonOn} from "react-icons/io";
import shortid from "shortid";
// import QuestionLabel from "./questionLabel";
// import Question from "./question";
import QuestionControl from "../../../common/questionControl";
import {useDispatch} from "react-redux";
import Button from "components/button";
import {v4 as uuidv4} from "uuid";
import QuestionSection from "./questionSection";
import {getNewQuestionObject} from "../../../helpers/constants";
import {addCourseQuestion} from "scenes/lms/helpers/action";
import FormGroup from "components/formGroup";
import {useForm} from "react-hook-form";

const AddQuestions = (props) => {
  const {courseId, pageId} = props;
  const [sections, setSections] = useState([[getNewQuestionObject(uuidv4())]]);

  const dispatch = useDispatch();
  const methods = useForm();
  const onSectionAdd = () => {
    setSections((prev) => [...prev, [getNewQuestionObject(uuidv4())]]);
  };

  const onQuestionRemove = (id) => {
    //setState((prev) => prev.filter((item) => item.id !== id));
  };
  const onQuestionSave = async (data) => {
    var param = [];
    sections.forEach((section, sectionOrder) => {
      section.forEach((item: any, questionOrder) => {
        const qn = {
          courseId: courseId,
          courseOutlineId: pageId,
          questionType: item?.questionType?.value,
          question: item?.question,
          isRandomised: item?.isRandomised,
          answerLimitFrom: item?.answerLimitFrom,
          answerLimitTo: item?.answerLimitTo,
          isOtherPresent: item?.isOtherPresent,
          section: item?.section,
          answerType: item?.answerType,
          sectionOrder: sectionOrder + 1,
          questionOrder: questionOrder + 1,
          answerOptions: item?.answerOptions?.map((x) => ({
            //questionId: editQuestionData.id,
            answerOption: x.answerOption,
            isCorrect: x.isChecked,
            point: x.point,
          })),
        };
        param.push(qn);
      });
    });

    await dispatch(addCourseQuestion(param));
    setSections((prev) => []);
    props?.clear();
  };
  const handleChange = async (name, value) => {
    // setEditQuestionData((prev) => {
    //   let newData = {...prev};
    //   if (name === "questionType" && (value.value === 3 || value.value === 4)) {
    //     newData[name] = {...value, answerType: AnswerType.normal};
    //     const options = [
    //       {isChecked: false, answerOption: "", marks: 0},
    //       {isChecked: false, answerOption: "", marks: 0},
    //     ];
    //     newData["answerOptions"] = options;
    //   } else {
    //     newData[name] = value;
    //   }
    //   return newData;
    // });
  };
  const handleCancelClicked = () => {
    setSections((prev) => []);
    props?.clear();
  };
  return (
    <FormGroup method={methods} defaultValues={{}} onSubmit={onQuestionSave}>
      <div className="lms-questions">
        {sections &&
          sections.map((section, index) => {
            return (
              <QuestionSection
                key={index}
                methods={methods}
                section={[...section]}
                setSections={setSections}
                sectionIndex={index}
              />
            );
          })}
        <div className="lms-questions__add">
          <div onClick={() => onSectionAdd()} className="lms-questions__add-section">
            <FaPlusCircle />
            <span>Add a section</span>
          </div>
        </div>
        <div className="lms-questions__save-actions lms-questions__add-question-btn">
          <Button onClick={handleCancelClicked} title="Cancel" size="large" />
          <Button
            // onClick={() => onQuestionSave()}
            htmlButtonType="submit"
            title="Save Questions"
            bg="green"
            size="large"
          />
        </div>
      </div>
    </FormGroup>
  );
};
export default AddQuestions;
