// @ts-nocheck 
import React, {useState, useEffect} from "react";
import {otherInputInterface} from "ts/interfaces/formInterface";
import styled from "styled-components";

const CharacterCounter = styled.span`
  padding-top: 5px;
  width: 100%;
  text-align: right;
  color: #ababab;
  font-weight: 400;
  font-size: 14px;
  display: block;
  position: absolute;
`;

const TextArea = (props: otherInputInterface) => {
  const {name, type, value, autoFocus, disabled, placeholder, rows, cols, maxLength} =
    props;

  const [characterCount, setCharacterCount] = useState(0);

  useEffect(() => {
    if (maxLength) {
      setCharacterCount(value?.length ?? 0);
    }
  }, [value]);

  const handleChange = (event) => {
    const {name, value} = event.target;
    props.onChange && props.onChange(name, value);
  };

  const handleKeyUp = (event) => {
    const {name, value} = event.target;
    props.onKeyUp && props.onKeyUp(name, value,event);
  };

  const handleBlur = (event) => {
    const {name, value} = event.target;
    props.onBlur && props.onBlur(name, value);
  };
  const handleKeyPress = (event) => {
    props.onKeyPress && props.onKeyPress(event);
  };

  return (
    <div className="position-relative">
      <textarea
        className="genericForm-group__textarea"
        placeholder={placeholder}
        value={value}
        name={name}
        disabled={disabled}
        autoFocus={autoFocus}
        onKeyUp={handleKeyUp}
        onKeyPress={handleKeyPress}
        onBlur={handleBlur}
        onChange={handleChange}
        rows={rows}
        cols={cols}
        maxLength={maxLength}
      />
      {maxLength && (
        <CharacterCounter>
          {characterCount}/{maxLength}
        </CharacterCounter>
      )}
    </div>
  );
};

TextArea.defaultProps = {
  name: "",
  type: "text",
  autoFocus: false,
};

export default TextArea;
