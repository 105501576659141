import * as fromSlice from "./slice";
import * as fromInterface from "./../../../ts";

const selectCurrentEmployeeTypeSlice = (
  state: any
): fromSlice.EmployeeTypeState => {
  return state.dataStore.employeeType;
};

export const selectEmployeeTypeItems = (state: any): any[] => {
  return selectCurrentEmployeeTypeSlice(state).items;
};

export const selectEmployeeTypeStatus = (
  state: any
): fromInterface.loadingType => {
  return selectCurrentEmployeeTypeSlice(state).status;
};

export const selectEmployeeTypeLoaded = (state: any): boolean => {
  return selectCurrentEmployeeTypeSlice(state).loaded;
};
