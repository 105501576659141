import {
  Checkbox as ChakraCheckbox,
  FormControl,
  CheckboxProps as ChakraCheckboxProps,
  FormLabel,
  Icon,
} from '@chakra-ui/react'
import merge from 'lodash/merge'
import { Controller } from 'react-hook-form'
import { resolveObjectValueByPath } from '../../../../helpers'
import FormErrorLable from '../form-error-text'

interface BaseCheckboxProps {
  name: any
  label?: any
  value?: any
  width?: any
  control?: any
  ignoreControl?: boolean
  required?: boolean
  errors?: any
  rule?: any
  onChange?: any
  onChangeRHF?: any
  customLabel?: any
}

interface CheckBoxComponentProps extends BaseCheckboxProps { }
export const CheckBoxComponent = (props: CheckBoxComponentProps) => {
  // remove custom props and spread rest
  let {
    label,
    name,
    value,
    onChange: _onChange,
    onChangeRHF,
    customLabel,
    ...rest
  } = props

  const handleChange = (e: any) => {
    let { checked } = e.target
    _onChange?.(name, checked)
    onChangeRHF?.(checked)
  }

  return (
    <ChakraCheckbox
      display="flex"
      alignItems="center"
      isChecked={value}
      size="md"
      onChange={handleChange}
      {...rest}
    >
      {customLabel ? customLabel : <p style={{ fontSize: "14px" }}>{label}</p>}
    </ChakraCheckbox>
  )
}
interface CheckboxProps
  extends BaseCheckboxProps,
  Omit<ChakraCheckboxProps, 'name' | 'value' | 'width' | 'onChange'>,
  Record<any, any> { }

export const Checkbox = (props: CheckboxProps) => {
  const {
    width,
    control,
    ignoreControl = false,
    required = false,
    errors,
    rule,
  } = props

  const error = errors && resolveObjectValueByPath(errors, props.name)?.message

  let _rule = {
    required: {
      value: required,
      message: 'required',
    },
  }

  if (rule) {
    _rule = merge(_rule, rule)
  }

  if (!control || ignoreControl) {
    return (
      <FormControl id={props.name} isRequired={required} style={{ width }}>
        <CheckBoxComponent {...props} />
        <FormErrorLable error={error} />
      </FormControl>
    )
  }

  return (
    <FormControl id={props.name} isRequired={required} style={{ width }}>
      <Controller
        control={control}
        name={props.name}
        rules={_rule}
        render={(controllerProps: any) => {
          const {
            field: { onChange: _onChange, value: _value },
          } = controllerProps
          return (
            <CheckBoxComponent
              {...props}
              value={_value}
              onChangeRHF={_onChange}
            />
          )
        }}
      />
      <FormErrorLable error={error} />
    </FormControl>
  )
}

export default Checkbox
