import React, { useContext } from 'react'
import {Box} from '@chakra-ui/react';
import {CheckIcon} from '@chakra-ui/icons';
import StepperContext,{StepperEnums} from './context';

function Stepper() {
    const value = useContext(StepperContext);
    return (
        <Box maxW={"800px"} justifyContent={"space-between"} className='two-steppers' display="flex" alignItems="center" margin="0 auto" gap="32px" position="relative" padding="12px" >
            <Box position="absolute" width="calc(100% - 120px)" zIndex={"-1"} backgroundColor="#efefef" h="2px" borderRadius={"12px"} left="50%" top="50%" transform={"translate(-50%,-50%)"} ></Box>
            {value.steps.map(step=>{
                return <StepperItem key={"Stepper-" + step.title} number={step.step} title={step.title}  />
            })}
        </Box>
    )
}

interface IStepperItem{
    number:StepperEnums,
    title:string,
}

const StepperItem = (props:IStepperItem) =>{
    const {number,title,} = props;
    const value = useContext(StepperContext);
    const isActive = value.activeStep === number;
    const isCompleted = value.activeStep > number;
    const activeColor = isActive ? "#0875e1" : isCompleted ? "#333d47" : "#7b858f" ; 
    return (
        <Box padding={"4px 14px"} backgroundColor={"#fff"} className="two-stepper" display="flex" alignItems="center" gap="8px"  >
            <Box className="two-stepper__icon center" fontWeight={500} fontSize={"13px"} backgroundColor={activeColor} color={"#fff"} w="24px" h="24px" borderRadius="50%"  >
                {
                    isCompleted ? <CheckIcon color={"#fff"} fontSize={"13px"} /> : number
                }
            </Box>
            <Box color={activeColor} className="two-stepper__title" fontWeight={500} fontSize={"16px"} >
                {title}
            </Box>
        </Box>
    )
}

export default Stepper