enum LMSRoutes {
  lmsRoutes = '/lms',
  lmsCourse = '/lms/course',
  lmsCoursePages = '/lms/course/:id/pages',
  lmsCourseAdd = '/lms/courses/add',
  lmsCourseAssign = '/lms/courses/assign',
  lmsCourseManagement = '/lms/courses/management',
  lmsCourseSettings = '/lms/courses/settings',
  lmsCourseResults = '/lms/courses/results',
  lmsCourseCategories = '/lms/courses/categories',
  lmsCourseCertificateSettings = "/lms/courses/certificate-settings"
}

export default LMSRoutes;