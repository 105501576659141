/**
 * Software Design Document
 * Imported Members
 * - {value, onChange}
 *
 * Exported Members
 * - expanded
 * - setExpanded
 * - referenceElement
 * - setReferenceElement
 * - popperElement
 * - setPopperElement
 * - handleHrClick
 * - handleMinClick
 * - handleSecClick
 * - handleAmPmClick
 * - handleInputChange
 * - state
 *
 *
 *
 * @param state
 * @param action
 * @returns
 */

import { SyntheticEvent, useEffect, useReducer } from 'react';
import {
  TimePickerActions,
  TimePickerStateProps,
  UseTimePickerProps,
} from '../interfaces';

const getValueFromState = (state: TimePickerStateProps) => {
  let defaultVal = '';

  defaultVal += `${(state.hr ?? 0) < 10 ? '0' + state.hr : state.hr}`;
  defaultVal += `:${(state.min ?? 0) < 10 ? '0' + state.min : state.min}`;

  if (state.showSec) {
    defaultVal += `:${(state.sec ?? 0) < 10 ? '0' + state.sec : state.sec}`;
  }

  if (state.format === '12hr') {
    defaultVal += `:${state.modulation}`;
  }
  return defaultVal;
};

const initialState: TimePickerStateProps = {
  inputValue: '',
  defaultVal: '19:45:00:pm',
  hr: 0,
  min: 0,
  sec: 0,
  modulation: '',
  showConfirm: true,
  showHour: true,
  showMin: true,
  showSec: true,
  format: '12hr',
};

const reducer = (state: TimePickerStateProps, action: any) => {
  switch (action.type) {
    case TimePickerActions.SET_INITIAL_STATE: {
      const newState = { ...state, ...action.payload };
      const defaultVal = getValueFromState(newState);
      return { ...newState, defaultVal };
    }

    case TimePickerActions.SET_STATE: {
      const nextState = {
        ...state,
        [action.payload.key]: action.payload.value,
      };

      const val = getValueFromState(nextState);
      return { ...nextState, defaultVal: val };
    }
    default:
      return state;
  }
};

export const useTimePicker = (props: UseTimePickerProps) => {
  const {
    value,
    format,
    showConfirm,
    showHour,
    showMin,
    showSec,
    disabledHrs,
    disabledMins,
    disabledSecs,
    onChange,
  } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const hrsToRender = Array.from(
      { length: format === '12hr' ? 12 : 24 },
      (_, i) => (format === '12hr' ? i + 1 : i)
    ).filter((item: number) => !disabledHrs?.includes(item));

    const minsToRender = Array.from({ length: 60 }, (_, i) => i).filter(
      (item: number) => !disabledMins?.includes(item)
    );

    const secsToRender = Array.from({ length: 60 }, (_, i) => i).filter(
      (item: number) => !disabledSecs?.includes(item)
    );

    let hr = 0;
    let min = 0;
    let sec = 0;
    let modulation = 'am';

    if (value) {
      const splitted = value.split(':');
      hr = parseInt(splitted[0], 10);
      min = parseInt(splitted[1], 10);
      sec = parseInt(splitted[2], 10) ?? 0;
      modulation = splitted[3];
    }

    const payload: TimePickerStateProps = {
      hr,
      min,
      sec,
      modulation,
      showConfirm,
      showHour,
      showMin,
      showSec,
      format,
      hrsToRender,
      minsToRender,
      secsToRender,
    };

    dispatch({
      type: TimePickerActions.SET_INITIAL_STATE,
      payload,
    });
  }, [disabledHrs, disabledMins, disabledSecs, format, showConfirm, showHour, showMin, showSec, value]);

  useEffect(() => {
    onChange?.(state.defaultVal);
  }, [onChange, state.defaultVal]);

  const handleHrClick = (num: any) => {
    dispatch({
      type: TimePickerActions.SET_STATE,
      payload: { value: num, key: 'hr' },
    });
  };

  const handleMinClick = (num: any) => {
    dispatch({
      type: TimePickerActions.SET_STATE,
      payload: { value: num, key: 'min' },
    });
  };

  const handleSecClick = (num: any) => {
    dispatch({
      type: TimePickerActions.SET_STATE,
      payload: { value: num, key: 'sec' },
    });
  };

  const handleAmPmClick = (ampm: any) => {
    dispatch({
      type: TimePickerActions.SET_STATE,
      payload: { value: ampm, key: 'modulation' },
    });
  };

  const handleInputChange = (evt: any) => {
    const value = evt.target.value;

    const valueSplited = value.split(":")
    const hr = valueSplited[0]
    const min = valueSplited[1]
    const sec = valueSplited[2]
    const modulation = valueSplited[3]

    // dispatch({
    //   type: TimePickerActions.SET_STATE,
    //   payload: { key: 'inputValue', value: value },
    // });
    dispatch({
      type: TimePickerActions.SET_STATE,
      payload: { key: 'defaultVal', value: value },
    });
    dispatch({
      type: TimePickerActions.SET_STATE,
      payload: { key: 'hr', value: hr },
    });
    dispatch({
      type: TimePickerActions.SET_STATE,
      payload: { key: 'min', value: min },
    });
    dispatch({
      type: TimePickerActions.SET_STATE,
      payload: { key: 'sec', value: sec },
    });
    dispatch({
      type: TimePickerActions.SET_STATE,
      payload: { key: 'modulation', value: modulation },
    });
  };

  return {
    handleHrClick,
    handleMinClick,
    handleSecClick,
    handleAmPmClick,
    handleInputChange,
    state,
  };
};
