// @ts-nocheck 
import classNames from "classnames";
import React from "react";
interface iprops {
  label: any;
  icon: any;
  selectedValue?: any;
  value?: any;
}
const QuestionLabel = (props: iprops) => {
  const {label, icon, selectedValue, value} = props;
  return (
    <div
      className={classNames("question-label", {active: selectedValue?.value == value})}
    >
      <div className="question-label__icon">{icon}</div>
      <div className="question-label__text">{label}</div>
    </div>
  );
};
export default QuestionLabel;
