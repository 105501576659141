import {
    ATTENDANCE_LOADING,
    GET_COST_CODES,
    GET_UNIT_LIST, 
    COST_CODES_LOADING,
    UNIT_LIST_LOADING,
    GENERATE_ATTENDANCE_REGISTER,
    COLORS_LOADING,
    GET_COLORS,
    SEARCHED_ATTENDANCE_REGISTER,
  } from '../../../actions/types';
  
  const initialState = {
    searchedAttendanceRegister:[],
    costcodes: {loading:false,value:[]},
    unitlist: {loading:false,value:[]},
    colors: {loading:false,value:[]},
    searchedPage:{index:0,total:0},
    loading: false,
  };

  export default function(state = initialState, action) {
    switch (action.type) {
      case ATTENDANCE_LOADING:
        return {
          ...state,
          // loading: true
          loading: false
        };
      case COST_CODES_LOADING:
        return {
          ...state,
          costcodes: {loading:true,value:[]},
        };
      case COLORS_LOADING:
        return {
          ...state,
          colors: {loading:true,value:[]},
        };
      case GET_COST_CODES:
        return {
          ...state,
          costcodes: {loading:false,value:action.payload},
        };
      case GET_COLORS:
        return {
          ...state,
          colors: {loading:false,value:action.payload},
        };
      case UNIT_LIST_LOADING:
        return {
          ...state,
          unitlist: {loading:true,value:[]},
        };
      case GET_UNIT_LIST:
        return {
          ...state,
          unitlist: {loading:false,value:action.payload},
      };
      case GENERATE_ATTENDANCE_REGISTER:
        return{
          ...state,
          searchedAttendanceRegister: action.payload,
          searchedPage:{index:action.page.index,total:action.page.total},
          loading: false
      };
      case SEARCHED_ATTENDANCE_REGISTER:
            return {
              ...state,
              searchedAttendanceRegister: action.payload,
              searchedPage:{index:action.page.index,total:action.page.total},
              loading: false
            };
      default:
        return state;
    }
  }
  