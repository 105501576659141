// @ts-nocheck 
import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { CourseStatus } from "../helpers/enums";
import { useSelector } from "react-redux";

const initialState = {
  TotalCourse: 0,
  TotalHistoryCourse: 0,
  TotalNotPublishedCourse: 0,
  TotalPublishedCourse: 0,
};
const CourseManagementTags = ({ onCourseStatus, activeTab }) => {
  const [courseQty, setCourseQty] = useState(initialState);
  const { courseCategories, courseList } = useSelector((state: any) => state.lmsReducer);
  useEffect(() => {
    if (courseList && courseList.length > 0) {
      const crs = courseList[0];
      setCourseQty((prev) => {
        return {
          TotalCourse: crs.TotalCourse,
          TotalHistoryCourse: crs.TotalHistoryCourse,
          TotalNotPublishedCourse: crs.TotalNotPublishedCourse,
          TotalPublishedCourse: crs.TotalPublishedCourse,
        };
      });
    }
  }, [courseList]);

  return (
    <div className="course-management__tags">
      <div
        onClick={() => onCourseStatus(-1)}
        className={classNames("course-management__tag", {
          "courses-active": activeTab === -1,
        })}
      >
        <div className="course-management__tag-num">{courseQty.TotalCourse}</div>
        <div className="course-management__tag-text">Course</div>
      </div>
      <div
        onClick={() => onCourseStatus(CourseStatus.Publish)}
        className={classNames("course-management__tag", {
          "courses-active": activeTab === CourseStatus.Publish,
        })}
      >
        <div className="course-management__tag-num">{courseQty.TotalPublishedCourse}</div>
        <div className="course-management__tag-text">Published</div>
      </div>
      <div
        onClick={() => onCourseStatus(CourseStatus.Unpublish)}
        className={classNames("course-management__tag", {
          "courses-active": activeTab === CourseStatus.Unpublish,
        })}
      >
        <div className="course-management__tag-num">
          {courseQty.TotalNotPublishedCourse}
        </div>
        <div className="course-management__tag-text">Not Published</div>
      </div>
      <div
        onClick={() => onCourseStatus(CourseStatus.Discontinue)}
        className={classNames("course-management__tag", {
          "courses-active": activeTab === CourseStatus.Discontinue,
        })}
      >
        <div className="course-management__tag-num">{courseQty.TotalHistoryCourse}</div>
        <div className="course-management__tag-text">History</div>
      </div>
    </div>
  );
};
export default CourseManagementTags;
