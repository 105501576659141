import { RigoComponent } from './rigo-component';
import { RigoDefault } from './rigo-default';
import { RigoFormControl } from './rigo-form-control';
import { RigoFormErrorLabel } from './rigo-form-error-label';
import { RigoFormHelperText } from './rigo-form-helper-text';
import { RigoFormLabel } from './rigo-form-label';
import { RigoFroalaEditor } from './rigo-froala-editor';

export const FroalaEditorV2 = (props: any) => {
  return <RigoFroalaEditor {...props} />;
};

FroalaEditorV2.Default = RigoDefault;
FroalaEditorV2.FormLabel = RigoFormLabel;
FroalaEditorV2.HelperText = RigoFormHelperText;
FroalaEditorV2.ErrorLabel = RigoFormErrorLabel;
FroalaEditorV2.FormControl = RigoFormControl;
FroalaEditorV2.Component = RigoComponent;

export default FroalaEditorV2;
