import * as fromInterface from "../../../ts";
import { BranchesWorkSitesState } from "./slice";

const selectBranchesWorkSitesCurrentSlice = (
  state: any
): BranchesWorkSitesState => {
  return state.dataStore.branchesWorkSites;
};

export const selectBranchesWorkSitesStatus = (
  state: any
): fromInterface.loadingType => {
  return selectBranchesWorkSitesCurrentSlice(state).status;
};

export const selectBranchesWorkSitesLoaded = (state: any): boolean => {
  return selectBranchesWorkSitesCurrentSlice(state).loaded;
};

export const selectBranchesWorkSitesItems = (state: any) => {
  return selectBranchesWorkSitesCurrentSlice(state).items;
};
