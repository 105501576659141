import * as fromSlice from "./slice";
import * as fromInterface from "../../ts";

const selectCurrentPersonalInfoSlice = (
  state: any
): fromSlice.PersonalInfoState => {
  return state.dataStore.personalInfo;
};

export const selectEmployeeDetails = (state: any): any => {
  return selectCurrentPersonalInfoSlice(state).employeeDetails;
};

export const selectEmployeeDetailStatus = (
  state: any
): fromInterface.loadingType => {
  return selectCurrentPersonalInfoSlice(state).employeeDetailsStatus;
};

export const selectEmployeeDetailLoaded = (state: any): boolean => {
  return selectCurrentPersonalInfoSlice(state).employeeDetailsLoaded;
};

export const selectPendingRequests = (state: any): any => {
  return selectCurrentPersonalInfoSlice(state).pendingRequests;
};

export const selectPendingRequestsStatus = (
  state: any
): fromInterface.loadingType => {
  return selectCurrentPersonalInfoSlice(state).pendingRequestsStatus;
};

export const selectPendingRequestsLoaded = (state: any): boolean => {
  return selectCurrentPersonalInfoSlice(state).pendingRequestsLoaded;
};

export const selectedSaveEmployeePersonalInfoStatus = (
  state: any
): fromInterface.loadingType => {
  return selectCurrentPersonalInfoSlice(state).savePersonalInfoStatus;
};
