
import * as fromSelector from "../selectors";
import { setStep } from "./set-step";

export const back = (): any => (dispatch: any, getState: any) => {
    const state = getState()
    const currentStep = fromSelector.selectCurrentStep(state)

    const currentStepIndex = currentStep.index

    dispatch(setStep(currentStepIndex as number - 1))
}