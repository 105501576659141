import * as fromInterface from "../../../ts";
import * as fromConstant from "../../../constants";
import { ApiClient } from "../../../network/apiClient";
import * as fromSlice from "./slice";
import {
  getShowHideColumns,
  postHiddenColumns,
  resetToDefaultColumns,
  getdefaultHiddenColumns,
} from "./api";

export const fetchShowHideColumn =
  (page?: any) => async (dispatch: any, getState: any) => {
    dispatch(fromSlice.fetchRequest());

    const response: any = await ApiClient.get({
      endpoint: getShowHideColumns,
      dispatch: dispatch,
    });

    dispatch(fromSlice.fetchSuccess(response?.Data));

    return response;
  };

export const fetchDefaultShowHideColumn =
  (page?: any) => async (dispatch: any, getState: any) => {
    dispatch(fromSlice.fetchRequest());

    const response: any = await ApiClient.get({
      endpoint: getdefaultHiddenColumns,
      dispatch: dispatch,
    });

    dispatch(fromSlice.fetchSuccess(response?.Data));

    return response;
  };

export const postShowHideColumns =
  (columns?: any, cb?: any): any =>
  async (dispatch: any, getState: any) => {
    const param = {
      endpoint: `${postHiddenColumns}/${columns}`,
      dispatch: dispatch,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.post<any>(param);
    const { Data, Status } = response;
    if (Status) {
      dispatch(fetchShowHideColumn());
    }
    cb?.(Status);
  };

export const restShowHideColumns =
  (cb: any): any =>
  async (dispatch: any, getState: any) => {
    const param = {
      endpoint: resetToDefaultColumns,
      dispatch: dispatch,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.post<any>(param);
    const { Data, Status } = response;
    if (Status) {
      dispatch(fetchShowHideColumn());
    }
    cb?.(Status);
  };

export const clearShowHideColumn = () => (dispatch: any) => {
  dispatch(fromSlice.clear());
};
