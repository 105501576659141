import { ApiClient } from "../../../network/apiClient";
import { attendanceReportFilterCountapi } from "./api";
import * as fromSlice from "./slice";

export const fetchAttendanceReportFilterCount =
  (data?: any) => async (dispatch: any, getState: any) => {
    dispatch(fromSlice.fetchRequest());

    const response: any = await ApiClient.get({
      endpoint: `${attendanceReportFilterCountapi}?date=${data}`,
      dispatch: dispatch,
    });

    dispatch(fromSlice.fetchSuccess(response?.Data));

    return response;
  };

export const clearAttendanceReportFilterCount = () => (dispatch: any) => {
  dispatch(fromSlice.clear());
};
