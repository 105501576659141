import { createSlice } from "@reduxjs/toolkit";

import {
  fetchAllManagers,
  fetchAssignedSummary,
  fetchEmployeeList,
  fetchPositionList,
} from "./actions";
import { LoadingStatus } from "../../../constants";

//import { LoadingStatus } from "../../../constants/loadingStatus";

export interface IManagerState {
  loading: {
    all: boolean;
  };
  managers: any;
  assignedSummary: any[];
  positionList: any[];
  employeeList: any[];
}

const initialState: IManagerState = {
  loading: {
    all: LoadingStatus.idle,
  },
  managers: null,
  assignedSummary: [],
  positionList: [],
  employeeList: [],
};

export const managerSlice = createSlice({
  name: "manager",
  initialState,
  reducers: {
    clearManagerState: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllManagers.pending, (state) => {
      state.loading.all = LoadingStatus.loading;
    });
    builder.addCase(fetchAllManagers.fulfilled, (state, action) => {
      state.loading.all = LoadingStatus.idle;
      state.managers = action.payload;
    });
    builder.addCase(fetchAllManagers.rejected, (state) => {
      state.loading.all = LoadingStatus.idle;
    });

    builder.addCase(fetchAssignedSummary.fulfilled, (state, action) => {
      state.assignedSummary = action.payload;
    });

    builder.addCase(fetchEmployeeList.fulfilled, (state, action) => {
      state.employeeList = action.payload;
    });

    builder.addCase(fetchPositionList.fulfilled, (state, action) => {
      state.positionList = action.payload;
    });
  },
});

export const { clearManagerState } = managerSlice.actions;

export default managerSlice.reducer;
