import { PeformanceRolloutInfoState } from "./slice";

const selectPerformanceRolloutInfoSlice = (state: any): PeformanceRolloutInfoState => {
    return state.dataStore.performanceRolloutInfo;
};

export const selectPerformanceRolloutInfo = (state: any): PeformanceRolloutInfoState => {

    return selectPerformanceRolloutInfoSlice(state);
};
