// @ts-nocheck 
import classNames from "classnames";
import React from "react";
import {FiPlus, FiMinus, FiChevronUp, FiChevronDown} from "react-icons/fi";

function Stepper(props) {
  const {
    name,
    type,
    value,
    autoFocus,
    disabled,
    stepperType,
    placeholder,
    minValue,
    maxValue,
  } = props;
  const handleChange = (event) => {
    const {name, value} = event.target;
    if (value > maxValue) {
      props.onChange && props.onChange(name, maxValue);
      return;
    }
    props.onChange && props.onChange(name, value);
  };

  const handleKeyUp = (event) => {
    const {name, value} = event.target;
    props.onKeyUp && props.onKeyUp(event);
  };

  const handleBlur = (event) => {
    const {name, value} = event.target;
    props.onBlur && props.onBlur(name, value);
  };
  const handleFocus = (event) => {
    if (type === "number") {
      event.target.select();
    }
  };
  const decrementValue = async (min = 0) => {
    let newValue = parseInt(value || 0) - 1;
    if (newValue < min) return;
    props.onChange && props.onChange(name, newValue);
  };
  const incrementValue = (max = 100000) => {
    let newValue = parseInt(value || 0) + 1;
    if (newValue > max) return;
    props.onChange && props.onChange(name, newValue);
  };
  return (
    <div
      className={classNames("genericForm-group__stepper", {
        "genericForm-group__stepper-alt": stepperType === "alternative",
      })}
    >
      {stepperType !== "alternative" ? (
        <div className="stepper">
          <button
            type="button"
            disabled={disabled}
            onClick={(e) => {
              decrementValue(minValue);
            }}
            className="stepper-btn stepper-plus"
          >
            <FiMinus />
          </button>
          <div className="stepper-input">
            <input
              name={name}
              min={minValue}
              value={value}
              max={maxValue}
              autoFocus={autoFocus}
              disabled={disabled}
              onKeyUp={handleKeyUp}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={handleChange}
              type="number"
            />
          </div>
          <button
            type="button"
            disabled={disabled}
            onClick={(e) => {
              incrementValue(maxValue);
            }}
            className="stepper-btn stepper-minus"
          >
            <FiPlus />
          </button>
        </div>
      ) : (
        <div className="stepper stepper-alt">
          <div className="stepper-input">
            <input
              name={name}
              min={minValue}
              value={value}
              max={maxValue}
              autoFocus={autoFocus}
              disabled={disabled}
              onKeyUp={handleKeyUp}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={handleChange}
              type="number"
            />
          </div>
          <div className="stepper-alt__btns">
            <button
              type="button"
              disabled={disabled}
              onClick={(e) => {
                incrementValue(maxValue);
              }}
              className="stepper-btn stepper-minus"
            >
              <FiChevronUp />
            </button>
            <button
              type="button"
              disabled={disabled}
              onClick={(e) => {
                decrementValue(minValue);
              }}
              className="stepper-btn stepper-plus"
            >
              <FiChevronDown />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Stepper;
