import React, { useCallback } from "react";

//CHAKRA
import { Box, Button } from "@chakra-ui/react";

//ICONS
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";

//CONSTANTS
import { PAGINATION_CASES } from "../constants";

interface ICustomPaginationButtons {
  DOTS: "DOTS";
  paginationRange: any[];
  CURRENT_PAGE: number;
  handlePagination: (type: number, value?: number) => void | null;
  shouldPaginationBtnDisabled: (type: number) => boolean;
}

const CustomPaginationButtons = (props: ICustomPaginationButtons) => {
  const {
    DOTS,
    paginationRange,
    CURRENT_PAGE,
    handlePagination,
    shouldPaginationBtnDisabled,
  } = props;

  const isActivePage = useCallback(
    (pageNumber: number) => {
      return pageNumber === CURRENT_PAGE ? true : false;
    },
    [CURRENT_PAGE, paginationRange]
  );

  return (
    <Box
      className="custom-pagination__buttons"
      display="flex"
      alignItems="center"
      alignSelf="center"
      paddingLeft="29px"
    >
      <Button
        size="sm"
        variant="ghost"
        border="0px"
        backgroundColor="transparent"
        disabled={shouldPaginationBtnDisabled(PAGINATION_CASES.previousPage)}
        _disabled={{
          border: "0px",
          backgroundColor: "transparent !important",
          opacity: 0.4,
        }}
        onClick={() => handlePagination(PAGINATION_CASES.previousPage)}
      >
        <HiChevronLeft size={24} />
      </Button>
      <Box display="flex" gap="6px">
        {paginationRange.map((pageNumber, index) => {
          //if page number is DOTS render unicode for "..."
          if (pageNumber === DOTS)
            return (
              <Box key={index} fontSize="20px">
                &#8230;
              </Box>
            );
          else
            return (
              <Button
                key={index}
                size="sm"
                fontSize="12px"
                fontWeight={isActivePage(pageNumber) ? 500 : 400}
                // bgColor={isActivePage(pageNumber) ? "#3273dc" : "#fff"}
                bgColor={isActivePage(pageNumber) ? "rgb(232 243 255)" : "#fff"}
                // color={isActivePage(pageNumber) ? "#fff" : "#000"}
                color={isActivePage(pageNumber) ? "rgb(22,93,255)" : "#000"}
                _hover={{
                  // bgColor: isActivePage(pageNumber) ? "#2364CD" : "#E2E8F0",
                  bgColor: isActivePage(pageNumber)
                    ? "rgb(197 225 255)"
                    : "#E2E8F0",
                }}
                _active={{
                  bgColor: isActivePage(pageNumber)
                    ? "rgb(197 225 255)"
                    : "#E2E8F0",
                }}
                onClick={() => {
                  const pageIndex = pageNumber - 1;
                  handlePagination(PAGINATION_CASES.gotoPageIndex, pageIndex);
                }}
              >
                {pageNumber}
              </Button>
            );
        })}
      </Box>

      <Button
        size="sm"
        variant="ghost"
        border="0px"
        backgroundColor="transparent"
        disabled={shouldPaginationBtnDisabled(PAGINATION_CASES.nextPage)}
        _disabled={{
          border: "0px",
          backgroundColor: "transparent !important",
          opacity: 0.4,
        }}
        onClick={() => handlePagination(PAGINATION_CASES.nextPage)}
      >
        <HiChevronRight size={24} />
      </Button>
    </Box>
  );
};

export default CustomPaginationButtons;
