export namespace FontSizes {
  // Headings
  export enum Headings {
    HeadingSmall = 'xl',
    Heading = '2xl',
    HeadingLarge = '4xl',
    TableHeader = '15px',
    SectionHeading = '17px',
    SectionHeadingLarge = '20px',
    PageHeading = '24px',
  }

  // Normal Texts
  export enum Texts {
    TextXS = '12px',
    TextS = '13px',
    Normal = '14px',
    TextL = '15px',
    TextXL = '16px',
  }
}
