// LIBS
import { Controller } from 'react-hook-form';
import Select from 'react-select';

// CHAKRA-UI
import { Box, FormControl, FormHelperText } from '@chakra-ui/react';

// UTILITIES
import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';
import { resolveObjectValueByPath } from '../../../../helpers';

// COMPONENTS
import FormErrorLable from '../form-error-text';
import RenderWithLabelPosition from '../form-helper-components/render-with-label-position';
import FormLabel from '../form-label/form-label';

// FORM HELPERS;
import * as fromFormHelpers from '../@form-helper';
import { SelectComponentsProps } from 'react-select/src/Select';

/**
 * SOME TYPES ARE OMITTED BECAUSE IT EXISTS AND COL=NFLICTS WITH THE CHILD INTERFACE (TextInputComponentProps)
 */
type ReactSelectComponentProps = { options: any[]; components?: any[] } & Pick<
  fromFormHelpers.BaseFormProps,
  'name' | 'value' | 'onChangeRHF' | 'onChange'
>;

export const ReactSelectComponent = (props: ReactSelectComponentProps) => {
  let {
    name,
    value,
    onChange: _onChange,
    onChangeRHF,
    options,
    components,
    ...rest
  } = props;

  const handleChange = (value: any) => {
    _onChange?.(name, value);
    onChangeRHF?.(value);
  };

  return (
    <Select
      value={value}
      options={options}
      onChange={handleChange}
      components={{
        ...components,
        IndicatorSeparator: () => null,
      }}
      {...rest}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: state.isFocused ? '#B6C3D3' : '#CDD5DF',
        }),
        placeholder: (baseStyles, state) => ({
          ...baseStyles,
          color: '#878787',
          fontWeight: '300',
          fontSize: '14px',
          textTransform: 'lowercase',
        }),
        option: (baseStyles) => ({
          ...baseStyles,
          fontSize: '14px',
        }),
      }}
    />
  );
};

type ReactSelectProps = {
  type?: any;
  width?: any;
  options: any[];
  components?: any[];
  onChange?: any;
} & Omit<fromFormHelpers.BaseFormProps, 'onChange'> &
  Omit<SelectComponentsProps, 'onChange'>;

export function ReactSelect(props: ReactSelectProps) {
  const {
    label,
    control,
    ignoreControl = false,
    required = false,
    showOptionalLabel = true,
    errors,
    rule,
    width,
    customLabel,
    labelPosition,
    ...rest
  } = props;

  /**
   * GET THE ERROR FROM ERRORS
   * LATER USE TO DISPLAY ERROR
   */
  const error = errors && resolveObjectValueByPath(errors, props.name)?.message;

  /**
   * IF USER PASSES OWN RULE,
   * MERGE THE RULE WITH DEFAULT RULE
   * AND OVERWRITE IT
   */
  let _rule = fromFormHelpers.getDefaultRules({ required });

  if (!isEmpty(rule)) {
    _rule = merge(_rule, rule);
  }

  /**
   * IF NO CONTROL IS PROVIDED,
   * THEN USER IS USING THIS COMPONENT OUTSIDE FORM
   * NORMALYY AS FILTER OR CUSTOM USE CASE
   */
  if (!control || ignoreControl) {
    return (
      <FormControl
        test-id='react-select'
        display='flex'
        flexDirection='column'
        gap={2}
        id={props.name}
        isRequired={required}
        style={{ width }}
      >
        <RenderWithLabelPosition
          {...rest}
          labelPosition={labelPosition}
          label={<FormLabel label={label} customLabel={customLabel} />}
          inputComponent={<ReactSelectComponent {...rest} />}
        />
      </FormControl>
    );
  }

  /**
   * USE THE COMPONENT WITH IN FORM PROVIDER
   */
  return (
    <FormControl
      display='flex'
      flexDirection='column'
      gap={1}
      id={props.name}
      isRequired={false}
      style={{ width }}
    >
      <RenderWithLabelPosition
        labelPosition={labelPosition}
        label={
          <FormLabel
            color='#000000b3'
            fontSize='14px'
            lineHeight='21px'
            label={label}
            customLabel={customLabel}
          />
        }
        inputComponent={
          <Controller
            control={control}
            name={props.name}
            rules={_rule}
            render={controllerProps => {
              const {
                field: { onChange: _onChange, value: _value },
              } = controllerProps;
              return (
                <ReactSelectComponent
                  {...rest}
                  value={_value}
                  onChangeRHF={_onChange}
                />
              );
            }}
          />
        }
        optionalPrompt={
          !required && showOptionalLabel ? (
            <FormHelperText
              m={0}
              pl='10px'
              color='gray.500'
              fontWeight='300'
              fontSize='14px'
            >
              optional
            </FormHelperText>
          ) : null
        }
        errorPrompt={
          <FormErrorLable py='2px' px={1} fontSize='14px' error={error} />
        }
      />
    </FormControl>
  );
}
export default ReactSelect;
