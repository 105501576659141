enum AprPerformanceReviewFlow {
    /// self followed by manager review (performance review type)
    SelfReviewFollowedByManager = 1,

    /// Manager review only (performance review type)
    ManagerReviewOnly = 2,

    /// Self review followed by manager and peers (360 degree review type) 
    SelfPeerReviewFollowedByManager = 3,
    /// self or peer review followed by manager review  (360 degree review type)
    PeerReviewFollowedByManager = 4,
}
export default AprPerformanceReviewFlow