enum toBeSeperated {
  PayrollComponent = '/payroll/components',
  AttendanceRegisterPath = '/payroll/components/attendance_register',
  LeaveAbsencePath = '/payroll/components/leave_absence',
  SalaryAdjustmentPath = '/payroll/components/salary_adjustment',
  ArrearCalculationPath = '/payroll/components/arrear_calculation',
  StopPaymentPath = '/payroll/components/stop_payment',
  RFAdjustmentPath = '/payroll/components/rf_adjustment',
  IncomeCalculationPath = '/payroll/components/income_calculation',
  TaxCalculationPath = '/payroll/components/tax_calculation',
  TaxAdjustmentPath = '/payroll/components/tax_adjustment',
  ReviewAndFinalizePath = '/payroll/components/review_and_finalize',

  //Recruit Links
  RecruitPath = '/recruit',
  JobCreatePath = '/recruit/job_create',
  JobListing = '/recruit/job_listing',
  PositionDetailsPath = '/recruit/job_create/position_details',
  ApplicationsPath = '/recruit/job_create/applications',
  HiringStagePath = '/recruit/job_create/hiring_stage',
  ScorecardPath = '/recruit/job_create/scorecard',
  HiringTeamPath = '/recruit/job_create/hiring_team',
  RecruitDashboardPath = '/recruit/dashboard',
  RecruitDashboardOpeningPath = '/recruit/dashboard/opening',
  RequistionRequestPath = '/recruit/requisition_request',
  RecruitDashboardInformationPath = '/recruit/dashboard/information',
  RequisitionRequestPath = '/recruit/requisition_request',
  MyRequistionRequestPath = '/recruit/my_requisition_request',
  CreateNewJobRequisitionPath = '/recruit/create_job_requisition',
  UpdateJobRequisitionPath = '/recruit/update_job_requisition',
  JobApplyPath = '/recruit/job_apply',
  JobListingPath = '/recruit/job_listing',
  JobDetailsPath = '/recruit/job_details/',
  Applicants = '/recruit/applicants/',
  RecruitEmailTemplate = '/recruit/email_template',

  //Employee Links
  PersonalInfo = '/employee/personal-information',
  JobInfo = '/employee/job-information',
  EmployeePayroll = '/employee/payroll',
  EmployeeDashBoard = '/employee',
  EmployeeViewAllMessage = '/employee/messages',
  EmployeeOnboarded = '/employeeonboard',
  EmployeeProfile = '/employee/profile',
  EmployeeRequest = '/employee/request',
  EmployeeRequestReviewPath = '/employee/request/review',
  MyRequestPath = '/employee/request/my_request',
  LeaveRequestDetailsPath = '/employee/leave_details',
  OverTimeRequestDetailsPath = '/employee/overtime_details',
  MyRequestHistoryPath = '/employee/request/my_history',
  EmployeeRequestHistoryPath = '/employee/request/team_history',
  EmployeeReportsPath = '/employee/reports',
  EmployeeOtherMenu = '/employee/others',
  //JobDescriptionPath = "/employee/profile/job-description",

  //Overtime & Allowance
  OvertimeRequestPath = '/overtime-request',
  AllowanceRequestPath = '/allowance-request',

  //Admin Dashboard Links
  EmployeeListPath = '/admin/employee_list',
  AttendanceTrackerPath = '/admin/attendance_tracker',
  DisciplinaryActionPath = '/admin/disciplinary_action',
  DisciplinaryActionStagesPath = '/admin/disciplinary_stages',
  ViewDisciplinaryActionPath = '/admin/view_disciplinary_action/',
  EmployeeOnboarding = '/admin/employee_onboarding',
  EmployeeOnboardingAddEmployee = '/admin/employee_onboarding/add_employee',
  CreateOnboardingEmployee = '/admin/employee_onboarding/create',
  UpdateOnboardingEmployee = '/admin/employee_onboarding/update',
  EmployeeOnboardingPackages = '/admin/employee_onboarding/packages',
  EmployeeDataChangeRequest = '/admin/employee_data_change_requests',



  //#region Task Tacker
  LegalClientPath = '/task_tracker/legal_client',
  ActivityDashboardPath = '/task_tracker/dashboard',
  TaskTrackerCommonTaskPath = '/task_tracker/common_tasks',
  TaskTrackerReportPath = '/task_tracker/reports',
  NewActivityPath = '/task_tracker/new_activity',
  ActivityAndBillingRolesPath = '/task_tracker/roles',
  ActivityReportPath = '/task_tracker/activity_report',
  ActivityNotSubmittedReportPath = '/task_tracker/activity_not_submitted_report',
  HoursWorkedByClientPath = '/task_tracker/hours_by_client',
  HoursWorkedByPeoplePath = '/task_tracker/hours_by_people',
  HoursCommittedPath = '/task_tracker/hours_committed',
  PeopleInProjectPath = '/task_tracker/people_in_project',
  RawReportPath = '/task_tracker/raw_report',
  ActivityProjectReview = '/task_tracker/projectReview',
  //#endregion Task Tracker

  //Tasks Links
  TaskPath = '/tasks',
  TaskBoardSingleLink = '/tasks/boards/board/',
  TaskBoardTodo = '/tasks/boards/to-do',
  TaskBoards = '/tasks/boards/',
  TaskBoardListLinks = '/tasks/boards/list',
  TaskSummary = '/tasks/task-summary',
  TaskBoardActitityReport = '/tasks/board-activity',
  //End-of Tasks Links

  HRDashboardPath = '/hr/dashboard',
  HRDashboardCommonTaskPath = '/hr/common_tasks',
  HRDashboardReportsPath = '/hr/reports',
  HRDashboardSettingsPath = '/hr/settings',
  PayrollDashboardPath = '/payroll/dashboard',
  PayrollDashboardCommonTaskPath = '/payroll/common_tasks',
  PayrollContinuePath = '/payroll',
  PayrollDashboardReportsPath = '/payroll/reports',
  PayrollDashboardSettingsPath = '/payroll/settings',
  PayrollIncomeCalculationPath = '/payroll/income_calculation',
  PayrollLeaveAbsencePath = '/payroll/leave_absence',
  PayrollReviewFinalizePath = '/payroll/review_finalize',
  PayrollRFAdjustmentPath = '/payroll/rf_adjustment',
  PayrollSalaryAdjustmentPath = '/payroll/salary_adjustment',
  PayrollStopPaymentPath = '/payroll/stop_payment',
  PayrollTaxAdjustmentPath = '/payroll/tax_adjustment',
  PayrollTaxCalculationPath = '/payroll/tax_calculation',
  LeaveTimeDashboardPath = '/leavetime/dashboard',
  LeaveTimeDashboardCommonTaskPath = '/leavetime/common_tasks',
  LeaveTimeDashboardReportsPath = '/leavetime/reports',
  LeaveTimeDashboardSettingsPath = '/leavetime/settings',
  MobileDevicePath = '/time_management/mobile_devices',

  //Roles & Permissions
  AuthorizedUser = '/roles_permission/authorized_user',
  Roles = '/roles_permission/roles',
  ManagersLinks = '/roles_permission/managers',
  ClustersLink = '/roles_permission/clusters',

  //Job Profile
  JobProfilePath = '/admin/job_profile',
  KSALibrariesPath = '/admin/ksa_libraries',
  CreateJobProfilePath = '/admin/create_job_profile',
  UpdateJobProfilePath = '/admin/update_job_profile/',
  CreateJobDescriptionPath = '/admin/create_job_description',
  UpdateJobDescriptionPath = '/admin/update_job_description/',

  ExportPdfPath = '/export_pdf',
  ExportRosterPdfPath = '/export_pdf/roster',

  //Common
  LoginPath = '/login',
  Logout = '/sign-out',
  Settings = '/settings',
  TravelSettings = '/settings/travel',
  NotificationsPath = '/notifications',
  Links = '/links',
  ResetOldHashPath = '/reset-old-hash-password',

  //#region Leaves and time
  EmployeeLeavesPath = '/employee/leaves',
  EmployeeTimePath = '/employee/time/attendance',
  EmployeeTimeRequestPath = '/employee/time-request',
  //#endregion

  // loan Routes
  LoanManagement = '/loan/employee_loan_requests',
  AdminLoanManagement = '/loan/admin_loan_list',
  EmployeeLoanList = '/loan/my_loan_requests_list',

  CreateHomeLoanPath = '/loan/request_home_loan',
  UpdateHomeLoanPath = '/loan/update_home_loan',
  ViewHomeLoanPath = '/loan/view_loan_request',
  CreateVehicleLoanPath = '/loan/request_vehicle_loan',
  UpdateVehicleLoanPath = '/loan/update_vehichle_loan',
  ViewVehicleLoanPath = '/loan/view_vehichle_loan',
  CreateStaffODLoanPath = '/loan/request_staff_od_loan',
  UpdateStaffODLoanPath = '/loan/update_staff_od_loan',
  CreateStaffPersonalLoanPath = '/loan/request_staff_personal_loan',
  CreateStaffAdvanceLoanPath = '/loan/request_staff_advance_loan',
  UpdateStaffPersonalLoanPath = '/loan/update_staff_personal_loan',
  AddRecordPath = '/loan/add_home_loan_record',


  //Roster
  roosterRoutes = '/roster',
  roosterEmployeeAttendance = '/roster/employee-attendance',
  roosterShiftsRoutes = '/roster/shifts',
  roosterScheduleRoutes = '/roster/schedule',
  roosterSchedulePdfRoutes = '/roster/pdf',
  roosterWorkHousRoutes = '/roster/work-hours',
  roosterSchedulePatternRoutes = '/roster/shifts-pattern',
  roosterTeam = '/roster/team',
  roosterShiftDashboard = '/roster/dashboard',
  roosterShiftRoles = '/roster/roles',
  // new loan module routes
  // MyLoanRequests = "/loan/my_loan_requests",
  loanRoutes = '/loan',
  ConsumerLoanRequest = '/loan/consumer_loan_requests',
  UpdateConsumerLoanRequest = '/loan/update_consumer_loan_requests',
  UpdateSocialLoanRequest = '/loan/update_social_loan_requests',
  SocialLoanRequest = '/loan/social_loan_requests',
  // UpdateSocialLoanRequest = "/loan/update_consumer_loan_requests",
  PersonalLoanRequest = '/loan/personal_loan_requests',
  UpdatePersonalLoanRequest = '/loan/update_personal_loan_requests',
  MyAppliedLoanRequests = '/loan/my_applied_loan_requests',
  ViewSBILoanDetail = '/loan/view_loan_detail',

  // sbi loan
  CreateLoanSbiPath = '/loan/sbi_loan/setting',
  SbiLoanSetting = '/links/Loan/4',
  StaffPersonalLoan = '/loan/sbi_loan/staffPersonalLoan/:id',
  LoanStatusHistory = '/loan/sbi_loan/loan-status-histories/list',
  SbiLoanPurpose = '/loan/sbi_loan/purpose',

  // Access & ID Category
  AccessAndIDCategory = '/admin/onboarding_accessid_category',

  // Checklist
  Checklist = '/admin/onboarding_checklist',

  // message
  Message = '/admin/onboarding_message',

  // callback-oauth
  CallbackOauth = '/callback-oauth',
}

export default toBeSeperated;