import {
  Box,
  Flex,
  Input,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  useDisclosure,
} from '@chakra-ui/react';
// import TimeInputPolyfill from '@time-input-polyfill/react'
import './scroll.scss';
// import dayjs from "dayjs";
import RenderCells from './components/RenderHours';
import RenderModulation from './components/RenderModulation';
import { useTimePicker } from './hooks/useTimePicker';
import { TimePickerProps } from './interfaces';
import React, { SyntheticEvent } from 'react';

export const PxTimePicker = (props: TimePickerProps) => {
  const { showHour, showMin, showSec, format } = props;

  const {
    handleHrClick,
    handleMinClick,
    handleSecClick,
    handleAmPmClick,
    handleInputChange,
    state,
  } = useTimePicker(props);

  const { isOpen, onToggle, onClose } = useDisclosure();
  const initialFocusRef = React.useRef(null);

  return (
    <Box>
      <label className="text-white">{JSON.stringify(state, null, 2)} </label>
      <Popover
        initialFocusRef={initialFocusRef}
        placement="bottom-start"
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <PopoverAnchor>
          <Box ref={initialFocusRef}>
            <Input
              value={state.defaultVal}
              onChange={handleInputChange}
              onClick={onToggle}
            />
          </Box>
        </PopoverAnchor>

        <PopoverContent
          _focus={{
            border: 'none',
          }}
          width="auto"
          shadow="md"
        >
          <PopoverArrow />
          <PopoverBody padding="2px 1px" bg="white">
            <Flex h="224px">
              <RenderCells
                nums={state.hrsToRender}
                show={showHour}
                identifier="hr"
                handleClick={handleHrClick}
                selectedValue={state.hr}
              />
              
              <RenderCells
                nums={state.minsToRender}
                show={showMin}
                identifier="min"
                handleClick={handleMinClick}
                selectedValue={state.min}
              />

              <RenderCells
                nums={state.secsToRender}
                show={showSec}
                identifier="sec"
                handleClick={handleSecClick}
                selectedValue={state.sec}
              />

              <RenderModulation
                show={format === '12hr'}
                selectedValue={state.modulation}
                handleClick={handleAmPmClick}
              />

            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

PxTimePicker.defaultProps = {
  format: '12hr',
  value: '',
  showConfirm: true,
  showHour: true,
  showMin: true,
  showSec: true,
  disabledHrs: [],
  disabledMins: [],
  disabledSecs: [],
};

export default PxTimePicker;
