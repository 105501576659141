// FOLLOWING RENDER PROPS PATTERN

import {
  HStack,
  Modal as ChakraModal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import Button from "../../atoms/button/button";
import CommonModalBody from "../common-modal-body/common-modal-body";
import CommonModalFooter from "../common-modal-footer/common-modal-footer";
import CommonModalHeader from "../common-modal-header/common-modal-header";

interface ModalProps {
  modalProps?: any;
  renderOverlay?: any;
  renderModalFooter?: any;
  isOpen?: any;
  onClose?: any;
  title?: any;
  children?: any;
}
export const ModalRegular = (props: ModalProps) => {
  const {
    modalProps,
    renderModalFooter,
    renderOverlay,
    isOpen,
    onClose,
    children,
    title,
  } = props;

  return (
    <ChakraModal isOpen={isOpen} onClose={onClose} {...modalProps}>
      {renderOverlay?.() || <ModalOverlay />}
      <ModalContent p={0}>
        <CommonModalHeader>{title}</CommonModalHeader>
        <ModalCloseButton />

        <CommonModalBody>{children}</CommonModalBody>

        <CommonModalFooter>
          {renderModalFooter?.() || (
            <HStack spacing={2}>
              <Button buttonType='secondary' mr={3} onClick={onClose}>
                Close
              </Button>
              <Button buttonType='primary' type='submit'>
                Submit
              </Button>
            </HStack>
          )}
        </CommonModalFooter>
      </ModalContent>
    </ChakraModal>
  );
};

export default ModalRegular;
