import * as fromSlice from "./slice";
import * as fromInterface from "../../../ts";

const selectPerformanceReviewActivitiesSlice = (state: any): fromSlice.ReivewActivityState => {
    return state.dataStore.performanceReviewActivities;
};

export const selectSelfActiveReviewActivities = (state: any): any[] => {
    return selectPerformanceReviewActivitiesSlice(state).activeActivities.selfReviews;
};
export const selectTeamActiveReviewActivities = (state: any): any[] => {
    return selectPerformanceReviewActivitiesSlice(state).activeActivities.teamReviews;
};

export const selectActiveReviewActivitiesIsLoading = (state: any): boolean => {
    return selectPerformanceReviewActivitiesSlice(state).activeActivities.status === fromInterface.STATUS.LOADING;;
};


export const selectSelfHistoryReviewActivities = (state: any): any[] => {
    return selectPerformanceReviewActivitiesSlice(state).historyActivities.selfReviews;
};
export const selectTeamHistoryReviewActivities = (state: any): any[] => {
    return selectPerformanceReviewActivitiesSlice(state).historyActivities.teamReviews;
};

export const selectHistoryReviewActivitiesIsLoading = (state: any):boolean => {
    return selectPerformanceReviewActivitiesSlice(state).historyActivities.status === fromInterface.STATUS.LOADING;
};