import * as fromInterface from "../../../ts";
import * as fromConstant from "../../../constants";
import { ApiClient } from "../../../network/apiClient";
import * as fromSlice from "./slice";
import { geoOfficeSites } from "./api";

export const fetchGeoOfficeSites =
  (page?: any) => async (dispatch: any, getState: any) => {
    dispatch(fromSlice.fetchRequest());

    const response: any = await ApiClient.get({
      endpoint: geoOfficeSites,
      dispatch: dispatch,
    });

    dispatch(fromSlice.fetchSuccess(response?.Data));

    return response;
  };

export const postGeoOfficeSites =
  (data?: any, cb?: any): any =>
  async (dispatch: any, getState: any) => {
    const param = {
      endpoint: geoOfficeSites,
      body: {
        ...data,
      },
      dispatch: dispatch,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.post<any>(param);
    const { Data, Status } = response;
    if (Status) {
      dispatch(fetchGeoOfficeSites());
    }
    cb?.(Status);
  };
export const updateGeoOfficeSites =
  (data?: any, cb?: any): any =>
  async (dispatch: any, getState: any) => {
    const param = {
      endpoint: geoOfficeSites,
      body: {
        ...data,
      },
      dispatch: dispatch,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.put<any>(param);
    const { Data, Status } = response;
    if (Status) {
      dispatch(fetchGeoOfficeSites());
    }
    cb?.(Status);
  };

export const deleteGeoOfficeSites =
  (id: any): any =>
  async (dispatch: any, getState: any) => {
    const param = {
      endpoint: `${geoOfficeSites}/${id}`,
      dispatch: dispatch,
    };
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.remove<any>(param);
    const { Data, Status } = response;

    if (Status) {
      dispatch(fetchGeoOfficeSites());
    }
    return Status;
  };

export const clearGeoOfficeSites = () => (dispatch: any) => {
  dispatch(fromSlice.clear());
};
