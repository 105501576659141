// @ts-nocheck 
import React, {useState, useEffect} from "react";
import {AnswerType, LMSQuestionTypeEnum} from "scenes/lms/helpers/enums";
import QuestionControl from "../../../common/questionControl";
import Button from "components/button";
import isEmpty from "helpers/isEmpty";
import questionTypeOptions from "scenes/lms/helpers/questionTypeOptions";
import {useDispatch} from "react-redux";
import {updateCourseQuestion, addCourseQuestion} from "scenes/lms/helpers/action";
import FormGroup from "components/formGroup";
import {useForm} from "react-hook-form";

const AddQuestion = (props) => {
  const dispatch = useDispatch();
  const {
    onQuestionAdd,
    onQuestionRemove,
    setSections,
    item,
    questionIndex,
    sectionIndex,
    courseId,
    pageId,
    lastItem,
  } = props;
  const [editQuestionData, setEditQuestionData] = useState(null);
  useEffect(() => {
    if (item) {
      setEditQuestionData({...item, answerOptions: [...item.answerOptions]});
    }
    //else {
    //insert
    // setEditQuestionData({
    //   answerLimitFrom: 0,
    //   answerLimitTo: 0,
    //   answerOptions: [],
    //   answerType: AnswerType.normal,
    //   isOtherPresent: null,
    //   isRandomised: false,
    //   question: "",
    //   questionOrder: totalData + 1,
    //   questionType: questionTypeOptions[0],
    //   section: lastItem?.section,
    //   sectionOrder: lastItem?.sectionOrder,
    // });
    // }
  }, [item]);

  const methods = useForm();

  const handleChange = async (name, value) => {
    setEditQuestionData((prev) => {
      let newData = {...prev};
      if (
        name === "questionType" &&
        (value?.value === LMSQuestionTypeEnum.RadioButton ||
          value?.value === LMSQuestionTypeEnum.CheckBox)
      ) {
        newData[name] = {...value, answerType: AnswerType.normal};

        let options = [
          {isChecked: false, answerOption: "", marks: 0},
          {isChecked: false, answerOption: "", marks: 0},
        ];
        if (value?.value === LMSQuestionTypeEnum.CheckBox) {
          options.push({isChecked: false, answerOption: "", marks: 0});
          options.push({isChecked: false, answerOption: "", marks: 0});
        }
        newData["answerOptions"] = options;
      } else {
        newData[name] = value;
      }
      return newData;
    });
  };
  const onQuestionSave = async (data) => {
    const {
      question,
      isRandomised,
      answerLimitFrom,
      answerLimitTo,
      isOtherPresent,
      section,
      answerType,
      answerOptions,
      questionType,
      questionOrder,
      sectionOrder,
    } = editQuestionData;

    const param = {
      id: editQuestionData.id,
      answerType,
      answerOptions: answerOptions?.map((x) => ({
        questionId: editQuestionData.id,
        answerOption: x.answerOption,
        isCorrect: x.isChecked,
        point: x.point,
      })),
      courseId: courseId,
      courseOutlineId: pageId,
      questionType: questionType.value,
      question,
      questionOrder,
      isRandomised,
      answerLimitFrom,
      answerLimitTo,
      isOtherPresent,
      section,
      sectionOrder,
    };

    if (param.id) await dispatch(updateCourseQuestion(param));
    else await dispatch(addCourseQuestion([param]));
    setSections((prev) => {
      let newSections = [...prev];
      if (questionIndex && questionIndex >= 0)
        newSections[sectionIndex][questionIndex] = {...editQuestionData};
      else {
        newSections[sectionIndex].push({...editQuestionData});
      }
      props.onCancel();
      return newSections;
    });
  };
  const onCancelClicked = () => {
    props.onCancel();
  };
  const handleDelete = () => {};
  const handleDuplicate = () => {};
  return (
    !isEmpty(editQuestionData) && (
      <div className="edit-question active">
        <FormGroup method={methods} defaultValues={{}} onSubmit={onQuestionSave}>
          <QuestionControl
            questionIndex={questionIndex}
            sectionIndex={sectionIndex}
            methods={methods}
            onChange={handleChange}
            item={editQuestionData}
            setItem={setEditQuestionData}
          />
          <div className="lms-questions__add-question-btn">
            <Button onClick={() => onCancelClicked()} title="Cancel" />
            <Button
              buttonClass="button-primary"
              // onClick={() => onQuestionSave()}
              title="Save"
              htmlButtonType="submit"
            />
          </div>
        </FormGroup>
      </div>
    )
  );
};
export default AddQuestion;
