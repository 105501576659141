import {
    SEARCHED_TAX_ADJUSTMENT,
    TAX_ADJUSTMENT_LOADING,
} from "../../../actions/types";

const initialState = {
    searchedTaxAdjustment: [],
    searchedPage: { index: 0, total: 0 },
    loading: false,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case TAX_ADJUSTMENT_LOADING:
            return {
                ...state,
                loading: true
            };
        case SEARCHED_TAX_ADJUSTMENT:
            return {
                ...state,
                searchedTaxAdjustment: action.payload,
                searchedPage: {
                    index: action.page.index,
                    total: action.page.total
                },
                loading: false
            };
        default:
            return state;
    }
}
