
import * as fromPerformanceReviewCommentStore from "../../performance-review-comments";
import * as fromInterface from "./../../../../ts";

export const mapComments = (SectionId: number, sectionContentId: number, state: any, ReviewInfo: fromInterface.ReviewInfo | undefined) => {
    const commentFound = fromPerformanceReviewCommentStore.PerformanceReviewCommentStore.selectCommentsForCurrentPage({
        sectionId: SectionId,
        sectionContentId: sectionContentId,
    })(state);

    let Comments: any = [{
        "SectionId": SectionId,
        "SectionContentId": sectionContentId,
        "ReviewId": ReviewInfo?.ReviewId,
        "Comment": "",
    }];

    if (commentFound?.length !== 0) {
        const commentFoundForThisReviewId = commentFound.find((comment: fromInterface.PerformanceReviewComment) => comment.ReviewId === ReviewInfo?.ReviewId);
        if (commentFoundForThisReviewId) {
            Comments = commentFound;
        } else {
            Comments = [...commentFound, {
                "SectionId": SectionId,
                "SectionContentId": sectionContentId,
                "ReviewId": ReviewInfo?.ReviewId,
                "Comment": "",
            }];
        }
    }
    return Comments;
}

// export const mapCommentsV2 = (SectionId: number, sectionContentId: number, comments: any[], ReviewInfo: fromInterface.ReviewInfo | undefined) => {

//     const commentFound = comments.filter((item: fromInterface.PerformanceReviewComment) => item.SectionId === SectionId && item.SectionContentId === sectionContentId);

//     let Comments: any = [{
//         "SectionId": SectionId,
//         "SectionContentId": sectionContentId,
//         "ReviewId": ReviewInfo?.ReviewId,
//         "Comment": "",
//     }];

//     if (commentFound?.length !== 0) {
//         const commentFoundForThisReviewId = commentFound.find((comment: fromInterface.PerformanceReviewComment) => comment.ReviewId === ReviewInfo?.ReviewId);
//         if (commentFoundForThisReviewId) {
//             Comments = commentFound;
//         } else {
//             Comments = [...commentFound, {
//                 "SectionId": SectionId,
//                 "SectionContentId": sectionContentId,
//                 "ReviewId": ReviewInfo?.ReviewId,
//                 "Comment": "",
//             }];
//         }
//     }
//     return Comments;
// }
