import { ApiClient } from "../../../network/apiClient";
import * as fromInterface from "../../../ts";
import { PERFORMANCE_SCORES_BY_ROLLOUTID, PERFORMANCE_SCORES_EXPORT, PERFORMANCE_SCORES_LIST } from "./api";
import * as fromSlice from "./slice";

// STORES
import * as fromPerformanceFormDropDownStore from "../performance-form-dropdown";
import * as fromReviewCycleDropdownStore from "../review-cycle-dropdown";
import * as formPerformanceFormDropdownStore from "./../performance-form-dropdown";
import * as fromPerformanceScoreFilterStore from "./../performance-score-filter";


//below codes are for tableList
export const fetchPerformanceScoreInitialData = ({ reviewCycleId }: { reviewCycleId: number }) => async (dispatch: any, getState: any) => {
    if (reviewCycleId) {

        await dispatch(fromReviewCycleDropdownStore.fetchReviewCycleDropDown())

        const state = getState();
        const dropdownItems = fromReviewCycleDropdownStore.selectReviewCycleDropdownItems(state)

        const reviewCYcleDropdownItemFound = dropdownItems.find((item: any) => item.value === reviewCycleId)

        await dispatch(
            fromPerformanceFormDropDownStore.fetchPerformanceFormDropDown(reviewCycleId, -1),
        )

        await dispatch(fromPerformanceScoreFilterStore.udpatePerformanceScoreFilter({
            reviewCycleId: reviewCYcleDropdownItemFound,
        }))
        dispatch(fetchPerformanceScoreList())
    } else {
        dispatch(formPerformanceFormDropdownStore.fetchPerformanceFormDropDown(-1, -1))
    }

}

export const clearPerformanceScoreInitialData = () => async (dispatch: any) => {
    dispatch(clearPerformanceScore())
    dispatch(fromPerformanceScoreFilterStore.clearPerformanceScoreFilter())
    dispatch(formPerformanceFormDropdownStore.clearPerformanceFormDropdown())
}
export const clearPerformanceScore = (): any => async (dispatch: any) => {
    dispatch(fromSlice.clearList())
}

export const fetchPerformanceScoreList = (isExport: boolean = false) => async (dispatch: any, getState: any) => {
    dispatch(fromSlice.fetchRequest())
    const state = getState()
    const currentFilterState = fromPerformanceScoreFilterStore.selectCurrentPerformanceScoreFilter(state);
    const param = {
        ReviewCycleId: currentFilterState.reviewCycleId?.value || -1,
        ReviewFormId: currentFilterState.reviewFormId?.value || -1,
        BranchId: currentFilterState.branchId?.value || -1,
        DepartmentId: currentFilterState.departmentId?.value || -1,
    }

    if (isExport) {
        await ApiClient.exportFile_post({
            endpoint: PERFORMANCE_SCORES_EXPORT,
            body: param,
            dispatch: dispatch,
        })

        dispatch(fromSlice.downloadSuccess())
        return
    }

    const response: fromInterface.ResponseWrapper<any> = await ApiClient.post<any>({
        endpoint: PERFORMANCE_SCORES_LIST,
        body: param,
        dispatch: dispatch,
    })
    const { Data, Status } = response
    if (Status) {
        dispatch(fromSlice.fetchSuccessList(Data))
    }
}



//Below codes are for modalScoresDetails while clicking table details
export const fetchPerformanceScoresDetails = (rolloutId: any) => async (dispatch: any) => {
    const response: fromInterface.ResponseWrapper<any> = await ApiClient.get<any>({
        endpoint: PERFORMANCE_SCORES_BY_ROLLOUTID.replace(":rolloutId", rolloutId + ""),
        dispatch
    })
    return response
}
