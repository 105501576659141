import React, { useContext } from 'react'
import StepperContext,{StepperEnums} from '../context'; 
import Container from './container';
import {Box,Text,Image, Flex} from "@chakra-ui/react";
import {Button,Modal} from "@rigotech/ui";
import QRCode from "react-qr-code";
import { AuthState } from 'src/reducers/authReducer';
import { useSelector } from 'react-redux';
import { useToast } from '@chakra-ui/react'


function SetupApp() {
  const auth:AuthState = useSelector((state:any)=>state.auth)
  const {tfaQrDetails,twoFactorUserDetails} = auth;
  const values = useContext(StepperContext);
  const onStepChange = () => {
    values.setActiveStepper(StepperEnums.code);
  }
  return (
    <Box>
        <Container title="Set up Authenticator App">
            <Box>
              <StepText step={1} title={"Open the Authenticator App in your Smart Phone"}  />
              <StepText step={2} title={"Add your account to the App by Scanning the following QR code"}  />
              <Box padding={"12px"} margin="32px 0 16px" w="236px" h="236px" border="1px solid #707070" background="#F7F7F7" borderRadius="12px" >
                  {tfaQrDetails?.FormattedKey ? <QRCode size={212} value={tfaQrDetails?.FormattedKey} /> : <StepText step={2} title={"No QR Code Found"}  />}
              </Box>
              <Box display="flex" gap="10px" mb="20px" alignItems={"center"}>
                  <Text fontSize='sm' fontWeight={600}>Can't Scan the QR ?</Text>
                  <SetupCodeModal />
                </Box>
              <StepText step={3} title={"Enter the 6-digit code found in you authenticator app under Rigo HR"}  />
            </Box>
        </Container>
        <Box padding="16px" textAlign="center" >
          <Button w="300px" onClick={onStepChange} buttonType="primary" variant="solid" padding="24px 60px" fontSize={"16px"} fontWeight={400} >Enter Code from Authenticator App</Button>
        </Box>
    </Box>
  )
}

const StepText = ({step,title}) => {
  return(
    <Box mb={"12px"} display="flex" flexDirection="row" alignItems="center" gap="12px" >
      <Box background="#2680EB" borderRadius="48px" padding="6px 12px" minW="max-content" >
        <Text color="#fff" fontSize={"15px"} fontWeight={500} >Step {step}</Text>
      </Box>
      <Text fontSize='md' color="#465060" fontWeight={500} >{title}</Text>
    </Box>
  )
}


const SetupCodeModal = () => {
  const [open,setOpen] = React.useState(false);
  const auth:AuthState = useSelector((state:any)=>state.auth)
  const toast = useToast()
  const {tfaQrDetails} = auth;
  const [loading,setLoading] = React.useState(false);
  const onOpen = ()=>setOpen(true);
  const onClose = ()=>setOpen(false);
  
  const handleSubmit = () => {
    onClose()
  }
  // 
  return(
    <>
      <Button onClick={onOpen} buttonType="link-primary" variant="link" >
        <Text fontSize='sm' fontWeight={600}>Enter a Setup Code instead.</Text>
      </Button>
      <Modal  isOpen={open} size="2xl" onClose={onClose} isCentered >
        <Modal.Overlay 
          bg='blackAlpha.300'
          backdropFilter='blur(4px) hue-rotate(24deg)'
        />
        <Modal.Content className="rigo-common-modal-1">
          {/* <Modal.CloseBtn /> */}
          <Modal.Header>Enter Setup Code</Modal.Header>
          <Modal.Body>
            <Box padding="24px" textAlign="center" >
              <Text fontSize='xl' color="#030511" fontWeight={600} >Enter the following setup code in your authenticator app</Text>
              {/* <Text fontSize='5xl' color="#0055BA" fontWeight={800} letterSpacing={12} margin="32px 0" >966542</Text> */}
              <Box 
                boxShadow="0 2px 3px rgba(0,0,0,0.12)"
                display="flex" flexDir="row" margin="48px 0" >
                <Box flex="1" padding="8px" background={"#efefef"} >
                  <Text fontSize='md' color="#030511" fontWeight={600}>{tfaQrDetails?.AuthenticatorKey}</Text>
                </Box>
                <Box 
                  onClick={() => {
                    navigator.clipboard.writeText(tfaQrDetails?.AuthenticatorKey)
                    toast({
                      title: 'Text copied.',
                      description: `${tfaQrDetails?.AuthenticatorKey} copied successfully`,
                      status: 'success',
                      duration:3000,
                      isClosable: true,
                    })
                  }}
                  border="1px solid #dfdfdf"
                  padding="8px 24px" background={"#1677ff"}
                  cursor="pointer"
                  _hover={{background:"#4096ff"}}
                >
                  <Text fontSize="md" color="#fff" >Copy</Text>
                </Box>
              </Box>              
              <Text fontSize='md' color="#030511" fontWeight={400} >Account Name</Text>
              <Text fontSize='md' color="#030511" fontWeight={600} >Type some name (like Rigo HR)</Text>
              <Text fontSize='md' color="#030511" fontWeight={400} mt="16px" >Type of Key</Text>
              <Text fontSize='md' color="#030511" fontWeight={600} >Time based</Text>
            </Box>
          </Modal.Body>
          <Modal.Footer>
            <Flex gap="15px" justifyContent={"center"}>
              <Button
                buttonType="secondary"
                onClick={onClose}
              >
                Close
              </Button>
              <Button
                buttonType="primary"
                onClick={handleSubmit}
                isLoading={loading}
                type="submit"
              >
                Done
              </Button>
            </Flex>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  )
}

export default SetupApp