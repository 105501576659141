// @ts-nocheck 
import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "isEmpty";
import { checkExtension } from "../../../helpers/checkExtension";
import Modal from "../../modal";
import SvgIcons from "../../icons/svgIcons";
import Icons from "../../icons";
import ImageViewer from "../../imageViewer";
import { api } from "./../../../networkService/api";
import getFullStringUrl from "../../../helpers/getFullStringUrl";
import { baseURL, fileLocation, hostedUrl } from "../../../constants/urls";

export default function UploadedFileBlock(props: any) {
  const { file, index, onDelete, disabled } = props;
  const [preview, setPreview] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const { isDocs, isImage, isPdf } = checkExtension(file.ServerFileName);
  const dispatch = useDispatch();
  const imageClass = classnames("uploaded-file", {
    "uploaded-file-image": isImage,
  });

  const actionClass = classnames({
    actions: isImage,
    "uploaded-file-pdf": !isImage,
  });
  const nameClass = classnames({
    name: isImage,
    "uploaded-file-name": !isImage,
  });
  // const param = {
  //   serverFileName: file.ServerFileName,
  //   userFileName: file.UserFileName || "",
  //   fileType: file.FileType || "",
  // };
  // const url = getFullStringUrl(baseURL + api.common.downloadFiles, param);
  //return <span>{JSON.stringify(file)}</span>;
  return (
    <div className={imageClass} key={index}>
      <div
        onClick={(e) => {
          if (!isImage) {
            e.stopPropagation();
            // window.open(url);
          }
        }}
        className={actionClass}
      >
        {isImage ? null : (
          <span className="mr-sm">
            {isDocs ? (
              <SvgIcons name="document" size="30" />
            ) : isPdf ? (
              <SvgIcons name="pdf" size="30" />
            ) : null}
          </span>
        )}
        <span className={nameClass}>{file.UserFileName || ""}</span>
        {!disabled && (
          <span
            className="icon"
            onClick={(e) => {
              e.stopPropagation();
              setOpenConfirmation(true);
            }}
          >
            <Icons name="Cross" size={13} color="#fff" />
          </span>
        )}
      </div>

      <Modal
        type="confirm"
        func={() => onDelete && onDelete(index)}
        open={openConfirmation}
        onModalClose={() => setOpenConfirmation(false)}
      />

      {isImage && (
        <div
          style={{ width: "100%" }}
          onClick={(e) => {
            e.stopPropagation();
            setPreview(true);
          }}
        >
          <img src={`${hostedUrl}/${fileLocation}/${file.ServerFileName}`} />
        </div>
      )}

      {preview && (
        <ImageViewer
          hostedUrl={hostedUrl}
          show={preview}
          title={file.UserFileName || file.ServerFileName}
          image={file}
          onClose={setPreview}
        />
      )}
    </div>
  );
}
