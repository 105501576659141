// @ts-nocheck
import { useDispatch } from "react-redux";
import { useConfirmation } from "@rigotech/ui";
import { CourseStatus } from "scenes/lms/helpers/enums";

import { deleteCourse } from "../../helpers/action";

export const useDelete = (Id, onStatusChange) => {
  const dispatch = useDispatch();
  const { openConfirm } = useConfirmation();

  const handleDelete = () =>
    openConfirm({
      onConfirm: async () => {
        let res = await dispatch(deleteCourse(Id));
        res && onStatusChange();
      },
      confirmationBtnName: "Delete",
      contentMessage: "Are you sure you want to delete this course?",
      title: "Delete",
      isCentered: true,
    });

  return {
    handleDelete,
  };
};
