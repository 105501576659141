//CHAKRA
import { Box, Center, Heading, Text } from '@chakra-ui/react';

//ICONS

//images
import nodatafound from '../../../assets/images/icons/nodatafound.svg';
import notabledata from '../../../assets/images/icons/notabledata.svg';
import nopagedata from '../../../assets/images/icons/nopagedata.svg';
import nopageload from '../../../assets/images/icons/nopageload.svg';
import { Children } from 'react';

interface NoDataFoundProps {
  heading?: string;
  message?: string;
  state?: 'default' | 'one' | 'two' | 'three';
  data?: any;
}

interface NoDataFoundWrapperProps {
  heading?: string;
  message?: string;
  children?: JSX.Element;
  state?: 'default' | 'one' | 'two' | 'three';
  data?: any;
}

// Use this empty state when user applies table filter and no data is displayed in the table
export const NodataFoundOne = () => {
  return (
    <Center
      mt='15px'
      padding='40px 25px'
      background='#fff'
      borderRadius='5px'
      minHeight='200px'
      width='100%'
      textAlign='center'
      flexDirection='column'
    >
      <img src={notabledata} alt='' height='50px' width='50px' />
      <Box pt='20px'>
        <Heading fontSize='16px' color='#bfbfbf' fontWeight='400' mb='5px'>
          No data matches your report options
        </Heading>
        <Heading fontSize='16px' color='#bfbfbf' fontWeight='400' mb={0}>
          Try some different criteria
        </Heading>
      </Box>
    </Center>
  );
};

// Use this empty state when user applies filter on a page and no data is displayed in that page
export const NodataFoundTwo = () => {
  return (
    <Center
      mt='15px'
      padding='40px 25px'
      background='#fff'
      borderRadius='5px'
      minHeight='200px'
      width='100%'
      textAlign='center'
      flexDirection='column'
    >
      <img src={nopagedata} alt='' height='50px' width='50px' />
      <Box pt='20px'>
        <Heading fontSize='16px' color='#bfbfbf' fontWeight='400' mb='5px'>
          Nothing to display
        </Heading>
        <Heading fontSize='16px' color='#bfbfbf' fontWeight='400' mb={0}>
          Try some different criteria
        </Heading>
      </Box>
    </Center>
  );
};

// Use this empty state when there is nothing on the page and also no filter is availaible
export const NodataFoundThree = () => {
  return (
    <Center
      mt='10px'
      padding='20px 25px'
      background='#fff'
      borderRadius='5px'
      minHeight='100px'
      width='100%'
      textAlign='center'
      flexDirection='column'
    >
      <img src={nopageload} alt='' height='50px' width='50px' />
      <Box pt='8px'>
        <Heading fontSize='14px' color='#bfbfbf' fontWeight='400'>
          Nothing here
        </Heading>
      </Box>
    </Center>
  );
};

export const NoDataFound = (props: NoDataFoundProps) => {
  const {
    heading = 'No result found',
    message = "Sorry, we couldn't find what you're looking for",
    state = 'default',
  } = props;

  if (state === 'one') {
    return <NodataFoundOne />;
  }

  if (state === 'two') {
    return <NodataFoundTwo />;
  }

  if (state === 'three') {
    return <NodataFoundThree />;
  }

  return (
    <Center
      mt='15px'
      padding='40px 25px'
      background='#fff'
      borderRadius='5px'
      minHeight='200px'
      width='100%'
      textAlign='center'
      flexDirection='column'
    >
      <img src={nodatafound} alt='' height='140px' width='140px' />
      <Box pt='20px'>
        <Heading
          fontSize='26px'
          color='#484848'
          fontWeight='500'
          mb='5px'
          textTransform='capitalize'
        >
          {heading}
        </Heading>
        <Text color='#bfbfbf' fontSize='18px'>
          {message}
        </Text>
      </Box>
    </Center>
  );
};

export const NoDataFoundWrapper = (props: NoDataFoundWrapperProps) => {
  if (props?.data?.length === 0) return <NoDataFound {...props}></NoDataFound>;

  return props?.children;
};

export default NoDataFound;
