// @ts-nocheck
import {
  GET_LEAVE_REQUEST_TYPE,
  GET_LEAVE_REQUEST_APPROVER,
  GET_LEAVE_REQUEST_BY_ID,
  GET_TEAM_MEMBER_LEAVE_DETAILS,
  SET_LEAVE_REQUEST_TYPE_LOADING,
  SET_LEAVE_REQUEST_APPROVER_LOADING,
  SET_LEAVE_REQUEST_DETAILS_LOADING,
  GET_LEAVE_REQUEST_DAYS,
  SET_LEAVE_REQUEST_DAYS_LOADING,
  CLEAR_LEAVE_REQUEST_DATAS,
} from "actions/types";
import { normalizeBy } from "helpers/normalizeData";

const initialState = {
  leaveRequestTypes: { loading: true, value: [] },
  leaveRequestApprover: {
    loading: false,
    value: [],
    normalizedValue: {
      Approvals: { byEmployeeId: {}, allEmployeeIds: [] },
      Recommended: { byEmployeeId: {}, allEmployeeIds: [] },
    },
  },
  leaveRequestDetails: { loading: true, value: {} },
  teamMembersLeaveDetails: [],
  leaveRequestDays: { loading: false, value: 0 },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LEAVE_REQUEST_TYPE:
      return {
        ...state,
        leaveRequestTypes: { loading: false, value: action.payload },
      };
    case GET_LEAVE_REQUEST_APPROVER:
      return {
        ...state,
        leaveRequestApprover: {
          loading: false,
          value: action.payload,
          normalizedValue: {
            ...state.leaveRequestApprover.normalizedValue,
            Approvals: {
              byEmployeeId: action.payload?.Approvals.reduce(
                normalizeBy("EmployeeId"),
                {}
              ),
              allEmployeeIds: action.payload?.Approvals?.map(
                (a) => a?.EmployeeId
              ),
            },
            Recommended: {
              byEmployeeId: action.payload?.Recommended.reduce(
                normalizeBy("EmployeeId"),
                {}
              ),
              allEmployeeIds: action.payload?.Recommended?.map(
                (r) => r?.EmployeeId
              ),
            },
          },
        },
      };
    case GET_LEAVE_REQUEST_BY_ID:
      return {
        ...state,
        leaveRequestDetails: { loading: false, value: action.payload },
      };
    case GET_TEAM_MEMBER_LEAVE_DETAILS:
      return {
        ...state,
        teamMembersLeaveDetails: action.payload,
      };
    case SET_LEAVE_REQUEST_APPROVER_LOADING:
      return {
        ...state,
        leaveRequestApprover: {
          loading: action.payload,
          value: [],
          normalizedValue: {
            Approvals: { byEmployeeId: {}, allEmployeeIds: [] },
            Recommended: { byEmployeeId: {}, allEmployeeIds: [] },
          },
        },
      };
    case SET_LEAVE_REQUEST_TYPE_LOADING:
      return {
        ...state,
        leaveRequestTypes: { loading: action.payload, value: [] },
      };
    case SET_LEAVE_REQUEST_DETAILS_LOADING:
      return {
        ...state,
        leaveRequestDetails: { loading: action.payload, value: {} },
      };
    case GET_LEAVE_REQUEST_DAYS:
      return {
        ...state,
        leaveRequestDays: { loading: false, value: action.payload },
      };
    case SET_LEAVE_REQUEST_DAYS_LOADING:
      return {
        ...state,
        leaveRequestDays: { loading: action.payload, value: 0 },
      };
    case CLEAR_LEAVE_REQUEST_DATAS:
      return initialState;
    default:
      return state;
  }
}
