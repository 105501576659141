// @ts-nocheck
import React from "react";

import RouteEnum from "constants/routeEnum";
import appraisalLinks from "./links";

const Appraisals = React.lazy(() => import('@rigotech/hrms-appraisal/src'))

const appraisal = (features) => [{
  path: RouteEnum.appraisalsRoutes,
  component: Appraisals,
  children: appraisalLinks,
  feature: features.PerfomanceAppraisal,
}]

export default appraisal
