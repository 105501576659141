import { ApiClient } from "../../../../network/apiClient";
import { UPDATE_EMPLOYEE_CONSENT } from "../api";

export const updateEmployeeConsent = ({ reviewId, history, dashboardRoute }: {
    reviewId: number;
    history: any;
    dashboardRoute: any;
}): any => async (dispatch: any) => {
    const response = await ApiClient.put<any>({
        endpoint: UPDATE_EMPLOYEE_CONSENT.replace(":reviewId", reviewId + ""),
        dispatch: dispatch
    });

    const { Status } = response;

    if (Status) {
        history.push(dashboardRoute)
    }
};
