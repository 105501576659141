import {
  SelectorTextBlockPageData,
  TextBlockReviewDetails,
} from "@rigotech/hrms-data/src/ts";
import { selectCurrentStep, selectReview } from ".";

export const selectTextBlockPageData = (
  state: any,
): SelectorTextBlockPageData => {
  const currentStep = selectCurrentStep(state);

  const review = selectReview(state);

  const { ReviewInfo } = review;

  const section = review.Sections?.find((section: any) => {
    return section.Id === currentStep.sectionId;
  });

  if (section) {
    const { PerformanceFormId, SectionName, IntroText, Contents } = section;

    const reviewDetails: TextBlockReviewDetails[] = Contents.map(
      ({ TextBlock }: any) => {
        return {
          TextBlock,
        };
      },
    );

    // =======================
    // DERIVE COMMENTS
    // =======================
    // let Comments: any = mapComments(SectionId, ContentId, state, ReviewInfo);

    // =======================
    // END DERIVE COMMENTS
    // =======================
    const mapped: SelectorTextBlockPageData = {
      Id: null, // THIS IS REVIEW ID
      PerformanceFormId,
      PerformanceRolloutId: ReviewInfo?.PerformanceRolloutId as number,
      reviewDetails,
      signature: false,

      SectionName,
      IntroText,
      Comments: [],
    };

    return mapped;
  }

  return {
    Id: null, // THIS IS REVIEW ID
    PerformanceFormId: 0,
    PerformanceRolloutId: 0,
    reviewDetails: [],
    signature: false,

    SectionName: "",
    IntroText: "",
    Comments: [],
  };
};
