import React from 'react'
import RouteEnum from "../../../constants/routeEnum";

const EmployeeRequestReviewScreen = React.lazy(() => import("../../../scenes/employee/requests/review"));
const MyRequestScreen = React.lazy(() => import("../../../scenes/employee/requests/myRequest"));
const MyRequestHistoryScreen = React.lazy(() => import("../../../scenes/employee/requests/myHistory"));
const TeamRequestHistoryScreen = React.lazy(() => import("../../../scenes/employee/requests/teamHistory"));

 

const requestLinks = [
    {
        path: RouteEnum.EmployeeRequestReviewPath,
        exact: true,
        component: EmployeeRequestReviewScreen,
        title: "Employee Request",
    },
    {
        path: RouteEnum.MyRequestPath,
        exact: true,
        component: MyRequestScreen,
        title: "My Request ",
    },
    {
        path: RouteEnum.MyRequestHistoryPath,
        exact: true,
        component: MyRequestHistoryScreen,
        title: "My Request History",
    },
    {
        path: RouteEnum.EmployeeRequestHistoryPath,
        exact: true,
        component: TeamRequestHistoryScreen,
        title: "Team Request History"
    },
];

export default requestLinks