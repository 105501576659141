import * as fromSlice from "./slice";
import * as fromInterface from "../../../ts";
import { ApiClient } from "../../../network/apiClient";
import { rolloutList, rolloutPerfomanceAppraisal } from "./api";
import * as datastore from "../../../index";
import { AprPerformanceFormReviewType } from "../../../enums/appraisal";
import * as fromReviewCycleDropdownStore from "../review-cycle-dropdown";
import * as fromPerformanceRolloutThreeSixtyFilterStore from "../performance-rollout-three-sixty-filter";

export const fetchPerformanceRolloutThreeSixtyInitialData = ({ reviewCycleId }: {
  reviewCycleId: number
}) => async (dispatch: any, getState: any) => {
  if (reviewCycleId) {

    await dispatch(fromReviewCycleDropdownStore.fetchReviewCycleDropDown());

    const state = getState();
    const dropdownItems = fromReviewCycleDropdownStore.selectReviewCycleDropdownItems(state);

    const reviewCYcleDropdownItemFound = dropdownItems.find((item: any) => item.value === reviewCycleId);

    await dispatch(fromPerformanceRolloutThreeSixtyFilterStore.udpatePerformanceRolloutThreeSixtyFilter({
      reviewCycleId: reviewCYcleDropdownItemFound,
    }));
    dispatch(fetchPerformanceRolloutThreeSixtyList());
  }


};

export const fetchPerformanceRolloutThreeSixtyList = () => async (dispatch: any, getState: any) => {
  dispatch(fromSlice.fetchRequest());

  const state = getState();
  const filterSlice = datastore.selectPerformanceRolloutThreeSixtyFilter(state);
  //fetch info
  dispatch(
    datastore?.fetchPerformanceRolloutInfo(AprPerformanceFormReviewType.ThreeSxity, filterSlice?.reviewCycleId?.value),
  );
  const bodyParam = {
    ...filterSlice,
    branchId: filterSlice?.branchId?.value || -1,
    departmentId: filterSlice?.departmentId?.value || -1,
    levelId: filterSlice?.levelId?.value || -1,
    reviewCycleId: filterSlice?.reviewCycleId?.value || -1,
    employeeName: filterSlice?.employee || "",
    managerId: filterSlice?.managerId?.value || -1,
    stat: filterSlice?.stat || "",
  };

  const response: fromInterface.ResponseWrapper<any> = await ApiClient.post<any>({
    endpoint: rolloutList,
    body: bodyParam,
    dispatch: dispatch,

  });

  if (response?.Status) {
    dispatch(fromSlice.fetchSuccess(response?.Data));
  }

  return response;
};

export const deletePerformanceRolloutThreeSixty = (id: number) => async (dispatch: any, getState: any) => {
  const response: fromInterface.ResponseWrapper<any> = await ApiClient.remove<any>({
    endpoint: `${rolloutPerfomanceAppraisal}/${id}`,
    dispatch: dispatch,
  });
  return response;
};

export const clearPerformanceRolloutThreeSixtyList = () => (dispatch: any) => {
  dispatch(fromSlice.clear());
  dispatch(datastore.clearPerformanceRolloutThreeSixtyFilter());
  dispatch(datastore.clearPerformanceRolloutInfo());
  dispatch(datastore.clearPerformanceRolloutEmployeeItems());
};