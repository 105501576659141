import * as fromInterface from "../../../ts";
import { StatusState } from "./slice";

export const currentStatusSlice = (state: any): StatusState => {
    return state.dataStore.status;
};

export const selectStatusStatus = (state: any): fromInterface.loadingType => {
    return currentStatusSlice(state).status;
};

export const selectStatusLoaded = (state: any): boolean => {
    return currentStatusSlice(state).loaded;
};

export const selectStatusItems = (state: any) => {
    return currentStatusSlice(state).items;
};
