import branch from "./branch/slice";
import currentFormDetail from "./current-form-detail/slice";
import currentSectionFormDetail from "./current-section-form-detail/slice";
import department from "./department/slice";
import finalScore from "./final-score/slice";
import globalFilter from "./global-filters/slice";
import goalCategory from "./goal-category/slice";
import goalDatabaseFilter from "./goal-database-filter/slice";
import goalDatabase from "./goal-database/slice";
import level from "./level/slice";
import newPerformanceSelectedFromOption from "./new-performance-selected-form-options/slice";
import participantWeights from "./participant-weights/slice";
import peerAffinityLevel from "./peer-affinity-level/slice";
import {
  performanceFormList,
  performanceFormListFilter,
} from "./peformance-form-list/slices";
import performanceFormDropdown from "./performance-form-dropdown/slice";
import preformanceFormState from "./performance-form-state/slice";
import performanceForm from "./performance-form/slice";
import performanceReviewActivities from "./performance-review-activities/slice";
import performanceReviewComment from "./performance-review-comments/slice";
import performanceReviewConsent from "./performance-review-consent/slice";
import performanceReviewEvaluation from "./performance-review-evaluation/slice";
import performanceReviewPreview from "./performance-review-preview/slice";
import performanceReview from "./performance-review/slice";
import performanceRolloutEmployeeFilter from "./performance-rollout-employee-filter/slice";
import performanceRolloutEmployee from "./performance-rollout-employee/slice";
import performanceRolloutInfo from "./performance-rollout-info/slice";
import performanceRolloutPreview from "./performance-rollout-preview/slice";
import performanceRolloutThreeSixtyFilter from "./performance-rollout-three-sixty-filter/slice";
import performanceRolloutThreeSixtyList from "./performance-rollout-three-sixty-list/slice";
import performanceScoreFilter from "./performance-score-filter/slice";
import performanceScore from "./performance-score/slice";
import pmsConfig from "./pms-config/slice";
import ratingScale from "./raitng-scale/slice";
import reviewCycleDropdown from "./review-cycle-dropdown/slice";
import reviewCycle from "./review-cycle/slice";
import reviewFlowDropdown from "./review-flow-dropdown/slice";
import rolloutManagerDropdown from "./rollout-manager-dropdown/slice";
import section from "./section/slice";
import selectedSectionOption from "./selected-section-option/slice";
import status from "./status/slice";
import performanceRolloutAppraisalFilter from "./performance-rollout-appraisal-filter/slice";
import performanceRolloutAppraisalList from "./performance-rollout-appraisal-list/slice";
import performanceRolloutAppraisal from "./performance-rollout-appraisal/slice";
import additionalScore from "./additional-score/slice";
import additionalScoreFilter from "./additional-score-filter/slice";
import employeeDevelopmentPlan from "./employee-development-plan/slice";
import pmsAnalytics from "./pms-analytics/slice";
import performanceFormWeight from "./performance-form-weight/slice";

export const appraisalSlices = {
  branch,
  currentFormDetail,
  performanceFormListFilter,
  currentSectionFormDetail,
  department,
  finalScore,
  globalFilter,
  goalCategory,
  goalDatabaseFilter,
  goalDatabase,
  level,
  newPerformanceSelectedFromOption,
  participantWeights,
  peerAffinityLevel,
  performanceFormList,
  performanceFormDropdown,
  preformanceFormState,
  performanceForm,
  performanceReviewActivities,
  performanceReviewComment,
  performanceReviewConsent,
  performanceReviewEvaluation,
  performanceReviewPreview,
  performanceReview,
  performanceRolloutEmployeeFilter,
  performanceRolloutEmployee,
  performanceRolloutInfo,
  performanceRolloutPreview,
  performanceRolloutThreeSixtyFilter,
  performanceRolloutThreeSixtyList,
  performanceScoreFilter,
  performanceScore,
  pmsConfig,
  ratingScale,
  reviewCycleDropdown,
  reviewCycle,
  reviewFlowDropdown,
  rolloutManagerDropdown,
  section,
  selectedSectionOption,
  status,
  performanceRolloutAppraisalFilter,
  performanceRolloutAppraisalList,
  performanceRolloutAppraisal,
  additionalScore,
  additionalScoreFilter,
  employeeDevelopmentPlan,
  pmsAnalytics,
  performanceFormWeight,
};
