import { createSlice } from "@reduxjs/toolkit";
import * as fromInterface from "../../../ts";

export interface ReviewCycleState {
    // entities: { [id: string]: fromInterface.ReviewCycle };
    // ids     : any[];
    items: any,
    // items: fromInterface.ReviewCycle[],
    status: fromInterface.loadingType;
    loaded: boolean;
}

const initialState: ReviewCycleState = {

    items: [],
    status: fromInterface.STATUS.LOADING,
    loaded: false,
};

const reviewCycleDropdownSlice = createSlice({
    name: "review cycle dropdown",
    initialState,
    reducers: {
        fetchRequest: (state) => {
            state.status = fromInterface.STATUS.LOADING;
        },
        fetchSuccess: (state, action) => {

            const payload = action.payload
            state.items = payload;
            state.status = fromInterface.STATUS.IDLE;
            state.loaded = true
        },



        clear: (state) => {
            state.items = []
            state.status = fromInterface.STATUS.IDLE
            state.loaded = false
        },
    },
});

export const {
    fetchRequest,
    fetchSuccess,
    clear,
} = reviewCycleDropdownSlice.actions

export default reviewCycleDropdownSlice.reducer;
