import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import isEmpty from "isEmpty";
import { getUploadPath } from "constants/userDetails";
import getFormattedNumber from "helpers/getFormattedNumber";

import RobotoRegular from "assets/fonts/Roboto-Regular.ttf";
import RobotoMedium from "assets/fonts/Roboto-Medium.ttf";
import RobotoBold from "assets/fonts/Roboto-Bold.ttf";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: RobotoRegular,
    },
    {
      src: RobotoMedium,
    },
    {
      src: RobotoBold,
    },
  ],
});

export default function travelPdfTemplate(props) {
  const {
    CompanyDetail,
    TravelDetails,
    TravelDestination,
    TravelExpense,
    TravelRequestApprover,
  } = props;

  let advanceAndExpense_Total = 0;
  return (
    <Document title="Travel Request.pdf">
      <Page style={styles.body} size="A4" orientation="portrait">
        {/*portrait */}
        {/* <Text style={styles.header} fixed>
          Travel Request
        </Text> */}
        {CompanyDetail && (
          <>
            {CompanyDetail.CompanyLogo && (
              <Image
                style={styles.image}
                src={getUploadPath(CompanyDetail.CompanyLogo)}
              />
            )}
            <Text style={styles.company}>{CompanyDetail.CompanyName}</Text>
            <Text style={styles.address}>{CompanyDetail.Address}</Text>
          </>
        )}
        <View style={{ marginTop: 15 }}></View>
        <Text style={styles.title}>Travel Report</Text>
        <View>
          <View style={styles.row}>
            <Text style={styles.leftColumn}>Travel Order</Text>
            <Text style={styles.rightColumn}>
              {!isEmpty(TravelDetails.RequestNumber)
                ? TravelDetails.RequestNumber
                : "--"}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.leftColumn}>Date</Text>
            <Text style={styles.rightColumn}>
              {TravelDetails.TravelOrderDate
                ? TravelDetails.TravelOrderDate
                : "--"}
            </Text>
          </View>
        </View>
        <View style={{ marginTop: 10 }}></View>
        {TravelDetails.RequestedBy && (
          <View>
            <Text style={styles.text}>Authorized to</Text>
            <Text style={styles.name}>
              {TravelDetails && TravelDetails.RequestedBy}
            </Text>
            <Text style={styles.text}>
              {TravelDetails.Designation && TravelDetails.Designation + ", "}
              {TravelDetails.Department && TravelDetails.Department + ", "}
              {TravelDetails.Branch}
            </Text>
          </View>
        )}
        <View style={styles.tableBlock}>
          <Text
            style={[
              styles.subtitle,
              {
                borderBottomWidth: 0.5,
                marginBottom: 3,
              },
            ]}
          >
            Travel Information
          </Text>
          {TravelDestination &&
            TravelDestination.map((dest, i) => {
              return (
                <View style={styles.travelInformation} key={i}>
                  <View style={styles.row}>
                    <Text style={styles.leftColumn}>Place of Travel</Text>
                    <Text style={styles.rightColumn}>{dest.PlaceName}</Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.leftColumn}>Mode</Text>
                    <Text style={styles.rightColumn}>{dest.TransportMode}</Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.leftColumn}>Dates</Text>
                    <Text style={styles.rightColumn}>
                      {dest.StartDate + " - "}
                      {dest.EndDate}
                      {!isEmpty(dest.TravelDuration) &&
                        " (" + dest.TravelDuration + ")"}
                    </Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.leftColumn}>Purpose</Text>
                    <Text style={styles.rightColumn}>
                      {TravelDetails.Purpose}
                    </Text>
                  </View>
                </View>
              );
            })}
        </View>
        <View style={styles.tableBlock}>
          <Text style={[styles.subtitle, { marginTop: 3 }]}>
            Allowances and Advance
          </Text>
          {TravelExpense && TravelExpense.length > 0 ? (
            <View style={styles.allowanceAndAdvance}>
              <View style={styles.tableTitle}>
                <Text style={styles.c1}>Particulars</Text>
                <Text style={styles.c2}>Units</Text>
                <Text style={styles.c3}>Rate</Text>
                <Text style={styles.c4}>Amount (Rs)</Text>
              </View>
              {TravelExpense.map((te, i) => {
                advanceAndExpense_Total =
                  advanceAndExpense_Total + parseFloat(te.Amount);
                return (
                  <View style={styles.row} key={i}>
                    <Text style={styles.c1}>{te.AllowanceName}</Text>
                    <Text style={styles.c2}>
                      {te.IsAllowance ? te.Units : "-"}
                    </Text>
                    <Text style={styles.c3}>
                      {te.IsAllowance ? te.Rate : "-"}
                    </Text>
                    <Text style={styles.c4}>
                      {te.Amount && te.Amount !== 0
                        ? getFormattedNumber(te.Amount)
                        : "-"}
                    </Text>
                  </View>
                );
              })}
              <View style={styles.total}>
                <Text style={styles.c1}>Total</Text>
                <Text style={styles.c2}></Text>
                <Text style={styles.c3}></Text>
                <Text style={styles.c4}>
                  {getFormattedNumber(advanceAndExpense_Total)}
                </Text>
              </View>
              <View style={styles.advance}>
                <Text style={styles.c1}>Advance</Text>
                <Text style={styles.c2}></Text>
                <Text style={styles.c3}></Text>
                <Text style={styles.c4}>
                  {TravelDetails.AdvanceTakenAmount !== 0
                    ? getFormattedNumber(TravelDetails.AdvanceTakenAmount)
                    : "-"}
                </Text>
              </View>
            </View>
          ) : (
            <Text style={styles.message}>-</Text>
          )}
        </View>

        <View style={styles.approvedBy}>
          <Text style={styles.approvedByTitle}>Travel Order approved by</Text>
          <View style={styles.authorizers}>
            {TravelRequestApprover && TravelRequestApprover.length > 0 ? (
              TravelRequestApprover.map((tra, i) => {
                return (
                  <View style={styles.authorizer}>
                    <Text style={styles.text}>{tra.ApproverName}</Text>
                    <Text style={styles.text}>{tra.Designation}</Text>
                    <Text style={styles.text}>{tra.ApprovedOn}</Text>
                  </View>
                );
              })
            ) : (
              <Text style={styles.message}>-</Text>
            )}
          </View>
        </View>
        <View style={styles.printedDate}>
          <Text style={styles.text}>
            {"Printed On " + TravelDetails.PrintedOn}
          </Text>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}

const styles = StyleSheet.create({
  body: {
    paddingTop: 25,
    paddingBottom: 45,
    paddingHorizontal: 70,
  },
  company: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "left",
    fontFamily: "Roboto",
  },
  branch: {
    fontSize: 10,
    textAlign: "left",
    marginTop: 5,
  },
  address: {
    fontSize: 12,
    fontFamily: "Roboto",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 16,
    marginBottom: 5,
    paddingVertical: 5,
  },
  subtitle: {
    fontSize: 12,
    textAlign: "left",
    paddingVertical: 3,
    marginTop: 5,
    fontFamily: "Roboto",
    fontWeight: "semibold",
  },

  row: {
    flexDirection: "row",
    marginVertical: 4,
  },
  leftColumn: {
    width: "20%",
    fontSize: 10,
    fontFamily: "Roboto",
  },
  rightColumn: {
    width: "80%",
    fontSize: 10,
    fontFamily: "Roboto",
    textTransform: "capitalize",
  },

  name: {
    fontSize: 10,
    fontWeight: 500,
    marginBottom: 4,
    textAlign: "left",
    fontFamily: "Roboto",
  },

  travelInformation: {
    marginTop: 3,
    fontFamily: "Roboto",
  },

  allowanceAndAdvanceTitle: {
    marginTop: 8,
    fontFamily: "Roboto",
  },
  allowanceAndAdvanceText: {
    fontSize: 12,
    textAlign: "left",
  },
  allowanceAndAdvance: {
    marginTop: 3,
  },

  tableTitle: {
    flexDirection: "row",
    paddingVertical: 4,
    borderTopWidth: 0.5,
    borderBottomWidth: 0.5,
    borderTopColor: "black",
    borderBottomColor: "black",
    marginBottom: 4,
    fontFamily: "Roboto",
  },
  c1: {
    width: "50%",
    fontSize: 10,
    paddingLeft: 2,
    fontFamily: "Roboto",
  },
  c2: {
    width: "13%",
    textAlign: "center",
    fontSize: 10,
    fontFamily: "Roboto",
  },
  c3: {
    width: "13%",
    textAlign: "center",
    fontSize: 10,
    fontFamily: "Roboto",
  },
  c4: {
    width: "30%",
    textAlign: "right",
    fontSize: 10,
    paddingRight: 4,
    fontFamily: "Roboto",
  },

  total: {
    flexDirection: "row",
    paddingVertical: 4,
    borderTopWidth: 0.5,
    borderTopColor: "black",
    borderBottomWidth: 0.5,
    borderBottomColor: "black",
  },

  advance: {
    flexDirection: "row",
    paddingVertical: 4,
    borderBottomWidth: 0.5,
    borderBottomColor: "black",
  },

  approvedBy: {
    marginVertical: 10,
  },
  approvedByTitle: {
    fontSize: 10,
    fontWeight: "bold",
    fontFamily: "Roboto",
  },
  tableBlock: {
    marginVertical: 5,
  },
  authorizers: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  authorizer: {
    maxWidth: "50%",
    flexDirection: "column",
    marginVertical: 5,
    paddingRight: 40,
  },
  printedDate: {
    marginVertical: 5,
  },
  text: {
    fontSize: 10,
    marginBottom: 4,
    textAlign: "left",
    fontFamily: "Roboto",
  },
  message: {
    fontSize: 8,
    margin: 4,
    textAlign: "left",
    color: "black",
    fontFamily: "Roboto",
  },
  image: {
    maxWidth: "50%",
    maxHeight: 30,
    objectFit: "contain",
    marginRight: "auto",
    marginBottom: 4,
  },
  header: {
    fontSize: 16,
    fontWeight: "700",
    marginBottom: 20,
    textAlign: "center",
    fontFamily: "Roboto",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});
