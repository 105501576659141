import isEmpty from 'isEmpty'
import {ADD_MESSAGES} from 'actions/types'
import shortid from 'shortid';

const dispatchError = (err,dispatch,type,message)=>{
    type && dispatch({
        type: type,
        payload: null
    });
    if(err && !isEmpty(err.response)){
        if(err.response.status === 500){
            let msg = !isEmpty(message)?`${message} - `:''
            dispatch({
                type: ADD_MESSAGES,
                payload: { type: 'Danger', message: `${msg} Internal Server Error`,id:shortid.generate() }
            });
        }else{
            dispatch({
                type: ADD_MESSAGES,
                payload: { type: 'Danger', message ,id:shortid.generate() }
            });
        }
    }
}



export default dispatchError;