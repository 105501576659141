import React from "react";
import {isFeatureEnabled} from "helpers/checkPermissions";
import {Route, Redirect} from "react-router-dom";
import {addMessage} from "actions/validateAction";
import {useDispatch, useSelector} from "react-redux";
import isEmpty from "isEmpty";

function FlagRoutes(props) {
  const error = {
    type: "Danger",
    message: `Please enable ${props.feature} feature to access this route`,
  };
  const dispatch = useDispatch();
  const {activeFeatureFlags, activeFeatures} = useSelector(
    (state) => state.permissionReducer
  );
  const {user} = useSelector((state) => state.auth);
  if (!isEmpty(props.feature) && !isEmpty(activeFeatureFlags)) {
    const isFeatured = isFeatureEnabled(props.feature);
    if (isFeatured) {
      return props.children;
    } else {
      dispatch(addMessage(error));
      // if(user.RoleId === 1){
      //     return <Redirect to={commonLinks.HRDashboardPath} />;
      // }
      // return <Redirect to={commonLinks.EmployeeDashBoard} />;
      return <Redirect to="/" />;
    }
  }
  return null;
}

export default FlagRoutes;
