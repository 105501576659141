import * as fromSlice from "./slice"
import * as fromInterface from "../../../ts"

const selectCurrentFinalScoreSlice = (
  state: any,
): fromSlice.FinalScoreState => {
  return state.dataStore.finalScore
}

export const selectFinalScoreItems = (state: any): any[] => {
  return selectCurrentFinalScoreSlice(state).items
}

export const selectFinalScoreStatus = (
  state: any,
): fromInterface.loadingType => {
  return selectCurrentFinalScoreSlice(state).status
}

export const selectFinalScoreLoaded = (state: any): boolean => {
  return selectCurrentFinalScoreSlice(state).loaded
}
