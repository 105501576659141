import React from 'react'

interface OnlyDevProps {
    children: any
}
export const OnlyDev = (props: OnlyDevProps) => {
    const { REACT_APP_ENVIRONMENT } = process.env;

    if (REACT_APP_ENVIRONMENT === "development" || REACT_APP_ENVIRONMENT === "staging") {
        return props.children
    }
    return null;
}

export default OnlyDev
