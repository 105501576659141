import React, { Component } from "react";
// import avatar from "assets/images/img/man.png";
import { connect } from "react-redux";
// import { getBaseAxios } from "helpers/Constants";
import { Flex, Heading } from "@chakra-ui/react";
import { getUser, loginUser, logoutUser } from "actions/authAction";
import { GetCompanyInformation } from "actions/commonAction";
import SvgIcons from "components/icons/svgIcons";
import { getUploadPath } from "constants/userDetails";
import Avatar from "react-avatar";
import { AiOutlineSearch } from "react-icons/ai";
import { MdKeyboardArrowDown } from "react-icons/md";
import ChangePassword from "scenes/common/changePassword";
import { ClearTravelCount } from "scenes/travel/common/travelInformation/actions";
import * as fromGlobalUiStore from "store/global-ui";
import Modal from "../modal";
import ProfilePictureChange from "./profilePictureChange";
import SearchEmployeeMenu from "./searchEmployeeMenu";
class Header extends Component {
  // public dropdown:any;
  constructor(props) {
    super(props);
    this.state = {
      companyInfo: null,
      drop: false,
      isModalOpen: false,
      swicth: true,
      search: false,
      searchText: "",
      features: [],
      categoryWiseList: [],
    };
    this.dropdown = "";
    this.headerRef = "";
    this.onHeaderClick = this.onHeaderClick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.keyOutside = this.keyOutside.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
    this.onSwitchUser = this.onSwitchUser.bind(this);
    this.updateCompanyState = this.updateCompanyState.bind(this);
  }

  handleClickOutside(event) {
    event.stopPropagation();
    if (this.dropdown && !this.dropdown.contains(event.target)) {
      this.setState({ drop: false });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener("keydown", this.keyOutside);
  }
  async componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("keydown", this.keyOutside);
    // await this.props.GetCompanyInformation();

    // const companyInformation = null;

    this.updateCompanyState();
  }
  componentDidUpdate(prevProps, preState) {
    if (prevProps.companyInformation !== this.props.companyInformation) {
      if (this.props.companyInformation) {
        this.updateCompanyState();
      }
    }
  }
  updateCompanyState() {
    let companyInfo = null;
    const { companyInformation, features } = this.props;
    if (companyInformation) {
      // const logoExtension =
      //   companyInformation &&
      //   companyInformation.CompanyLogo &&
      //   companyInformation.CompanyLogo.split(".")[1];
      // let companyLogo = null;
      // if (logoExtension) {
      //   companyLogo = companyInformation.CompanyLogo;
      // } else {
      //   companyLogo = companyInformation.CompanyLogo + ".png";
      // }

      companyInfo = {
        companyGuIdId: companyInformation.ID,
        companyName: companyInformation.Name,
        address: companyInformation.Address,
        countryId: companyInformation.CountryId,
        currencyCode: companyInformation.CurrencyCode,
        email: companyInformation.Email,
        // logo: getBaseAxios() + "/images/" + companyLogo,
        logo: null,
      };
      this.setState({ ...this.state, companyInfo: companyInfo, features: features });
    }
  }
  keyOutside(e) {
    if (e.key === "Escape") {
      this.setState({ search: false });
    } else if (e.key === "k" && e.ctrlKey) {
      this.setState({ search: true });
      e.preventDefault();
      e.stopPropagation();
    }

    //  else if(e.key==="")
  }
  onUserLogout(e) {
    e.stopPropagation();
    // this.props.onLogoutCheck(auth);
    this.props.logoutUser();
    this.props.ClearTravelCount();
  }
  onHeaderClick(e) {
    // if (e.target.classList.contains("header-search-active")) {
    //   this.setState({ search: false });
    // }
  }
  onModalClose() {
    this.setState({ isModalOpen: false });
  }
  onSwitchUser = () => {
    const { user } = this.props.auth;
    const data = {
      rigoId: user.MapToUserName,
      password: "",
      strategy: "switchuser",
    };
    this.props.loginUser(data, this.changeHistory, true);
  };

  changeHistory = async () => {
    await this.props.getUser();
  };

  handleSearhEmpMenuClose() {
    this.setState({ ...this.state, search: false });
  }

  render() {
    const { companyInfo } = this.state;

    const { informationalVersion } = this.props;
    const { user, baseUrl } = this.props.auth;
    const base = baseUrl ? baseUrl.NetFrameworkAppBaseUrl : "";

    return (
      <header id="header" className="header" ref={(ref) => (this.headerRef = ref)}>

        <div onClick={(e) => this.onHeaderClick(e)} className="header-contain">
          <div className="header-bar">
            <div className="left">

              <Flex alignItems="center" gap={2} >

                <Heading fontSize="20px" fontWeight="500" color="#252525" >{companyInfo && companyInfo.companyName}</Heading>
              </Flex>
            </div>
            <div className="right">
              <div
                className="header-search-icon"
                onClick={() => this.setState({ search: !this.state.search })}
              >
                <AiOutlineSearch />
              </div>
              <div
                ref={(ref) => (this.dropdown = ref)}
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState({ drop: !this.state.drop });
                }}
                className={`header-avatar header-drop ${this.state.drop ? "active" : ""}`}
              >
                <div className="header-avatar__items">
                  <Avatar
                    className="company-logo"
                    name={user.UserName}
                    size={"30"}
                    round={true}
                    src={getUploadPath(user.UserImage)}
                  />
                  <div className="header-down-caret">
                    <MdKeyboardArrowDown />
                  </div>
                </div>
                <div className="header-drop__content">
                  <div className="header-drop__image">
                    <Avatar
                      className="company-logo"
                      name={user.UserName}
                      size={"80"}
                      round={true}
                      src={getUploadPath(user.UserImage)}
                    />
                  </div>
                  <div className="header-drop__name">
                    <h2>{user && user.UserName}</h2>
                    <p>
                      {user && user.Designation ? (
                        user.Designation
                      ) : (
                        <span className="no-found">No Desgination</span>
                      )}
                    </p>
                    <div className="header-change-profile">
                      <ProfilePictureChange />
                    </div>
                  </div>
                  <ul className="header-drop-list">
                    {user && user.SwitchToEmployeeAllowed && (
                      <li
                        onClick={() => this.onSwitchUser()}
                        className="header-drop-list__item with-hr "
                      >
                        <span className="header-drop-icon">
                          <SvgIcons size="16px" name="repeat" />
                        </span>
                        <span className="header-drop-name">View as Employee</span>
                      </li>
                    )}
                    <li
                      onClick={() => window.open(base + "newhr/DashboardHR.aspx")}
                      className="header-drop-list__item with-hr"
                    >
                      <span className="header-drop-icon">
                        <SvgIcons size="16px" name="techo" />
                      </span>
                      <span className="header-drop-name">Old Dashboard</span>
                    </li>

                    <li
                      onClick={() => this.setState({ isModalOpen: true })}
                      className="header-drop-list__item with-hr"
                    >
                      <span className="header-drop-icon">
                        <SvgIcons size="16px" name="key" />
                      </span>
                      <span className="header-drop-name">Change Password</span>
                    </li>
                    <li
                      onClick={(e) => this.onUserLogout(e)}
                      className="header-drop-list__item with-hr"
                    >
                      <span className="header-drop-icon">
                        <SvgIcons size="16px" name="sign-out" />
                      </span>
                      <span className="header-drop-name">Sign Out</span>
                    </li>
                  </ul>

                  <div className="header-app-version">
                    Version: {informationalVersion}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          onModalClose={this.onModalClose}
          open={this.state.isModalOpen}
          className=""
          width="400px"
          title={"Change Password"}
          isPrimary
        >
          <div>
            <ChangePassword onModalClose={this.onModalClose} />
          </div>
        </Modal>
        <Modal
          onModalClose={() => this.handleSearhEmpMenuClose()}
          open={this.state.search}
        >
          <SearchEmployeeMenu
            {...this.props}
            onModalClose={() => this.handleSearhEmpMenuClose()}
          />
        </Modal>
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  companyInformation: state.commonReducer.companyInformation,
  features: state.permissionReducer.features,
  auth: state.auth,
  informationalVersion: state.commonReducer.informationalVersion,
});

const mapDispatchToProps = {
  GetCompanyInformation,
  logoutUser,
  loginUser,
  getUser,
  ClearTravelCount,
  toggleSidebar: fromGlobalUiStore.toggleSidebar,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
