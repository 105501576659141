import {
  GET_DISTRIBUTED_INCOME_BY_AMOUNT,
  SAVE_MESSAGE_TEMPLATE,
  MESSAGE_TEMPLATE_LIST,
  DELETE_SAVE_MESSAGE_TEMPLATE,
  CLEAR_SAVE_MESSAGE_TEMPLATE,
} from "actions/types";

const initialState = {
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DISTRIBUTED_INCOME_BY_AMOUNT: {
      return {
        ...state,
        distributedIncomeByAmount: action.payload,
      };
    }
    case SAVE_MESSAGE_TEMPLATE: {
      return {
        ...state,
        saveMessageTemplate: true,
      };
    }
    case CLEAR_SAVE_MESSAGE_TEMPLATE: {
      return {
        ...state,
        saveMessageTemplate: false,
      };
    }
    case MESSAGE_TEMPLATE_LIST: {
      return {
        ...state,
        loading: false,
        messageTemplateList: action.payload,
      };
    }
    case DELETE_SAVE_MESSAGE_TEMPLATE: {
      return {
        ...state,
        deleteSalaryTemplate: true,
      };
    }
    default:
      return state;
  }
}
