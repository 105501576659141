import * as fromModels from "@rigotech/hrms-model";
import { PerformanceRolloutPreviewState } from "./slice";

const selectPerformanceRolloutPreviewSlice = (state: any): PerformanceRolloutPreviewState => {
  return state.dataStore.performanceRolloutPreview;
};

export const selectStatus = (state: any): fromModels.loadingType => {
  return selectPerformanceRolloutPreviewSlice(state).status;
};

export const selectLoaded = (state: any): boolean => {
  return selectPerformanceRolloutPreviewSlice(state).loaded;
};

export const selectItem = (state: any) => {
  return selectPerformanceRolloutPreviewSlice(state).item;
};
