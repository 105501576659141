import {
  GET_REQUISITION_NUMBER,
  GET_MY_REQUISITION,
  SET_SPINNER_LOADING,
  GET_REQUISITION_DETAILS,
  GET_APPROVER_REQUISITION,
  IS_REQUISITION_DETAILS_LOADING,
  SAVE_JOB_REQUISITION,
  GET_REQUISITION_LIST_COUNT,
  GET_APPROVED_REQUISITION,
  APPROVED_REQUISITION_LOADING,
  CANCEL_JOB_REQUISITION
} from "../../../actions/types";

const initialState = {
  requisitionListCount: [],
  requisitionNumber: "",
  loading: true,
  spinnerLoading: false,
  approvers: [],
  myRequisition: [],
  approverRequisition: [],
  approvedRequisition:[],
  approvedReqLoading:false,
  requisitionDetails: [],
  myRequisitionSearchedPage: { index: 1, total: 0 },
  approverRequisitionSearchedPage: { index: 1, total: 0 },
  cancelledList: [],
  isSaveSuccess: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case APPROVED_REQUISITION_LOADING:
      return{
        ...state,
        approvedReqLoading:true
      };
    case GET_APPROVED_REQUISITION:
      return{
        ...state,
        approvedRequisition:action.payload,
        approvedReqLoading:false
      };
    case GET_REQUISITION_NUMBER:
      return {
        ...state,
        requisitionNumber: action.payload
      };
    case GET_MY_REQUISITION:
      return {
        ...state,
        myRequisition: action.payload,
        myRequisitionSearchedPage: {
          index: action.page.index,
          total: action.page.total
        },
        loading: false
      };
    case GET_APPROVER_REQUISITION:
      return {
        ...state,
        approverRequisition: action.payload,
        approverRequisitionSearchedPage: {
          index: action.page.index,
          total: action.page.total
        },
        loading: false
      };
    case GET_REQUISITION_DETAILS:
      return {
        ...state,
        requisitionDetails: action.payload
      };
    case SET_SPINNER_LOADING:
      return {
        ...state,
        spinnerLoading: action.payload
      };
    case IS_REQUISITION_DETAILS_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case SAVE_JOB_REQUISITION:
      return {
        ...state,
        isSaveSuccess: true
      };
    case GET_REQUISITION_LIST_COUNT:
      return {
        ...state,
        requisitionListCount: action.payload
      };
    case CANCEL_JOB_REQUISITION:
      return {
        ...state,
        cancelledList: [...state.cancelledList, action.payload],
        spinnerLoading: false
      };
    default:
      return state;
  }
}
