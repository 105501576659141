// @ts-nocheck
import React from "react";

import RouteEnum from "constants/routeEnum";

const BranchesAndWorkSites = React.lazy(
  () => import("@rigotech/hrms-geofencing/src/pages/branches-and-work-sites")
);
const LoactionAttendance = React.lazy(
  () => import("@rigotech/hrms-geofencing/src/pages/location-attendance")
);
// const GeoOfficeSites = React.lazy(
//   () => import("@rigotech/hrms-geofencing/src/pages/geo-office-sites")
// );
const AttendanceDeviceRules = React.lazy(
  () => import("@rigotech/hrms-geofencing/src/pages/attendance-device-rules")
);

/**
 * SUPPLY LINKS TO RENDER IN SIDDEBAR
 */
const geoFencinglLinks = [
  {
    path: RouteEnum.geoBranchesAndWorkSitesRoutes,
    exact: true,
    component: BranchesAndWorkSites,
    title: "Branches and Work Sites",
  },
  {
    path: RouteEnum.geoLoactionAttendanceRoutes,
    exact: true,
    component: LoactionAttendance,
    title: "Location Attendance",
  },
  // {
  //   path: RouteEnum.geoOfficeRoutes,
  //   exact: true,
  //   component: GeoOfficeSites,
  //   title: "Geo Office Sites",
  // },
  {
    path: RouteEnum.attendanceRulesRoutes,
    exact: true,
    component: AttendanceDeviceRules,
    title: "Attendance Devices and Rules",
  },
];

export default geoFencinglLinks;
