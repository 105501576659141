import { GOAL_CATEGORY } from "./api"
import * as fromInterface from "../../../ts"
import { ApiClient } from "../../../network/apiClient"
import * as fromSlice from "./slice"

import * as fromSelector from "./selectors"

export const fetchGoalCategories =
  (isArchived: boolean) => async (dispatch: any) => {
    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.get<any>({
        endpoint: `${GOAL_CATEGORY}/${isArchived}`,
        dispatch: dispatch,
      })
    const { Data, Status } = response

    return { Data, Status }
  }

export const fetchGoalCategoriesDropdown = (): any => async (dispatch: any, getState: any) => {
  dispatch(fromSlice.fetchRequest())
  const state = getState();
  const isLoaded = fromSelector.selectGoalCategoryLoaded(state)
  if (!isLoaded) {
    const response = await ApiClient.get<any>({
      endpoint: `${GOAL_CATEGORY}`,
      dispatch: dispatch,
    })
    if (response?.Status) {
      dispatch(fromSlice.fetchSuccess(response?.Data))
    }
  } return fromSelector.selectGoalCategoryItems(state)
}

export const saveGoalCategory =
  (data?: any, cb?: any): any =>
    async (dispatch: any) => {
      const param = {
        endpoint: GOAL_CATEGORY,
        body: data,
        dispatch: dispatch,
      }
      const response: fromInterface.ResponseWrapper<any> =
        await ApiClient.post<any>(param)
      const { Status } = response

      // Status && dispatch(fetchDevelopmentAreas(false))
      cb?.(Status)
    }

export const deleteGoalCategory =
  (id: number): any =>
    async (dispatch: any) => {
      const param = {
        endpoint: `${GOAL_CATEGORY}/${id}`,
        dispatch: dispatch,
      }
      const response: any = await ApiClient.remove(param)
      const { Status } = response
      return Status
    }
