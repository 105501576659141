import * as fromSlice from "./slice";
import * as fromInterface from "./../../../ts";
import { selectBranchItems } from "store/appraisal";

const selectCurrentDepartmentSlice = (state: any): fromSlice.DepartmentState => {
    return state.dataStore.department;
};

export const selectDepartmentItems = (state: any): any[] => {
    return selectCurrentDepartmentSlice(state).items;
};


export const selectDepartmentDropdownItems = (state: any): any[] => {
    return selectDepartmentItems(state)?.map(item => {
        return {
            label: item.Text,
            value:item.Value
        }
    }) || [];
};

export const selectDepartmentStatus = (state: any): fromInterface.loadingType => {
    return selectCurrentDepartmentSlice(state).status;
};

export const selectDepartmentLoaded = (state: any): boolean => {
    return selectCurrentDepartmentSlice(state).loaded;
};