import React from 'react';
import { Link } from 'react-router-dom';
import { When, If, Then, Else } from 'react-if';
import {
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbProps,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
  Heading,
  Box,
  Tooltip,
  // BreadcrumbSeparator,
  // Link,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { PageHeading } from '../../atoms';
import {
  FontSizes,
  Spacing,
} from '@rigotech/hrms-data';
export interface BreadcrumbItems {
  href?: string;
  to?: string | any;
  as?: any;
  text?: string;
  isCurrentPage?: boolean;
}

interface RigoBreadcrumbProps extends BreadcrumbProps {
  items: BreadcrumbItems[];
  showTitle?: boolean;
}

export const Breadcrumb: React.FunctionComponent<RigoBreadcrumbProps> =
  props => {
    const { items, showTitle, ...newProps } = props;

    let subBreadcumbText = '';
    return (
      <>
        <ChakraBreadcrumb
          {...newProps}
          separator={<ChevronRightIcon color='gray.500' />}
          mb={Spacing.s}
          mt='0'
          paddingInlineStart='0'
        >
          {items &&
            items.map((item: any, index) => {
              if (item.isCurrentPage) {
                subBreadcumbText = item.text ?? '';
              }

              let linkProps = {};
              if (item.to) {
                linkProps = { as: item?.as || Link, to: item.to };
              } else {
                linkProps = { href: item.href || '#' };
              }

              return (
                <BreadcrumbItem
                  isCurrentPage={item.isCurrentPage || false}
                  key={index}
                >
                  {item.isCurrentPage ? (
                    <If condition={item?.text?.length > 36}>
                      <Then>
                        <Tooltip hasArrow label={item.text} placement='bottom'>
                          <Text maxW='280px' noOfLines={1}>
                            {item.text}
                          </Text>
                        </Tooltip>
                      </Then>
                      <Else>
                        <Text maxW='280px' noOfLines={1}>
                          {item.text}
                        </Text>
                      </Else>
                    </If>
                  ) : (
                    <>
                      <BreadcrumbLink
                        {...linkProps}
                        _focus={{ outline: 'none' }}
                        color='blue.500'
                      >
                        {item.text}
                      </BreadcrumbLink>
                    </>
                  )}
                </BreadcrumbItem>
              );
            })}
        </ChakraBreadcrumb>

        {subBreadcumbText && props.showTitle && (
          <If condition={subBreadcumbText?.length > 50}>
            <Then>
              <Tooltip hasArrow label={subBreadcumbText} placement='bottom'>
                <PageHeading
                  as='h3'
                  fontSize={FontSizes.Headings.SectionHeadingLarge}
                  mb={Spacing.ml}
                  lineHeight='1.5'
                  fontWeight={700}
                  maxW='500px'
                  noOfLines={1}
                >
                  {subBreadcumbText}
                </PageHeading>
              </Tooltip>
            </Then>
            <Else>
              <PageHeading
                as='h3'
                fontSize={FontSizes.Headings.SectionHeadingLarge}
                mb={Spacing.ml}
                lineHeight='1.5'
                fontWeight={700}
                maxW='500px'
                noOfLines={1}
              >
                {subBreadcumbText}
              </PageHeading>
            </Else>
          </If>
        )}
      </>
    );
  };

Breadcrumb.defaultProps = {
  mb: '10px',
  mt: '10px',
  px: 0,
  spacing: '8px',
  fontSize: '14px',
  showTitle: true,
};

// export const Breadcrumb = () => {
//   return <p>Breadcrumb</p>;
// };

export default Breadcrumb;
