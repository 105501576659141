import * as fromModel from "@rigotech/hrms-model";
import * as fromSlice from "./slice";

export const selectSlice = (state: any): fromSlice.PerformanceFormWeightState => {
  return state.dataStore.performanceFormWeight;
};

export const selectItem = (state: any): fromModel.IPerformanceFormWeights | null => {
  return selectSlice(state).performanceFormWeights;
};

export const selectLoaded = (state: any): boolean => {
  return selectSlice(state).loaded;
};

export const selectStatus = (state: any): fromModel.loadingType => {
  return selectSlice(state).status;
};
