import { GET_EMPLOYEE_LEAVE_LIST2 } from "actions/types";

const initialState = {
  employeeLeaveList: null,
  employeeLeaveList_Formated: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEE_LEAVE_LIST2:
      return {
        ...state,
        employeeLeaveList: action.response,
        employeeLeaveList_Formated: action.formatedResponse,
      };
    default:
      return state;
  }
}
