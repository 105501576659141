// @ts-nocheck 
import React, { Component, useEffect, useRef } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import useOutsideClick from "helpers/customHooks/outsideClickHook";
import { FaRegCalendarAlt } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { DateRangePicker } from "react-date-range";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import {
  startDatetime,
  endDatetime,
  Date_Format,
  Nepali_Date_Format
} from "../../helpers/dateFormat";
import isEmpty from "../../helpers/isEmpty";
import {dateRangeInterface} from 'ts/interfaces/formInterface'
declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    tabindex?: string;
  }
}

function ReactDateRange(props: dateRangeInterface) {
  const {
    name,
    type,
    startDate,
    endDate,
    autoFocus,
    disabled,
    placeholder,
  } = props;

  let wrapperRef: any = useRef();
  let dateRangeRef: any = useRef();

  useOutsideClick(dateRangeRef, () =>
    wrapperRef.current.classList.remove("active")
  );
  const handleDateClick = (event: any) => {
    if (
      wrapperRef.current.contains(event.target) &&
      !isEmpty(dateRangeRef.current) &&
      !dateRangeRef.current.contains(event.target)
    )
      wrapperRef.current.classList.toggle("active");
  };

  const handleDateRangePicker = (range: any) => {
    let { startDate, endDate } = range.selection;
    startDate = startDate ? startDatetime(startDate) : null;
    endDate = endDate ? endDatetime(endDate) : null;

    const date = { startDate, endDate };
    props.onChange && props.onChange(name, date);
  };

  const removeSelectedDate = () => {
    const date = { startDate: null, endDate: null };
    props.onChange && props.onChange(name, date);
  };

  const selectionRange = {
    startDate: !isEmpty(startDate) ? new Date(startDate) : new Date(),
    endDate: !isEmpty(endDate) ? new Date(endDate) : new Date(),
    key: "selection",
  };

  // useEffect(()=>{
  //   console.log(wrapperRef)
  
  // },[wrapperRef])

  return (
    <div
      className="daterangepicker"
      ref={wrapperRef}
      tabindex="0"
      onFocus={(e)=>handleDateClick(e)}
      // onClick={(e) => handleDateClick(e)}
    >
      <span className="daterangepicker__date">
        {!isEmpty(startDate) && !isEmpty(endDate) ? (
          <span>{Nepali_Date_Format(startDate) + " - " + Nepali_Date_Format(endDate)}</span>
        ) : (
          <span className="placeholder">{placeholder}</span>
        )}
      </span>
      {disabled ? null : (
        <div className="daterangepicker__range" ref={dateRangeRef}>
          <DateRangePicker
            // startDate={
            //   !isEmpty(startDate) ? moment(startDate) : moment(new Date())
            // }
            // endDate={!isEmpty(endDate) ? moment(endDate) : moment(new Date())}
            // onInit={this.handleDateRangePicker}

            ranges={[selectionRange]}
            onChange={handleDateRangePicker}
          />
          {/* <Calendar
          date={new Date()}
          // onChange={this.handleSelect}
        /> */}
        </div>
      )}
      {isEmpty(startDate) && isEmpty(endDate) ? (
        <span className="daterangepicker__icon">
          <FaRegCalendarAlt />
        </span>
      ) : (
        <span className="daterangepicker__icon" onClick={removeSelectedDate}>
          <MdClose />
        </span>
      )}
    </div>
  );
}

ReactDateRange.defaultProps = {
  startDate: "",
  endDate: "",
  placeholder: "Date range..",
};

export default ReactDateRange;
