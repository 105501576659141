import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Table } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { TableCell } from "@material-ui/core";
import { TableContainer } from "@material-ui/core";
import { TableHead } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { TableFooter } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import getFormattedNumber from "helpers/getFormattedNumber";
import FormGroup from "components/form";
import { useDispatch } from "react-redux";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fafafa",
    color: "#777 !important",
    borderBottom: "2px solid #cbcbcb",
    padding: "12px",
    fontWeight: "400",
    fontSize: 15,
  },
  body: {
    fontSize: 14,
    padding: "12px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const StyledPaper = withStyles((theme) => ({
  root: {
    boxShadow: "unset",
    width: 600,
  },
}))(Paper);

function createData(Allowance, IsAllowance, Units, Rate, Amount) {
  let obj = { Allowance, IsAllowance: IsAllowance, Units, Rate, Amount };
  console.log(obj);
  return obj;
}

const useStyles = makeStyles({
  table: {
    minWidth: 200,
  },
  footerCell: {
    fontSize: "15px !important",
    color: "#444 !important",
    fontWeight: 600,
  },
});

export default function AdvanceAndExpensesTable(props) {
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const { data, isViewMode } = props;

  useEffect(() => {
    if (data) {
      let dataRows = [];

      console.log(data);
      data.map((m) => {
        dataRows.push(
          createData(m.allowance_name, m.isAllowance, m.units, m.rate, m.amount)
        );
      });
      console.log(dataRows);
      console.log(data);
      setRows(dataRows);
    }
  }, [props.data]);

  const classes = useStyles();
  let totalAmount = 0;

  const handleTextChange = (index, name, value) => {
    const { data } = props;
    const newData = data?.map((item, idx) => {
      return index === idx ? { ...item, amount: Number(value) } : item;
    });

    dispatch({ type: "GET_CATEGORY_ALL_EXPENSE_LIST", payload: newData });
  };

  return (
    <TableContainer component={StyledPaper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Allowance</StyledTableCell>
            <StyledTableCell align="right">Units</StyledTableCell>
            <StyledTableCell align="right">Rate</StyledTableCell>
            <StyledTableCell align="right">Amount</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows &&
            rows.map((row, index) => {
              totalAmount = parseFloat(totalAmount) + parseFloat(row.Amount);
              return (
                <StyledTableRow key={row.Allowance}>
                  <StyledTableCell component="td" scope="row">
                    {row.Allowance}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.IsAllowance === true ? row.Units : "-"}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.IsAllowance === true
                      ? getFormattedNumber(row.Rate)
                      : "-"}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <>
                      {row.IsAllowance === true ? (
                        getFormattedNumber(row.Amount)
                      ) : (
                        <FormGroup
                          disabled={isViewMode}
                          name="Amount"
                          type="number"
                          value={row.Amount}
                          onChange={(name, value) =>
                            handleTextChange(index, name, value)
                          }
                          width="110px"
                        />
                      )}
                    </>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <StyledTableCell className={classes.footerCell} align="left">
              Total
            </StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell className={classes.footerCell} align="right">
              {getFormattedNumber(totalAmount)}
            </StyledTableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
