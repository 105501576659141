export const WHITE = "#ffffff";

export const PRIMARY = "#0066d1";
export const PRIMARY_TEXT = "#006df9";

export const COMPLETED = "#3cb159";
export const IN_PROGRESS = "#ebac05";
export const NOT_STARTED = "#757575";
export const IN_CORRECT = "#ea4a22";

export const COURSE_BACKGROUND = "#dfeaf5";
export const COURSE_SKELETON = "#f6f7f8";
export const WRONG_ANSWER_BACKGROUND = "#ced6dc";
export const CORRECT_ANSWER_BACKGROUND = "#3cb159";
export const BORDER_COLOR = "#d8e0e8";
