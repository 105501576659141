// @ts-nocheck 
const isEmpty = (value: any): boolean =>
  value === undefined ||
  value === null ||
  value === {} ||
  value === "" ||
  value === "null" ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);

export default isEmpty;
