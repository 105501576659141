import { getBaseAxios } from '../helpers/constants';

export const ADMIN_ROLE_ID = 1;

export const TRAVEL_ROLE_ID = 42;

export const BASE_URL = getBaseAxios();

export const TRAVEL_REQUEST_APPROVAL_FLOW_TYPE = 1;

export const SETTLEMENT_REQUEST_APPROVAL_FLOW_TYPE = 3;

export const DISCIPLINARY_ACTION_DETAILS = [
  {
    ActionId: 0,
    ActionTitle: 'Close the Case',
    ActionDateTitle: 'Closure date',
    ActionByTitle: 'Authorized by',
    ActionNoteTitle: 'Closure Note',
    SubmitButtonText: 'Close the Case',
  },
  {
    ActionId: 1,
    ActionTitle: 'Counselling Note',
    ActionDateTitle: 'Counselled date',
    ActionByTitle: 'Counselled by',
    ActionNoteTitle: 'Counselling Note',
    SubmitButtonText: 'Add Note',
  },
  {
    ActionId: 2,
    ActionTitle: 'Verbal Warning Note',
    ActionDateTitle: 'Warning date',
    ActionByTitle: 'In presence of',
    ActionNoteTitle: 'Warning Note',
    SubmitButtonText: 'Add Note',
  },
  {
    ActionId: 3,
    ActionTitle: 'Written Warning Note',
    ActionDateTitle: 'Warning date',
    ActionByTitle: 'Authorized by',
    ActionNoteTitle: 'Warning Note',
    SubmitButtonText: 'Add Note',
  },
  {
    ActionId: 4,
    ActionTitle: 'Take an Action',
    ActionDateTitle: 'Action date',
    EffectiveFromTitle: 'Effective From',
    ActionByTitle: 'Authorized by',
    ActionNoteTitle: 'Action Note',
    SubmitButtonText: 'Add Note',
  },
];

export const SUMMARY_OF_POSITION_ID = 1;
export const SUMMARY_OF_POSITION_TYPE = 'Summary of the Position';
export const PRIMARY_DUITIES_RESPONSIBILTY_ID = 2;
export const PRIMARY_DUITIES_RESPONSIBILTY_TYPE =
  'Primary Duties and Responsibilities';
export const EDUCATION_QUALIFICATION_ID = 3;
export const EDUCATION_QUALIFICATION_TYPE = 'Education and Certifications';
export const KNOWLEDGE_SKILL_ABILITY_ID = 4;
export const KNOWLEDGE_SKILL_ABILITY_TYPE =
  'Knowledge, Skills and Abilities (KSA)';
export const SCOPE_IMPACT_ID = 5;
export const SCOPE_IMPACT_TYPE = 'Scope and Impact';
export const CUSTOM_PROFILE_BLOCK_TYPE = 'Custom Section';
export const CUSTOM_PROFILE_BLOCK_ID = 6;
export const COMPONENT_TYPE = {
  Paragraph: 1,
  Education: 2,
  Qualification: 3,
  Knowledge: 4,
  Skills: 5,
  Abilities: 6,
};
export const JOB_PROFILE_PARAGRAPH_TYPE = 'Paragraph';
export const JOB_PROFILE_EDUCATION_TYPE = 'Education';
export const JOB_PROFILE_QUALIFICATION_TYPE = 'Certification';
export const JOB_PROFILE_KNOWLEDGE_TYPE = 'Knowledge';
export const JOB_PROFILE_SKILL_TYPE = 'Skills';
export const JOB_PROFILE_ABILITY_TYPE = 'Abilities';

export const JOB_PROFILE_DEFAULT_BLOCKS = [
  {
    Id: SUMMARY_OF_POSITION_ID,
    Name: 'Summary of the Position',
    Type: SUMMARY_OF_POSITION_TYPE,
  },
  {
    Id: PRIMARY_DUITIES_RESPONSIBILTY_ID,
    Name: 'Primary Duties and Responsibilities',
    Type: PRIMARY_DUITIES_RESPONSIBILTY_TYPE,
  },
  {
    Id: EDUCATION_QUALIFICATION_ID,
    Name: 'Education and Qualifications',
    Type: EDUCATION_QUALIFICATION_TYPE,
  },
  {
    Id: KNOWLEDGE_SKILL_ABILITY_ID,
    Name: 'Knowledge, Skills and Abilities (KSA)',
    Type: KNOWLEDGE_SKILL_ABILITY_TYPE,
  },
  {
    Id: SCOPE_IMPACT_ID,
    Name: 'Scope and Impact',
    Type: SCOPE_IMPACT_TYPE,
  },
];

export const WHOLE_DAY_LEAVE_PERIOD_VALUE = null;
export const FIRST_HALF_LEAVE_PERIOD_VALUE = 'First Half';
export const SECOND_HALF_LEAVE_PERIOD_VALUE = 'Second Half';

export const LEAVE_PERIOD_LIST = [
  { label: 'Whole Day', value: WHOLE_DAY_LEAVE_PERIOD_VALUE },
  { label: 'First Half', value: FIRST_HALF_LEAVE_PERIOD_VALUE },
  { label: 'Second Half', value: SECOND_HALF_LEAVE_PERIOD_VALUE },
];

export const EMPLOYEE_REQUEST_CHIPS_FILTER_LIST = [
  { label: 'Everything', value: 1 },
  { label: 'Leave', value: 2 },
  { label: 'Allowance', value: 3 },
  { label: 'Overtime', value: 4 },
  { label: 'Time', value: 5 },
];

export const EMPLOYEE_REQUEST_CHIPS_EVERYTHING_VALUE = 1;

export const roles = () => {
  let a = [1];
  const { REACT_APP_ADMIN_ROLES } = process.env;
  if (REACT_APP_ADMIN_ROLES) {
    a = REACT_APP_ADMIN_ROLES.split(',').map(item => parseInt(item));
  }
  return a;
};

export const EMPLOYEE_LEAVE_REQUEST_MODULE_VALUE = 6;
export const EMPLOYEE_TIME_REQUEST_MODULE_VALUE = 206;
export const EMPLOYEE_OVERTIME_REQUEST_MODULE_VALUE = 207;
export const EMPLOYEE_ALLOWANCE_REQUEST_MODULE_VALUE = 208;
export const nodeEnv = process.env.NODE_ENV;

export const goToOldEmployeeDashabord = baseUrl =>
  window.location.replace(baseUrl + 'Employee/Dashboard.aspx');

export const goToOldAdminDashabord = baseUrl =>
  window.location.replace(baseUrl + 'newhr/DashboardHR.aspx');

export const AuthToken = 'AuthToken';
export const RefreshToken = 'RefreshToken';
export const IdleTimeOut = 'IdleTimeOut';
export const ExpiresMinutes = 'ExpiresMinutes';
export const Expires = 'Expires';
export const UserDetail = 'UserDetail';
