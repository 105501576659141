// @ts-nocheck 
import React from 'react'
import { LoginRequestType } from 'constants/loginRequestType'
import classnames from 'classnames'
import google from 'assets/images/google.svg'
import microsoft from 'assets/images/microsoft.svg'

function ExternalLoginButtons({
  oauthEnabled,
  loginRequestType,
  getSamlRequest,
  updateState,
  loginViaVianet,
  loginViaVianetLoading,
  loginLoading,
}) {
  const loginViaVianetButtonClass = classnames('login__via-vianet flex', {
    'login-loading': loginViaVianetLoading,
  })

  const loginViaMicrosoftButtonClass = classnames('login__via-microsoft flex', {
    'login-loading': loginViaVianetLoading,
  })
  const handleLoginViaVianet = () => {
    updateState({ errors: {} })
    loginViaVianet()
  }

  const handleSamlLogin = async (requestType) => {
    updateState({ errors: {} })

    let ssoRequestURL = await getSamlRequest(requestType)
    if (ssoRequestURL) window.location.href = ssoRequestURL
  }

  if (!oauthEnabled) return null

  return (
    <>
      {loginRequestType === LoginRequestType.ViaNet && (
        <button
          className={loginViaVianetButtonClass}
          disabled={loginViaVianetLoading}
          onClick={handleLoginViaVianet}
        >
          {loginViaVianetLoading ? (
            <>
              <span className="button-spin"></span>
              <span>Logging In...</span>
            </>
          ) : (
            'Log In with Vianet'
          )}
        </button>
      )}

      {loginRequestType == LoginRequestType.GoogleSSO && (
        <>
          <button
            type="button"
            className={loginViaVianetButtonClass}
            disabled={loginViaVianetLoading}
            onClick={() => handleSamlLogin(loginRequestType)}
          >
            {loginLoading ? (
              <>
                <span className="button-spin"></span>
                <span>Logging In...</span>
              </>
            ) : (
              <div className="admin-info-btn">
                <img src={google} alt="Log In with Google" />
                Log In with Google
              </div>
            )}
          </button>
        </>
      )}

      {loginRequestType == LoginRequestType.Microsoft && (
        <>
          <button
            type="button"
            className={loginViaMicrosoftButtonClass}
            disabled={loginViaVianetLoading}
            onClick={() => handleSamlLogin(loginRequestType)}
          >
            {loginLoading ? (
              <>
                <span className="button-spin"></span>
                <span>Logging In...</span>
              </>
            ) : (
              <div className="admin-info-btn">
                <img src={microsoft} alt="Log In with Microsoft" />
                Log In with Microsoft
              </div>
            )}
          </button>
        </>
      )}
    </>
  )
}

export default ExternalLoginButtons
