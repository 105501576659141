// @ts-nocheck 
import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FaRegFilePdf } from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";
import { singleDocsInterface } from "ts/interfaces/formInterface";

function SingleDocumentInput(props: singleDocsInterface) {
  const { name, value, btnText, extraClassName } = props;

  const fileClasses = (value) => {
    return classnames({
      badges: value,
      inputWidth: "calc(100% - 225px)",
      "left-icon": value,
      "badges-file": value,
    });
  };
  const file = value;

  const fileSelectedHandler = (event) => {
    const newFile = event.target.files[0];
    props.onChange(name, newFile);
  };

  const fileRemoveHandler = () => {
    props.onChange(name, null);
  };

  return (
    <div className="upload-btn-wrapper" style={{ marginRight: "20px" }}>
      {file && file.name ? (
        <div
          className={fileClasses(true)}
          // onClick={()=>this.onModalOpen(file,true)}
        >
          <span className="document-icon">
            <FaRegFilePdf />
          </span>
          <label className="document-name">
            {file.name}
            {/* {file.name.split(".")[0] + "." + file.name.split(".")[1] } */}
          </label>
          <span
            className="document-icon cancel-icon"
            onClick={fileRemoveHandler}
          >
            <AiFillCloseCircle />
          </span>
        </div>
      ) : (
        <button className={"upload-btn " + extraClassName}>
          {btnText}
          <input type="file" name="file" onChange={fileSelectedHandler} />
        </button>
      )}
    </div>
  );
}

SingleDocumentInput.defaultProps = {
  name: "",
  btnText: "Add document",
  extraClassName: "",
};

export default SingleDocumentInput;
