import React from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "./privateRoute";

export function RouteNest(props) {
  const { isNotPrivate } = props;
  return (
    <PrivateRoute
      exact={props.exact}
      path={props.path}
      children={props.children}
      component={props.component}
      // childRef={props.forwardRef}
      isNotPrivate={props.isNotPrivate}
      {...props}
    />
    // <Route exact={props.exact} path={props.path} render={ p => <props.component {...p} children={props.children}/> } />
  );
}
