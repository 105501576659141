import * as fromInterface from "../../../ts";
import { RolloutManagerDropdownState } from "./slice";

const selectRolloutManagerDropdownSlice = (state: any): RolloutManagerDropdownState => {
    return state.dataStore.rolloutManagerDropdown;
};

export const selectRolloutManagerDropdownStatus = (state: any): fromInterface.loadingType => {
    return selectRolloutManagerDropdownSlice(state).status;
};

export const selectRolloutManagerDropdownLoaded = (state: any): boolean => {
    return selectRolloutManagerDropdownSlice(state).loaded;
};

export const selectRolloutManagerDropdownItems = (state: any) => {
    return selectRolloutManagerDropdownSlice(state).items;
};
