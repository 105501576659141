enum AprPMSConfigType {
  EmployeeConsent = 1,
  EmployeeSignature = 2,
  PeerSignature = 3,
  ManagerSignature = 4,
  EmailTemplateForInvitationToCompleteReview = 5,
  EmailTemplateForInvitationToNominateParticipation = 6,
  EmailTemplateForInvitationForPeersToProvideFeedback = 8,
  EmailTemplateForManagerAfterSelfReviewCompletion = 7,
  EmailTemplateForManagerToCompleteReview = 9,
  EmailTemplateForEmployeeToCompleteConsent = 10,
  EmailTemplateToInviteAdditionalEvaluators = 11,
}

export default AprPMSConfigType
