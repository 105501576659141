

import * as fromSlice from "./slice";

const selectPerformanceRolloutAppraisalFilterSlice = (state: any): fromSlice.PeformanceRolloutAppraisalFilterState =>
    state.dataStore.performanceRolloutAppraisalFilter;


export const selectPerformanceRolloutAppraisalFilter = (state: any): fromSlice.PeformanceRolloutAppraisalFilterState =>
    selectPerformanceRolloutAppraisalFilterSlice(state);

export const selectPerformanceRolloutReviewCycleAppraisalFilter = (state: any): any =>
    selectPerformanceRolloutAppraisalFilterSlice(state).reviewCycleId;


