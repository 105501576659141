// @ts-nocheck 
import React, {useCallback, useEffect, useState} from "react";
import {Paper, RootRef} from "@material-ui/core";
import dataURLtoFile from "helpers/dataURLtoFile";
import isEmpty from "helpers/isEmpty";
import {useDropzone} from "react-dropzone";
import Icons from "components/icons";
import CropModal from "components/cropModal";
import Button from "components/button";
import {validateImage} from "components/uploadProfile/validateImage";
import ImageCropModal from "components/imageCropModal";

export const UploadCoverPhoto = (props) => {
  const {onModalClose, isNotModal, previewImage, setState} = props;
  const [images, setImages] = useState([]);
  const [src, setSrc] = useState("");
  const [cropImages, setCropImages] = useState("");
  const [errors, setErrors]: any = useState({});
  const [open, setOpen] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    const {errs, isValid} = validateImage(acceptedFiles);
    setErrors(errs);
    if (!isValid) {
      return false;
    }
    setImages(acceptedFiles);
  }, []);

  useEffect(() => {
    if (images.length > 0) {
      setSrc(URL.createObjectURL(images[0]));
    }
  }, [images]);

  const {getRootProps, getInputProps} = useDropzone({onDrop});
  const {ref, ...rootProps} = getRootProps();

  const getFileImage = async () => {
    const cropedPhotoName = "croped_" + images[0].name;
    const base64_photo = cropImages;
    const file_photo = await dataURLtoFile(base64_photo, cropedPhotoName);
    return file_photo;
  };

  const onValidate = () => {
    let errs: any = {};
    if (isEmpty(images)) {
      errs.images = "Browse an image before uploading";
    }
    if (isEmpty(cropImages)) {
      errs.cropped = "Image needed to be cropped before uploading";
    }
    return {errs, isValid: isEmpty(errs)};
  };

  const onSave = async () => {
    const {errs, isValid} = onValidate();
    setErrors(errs);
    if (!isValid) {
      return false;
    }
    const cropFile = await getFileImage();

    setState((prev) => {
      return {
        ...prev,
        CoverPicture: [...prev.CoverPicture, cropFile],
      };
    });
    onModalClose();
  };

  return (
    <div className="profile-change">
      <div className="profile-change__container">
        <div className="profile-change__input">
          <RootRef rootRef={ref}>
            <Paper {...rootProps}>
              <input {...getInputProps()} />
              {!isEmpty(previewImage) && isEmpty(images) ? (
                <div className="profile-change__file">
                  <div style={{display: "block"}} className="profile-change__images">
                    <div className="profile-change__images-block">
                      <div className="profile-change__image">
                        <img src={previewImage} alt="Profile Image" />
                      </div>
                      <h5>Profile Image</h5>
                    </div>
                  </div>
                  <p>
                    <div className="text-blue">Replace photo</div>
                    <div className="">or</div>
                    <span>Drag and drop here</span>
                    {!isEmpty(errors.images) ? (
                      <div className="profile-change__error">{errors.images}</div>
                    ) : null}
                  </p>
                </div>
              ) : !isEmpty(images) ? (
                <div className="profile-change__file">
                  {/* <div className="profile-change__images-block">
                      <div className="profile-change__image">
                        <img src={src} alt="" />
                        <div
                          onClick={() => setImages([])}
                          className="profile-change__image-remove"
                        >
                          <Icons name="Close" justSVG />
                        </div>
                      </div>
                      <h5>Original Image</h5>
                    </div> */}
                  <div className="profile-change__images-block">
                    <div className="profile-change__image">
                      <img src={cropImages} alt="" />
                      <div
                        onClick={() => setCropImages(null)}
                        className="profile-change__image-remove"
                      >
                        <Icons name="Close" justSVG />
                      </div>
                      {errors.cropped ? (
                        <div className="profile-change__image-error">
                          <span>{errors.cropped}</span>
                        </div>
                      ) : null}
                    </div>
                    <h5>Cropped Image</h5>
                  </div>
                  <p>
                    <div className="text-blue">Replace photo</div>
                    <div className="">or</div>
                    <span>Drag and drop here</span>
                  </p>
                </div>
              ) : (
                <p>
                  <div className="text-blue">Browse a file</div>
                  <div className="">or</div>
                  <span>Drag and drop here</span>
                  {!isEmpty(errors.images) ? (
                    <div className="profile-change__error">{errors.images}</div>
                  ) : null}
                </p>
              )}
            </Paper>
          </RootRef>
        </div>
      </div>

      {/* <CropModal
        cropImages={cropImages}
        setCropImage={setCropImages}
        setImages={setImages}
        open={open}
        setOpen={setOpen}
        src={src}
      /> */}
      <ImageCropModal
        cropImages={cropImages}
        src={src}
        open={open}
        setOpen={setOpen}
        setCropImage={setCropImages}
      />

      <div className="profile-change__footer">
        {!isEmpty(images) ? (
          <>
            <Button onClick={() => onSave()} bg="primary" title="Save" />
            {/* <Button onClick={() => setOpen(true)} bg="green" title="Crop Image" /> */}
          </>
        ) : null}
        {isNotModal ? null : (
          <Button onClick={() => onModalClose()} bg="secondary-dark" title="Cancel" />
        )}
      </div>
    </div>
  );
};
