import { selectReview } from ".";

export const selectBlockDetail = (state: any, block: any): any => {
    const reviewDetail = selectReview(state);
    const { Sections, ScoreSummary } = reviewDetail

    if (block.isBlock) {
        return Sections?.[block.index]
    }

    if (block.isSummary) {
        return ScoreSummary?.Scores
    }

    return { message: null }

};