// @ts-nocheck 
import isEmpty from "helpers/isEmpty";
import tableView from "../board/taskBody/tableView";
import {TMStage} from "./enum";
import {
  ADD_BOARD,
  DELETE_BOARD,
  GET_BOARDS,
  GET_BOARD_LABEL,
  Save_BOARD_LABEL,
  DELETE_BOARD_LABEL,
  GET_BOARD_VIEW,
  GET_CARD_VIEW,
  GET_TABLE_VIEW,
  GET_TABLE_VIEW_LOADING,
  UPDATE_TABLE_VIEW,
  RESET_TABLE_VIEW,
  DELETE_TASKS,
  GET_TASK_DETAILS,
  ADD_TASK_LABEL,
  ADD_TASK_DETAILS,
  ADD_TASK_DETAILS_LOADING,
  ADD_TASK,
  GET_BOARD_MEMBERS,
  SAVE_BOARD_MEMBERS,
  GET_COMMENTS_BY_TASK_ID,
  GET_COMMENTS_BY_TASK_ID_LOADING,
  COMMENTS_SCROLL,
  GET_BOARD_ACTIVITY,
  GET_BOARD_DETAILS,
  GET_BOARD_DETAILS_EXCEL,
  SET_BOARD_ACTIVITY_AS_VIEWED,
  SAVE_SELECTED_CUSTOM_FIELDS,
  ARCHIVE_BOARD,
} from "./types";
import {
  updateBoards,
  deleteBoard,
  archiveBoard,
  swithcBoard,
  removeBoardLabel,
  updateBoardLabel,
  updateCheckList,
} from "./services";

const initialState: any = {
  board: {},
  boardLabels: [],
  boardActivityLog: {
    Page: 0,
    TotalRows: 0,
    TotalNewActivity: 0,
    Data: [],
    Records: [],
    HasMore: false,
  },
  boards: [],
  boardDetails: [],
  boardDetailsExcel: [],
  tableViewLoading: false,
  tableView: {},
  boardView: {},
  task: {},
  taskLoading: false,
  boardMembers: [],
  cardView: [],
  loading: false,
  taskComments: [],
  taskCommentsLoading: false,
  commentScroll: false,
  selectedCustomFields: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DELETE_BOARD: {
      const data = action.payload;
      return {
        ...state,
        board: {},
        boards: deleteBoard(state, data),
        //  boards: [action.payload, ...state.boards],
      };
    }
    case ARCHIVE_BOARD: {
      const data = action.payload;
      return {
        ...state,
        boards: archiveBoard(state, data),
        board: {...state.board, IsArchived: data.IsArchived},
      };
    }
    case ADD_BOARD:
      const data = action.payload;
      return {
        ...state,
        board: action.payload,
        boards: updateBoards(state, data),
        //  boards: [action.payload, ...state.boards],
      };
    case GET_BOARDS:
      return {
        ...state,
        boards: action.payload,
      };

    case GET_BOARD_DETAILS:
      return {
        ...state,
        boardDetails: action.payload,
      };
    case GET_BOARD_DETAILS_EXCEL:
      return {
        ...state,
        boardDetailsExcel: action.payload,
      };

    case DELETE_BOARD_LABEL: {
      const {payload} = action;
      let boardLabels = [...state.boardLabels];
      const index = boardLabels.findIndex((x) => x.Id === payload.id);
      let ThingsToDo = removeBoardLabel(state.tableView.ThingsToDo, payload.id);
      let OnHold = removeBoardLabel(state.tableView.OnHold, payload.id);
      let InProgress = removeBoardLabel(state.tableView.InProgress, payload.id);
      let Completed = removeBoardLabel(state.tableView.Completed, payload.id);
      let Cancelled = removeBoardLabel(state.tableView.Cancelled, payload.id);
      if (index > -1) {
        boardLabels.splice(index, 1);
      }

      return {
        ...state,
        boardLabels,
        tableView: {
          ...state.tableView,
          ThingsToDo,
          OnHold,
          InProgress,
          Completed,
          Cancelled,
        },
      };
    }

    case Save_BOARD_LABEL: {
      const {payload} = action;
      let boardLabels = [...state.boardLabels];
      const index = boardLabels.findIndex(
        (x) => x.BoardId === payload.BoardId && x.Id === payload.Id
      );
      if (index > -1) boardLabels[index] = payload;
      else boardLabels.push(payload);

      let ThingsToDo = updateBoardLabel(state.tableView.ThingsToDo, payload);
      let OnHold = updateBoardLabel(state.tableView.OnHold, payload);
      let InProgress = updateBoardLabel(state.tableView.InProgress, payload);
      let Completed = updateBoardLabel(state.tableView.Completed, payload);
      let Cancelled = updateBoardLabel(state.tableView.Cancelled, payload);

      return {
        ...state,
        boardLabels,
        tableView: {
          ...state.tableView,
          ThingsToDo,
          OnHold,
          InProgress,
          Completed,
          Cancelled,
        },
      };
      // return { ...state }; //, boardLabels: action.payload };
    }
    case GET_BOARD_LABEL: {
      return {
        ...state,
        boardLabels: action.payload,
      };
    }
    case GET_BOARD_MEMBERS: {
      return {
        ...state,
        boardMembers: action.payload,
      };
    }
    case SAVE_BOARD_MEMBERS: {
      let board = {...state.board};
      board.BoardType = action.payload.length > 0 ? 2 : 1;

      return {
        ...state,
        board: board,
        boards: swithcBoard(state, board),
        boardMembers: action.payload,
      };
    }
    case GET_CARD_VIEW:
      return {
        ...state,
        cardView: action.payload,
      };
    case GET_TABLE_VIEW_LOADING:
      return {
        ...state,
        tableViewLoading: action.payload,
      };
    case GET_TABLE_VIEW: {
      return {
        ...state,
        tableViewLoading: false,
        board: action.payload && action.payload.Board,
        tableView: action.payload,
        boardActivityLog: initialState.boardActivityLog,
      };
    }
    case RESET_TABLE_VIEW: {
      return {
        ...state,
        tableView: {
          ThingsToDo: [],
          OnHold: [],
          InProgress: [],
          Completed: [],
          Cancelled: [],
        },
      };
    }

    case UPDATE_TABLE_VIEW:
      const {rowIndex, stage, task} = action.payload;
      let ThingsToDo = [...state.tableView.ThingsToDo];
      let OnHold = [...state.tableView.OnHold];
      let InProgress = [...state.tableView.InProgress];
      let Completed = [...state.tableView.Completed];
      let Cancelled = [...state.tableView.Cancelled];

      if (stage === TMStage.ThingsToDo) {
        ThingsToDo[rowIndex] = task;
      } else if (stage === TMStage.OnHold) {
        OnHold[rowIndex] = task;
      } else if (stage === TMStage.InProgress) {
        InProgress[rowIndex] = task;
      } else if (stage === TMStage.Completed) {
        Completed[rowIndex] = task;
      } else if (stage === TMStage.Cancelled) {
        Cancelled[rowIndex] = task;
      }
      return {
        ...state,
        tableView: {
          ...state.tableView,
          ThingsToDo,
          OnHold,
          InProgress,
          Completed,
          Cancelled,
        },
      };

    //
    case GET_BOARD_VIEW:
      return {
        ...state,
        board: action.payload.Board,
        boardView: action.payload,
        boardActivityLog: initialState.boardActivityLog,
      };

    case ADD_TASK:
      let thingsToDo = [];
      let inProgress = [];

      thingsToDo = !isEmpty(state.tableView.ThingsToDo)
        ? [...state.tableView.ThingsToDo]
        : [];
      inProgress = !isEmpty(state.tableView.InProgress)
        ? [...state.tableView.InProgress]
        : [];

      const {StageId} = action.payload;
      if (StageId === TMStage.InProgress) {
        inProgress.push(action.payload);
      } else if (StageId === TMStage.ThingsToDo) {
        thingsToDo.push(action.payload);
      }

      return {
        ...state,
        tableView: {
          ...state.tableView,
          ThingsToDo: thingsToDo,
          InProgress: inProgress,
        },
      };

    case GET_TASK_DETAILS:
      return {
        ...state,
        task: action.payload,
      };
    case ADD_TASK_DETAILS_LOADING:
      return {
        ...state,
        taskLoading: action.payload,
      };

    case ADD_TASK_DETAILS:
      return {
        ...state,
        taskLoading: false,
        task: action.payload,
      };

    case DELETE_TASKS:
      return {
        ...state,
      };
    case GET_COMMENTS_BY_TASK_ID: {
      return {
        ...state,
        taskComments: action.payload,
        taskCommentsLoading: true,
      };
    }
    case GET_COMMENTS_BY_TASK_ID_LOADING: {
      return {
        ...state,
        taskCommentsLoading: action.payload,
      };
    }
    case COMMENTS_SCROLL: {
      return {
        ...state,
        commentScroll: action.payload,
      };
    }
    case GET_BOARD_ACTIVITY: {
      if (action.payload.length === 0) {
        return {
          ...state,
        };
      }
      let firstEl = action.payload[0];
      let oldData = state.boardActivityLog.Data || [];

      let newData = oldData.concat(action.payload);
      var grouped = newData.reduce(function (rv, x) {
        (rv[x["CreatedOnText"]] = rv[x["CreatedOnText"]] || []).push(x);
        return rv;
      }, {});

      let boardActivityLog = {
        ...state.boardActivityLog,
        Data: newData,
        TotalRows: firstEl.TotalRows,
        TotalNewActivity: firstEl.NewActivities,
        Records: grouped,
        HasMore: newData.length < firstEl.TotalRows,
        Page: state.boardActivityLog.Page + 1,
      };
      return {
        ...state,
        boardActivityLog: boardActivityLog,
      };
    }
    case SET_BOARD_ACTIVITY_AS_VIEWED: {
      return {
        ...state,
        boardActivityLog: {...state.boardActivityLog, TotalNewActivity: 0},
      };
    }
    case SAVE_SELECTED_CUSTOM_FIELDS: {
      let selectedCustomFields = {...state.selectedCustomFields};

      const {isSelected, stageId, colName, boardId} = action.payload;
      let key = `${boardId}-${stageId}-${colName}`;
      if (isEmpty(selectedCustomFields[state])) {
        selectedCustomFields[key] = stageId;
      }
      if (isSelected) {
        delete selectedCustomFields[key];
      }
      return {
        ...state,
        selectedCustomFields: selectedCustomFields,
      };
    }
    default:
      return state;
  }
}
