import React from 'react'
import EmpHeader from "scenes/common/header";
import Footer from "components/layout/footer";

function EmployeeLayout(props) {
  return (
    <div className="layout layout-sidebar">
        <div
          className={'layout-body layout-employee'}
          style={{ backgroundColor: '#eff3f9' }}
        >
          <EmpHeader />
          <div className="emp-dashboard">{props.children}</div>
        </div>
        <Footer />
    </div>
  )
}

export default EmployeeLayout