export const ne = {
  name: [
    "बैशाख",
    "जेष्ठ",
    "आषाढ",
    "श्रावण",
    "भाद्र",
    "आश्विन",
    "कार्तिक",
    "मंसिर",
    "पौष",
    "माघ",
    "फाल्गुन",
    "चैत्र",
  ],
  shortName: ["बै", "जे", "आषा", "श्रा", "भा", "आश", "का", "मं", "पौ", "मा", "फा", "चै"],
};

export const en = {
  name: [
    "Baisakh",
    "Jestha",
    "Ashadh",
    "Shrawan",
    "Bhadra",
    "Ashwin",
    "Kartik",
    "Mangsir",
    "Paush",
    "Mangh",
    "Falgun",
    "Chaitra",
  ],
  shortName: [
    "Bai",
    "Jes",
    "Ash",
    "Shra",
    "Bha",
    "Ash",
    "Kar",
    "Mang",
    "Pau",
    "Ma",
    "Fal",
    "Chai",
  ],
};
