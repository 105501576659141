export const RightType = {
  moduleRight: 1,
  admin: 2,
  viewOnly: 3,
};

export const permissions = {
  customRole: "Custom Role",

  skippedPermission: "Skipped Permission",
  //#region standard
  standardPermission: {
    //#region humanResources
    hrManagement: "HR.HrManagement",
    employeeOnBoarding: "HR.EmployeeOnboarding",
    hrCommunication: "HR.Communication",
    jobProfileAndJD: "HR.JobProfileAndJD",
    employeePositionChange: "HR.PositionChange",
    employeeTransfer: "HR.EmployeeTransfer",
    hrFunctionsWithPayReference: "HR.FunctionsWithPayReference",
    employeeSeparation: "HR.EmployeeSeparation",
    //#endregion

    //#region payroll
    payrollManagement: "Payroll.PayrollManagement",
    payrollConfiguration: "Payroll.PayrollConfiguration",
    //#endregion

    //#region leaveTime
    leaveManagement: "Leave.LeaveManagement",
    leaveConfiguration: "Leave.LeaveConfiguration",
    timeManagement: "Time.Management",
    timeConfiguration: "Time.Configuration",
    //#endregion

    //#region travel
    travelManagement: "Travel.Management",
    travelSettlement: "Travel.Settlement",
    //#endregion

    trainingManagement: "Training.Management",
    recruitmentManagement: "Recruitment.Management",
    learningManagement: "Learning.Management",
    taskManagement: "Task.Management",
    performanceManagement: "Performance.Management",
    shiftScheduleManagement: "ShiftSchedule.Management",
    shiftScheduleDownloadReports: "ShiftSchedule.DownloadReports",
  },
  //#endregion

  //#region viewOnly
  viewOnlyPermission: {
    downloadReports: "ViewOnlyUser.DownloadReports",
    leave: "Leave",
    hrDisplayName: "HR",
    humanResources: "Human Resources",
    payroll: "Payroll",
    training: "Training",
    time: "Time",
    travel: "Travel",
    learning: "Learning",
    task: "Task",
    performance: "Performance",
    recruitment: "Recruitment",
    shiftSchedule: "Shift_Schedule",
    shiftScheduleDisplayName: "Shift Schedule",
  },
  //#endregion

  //#region configuration(admin)
  configurationPermission: {
    //#region administrativeRight
    administration: "Administration",
    organizationAndStructure: "Administration.OrganizationAndStructure",
    hrSettings: "Administration.HrSettings",
    payrollSettings: "Administration.PayrollSettings",
    leaveSettings: "Administration.LeaveSettings",
    timeSettings: "Administration.TimeSettings",
    userRoles: "Administration.UserAndRoles",
    retirementFundSettings: "Administration.RetirementFundSettings",
    projectConfiguration: "Administration.ProjectConfiguration",
    apiAndConnectivity: "Administration.ApiAndConnectivity",
    systemAndPermformance: "Administration.SystemAndPermformance",
    //#endregion
  },
  //#endregion
};
