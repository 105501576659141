import { ApiClient } from "../../../network/apiClient";
import { REVIEW_ACTIVITY_ACTIVE_LIST, REVIEW_ACTIVITY_HISTORY_LIST, NOMINATE_PARTICIPANT, GOAL_DATABASE } from "./api"
import * as fromSlice from "./slice"


export const fetchActiveReviewActivities = (): any => async (dispatch: any) => {
    dispatch(fromSlice.fetchActiveActivitiesRequest())
    const response: any = await ApiClient.get<any>({
        endpoint: REVIEW_ACTIVITY_ACTIVE_LIST,
        dispatch: dispatch,
    })

    dispatch(fromSlice.fetchActiveActivitiesSuccess(response?.Data ?? []))

    return response?.Data ?? []
}

export const fetchHistoryReviewActivities = (): any => async (dispatch: any) => {
    dispatch(fromSlice.fetchHistoryActivitiesRequest())
    const response: any = await ApiClient.get<any>({
        endpoint: REVIEW_ACTIVITY_HISTORY_LIST,
        dispatch: dispatch,
    })
    dispatch(fromSlice.fetchHistoryActivitiesSuccess(response?.Data ?? []))

    return response?.Data ?? []
}


export const fetchRolloutNominationInfo = (id: number): any => async (dispatch: any) => {

    const response: any = await ApiClient.get<any>({
        endpoint: `${NOMINATE_PARTICIPANT}/${id}`,
        dispatch: dispatch,
        showLoader: true
    })


    return response;
}


export const saveRolloutNominationInfo = (param: any): any => async (dispatch: any) => {

    const response: any = await ApiClient.put<any>({
        endpoint: NOMINATE_PARTICIPANT,
        body: param,
        dispatch: dispatch,
    })

    if (response?.Status) {
        dispatch(fetchActiveReviewActivities())
        dispatch(fetchHistoryReviewActivities())
    }
    return response?.Status;
}

export const fetchEmployeeGoalDatabase = (): any => async (dispatch: any) => {

    const response: any = await ApiClient.get<any>({
        endpoint: GOAL_DATABASE,
        dispatch: dispatch,
    })



    return response?.Data ?? []
}

