// @ts-nocheck 
import classnames from "classnames";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getDashboadCourseCount} from "scenes/lms/helpers/action";
import {CourseDashboardStatus} from "scenes/lms/helpers/enums";
const initialTabState = [
  {name: "Assigned", num: 0, status: CourseDashboardStatus.Assign, key: "TotalAssigned"},
  {
    name: "In Progress",
    num: 0,
    status: CourseDashboardStatus.InProgress,
    key: "TotalProgress",
  },
  {
    name: "Deadlines",
    num: 0,
    status: CourseDashboardStatus.Deadline,
    key: "TotalNearDeadlines",
  },
  {name: "All Courses", num: null, status: CourseDashboardStatus.All, key: ""},
  {
    name: "Completed",
    num: 0,
    status: CourseDashboardStatus.Completed,
    key: "TotalCompleted",
  },
];
const CourseStatusTabBar = ({status, onStatusChange}) => {
  const dispatch = useDispatch();
  const {courseDashboardCount} = useSelector((state: any) => state.lmsReducer);
  const [tabs, setTabs] = useState(initialTabState);
  useEffect(() => {
    dispatch(getDashboadCourseCount());
  }, []);
  useEffect(() => {
    if (courseDashboardCount) {
      setTabs(tabs.map((item) => ({...item, num: courseDashboardCount[item.key]})));
    }
  }, [courseDashboardCount]);

  const handleClick = (item) => onStatusChange(item.status);
  return (
    <>
      <div className="courses-status-header header-course">Courses</div>
      <div className="courses-status-tab">
        <div className="courses-status__list">
          {tabs &&
            tabs.map((item, index) => {
              return (
                <div
                  onClick={() => handleClick(item)}
                  key={item.status}
                  className={classnames("courses-status__item", {
                    "courses-active": status === item.status,
                  })}
                >
                  <div className="courses-status__num">
                    {item.status === CourseDashboardStatus.All ? "" : item.num ?? 0}
                  </div>
                  <div className="courses-status__label">{item.name}</div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default CourseStatusTabBar;
