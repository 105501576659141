// @ts-nocheck 
import {
  GET_FEATURE_FLAG,
  GET_ACTIVE_FEATURE_FLAG,
  GET_ACTIVE_FEATURES_CATEGORIES,
} from "actions/types";
import isEmpty from "helpers/isEmpty";
import { Action, Reducer } from "redux";

export interface InitialState {
  features:object[],
  activeFeaturesCategories: object[];
  featureFlags: object[];
  activeFeatureFlags: object[];
}

export const initialState: InitialState = {
  features:[],
  activeFeaturesCategories: [],
  featureFlags: [],
  activeFeatureFlags: [],
};

interface PermissionAction {
  type: string;
  payload: any;
}

export const reducer: Reducer<InitialState> = (
  state: InitialState | undefined,
  incomingAction: Action
): InitialState => {
  if (state === undefined) {
    return initialState;
  }
  const action = incomingAction as PermissionAction;
  switch (action.type) {
    
    case GET_ACTIVE_FEATURES_CATEGORIES:
      let features=[];
      action.payload.map((x) => {
        let {FeatureCategories} = x;
        let LinkCategories = [];

        FeatureCategories.map((x) => {
          for (let i = 0; i < x.LinkCategories.length; i++) {
            LinkCategories.push(x.LinkCategories[i]);
          }
        });
        LinkCategories.map((x) => {
          for (let i = 0; i < x.Links.length; i++) {
            features.push(x.Links[i]);
          }
        });
      });
  
      return {
        ...state,
        features:features,
        activeFeaturesCategories: action.payload,
      };

    case GET_FEATURE_FLAG:
      return {
        ...state,
        featureFlags: action.payload,
      };
    case GET_ACTIVE_FEATURE_FLAG:
      return {
        ...state,
        activeFeatureFlags: action.payload,
      };
    default:
      return state;
  }
};
