// @ts-nocheck 
export enum TmTaskPriorityEnum {
  High = 1,
  Medium = 2,
  Low = 3,
  None = 0,
}

export enum TMStage {
  ThingsToDo = 1,
  InProgress = 2,
  Completed = 3,
  OnHold = 4,
  Cancelled = 5,
}
export enum TmViewType {
  TableView = "table",
  BoardView = "board",
}
