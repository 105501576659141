
import { ApiClient } from "../../../../network/apiClient";
import * as fromInterface from "../../../../ts";
import { fetchPerformanceReviewForSelf } from ".";
import { PERFORMANCE_REVIEW } from "../api";
import * as fromSlice from "../slice";
import * as fromPerformanceReviewCommentStore from "../../performance-review-comments"


export const fetchPerformanceReview = ({ reviewId, showLoader = false }: { reviewId: number, showLoader?: boolean }): any => async (dispatch: any) => {
    if (showLoader) {
        dispatch(fromSlice.fetchRequest())
    }

    const response: fromInterface.ResponseWrapper<fromInterface.PerformanceReview> = await ApiClient.get<any>({
        endpoint: PERFORMANCE_REVIEW.replace(":reviewId", reviewId + ""),
        dispatch: dispatch
    })

    const { Data, Status } = response

    if (Status) {
        dispatch(fromSlice.fetchSuccess(Data))

        let _reviewId = reviewId + ""

        if (Data?.ReviewInfo?.SelfReviewId) {
            _reviewId += `,${Data?.ReviewInfo?.SelfReviewId}`
            dispatch(fetchPerformanceReviewForSelf({ reviewId: Data?.ReviewInfo?.SelfReviewId }))
        }

        dispatch(fromPerformanceReviewCommentStore.PerformanceReviewCommentStore.fetchCommentsByReviewId({ reviewId: _reviewId }))
    } else {
        dispatch(fromSlice.fetchError())
    }
}
