import { ApiClient } from "../../../../network/apiClient";
import { SAVE_PERFORMANCE_FORM_EVALUATION } from "../api";

export const saveReviewEvaluation = ({
    payload,
    history,
    dashboardRoute
}: any): any => async (dispatch: any) => {
    console.log({
        payload
    })
    const response: any = await ApiClient.post<any>({
        endpoint: SAVE_PERFORMANCE_FORM_EVALUATION,
        dispatch: dispatch,
        body: payload
    });

    const { Status } = response;

    if (Status) {
        history.push(dashboardRoute)
    }

};
