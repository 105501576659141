// @ts-nocheck
import React, { useState, useEffect, useMemo } from "react";
import FormGroup from "components/form";
import isEmpty from "isEmpty";
import { isFeatureEnabledValue } from "helpers/checkPermissions";
import ModuleMain from "./moduleMain";
import { GetFavLink } from "src/store/actions/favLink";
import { connect } from "react-redux";

const LinkCategories = (props) => {
  const { baseUrl, title, categories, featureId, category, favLinks } = props;

  useEffect(() => {
    getFavLinks();
  }, [category, featureId]);

  const getFavLinks = async () => {
    if (featureId && category) {
      props.GetFavLink({ feature: featureId, category, dashboardType: false });
    }
  };
  const [search, setSearch] = useState("");
  const setFav = (fav) => {};

  const medianLengthOfCategories = useMemo(() => {
    if (categories) {
      return Math.ceil(categories.length / 2);
    }
    return 0;
  }, [categories]);

  const categoriesBeforeMedian = useMemo(() => {
    if (categories) {
      return new Array(medianLengthOfCategories)
        .fill(undefined)
        .map((ele, index) => categories[index]);
    }
    return [];
  }, [categories]);

  const categoriesAfterMedian = useMemo(() => {
    if (categories) {
      return new Array(categories.length - medianLengthOfCategories)
        .fill(undefined)
        .map((ele, index) => categories[categoriesBeforeMedian.length + index]);
    }
    return [];
  }, [categories]);

  return (
    <div className="admin admin-common wrap-container">
      <div className="admin-container">
        <div className="admin-title">
          <h1>{title}</h1>
        </div>
        <div className="admin-modules">
          <div className="admin-modules__container">
            <div className="admin-modules__block">
              <div className="admin-modules__search">
                <FormGroup
                  formName="searchInput"
                  name="search"
                  value={search}
                  onChange={(name, value) => setSearch(value)}
                  placeholder="Search.."
                />
              </div>

              <div className="admin-modules__contain">
                {favLinks && favLinks.length > 0 && (
                  <div className="admin-modules__list admin-modules__list-fav">
                    <ModuleMain
                      searchValue={search}
                      groupTitle={"Favorite"}
                      links={favLinks}
                      baseUrl={baseUrl}
                      isBaseUrl={!isEmpty(baseUrl)}
                    />
                  </div>
                )}
                <div className="modules-wrapper">
                  {categoriesBeforeMedian.length > 0 ? (
                    <div className="admin-modules__left">
                      <ul className="admin-modules__list">
                        <>
                          {categoriesBeforeMedian.map((item, index) => {
                            // isFeatureEnabledValue
                            const feat = item.feature ? item.feature : "CoreHR";
                            if (!isFeatureEnabledValue(feat)) {
                              return false;
                            }

                            return (
                              <ModuleMain
                                key={index}
                                searchValue={search}
                                groupTitle={item.LinkCategory}
                                links={item.Links}
                                baseUrl={baseUrl}
                                isBaseUrl={!isEmpty(baseUrl)}
                              />
                            );
                          })}
                        </>
                      </ul>
                    </div>
                  ) : null}

                  {categoriesAfterMedian.length > 0 ? (
                    <div className="admin-modules__right">
                      <ul className="admin-modules__list">
                        <>
                          {categoriesAfterMedian.map((item, index) => {
                            // isFeatureEnabledValue
                            const feat = item.feature ? item.feature : "CoreHR";
                            if (!isFeatureEnabledValue(feat)) {
                              return false;
                            }

                            return (
                              <ModuleMain
                                key={index}
                                searchValue={search}
                                groupTitle={item.LinkCategory}
                                links={item.Links}
                                baseUrl={baseUrl}
                                isBaseUrl={!isEmpty(baseUrl)}
                              />
                            );
                          })}
                        </>
                      </ul>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  favLinks: state.favLinkReducer.favLinks,
});
const mapDispatchToProps = {
  GetFavLink,
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkCategories);
