import { createSlice } from "@reduxjs/toolkit";
import * as fromModels from "@rigotech/hrms-model";


export interface PerformanceReviewCommentState {
  items: fromModels.IComments,
  status: fromModels.loadingType;
  loaded: boolean;
}

const initialState: PerformanceReviewCommentState = {
  items: [],
  status: fromModels.STATUS.LOADING,
  loaded: false,
};

const performanceReviewCommentSlice = createSlice({
  name: "performance review comments",
  initialState,
  reducers: {
    fetchRequest: (state) => {
      state.status = fromModels.STATUS.LOADING;
    },
    fetchSuccess: (state, action) => {
      const payload = action.payload;

      state.items = payload;
      state.status = fromModels.STATUS.IDLE;
      state.loaded = true;

    },
    clear: (state) => {
      state.items = [];
      state.status = fromModels.STATUS.IDLE;
      state.loaded = false;
    },
  },
});

export const {
  fetchRequest,
  fetchSuccess,
  clear,
} = performanceReviewCommentSlice.actions;

export default performanceReviewCommentSlice.reducer;