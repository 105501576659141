import { z } from "zod"
export const teamDirectoryDetailSchema = z.object({
    Id: z.number(),
    TDVisibilityTypeId: z.number(),
    TDVisibilityTypeName: z.string(),
    TeamContactVisibilityEnabled: z.boolean(),
    PersonalContactVisibilityEnabled: z.boolean(),
    AnyEmployeeHidden: z.boolean()
})

export type ITeamDirectoryDetail = z.infer<typeof teamDirectoryDetailSchema>;