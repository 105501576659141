import React, { useState,useEffect } from 'react'
import {Box} from '@chakra-ui/react';
import Stepper from './stepper';
import StepperContext,{StepperEnums,steps} from './context';
import Authentication from './views/authentication';
import SetupApp from './views/setupApp';
import EnterCode from './views/enterCode';
import Completed from './views/completed';
import {getTFAQRCode} from 'actions/authAction';
import { useDispatch, useSelector } from 'react-redux';
import { AuthState } from '@/reducers/authReducer';
import RouteEnum from 'constants/routeEnum';

const {Provider} = StepperContext;

function TwoFactorScreen(props) {
    const [activeStep,setActiveStep] = useState(StepperEnums.authentication);
    const auth:AuthState = useSelector((state:any)=>state.auth)
    const {tfaQrDetails,twoFactorUserDetails} = auth;
    const dispatch = useDispatch();
    const Username = twoFactorUserDetails && twoFactorUserDetails.Username
    useEffect(()=>{
        if(Username){
            // dispatch(getTFAQRCode(Username));
        }else{
            props.history.push(RouteEnum.LoginPath);
        }
    },[]);
    useEffect(()=>{
        if(tfaQrDetails){
            setActiveStep(tfaQrDetails?.AuthKeyUsedAleady ? StepperEnums.code : StepperEnums.authentication);
        }
    },[tfaQrDetails])
    const renderView = () =>{
        switch(activeStep){
            case StepperEnums.authentication:
                return <Authentication />
            case StepperEnums.setUp:
                return <SetupApp />
            case StepperEnums.code:
                return <EnterCode />
            case StepperEnums.completed:
                return <Completed />
            default:
                return <Authentication />
        }
    }
    return (
        <Provider value={{activeStep:activeStep,setActiveStepper:setActiveStep,steps}}>
            <Box padding={"32px 12px"}>
                {!tfaQrDetails?.AuthKeyUsedAleady ? <Stepper /> : null }
                <Box margin="24px auto" maxW="800px">
                    {renderView()}
                </Box>
            </Box>
        </Provider>
    )
}

export default TwoFactorScreen