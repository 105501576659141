// import { selectSlice } from ".";

// export const selectLoadedForSelf = (state: any): boolean => {
//     return selectSlice(state).selfLoaded;
// };

import { selectBaseSlice } from ".";

export const selectBaseSelfReviewLoaded = <T>(sliceName: string) => (state: any): boolean => {
    return selectBaseSlice<T>(sliceName)(state).selfLoaded;
};
