// @ts-nocheck
import React, { useState } from "react";
import Dropdown from "components/dropdown";
import RouteEnum from "constants/routeEnum";
import { useHistory } from "react-router-dom";
import { CourseStatus } from "scenes/lms/helpers/enums";

import AssignModal from "../components/modal";
import { useReset } from "../hooks/useReset";
import { useDelete } from "../hooks/useDelete";
import { useStatusDiscontinue } from "../hooks/useStatusDiscontinue";
import { useAssignDrop } from "../hooks/useAssignDrop";

const AssignedDrop = (props) => {
  const history = useHistory();
  const { Id } = props.row?.original;
  const { handleReset } = useReset(Id, props?.onStatusChange);
  const { handleStatusDiscontinue } = useStatusDiscontinue(
    Id,
    props?.onStatusChange
  );
  const { handleDelete } = useDelete(Id, props?.onStatusChange);

  const {
    confirm,
    onConfirmed,
    onModalClose,
    showConfirm,
    getTitle,
    getStatusMessage,
    isPrimarButtonType,
    showDiscontinueAction,
  } = useAssignDrop(props);

  return (
    <div className="course-drop">
      <Dropdown className="coursemgmt-popover" zIndex="1 !important">
        <div className="course-drop__list">
          {props.row?.original?.Status !== CourseStatus.Discontinue && (
            <div
              onClick={() =>
                history.push(
                  RouteEnum.lmsCourseAdd + "/" + props.row?.original?.Id
                )
              }
              className="course-drop__item"
            >
              <span>Edit</span>
            </div>
          )}
          {props.row?.original?.Status !== CourseStatus.Discontinue && (
            <div
              onClick={() =>
                history.push(
                  RouteEnum.lmsCourseSettings + "/" + props.row?.original?.Id
                )
              }
              className="course-drop__item"
            >
              <span>Course Options</span>
            </div>
          )}
          <div
            className="course-drop__item"
            onClick={() =>
              showConfirm(
                "Duplicate",
                "Are you sure you want to duplicate this course?",
                "duplicate",
                "Duplicate"
              )
            }
          >
            <span>Duplicate</span>
          </div>
          <div
            className="course-drop__item course-drop__item-red"
            onClick={handleReset}
          >
            <span>Reset Course</span>
          </div>
          <div
            onClick={() =>
              showConfirm(
                getTitle(),
                getStatusMessage(),
                "statusChange",
                getTitle()
              )
            }
            className="course-drop__item course-drop__item-blue"
          >
            <span>{getTitle()}</span>
          </div>
          {showDiscontinueAction() && (
            <div
              onClick={handleStatusDiscontinue}
              className="course-drop__item course-drop__item-red"
            >
              <span>Discontinue </span>
            </div>
          )}
          {props.row?.original?.Assigned === 0 && (
            <div
              onClick={handleDelete}
              className="course-drop__item course-drop__item-red"
            >
              <span>Delete </span>
            </div>
          )}
        </div>
      </Dropdown>
      <AssignModal
        confirm={confirm}
        isPrimarButtonType={isPrimarButtonType}
        onConfirmed={onConfirmed}
        onModalClose={onModalClose}
      />
    </div>
  );
};

export default AssignedDrop;
