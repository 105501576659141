enum travelRoutes {
    TravelPath = '/travel',
    MyTravelRequestPath = '/travel/my_travel_request',
    TeamTravelRequestPath = '/travel/travel_request',
    CreateTravelRequestPath = '/travel/create_travel_request',
    UpdateTravelRequestPath = '/travel/update_travel_request',
    SettlementRequestPath = '/travel/settlement_request',
    PendingSettlementPath = '/travel/pending_settlement',
    SettlementHistoryPath = '/travel/settlement_history',
    TravelHistoryPath = '/travel/travel_history',
    TravelAllowanceExpensePath = '/travel/allowance_expense',
    TravelCategoryPath = '/travel/travel_category',
    TravelAdvanceSettlementReport = '/hr/report/travelAdvanceSettlementReport',
    TeamTravelHistoryPath = '/travel/team_travel_history',
    TeamSettlementHistoryPath = '/travel/team_settlement_history',
}

export default travelRoutes  