import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { getCourseResultsByCourseId, handleChangeFilterValue } from "../helpers/action";

const Course = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 0;
  background-color: ${(props) => props.courseId === props.activeCourse && "#0068d4"};

`;

const Title = styled.h4`
  color: ${(props) => (props.courseId === props.activeCourse ? "#fff" : "#353f5a")};
  font-weight: ${(props) => (props.courseId === props.activeCourse ? "400" : "500")};

  font-size:14px;
`;

const Category = styled.p`
  color: ${(props) => (props.courseId === props.activeCourse ? "#fff" : "#405471")};
  opacity: ${(props) => (props.courseId === props.activeCourse ? "0.7" : "1")};
  font-weight: ${(props) => (props.courseId === props.activeCourse ? "300" : "400")};
  font-size:12px;
  margin-top:0px;
  
`;

const CourseList = ({ data }) => {
  const [activeCourse, setActiveCourse] = useState(null);

  const { filterValues } = useSelector((state) => state.lmsReducer);

  const dispatch = useDispatch();

  const handleCourseClick = (id) => {
    setActiveCourse(id);
    dispatch(
      getCourseResultsByCourseId({
        ...filterValues,
        courseId: id,
        branchId: filterValues?.branchId?.value ?? -1,
        departmentId: filterValues?.departmentId?.value ?? -1,
      })
    );
    dispatch(handleChangeFilterValue("courseId", id));
  };

  return (
    <div className="course-results__list-tab">
      {data.length > 0 &&
        data.map((course) => (
          <Course
            key={course.Id}
            onClick={() => handleCourseClick(course.Id)}
            courseId={course.Id}
            activeCourse={activeCourse}
          >
            <Title courseId={course.Id} activeCourse={activeCourse}>
              {course.Title}
            </Title>
            <Category courseId={course.Id} activeCourse={activeCourse}>
              {course.CategoryName}
            </Category>
          </Course>
        ))}
    </div>
  );
};

export default CourseList;
