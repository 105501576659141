import * as fromSlice from "./slice";
import * as fromAdditionalScoreStore from "../additional-score"
export const udpateAdditionalScoreFilter =(newFilters: any):any => (dispatch: any, getState: any) => {
    dispatch(fromSlice.udpateFilter(newFilters))
}

export const clearAdditionalScoreFilter =() => (dispatch: any, getState: any) => {
    dispatch(fromSlice.clear())
}

export const udpateAdditionalScoreTablePagination =(pageInfo: any) => (dispatch: any, getState: any) => {
    // OPTIMISTIC UI UPDATE
    dispatch(fromSlice.udpateFilter(pageInfo))
    dispatch(fromAdditionalScoreStore.fetchAdditionalScore())
}
