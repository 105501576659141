import * as fromSlice from "./slice"
import * as fromInterface from "../../../ts"

const selectCurrentParticipantWeightsSlice = (
  state: any,
): fromSlice.ParticipantWeightsState => {
  return state.dataStore.participantWeights
}

export const selectParticipantWeightItems = (state: any): any[] => {
  return selectCurrentParticipantWeightsSlice(state).items
}

export const selectParticipantWeightStandardReviewItems = (
  state: any,
): any[] => {
  return selectCurrentParticipantWeightsSlice(state).standardReviewItems
}

export const selectParticipantWeightThreeSixtyItems = (state: any): any[] => {
  return selectCurrentParticipantWeightsSlice(state).threeSixtyItems
}

export const selectParticipantWeightStatus = (
  state: any,
): fromInterface.loadingType => {
  return selectCurrentParticipantWeightsSlice(state).status
}
