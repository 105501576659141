import {
  GET_TIME_REQUEST_DETAIL,
  GET_TIME_REQUEST_APPROVAL_RECOMMENDED,
  CREATE_TIME_REQUEST_REQUEST,
  CREATE_TIME_REQUEST_SUCCESS,
  CREATE_TIME_REQUEST_FAILURE,
  UPDATE_TIME_REQUEST_REQUEST,
  UPDATE_TIME_REQUEST_SUCCESS,
  UPDATE_TIME_REQUEST_FAILURE,
  GET_SHIFT_NAMES_LIST,
} from "actions/types";
import { normalizeBy } from "helpers/normalizeData";

const initialState = {
  timeRequestDetail: null,
  timeRequestApprovalRecommended: null,
  normalizedApprovals: {
    byEmployeeId: {},
    allEmployeeIds: [],
  },
  normalizedRecommended: {
    byEmployeeId: {},
    allEmployeeIds: [],
  },
  requesting: false,
  timeRequestApprovalRecommendedLoading: false,
  shifts: [],
  timeRequestSucceed: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TIME_REQUEST_DETAIL:
      return {
        ...state,
        timeRequestDetail: action.payload,
      };
    case GET_TIME_REQUEST_APPROVAL_RECOMMENDED:
      return {
        ...state,
        timeRequestApprovalRecommendedLoading: action.requesting,
        timeRequestApprovalRecommended: action.payload,
        normalizedApprovals: action.payload
          ? {
              byEmployeeId: action.payload?.Approvals.reduce(
                normalizeBy("EmployeeId"),
                {}
              ),
              allEmployeeIds: action.payload?.Approvals?.map(
                (a) => a?.EmployeeId
              ),
            }
          : state.normalizedApprovals,
        normalizedRecommended: action.payload
          ? {
              byEmployeeId: action.payload?.Recommended.reduce(
                normalizeBy("EmployeeId"),
                {}
              ),
              allEmployeeIds: action.payload?.Recommended?.map(
                (r) => r?.EmployeeId
              ),
            }
          : state.normalizedRecommended,
      };
    case CREATE_TIME_REQUEST_REQUEST:
      return {
        ...state,
        requesting: true,
        timeRequestSucceed: false,
      };
    case CREATE_TIME_REQUEST_SUCCESS:
      return {
        ...state,
        requesting: false,
        timeRequestSucceed: true,
      };
    case CREATE_TIME_REQUEST_FAILURE:
      return {
        ...state,
        requesting: false,
        timeRequestSucceed: false,
      };
    case UPDATE_TIME_REQUEST_REQUEST:
      return {
        ...state,
        requesting: true,
        timeRequestSucceed: false,
      };
    case UPDATE_TIME_REQUEST_SUCCESS:
      return {
        ...state,
        requesting: false,
        timeRequestSucceed: true,
      };
    case UPDATE_TIME_REQUEST_FAILURE:
      return {
        ...state,
        requesting: false,
        timeRequestSucceed: false,
      };
    case GET_SHIFT_NAMES_LIST:
      return {
        ...state,
        shifts: action.payload,
      };

    default:
      return state;
  }
}
