import * as fromInterface from "../../../ts";
import { ActiveFeatureFlagState } from "./slice";

const selectActiveFeatureFlagSlice = (state: any): ActiveFeatureFlagState => {
  return state.dataStore.activeFeatureFlag;
};

export const selectActiveFeatureFlagStatus = (
  state: any
): fromInterface.loadingType => {
  return selectActiveFeatureFlagSlice(state).status;
};

export const selectActiveFeatureFlagLoaded = (state: any): boolean => {
  return selectActiveFeatureFlagSlice(state).loaded;
};

export const selectActiveFeatureFlagItems = (state: any) => {
  return selectActiveFeatureFlagSlice(state).items;
};
