import { createSlice } from "@reduxjs/toolkit";
import * as fromModel from "@rigotech/hrms-model";

export interface PerformanceFormWeightState {
  isValid: boolean;
  performanceFormWeights: fromModel.IPerformanceFormWeights | null;
  status: fromModel.loadingType;
  loaded: boolean;
}

const initialState: PerformanceFormWeightState = {
  isValid: false,
  performanceFormWeights: null,
  status: fromModel.STATUS.LOADING,
  loaded: false,
};

const performanceFormWeightSlice = createSlice({
  name: "performance form weight",
  initialState,
  reducers: {
    fetchRequest: state => {
      state.status = fromModel.STATUS.LOADING;
    },
    sync: (state, action) => {
      const payload = action.payload;

      state.isValid = payload.isValid;
      state.performanceFormWeights = payload.performanceFormWeights;
      state.status = fromModel.STATUS.IDLE;
      state.loaded = true;
    },
    invalidateWeight: state => {
      state.isValid = false;
    },
    clear: state => {
      state.isValid = false;
      state.performanceFormWeights = null;
      state.status = fromModel.STATUS.IDLE;
      state.loaded = false;
    },
  },
});

export const { fetchRequest, sync, invalidateWeight, clear } = performanceFormWeightSlice.actions;

export default performanceFormWeightSlice.reducer;
