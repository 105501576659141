
import { z } from "zod"
import { teamDirectoryDetailSchema } from "./team-directory-detail"
import { officeContactExceptionLevelSchema } from "./office-contact-exception-level"
import { officeContactExceptionEmployeeeSchema } from "./office-contact-exception-employee"
import { hiddenEmployeesDetailSchema } from "./hidden-employees-detail"

const teamDirectorySchema = z.object({
    TeamDirectoryDetail: teamDirectoryDetailSchema,
    OfficeContactExceptionLevel: z.array(officeContactExceptionLevelSchema),
    OfficeContactExceptionEmployeee: z.array(
        officeContactExceptionEmployeeeSchema
    ),
    PersonalContactExceptionLevel: z.array(officeContactExceptionLevelSchema),
    PersonalContactExceptionEmployee: z.array(
        officeContactExceptionEmployeeeSchema
    ),
    HiddenEmployeesDetail: z.array(hiddenEmployeesDetailSchema)
})

export type ITeamDirectory = z.infer<typeof teamDirectorySchema> | null;
