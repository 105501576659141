export const selectRoles = (state: any) => state.dataStore.rolesReducer.roles;

export const selectLoading = (state: any) =>
  state.dataStore.rolesReducer.loading;

export const selectIndividualRole = (state: any) =>
  state.dataStore.rolesReducer.individualRole;

export const selectUsersOptions = (state: any) =>
  state.dataStore.rolesReducer.usersOptions;

export const selectTpOptions = (state: any) =>
  state.dataStore.rolesReducer.individualRole?.TargetPopulation;

export const selectCustomPermission = (state: any) =>
  state.dataStore.rolesReducer.customPermission;
