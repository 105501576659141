import * as fromSlice from "./slice"
import * as fromInterface from "../../../ts";
import { ApiClient } from "../../../network/apiClient";
import { rolloutInfo } from "./api";
import * as fromDataStore from "../../../index"

export const fetchPerformanceRolloutInfo = (formType: number, reviewCycleId: any) => async (dispatch: any, getState: any) => {
    dispatch(fromSlice.fetchRequest())

    let url = `${rolloutInfo}?formType=${formType}`
    if (reviewCycleId)
        url = `${url}&reviewCycle=${reviewCycleId}`

    const response: fromInterface.ResponseWrapper<any> = await ApiClient.get<any>({
        endpoint: url,
        dispatch: dispatch
    })


    if (response?.Status) {
        dispatch(fromSlice.fetchSuccess(response?.Data))
    }

    return response
}

export const clearPerformanceRolloutInfo = () => async (dispatch: any, getState: any) => dispatch(fromSlice.clear())
