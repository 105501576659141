// @ts-nocheck
import {
  CHECK_CLOCK_IN_OUT_AUTHORITY,
  GET_ATTENDANCE_REPORT,
  GET_ATTENDANCE_BY_DATE,
  SAVE_ATTENDANCE_TIME,
  SAVE_ATTENDANCE_NOTE,
} from "actions/types";
import { catchError } from "helpers";
import Http from "src/utils/http/http";
import { get } from "src/networkService";
import axios from "helpers/axiosinstance";
import { Date_Format } from "helpers/dateFormat";
import Action from "src/utils/http/actionUtility";
import { toastMessage } from "actions/validateAction";

const urlPrefix = "/v1/leave-time/attendance/";

export const CheckClockInOutAuthority = () => async (dispatch: any) => {
  await axios
    .get(`${urlPrefix}is/manual`)
    .then((resp) => {
      const response = resp.data;
      if (response.Status) {
        dispatch({
          type: CHECK_CLOCK_IN_OUT_AUTHORITY,
          payload: response.Data,
        });
      } else {
        toastMessage(dispatch, response);
      }
    })
    .catch((err) => {
      catchError(dispatch, err);
    });
};

export const SaveAttendanceTime =
  (isCheckIn: boolean, param: any) => async (dispatch: any) => {
    const res = Http.post(`${urlPrefix}manual?isCheckIn=${isCheckIn}`, param);
    const actionConfig = {
      dispatch,
      actionType: "",
      effect: res,
      isLoading: true,
      isReturn: true,
    };
    var result = await Action.createThunkEffect(actionConfig);
    toastMessage(dispatch, result);
    return result?.Status;
  };

export const SaveLunchTime =
  (isBreakIn: boolean, param: any) => async (dispatch: any) => {
    const res = Http.post(`${urlPrefix}break?isBreakIn=${isBreakIn}`, param);
    const actionConfig = {
      dispatch,
      actionType: "",
      effect: res,
      isLoading: true,
      isReturn: true,
    };
    var result = await Action.createThunkEffect(actionConfig);
    toastMessage(dispatch, result);
    return result?.Status;
  };

export const SaveAttendanceNote =
  (isCheckIn: boolean, param: any) => async (dispatch: any) => {
    await axios
      .post(`${urlPrefix}comments?isCheckIn=${isCheckIn}`, param)
      .then((resp) => {
        const response = resp.data;
        if (response.Status) {
          dispatch({
            type: SAVE_ATTENDANCE_NOTE,
            isCheckIn: isCheckIn,
            clockNote: param.note,
          });
        }
        toastMessage(dispatch, response);
      })
      .catch((err) => {
        catchError(dispatch, err);
      });
  };

export const SaveLunchInNote =
  (isBreakIn: boolean, param: any) => async (dispatch: any) => {
    await axios
      .post(`${urlPrefix}break/comments?isBreakIn=${isBreakIn}`, param)
      .then((resp) => {
        const response = resp.data;
        if (response.Status) {
          dispatch({
            type: SAVE_ATTENDANCE_NOTE,
            isBreakIn: isBreakIn,
            lunchInNote: param.note,
          });
        }
        toastMessage(dispatch, response);
      })
      .catch((err) => {
        catchError(dispatch, err);
      });
  };

export const UpdateAttendanceNote =
  (isCheckIn: boolean, param: any) => async (dispatch: any) => {
    await axios
      .put(`${urlPrefix}comments?isCheckIn=${isCheckIn}`, param)
      .then((resp) => {
        const response = resp.data;
        toastMessage(dispatch, response);
      })
      .catch((err) => {
        catchError(dispatch, err);
      });
  };

export const GetAttendanceReport =
  (fromDate, toDate, attPeriod) => async (dispatch: any) => {
    let param = {
      startDate: fromDate,
      endDate: toDate,
      attPeriod,
      branchId: -1,
      departmentId: 0,
      pageIndex: 0,
      pageSize: 500,
      ShiftId: -1,
    }
    dispatch({
      type: GET_ATTENDANCE_REPORT,
      payload: [],
      requesting: true,
    });
    await axios
      .post(`${urlPrefix}report`, param)
      .then((resp) => {
        const response = resp.data;
        if (response.Status) {
          dispatch({
            type: GET_ATTENDANCE_REPORT,
            payload: response.Data,
            requesting: false,
          });
        } else {
          toastMessage(dispatch, response);
          dispatch({
            type: GET_ATTENDANCE_REPORT,
            payload: [],
            requesting: false,
          });
        }
      })
      .catch((err) => {
        catchError(dispatch, err);
        dispatch({
          type: GET_ATTENDANCE_REPORT,
          payload: [],
          requesting: false,
        });
      });
  };

export const GetAttendanceByDate =
  (fromDate, toDate, employeeId = null) =>
    async (dispatch) => {
      const startDate = Date_Format(fromDate);
      const endDate = Date_Format(toDate);
      var res = await get(
        `/v1/leave-time/time/line?startDate=${startDate}&endDate=${endDate}&employeeId=${employeeId}`,
        dispatch,
        null,
        false
      ).catch((ex) => { });

      if (res?.Status) {
        dispatch({
          type: GET_ATTENDANCE_BY_DATE,
          payload: res.Data,
        });
      } else {
        toastMessage(dispatch, res);
      }
    };
