export const baseName = "";

export const getBaseName = () => {
  var segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
  var segment_array = segment_str.split("/");
  let segment = segment_array[1];
  let segment2 = segment_array[2];

  if (segment && segment2) {
    if (segment !== "react" && segment2 === "react") {
      return `/${segment}/react`;
    } else {
      return "/react";
    }
  } else {
    return "/react";
  }
};

export const getBaseAxios = () => {
  // let origin = window.location.origin;
  // let path = window.location.pathname.split("/");
  // if(path[1] && path[2]){
  //     if(path[1] !=='react' && path[2] ==='react'){
  //         return origin + "/" + path[1]
  //     }else{
  //         return origin
  //     }
  // }else{
  //     return origin
  // }
  

  // if (process.env.IS_PRODCTION) {
  //   return window.CompanyBaseUrl;
  // }
 
  // return process.env.BASE_REACT_API_URL;
  return "";
};

export const clearLogoutLocalStorage = () => {
  localStorage.removeItem("AuthToken");
  localStorage.removeItem("Expires");
  localStorage.removeItem("ExpiresMinutes");
  localStorage.removeItem("RefreshToken");
  localStorage.removeItem("UserDetail");

  // localStorage.removeItem("AuthToken");
  // localStorage.removeItem("Expires");
  // localStorage.removeItem("ExpiresMinutes");
  // localStorage.removeItem("RefreshToken");
  // localStorage.removeItem("UserDetail");
};
