import React from 'react';
import { useSelector } from 'react-redux';
import OnboardLayout from './onboardLayout';
import EmployeeLayout from './employeeLayout';
import AdminLayout from './adminLayout';

function Layout(props) {
    const {children} = props;
    const { isAuthenticated, user } = useSelector((state: any) => state.auth)
    const { baseUrl } = props
    const isEmployee = user?.IsEmployeeType;
    const isAdmin = !user?.IsEmployeeType;
    const isOnboarding = isAuthenticated && user && user.RoleName === 'OnboardingEmployee';
    const isNotLoggedIn = !isAuthenticated;
    if(isNotLoggedIn){
        return children;
    }
    if(isOnboarding){
        if (
            props.location.pathname == '/login' ||
            props.location.pathname == '' ||
            props.location.pathname == '/employee'
        )
        props.history.push('/employeeonboard')
        return <OnboardLayout isEmployee={isEmployee}>{children}</OnboardLayout>
    }
    if(isEmployee)
        return <EmployeeLayout>{children}</EmployeeLayout>
    
    return <AdminLayout baseUrl={baseUrl}>{children}</AdminLayout>
}

export default Layout