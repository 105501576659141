// @ts-nocheck 
import React from "react";
import {Card} from "components/card";
import {Text} from "components/text";
import {RegularList} from "components/common/regularList";

import QuestionListItem from "./QuestionListItem";

const CoursePageListItem = ({coursePage, index}) => {
  return (
    <Card flexDirection={"column"} padding={"0 1.5rem"} width="100%">
      <Text
        fontSize="16px"
        fontWeight="500"
        letterSpacing="0.4px"
        textDecoration="underline"
        textUnderlineOffset="2px"
        title={`S${index + 1}. ${coursePage.PageName}`}
      />
      <RegularList
        items={coursePage?.Question}
        resourceName="question"
        itemComponent={QuestionListItem}
      />
    </Card>
  );
};

CoursePageListItem.defaultProps = {
  coursePage: [],
};

export default CoursePageListItem;
