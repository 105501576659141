// @ts-nocheck 
import {
  GET_BRANCHES,
  GET_LOCATION,
  GET_DEPARTMENTS,
  GET_JOB_STATUS,
  GET_EDUCATION_LEVELS,
  SET_SPINNER_LOADING,
  GET_EXPERIENCE_DURATION,
} from "actions/types";

const initialState = {
  loading: true,
  spinnerLoading: false,
  branches: [],
  locations: [],
  departments: [],
  employeeStatus: [],
  educationLevels: [],
  experienceDuration: [],
  approvers: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_BRANCHES:
      return {
        ...state,
        branches: action.payload,
      };

    case GET_LOCATION:
      return {
        ...state,
        locations: action.payload,
      };

    case GET_DEPARTMENTS:
      return {
        ...state,
        departments: action.payload,
      };

    case GET_JOB_STATUS:
      //store/reducer/recruitment/common
      return {
        ...state,
        employeeStatus: action.payload,
      };

    case GET_EDUCATION_LEVELS:
      return {
        ...state,
        educationLevels: action.payload,
      };

    case GET_EXPERIENCE_DURATION:
      return {
        ...state,
        experienceDuration: action.payload,
      };

    case SET_SPINNER_LOADING:
      return {
        ...state,
        spinnerLoading: action.payload,
      };

    default:
      return state;
  }
}
