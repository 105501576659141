import * as fromInterface from "../../../ts";
import * as fromConstant from "../../../constants";
import { ApiClient } from "../../../network/apiClient";
import * as fromSlice from "./slice";
import * as fromSelector from "./selectors"
import { reviewFlowDropdown } from "./api";


export const fetchReviewFlowDropDown = () => async (dispatch: any, getState: any) => {
    const state = getState();
    const isLoaded = fromSelector.selectReviewFlowDropDownLoaded(state)
    if (!isLoaded) {
        dispatch(fromSlice.fetchRequest(false))
        const response: any = await ApiClient.get({
            endpoint: `${reviewFlowDropdown}/false`,
            dispatch: dispatch
        })

        if (response?.Status) {
            dispatch(fromSlice.fetchReviewFlowSuccess(response.Data))
        }

        return response
    }
    return fromSelector.selectReviewFlowDropDownItems(state)
}

export const clearReviewFlowDropDown = () => (dispatch: any) => dispatch(fromSlice.clear(false))


export const fetchReviewFlow360DropDown = () => async (dispatch: any, getState: any) => {
    const state = getState();
    const isLoaded = fromSelector.selectReviewFlow360DropDownLoaded(state)
    if (!isLoaded) {
        dispatch(fromSlice.fetchRequest(false))
        const response: any = await ApiClient.get({
            endpoint: `${reviewFlowDropdown}/true`,
            dispatch: dispatch
        })

        if (response?.Status) {
            dispatch(fromSlice.fetchReviewFlow360Success(response.Data))
        }

        return response
    }
    return fromSelector.selectReviewFlow360DropDownItems(state)
}

export const clearReviewFlow360DropDown = () => (dispatch: any) => dispatch(fromSlice.clear(true))