import { GET_EMPLOYEE_LIST_FOR_ADMIN } from "actions/types";

const initialState = {
  employeeListForAdmin: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEE_LIST_FOR_ADMIN:
      return {
        ...state,
        employeeListForAdmin: action.payload,
      };

    default:
      return state;
  }
}
