import React from "react";
import styled from "styled-components";

const CardContainer = styled.span`
  align-items: ${(props) => props.alignItems};
  background: ${(props) => props.background};
  border: ${(props) => props.border};
  border-radius: ${(props) => props.borderRadius};
  color: ${(props) => props.color};
  cursor: ${(props) => props.cursor};
  display: inline-flex;
  flex-direction: ${(props) => props.flexDirection};
  gap: ${(props) => props.gap};
  height: ${(props) => props.height};
  justify-content: ${(props) => props.justifyContent};
  margin: ${(props) => props.margin};
  margin-top: ${(props) => props.marginTop};
  margin-right: ${(props) => props.marginRight};
  margin-bottom: ${(props) => props.marginBottom};
  margin-left: ${(props) => props.marginLeft};
  /* min-width: 100px; */
  padding: ${(props) => props.padding};
  padding-top: ${(props) => props.paddingTop};
  padding-right: ${(props) => props.paddingRight};
  padding-bottom: ${(props) => props.paddingBottom};
  padding-left: ${(props) => props.paddingLeft};
  pointer-events: ${(props) => props.pointerEvents};
  width: ${(props) => props.width};

  &:hover {
    background: ${(props) => props.hoverBackground};
    color: ${(props) => props.hoverColor};
  }
`;

export const Card = ({
  alignItems,
  background,
  border,
  borderRadius,
  children,
  color,
  cursor,
  flexDirection,
  gap,
  handleClick,
  height,
  hoverBackground,
  hoverColor,
  justifyContent,
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  padding,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
  pointerEvents,
  width,
}) => {
  return (
    <CardContainer
      alignItems={alignItems}
      background={background}
      border={border}
      borderRadius={borderRadius}
      color={color}
      cursor={cursor}
      flexDirection={flexDirection}
      gap={gap}
      height={height}
      hoverBackground={hoverBackground}
      hoverColor={hoverColor}
      justifyContent={justifyContent}
      margin={margin}
      marginTop={marginTop}
      marginRight={marginRight}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      onClick={handleClick}
      padding={padding}
      paddingTop={paddingTop}
      paddingRight={paddingRight}
      paddingBottom={paddingBottom}
      paddingLeft={paddingLeft}
      pointerEvents={pointerEvents}
      width={width}
    >
      {children}
    </CardContainer>
  );
};

Card.defaultProps = {
  alignItems: "flex-start",
  background: "#fff",
  border: "",
  borderRadius: "",
  children: "",
  color: "#000",
  cursor: "",
  flexDirection: "row",
  gap: "",
  height: "",
  handleClick: () => {},
  hoverBackground: "",
  hoverColor: "",
  justifyContent: "flex-start",
  margin: "",
  marginTop: "",
  marginRight: "",
  marginBottom: "",
  marginLeft: "",
  padding: "",
  paddingTop: "",
  paddingRight: "",
  paddingBottom: "",
  paddingLeft: "",
  pointerEvents: "",
  width: "",
};
