import { SAVE_JOB_APPLY, GET_JOB_DETAIL_BY_ID } from "actions/types";

const initialState = {
  jobDetailById: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_JOB_DETAIL_BY_ID: {
      return {
        ...state,
        jobDetailById: action.payload
      };
    }

    default:
      return state;
  }
}
