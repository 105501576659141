// @ts-nocheck
import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  useTable,
  useSortBy,
  useRowSelect,
  useResizeColumns,
  useFlexLayout,
  useBlockLayout,
  usePagination,
  useExpanded,
} from "react-table";
import Icons from "components/icons/svgIcons";
import "./styles.scss";
import isEmpty from "isEmpty";
import { useSticky } from "react-table-sticky";
import EditButtonCell from "./components/EditButtonCell";
import EditableCell from "./components/editableCell";
import RowSelectionCheckbox from "./components/rowSelectionCheckbox";
import classNames from "classnames";

interface TableInterface {
  columns: any;
  data: any;
  isRowSelect?: boolean;
  isExpanded?: boolean;
  isEditable?: boolean;
  onRowClick?: Function;
  isFixedWidth?: boolean;
  onSaveData?: Function;
  onRowSelect?: Function;
  updateMyData?: Function;
  disablePageResetOnDataChange?: any;
  hideHeader?: boolean;
  enableRemoteSort?: boolean;
  onRemoteSorting?: Function;
  selectedRows?: any;
  hiddenColumns?: any;
  shouldEnableResizing?: boolean;
}

// function Table(props: TableInterface) {
const Table = (props: TableInterface) => {
  const {
    columns,
    data,
    isRowSelect,
    isEditable,
    onRowClick,
    isFixedWidth,
    isExpanded = false,
    updateMyData,
    onSaveData,
    onRowSelect,
    hideHeader,
    disablePageResetOnDataChange,
    enableRemoteSort,
    onRemoteSorting,
    selectedRows,
    hiddenColumns,
    shouldEnableResizing,
  } = props;

  const [edit, setEditable] = useState(false);
  const [length, setlength] = useState(0);
  const onEdit = async () => {
    await setEditable(true);
  };

  const defaultColumn = React.useMemo(
    () => ({
      // width: 150,
      // maxWidth: 300,
      edit,
      Cell: (cprops) => (
        <EditableCell
          {...cprops}
          updateMyData={updateMyData}
          onSaveData={onSaveData}
        />
      ),
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    setHiddenColumns,
    rows,
    columns: cls,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds, expanded },
  } = useTable(
    {
      columns,
      manualSortBy: enableRemoteSort,
      edit,
      data,
      getSubRows: (row) => row?.OvertimeRequestsLines ?? [],
      defaultColumn,
      disablePageResetOnDataChange,
      onSaveData,
      initialState: {
        expanded: isExpanded ? { "0": true, "2": true } : false,
        selectedRowIds: selectedRows ?? {},
        // hiddenColumns: ["Position", "Reason"],
        hiddenColumns: hiddenColumns ?? [],
      },
    },
    useSortBy,
    useExpanded,
    usePagination,
    // useBlockLayout,
    useFlexLayout,
    useResizeColumns,
    useRowSelect,

    useSticky,

    (hooks) => {
      // if (isEditable) {
      //   hooks.visibleColumns.push((columns: any) => [
      //     ...columns,
      //     {
      //       id: "editable",
      //       Cell: ({row}: any) => (
      //         <div>
      //           <EditButtonCell
      //             {...row}
      //             onSaveData={onSaveData}
      //             onEdit={onEdit}
      //             setEditable={setEditable}
      //             isEdit={edit}
      //           />
      //         </div>
      //       ),
      //     },
      //   ]);
      // }
      if (isRowSelect) {
        hooks.visibleColumns.push((columns: any) => [
          // Let's make a column for selection
          {
            id: "selection",
            width: 50,
            maxWidth: 50,
            minWidth: 50,
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <RowSelectionCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }: any) => (
              <div>
                <RowSelectionCheckbox
                  {...row.getToggleRowSelectedProps()}
                  disabled={row.original.isDisabled}
                />
              </div>
            ),
          },
          ...columns,
        ]);
      }
    }
  );

  const isSelectedRowEmpty = useMemo(
    () => !selectedFlatRows || selectedFlatRows?.length === 0,
    [selectedFlatRows]
  );

  useEffect(() => {
    setEditable(isEditable);
  }, [isEditable]);

  useEffect(() => {
    if (onRowSelect && !isSelectedRowEmpty) {
      onRowSelect(
        selectedFlatRows.map((el) => el.original).filter((x) => !x.isDisabled)
      );
    }
  }, [selectedRowIds]);

  // const firstPageRows = rows.slice(0, 20);
  React.useEffect(() => {
    const hiddenColumns = cls
      .filter((column: any) => {
        if ("show" in column) {
          return !column.show;
        }
      })
      .map((column: any) => column.id || column.accessor);
    setHiddenColumns(hiddenColumns);
  }, []);
  const handleColumnClick = async (event, column) => {
    event.persist();
    if (column?.isSort) {
      column.getSortByToggleProps().onClick(event);

      if (enableRemoteSort) {
        const { isSorted, isSortedDesc } = column;
        let sortOrder = "asc";
        if (isSorted === false && isSortedDesc === undefined) {
          sortOrder = "desc";
        } else if (isSorted && isSortedDesc === false) {
          sortOrder = "asc";
        } else {
          sortOrder = "";
        }

        onRemoteSorting &&
          (await onRemoteSorting({
            sortBy: column.id || column.accessor,
            sortOrder,
          }));
      }
    }
  };

  return (
    <div className="table-contain">
      <div className="table-container">
        <div
          className="table-table sticky shift-table"
          style={{ background: "#fff" }}
        >
          {/* <div style={{ width: '100%' }} className="table-table sticky " {...getTableProps()}> */}
          {!hideHeader && (
            <div className="table-header">
              {headerGroups.map((headerGroup, key) => {
                return (
                  <div
                    key={"Table-Header-" + key}
                    {...headerGroup.getHeaderGroupProps()}
                    className="tr"
                  >
                    {headerGroup.headers.map((column: any, key) => {
                      const isShow = "show" in column ? !column.show : true;
                      const { style, ...rest } = column.getHeaderProps();
                      const extraStyle = column?.maxWidth
                        ? { maxWidth: column.maxWidth }
                        : {};
                      return (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props

                        <div
                          // style={isFixedWidth ? { minWidth: column.width } : {}}

                          {...rest}
                          key={"Table-Row-" + key}
                          style={{ ...style, ...extraStyle }}
                          // style={{
                          //   ...style,
                          //   width: "auto",
                          //   minWidth: column.minWidth,
                          //   flex: 1,
                          // }}
                          className={
                            column.show && column.show === false
                              ? "hide-th th"
                              : "th"
                          }
                          // onClick={column?.isSort ?column.getSortByToggleProps().onClick:null }
                          // {...column.getHeaderProps(column.getSortByToggleProps())}
                        >
                          {column.render("Header")}
                          {/* Add a sort direction indicator */}
                          {/* {column?.id !== 'selection'  &&(<span className="table-sort"> */}

                          {column?.id !== "selection" && column?.isSort && (
                            <span
                              style={{ padding: "0 8px", marginLeft: 0 }}
                              onClick={(event) =>
                                handleColumnClick(event, column)
                              }
                              // onClick={
                              //   column?.isSort
                              //     ? column.getSortByToggleProps().onClick
                              //     : null
                              // }
                              className="table-sort"
                            >
                              {column.isSorted && column.isSortedDesc ? (
                                <Icons
                                  size={15}
                                  color={"#333"}
                                  name="arrow-down"
                                />
                              ) : column.isSorted && !column.isSortedDesc ? (
                                <Icons
                                  size={15}
                                  color={"#333"}
                                  name="arrow-up"
                                />
                              ) : (
                                <Icons
                                  size={15}
                                  color={"#DBDDE0"}
                                  name="arrow-down"
                                />
                              )}
                            </span>
                          )}
                          {column?.Filter && <column.Filter />}
                          <div
                            {...column.getResizerProps()}
                            className={`resizer ${
                              column.isResizing ? "isResizing" : ""
                            } ${!shouldEnableResizing ? "hide-resizer" : ""}`}
                          />
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          )}

          <div {...getTableBodyProps()} className="table-body">
            {rows.map((row, key) => {
              prepareRow(row);
              return (
                <div
                  key={"Table-Row-" + key}
                  {...row.getRowProps()}
                  onClick={() => onRowClick && onRowClick(row)}
                  className={classNames("tr", { "tr-sub": row.depth === 1 })}
                >
                  {row.cells.map((cell: any, any) => {
                    const { style, ...otherProps } = cell.getCellProps();
                    const extraStyle =
                      cell.column.Header === "Status"
                        ? { overflow: "unset" }
                        : {};
                    const extraStyleNew = cell.column?.maxWidth
                      ? { maxWidth: cell.column.maxWidth }
                      : {};
                    // const widthStyle = {width:'auto',minWidth:cell.column.minWidth,flex:1}
                    return (
                      <div
                        key={"Table-Cell-" + key}
                        className="td"
                        style={{
                          ...style,
                          ...extraStyle,
                          ...extraStyleNew,
                          position: "relative",
                        }}
                        {...otherProps}
                      >
                        {cell.render("Cell")}
                      </div>
                    );
                  })}
                </div>
              );
            })}
            {isEmpty(rows) && (
              <div className="no-found">No data matches your criteria.</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Table.defaultProps = {
  hideHeader: false,
  shouldEnableResizing: false,
};
export default Table;
