// @ts-nocheck 
// import engCal from './engCal';
// import nepCal from './nepCal';

// export {engCal};
// export {nepCal};

// export default engCal;

import React from "react";
import EngCal from "./engCal";
import NepCal from "./nepCal";
import DateErrorBoundary from "components/common/dateErrorBoundry";

interface DatePickerProps {
  isTextInNepali?: boolean;
  isFullDetails?: boolean;
  onDateChange?: Function;
  onClose?: Function;
  isNepali?: boolean;
  value?: any;
  date?: any;
  minDate?: any;
  maxDate?: any;
  noFooter?: boolean;
  isDateRange?: boolean;
  enableOkButton?:boolean;
  onOkButtonClicked?:Function;
  hideAction?:boolean;
}

function DatePicker(props: DatePickerProps) {
  const { isNepali, isTextInNepali } = props;
  if (isNepali) {
    return (
      <DateErrorBoundary>
        <NepCal isInNepali={isTextInNepali} {...props} />
      </DateErrorBoundary>
    );
  }
  return (
    <DateErrorBoundary>
      <EngCal {...props} />
    </DateErrorBoundary>
  );
}

DatePicker.defaultProps = {
  isFullDetails: false,
  isNepali: false,
  isDateRange: false,
  isTextInNepali: false,
  minDate: null,
  maxDate: null,
};

export default DatePicker;
