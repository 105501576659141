import { createSlice } from "@reduxjs/toolkit";
import * as fromInterface from "../../../ts";

export interface PerformanceRolloutEmployeeState {
    items: any,
    status: fromInterface.loadingType;
    loaded: boolean;
}

const initialState: PerformanceRolloutEmployeeState = {
    items: [],
    status: fromInterface.STATUS.IDLE,
    loaded: false,
};

const performanceRolloutEmployeeSlice = createSlice({
    name: "performance rollout employees",
    initialState,
    reducers: {
        fetchRequest: (state) => {
            state.status = fromInterface.STATUS.LOADING;
        },
        fetchSuccess: (state, action) => {
            const payload = action.payload

            state.items = payload;
            state.status = fromInterface.STATUS.IDLE;
            state.loaded = true

        },
        clear: (state) => {
            state.items = []
            state.status = fromInterface.STATUS.IDLE
            state.loaded = false
            return state
        },
    },
});

export const {
    fetchRequest,
    fetchSuccess,
    clear,
} = performanceRolloutEmployeeSlice.actions

export default performanceRolloutEmployeeSlice.reducer;
