

export enum Colors {
    PrimaryColor = "#0068D4",
    PrimaryDarkerColor = "#0052AB",
    PrimaryLighterColot = "#2680EB",
    RedColor = "#D70939",
    PinkColor = "#FF0086",
    Pink20PColor = "#FF008633",
    GreenColor = "#49AA00",
    LinkColor = "#2957D9",

    SuccessColor = "#00ad00",

    BlueGrey = '#405471',

    Black = "#020305",
    White = "#fff",

    TableHeaderBg = "#ECF0F5",
    TableBorder = "#ECF0F5",
    TableBorderDarker = "#CCD5DF",


    // Components
    BorderColor = "#e6e6e6",

    // Text Colors 
    TextDark = "#1F262E",
    TextNormal = "#373F58",
    TextLight = "#757B82",
    TextLighter = "#9c9c9c",
    TextPrimaryDark = '#355DAE',
    TextPrimaryLight = '#2957D9',
    HeadingText = "#333",
    //Badge
    TextPrimaryBadgeColor = '#0386f3',


    //Icons 
    IconGrey = "#7D858E",




    //skip
    inherit = 'inherit',
    transparent = "transparent",
}