import {
    GET_REGION_CLUSTER,
    GET_CLUSTER,
    ADD_CLUSTER,
    UPDATE_CLUSTER,
  } from "actions/types";

const initialState = {
    regionCluster: [],
    clusters: [],
    loading:false,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_REGION_CLUSTER:
            return{
                ...state,
                regionCluster:action.payload,
            }
        case GET_CLUSTER:
            return{
                ...state,
                clusters:action.payload,
            }
        case ADD_CLUSTER:
            return{
                ...state,
                regionCluster:state.regionCluster.map(region=>{
                    
                    if(region.ProvinceId === action.payload.ProvinceRefId){
                        let clus = action.payload;
                        clus.Alias ="C";
                        clus.Desc = "Cluster";
                        clus.BranchesCount = 0;
                        region.ClustersCount += 1;
                        region.Clusters = [clus,...region.Clusters];
                        return region;
                    }
                    return region;
                }),
            }
        case UPDATE_CLUSTER:
            return{
                ...state,
                regionCluster:state.regionCluster.map(region=>{
                    
                    if(region.ProvinceId === action.payload.ProvinceRefId){
                        
                        region.Clusters = region.Clusters.map(item=>{
                            if(item.ClusterId === action.payload.ClusterId){
                                item.ClusterName = action.payload.ClusterName
                                return item
                            }
                            return item
                        })
                        return region;
                    }
                    return region;
                }),
            }
        default:
            return state;
        }
  }
  