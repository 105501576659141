import * as fromInterface from "../../../ts";
import { PerformanceFormSectionState } from "./slice";

const selectPxerformanceFormSectionCurrentSlice = (state: any): PerformanceFormSectionState => {
    return state.dataStore.performanceFormSection;
};

export const selectPerformanceFormSelectSectionStatus = (state: any): fromInterface.loadingType => {
    return selectPxerformanceFormSectionCurrentSlice(state).status;
};

export const selectPerformanceFormSelectSectionLoaded = (state: any): boolean => {
    return selectPxerformanceFormSectionCurrentSlice(state).loaded;
};

export const selectPerformanceFormSelectSectionItems = (state: any) => {
    return selectPxerformanceFormSectionCurrentSlice(state).items;
};
