import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import * as fromModels from "@rigotech/hrms-model";

export interface PMSAnalytics {
  item: fromModels.IPMSAnalytics | null;
  filters: fromModels.IPMSAnalyticsFilters | null;
  status: fromModels.loadingType;
  loaded: boolean;
}

const initialState: PMSAnalytics = {
  item: null,
  filters: null,
  status: fromModels.STATUS.LOADING,
  loaded: false,
};

const pmsAnalyticsSlice = createSlice({
  name: "pms analytics",
  initialState,
  reducers: {
    fetchRequest: state => {
      state.status = fromModels.STATUS.LOADING;
    },
    fetchSuccess: (state, { payload }: PayloadAction<any>) => {

      state.item = payload;
      state.status = fromModels.STATUS.IDLE;
      state.loaded = true;
    },

    updateFilters: (state, action) => {
      const payload = action.payload

      state.filters = { ...state.filters, ...payload }
    },

    clear: state => {

      state.item = null
      state.filters = null
      state.status = fromModels.STATUS.LOADING
      state.loaded = false

    },
  },
});

export const { fetchRequest, fetchSuccess, updateFilters, clear } = pmsAnalyticsSlice.actions;

export default pmsAnalyticsSlice.reducer;
