// @ts-nocheck
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { reducers } from "../reducers";
import { newReducers } from "store/reducers";
import { nodeEnv } from "constants/constants";
import environment from "environment";
import * as fromDataStore from "@rigotech/hrms-data";
import { configureStore } from "@reduxjs/toolkit";

const initialState = {};

const history = createBrowserHistory<any>({ basename: "/" });

const enhancers = [];
const isDev = nodeEnv !== "production";
const windowIfDefined = typeof window === "undefined" ? null : (window as any);
if (
  environment.isDevelopment &&
  windowIfDefined &&
  windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__
) {
  enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
}

const config = {
  whitelist: ["SET_CURRENT_USER", "SET_CURRENT_TOKEN"],
};

// const middleware = [
//   environment.isDevelopment ? reduxFreeze : null,
//   thunk,
//   routerMiddleware(history),
//   checkTokenExpirationMiddleware,
//   createStateSyncMiddleware(config),
//   // errorToastMiddleware()
// ].filter(Boolean);

// const rootReducer = combineReducers({
//   ...reducers,
//   ...newReducers,
//   router: connectRouter(history),
// });

// const store = createStore(
//   withReduxStateSync(rootReducer),
//   initialState,
//   composeWithDevTools(applyMiddleware(...middleware))
// );

const store = configureStore({
  reducer: {
    ...reducers,
    ...newReducers,
    [fromDataStore.performanceFormPreviewApi.reducerPath]: fromDataStore.performanceFormPreviewApi.reducer,
    [fromDataStore.rolloutPreviewApi.reducerPath]: fromDataStore.rolloutPreviewApi.reducer,
    router: connectRouter(history),
  },
  preloadedState: initialState,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false
    }),
    routerMiddleware(history),
    fromDataStore.performanceFormPreviewApi.middleware,
    fromDataStore.rolloutPreviewApi.middleware,
    // add other middlewares here
  ],
  devTools: true, // enable the Redux DevTools extension
});

export default store;
// export default initStateWithPrevTab(store);
