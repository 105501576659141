// @ts-nocheck 
import React from "react";
import classnames from "classnames";
import isEmpty from "helpers/isEmpty";

import TextInput from "./textInput";
import Stepper from "./stepper";
import TextArea from "./textArea";
import ReactSelect from "./reactSelect";
import DateInput from "./dateInput";
import CustomDateInput from "./customDateInput";
import ChipsInput from "./chipsInput";
import SingleDocumentInput from "./singleDocumentInput";
import CheckGroup from "./checkGroup";
import AsyncAutoSelect from "./asyncAutoSelect";
import ReactDateRange from "./reactDateRange";
import ReactCalendar from "./reactCalendar";
import RadioGroup from "./radioGroup";
import RadioList from "./radioList";
import TextEditor from "./textEditor";
import SearchInput from "./searchInput";
import YDMInput from "./ydmInput";
import UploadInput from "./uploadInput";
import {wholeFormInterface} from "ts/interfaces/formInterface";
import DragAndDropUpload from "./dragAndDropUpload";
import CheckBoxGroup from "./checkBoxGroup";
import CheckBoxList from "./checkBoxList";
import TimePicker from "./timePicker";
import SelectInput from "./selectInput";
import Switch from "./switch";
import HourMinuteInput from "./hourMinuteInput";

declare module "react" {
  interface HTMLAttributes<T> extends DOMAttributes<T> {
    validators?: any[];
  }
}

class index extends React.Component<wholeFormInterface, {}> {
  public static defaultProps = {
    alignment: "vertical",
    autoFocus: false,
    formName: "textinput",
    label: "",
    name: "",
    validators: [],
  };

  constructor(props: wholeFormInterface) {
    super(props);
    this.getDynamicComponent = this.getDynamicComponent.bind(this);
  }

  getDynamicComponent(): any {
    let {formName} = this.props;

    switch (formName) {
      case "textinput":
        return TextInput;

      case "textarea":
        return TextArea;

      case "reactselect":
        return ReactSelect;

      case "dateinput":
        return DateInput;

      case "radioList":
        return RadioList;

      case "customdateinput":
        return CustomDateInput;

      case "chipsinput":
        return ChipsInput;

      case "singledocumentinput":
        return SingleDocumentInput;

      case "stepper":
        return Stepper;

      case "checkgroup":
        return CheckGroup;

      case "searchInput":
        return SearchInput;

      case "asyncautoselect":
        return AsyncAutoSelect;

      case "reactdaterange":
        return ReactDateRange;

      case "reactcalendar":
        return ReactCalendar;

      // case "timepicker":
      //   return ReactTimePicker;
      case "timepicker":
        return TimePicker;

      case "radiogroup":
        return RadioGroup;
      case "selectInput":
        return SelectInput;

      case "ydmInput":
        return YDMInput;

      case "uploadInput":
        return UploadInput;

      case "texteditor":
        return TextEditor;

      case "dragAndDropUpload":
        return DragAndDropUpload;

      case "checkBoxGroup":
        return CheckBoxGroup;

      case "switch":
        return Switch;

      case "hourMinute":
        return HourMinuteInput;

      case "checkBoxList":
        return CheckBoxList;

      default:
        return null;
    }
  }

  render() {
    const {
      alignment,
      className,
      error,
      formError,
      formName,
      label,
      loading,
      name,
      size,
      width,
      validators,
    } = this.props;

    let inputclass = classnames({
      "genericForm-group": true,
      error: (!isEmpty(error) && error) || (!isEmpty(formError) && formError[name]),
      horizontalGenericForm: alignment && alignment == "horizontal",
      [className]: className,
      [size]: size,
      "check-group": formName === "checkBoxGroup",
    });

    let DynamicComponent = this.getDynamicComponent();
    const load = !isEmpty(loading) ? loading : false;
    return (
      <div
        id={name}
        validators={validators}
        className={inputclass}
        style={{width}}
        onClick={(e) => e.stopPropagation()}
      >
        {label &&
          formName != "radiogroup" &&
          formName != "checkgroup" &&
          formName != "checkBoxGroup" &&
          !load && (
            <div className="genericForm-group__label">
              <label htmlFor={name} className="genericForm-group__label">
                {label}{" "}
                {!isEmpty(validators) && validators.find((f) => f == "required")
                  ? " *"
                  : ""}
              </label>
            </div>
          )}
        {DynamicComponent ? <DynamicComponent {...this.props} /> : "Invalid form name"}
        {error && <div className="genericForm-group__message">{error}</div>}
        {formError && formError[name] && (
          <div className="genericForm-group__message"> {formError[name].message}</div>
        )}
      </div>
    );
  }
}

export default index;
