import {
  GET_JOB_PROFILE,
  GET_JOB_PROFILE_BY_ID,
  GET_DEFAULT_JOB_PROFILE,
  GET_JOBPROFILE_KSA_EDUCATION_LIST,
  GET_JOBPROFILE_KSA_CERTIFICATION_LIST,
  GET_JOBPROFILE_KNOWLEDGES,
  GET_JOBPROFILE_SKILLS,
  GET_JOBPROFILE_ABILITIES,
  GET_JOB_DESCRIPTION,
  GET_JOB_DESCRIPTION_BY_ID,
  SET_JOB_PROFILE_BY_ID_LOADING
} from "actions/types";

const initialState = {
  defaultJobProfiles: {},
  jobProfileList: [],
  jobProfileDetails: { loading: false, value: {} },
  educationList: [],
  qualificationList: [],
  knowledgeList: [],
  skillList: [],
  abilityList: [],
  jobDescriptionList: [],
  jobDescriptionDetails: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_JOB_PROFILE:
      return {
        ...state,
        jobProfileList: action.payload,
      };
    case GET_DEFAULT_JOB_PROFILE:
      return {
        ...state,
        defaultJobProfiles: action.payload,
      };
    case SET_JOB_PROFILE_BY_ID_LOADING:
      return {
        ...state,
        jobProfileDetails: { loading: action.payload, value: {} },
      };
    case GET_JOB_PROFILE_BY_ID:
      return {
        ...state,
        jobProfileDetails: { loading: false, value: action.payload },
      };
    case GET_JOBPROFILE_KSA_EDUCATION_LIST:
      return {
        ...state,
        educationList: action.payload,
      };
    case GET_JOBPROFILE_KSA_CERTIFICATION_LIST:
      return {
        ...state,
        qualificationList: action.payload,
      };
    case GET_JOBPROFILE_KNOWLEDGES:
      return {
        ...state,
        knowledgeList: action.payload,
      };
    case GET_JOBPROFILE_SKILLS:
      return {
        ...state,
        skillList: action.payload,
      };
    case GET_JOBPROFILE_ABILITIES:
      return {
        ...state,
        abilityList: action.payload,
      };
    case GET_JOB_DESCRIPTION:
      return {
        ...state,
        jobDescriptionList: action.payload,
      };
    case GET_JOB_DESCRIPTION_BY_ID:
      return {
        ...state,
        jobDescriptionDetails: action.payload,
      };

    default:
      return state;
  }
}
