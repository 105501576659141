// @ts-nocheck 
import {RegularList} from "components/common/regularList";
import {Text} from "components/text";
import React from "react";
import CoursePageQuestionMultipleAnswerListItem from "./coursePageQuestionMultipleAnswerListItem";
import CoursePageQuestionSingleAnswerListItem from "./coursePageQuestionSingleAnswerListItem";

const CoursePageQuestionListItem = ({coursePageQuestion, index}) => {
  return (
    <div className="course-page-block__question">
      <Text title={`Q${index + 1}. ${coursePageQuestion.Question}`} />

      {coursePageQuestion.QuestionOption.length > 0 &&
      (coursePageQuestion.QuestionType === 1 || coursePageQuestion.QuestionType === 2) ? (
        <RegularList
          items={coursePageQuestion.QuestionOption}
          resourceName="questionOption"
          itemComponent={CoursePageQuestionSingleAnswerListItem}
        />
      ) : (
        <RegularList
          items={coursePageQuestion.QuestionOption}
          resourceName="questionOption"
          itemComponent={CoursePageQuestionMultipleAnswerListItem}
        />
      )}
    </div>
  );
};

export default CoursePageQuestionListItem;
