import { createSlice } from "@reduxjs/toolkit"

import * as fromInterface from "../../../ts"

export interface PeerAffinityLevelState {
  items: any[]
  status: fromInterface.loadingType
}

const initialState: PeerAffinityLevelState = {
  items: [],
  status: fromInterface.STATUS.LOADING,
}

const peerAffinityLevelSlice = createSlice({
  name: "peer affinity level",
  initialState,
  reducers: {
    fetchRequest: state => {
      state.status = fromInterface.STATUS.LOADING
    },
    fetchSuccess: (state, action) => {
      const payload = action.payload

      state.items = payload
      state.status = fromInterface.STATUS.IDLE
    },
    fetchComplete: state => {
      state.status = fromInterface.STATUS.IDLE
    },
    clear: state => {
      state.items = []
      state.status = fromInterface.STATUS.IDLE
    },
  },
})

export const { fetchRequest, fetchSuccess, fetchComplete, clear } =
  peerAffinityLevelSlice.actions

export default peerAffinityLevelSlice.reducer
