// @ts-nocheck 
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {getCourseResultDetailById} from "../../helpers/action";
import CourseScoreDetail from "./courseScoreDetail";
import CoursePage from "./coursePage";
import EmployeeDetail from "./employeeDetail";
import Loaders from "components/loaders";

const CourseResultDetail = ({selectedCourseResult}) => {
  const dispatch = useDispatch();
  const {courseResultStat, coursePageQuestions, requesting} = useSelector(
    (state: any) => state.lmsReducer
  );

  useEffect(() => {
    dispatch(getCourseResultDetailById(selectedCourseResult.Id));
  }, []);

  if (requesting) {
    return (
      <Loaders cardType="course-result" loading={true} name="card" type="skeleton" />
    );
  }

  return (
    <>
      <EmployeeDetail detail={courseResultStat} />
      <CourseScoreDetail
        detail={courseResultStat}
        selectedCourseResult={selectedCourseResult}
      />
      <CoursePage coursePageQuestions={coursePageQuestions} />
    </>
  );
};

export default CourseResultDetail;
