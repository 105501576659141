import React, { memo } from "react";
import { ConnectForm, Input, ModalForm } from "@rigotech/ui";
import { Box, Heading } from "@chakra-ui/react";

interface paramType {
  note: string;
}

interface CheckInModalProps {
  handleClose: () => {};
  isOpen: boolean;
  saveAttendanceNote: (isCheckIn: boolean, param: paramType) => {};
  saveAttendanceTime: (isCheckIn: boolean) => {};
}

const CheckInModal = ({
  handleClose,
  isOpen,
  saveAttendanceNote,
  saveAttendanceTime,
}: CheckInModalProps) => {
  const handleSubmit = async (data: any) => {
    if (data?.note) {
      const param = { note: data?.note };
      await saveAttendanceNote(true, param);
    }
    await saveAttendanceTime(true);
  };

  return (
    <ModalForm
      isCentered
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={handleSubmit}
      submitButtonLabel="Submit"
      renderHeader={() => {
        return (
          <Box>
            <Heading color="#121212" fontSize="24px" fontWeight="500">
              Clock In
            </Heading>
          </Box>
        );
      }}
    >
      <ConnectForm>
        {(formProps: any) => {
          const {
            control,
            formState: { errors },
          } = formProps;

          const inputProps = {
            control,
            errors,
          };

          return <Input.TextArea label="Note" name="note" {...inputProps} />;
        }}
      </ConnectForm>
    </ModalForm>
  );
};

export default memo(CheckInModal);
