// @ts-nocheck 
import configureStore from "../store/configureStore";
import isEmpty from "isEmpty";
const store = configureStore;

// interface featuresInterface {
//     features : featureInterface[]
// }

interface featureInterface {
  Feature: string;
  Flag: boolean;
}

export const Features = (): any => {
  const { featureFlags } = store.getState().permissionReducer;
  let features = {};

  if (!isEmpty(featureFlags)) {
    featureFlags.map((feature: featureInterface) => {
      features[feature.Feature] = feature.Feature;
    });
  }
  return features;
};

export const Permissions = [
  "DisciplinaryActions.create",
  "DisciplinaryActions.update",
  "DisciplinaryActions.delete",
  "DisciplinaryActions.view",
  "TravelManagement.create",
  "TravelManagement.update",
  "TravelManagement.delete",
  "TravelManagement.view",
  "JobProfile.create",
  "JobProfile.update",
  "JobProfile.delete",
  "JobProfile.view",
  "Try.create",
];

export const UserPermissions = [
  "DisciplinaryActions.create",
  "DisciplinaryActions.update",
  "DisciplinaryActions.delete",
  "DisciplinaryActions.view",
  "TravelManagement.create",
  "TravelManagement.update",
  "TravelManagement.delete",
  "TravelManagement.view",
  "DisciplinaryStages.create",
  "JobProfile.create",
  "JobProfile.update",
  "JobProfile.delete",
  "JobProfile.view",
  "Try.create",
];

const convertToObject = (permissions: string[]) => {
  let a = permissions;
  let b = {};
  a.map((item) => {
    let c = item.split(".");
    if (!b[c[0]]) {
      b[c[0]] = [];
      b[c[0]] = [...b[c[0]], c[1]];
    } else {
      b[c[0]] = [...b[c[0]], c[1]];
    }
  });
  return b;
};

export const getPermissionsObject = (type: "all" | "user" = "all"): any => {
  if (type === "all") {
    return convertToObject(Permissions);
  }
  if (type === "user") {
    return convertToObject(UserPermissions);
  }
  return [];
};

// export const Fetaures = {
//     Default:'Default',
//     DisciplinaryAction:'DisciplinaryAction',
//     TravelManagement:'TravelManagement',
//     TaskTracker:'TaskTracker',
//     LeaveAndTime:'LeaveAndTime',
//     JobProfile:'JobProfile'
// }
