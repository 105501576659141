import { ApiClient } from "../../../network/apiClient";
import { FETCH_PMS_ANALYTICS } from "./api";
import * as fromSlice from "./slice";
import * as fromModels from '@rigotech/hrms-model'
import * as fromSelectors from './selectors';

export const fetchPMSAnalyticsinitialPageData = (params: any) => (dispatch: any) => {
  dispatch(fetchPMSAnalytics())
}

export const clearInitialPageData = () => (dispatch: any) => {
  dispatch(clearPmsAnalytics())
}

export const fetchPMSAnalytics =
  () => async (dispatch: any, getState: any) => {
    const state = getState()
    const { filters } = fromSelectors.selectCurrentSlice(state);

    if (!filters?.reviewCycleId?.value) {
      return
    }

    const param = {
      ReviewCycleId: filters?.reviewCycleId?.value,
      formId: filters?.formId?.value || null,
      BranchId: filters?.branchId?.value || null,
      DepartmentId: filters?.departmentId?.value || null,
    }

    dispatch(fromSlice.fetchRequest());
    const { Data, Status }: any = await ApiClient.post<any>({
      endpoint: FETCH_PMS_ANALYTICS,
      dispatch: dispatch,
      body: param
    });

    if (Status) {
      dispatch(fromSlice.fetchSuccess(Data || []));
    }
  };


export const updateFilters = (newFilters: any) => async (dispatch: any, getState: any) => {
  dispatch(fromSlice.updateFilters(newFilters));
};

export const clearPmsAnalytics =
  () => async (dispatch: any) => {
    dispatch(fromSlice.clear());
  };
