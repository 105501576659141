import { ApiClient } from '../../../network/apiClient';
import * as fromInterface from '../../../ts';
import { FETCH_PERFORMANCE_REVIEW_COMMENTS } from './api';
import * as fromSlice from './slice';
import * as fromSelectors from '.';
import { createNextState } from '@reduxjs/toolkit';

export const fetchCommentsByReviewId =
  (params: { reviewId: any }): any =>
  async (dispatch: any, getState: any) => {
    dispatch(fromSlice.fetchRequest());

    const response: fromInterface.ResponseWrapper<any> =
      await ApiClient.get<any>({
        endpoint: `${FETCH_PERFORMANCE_REVIEW_COMMENTS}/${params.reviewId}`,
        dispatch: dispatch,
      });

    const { Status, Data } = response;
    if (Status) {
      return dispatch(fromSlice.fetchSuccess(Data));
    }
  };

export const syncComments =
  (comments: fromInterface.PerformanceReviewComment[]): any =>
  async (dispatch: any, getState: any) => {
    const state = getState();
    const oldComments =
      fromSelectors.PerformanceReviewCommentStore.selectAllComments(state);

    const nextComents = createNextState(oldComments, (draftState: any) => {
      comments?.forEach(newComment => {
        const foundInOldCommentIndex = draftState.findIndex(
          (draftComment: any) => {
            const isSameReview = draftComment.ReviewId === newComment.ReviewId;
            const isSameSection =
              draftComment.SectionId === newComment.SectionId;
            const isSameContent =
              draftComment.SectionContentId === newComment.SectionContentId;
            const isSameOption = newComment.SectionContentOptionId
              ? newComment.SectionContentOptionId ===
                draftComment.SectionContentOptionId
              : true;
            const isSameGoalDatabaseId = newComment.GoalDatabaseId
              ? newComment.GoalDatabaseId === draftComment.GoalDatabaseId
              : true;

            return (
              isSameReview &&
              isSameSection &&
              isSameContent &&
              isSameOption &&
              isSameGoalDatabaseId
            );
          },
        );

        if (foundInOldCommentIndex !== -1) {
          draftState[foundInOldCommentIndex] = {
            ...draftState[foundInOldCommentIndex],
            ...newComment,
          };
        } else {
          draftState.push({
            Id: 0,
            ReviewId: newComment.ReviewId,
            PerfomanceFormId: 0,
            RolloutId: 0,
            SectionId: newComment.SectionId,
            SectionContentId: newComment.SectionContentId,
            Comment: newComment.Comment,
            CreatedOn: '',
            EmployeeId: null,
            EmployeeName: '',
            UrlPhoto: '',
            IsManager: false,
          });
        }
      });
    });

    dispatch(fromSlice.fetchSuccess(nextComents));
  };

export const clearPerformanceReviewComments =
  (): any => async (dispatch: any) => {
    dispatch(fromSlice.clear());
  };
