import * as fromSlice from "./slice";

export const selectCurrentGoalDatabaseFilterSlice = (state: any): fromSlice.GoalDatabaseFilterState => {
    return state.dataStore.goalDatabaseFilter;
};

export const selectCurrentGoalDatabaseFilter = (state: any): fromSlice.GoalDatabaseFilterState => {
    return selectCurrentGoalDatabaseFilterSlice(state);
};

