// @ts-nocheck 
import React, { useState, useRef } from "react";
import Table from 'components/customTable'

function Tried() {
  const [data, setData] = useState([
      {RowNum:2,FullName:"Sa",CardNumber:'New'}
  ])
  const beforecolumns = [
    {
        Header: 'Id',
        accessor: 'RowNum',
        width: 80,
    },
    {
        Header: 'Full Name',
        accessor: 'FullName',
        width: 200,
        isEditable:true,
        isSort: true
    },
    {
        Header: 'TIMS Card',
        accessor: 'CardNumber', // Access Field
        width: 200,
        minWidth:100,
        maxWidth:500,
        isEditable:true, // If Column is editable
        editableType:'select', // Editable type for now just input and select, by default its select
        selectOptions:[{label:'Sth',value:1},{label:'Sth@',value:2}], // select options for select input for inline edit
        isSort: true
    },
];
    const updateMyData = (data) => {
        console.log({data})
    }
    const onSave = (data) => {
        console.log({data})
    }
  return (
    <div>
        <Table 
            isRowSelect  // Add Checkbox iinside table for selecting multiple rows
            onRowSelect={onSave} // Return Selected Rows after change in row selection ,
            onRowClick={onSave}  // on Row Click 
            isFixedWidth 
            isEditable // Enable inline Edit
            onSaveData={onSave} // After change in inline edited data
            columns={beforecolumns} // Columns Settings
            updateMyData={updateMyData} // Update Row data during onchange inside inline data
            data={data} // Data list
        />
    </div>
  );
}


export default Tried;


// export default "";
