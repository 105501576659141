import { ApiClient } from "../../../network/apiClient";
import * as fromPMSDashboardStore from "../pms-dashboard";
import { PUBLISH_SCORE } from "./api";

export const publishOrUnpublishScore: any =
  (params: { ReviewCycleId: number; FormId: number; Publish: boolean }) =>
    async (dispatch: any, getState: any) => {
      const { ReviewCycleId, FormId, Publish } = params;

      const response: any = await ApiClient.put({
        endpoint: `${PUBLISH_SCORE}/${FormId}/${ReviewCycleId}?publish=${Publish}`,
        dispatch: dispatch,
        body: null,
      });

      return response?.Status

    };
