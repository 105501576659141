import {
    GET_STOP_PAYMENT,
    SEARCHED_STOP_PAYMENT,
    EDIT_STOP_PAYMENT,
    ADD_STOP_PAYMENT,
    DELETE_STOP_PAYMENT,
    STOP_PAYMENT_LOADING
} from "../../../actions/types";

const initialState = {
    searchStopPayment: [],
    searchedPage: { index: 0, total: 0 },
    loading: false,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case STOP_PAYMENT_LOADING:
            return {
                ...state,
                loading: true
            };
        case EDIT_STOP_PAYMENT:
            return {
                ...state,
                searchStopPayment: state.searchStopPayment.map(
                    (salary, i) =>
                        salary.StopPaymentId === action.id
                            ? action.payload
                            : salary
                )
            };
        case ADD_STOP_PAYMENT:
            return {
                ...state,
                searchStopPayment: [action.payload, ...state.searchStopPayment]
            };
        case SEARCHED_STOP_PAYMENT:
            return {
                ...state,
                searchStopPayment: action.payload,
                searchedPage: {
                    index: action.page.index,
                    total: action.page.total
                },
                loading: false
            };
        case DELETE_STOP_PAYMENT:
            return {
                ...state,
                searchStopPayment: state.searchStopPayment.filter(
                    salary => salary.StopPaymentId !== action.id
                )
            };
        default:
            return state;
    }
}
