//UserGroup
export const userGroupAPI = {
  groups: "security/user-groups",
  groupStatus: "security/user-groups/update-status/userGroupId",
  updateGroupMembers: "security/user-groups/find-group-members",
  userGroupDropDown: "security/user-groups/drop-down",
  individualGroupMembers: "security/user-groups/userGroupId/members",
};

export const commonAPI = {
  branches: "common/branches",
  departments: "common/departments",
  status: "common/shared/status",
  level: "common/levels",
};
