
import AprPerformanceReviewStatusEnum from "../../../../enums/appraisal/AprPerformanceReviewStatus";
import { ApiClient } from "../../../../network/apiClient";
import { PERFORMANCE_EVALUATION } from "../api";
import * as fromSelector from "../selectors";


export const savePerformance = ({ payload }: { payload: any }) => async (dispatch: any, getState: any) => {

    const state = getState();

    const reviewDetail = fromSelector.selectReview(state);
    const currentStep = fromSelector.selectCurrentStep(state);

    const { ReviewInfo } = reviewDetail;

    const basePayload = {
        step: currentStep?.displayNumber,
        status: currentStep.isLastStep ? AprPerformanceReviewStatusEnum.Submitted : AprPerformanceReviewStatusEnum.Pending,
        ...(currentStep.isLastStep && { signature: true }),
    }


    const finalPayload = {
        ...payload,
        PerformanceFormRolloutId: ReviewInfo?.PerformanceRolloutId,
        RatingScaleId: ReviewInfo?.RatingScaleId ?? 1,
        ...basePayload,
    }

    console.log(JSON.stringify(finalPayload, null, 2))

    const response: any = await ApiClient.post<any>({
        endpoint: PERFORMANCE_EVALUATION,
        dispatch: dispatch,
        body: finalPayload
    })

    const { Status } = response;
    // if (Status) {
    //     dispatch(fetchPerformanceReview({ reviewId: payload.Id }))
    // }
}