// @ts-nocheck
import React, { Component, useEffect, useState } from "react";
// import isEmpty from "~/helpers/isEmpty";
import { withRouter } from "react-router-dom";
import Login from "scenes/auth/login";
import { Switch } from "react-router-dom";
import Logout from "scenes/auth/signout";
import Navigate from "./navigate";
import { RouteNest } from "components/routes/nestedRoutes";
import { useSelector } from "react-redux";
import LoadingHOC from "src/hoc/loadingHOC";
// import Try from "./navigate/try";
import EmpHeader from "scenes/common/header";
import Footer from "components/layout/footer";
import { Features } from "helpers/getPermissions";
import Try from "./navigate/try";
import ExportPdf from "components/common/exportPdf";
import SwitchUser from "scenes/auth/switchUser";
import ResetPassword from "scenes/auth/resetPassword";
import RouteEnum from "constants/routeEnum";
import routesList from "./routes";
import CreateNewTravelRequest from "scenes/travel/common/travelInformation/createTravelRequest/createNewTravelRequest";
import NotFound from "components/common/404";
import ResetOldHash from "scenes/auth/resetOldHash";
import Links from "src/components/layout/sidebar/links";
import SchedulePdf from "scenes/roster/schedule/schedules/schedulePdf";
import CallbackOauth from "scenes/callbackOauth/callback-auth";
import sidebar from "../components/layout/sidebar";
import Layout from "./layout";
import TwoFactorScreen from "scenes/auth/twoFactorAuth";

const renderEnvBaseSidebar = () => {
  const { REACT_APP_ENVIRONMENT } = process.env;

  if (
    REACT_APP_ENVIRONMENT === "development" ||
    REACT_APP_ENVIRONMENT === "staging"
  ) {
    // return newSidebar;
    return sidebar;
  }
  return sidebar;
};

const Routes = () => {
  const features = Features();
  const { featureFlags } = useSelector((state: any) => state.permissionReducer);
  useEffect(() => { }, [featureFlags]);

  let routes = routesList(features);
  return (
    <RouteNest feature={"Default"} path='/' isNotPrivate component={Layout}>
      <Switch>
        <RouteNest
          path={RouteEnum.Signout}
          exact
          title='Logout'
          isNotPrivate
          feature={"Default"}
          component={Logout}
        />
        <RouteNest
          path={RouteEnum.TwoFactorAuthPath}
          exact
          title='Two Factor Authenticator'
          feature={"Default"}
          isNotPrivate
          component={TwoFactorScreen}
        />
        <RouteNest
          path={RouteEnum.ResetOldHashPath}
          exact
          title='Reset Old Password'
          feature={"Default"}
          component={ResetOldHash}
        />
        <RouteNest
          path={RouteEnum.LoginPath}
          exact
          title='Login'
          isNotPrivate
          feature={"Default"}
          component={Login}
        />

        <RouteNest
          path={RouteEnum.CallbackOauth}
          exact
          title='Callback Oauth'
          isNotPrivate
          feature={"Default"}
          component={CallbackOauth}
        />

        <RouteNest
          path={RouteEnum.ResetPassword}
          exact
          title='Reset Password'
          key='reset'
          isNotPrivate
          feature={"Default"}
          component={ResetPassword}
        />
        <RouteNest
          path={RouteEnum.Switchuser}
          exact
          title='Switch User'
          feature={"Default"}
          isNotPrivate
          component={SwitchUser}
        />
        <RouteNest
          path='/try'
          feature={"Default"}
          exact
          title='Try'
          isNotPrivate
          isDev
          component={Try}
        />
        <RouteNest
          path='/'
          feature={"Default"}
          exact
          title='Home'
          component={Navigate}
        />
        <RouteNest
          path={RouteEnum.ExportPdfPath}
          key='export pdf'
          feature={"Default"}
          title='Export PDF'
          component={ExportPdf}
        />
        <RouteNest
          path={RouteEnum.ExportRosterPdfPath}
          key='export pdf'
          feature={"Default"}
          title='Export Roster PDF'
          component={SchedulePdf}
        />

        <RouteNest
          path={`${RouteEnum.Links}/:feature/:category`}
          exact
          title='Links'
          feature={"Default"}
          isAdmin={true}
          component={Links}
        />
        {routes.map((route: any, index) => {
          if (route.children) {
            return (
              <RouteNest key={index + Math.round(1)} {...route}>
                {route.children.map((item: any, ind) => {
                  if (
                    item.path ===
                    RouteEnum.roosterSchedulePdfRoutes +
                    "/:teamid/:startDate:/:endDate"
                  ) {
                    console.log({ item });
                  }
                  let a = (
                    <RouteNest
                      key={ind + Math.round(1)}
                      {...item}
                      feature={route.feature}
                    />
                  );
                  if (item.isTravelAnimate) {
                    const comp = Object.assign({}, item);
                    item.component = AnimatedTravel;
                    a = (
                      <RouteNest
                        key={ind + Math.round(1)}
                        {...item}
                        feature={route.feature}
                        Comp={comp.component}
                      />
                    );
                  }
                  return a;
                })}
              </RouteNest>
            );
          }
          return <RouteNest key={index + Math.round(1)} {...route} />;
        })}
        <RouteNest feature={"Default"} exact path='*' component={NotFound} />
        <RouteNest feature={"Default"} component={NotFound} />
      </Switch>
    </RouteNest>
  );
};

const AnimatedTravel = props => {
  const { Comp, history, ...rest } = props;
  return <CreateNewTravelRequest {...rest} history={history} />;
};

// const Layout = (props) => {
//   const { isAuthenticated, user } = useSelector((state: any) => state.auth)
//   const layoutClasses = classnames('layout-body ', {
//     'layout-employee': isAuthenticated && user.RoleId === 2,
//   })

//   const { baseUrl } = props
//   const isEmployee = user?.IsEmployeeType;
//   const pad = { paddingBottom: 0 }
//   if (isAuthenticated && user && user.RoleName === 'OnboardingEmployee') {
//     if (
//       props.location.pathname == '/login' ||
//       props.location.pathname == '' ||
//       props.location.pathname == '/employee'
//     )
//       props.history.push('/employeeonboard')
//     return (
//       <div>
//         {user.RoleId !== 1 ? (
//           <div className="emp-dashboard">
//             {' '}
//             <EmpHeader />
//           </div>
//         ) : (
//           <EmpHeader />
//         )}
//         {/* <div className="emp-dashboard"><div className="wrap-container">{props.children}</div></div> */}
//         <div className="emp-dashboard">{props.children}</div>
//       </div>
//     )
//   } else if (!isAuthenticated) {
//     return props.children
//   } else if(isEmployee){
//     return null
//   } else
//     return (
//       <div className="layout layout-sidebar">
//         <CommonLayout for="Admin" baseUrl={baseUrl} component={renderEnvBaseSidebar()} />
//         <div
//           className={layoutClasses}
//           style={
//             isAuthenticated
//               ? user.RoleId === 1
//                 ? pad
//                 : { backgroundColor: '#eff3f9' }
//               : pad
//           }
//         >
//           <CommonLayout for="Employee" component={EmpHeader} />

//           <CommonLayout for="Admin" component={Header} />
//           {user.RoleId !== 1 ? (
//             <div className="emp-dashboard">{props.children}</div>
//           ) : (
//             props.children
//           )}
//         </div>
//         <CommonLayout for="Employee" component={Footer} />
//       </div>
//     )
// }

// interface layoutInterface {
//   for: 'Employee' | 'Admin' | 'OnboardingEmployee' | 'Both'
//   component: any
//   baseUrl?: any
//   isSideBarOpen?: boolean
// }

// const CommonLayout = (props: layoutInterface) => {
//   const user = useSelector((state: any) => state.auth.user)
//   const [role, setRole] = useState(0)

//   const isAuthenticated = useSelector(
//     (state: any) => state.auth.isAuthenticated,
//   )
//   const fea = useSelector(
//     (state: any) => state.permissionReducer.activeFeatureFlags,
//   )

//   const features = Features()

//   useEffect(() => {
//     if (!isEmpty(user)) {
//       setRole(user.RoleId)
//     } else {
//       setRole(0)
//     }
//   }, [user])

//   useEffect(() => {
//     Features()
//   }, [fea])

//   if (!isAuthenticated) {
//     return null
//   }

//   const { for: compFor, component, ...rest } = props
//   // let roled:any[number] = roles();
//   switch (compFor) {
//     case 'Employee':
//       // return role != 1 && role > 1 && !roled.includes(role) ? <props.component /> : null;
//       return role === 2 ? <props.component {...rest} /> : null
//     case 'Admin':
//       return role !== 2 ? (
//         <props.component {...rest} features={features} />
//       ) : null
//     case 'OnboardingEmployee':
//       return role === 43 ? <props.component {...rest} /> : null
//     case 'Both':
//       return <props.component />
//     default:
//       return null
//   }
// }

export default withRouter(LoadingHOC(Routes));
