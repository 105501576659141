// @ts-nocheck 
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import "./scrollbar.scss";
interface CustomScrollbarProps {
    children: any
}
const CustomScrollbar = ({ children }: CustomScrollbarProps) => {
    return (
        <Scrollbars
            className="custom-scrollbars"
            renderThumbVertical={props => <div className="thumb"  {...props} />}
        >
            {children}
        </Scrollbars>
    )
}
export default CustomScrollbar