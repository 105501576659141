import * as fromInterface from "../../../ts";
import * as fromPerformanceReviewBase from "../performance-review-base";

const performanceReviewEvaluationSlice = fromPerformanceReviewBase.createGenericSlice({
    name: 'performance review evaluation',
    initialState: {} as fromPerformanceReviewBase.GenericState<fromInterface.PerformanceReview>,
    reducers: {

    },
})

export const {
    fetchRequest,
    fetchSuccess,
    fetchError,
    fetchSelfRequest,
    fetchSelfSuccess,
    setStep,
    syncReviewDetail,
    clear,
} = performanceReviewEvaluationSlice.actions

export default performanceReviewEvaluationSlice.reducer
