import * as fromInterface from "../../../ts";
import { PerformanceFormState } from "./slice";

const performanceFormCurrentSlice = (state: any): PerformanceFormState => {
    return state.dataStore.performanceForm;
};

export const selectPerformanceFormSelectStatus = (state: any): fromInterface.loadingType => {
    return performanceFormCurrentSlice(state).status;
};

export const selectPerformanceFormSelectLoaded = (state: any): boolean => {
    return performanceFormCurrentSlice(state).loaded;
};

export const selectPerformanceFormSelectItems = (state: any) => {
    return performanceFormCurrentSlice(state).items;
};

export const selectPerformanceFormSelectFilters = (state: any) => {
    return performanceFormCurrentSlice(state).filters;
};

export const selectPerformanceFormSelectPageInfo = (state: any) => {
    return performanceFormCurrentSlice(state).pageInfo;
};
