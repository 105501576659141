import { z } from "zod";

export const CommentSchema = z.object({
  Id: z.number(),
  ReviewId: z.number(),
  PerfomanceFormId: z.number(),
  RolloutId: z.number(),
  SectionId: z.number(),
  SectionContentId: z.number(),
  SectionContentOptionId: z.null(),
  GoalDatabaseId: z.number(),
  Comment: z.string(),
  CreatedOn: z.string(),
  EmployeeId: z.number(),
  EmployeeName: z.string(),
  UrlPhoto: z.string(),
  IsManager: z.boolean(),
});
export type IComment = z.infer<typeof CommentSchema>;

export const CommentsSchema = z.array(CommentSchema);

export type IComments = z.infer<typeof CommentsSchema>
