
// SLICE
import * as fromSlice from "./slice";
import * as _ from "lodash"

// STORES
import * as fromInterface from "../../../../ts";
import * as fromGlobalFilterStore from "../../global-filters";
import * as filterStore from "../filter";
import * as fromGlobalfilterStore from "../../global-filters";

// NETWORK
import { ApiClient } from "../../../../network/apiClient";
import { DELETE_PERFORMANCE_FORM, DUPLICATE_PERFORMANCE_FORM, PERFORMANCE_FORM_LIST } from "./api";

export const fetchPerforanceFormListPageData = () => (dispatch: any) => {
    dispatch(fromGlobalFilterStore.fetchReviewCycleFilterDropDown())
    dispatch(fetchPerformanceForms())
}

export const clearPerforanceFormListPageData = () => (dispatch: any) => {
    dispatch(clearPerformanceFormList())
}


export const fetchPerformanceForms: any = (): any => async (dispatch: any, getState: any) => {

    dispatch(fromSlice.fetchRequest())

    const state = getState()
    const filters = fromGlobalfilterStore.selectFilters(state)
    // const filters = _.pickBy(_.cloneDeep(fromGlobalfilterStore.selectFilters(state)), _.identity);

    const mappedFilters = {
        reviewCycleId: filters?.reviewCycleId?.value ?? 0,
        assessmentOptionType: 1,
        formName: filters?.formName,
        status: filters?.status?.value ?? "",
        pageIndex: filters?.pageIndex ?? 0,
        pageSize: filters?.pageSize ?? 10,
    }

    const response: fromInterface.ResponseWrapper<any> = await ApiClient.post<any>({
        endpoint: PERFORMANCE_FORM_LIST,
        body: mappedFilters,
        dispatch: dispatch
    })
    const { Data, Status } = response

    if (Status) {
        dispatch(fromSlice.fetchSuccess(Data))
    }
}

export const removePerformanceForm = ({ formId }: { formId: any }) => async (dispatch: any) => {
    const response: any = await ApiClient.remove<any>({
        endpoint: DELETE_PERFORMANCE_FORM.replace(":formId", formId),
        dispatch: dispatch
    })

    if (response?.Status) {
        dispatch(fetchPerformanceForms())
    }
    return response?.Status
}


export const duplicatePerformanceForm = ({ formId }: { formId: any }) => async (dispatch: any) => {
    const response: any = await ApiClient.put<any>({
        endpoint: DUPLICATE_PERFORMANCE_FORM.replace(":formId", formId),
        dispatch: dispatch
    })

    if (response?.Status) {
        dispatch(fetchPerformanceForms())
    }
    return response?.Status
}

export const clearPerformanceFormList = (): any => async (dispatch: any, getState: any) => {
    dispatch(fromSlice.clear())
}