import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../../network/axiosBaseQuery";
import { FETCH_PERFORMANCE_PREVIEW } from "./api";


// const checkResponseError = (response) => {
//     if (response.Status === false) {
//         toastMessage("dispatchrenetaw", "error ayo ne taw");
//         throw new Error(response.Message);
//     }
// };



export const performanceFormPreviewApi = createApi({
    baseQuery: axiosBaseQuery(),
    endpoints: (builder) => ({
        getPreview: builder.query<any, any>({
            query: (formId: number) => ({ url: FETCH_PERFORMANCE_PREVIEW.replace(":formId", formId + ""), method: "get" }),
            transformResponse: (response: any) => {
                // if (!response.Status) {
                //     throw new Error(response.Message);
                // }
                return response?.Data;
                // {
                //     ...response,
                //     Data: {
                //         MyUpdate: response.Data.MyUpdate,
                //         RequestCount: response.Data.RequestCount,
                //     },
                // };
            },
        }),


    }),
});

export const {
    useGetPreviewQuery,
} = performanceFormPreviewApi;