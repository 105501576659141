
export const selectGoaldAndActionsPageData = (section: any) => (state: any): any => {

    const {
        Id,
        PerformanceFormId,
        SectionName,
        IntroText,
        RatingScaleOptions,
        Contents,
        AssessmentType
    } = section

    const contentIdx = 0;
    const GoalActionOptions = Contents[contentIdx]?.GoalActionOptions;
    const reviewDetails = GoalActionOptions.map(({
        Id,
        Goal,
        GoalDatabaseId,
        ContentId,
        Target,
        Achievement,
        SectionId,
        RatingScaleOptionId
    }: any, gaaIdx: number) => {

        return {
            SectionId: SectionId,
            SectionContentId: ContentId,
            SectionContentOptionId: Id,
            RatingScaleOptionId: RatingScaleOptionId,
            // selfMarkingValue: selfReview?.Sections?.[sectionIdx as number].Contents[contentIdx].GoalActionOptions[gaaIdx].RatingScore,
            GoalAction: {
                GoalDatabaseId,
                Target,
                Achievement
            },


            // REQUIRED TO DISPLAY
            Goal,
        }
    })

    // =======================
    // DERIVE COMMENTS
    // =======================
    // const { SectionId, ContentId } = GoalActionOptions[0]
    // let Comments: any = mapComments(SectionId, ContentId, state, ReviewInfo);

    // =======================
    // END DERIVE COMMENTS
    // =======================

    const mapped = {
        Id,
        PerformanceFormId,
        PerformanceRolloutId: 0,
        reviewDetails,
        Comments: [],
        signature: false,

        // REQUIRED TO DISPLAY
        SectionName,
        IntroText,
        RatingScaleOptions,
        AssessmentType,
        AllowComments: Contents[contentIdx].AllowComments
    }
    return mapped


};
