import * as fromInterface from "../../../ts";
import * as fromConstant from "../../../constants";
import { ApiClient } from "../../../network/apiClient";
import * as fromSlice from "./slice";
import { getLocationAttendanceReport } from "./api";

export const fetchLocationAttendanceReport =
  (data?: any) => async (dispatch: any, getState: any) => {
    if (data?.isExport) {
      await ApiClient.exportFile_post<any>({
        endpoint: getLocationAttendanceReport,
        dispatch: dispatch,
        body: { ...data },
        convertXlsx: true,
        showLoader: true,
      });
    } else {
      dispatch(fromSlice.fetchRequest());
      const response: any = await ApiClient.post({
        endpoint: getLocationAttendanceReport,
        dispatch: dispatch,
        body: { ...data },
      });
      dispatch(fromSlice.fetchSuccess(response?.Data));

      return response;
    }
  };

export const clearLocationAttendanceReport = () => (dispatch: any) => {
  dispatch(fromSlice.clear());
};
