import {
  SAVE_CHECKLIST_TEMPLATE,
  CHECKLIST_TEMPLATE_LIST,
  DELETE_SAVE_CHECKLIST_TEMPLATE,
} from "actions/types";

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_CHECKLIST_TEMPLATE: {
      return {
        ...state,
        saveChecklistTemplate: true,
      };
    }
    case CHECKLIST_TEMPLATE_LIST: {
      return {
        ...state,
        checklistTemplateList: action.payload,
      };
    }
    case DELETE_SAVE_CHECKLIST_TEMPLATE: {
      return {
        ...state,
        deleteSalaryTemplate: true,
      };
    }
    default:
      return state;
  }
}
