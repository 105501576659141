import { useRef } from "react";

//COMPONENTS
import TableBodyContent from "./tableBodyContent";
import TableFooterContent from "./tableFooterContent";
import TableHeaderContent from "./tableHeaderContent";

//CUSTOM HOOKS
import useScrollRight from "../customHooks/useScrollRight";

//CHAKRA
import { Box } from "@chakra-ui/react";

//INTERFACE
import { ITableContentProps } from "../ts";

const TableContent = (props: ITableContentProps) => {
  const ref = useRef<any>(0);
  const stickyWidthRef = useRef<number[]>([0]);

  const { scrollRight, onScroll } = useScrollRight(ref);

  const calculateStickyWidth = (index: number, width: number) => {
    if (index === 0) {
      stickyWidthRef.current = [0];
      stickyWidthRef.current.push(width);
    } else {
      const nextWidthValue = stickyWidthRef.current[index] + width;
      stickyWidthRef.current.push(nextWidthValue);
    }
  };

  return (
    <Box
      className='data-table_container'
      bg='#fff'
      border='1px solid #CCD5DF'
      borderRadius='5px'
      {...{ ref, onScroll }}
    >
      <table
        cellSpacing='0'
        cellPadding={0}
        className={`${props.borderVariant}`}
        {...{
          style: {
            width: "100%",
          },
        }}
      >
        <TableHeaderContent
          {...props}
          {...{ scrollRight, calculateStickyWidth }}
          stickyWidth={stickyWidthRef.current}
        />
        <TableBodyContent
          {...props}
          {...{ scrollRight, calculateStickyWidth }}
          stickyWidth={stickyWidthRef.current}
        />
        {props.showFooter ? <TableFooterContent {...props} /> : null}
      </table>
    </Box>
  );
};

export default TableContent;
