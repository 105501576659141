// @ts-nocheck 
import {Skeleton} from "@material-ui/lab";
import React from "react";

interface ButtonsSkeletonProps {
  numberOfBtns?: number | string;
}

const ButtonsSkeleton: React.FC<ButtonsSkeletonProps> = ({numberOfBtns}) => {
  const renderBtnSkeleton = () => {
    return [...Array(numberOfBtns)].map((btn, i) => (
      <Skeleton key={i} animation="wave" height={50} width="100px" />
    ));
  };

  return (
    <div className="flex flex-gap-10">
      {renderBtnSkeleton()}
      {/* <Skeleton animation="wave" height={50} width="100px" style={{marginRight: 10}} />
      <Skeleton animation="wave" height={50} width="100px" style={{marginRight: 10}} />
      <Skeleton animation="wave" height={50} width="100px" /> */}
    </div>
  );
};

ButtonsSkeleton.defaultProps = {
  numberOfBtns: 1,
};

export default ButtonsSkeleton;
