import { Button, Flex } from '@chakra-ui/react';
import TimePicker from './time-picker';
import React from 'react';
/* eslint-disable-next-line */
export interface TimePickerFinalProps {
  name: any;
  value: any;
  onChange2: any;
  onChangeRHF: any;
  ignoreControl?: any;
  inputName?: any;
  customLabel: any;
  setError?: any;
  disabledHrs?: any;
}

export function TimePickerFinal(props: TimePickerFinalProps) {
  const {
    name,
    value,
    onChange2,
    onChangeRHF,
    customLabel,
    ignoreControl,
    inputName,
    disabledHrs,
    setError,
    ...rest
  } = props;
  
  const handleChange = (finalValue: any) => {
    onChange2?.(name, finalValue);
    onChangeRHF?.(finalValue);
  };

  return (
    <TimePicker
      value={value}
      onChange={handleChange}
      inputProps={{
        w: "full"
      }}
      use12Hr
      {...rest}
      disabledHrs={disabledHrs}
    />

  );
}

export default TimePickerFinal;


