import React from "react"

// COMPONENTS
import Checkboxgroup from "../checkbox-group/checkbox-group"
import Checkbox from "../checkbox/checkbox"
import ReactSelect from "../react-select/react-select"
import TextInput from "../text-input/text-input"
import RadioGroup from "../radio-group/radio-group"
import RadioGroupV2 from "../radio-group-v2/radio-group-v2"
import DatePicker from "../react-date-picker/react-date-picker"
import ReactSelectAsync from "../react-select-async/react-select-async"
import FroalaEditor from "../froala-editor/froala-editor"
import TextArea from "../text-area/text-area"

const Input = () => {
  return null
}

Input.TextInput = TextInput
Input.TextArea = TextArea
Input.FroalaEditor = FroalaEditor
Input.ReactSelect = ReactSelect
Input.ReactSelectAsync = ReactSelectAsync
Input.Checkboxgroup = Checkboxgroup
Input.Checkbox = Checkbox
Input.RadioGroup = RadioGroup
Input.RadioGroupV2 = RadioGroupV2
Input.DatePicker = DatePicker

export { Input }
