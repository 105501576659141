import * as fromInterface from "../../../ts";
import { createSteps } from "./helper/create-steps";
import { createSlice, SliceCaseReducers, ValidateSliceCaseReducers } from "@reduxjs/toolkit";
import { GenericState } from "./generic-state";

/**
 * USUAGE 
 
const wrappedSlice = createGenericSlice({
    name: 'test',
    initialState: { status: 'loading' } as GenericState<string>,
    reducers: {

    },
})

export const {
    fetchRequest,
    fetchSuccess,
    fetchError,
    fetchSelfRequest,
    fetchSelfSuccess,
    setStep,
    syncReviewDetail,
    clear,
} = wrappedSlice.actions

export default wrappedSlice.reducer

*/
export const createGenericSlice = <T, Reducers extends SliceCaseReducers<GenericState<T>>>({
    name = '',
    initialState,
    reducers,
}: {
    name: string
    initialState: GenericState<T>
    reducers: ValidateSliceCaseReducers<GenericState<T>, Reducers>
}) => {
    return createSlice({
        name,
        initialState,
        reducers: {
            fetchRequest: (state) => {
                state.status = fromInterface.STATUS.LOADING;
            },

            fetchSuccess: (state, action) => {
                const payload: any = action.payload

                const { ReviewInfo, Evaluation } = payload;
                const { RatingScaleOptions } = ReviewInfo


                const { newSteps, currentStep }: any = createSteps(payload)

                let newEvaluation = Evaluation
                if (Evaluation) {

                    const ratingScaleFound = RatingScaleOptions.find((rs: any) => {
                        return rs.value === Evaluation.RatingScaleOptionId
                    })
                    newEvaluation = { ...Evaluation, ratingScaleOptionId: ratingScaleFound }
                }

                state.item = { ...payload, Evaluation: newEvaluation };
                state.steps = newSteps;
                state.currentStep = currentStep;

                state.status = fromInterface.STATUS.IDLE;
                state.loaded = true

            },

            fetchError: (state) => {
                state.item = {};
                state.status = fromInterface.STATUS.IDLE;
                state.loaded = true
            },

            fetchSelfRequest: (state) => {
                state.selfStatus = fromInterface.STATUS.LOADING;
            },

            syncReviewDetail: (state, action) => {
                state.item = action.payload;
            },

            fetchSelfSuccess: (state, action) => {
                const payload = action.payload

                state.selfItem = payload
                state.selfStatus = fromInterface.STATUS.IDLE;
                state.selfLoaded = true
            },

            setStep: (state, action) => {
                const payload = action.payload

                state.currentStep = payload.currentStep;
                state.steps = payload.steps;
            },

            clear: (state) => {
                state.item = {}
                state.status = fromInterface.STATUS.IDLE
                state.loaded = false
            },
            ...reducers,
        },
    })
}


