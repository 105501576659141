import { createSlice } from "@reduxjs/toolkit";

import {
  fetchRoles,
  fetchIndividualRole,
  fetchRoleAssignedToUserGroup,
  fetchUsersOptions,
  fetchRoleTargetPopulationOption,
  fetchCustomPermission,
} from "./actions";

import { LoadingStatus } from "../../../constants";

export interface IRolesState {
  loading: {
    all: boolean;
    individual: boolean;
  };
  roles: any;
  individualRole: any;
  usersOptions: any;
  tpOptions: any;
  customPermission: any;
}

const initialState: IRolesState = {
  loading: {
    all: LoadingStatus.idle,
    individual: LoadingStatus.idle,
  },
  roles: null,
  individualRole: null,
  usersOptions: [],
  tpOptions: [],
  customPermission: null,
};

export const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    clearRoles: (state) => initialState,

    clearIndividualRole: (state) => {
      state.individualRole = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRoles.pending, (state) => {
      state.loading.all = LoadingStatus.loading;
    });
    builder.addCase(fetchRoles.fulfilled, (state, action) => {
      state.loading.all = LoadingStatus.idle;
      state.roles = action.payload;
    });

    builder.addCase(fetchIndividualRole.pending, (state) => {
      state.loading.individual = LoadingStatus.loading;
    });
    builder.addCase(fetchIndividualRole.fulfilled, (state, action) => {
      state.loading.individual = LoadingStatus.idle;
      state.individualRole = action.payload;
    });
    builder.addCase(fetchIndividualRole.rejected, (state) => {
      state.loading.individual = LoadingStatus.idle;
    });

    builder.addCase(fetchRoleAssignedToUserGroup.pending, (state) => {
      state.loading.individual = LoadingStatus.loading;
    });
    builder.addCase(fetchRoleAssignedToUserGroup.fulfilled, (state, action) => {
      state.loading.individual = LoadingStatus.idle;
      state.individualRole = action.payload;
    });
    builder.addCase(fetchRoleAssignedToUserGroup.rejected, (state) => {
      state.loading.individual = LoadingStatus.idle;
    });

    builder.addCase(fetchUsersOptions.fulfilled, (state, action) => {
      state.usersOptions = action.payload;
    });

    builder.addCase(fetchRoleTargetPopulationOption.pending, (state) => {
      state.loading.individual = LoadingStatus.loading;
    });
    builder.addCase(
      fetchRoleTargetPopulationOption.fulfilled,
      (state, action) => {
        state.loading.individual = LoadingStatus.idle;
        state.tpOptions = action.payload;
      }
    );
    builder.addCase(fetchRoleTargetPopulationOption.rejected, (state) => {
      state.loading.individual = LoadingStatus.idle;
    });

    builder.addCase(fetchCustomPermission.pending, (state) => {
      state.loading.individual = LoadingStatus.loading;
    });
    builder.addCase(fetchCustomPermission.fulfilled, (state, action) => {
      state.customPermission = action.payload;
      state.loading.individual = LoadingStatus.idle;
    });
    builder.addCase(fetchCustomPermission.rejected, (state) => {
      state.loading.individual = LoadingStatus.idle;
    });
  },
});

export const { clearRoles,clearIndividualRole } = rolesSlice.actions;

export default rolesSlice.reducer;
