// @ts-nocheck 
import commonReducer from "./common";
import recruitment_commonReducer from "./recruitment/common";
import recruitment_dashboardReducer from "./recruitment/dashboard";
import recruitment_reportReducer from "./recruitment/report";
import recruitment_settingReducer from "./recruitment/setting";
import recruitment_rejectReducer from "./recruitment/reject";
import recruitment_interviewReducer from "./recruitment/interview";
import recruitment_jobReducer from "./recruitment/job";
import favLinkReducer  from "./favLink"

export const newReducers = {
  //commonReducer,
  recruitment_commonReducer,
  recruitment_dashboardReducer,
  recruitment_reportReducer,
  recruitment_settingReducer,
  recruitment_rejectReducer,
  recruitment_interviewReducer,
  recruitment_jobReducer,
  favLinkReducer
};
