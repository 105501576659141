// @ts-nocheck 
import React from 'react'
import classnames from 'classnames';
import TabItemContent from './tabItemContent';
import TabItemHeader from './tabItemHeader';

function TabItem(props: any) {
    const { color, noBackground } = props;
    const tabsClass = classnames({
        tabs: true,
        "tabs-primary": color === "primary",
        "tabs-danger": color === "danger",
        "tabs-success": color === "success",
        "tabs-warning": color === "warning",
        "tabs-no-bg": noBackground,
        // "vertical-tabs": "verticalTabs",
    });
    return (
        <div className={tabsClass}>
            <ul className="tabs-list">
                {/* TabItemHeader renders Label part of tab */}
                <TabItemHeader {...props} />
            </ul>
            <div className="tabs-content">
                {/* TabItemHeader renders tab content of active tab */}
                <TabItemContent {...props} />
            </div>
        </div>
    )
}

export default TabItem
