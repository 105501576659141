import { ApiClient } from "../../../network/apiClient";
import * as fromSlice from "./slice";
import * as dataStore from "../../../index"
import { performanceRolloutAppraisal } from "./api";
import * as fromInterface from "../../../ts";
import { AprPerformanceFormReviewType, AprPerformanceReviewFlow } from "../../../enums/appraisal";


export const savePerformanceRolloutAppraisal = (data: fromInterface.PerformanceRolloutAppraisal) => async (dispatch: any, getState: any) => {
    dispatch(fromSlice.fetchRequest())
    const param = {
        endpoint: performanceRolloutAppraisal,
        body: data,
        dispatch: dispatch
    }
    const response = await ApiClient.post(param)
    dispatch(fromSlice.fetchSucces())

    return response.Status
}

export const handleChangePerformanceRolloutAppraisal = (name: string, value: any): any => async (dispatch: any, getState: any) => {
    const state = getState()
    let item: any = { ...dataStore.selectPerformanceRolloutAppraisalItem(state), [name]: value };

    if (name === "ReviewWorkflowId" && value?.revieFlow !== AprPerformanceReviewFlow.ManagerReviewOnly) {
        item.SelfReviewDeadline = ""
    }
    else if (name === 'ReviewCycleId') {

        console.log(name, item)
        item.PerformanceFormId = null
    }

    dispatch(fromSlice.updateItem(item))

    if (name === 'ReviewCycleId') {
        const reviewCycleId = Number(value?.value || value?.Value || 0)
        await dispatch(
            dataStore.fetchPerformanceFormDropDown(reviewCycleId, AprPerformanceFormReviewType.StandardReview),
        );
        //update select employee filter and fetch new employee list
        const newFilters = { ...dataStore.selectPerformanceRolloutEmployeeFilter(state), "reviewCycleId": value };
        dispatch(dataStore.udpatePerformanceRolloutEmployeeFilter(newFilters))
        // dispatch(dataStore.fetchPerformanceRolloutEmployees())
    }

}

export const udpatePerformanceRolloutAppraisal = (obj: fromInterface.PerformanceRolloutAppraisal): any => (dispatch: any, getState: any) => {
    dispatch(fromSlice.updateItem(obj))
}

export const resetPerformanceRolloutAppraisalEmployeeIds = () => (dispatch: any) => dispatch(fromSlice.resetEmployeeIds())

export const clearPerformanceRolloutAppraisal = () => (dispatch: any) => {
    dispatch(fromSlice.clear())
    dispatch(dataStore.clearPerformanceRolloutEmployeeFilter())
    dispatch(dataStore.clearPerformanceRolloutEmployeeItems())
}