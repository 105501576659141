import * as fromSlice from "./slice";
import * as fromSelector from "./selectors"
import { ApiClient } from "../../../network/apiClient";
import { rolloutEmployeeManagers } from "./api";


export const fetchRolloutManagerDropDown = () => async (dispatch: any, getState: any) => {
    const state = getState();
    const isLoaded = fromSelector.selectRolloutManagerDropdownLoaded(state)
    if (!isLoaded) {
        dispatch(fromSlice.fetchRequest())
      
        const response: any = await ApiClient.get({
            endpoint: rolloutEmployeeManagers,
            dispatch: dispatch
        })

        if (response?.Status) {
            dispatch(fromSlice.fetchSuccess(response.Data))
        }

        return response
    }
    return fromSelector.selectRolloutManagerDropdownItems(state)
}

export const clearManagerDropdown = () => (dispatch: any) => dispatch(fromSlice.clear())
