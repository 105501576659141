import React from "react";

import { ModalFooter, ModalFooterProps } from "@chakra-ui/react";
interface CommonModalFooterProps extends ModalFooterProps {
}
export const CommonModalFooter = (props: CommonModalFooterProps) => {
    const { children, ...rest } = props;

    return <ModalFooter borderTop="1px solid" borderColor="gray.200" marginTop="15px" {...rest}>{children}</ModalFooter>;
}

export default CommonModalFooter;
