// @ts-nocheck 
import {
  GET_EMAIL_TEMPLATE,
  GET_CANDIDATE_EMAILS,
  GET_CANDIDATE_COMMENTS,
} from "store/types";

const initialState = {
  emailTemplates: [],
  candidateEmails: [],
  candidateComments: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EMAIL_TEMPLATE: {
      return {
        ...state,
        emailTemplates: action.payload,
      };
    }

    case GET_CANDIDATE_EMAILS: {
      return {
        ...state,
        candidateEmails: action.payload,
      };
    }

    case GET_CANDIDATE_COMMENTS: {
      return {
        ...state,
        candidateComments: action.payload,
      };
    }

    default:
      return state;
  }
}
