// @ts-nocheck 
import React from 'react';
import {
  MdArrowForward,
  MdAdd,
  MdAddCircle,
  MdEdit,
  MdDelete,
  MdCheck,
  MdClose,
  MdCheckCircle,
  MdPauseCircleFilled,
  MdPause,
  MdPlayArrow,
  MdKeyboardArrowDown,
  MdKeyboardArrowRight,
  MdKeyboardArrowLeft,
  MdInfo,
  MdCancel,
  MdExpandMore,
  MdExpandLess,
} from 'react-icons/md';
import {
  FaUserFriends,
  FaUserEdit,
  FaRegCopy,
  FaUmbrellaBeach,
  FaFileInvoice,
  FaMinusCircle,
  FaRegFilePdf,
  FaRegFileImage,
  FaRegFileWord,
  FaCheckCircle,
  FaTimes,
  FaShare,
  FaAward,
  FaTimesCircle,
  FaChevronCircleLeft,
} from 'react-icons/fa';
import {
  AiOutlineSearch,
  AiFillAppstore,
  AiOutlineStar,
  AiFillStar,
  AiOutlineUnorderedList,
} from 'react-icons/ai';
import { IoIosPlayCircle, IoMdStopwatch, IoIosList } from 'react-icons/io';
import { FiArrowUpRight, FiFileText } from 'react-icons/fi';
import { GoCheck, GoChevronDown, GoComment } from 'react-icons/go';
import { MdAccessTime } from 'react-icons/md';
import { FcOvertime } from 'react-icons/fc';
import { AiOutlineDollar } from 'react-icons/ai';
import {
  BsThreeDots,
  BsThreeDotsVertical,
  BsArrowsAngleExpand,
  BsArrowsAngleContract,
  BsChevronRight,
  BsChevronLeft,
  BsFilter,
} from 'react-icons/bs';
import { ImAttachment } from 'react-icons/im';
import { BiBook } from 'react-icons/bi';
import { RiBarChartFill } from 'react-icons/ri';
import { ReactComponent as RoundEditIcon } from 'assets/images/icons/round-edit.svg';
import { ReactComponent as RoundDeleteIcon } from 'assets/images/icons/round-delete.svg';
import { ReactComponent as Trash } from 'assets/images/icons/trash.svg';
import { ReactComponent as ForwardShare } from 'assets/images/icons/forwardShare.svg';
import { ReactComponent as EyeView } from 'assets/images/icons/eye.svg';
import { GrFormView } from 'react-icons/gr';
import { IoStarOutline, IoStarSharp } from 'react-icons/io5';
import { VscListSelection } from 'react-icons/vsc';
interface Icons {
  name: string;
  size?: number;
  color?: string;
  className?: string;
  styles?: object;
  justSVG?: boolean;
}

function getIconComponent(name) {
  switch (name) {
    case 'ArrowExpand':
      return BsArrowsAngleExpand;
    case 'ArrowCollapse':
      return BsArrowsAngleContract;
    case 'AngleRight':
      return BsChevronRight;
    case 'AngleLeft':
      return BsChevronLeft;
    case 'ArrowForward':
      return MdArrowForward;
    case 'Approve':
      return FaCheckCircle;
    case 'Attachment':
      return ImAttachment;
    case 'AddCircle':
      return MdAddCircle;
    case 'ArrowUpRight':
      return FiArrowUpRight;
    case 'ArrowDown':
      return MdKeyboardArrowDown;
    case 'ArrowLeft':
      return MdKeyboardArrowLeft;
    case 'ArrowRight':
      return MdKeyboardArrowRight;
    case 'Add':
      return MdAdd;
    case 'Allowance':
      return AiOutlineDollar;
    case 'Back':
      return FaChevronCircleLeft;
    case 'BarChart':
      return RiBarChartFill;
    case 'Book':
      return BiBook;
    case 'BoldCheck':
      return GoCheck;
    case 'Cancel':
      return FaMinusCircle;
    case 'Cross':
      return FaTimes;
    case 'Comment':
      return GoComment;
    case 'Check':
      return MdCheck;
    case 'Close':
      return MdClose;
    case 'ChevronDown':
      return GoChevronDown;
    case 'Copy':
      return FaRegCopy;
    //#region Circle
    case 'CircleCheck':
      return MdCheckCircle;
    case 'CirclePlay':
      return IoIosPlayCircle;
    case 'CirclePause':
      return MdPauseCircleFilled;
    case 'CircleMinus':
      return FaMinusCircle;

    case 'Doc':
      return FaRegFileWord;
    case 'Delete':
      return MdDelete;
    case 'EyeView':
      return EyeView;
    case 'Edit':
      return MdEdit;
    case 'Ellipse':
      return BsThreeDots;
    case 'ThreeDotsVertical':
      return BsThreeDotsVertical;
    case 'File':
      return FaFileInvoice;
    case 'Filter':
      return BsFilter;
    case 'FavBorder':
      return IoStarOutline;
    case 'Fav':
      return IoStarSharp;
    case 'FaAward':
      return FaAward;
    case 'FaCross':
      return FaTimesCircle;
    case 'File':
      return FiFileText;
    case 'ForwardShare':
      return ForwardShare;
    case 'Info':
      return MdInfo;
    case 'Grab':
      return AiFillAppstore;
    case 'Image':
      return FaRegFileImage;
    case 'IosList':
      return IoIosList;
    case 'Leave':
      return FaUmbrellaBeach;
    case 'List':
      return AiOutlineUnorderedList;
    case 'ListSelection':
      return VscListSelection;
    case 'Play':
      return MdPlayArrow;
    case 'Pause':
      return MdPause;
    case 'RoundEdit':
      return RoundEditIcon;
    case 'RoundDelete':
      return RoundDeleteIcon;
    case 'Search':
      return AiOutlineSearch;
    case 'StopWatch':
      return IoMdStopwatch;
    case 'Time':
      return MdAccessTime;
    case 'Overtime':
      return FcOvertime;
    case 'Pdf':
      return FaRegFilePdf;
    case 'Reject':
      return MdCancel;
    case 'ExpandMore':
      return MdExpandMore;
    case 'ExpandLess':
      return MdExpandLess;
    case 'Share':
      return FaShare;
    case 'Trash':
      return Trash;
    case 'Users':
      return FaUserFriends;
    case 'UserEdit':
      return FaUserEdit;
    case 'View':
      return GrFormView;
    //#endregion
    default:
      return null;
  }
}

function Icons(props: Icons) {
  const { name, size, color, className, styles, justSVG, ...rest } = props;

  let ReactIcon = getIconComponent(name);

  const finalStyles = {
    display: 'flex',
    fontSize: size,
    color: color,
    ...styles,
  };

  if (justSVG) {
    return <ReactIcon />;
  }
  return (
    <span style={finalStyles} className={className} {...rest}>
      <ReactIcon />
    </span>
  );
}

Icons.defaultProps = {
  name: '',
  size: 20,
  color: '',
  className: '',
  styles: '',
};

export default Icons;
