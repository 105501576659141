// @ts-nocheck 
import {

  FAV_LINK,
  Toggle_FAV_LINK
} from "store/types";

const initialState = {
  favLinks: [],
  favDashboardLinks: []
};

export default function (state: any = initialState, action: any) {
  switch (action.type) {
    case Toggle_FAV_LINK: {

      const { IsAdded, FeatureLink } = action.payload
      let favLinks = [...state.favLinks]

      if (IsAdded) {
        favLinks.push(FeatureLink)
      }
      else {
        let index = favLinks.findIndex(x => x.Link === FeatureLink.Link)
        if (index > -1) {
          favLinks.splice(index, 1)
        }
      }
      return {
        ...state,
        favLinks,
      };
    }


    case FAV_LINK:
      return {
        ...state,
        favLinks: action.payload,
      };


    default:
      return state;
  }
}
