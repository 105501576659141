import { createSlice } from "@reduxjs/toolkit";
import * as fromInterface from "../../../ts";

export interface MyActivityState {
    selfReviews: any[],
    teamReviews: any[],
    status: fromInterface.loadingType;
    loaded: boolean;
}

export interface ReivewActivityState {
    activeActivities: MyActivityState
    historyActivities: MyActivityState
}

const initialState: ReivewActivityState = {
    activeActivities: {
        selfReviews: [],
        teamReviews: [],
        status: fromInterface.STATUS.LOADING,
        loaded: false
    },
    historyActivities: {
        selfReviews: [],
        teamReviews: [],
        status: fromInterface.STATUS.LOADING,
        loaded: false
    },
};

const MyReviewActivitySlice = createSlice({
    name: "My Review Activity",
    initialState,
    reducers: {
        fetchActiveActivitiesRequest: (state) => {
            state.activeActivities.status = fromInterface.STATUS.LOADING;
        },
        fetchActiveActivitiesSuccess: (state, action) => {
            const payload = action.payload

            state.activeActivities.selfReviews = payload.SelfReviews;
            state.activeActivities.teamReviews = payload.TeamReviews;
            state.activeActivities.status = fromInterface.STATUS.IDLE;
            state.activeActivities.loaded = true

        },
        clearActiveActivities: (state) => {
            state.activeActivities.selfReviews = [];
            state.activeActivities.teamReviews = [];
            state.activeActivities.status = fromInterface.STATUS.IDLE
            state.activeActivities.loaded = false
        },

        fetchHistoryActivitiesRequest: (state) => {
            state.historyActivities.status = fromInterface.STATUS.LOADING;
        },
        fetchHistoryActivitiesSuccess: (state, action) => {
            const payload = action.payload
            state.historyActivities.selfReviews = payload.SelfReviews;
            state.historyActivities.teamReviews = payload.TeamReviews;
            state.historyActivities.status = fromInterface.STATUS.IDLE;
            state.historyActivities.loaded = true

        },
        clearHistoryActivities: (state) => {
            state.historyActivities.selfReviews = [];
            state.historyActivities.teamReviews = [];
            state.historyActivities.status = fromInterface.STATUS.IDLE
            state.historyActivities.loaded = false
        },
    },
});

export const {
    fetchActiveActivitiesRequest,
    fetchActiveActivitiesSuccess,
    clearActiveActivities,

    fetchHistoryActivitiesRequest,
    fetchHistoryActivitiesSuccess,
    clearHistoryActivities,
} = MyReviewActivitySlice.actions

export default MyReviewActivitySlice.reducer;