//@ts-nocheck
import React, { useState, useRef, useEffect } from 'react'
import certificateHeader from 'assets/images/CertificateText.svg';
import logo from 'assets/images/rigo_logo.png'
import { useDispatch, useSelector } from "react-redux";
import ImageRenderer from "../../../common/imageRenderer";
import dayjs from 'dayjs';
import Button from 'components/button';
import {exportComponentAsPNG,exportComponentAsPDF} from 'helpers/exportComponent';
import { getFullURL } from "helpers/fullURL";
import axios from 'axios'
import {downloadCourseCertificatePdf} from '../../../helpers/action'

const getBase64 = async(url)=>{
    try {
      let image = await axios.get(url, { responseType: 'arraybuffer' });
      let raw = Buffer.from(image.data).toString('base64');
      return "data:" + image.headers["content-type"] + ";base64,"+raw;
    } catch (error) {
        console.log(error)
    } 
}

function CourseCertificate(props) {
    const {} = props;
    // const [companyLogo,setCompanyLogo] = useState(logo);
    // const [signatureImage,setSignatureImage] = useState(null);
    var baseUrl = getFullURL(useSelector, "");
    const dispatch = useDispatch()
    const { user } = useSelector((state: any) => state.auth);
    const { course, courseCompleted,courseSettings } = useSelector((state: any) => state.lmsReducer);
    const companyInformation = useSelector((state: any) => state.commonReducer.companyInformation);
    const ref = useRef();
    const courseSetting = courseSettings.length > 0 ? courseSettings[courseSettings.length - 1] : null;
    // const getImageUrl = async(url) =>{
    //     const newUrl = await getBase64(url);
    //     return newUrl;
    // }
    // useEffect(()=>{
    //     (async()=>{
    //         if(companyInformation?.CompanyLogo){
    //             const dataImage = await getImageUrl(companyInformation?.CompanyLogo);
    //             setCompanyLogo(dataImage)
    //         }
    //         if(courseSetting){
    //             if(courseSetting?.SignatureImage){
    //                 const dataImage = await getImageUrl( baseUrl + courseSetting?.SignatureImage);
    //                 setSignatureImage(dataImage)
    //             }
    //         }
    //     })()
    // },[companyInformation,courseSetting]);
    if(!courseSetting){
        return null;
    }

    const companyLogo = companyInformation?.CompanyLogo ? companyInformation?.CompanyLogo : null;
    const signatureImage = courseSetting?.SignatureImage ?  baseUrl + courseSetting?.SignatureImage : null;
    
    const footerText = courseSetting?.FooterText ? courseSetting.FooterText.split("\n") : [];
    const onPdfDownload = () => {
        dispatch(downloadCourseCertificatePdf({courseId:course?.CourseId,employeeId:user?.EmployeeId}))
    }
    return (
        <>
        <div className='lms-certificate__block'>
            <div className="lms-certificate__container">
                <div ref={ref} className="lms-certificate">
                    <div className="lms-certificate__header">
                        <div className="lms-certificate__header-text">
                            <img src={certificateHeader} alt="Learning Certificate Text" />
                        </div>
                        <div className="lms-certificate__header-logo">
                            <img src={companyLogo} alt="Learning Company Logo" ></img>
                        </div>
                    </div>
                    <div className="lms-certificate__body">
                        <div className="lms-certificate__body-sub">
                            <span>THE CERTIFICATE IS PRESENTED TO </span>
                        </div>
                        <div className="lms-certificate__body-employee">
                            <span>{user?.EmployeeName}</span>
                            {/* <span>Mr {user?.EmployeeName}</span> */}
                        </div>
                        <div className="lms-certificate__body-sub">
                            <span>
                                for completion of {courseSetting?.CompanyName ?? companyInformation?.Name ?? ""}'s online course on 
                                <b> "{course?.Title}" </b> 
                                on {dayjs(course?.StatusChangedOn).format("ll")}.
                            </span>
                        </div>
                    </div>
                    <div className="lms-certificate__footer">
                        <div className="lms-certificate__sign">
                            <div className="lms-certificate__sign-image">
                                <img 
                                   src={signatureImage} alt="Learning Company Logo" ></img> 
                            </div>
                            <div className="lms-certificate__sign-line"></div>
                        </div>
                        <div className="lms-certificate__footer-text">
                            {
                                footerText && footerText.map(item=><div>{item}</div>)
                            }
                        </div>
                    </div>
                </div>
                <div className="lms-certificate__btn mt-sm center-text">
                    {/* <Button bg="secondary" onClick={()=>exportComponentAsPNG(ref, { html2CanvasOptions: {backgroundColor: null} ,fileName:`${course?.Title.split(" ").join("-")}-course.png`})}>Download as image</Button> */}
                    <Button bg="secondary" 
                        // onClick={()=>exportComponentAsPDF(ref,{pdfOptions:{
                        //     // orientation: "landscape",
                        //     // unit: "px",
                        //     // w:950,
                        //     // h:620,
                        //     // format: [4, 2]
                        //     usePdfSize:true
                        // },fileName:`${course?.Title.split(" ").join("-")}-course.pdf`})}
                        onClick={onPdfDownload}
                        >Download as PDF</Button>
                </div>
            </div>
        </div>
        </>
        
    )
}

export default CourseCertificate