import { createSlice } from "@reduxjs/toolkit";
import * as fromInterface from "../../../ts";

export interface StatusState {
    items: any,
    status: fromInterface.loadingType;
    loaded: boolean;
}

const initialState: StatusState = {
    items: [],
    status: fromInterface.STATUS.LOADING,
    loaded: false,
};

const StatusSlice = createSlice({
    name: "status",
    initialState,
    reducers: {
        fetchRequest: (state) => {
            state.status = fromInterface.STATUS.LOADING;
        },
        fetchSuccess: (state, action) => {

            const payload = action.payload

            state.items = payload;
            state.status = fromInterface.STATUS.IDLE;
            state.loaded = true

        },
        clear: (state) => {
            state.items = []
            state.status = fromInterface.STATUS.IDLE
            state.loaded = false
        },
    },
});

export const {
    fetchSuccess,
    fetchRequest,
    clear,
} = StatusSlice.actions

export default StatusSlice.reducer;
