import React from "react";
import OnlyDev from "components/common/onlyDev";
import SideBarLink from "./sideBarLink";
const SideLink = ({ isDev, ...rest }) => {
  // console.log({feature},isFeatureEnabledValue(feature))

  if (isDev) {
    return (
      <OnlyDev>
        <SideBarLink {...rest} />
      </OnlyDev>
    );
  }
  return <SideBarLink {...rest} />;
};
export default SideLink;
