import React, { memo, useMemo } from "react";
import { Flex } from "@chakra-ui/react";
import { Button, ModalForm } from "@rigotech/ui";

const AssignModal = ({
  confirm,
  isPrimarButtonType,
  onConfirmed,
  onModalClose,
}) => {
  const isPrimary = useMemo(() => isPrimarButtonType(), []);
  console.log({ isPrimary });

  return (
    <ModalForm
      isCentered
      isOpen={confirm.open}
      onClose={onModalClose}
      onSubmit={onConfirmed}
      title={confirm.title}
      renderModalFooter={() => (
        <Flex>
          <Button buttonType="secondary" mr="12px" onClick={onModalClose}>
            Cancel
          </Button>
          <Button type="submit" buttonType={isPrimary ? "primary" : "danger"}>
            {confirm.okButtonText}
          </Button>
        </Flex>
      )}
    >
      {confirm.message}
    </ModalForm>
  );
};

export default memo(AssignModal);
