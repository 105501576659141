import { ApiClient } from "../../../network/apiClient";
import * as fromSlice from "./slice";
import * as fromSelector from "./selectors"
import { perfomanceFormDropItems } from "./api";


export const fetchPerformanceFormDropDown = (reviewCycleId: number, formType: number, loadAll?: boolean) => async (dispatch: any, getState: any) => {

    dispatch(fromSlice.fetchRequest())
    const response: any = await ApiClient.get({
        endpoint: `${perfomanceFormDropItems}/${reviewCycleId}/${formType}?loadAll=${loadAll || false}`,
        dispatch: dispatch
    })

    if (response?.Status) {
        dispatch(fromSlice.fetchSuccess(response.Data))
    }

    return response

}

export const clearPerformanceFormDropdown = () => (dispatch: any) => dispatch(fromSlice.clear())
