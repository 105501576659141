// @ts-nocheck 
import {
    GET_NOTIFICATIONS,
    GET_NOTIFICATIONS_LOGS,
    SET_NOTIFICATIONS_READ,
    SET_NOTIFICATIONS_LOADING
  } from "../../actions/types";

const initialState = {
    notifications: [],
    currentLogs:[],
    loading:false,
};


export default function(state = initialState, action) {
    switch (action.type) {
        case SET_NOTIFICATIONS_LOADING:
            return{
                ...state,
                loading:true
            }
        case GET_NOTIFICATIONS:
            return{
                ...state,
                notifications:action.payload,
                loading:false
            }
        case GET_NOTIFICATIONS_LOGS:
            return{
                ...state,
                currentLogs:action.payload,
            }
        case SET_NOTIFICATIONS_READ:
            return{
                ...state,
                notifications:state.notifications.map(item=>{
                    if(item.Id === action.payload.notificationId){
                        let clone = Object.assign({}, item);
                        clone.IsRead = true;
                        return clone;
                    }
                    return item;
                }),
            }
        default:
            return state;
        }
  }
  