import * as fromInterface from "../../../ts";
import { AttendanceReportFilterCountState } from "./slice";

const selectAttendanceReportFilterCountSlice = (
  state: any
): AttendanceReportFilterCountState => {
  return state.dataStore.attendanceReportFilterCount;
};

export const selectAttReportFilterCountStatus = (
  state: any
): fromInterface.loadingType => {
  return selectAttendanceReportFilterCountSlice(state).status;
};

export const selectAttReportFilterCountLoaded = (state: any): boolean => {
  return selectAttendanceReportFilterCountSlice(state).loaded;
};

export const selectAttReportFilterCountItems = (state: any) => {
  return selectAttendanceReportFilterCountSlice(state).items;
};
