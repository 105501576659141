// @ts-nocheck 
import types from "constants/types";

const initialState = {
  sbiLoanSettingList: [],
  sbiLoanPurpose: [],
  sbiLoanPurposeFormData: {
    Id: 0,
    Name: "",
  },
  sbiLoanTypeList: [],
  sbiSettingApprovalFlow: [],
  // employeeListForSbiLoanTest: [],
  sbiLoanStatusHistory: [],
  exportSbiLoanStatusHistory: [],
  sbiLoanApproversList: [],
  forwardSbiLoan: [],
  sbiLoanTypes: [],
  isEdit: false,
  spinnerLoading: false,
};

export default function (state: any = initialState, action: any) {
  switch (action.type) {
    case types.GET_SBI_LOAN_SETTING_LIST: {
      return {
        ...state,
        sbiLoanSettingList: action.payload,
      };
    }
    case types.LOAD_LOAN_PURPOSE:
      return {
        ...state,
        sbiLoanPurposeFormData: action.payload,
        loading: false,
        isEdit: false,
        spinnerLoading: false,
      }
    case types.ADD_LOAN_PURPOSE:
      return {
        ...state,
        sbiLoanPurpose: [action.payload, ...state.sbiLoanPurpose],
        loading: false,
        isEdit: false,
        spinnerLoading: false,
      };
    case types.EDIT_LOAN_PURPOSE:
      return {
        ...state,
        sbiLoanPurpose: state.sbiLoanPurpose.map((item) =>
          item.Id === action.payload.Id ? action.payload : item
        ),
        loading: false,
        isEdit: false,
        spinnerLoading: false,
      };
    case types.DELETE_LOAN_PURPOSE:
      return {
        ...state,
        sbiLoanPurpose: state.sbiLoanPurpose.filter(
          (item) => item.Id !== action.payload
        ),
        loading: false,
        spinnerloading: false,
      };
    case types.SET_EDIT_MODE:
      return {
        ...state,
        isEdit: true,
      };
    case types.GET_SBI_LOAN_PURPOSE_LIST: {
      return {
        ...state,
        sbiLoanPurpose: action.payload,
        loading: false,
        isEdit: false,
        spinnerLoading: false,
      };
    }
    case types.GET_SBI_LOAN_TYPE_LIST: {
      return {
        ...state,
        sbiLoanTypeList: action.payload,
      };
    }
    case types.GET_SBI_SETTINGS_APPROVAL_FLOW: {
      return {
        ...state,
        sbiSettingApprovalFlow: action.payload,
      };
    }
    // case types.GET_SBI_ALL_EMPLOYEES_SPECIFIC: {
    //   return {
    //     ...state,
    //     employeeListForSbiLoanTest: action.payload,
    //   };
    // }
    case types.EXPORT_SBI_LOAN_STATUS_HISTORY: {
      return {
        ...state,
        exportSbiLoanStatusHistory: action.payload,
      };
    }
    case types.GET_SBI_LOAN_STATUS_HISTORY: {
      return {
        ...state,
        sbiLoanStatusHistory: action.payload,
      };
    }
    case types.GET_SBI_LOAN_APPROVERS_LIST: {
      return {
        ...state,
        sbiLoanApproversList: action.payload,
      };
    }
    case types.FORWARD_SBI_LOAN: {
      return {
        ...state,
        forwardSbiLoan: action.payload,
      };
    }
    case types.REJECT_SBI_LOAN: {
      return {
        ...state,
        rejectSbiLoan: action.payload,
      }
    }
    case types.GET_SBI_LOAN_TYPES: {
      return {
        ...state,
        sbiLoanTypes: action.payload,
      };
    }
    default:
      return state;
  }
}
