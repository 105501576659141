import * as fromSlice from "./slice";
import * as fromSelector from "./selectors"
import * as fromDashboardFilterStore from "../dashboard-filter"
import { ApiClient } from "../../../network/apiClient";
import { todaySchedule, pendingSchedule, tomorrowSchedule, closeSchedule } from "./api";


export const fetchTodaySchedule = () => async (dispatch: any, getState: any) => {
    const state = getState();
    var filter = fromDashboardFilterStore.selectFilter(state)
    if (filter?.TeamId?.value == null)
        return

    dispatch(fromSlice.fetchTodayShift());
    const res: any = await ApiClient.get({
        endpoint: `${todaySchedule}?teamId=${filter?.TeamId?.value}`,
        dispatch: dispatch,
    });

    dispatch(fromSlice.fetchSuccesTodayShift(res?.Data));

}

export const fetchPendingSchedule = (pageIndex: number, pageSize: number) => async (dispatch: any, getState: any) => {
    const state = getState();
    var filter = fromDashboardFilterStore.selectFilter(state)
    if (filter?.TeamId?.value == null)
        return

    let prevData = []
    if (pageIndex > 1) {
        prevData = fromSelector.selectPendingShift(state)?.data ?? []
    }
    dispatch(fromSlice.fetchPendingShift());
    const res: any = await ApiClient.get({
        endpoint: `${pendingSchedule}?teamId=${filter?.TeamId?.value}&pageSize=${pageSize}&page=${pageIndex}`,
        dispatch: dispatch,
    });
    const data = { data: [...prevData, ...res?.Data], pageIndex, pageSize, showPager: res?.Data?.length > 0 }
    dispatch(fromSlice.fetchSuccesPendingShift(data));

}

export const fetchTomorrowSchedule = () => async (dispatch: any, getState: any) => {
    const state = getState();

    var filter = fromDashboardFilterStore.selectFilter(state)

    if (filter?.TeamId?.value == null)
        return


    dispatch(fromSlice.fetchTomorrowShift());
    const res: any = await ApiClient.get({
        endpoint: `${tomorrowSchedule}?teamId=${filter?.TeamId?.value}`,
        dispatch: dispatch,
    });

    dispatch(fromSlice.fetchSuccesTomorrowShift(res?.Data));

}

export const closeShiftSchedule = (shift: any) => async (dispatch: any, getState: any) => {

    const { ScheduleDate, ShiftId, TeamId } = shift
    let param = {}
    const res: any = await ApiClient.put({
        endpoint: `${closeSchedule}`,
        dispatch: dispatch,
        body: { ScheduleDate, ShiftId, TeamId }
    });
    return res?.Status
}
export const clear = () => (dispatch: any) => {
    dispatch(fromSlice.clear());
    dispatch(fromDashboardFilterStore.clear())
}
