import { createSlice } from "@reduxjs/toolkit";
import * as fromInterface from "../../../ts";

export interface PerformanceFormDropdownState {
    items: any,
    status: fromInterface.loadingType;
    loaded: boolean;
}

const initialState: PerformanceFormDropdownState = {

    items: [],
    status: fromInterface.STATUS.IDLE,
    loaded: false,
};

const performanceFormDropdownSlice = createSlice({
    name: "performance form dropdown",
    initialState,
    reducers: {
        fetchRequest: (state) => {
            state.status = fromInterface.STATUS.LOADING;
        },
        fetchSuccess: (state, action) => {

            const payload = action.payload

            state.items = payload;
            state.status = fromInterface.STATUS.IDLE;
            state.loaded = true

        },
        clear: (state) => {
            state.items = []
            state.status = fromInterface.STATUS.IDLE
            state.loaded = false
        },
    },
});

export const {
    fetchRequest,
    fetchSuccess,
    clear,
} = performanceFormDropdownSlice.actions

export default performanceFormDropdownSlice.reducer;
