import { createSlice } from "@reduxjs/toolkit";
import * as fromInterface from "../../../ts";

export interface SectionState {
    items: fromInterface.SidebarSectionListItem[],
    status: fromInterface.loadingType;
    loaded: boolean;
}

const initialState: SectionState = {
    items: [],
    status: fromInterface.STATUS.LOADING,
    loaded: false,
};

const sectionSlice = createSlice({
    name: "section",
    initialState,
    reducers: {
        fetchRequest: (state) => {
            state.status = fromInterface.STATUS.LOADING;
        },
        fetchSuccess: (state, action) => {
            const payload = action.payload

            state.items = payload;
            state.status = fromInterface.STATUS.IDLE;
            state.loaded = true

        },
        createSuccess: (state, action) => {
            const payload = action.payload

            state.items.push(payload)

        },
        updateSuccess: (state, action) => {
            const payload = action.payload

            state.items = state.items.map(item => item?.Id === payload.Id ? { ...item, ...payload } : item)

        },
        removeSuccess: (state, action) => {
            const { id } = action.payload
            state.items = state.items.filter((item: fromInterface.SidebarSectionListItem) => item?.Id !== id)
        },
        clear: (state) => {
            state.items = []
            state.status = fromInterface.STATUS.IDLE
            state.loaded = false
        },
    },
});

export const {
    fetchRequest,
    fetchSuccess,
    createSuccess,
    updateSuccess,
    removeSuccess,
    clear,
} = sectionSlice.actions

export default sectionSlice.reducer;