import React from 'react'
import Sidebar from "../../components/layout/sidebar";
import Header from 'components/layout/header'

function AdminLayout(props) {
  return (
    <div className="layout layout-sidebar">
        <Sidebar />
        <div className={'layout-body '} style={{paddingBottom: 0}}>
            <Header />
            {props.children}
        </div>
    </div>
  )
}

export default AdminLayout