// @ts-nocheck 
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAssignedCoursesDashboad } from "scenes/lms/helpers/action";
import { CourseDashboardStatus } from "scenes/lms/helpers/enums";
import AssigneCourseCard from "./assignedCourseCard";

const AssignedCourses = ({ status }) => {
  const { assignedCourseDashboard } = useSelector((state: any) => state.lmsReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    loadAssignedCourses();
  }, [status]);

  const loadAssignedCourses = () => {
    let coureStatus = status;
    if (status === CourseDashboardStatus.Assign) coureStatus = CourseDashboardStatus.All;
    dispatch(
      getAssignedCoursesDashboad({ pageIndex: 0, pageSize: 99999, status: coureStatus })
    );
  };

  return (
    assignedCourseDashboard?.length > 0 && (
      <div className="courses-assigned">
        <div className="courselist-header header-course">Courses assigned to you</div>
        <div className="courses-assigned__container">
          <ul className="courses-assigned__list">
            {assignedCourseDashboard?.map((item) => (
              <li className="courses-assigned__item">
                <AssigneCourseCard item={item} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  );
};
export default AssignedCourses;
