import React from "react";
import { ModalHeader, ModalHeaderProps } from "@chakra-ui/react";

interface CommonModalHeaderProps extends ModalHeaderProps {
}

export const CommonModalHeader = (props: CommonModalHeaderProps) => {
    const { children, ...rest } = props;
    return <ModalHeader borderBottom="1px solid" borderColor="gray.200" fontWeight="500" {...rest}>{children}</ModalHeader>;
}

export default CommonModalHeader;
