export const selectUserGroup = (state: any) =>
  state.dataStore.userGroupReducer.userGroup;

export const selectLoading = (state: any) =>
  state.dataStore.userGroupReducer.loading;

export const selectIndividualUserGroup = (state: any) =>
  state.dataStore.userGroupReducer.individualUserGroup;

export const selectBranches = (state: any) =>
  state.dataStore.userGroupReducer.branches;

export const selectDepartments = (state: any) =>
  state.dataStore.userGroupReducer.departments;

export const selectStatus = (state: any) =>
  state.dataStore.userGroupReducer.status;

export const selectLevel = (state: any) =>
  state.dataStore.userGroupReducer.levels;

export const selectUserGroupOptions = (state: any) =>
  state.dataStore.userGroupReducer.userGroupOptions;

export const selectIndividualGroupMembers = (state: any) =>
  state.dataStore.userGroupReducer.individualGroupMembers;

export const selectUpdateMemberCount = (state: any) =>
  state.dataStore.userGroupReducer.memberCounts;
