import { GenericState } from "./../../@base-store"
import * as fromModel from "@rigotech/hrms-model"

export const selectEmployeeDevelopmentPlanState = (state: any): GenericState<fromModel.IEmployeeDevelopmentPlan, fromModel.IEmployeeDevelopmentPlanFilters> => {
  return state.dataStore.employeeDevelopmentPlan;
};


export const selectItemsGrouped = (state: any): fromModel.IEmployeeDevelopmentPlan[] | null => {
  const items = selectEmployeeDevelopmentPlanState(state)?.items || null;

  if (!items) {
    return items
  }

  const groupedData: any = {};

  for (const item of items) {
    const key = item?.RolloutId;
    groupedData[key] = groupedData[key] || {
      ...item,
      DevelopmentPlans: []
    };
    groupedData[key].DevelopmentPlans.push(item.DevelopmentPlans);
  }

  const mergedData = Object.values(groupedData).map((item: any) => ({
    ...item,
    DevelopmentPlans: item.DevelopmentPlans.join(",")
  }));

  return mergedData
};