import * as fromInterface from "../../../ts";
import { SectionState } from "./slice";

const selectSectionSlice = (state: any): SectionState => {
    return state.dataStore.section;
};

export const selectSectionStatus = (state: any): fromInterface.loadingType => {
    return selectSectionSlice(state).status;
};

export const selectSectionLoaded = (state: any): boolean => {
    return selectSectionSlice(state).loaded;
};

export const selectSectionItems = (state: any): fromInterface.SidebarSectionListItem[] => {
    return selectSectionSlice(state).items;
};

export const selectNextSectionItemToDisplay = (id: any) => (state: any): number => {
    const items = selectSectionSlice(state).items

    if ((items?.length - 1) === 0) {
        return -1
    }

    if ((items?.length - 1) === 1) {
        return items?.[0].Id
    }

    const indexFound = items.findIndex(item => item.Id === id)

    const inext = items?.[indexFound + 1]?.Id
    return inext
};

export const selectNextSectionItemObjToDisplay = (id: any) => (state: any): any => {
    const items = selectSectionSlice(state).items;

    const findIndex = items.findIndex(i => i.Id === id)

    if (findIndex === -1 || items.length === 0 || (items.length === 1 && items[0].Id === id)) {
        return null
    }

    if (findIndex !== -1) {

        const hasNextItemSelected = items[findIndex + 1]

        if (hasNextItemSelected) {
            return hasNextItemSelected
        }

        const hasPreviousItem = items[findIndex - 1]

        if (hasPreviousItem) {
            return hasPreviousItem
        }

        return null
    }
    return null
}