import { createSlice } from "@reduxjs/toolkit";
import * as fromInterface from "../../../ts";

export interface PerformanceScoreState {
    list: any[] | null,
    scores: any[],
    status: fromInterface.loadingType;
    loaded: boolean;
}

const initialState: PerformanceScoreState = {
    list: null,
    scores: [],
    status: fromInterface.STATUS.LOADING,
    loaded: false,
};

const performanceScoreSlice = createSlice({
    name: "perfromance score",
    initialState,
    reducers: {
        fetchRequest: (state) => {
            state.status = fromInterface.STATUS.LOADING;
        },
        downloadSuccess: (state) => {
            state.status = fromInterface.STATUS.IDLE;
            state.loaded = true
        },
        fetchSuccessList: (state, action) => {
            const payload = action.payload
            state.list = payload;
            state.status = fromInterface.STATUS.IDLE;
            state.loaded = true

        },
        clearList: (state) => {
            state = initialState
        },

    },
});

export const {
    fetchRequest,
    fetchSuccessList,
    downloadSuccess,
    clearList
} = performanceScoreSlice.actions

export default performanceScoreSlice.reducer;