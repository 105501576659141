export const months = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
};

export const getYears = () => {
  let min = 1980;
  let years = 100;
  return Array.from(Array(years).keys()).map(index => {
    return { label: min + index, value: min + index };
  });
};

export const getDays = () => {
  let max = 31;
  return Array.from(Array(max).keys()).map(index => {
    return { label: 1 + index, value: 1 + index };
  });
};
