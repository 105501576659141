// @ts-nocheck
import { useDispatch } from "react-redux";
import { useConfirmation } from "@rigotech/ui";
import { CourseStatus } from "scenes/lms/helpers/enums";

import { updateCourseStatus } from "../../helpers/action";

export const useStatusDiscontinue = (Id, onStatusChange) => {
  const dispatch = useDispatch();
  const { openConfirm } = useConfirmation();

  const handleStatusDiscontinue = () =>
    openConfirm({
      onConfirm: async () => {
        let res = await dispatch(
          updateCourseStatus(Id, CourseStatus.Discontinue)
        );
        res && onStatusChange();
      },
      confirmationBtnName: "Discontinue",
      contentMessage: "Are you sure you want to discontinue this course?",
      title: "Discontinue",
      isCentered: true,
    });

  return {
    handleStatusDiscontinue,
  };
};
