export const selectMatrixRatingPageData = (section: any) => (state: any): any => {

    const {
        PerformanceFormId,
        SectionName,
        IntroText,
        RatingScaleOptions,
        Contents
    } = section

    const contentIdx = 0;
    const options = Contents[contentIdx]?.Options;
    const reviewDetails = options.map(({
        Id,
        Name,
        SectionId,
        ContentId,
        RatingScaleOptionId,
    }: any) => {

        return {
            sectionId: SectionId,
            sectionContentId: ContentId,
            sectionContentOptionId: Id,
            RatingScaleOptionId: RatingScaleOptionId,
            // selfMarkingValue: selfReview?.Sections?.[sectionIdx as number].Contents[contentIdx].Options[optionIdx].RatingScore ?? 0,

            // REQUIRED TO DISPLAY
            Name
        }
    })

    const mapped = {
        Id: null, // THIS IS REVIEW ID
        PerformanceFormId,
        PerformanceRolloutId: 0,
        reviewDetails,
        Comments: [],
        signature: false,

        // REQUIRED TO DISPLAY
        SectionName,
        IntroText,
        RatingScaleOptions,
        AllowComments: Contents[contentIdx].AllowComments
    }

    return mapped
};
