

import * as fromSlice from "./slice";

const selectPerformanceRolloutThreeSixtyFilterSlice = (state: any): fromSlice.PeformanceRolloutThreeSixtyFilterState =>
    state.dataStore.performanceRolloutThreeSixtyFilter;


export const selectPerformanceRolloutThreeSixtyFilter = (state: any): fromSlice.PeformanceRolloutThreeSixtyFilterState =>
    selectPerformanceRolloutThreeSixtyFilterSlice(state);

export const selectPerformanceRolloutReviewCycleThreeSixtyFilter = (state: any): any =>
    selectPerformanceRolloutThreeSixtyFilterSlice(state).reviewCycleId;

