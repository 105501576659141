import * as fromSlice from "./slice";
import * as fromInterface from "../../../ts";
const selectCurrentSlice = (
    state: any
): fromSlice.ShiftDashboardFilterState => {
    return state.dataStore.shiftDashboardFilterSlice
}

export const selectFilter = (state: any): any => {
    return selectCurrentSlice(state)?.filter;
};
