// @ts-nocheck 
import React, {useEffect, useState} from "react";
import TableView from "./tableView";
import Avatar from "react-avatar";
import {useDispatch, useSelector} from "react-redux";
import FormInput from "components/form";
import {getUnAssignedEmployeeList} from "../../helpers/action";
import {GetBranches, GetDepartments} from "actions/commonAction";
import DataSelect from "components/dataselect";

const EmpCell = (props) => {
  const {original} = props.row;
  return (
    <div className="appraisals-table__col">
      <div className="appraisals-table__emp">
        <div className="appraisals-table__emp-image">
          {/* <img src={original.Photo} alt="" /> */}
          <Avatar size="32px" round src={original.Photo} name={original.EmployeeName} />
        </div>
        <div className="appraisals-table__emp-link">{original.EmployeeName}</div>
      </div>
    </div>
  );
};

const initialFilters = {
  pageIndex: 0,
  pageSize: 10,
  employeeName: "",
  branchId: -1,
  departmentId: -1,
};
const SelectEmployee = (props: any) => {
  const {name, courses, onRowSelect} = props;
  const {filters} = props;

  const [state, setState] = useState({employees: [], selectedRows: null});
  const [branchList, setBranchhList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchEmployee(filters);
  }, []);

  const [empList, setEmpList] = useState([]);

  // const {branchList, departmentList} = useSelector((state: any) => state);
  const {commonReducer} = useSelector((state: any) => state);

  const onFilterChange = (name, value) => {
    let newFilters = {...props.filters};
    newFilters[name] = value;
    props.onFilterChange && props.onFilterChange("filters", newFilters);
    fetchEmployee(newFilters);
  };
  const onPaginatonChange = (name: any, value: any) => {
    let newFilters = {...props.filters};
    newFilters[name] = value;
    props.onChange && props.onChange("filters", newFilters);
    fetchEmployee(newFilters);
  };

  const fetchEmployee = async (params) => {
    const {pageIndex, pageSize, employeeName, branchId, departmentId} = params;
    const courseId = courses && courses.map((x) => x.Id).toString();
    var res: any = await dispatch(
      getUnAssignedEmployeeList({
        pageIndex,
        pageSize,
        employeeName,
        branchId: branchId?.value ?? -1,
        departmentId: departmentId?.value ?? -1,
        courseId,
      })
    );

    let selectedRows = getSelectedRows(params, res);
    setState({...state, employees: res, selectedRows});
  };

  const getSelectedRows = (params, employees) => {
    const {pageIndex} = params;
    let selectedData = (props.value && props.value[pageIndex]) || [];
    var rows =
      selectedData &&
      employees &&
      employees.reduce((a, b) => {
        if (!a) a = {};
        selectedData.forEach((el, index) => {
          var ind = employees.findIndex((x) => x.EmployeeId === el.EmployeeId);
          if (ind > -1) a[ind] = true;
        });
        return a;
      }, 0);
    return rows;
  };

  const handleRowSelect = (emps) => {
    props.onChange("selectedEmployees", emps);
  };

  return (
    <div>
      <div className="assign-employee__filter">
        <div className="assign-employee__row">
          <FormInput
            onChange={onFilterChange}
            name="employeeName"
            label="Search employee"
            value={filters.employeeName}
          />
        </div>
        <div className="assign-employee__row">
          <div className="assign-employee__branch-block">
            <DataSelect
              onChange={onFilterChange}
              value={filters.branchId}
              label="Branch"
              name="branchId"
              type=""
              actionType="branch"
              width="200px"
            />

            <DataSelect
              onChange={onFilterChange}
              value={filters.departmentId}
              label="Department"
              name="departmentId"
              type=""
              actionType="department"
              width="200px"
            />
          </div>
        </div>
      </div>

      <TableView
        handleRowChange={(num) => onPaginatonChange("pageSize", num)}
        handleIndexChange={(num) => onPaginatonChange("pageIndex", num)}
        pageIndex={filters.pageIndex}
        pageSize={filters.pageSize}
        onRowSelect={handleRowSelect}
        selectedRows={state.selectedRows || {}}
        isFixedWidth
        tableData={state.employees || []}
      />
    </div>
  );
};
export default SelectEmployee;
