import * as fromSlice from "./slice";

const selectCurrentPerformanceSlice = (state: any): fromSlice.PerformanceScoreState => {
    return state.dataStore.performanceScore;
};
//for table list
export const selectPerformanceListItems = (state: any): any[] | null => {
    return selectCurrentPerformanceSlice(state).list;
};

export const selectPerformanceScoreStatus = (state: any): boolean => {
    return selectCurrentPerformanceSlice(state).loaded;
};

export const selectPerformanceScoreLoaded = (state: any): boolean => {
    return selectCurrentPerformanceSlice(state).loaded;
};
