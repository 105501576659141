// @ts-nocheck 
import React, {useEffect, useState} from "react";
import Button from "components/button";
import {BsFillQuestionSquareFill} from "react-icons/bs";
import {AddContent, QuestionContent} from "../contents";
// import AddQuestions from "./addQuestions__old";
import {FiFileText} from "react-icons/fi";
import Content from "./content";
import AddQuestions from "./addQuestions";
const initialState = {showButtons: true, showContent: false, showQuestions: false};
const ActionButton = (props) => {
  const [state, setState] = useState(initialState);
  useEffect(() => {
    setState(initialState);
  }, [props.pageId]);

  const data = {
    CoursePageId: props.pageId,
    Type: 1,
    Id: 0,
    ContentOrder: 0,
    VideoDuration: 0,
    VideoLink: "",
    Description: "",
  };
  return (
    <div>
      {state.showContent && (
        <Content
          {...data}
          handleCancel={() => setState({...state, showButtons: true, showContent: false})}
        />
      )}
      {state.showQuestions && (
        <AddQuestions
          {...props}
          clear={() => {
            props.setEnableUpdateQuestion(true);
            setState({...state, showQuestions: false, showButtons: true});
          }}
        />
      )}

      {state.showButtons && props.enableUpdateQuestion && (
        <div className="action-buttons">
          <Button
            onClick={() => setState({...state, showButtons: false, showContent: true})}
            title={"Add Content"}
            buttonType="icon-button"
            // style={{ backgroundColor: "#2693FF", _hover: { background: "#0f6ac3" } }}
            bg="primary"
            icon={<FiFileText />}
          />

          <Button
            onClick={() => {
              props.setEnableUpdateQuestion(false);
              setState({...state, showButtons: false, showQuestions: true});
            }}
            title="Add Questions"
            bg="primary"
            buttonType="icon-button"
            icon={<BsFillQuestionSquareFill />}
            // style={{ backgroundColor: "#2693FF", borderRadius: 4 }}
          />
        </div>
      )}
    </div>
  );
};

export default ActionButton;
