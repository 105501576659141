
// Constants
import {
  FontSizes,
  Spacing
} from '@rigotech/hrms-data';

//Components
import { Box, Container } from '@chakra-ui/react';
import { When } from 'react-if';
import { PageHeading } from '../../atoms';
import { Breadcrumb } from '../breadcrumb/breadcrumb';

//Types
import { PageContainerProps } from './types';

export const PageContainer = (props: PageContainerProps) => {
  // VARIABLES
  const {
    children,
    showTitle = true,
    pageTitle = "",
    breadCrumbsItems = [],
    size = "md"
    , ...rest } = props;
  const isBreadcrumbs = Boolean(breadCrumbsItems?.length)
  const isTitle = Boolean(props.pageTitle)
  const sizeLarge = size === 'lg'
  const pageSpacing = sizeLarge ? Spacing.pageSpacinglarge : Spacing.pageSpacingSmall;

  return (
    <Container
      pos="relative"
      padding={pageSpacing}
      className='rigo-page'
      w="100%"
      maxW="100%"
      {...rest}
    >
      <When condition={isBreadcrumbs}>
        <Breadcrumb showTitle={showTitle && !isTitle} items={breadCrumbsItems!} />
      </When>
      
      <When condition={isTitle}>
        <PageHeading
          as='h1'
          fontSize={FontSizes.Headings.SectionHeadingLarge}
          mb={Spacing.ml}
          lineHeight='1.5'
          fontWeight={700}
          maxW='500px'
          noOfLines={1}
        >
          {props?.pageTitle}
        </PageHeading>
      </When>

      <Box className='rigo-body'>
        {children}
      </Box>
    </Container>
  );
};





