import * as fromInterface from "../../../ts";
import * as fromSlice from "./slice"

const selectPerformanceRolloutEmployeeSlice = (state: any): fromSlice.PerformanceRolloutEmployeeState => {
    return state.dataStore.performanceRolloutEmployee;
};

export const selectPerformanceRolloutEmployeeStatus = (state: any): fromInterface.loadingType => {
    return selectPerformanceRolloutEmployeeSlice(state).status;
};

export const selectPerformanceRolloutEmployeeLoaded = (state: any): boolean => {
    return selectPerformanceRolloutEmployeeSlice(state).loaded;
};

export const selectPerformanceRolloutEmployeeItems = (state: any) => {
    return selectPerformanceRolloutEmployeeSlice(state).items;
};
export const clearPerformanceRolloutEmployeeItems = () => (dispatch: any) => {
    dispatch(fromSlice.clear())
}

