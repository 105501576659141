// FOLLOWING RENDER PROPS PATTERN

import React from 'react';
import { Box, Center, Flex, Spinner } from '@chakra-ui/react';
import RigoModalNormal from '../modal/modal';
import FormProvider from '../../atoms/form/form-provider/form-provider';
import ConnectForm from '../../atoms/form/connect-form/connect-form';
import Button from '../../atoms/button/button';
import ModalSpinner from '../../atoms/modalSpinner';

interface ModalProps {
  modalProps?: any;
  renderHeader?: any;
  renderModalFooter?: any;
  isOpen?: boolean;
  isCentered?: boolean;
  onClose?: any;
  showDevTool?: any;
  editMode?: boolean;
  onSubmit?: any;
  submitButtonLabel?: string;
  defaultValues?: any;
  title?: any;
  children?: any;
  resolver?: any;
}

export const ModalForm = (props: ModalProps) => {
  const {
    modalProps,
    renderHeader,
    renderModalFooter,
    isCentered,
    isOpen,
    onClose,
    showDevTool,
    onSubmit,
    defaultValues,
    submitButtonLabel,
    title,
    editMode,
    children,
    resolver,
  } = props;

  return (
    <>
      <RigoModalNormal
        isOpen={isOpen}
        isCentered={isCentered}
        onClose={onClose}
        {...modalProps}
      >
        <RigoModalNormal.Overlay />
        <FormProvider
          showDevTool={showDevTool}
          onSubmit={onSubmit}
          defaultValues={defaultValues}
          resolver={resolver}
        >
          <RigoModalNormal.Content>
            <RigoModalNormal.CloseBtn />
            <RigoModalNormal.Header>
              {renderHeader?.() || title}
            </RigoModalNormal.Header>
            <RigoModalNormal.Body>{children}</RigoModalNormal.Body>
            <RigoModalNormal.Footer>
              <ConnectForm>
                {({ formState: { isValid, isSubmitting } }: any) => {
                  return (
                    renderModalFooter?.({ isValid, isSubmitting, onClose }) || (
                      <Flex>
                        <Button
                          buttonType='secondary'
                          mr='12px'
                          onClick={onClose}
                        >
                          Close
                        </Button>
                        <Button
                          type='submit'
                          buttonType='primary'
                          disabled={isSubmitting}
                          isLoading={isSubmitting}
                        >
                          {submitButtonLabel || (editMode ? 'Update' : 'Save')}
                        </Button>
                      </Flex>
                    )
                  );
                }}
              </ConnectForm>
            </RigoModalNormal.Footer>
          </RigoModalNormal.Content>
        </FormProvider>
      </RigoModalNormal>
    </>
  );
};

export default ModalForm;
