// @ts-nocheck 
import React from "react";
import classnames from "classnames";

interface Spinner {
  loading?: boolean;
  name?: string;
  loadingTextShow?: boolean;
  loaderSize?: string;
  loaderBackground?: string;
  backgroundColor?: string;
  className?: string;
}

const Spinner = (props: Spinner) => {
  const {
    loading,
    backgroundColor,
    loaderSize,
    name,
    loadingTextShow,
    className,
    ...rest
  } = props;

  const style = {
    width: loaderSize + "px",
    height: loaderSize + "px",
  };
  if (!loading) {
    return null;
  }
  if (name === "backdrop") {
    return (
      <div className="loader" style={{ backgroundColor: backgroundColor }}>
        <div>
          <div className="loader-icon" style={style}></div>
          {/* {loadingTextShow ? (
                    <div className="loader-text">Loading...</div>
                ) : null} */}
        </div>
      </div>
    );
  }
  return (
    <div className={className ? className : "loader-icon"} style={style}></div>
  );
};

Spinner.defaultProps = {
  loading: false,
  loaderSize: "30",
  backgroundColor: "rgba(26, 26, 26, 0.2)",
  name: "backdrop",
  loadingTextShow: true,
};

export default Spinner;
