import * as fromInterface from "../../../../ts";

/**
 * GET KSA PAGE DATA BASED ON PROVIDED SECTION
 * @param section 
 * @returns 
 */
export const selectBaseKSAPageData = (section: any) => (state: any): fromInterface.KSAPageDataSelector | null => {
    const {
        PerformanceFormId,
        SectionName,
        IntroText,
        RatingScaleOptions,
        Contents
    } = section

    const KSAOptions = Contents[0]?.KSAOptions;
    const reviewDetails = KSAOptions.map(({
        KsaName,
        SectionId,
        ContentId,
        RatingScore,
        Expected,
        KSAId
    }: any) => {

        return {
            sectionId: SectionId,
            sectionContentId: ContentId,
            RatingScore: RatingScore ?? 0,
            Ksa: {
                Ksaid: KSAId,
                RatingName: "",
                RatingScore: RatingScore ?? 0,
                Expected: Expected
            },
            // selfMarkingValue: selfReview?.Sections?.[sectionIdx as number].Contents[contentIdx].KSAOptions[ksaOptionIdx].RatingScore ?? 0,

            // REQUIRED TO DISPLAY
            KsaName,
            Expected
        }
    })

    // =======================
    // DERIVE COMMENTS
    // =======================
    // const { SectionId, ContentId } = KSAOptions[0]
    // let Comments: any = mapComments(SectionId, ContentId, state, ReviewInfo);
    // =======================
    // END DERIVE COMMENTS
    // =======================

    const mapped = {
        Id: null, // THIS IS REVIEW ID
        PerformanceFormId,
        PerformanceRolloutId: 0,
        reviewDetails,
        Comments: [],
        signature: false,

        // REQUIRED TO DISPLAY
        SectionName,
        IntroText,
        RatingScaleOptions,
        AllowComments: Contents[0].AllowComments
    }

    return mapped
};

