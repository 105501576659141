import { ApiClient } from "../../../network/apiClient";
import * as fromInterface from "../../../ts";
import { GOAL_DATABASES, GOAL_DATABASES_BULK, GOAL_DATABASE_LIST } from "./api";
import * as fromSlice from "./slice";
// STORES
import * as fromGoalDatabaseFilterStore from "./../goal-database-filter";
import * as formReviewCycleDropdownStore from "./../review-cycle-dropdown";
export const fetchGoalDatabaseInitialData = (filter?: any): any => async (dispatch: any) => {
  // dispatch(fetchGoalDatabase())
  dispatch(formReviewCycleDropdownStore.fetchReviewCycleDropDown())
  // dispatch(fromDepartmentStore.fetchDepartments())
  // dispatch(fromBranchStore.fetchBranches())
  // dispatch(fromLevlStore.fetchLevels())
}
export const clearGoalDatabaseInitialData = (filter?: any): any => async (dispatch: any) => {
  dispatch(clearGoalDatabase())
  dispatch(fromGoalDatabaseFilterStore.clearGoalDatabaseFilter())
}
export const clearGoalDatabase = (): any => async (dispatch: any, getState: any) => {
  dispatch(fromSlice.clear())
}
export const fetchGoalDatabase = (filter?: any): any => async (dispatch: any, getState: any) => {
  dispatch(fromSlice.fetchRequest())
  const state = getState()
  const currentFilterState = fromGoalDatabaseFilterStore.selectCurrentGoalDatabaseFilter(state);
  const response: fromInterface.ResponseWrapper<any> = await ApiClient.post<any>({
    endpoint: GOAL_DATABASE_LIST,
    body: {
      ...currentFilterState,
      employee: currentFilterState?.employee || "",
      reviewCycleId: currentFilterState?.reviewCycleId?.value,
      departmentId: currentFilterState?.departmentId?.value,
      levelId: currentFilterState?.levelId?.value,
      branchId: currentFilterState?.branchId?.value
    },
    dispatch: dispatch
  })
  const { Data, Status } = response
  if (Status) {
    dispatch(fromSlice.fetchSuccess(Data))
  }
}
export const saveGoalDatabase = (data?: any, cb?: any): any => async (dispatch: any, getState: any) => {
  const param = {
    endpoint: GOAL_DATABASES,
    body: {
      ...data,
    },
    dispatch: dispatch
  }
  const response: fromInterface.ResponseWrapper<any> = await ApiClient.post<any>(param)
  const { Data, Status } = response
  if (Status) {
    dispatch(fetchGoalDatabase())
  }
  cb?.(Status)
}
export const saveBulkGoalDatabase = (data?: any): any => async (dispatch: any, getState: any) => {
  const param = {
    endpoint: GOAL_DATABASES_BULK,
    body: data,
    dispatch: dispatch,
  }
  const response: fromInterface.ResponseWrapper<any> = data.Id ? await ApiClient.post<any>(param) : await ApiClient.post<any>(param)
  const { Data, Status } = response
  if (Status) {
    dispatch(fetchGoalDatabase())
  }
  return Status
}
export const deleteGoalDatabase = (employeeId: number, reviewCycleId: number): any => async (dispatch: any, getState: any) => {
  const param = {
    endpoint: `${GOAL_DATABASES}/${employeeId}/${reviewCycleId}`,
    dispatch: dispatch
  }
  const response: fromInterface.ResponseWrapper<any> = await ApiClient.remove<any>(param)
  const { Data, Status } = response
  if (Status) {
    dispatch(fetchGoalDatabase())
  }
  return Status
}