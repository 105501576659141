
import { ApiClient } from "../../../network/apiClient";
import * as fromInterface from "../../../ts";
import { LEVEL } from "./api";
import * as fromSlice from "./slice";
import * as fromSelector from "./selectors"


export const fetchLevels = (): any => async (dispatch: any, getState: any) => {
    const state = getState();
    const isLoaded = fromSelector.selectLevelLoaded(state)
    if (!isLoaded) {
        dispatch(fromSlice.fetchRequest())

        const response: fromInterface.ResponseWrapper<any> = await ApiClient.get<any>({
            endpoint: LEVEL,
            dispatch: dispatch
        })

        const { Data, Status } = response

        if (Status) {
            dispatch(fromSlice.fetchSuccess(Data))
        }
    }
    return fromSelector.selectLevelItems(state)
}

export const clearLevels = (): any => async (dispatch: any) => {
    dispatch(fromSlice.clear())

}