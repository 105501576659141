import { ApiClient } from "../../../network/apiClient";
import * as fromSlice from "./slice";
import { FETCH_ROLLOUT_PREVIEW } from "./api";

export const fetchRolloutPreview = (params: { rolloutId: number }) => async (dispatch: any) => {
  dispatch(fromSlice.fetchRequest());
  const response: any =
    await ApiClient.get<any>({
      endpoint: FETCH_ROLLOUT_PREVIEW.replace(":rolloutId", params.rolloutId + ""),
      dispatch: dispatch,
    });


  const { Data, Status } = response;
  if (Status) {
    dispatch(fromSlice.fetchSuccess(Data));
    return Data;
  }
};

export const clearRolloutPreview = () => (dispatch: any) => {
  dispatch(fromSlice.clear());
};