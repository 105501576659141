// @ts-nocheck 
import React from "react";
import {MdClose, MdCheck} from "react-icons/md";

const EmployeeAnsBlock = ({isCorrectAns, point}) => {
  return (
    <div className="flex ans-block">
      {isCorrectAns ? (
        <div className="green">
          <MdCheck size={12} />
        </div>
      ) : (
        <div className="red">
          <MdClose size={12} />
        </div>
      )}
      <div className="gray">{point}</div>
    </div>
  );
};

const CoursePageQuestionMultipleAnswerListItem = ({questionOption, index}) => {
  return (
    <div className="flex my-xsm">
      <div
        className={`course-page-block__question__multiple-answer ${
          questionOption.IsCorrectAns && !questionOption.EmployeeAns
            ? "course-page-block__question__multiple-answer--correct"
            : ""
        } ${
          questionOption.EmployeeAns
            ? "course-page-block__question__multiple-answer--choosen"
            : ""
        }`}
      >
        {index + 1}. {questionOption.AnswerOption}
      </div>
      {questionOption.EmployeeAns ? (
        <EmployeeAnsBlock
          isCorrectAns={questionOption.IsCorrectAns}
          point={questionOption.Point}
        />
      ) : null}
    </div>
  );
};

export default CoursePageQuestionMultipleAnswerListItem;
