import {
    GET_CALCULATION_HEADER,
    INCOME_CALCULATION_LOADING,
    SEARCH_CALCULATION_LIST,
    CALCULATION_HEADER_LOADING,
    PAYTYPE_VISIBILITY_LOADING,
    GET_PAYTYPE_VISIBILITY
} from "../../../actions/types";

const initialState = {
    calculation_header: [],
    searchedIncomeList: [],
    searchedPage: { index: 0, total: 0 },
    payTypeVisibility : {loading:false,value:{}},
    loading: false,
    calcHeaderLoading: false
};
export default function(state = initialState, action) {
    switch (action.type) {
        case INCOME_CALCULATION_LOADING:
            return {
                ...state,
                loading: true
            };
        case CALCULATION_HEADER_LOADING:
            return {
                ...state,
                calcHeaderLoading: true
            };
        case PAYTYPE_VISIBILITY_LOADING:
            return {
                ...state,
                payTypeVisibility : {loading:true,value:{}}
            };
            
        case GET_CALCULATION_HEADER:
            return {
                ...state,
                calculation_header: action.payload,
                calcHeaderLoading: false
            };
        case GET_PAYTYPE_VISIBILITY:
            return{
                ...state,
                payTypeVisibility : {loading:false,value:action.payload}
            }
        case SEARCH_CALCULATION_LIST:
            return {
                ...state,
                searchedIncomeList: action.payload,
                searchedPage: {
                    index: action.page.index,
                    total: action.page.total
                },
                loading: false
            };
        default:
            return state;
    }
}
