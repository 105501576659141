import React, { Component } from "react";
import { DisplayClientSideMessage } from "actions/commonAction";
import FormGroup from "components/form";
import { BS2AD } from "helpers/dateConvert";
import isEmpty from "isEmpty";
import { connect } from "react-redux";

import {
  GetAdvanceAndExpensesList,
  GetSavedAdvanceAndExpensesList,
  IsDropdownValueChanged,
} from "../actions";
import AdvanceAndExpensesTable from "./advanceAndExpensesTable";

export class CreateAdvanceAndExpenses extends Component {
  componentDidMount() {
    const { selectedTravelCategory } = this.props.advanceAndExpensesState;
    // this.handleDropdown("selectedTravelCategory", selectedTravelCategory, true, false);
  }

  handleDropdown = (name, selected, initial, changed) => {
    const { isDropdownValueChanged, IsDropdownValueChanged } = this.props;
    if (changed) {
      IsDropdownValueChanged(true);
    }
    this.props.handleAdvanceAndExpensesTabState &&
      this.props.handleAdvanceAndExpensesTabState(name, selected);

    const categoryId = !isEmpty(selected) ? parseInt(selected.value) : -1;
    const {
      companyInformation,
      GetSavedAdvanceAndExpensesList,
      isEditPage,
      isViewMode,
      overAllDestinationFromDate,
      overAllDestinationToDate,
      RequestId,
      selectedEmployeeId,
      requesterId,
    } = this.props;

    const { selectedTravelCategory } = this.props.advanceAndExpensesState;

    let { selectedTravelDestList } = this.props.travelInformationState;

    const totalDays = selectedTravelDestList.reduce(
      (a, b) => a + (parseInt(b["travelDestination"]["days"]) || 0),
      0
    );

    const totalNights = selectedTravelDestList.reduce(
      (a, b) => a + (parseInt(b["travelDestination"]["nights"]) || 0),
      0
    );

    if (
      !isEmpty(overAllDestinationFromDate) &&
      !isEmpty(overAllDestinationToDate)
    ) {
      const fromDate = overAllDestinationFromDate;
      const toDate = overAllDestinationToDate;
      (isViewMode || isEditPage) &&
      initial &&
      selectedTravelCategory & !isDropdownValueChanged
        ? GetSavedAdvanceAndExpensesList({
            requestId: RequestId,
            totalDays,
            totalNights,
          })
        : this.props.GetAdvanceAndExpensesList({
            categoryId,
            overAllDestinationFromDate: fromDate,
            overAllDestinationToDate: toDate,
            selectedEmployeeId,
            totalDays,
            totalNights,
          });
    } else {
      let message = {
        MessageType: "danger",
        Message: "Please fill all your details for this destination.",
      };
      this.props.DisplayClientSideMessage(message);
    }
  };

  handleTextChange = (name, value) => {
    this.props.handleAdvanceAndExpensesTabState &&
      this.props.handleAdvanceAndExpensesTabState(name, value);
  };

  render() {
    const { selectedTravelCategory, advanceRequestAmount, note } =
      this.props.advanceAndExpensesState;

    const { travelCategoryList, selectedAdvanceAndExpensesList, isViewMode } =
      this.props;
    return (
      <div className="travel-advance-expense-create">
        <div className="travel-create__title">
          <h3>Advance and expenses</h3>
        </div>
        <div className="travel-create__body">
          <div className="travel-field">
            <label>Travel category</label>
            <FormGroup
              formName="reactselect"
              name="selectedTravelCategory"
              placeholder="select category"
              value={selectedTravelCategory}
              options={travelCategoryList}
              onChange={(name, selected) =>
                this.handleDropdown(name, selected, false, true)
              }
              disabled={isViewMode}
              width="300px"
            />
          </div>
          <div className="advance-expense-table">
            {selectedAdvanceAndExpensesList &&
            selectedAdvanceAndExpensesList.length > 0 ? (
              <AdvanceAndExpensesTable
                data={selectedAdvanceAndExpensesList}
                isViewMode={isViewMode}
              />
            ) : null}
          </div>
          <div className="travel-detail-row">
            <div className="travel-field">
              <label>Advance Request Amount</label>
              <FormGroup
                name="advanceRequestAmount"
                type="number"
                value={advanceRequestAmount}
                onChange={this.handleTextChange}
                disabled={isViewMode}
                width="300px"
              />
            </div>
          </div>
          <div className="travel-field position-relative">
            {/* <label>Note</label> */}
            <FormGroup
              disabled={isViewMode}
              formName="textarea"
              label="Note"
              maxLength={4000}
              name="note"
              onChange={this.handleTextChange}
              style={{ width: "100%" }}
              value={note}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  travelCategoryList: state.travelInformationReducer.travelCategoryList,
  selectedAdvanceAndExpensesList:
    state.travelInformationReducer.selectedAdvanceAndExpensesList,
  isDropdownValueChanged: state.travelInformationReducer.isDropdownValueChanged,
});
const mapDispatchToProps = {
  GetAdvanceAndExpensesList,
  DisplayClientSideMessage,
  GetSavedAdvanceAndExpensesList,
  IsDropdownValueChanged,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAdvanceAndExpenses);
